import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ButtonClickService {
  private clickStatusSubject = new BehaviorSubject<boolean>(false);
  clickStatus$: Observable<boolean> = this.clickStatusSubject.asObservable();

  constructor() {}

  getClickStatus(): Observable<boolean> {
    const clickStatus = this.clickStatusSubject.getValue();
    return this.clickStatusSubject.asObservable();
  }

  setClickStatus(status: boolean): void {
    this.clickStatusSubject.next(status);
  }


}