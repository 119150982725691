<div
  class="footer"
  class="navbar-fixed-bottom"
  style="background-color: #0c7ec3"
>
  <div
    class="container footer-content"
    style="padding-top: 2%; padding-bottom: 2%"
  >
    <div class="row text-center">
      <div class="col-sm" style="margin-left: auto; margin-right: auto">
        <div class="row">
          <h1>Samu</h1>
        </div>
        <div class="links">
          <div class="row">
            <a routerLink="/meteo-samu">Météo des Samu</a>
          </div>
          <div class="row">
            <a
              href="https://santegraphie.fr/mviewer/?config=apps/SMURPediatrique_dev.xml#"
              target="_blank"
              >Carto samu pédiatrique</a
            >
          </div>
        </div>
      </div>
      <div class="col-sm" style="margin-left: auto; margin-right: auto">
        <div class="row">
          <h1>Urgences</h1>
        </div>
        <div class="links">
          <div class="row">
            <a routerLink="/het">Tension</a>
          </div>
          <div class="row">
            <a routerLink="/meteo-urgences">Météo des urgences</a>
          </div>
          <div class="row">
            <a routerLink="/snp-qualite">SNP Qualité</a>
          </div>
          <div class="row">
            <a href="https://litsbrancards.sesan.fr" target="_blank"
              >Lits Brancards</a
            >
          </div>
          <div class="row">
            <a routerLink="/mes-urgences">Carto mes urgences</a>
          </div>
          <div class="row">
            <a routerLink="/mes-urgences-pro">Mes urgences pro</a>
          </div>
        </div>
      </div>
      <div class="col-sm" style="margin-left: auto; margin-right: auto">
        <div class="row">
          <h1>
            <a href="https://adhoc.sante-idf.fr" target="_blank"
              >ADHOC</a
            >
          </h1>
        </div>
      </div>
      <div class="col-sm" style="margin-left: auto; margin-right: auto">
        <div class="row">
          <h1>Gestion des lits</h1>
        </div>
        <div class="links">
          <div class="row">
            <a
              href="https://santegraphie.fr/mviewer3/?config=app/ror_dispo_lits.xml"
              target="_blank"
              >Carto dispo en lits</a
            >
          </div>
        </div>
      </div>
      <div class="col-sm" style="margin-left: auto; margin-right: auto">
        <div class="row">
          <h1>Administration</h1>
        </div>
        <div class="links">
          <div class="row">
            <a href="#">Exploitation des flux</a>
          </div>
          <div class="row">
            <a routerLink="/emergency-depts">Paramétrage des SAU</a>
          </div>
          <div class="row">
            <a routerLink="/trafic-mes-urgences">Trafic Mes Urgences</a>
          </div>
          <div class="row" *ngIf="isPreprod() == true">
            <a routerLink="/pdsa-settings">Paramétrage des PDSA</a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="footer-footer">
    <div class="container">
      <div class="row" *ngIf="768 < innerWidth">
        <div class="col-sm-6">
          <div class="row">
            <div class="col-4">
              <a href="https://www.sesan.fr/" target="_blank"
                ><img
                  class="logo logo-region"
                  alt="logo region"
                  src="/assets/images/LOGO SESAN-HD-BLANC.png"
              /></a>
            </div>
            <div class="col-4">
              <a href="https://orsnp-idf.fr/" target="_blank">
                <img
                  class="logo"
                  alt="logo observatoire"
                  src="/assets/images/ORSNP_logo_blanc.png"
                />
              </a>
            </div>
            <div class="col-4">
              <a href="https://www.iledefrance.ars.sante.fr/" target="_blank">
                <img
                  class="logo"
                  alt="logo ARS"
                  src="/assets/images/ars_idf_white.png"
                />
              </a>
            </div>
          </div>
        </div>
        <!-- <div class="col-4"></div> -->
        <div class="col-sm-6 my-auto d-flex flex-row-reverse">
          <div class="row">
            <div class="col-6">
              <a class="mail" href="mailto: ecerveau@sesan.fr"
                >ecerveau@sesan.fr</a
              >
            </div>
            <div class="col-6">
              <a routerLink="/mentions-legales">Mentions Légales</a>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="768 > innerWidth">
        <div class="col-12">
          <div class="row" style="padding-left: 10%">
            <div class="col-4">
              <a href="https://www.sesan.fr/" target="_blank"
                ><img
                  class="logo logo-region"
                  alt="logo region"
                  src="/assets/images/LOGO SESAN-HD-BLANC.png"
              /></a>
            </div>
            <div class="col-4">
              <a href="https://orsnp-idf.fr/" target="_blank">
                <img
                  class="logo"
                  alt="logo observatoire"
                  src="/assets/images/ORSNP_logo_blanc.png"
                />
              </a>
            </div>
            <div class="col-4">
              <a href="https://www.iledefrance.ars.sante.fr/" target="_blank">
                <img
                  class="logo"
                  alt="logo ARS"
                  src="/assets/images/ars_idf_white.png"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-4"></div> -->
      <div class="row" *ngIf="768 > innerWidth">
        <div class="col-12 justify-content-center">
          <div class="row">
            <div class="col-6" style="text-align: center">
              <a class="mail" href="mailto: ecerveau@sesan.fr"
                >ecerveau@sesan.fr</a
              >
            </div>
            <div class="col-6" style="text-align: center">
              <a routerLink="/mentions-legales">Mentions Légales</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
