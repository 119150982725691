import { Component, OnInit } from '@angular/core';
import { AccountService } from '../core/auth/account.service';

@Component({
  selector: 'app-qualite-ara',
  templateUrl: './qualite-ara.component.html',
  styleUrls: ['./qualite-ara.component.scss']
})
export class QualiteAraComponent implements OnInit {
  account: any;

  constructor(private accountService: AccountService) {}

    ngOnInit() {
      this.accountService.identity().subscribe((account) => {
        this.account = account;
        console.warn("account change", account);
      });
        if (this.isPreprod()) {
          window.location.href = 'https://ppr-snp-qualite-ara.sesan.fr';
        }
        else {
          window.location.href = 'https://snp-qualite-ara.sante-idf.fr';
        }
    }

    isAraPage() {
      const str = window.location.href;
      return str.includes("ara") || str.includes(":4201/");
    }

    isPreprod() {
      const str = window.location.href;
      return str.includes("ppr");
    }
  
}
