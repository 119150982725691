import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { InputHierarchy } from "../model/input-hierarchy.model";

@Injectable({
  providedIn: "root",
})
export class InputHierarchyService {
  constructor(private httpClient: HttpClient) {}

  public getInputHierarchy(): Observable<InputHierarchy[]> {
    return this.httpClient.get<InputHierarchy[]>(`/api/input-hierarchy`);
  }

  //à ajouter pour récup code EG et code Serv urg
  public getInputHierarchyBySu(
    codEg: string,
    codSerUrg: number
  ): Observable<InputHierarchy[]> {
    return this.httpClient.get<InputHierarchy[]>(
      `/api/input-hierarchy/${codEg}-${codSerUrg}`
    );
  }

  public setInputHierarchy(
    inputHierarchy: InputHierarchy
  ): Observable<InputHierarchy> {
    return this.httpClient.post<InputHierarchy>(
      `/api/input-hierarchy`,
      inputHierarchy
    );
  }
}
