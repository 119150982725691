<form
  [formGroup]="mobileCrraVisitRangeForm"
  (ngSubmit)="onSubmit(eventTypes.CLOSE)"
>
  <div class="table-responsive">
    <table class="table table-striped shadow-sm p-3 bg-white">
      <thead>
        <tr class="noselect header-row">
          <th
            style="max-width: 15vw; width: 15vw; padding: 0px; margin: 0px"
          ></th>
          <th
            *ngFor="let timeSlotLabel of timeSlotLabels; let i = index"
            style="max-width: 4vw; margin: 0px"
          >
            <p style="color: #ffffff">{{ timeSlotLabel }}</p>
            <p style="color: #ffffff">{{ timeSlotDayTypes[i] }}</p>
          </th>
        </tr>
      </thead>
      <tbody formArrayName="elements">
        <tr
          *ngFor="let item of items; let i = index"
          class="cursor noselect hover-effect"
        >
          <td>
            <div class="d-flex align-items-center">
              <div class="fontSize">
                Nombre de visites effectuées à la demande directe du CRRA15
              </div>
            </div>
          </td>
          <td
            *ngFor="
              let countControl of mobileCrraVisitRangeForm.get('elements')
                ?.controls;
              let i = index
            "
          >
            <div class="d-flex justify-content-center align-items-center">
              <input
                type="text"
                class="rounded border inputSize"
                style="width: 4vw; height: 2vw"
                [ngClass]="{
                  error: countControl.get('count').hasError('invalidValue'),
                  warning: countControl.get('count').hasError('emptyValue')
                }"
                [formControl]="countControl.get('count')"
                (input)="this.onInputChange()"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</form>
