import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Commune } from '../model/communes.model';

@Injectable({
  providedIn: 'root'
})
export class CommunesService {
  constructor(private httpClient: HttpClient) {}

  public getCommunesIDF(): Observable<Commune[]> {
    return this.httpClient.get<Commune[]>(`/api/communes/idf`);
  }
}
