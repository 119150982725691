<!DOCTYPE html>
<html lang="fr">
  <link
    rel="stylesheet"
    href="https://fonts.googleapis.com/icon?family=Material+Icons"
  />
  <script src="//ajax.googleapis.com/ajax/libs/jquery/1.10.2/jquery.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
  <title>Paramétrages des services d'urgences</title>

  <body>
    <div *ngIf="pageIs == false">
      <div class="div-alert">
        <alert class="alert"></alert>
      </div>
      <div class="col-md-12"></div>
      <div class="container content col-md-11" style="padding-top: 2rem">
        <div class="row">
          <div class="col-md-12">
            <h1 class="display-5 text-muted">
              Paramétrage des services d'urgences
            </h1>
            <p class="display-5 text-muted mb-1">
              Cette page permet de créer, gérer ou bien désactiver les
              différents services d'urgence. <br />
              Les paramétrages du module HET et de l'application mobile "Mes
              urgences" des différents services d'urgence sont accessibles dans
              leur page respective.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1"
              ><i class="material-icons">&#xE8B6;</i></span
            >
            <input
              class="form-control shadow-none border border-primary-none"
              placeholder="Recherche"
              [(ngModel)]="userQuestion"
              type="text"
              name="userQuestion"
              id="userQuestions"
              (ngModelChange)="this.userQuestionUpdate.next($event)"
              placeholder="Recherche"
              aria-label="Recherche"
              aria-describedby="basic-addon1"
              autocomplete="off"
            />
            <button
              class="btn btn-primary d-flex align-items-center"
              (click)="openNewSau()"
              ngbTooltip="Créer un service d'urgence"
              type="submit"
            >
              <i class="material-icons">add</i>
            </button>
          </div>
        </div>
        <div class="row"></div>

        <div
          class="row d-flex justify-content-center"
          (click)="coordinates($event)"
        >
          <div class="shadow p-md-3 mb-5 bg-white rounded w-100">
            <div class="container-fluid">
              <table class="table-striped w-100">
                <thead class="text-muted text-center">
                  <tr>
                    <th
                      *ngFor="let headerDefintion of headerDefintions"
                      [ngClass]="
                        headerDefintion.fieldName == 'FINESS' ||
                        headerDefintion.fieldName == 'PEDIATRIQUE SUR ADULTE'
                          ? 'd-none d-sm-table-cell'
                          : ''
                      "
                    >
                      <app-header-table
                        titleHeader="{{ headerDefintion.fieldName }}"
                        [sectionTitle]="
                          headerDefintion.sortAsc == false ||
                          headerDefintion.sortAsc == true
                            ? 'text-primary'
                            : ''
                        "
                        [filterUp]="
                          headerDefintion.sortAsc == false
                            ? 'material-icons cursor-default text-primary'
                            : 'material-icons cursor-default'
                        "
                        (sortDesc)="sortEmergencyDepts($event)"
                        [filterDown]="
                          headerDefintion.sortAsc == true
                            ? 'material-icons cursor-default text-primary'
                            : 'material-icons cursor-default'
                        "
                        (sortAsc)="sortEmergencyDepts($event)"
                      ></app-header-table>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let emergencyDept of emergencyDepts; let i = index"
                  >
                    <td class="text-muted">
                      {{ emergencyDept.finess | slice : 0 : 2 }}
                    </td>
                    <td class="text-primary text-left">
                      {{ emergencyDept.libSerUrg }}
                    </td>

                    <td class="text-muted d-none d-sm-table-cell">
                      {{ emergencyDept.finess }}
                    </td>

                    <td class="text-muted text-center">
                      {{ emergencyDept.type | transformSauType }}
                    </td>
                    <td class="text-muted d-none d-sm-table-cell text-center">
                      {{ emergencyDept.pedAdu | yesOrNo }}
                    </td>
                    <td>
                      <div [class]="emergencyDept.enabled | checkCircle">
                        <i class="material-icons"
                          >{{ emergencyDept.enabled | checkCircle }}
                        </i>
                        <span [class]="emergencyDept.enabled | actifOrInactif"
                          >{{ emergencyDept.enabled | actifOrInactif }}
                        </span>
                      </div>
                    </td>
                    <td>
                      <button
                        type="button"
                        class="btn modify btn-primary"
                        (click)="
                          openSau(emergencyDept.code, emergencyDept.type)
                        "
                        ngbTooltip="Modifier le service d'urgence"
                        container="body"
                      ></button>
                    </td>
                    <td>
                      <div class="d-flex align-items-center">
                        <button
                          [disabled]="emergencyDept.enabled === false"
                          type="button"
                          [class]="
                            emergencyDept.enabled === false
                              ? 'btn delete-inactif'
                              : 'btn delete'
                          "
                          (click)="
                            openWind(
                              emergencyDept.code,
                              emergencyDept.type,
                              emergencyDept.libSerUrg
                            )
                          "
                          ngbTooltip="Désactiver le service d'urgence"
                          container="body"
                        ></button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="pl-5 pr-5">
              <div
                class="row d-flex justify-content-between align-self-md-center border-top mt-1"
              >
                <div class="align-self-md-center selectPageNumber">
                  Afficher
                  <select
                    [(ngModel)]="size"
                    ngbTooltip="Selection du nombre par page"
                    (change)="sizeOfTable(size)"
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option selected value="50">50</option>
                  </select>

                  éléments
                </div>
                <div class="align-self-md-center selectPage">
                  <nav>
                    <ul class="pagination">
                      <li
                        class="page-item"
                        [ngClass]="{ disabled: page === 1 }"
                      >
                        <a
                          aria-label="Previous"
                          role="button"
                          class="page-link"
                          (click)="getPage(0, -1)"
                          ><span class="sr-only">Previous</span>
                          <span aria-hidden="true">&laquo;</span></a
                        >
                      </li>
                      <li
                        *ngFor="
                          let i of counter(numberOfPages);
                          index as pageNumber
                        "
                        class="page-item"
                        [ngClass]="{ active: page === pageNumber + 1 }"
                      >
                        <a
                          role="button"
                          class="page-link"
                          (click)="getPage(pageNumber + 1)"
                          >{{ pageNumber + 1 }}</a
                        >
                      </li>
                      <li
                        class="page-item"
                        [ngClass]="{ disabled: page === numberOfPages }"
                      >
                        <a
                          aria-label="Next"
                          role="button"
                          class="page-link"
                          (click)="getPage(0, 1)"
                        >
                          <span aria-hidden="true">&raquo;</span>
                          <span class="sr-only">Next</span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--FORMULAIRE SAU-->

    <div *ngIf="pageIs == true">
      <div class="card mx-auto">
        <div class="form" role="document">
          <form [formGroup]="emergencyForm">
            <div class="form-content">
              <!--FORMULAIRE HEADER-->
              <div class="col-xs-12" *ngIf="isNew">
                <div class="form-header">
                  <h2 class="display-5 text-muted">
                    Création d'un service d'urgence
                  </h2>
                </div>
              </div>
              <div class="col-xs-12" *ngIf="!isNew">
                <div class="form-header">
                  <h2 class="display-5 text-muted">
                    {{ emergencyForm.value.libelleSU }}
                  </h2>
                  <br />
                </div>
              </div>

              <!--FORMULAIRE CONTENT-->
              <div class="col-xs-12">
                <div class="form-body text-muted">
                  <div class="container-fluid">
                    <ngbd-nav-markup
                      [emergencyForm]="emergencyForm"
                      [emergencyDepts]="emergencyDepts"
                      [selectedEmergencyDept]="selectedEmergencyDept"
                      [geoEntities]="geoEntities"
                      [edTerritories]="edTerritories"
                      [users]="users"
                      [deptSizes]="deptSizes"
                      [doubleauthidden]="doubleauthidden"
                      [selectedContactTechnicalParameter]="
                        selectedContactTechnicalParameter
                      "
                      [technicalParameterForm]="technicalParameterForm"
                      [technicalParameter]="technicalParameter"
                      [selectedTechnicalParameter]="selectedTechnicalParameter"
                      (updateContacts)="updateContacts($event)"
                      (addDeleteIds)="addDeleteIds($event)"
                      [newContactsForm]="newContactsForm"
                      [contactsArrayForm]="contactsArrayForm"
                      [contactsForm]="contactsForm"
                      [logicielMetierStartId]="logicielMetierStartId"
                      [emergencySpeciality]="emergencySpeciality"
                      [logicielTechnicalParameter]="logicielTechnicalParameter"
                      [itForm]="itForm"
                      [indicateur]="indicateur"
                      [inputHierarchy]="inputHierarchy"
                      [classificationForm]="classificationForm"
                      (updateInputHierarchySaisie)="
                        updateInputHierarchySaisie()
                      "
                    ></ngbd-nav-markup>
                  </div>
                </div>
              </div>
              <div class="form-footer">
                <div class="row">
                  <div class="col-md-12">
                    <button
                      type="button"
                      class="btn save"
                      [disabled]="!emergencyForm.valid"
                      name="Enregistrer"
                      (click)="onSubmit()"
                      appDisableDoubleClick
                    >
                      Enregistrer
                    </button>
                    <!--BTN DISABLED IF IT.CONTROLS PAS OK : [disabled]="!emergencyForm.valid && !itForm.controls['IT1'].invalid"-->
                    <button
                      type="button"
                      class="btn cancel"
                      (click)="onCloseHandled()"
                    >
                      Annuler
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.modal-content -->
          </form>
        </div>
      </div>
      <!-- /.modal-dialog -->
    </div>
    <!-- /.modal !-->

    <div class="backdrop" [ngStyle]="{ display: dis }"></div>

    <!-- /.modal !-->

    <div class="backdrop" [ngStyle]="{ display: dis }"></div>
    <div
      class="modal modal2"
      tabindex="-1"
      role="dialog"
      [ngStyle]="{ display: dis }"
      *ngIf="selectedEmergencyDept"
      style="overflow-y: hidden"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Désactivation</h5>
          </div>
          <div class="modal-body">
            Êtes-vous sûr de vouloir désactiver :
            {{ selectedEmergencyDept.abrSerUrg }}
          </div>
          <div class="form-footer">
            <button
              type="button"
              class="btn btn-secondary"
              (click)="
                closeWind(
                  selectedEmergencyDept.finess,
                  selectedEmergencyDept.libSerUrg,
                  selectedEmergencyDept.pedAdu,
                  selectedEmergencyDept.type
                )
              "
              style="margin-right: 2%"
            >
              Annuler
            </button>
            <button type="button" class="btn btn-primary" (click)="saveWind()">
              Confirmer
            </button>
          </div>
        </div>
      </div>
    </div>
  </body>
</html>
