import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PostedConsultationEntry } from '../../model/activity/posted-consultation-entry.model';

@Injectable({
  providedIn: 'root'
})
export class PostedConsultationEntryService {
  constructor(private httpClient: HttpClient) {}

  public getPostedConsultationEntries(
    id: number,
    yearMonth: string
  ): Observable<PostedConsultationEntry> {
    return this.httpClient.get<PostedConsultationEntry>(
      `api/effectors/${id}/activities/${yearMonth}/consultation`
    );
  }

  public setPostedConsultationEntries(
    id: number,
    yearMonth: string,
    postedConsultationEntry: PostedConsultationEntry
  ): Observable<PostedConsultationEntry> {
    return this.httpClient.post<PostedConsultationEntry>(
      `api/effectors/${id}/activities/${yearMonth}/consultation`,
      postedConsultationEntry
    );
  }
}
