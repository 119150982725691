<div id="contenu-meteo" *ngIf="account?.authorities.includes('28')">
  <div class="div-alert" style="display: flex; flex-direction: column">
    <alert
      class="alert"
      style="
        padding-right: 0px !important;
        padding-left: 0px !important;
        padding-bottom: 0px !important;
        padding-top: 0px !important;
      "
    ></alert>
  </div>
  <iframe
    *ngIf="!isPreprod()"
    width="100%"
    frameBorder="0"
    src="https://meteo-urgence.sante-idf.fr"
    scrolling="yes"
  ></iframe>
  <iframe
    *ngIf="isPreprod()"
    width="100%"
    frameBorder="0"
    src="https://ppr-meteo-urgence-predictif.sesan.fr/"
    scrolling="yes"
  ></iframe>
</div>

<div
  class="accessdenied"
  *ngIf="accessDenied && !account?.authorities.includes('28')"
>
  <app-error-page></app-error-page>
</div>
