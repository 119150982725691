<div style="padding-bottom: 1%; padding-top: 1%">
  <div ngcontent class="card mx-auto my-auto">
    <div class="container-fluid">
      <div class="my-1">
        <h1>{{ getTitle() }}</h1>
      </div>
      <!--NAVIGATION-->
      <nav ngbNav #nav="ngbNav" class="nav-tabs">
        <!-- TAB 1 -->
        <ng-container ngbNavItem [ngbNavItem]="1">
          <a ngbNavLink>Informations générales</a>

          <ng-template
            ngbNavContent
            class="nav-content shadow p-md-3 mb-5 bg-white rounded w-100"
          >
            <form [formGroup]="effectorsForm">
              <div class="row">
                <div class="col-12 col-md-4">
                  <!-- Type of structure-->
                  <label for="structure" class="form-control-label required"
                    >Type de structure</label
                  >

                  <!-- Radio button -->
                  <div class="d-flex typeOfStructure">
                    <div class="form-check mr-5">
                      <input
                        mdbRadio
                        class="form-check-input mt-2 modifyStyleRadio"
                        type="radio"
                        id="MOB"
                        formControlName="domainCode"
                        value="MOB"
                        (change)="onDomainCodeChanged()"
                        [attr.disabled]="idEffecteur ? true : null"
                        [ngClass]="{
                          active:
                            effectorsForm.controls['domainCode'].value === 'MOB'
                        }"
                      />
                      <label class="form-check-label" for="MOB"> Mobile </label>
                    </div>
                    <div class="form-check mr-4">
                      <input
                        mdbRadio
                        class="form-check-input mt-2 modifyStyleRadio"
                        type="radio"
                        id="POS"
                        formControlName="domainCode"
                        value="POS"
                        (change)="onDomainCodeChanged()"
                        [attr.disabled]="idEffecteur ? true : null"
                        [ngClass]="{
                          active:
                            effectorsForm.controls['domainCode'].value === 'POS'
                        }"
                      />
                      <label class="form-check-label" for="POS"> Postée </label>
                    </div>
                  </div>
                </div>

                <!-- Type de job -->
                <div
                  class="col-12 col-md-4"
                  *ngIf="effectorsForm.controls['domainCode'].value === 'MOB'"
                ></div>
                <div
                  class="col-12 col-md-4"
                  *ngIf="effectorsForm.controls['domainCode'].value === 'POS'"
                >
                  <label
                    for="typeStructureCode"
                    class="form-control-label required"
                    >Type de posté</label
                  ><br />
                  <ng-select
                    [items]="typePoste"
                    formControlName="typeStructureCode"
                    bindLabel="name"
                    bindValue="id"
                    labelForId="typeStructureCode"
                    id="typeStructureCode"
                    ngbTooltip="Type de poste"
                    [clearable]="false"
                    required
                  ></ng-select>
                </div>

                <!-- Identifier -->
                <div class="col-12 col-md-4">
                  <div class="form-group">
                    <label for="identifiant">Identifiant</label>
                    <input
                      type="text"
                      class="identifier form-control"
                      id="identifiant"
                      name="idEffecteur"
                      [value]="this.idEffecteur ?? ''"
                      ngbTooltip="Identifiant"
                      [attr.disabled]="true"
                    />
                  </div>
                </div>

                <!-- Labels -->
                <div class="col-12">
                  <div class="form-group">
                    <label class="required" for="ARS"
                      >Libellé ARS de la structure</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      formControlName="effectorArsLabel"
                      maxlength="200"
                      name="effectorArsLabel"
                      id="effectorArsLabel"
                      required
                      ngbTooltip="Libellé ARS de la structure"
                    />
                  </div>
                  <div class="form-group">
                    <label class="required" for="libelle">
                      Libellé de la structure</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      formControlName="effectorLabel"
                      maxlength="200"
                      name="effectorLabel"
                      id="effectorLabel"
                      required
                      ngbTooltip="Libellé de la structure"
                    />
                  </div>
                  <div class="form-group">
                    <label class="required" for="libelle"
                      >Libellé court de la structure</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      formControlName="effectorShortLabel"
                      maxlength="50"
                      name="effectorShortLabel"
                      id="libelle"
                      required
                      ngbTooltip="Libellé court de la structure"
                    />
                  </div>
                </div>

                <div class="col-12 col-md-4 mt-2">
                  <div>
                    <label for="postType" class="form-control-label required"
                      >Département</label
                    >
                    <ng-select
                      [items]="departmentCode"
                      formControlName="departmentCode"
                      maxlength="5"
                      bindLabel="name"
                      bindValue="id"
                      [clearable]="false"
                      labelForId="departmentCode"
                      id="departmentCode"
                      required
                      ngbTooltip="Département"
                    ></ng-select>
                  </div>
                </div>

                <!-- Date Picker -->
                <div class="col col-md-4 mt-2">
                  <div class="form-group">
                    <label for="libelle" class="required"
                      >Début de validité</label
                    >
                    <div class="input-group">
                      <input
                        class="form-control"
                        formControlName="validityStartDate"
                        type="date"
                        lang="fr-ca"
                        id="validityStartDate"
                        name="validityStartDate"
                        ngbTooltip="Date de début de validité"
                        placeholder="jj-mm-aaaa"
                        [valueAsDate]="validityStartDate"
                        (change)="validityStartDate = $event.target.valueAsDate"
                      />
                    </div>
                  </div>
                </div>
                <div class="col col-md-4 mt-2">
                  <div class="form-group">
                    <label class="required" for="libelle"
                      >Fin de validité</label
                    >
                    <div class="input-group">
                      <input
                        class="form-control"
                        formControlName="validityEndDate"
                        type="date"
                        lang="fr-ca"
                        id="validityEndDate"
                        name="validityEndDate"
                        ngbTooltip="Date de fin de validité"
                        placeholder="jj-mm-aaaa"
                        min="{{ validityStartDate | date: 'yyyy-MM-dd' }}"
                        [ngClass]="{
                          'invalid-date': isEndStructureDateInvalidCreation()
                        }"
                        [ngbTooltip]="isEndStructureDateInvalidCreation()"
                        [ngbTooltip]="
                          isEndStructureDateInvalidCreation()
                            ? 'La date de fin doit être postérieure ou égale à la date de début'
                            : ''
                        "
                        [ngClass]="{
                          'invalid-date': !isValidityStartDateValid
                        }"
                      />
                    </div>
                  </div>
                </div>

                <!-- Structural manager and managing entity -->
                <div class="col-12 col-md-4 mt-2">
                  <div class="form-group">
                    <label for="structureResponsable"
                      >Responsable de structure</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="structureResponsable"
                      ngbTooltip="Responsable de structure"
                      formControlName="managerName"
                      maxlength="200"
                    />
                  </div>
                </div>
                <div class="col-md-8 mt-2">
                  <div class="form-group">
                    <label for="gestionnaire">Entité gestionnaire</label>
                    <input
                      type="text"
                      class="form-control"
                      id="gestionnaire"
                      ngbTooltip="Entité gestionnaire"
                      formControlName="managingEntityName"
                      maxlength="200"
                    />
                  </div>
                </div>

                <!-- List of related users -->
                <h3 class="mt-4 mx-3">Liste des utilisateurs rattachés</h3>
                <div
                  class="shadow p-md-3 mb-5 bg-white rounded w-100 mx-3 mt-2"
                >
                  <div class="container-fluid">
                    <table class="table table-striped w-100 table-borderless">
                      <thead class="text-muted">
                        <tr>
                          <th>
                            <p
                              class="text-perso cursor"
                              [appSort]="users"
                              data-order="desc"
                              data-name="lastname"
                            >
                              NOM&nbsp;<img
                                src="../../assets/images/down.png"
                                class="icon-sort"
                              />
                            </p>
                          </th>
                          <th>
                            <p
                              class="text-perso cursor"
                              [appSort]="users"
                              data-order="desc"
                              data-name="firstname"
                            >
                              PRENOM&nbsp;<img
                                src="../../assets/images/down.png"
                                class="icon-sort"
                              />
                            </p>
                          </th>
                          <th>
                            <p
                              class="text-perso cursor"
                              [appSort]="users"
                              data-order="desc"
                              data-name="email"
                            >
                              MAIL&nbsp;<img
                                src="../../assets/images/down.png"
                                class="icon-sort"
                              />
                            </p>
                          </th>
                          <th><p class="text-perso">TELEPHONE</p></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="
                            let user of users
                              | paginate
                                : {
                                    itemsPerPage: tableSize,
                                    currentPage: page,
                                    totalItems: count
                                  };
                            let i = index
                          "
                        >
                          <td class="text-muted align-middle">
                            {{ user.lastName }}
                          </td>
                          <td class="text-muted align-middle">
                            {{ user.firstName }}
                          </td>
                          <td class="text-muted align-middle">
                            {{ user.mail }}
                          </td>
                          <td class="text-muted">{{ user.phone }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </form>
          </ng-template>
        </ng-container>

        <!-- Tab 2 -->
        <ng-container ngbNavItem>
          <a ngbNavLink>Paramétrage PDSA</a>
          <ng-template ngbNavContent>
            <form [formGroup]="parentForm">
              <div formGroupName="effectorsForm">
                <div class="row">
                  <div
                    class="col-12 col-md-4 mb-2"
                    *ngIf="effectorsForm.controls['domainCode'].value === 'MOB'"
                  >
                    <div class="form-check checkbox icheck-primary">
                      <input
                        id="flexCheckDefault"
                        class="form-check-input mt-2"
                        type="checkbox"
                        formControlName="platformBoolean"
                        [ngClass]="{
                          active:
                            effectorsForm.controls['platformBoolean'].value ===
                            1
                        }"
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Plateforme d'appel
                      </label>
                    </div>
                  </div>
                  <div class="col-12 col-md-4">
                    <div class="form-check checkbox icheck-primary">
                      <input
                        id="flexCheckChecked"
                        class="form-check-input mt-2"
                        type="checkbox"
                        formControlName="ccrBoolean"
                        [ngClass]="{
                          active:
                            effectorsForm.controls['ccrBoolean'].value === 0
                        }"
                      />
                      <label class="form-check-label" for="flexCheckChecked">
                        Hors cahier des charges
                      </label>
                    </div>
                  </div>
                </div>

                <h3 class="mt-4">Paramétrage des flux</h3>
                <div class="row mb-1 mt-2">
                  <div class="col-12 col-md-8">
                    <div class="form-group">
                      <label for="codeMetier"
                        >Code métier de la structure</label
                      >
                      <div class="input-group has-validation">
                        <input
                          type="text"
                          class="form-control"
                          id="codeMetier"
                          formControlName="effectorCodeMet"
                          [class.is-invalid]="
                            effectorsForm.get('effectorCodeMet')?.invalid
                          "
                          maxlength="50"
                          ngbTooltip="Code métier de la structure"
                        />
                        <div
                          *ngIf="
                            effectorsForm
                              .get('effectorCodeMet')
                              ?.hasError('codeMetAlreadyExist')
                          "
                          class="invalid-feedback"
                        >
                          Ce code métier existe déjà, merci d'en choisir un
                          autre
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row my-2">
                  <div
                    *ngIf="effectorsForm.controls['domainCode'].value === 'MOB'"
                  ></div>
                  <div
                    class="col-12 col-md-4 mt-3"
                    *ngIf="effectorsForm.controls['domainCode'].value === 'POS'"
                  >
                    <div class="form-check checkbox icheck-primary">
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        id="flowTransmissionBoolean"
                        formControlName="flowTransmissionBoolean"
                        (change)="onFlowChanged()"
                        [ngClass]="{
                          active:
                            effectorsForm.controls['flowTransmissionBoolean']
                              .value === 1
                        }"
                      />
                      <label
                        class="form-check-label"
                        for="flowTransmissionBoolean"
                      >
                        Flux Consultations activé
                      </label>
                    </div>
                    <div
                      class="input-group"
                      [ngSwitch]="
                        effectorsForm.controls['flowTransmissionBoolean'].value
                      "
                    >
                      <div *ngSwitchCase="true" class="mr-md-4 mb-2">
                        <input
                          class="form-control"
                          formControlName="firstTransmissionDate"
                          type="date"
                          lang="fr-ca"
                          id="firstTransmissionDate"
                          name="firstTransmissionDate"
                          ngbTooltip="Date de début de transmission"
                          placeholder="jj-mm-aaaa"
                        />
                      </div>
                      <div *ngSwitchCase="false" class="mr-md-4 mb-2">
                        <input
                          class="form-control"
                          type="date"
                          lang="fr-ca"
                          ngbTooltip="Date de début de transmission du flux consultations"
                          placeholder="jj-mm-aaaa"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-12 col-md-4 mt-3"
                    *ngIf="effectorsForm.controls['domainCode'].value === 'POS'"
                  ></div>
                  <div
                    class="col-12 col-md-4 mt-3"
                    *ngIf="effectorsForm.controls['domainCode'].value === 'MOB'"
                  >
                    <div class="form-check checkbox icheck-primary">
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        id="fileFlowBoolean"
                        formControlName="fileFlowBoolean"
                        (change)="onFlowChanged()"
                        [ngClass]="{
                          active:
                            effectorsForm.controls['fileFlowBoolean'].value ===
                            1
                        }"
                      />
                      <label class="form-check-label" for="fileFlowBoolean">
                        Flux Visites activé
                      </label>
                    </div>
                    <div
                      class="input-group"
                      [ngSwitch]="
                        effectorsForm.controls['fileFlowBoolean'].value
                      "
                    >
                      <div *ngSwitchCase="true" class="mr-md-4 mb-2">
                        <input
                          class="form-control"
                          formControlName="visitTransmissionDate"
                          type="date"
                          lang="fr-ca"
                          id="visitTransmissionDate"
                          name="visitTransmissionDate"
                          ngbTooltip="Date de début de transmission du flux visites"
                          placeholder="jj-mm-aaaa"
                        />
                      </div>
                      <div *ngSwitchCase="false" class="mr-md-4 mb-2">
                        <input
                          class="form-control"
                          type="date"
                          lang="fr-ca"
                          ngbTooltip="Date de début de transmission du flux visites"
                          placeholder="jj-mm-aaaa"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-12 col-md-4 mt-3"
                    *ngIf="effectorsForm.controls['domainCode'].value === 'POS'"
                  ></div>
                  <div
                    class="col-12 col-md-4 mt-3"
                    *ngIf="effectorsForm.controls['domainCode'].value === 'MOB'"
                  >
                    <div class="form-check checkbox icheck-primary">
                      <input
                        class="form-check-input mt-2"
                        type="checkbox"
                        id="callFlowBoolean"
                        formControlName="callFlowBoolean"
                        (change)="onFlowChanged()"
                        [ngClass]="{
                          active:
                            effectorsForm.controls['callFlowBoolean'].value ===
                            1
                        }"
                      />
                      <label class="form-check-label" for="callFlowBoolean">
                        Flux Appels activé
                      </label>
                    </div>
                    <div
                      class="input-group"
                      [ngSwitch]="
                        effectorsForm.controls['callFlowBoolean'].value
                      "
                    >
                      <div *ngSwitchCase="true" class="mr-md-4 mb-2">
                        <input
                          class="form-control"
                          formControlName="callTransmissionDate"
                          type="date"
                          lang="fr-ca"
                          id="callTransmissionDate"
                          name="callTransmissionDate"
                          ngbTooltip="Date de début de transmission du flux appels"
                          placeholder="jj-mm-aaaa"
                        />
                      </div>
                      <div *ngSwitchCase="false" class="mr-md-4 mb-2">
                        <input
                          class="form-control"
                          type="date"
                          lang="fr-ca"
                          ngbTooltip="Date de début de transmission du flux appels"
                          placeholder="jj-mm-aaaa"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Time slots PDSA -->
              <h3 class="my-4">Plages horaires PDSA</h3>
              <div class="row" formArrayName="availabilitiesForm">
                <div
                  class="col-4"
                  *ngFor="let availability of availabilities; let i = index"
                >
                  <div
                    class="form-check checkbox icheck-primary"
                    [formGroupName]="i"
                  >
                    <input
                      id="availability-{{ i }}"
                      class="form-check-input mt-2 mb-2"
                      type="checkbox"
                      formControlName="validityBoolean"
                    />
                    <label class="form-check-label" for="availability-{{ i }}">
                      {{ availability.dayType }}
                      {{ availability.slotTimeLabel }}
                    </label>
                  </div>
                </div>
              </div>

              <!-- Add territory PDSA button -->
              <div class="d-flex justify-content-between">
                <h3 style="display: flex; flex-direction: column-reverse">
                  Territoire(s) de rattachement
                </h3>
                <div class="row">
                  <div class="col-1">
                    <button
                      class="btn btn-primary d-flex align-items-center"
                      ngbTooltip="Créer un territoire PDSA"
                      placement="left"
                      type="button"
                      (click)="this.newEffectorsTerritoryForm.enable()"
                    >
                      <i class="material-icons">add</i>
                    </button>
                  </div>
                </div>
              </div>
              <!-- TABLE EDITABLE -->
              <div class="row" style="margin-right: 2px; margin-left: 2px">
                <div class="shadow p-md-3 mt-2 mb-5 bg-white rounded w-100">
                  <div class="container-fluid">
                    <table class="table table-striped w-100 table-borderless">
                      <thead class="text-muted">
                        <tr>
                          <th>
                            <p
                              (click)="changeStyle('healthTerritoryCode')"
                              class="text-perso cursor"
                              data-order="desc"
                              [appSort]="effectorsTerritoryTableLine"
                              data-name="healthTerritoryCode"
                            >
                              TERRITOIRE PDSA&nbsp;<img
                                src="../../../../assets/images/down.png"
                                class="icon-sort"
                                [style]="{
                                  transform:
                                    changeRotate.healthTerritoryCode === true
                                      ? 'rotate(180deg)'
                                      : ''
                                }"
                              />
                            </p>
                          </th>
                          <th>
                            <p
                              (click)="changeStyle('validityStartDate')"
                              class="text-perso cursor"
                              data-order="desc"
                              [appSort]="effectorsTerritoryTableLine"
                              data-name="validityStartDate"
                            >
                              DATE DE DEBUT&nbsp;<img
                                src="../../../../assets/images/down.png"
                                class="icon-sort"
                                [style]="{
                                  transform:
                                    changeRotate.validityStartDate === true
                                      ? 'rotate(180deg)'
                                      : ''
                                }"
                              />
                            </p>
                          </th>
                          <th>
                            <p
                              (click)="changeStyle('validityEndDate')"
                              class="text-perso cursor"
                              data-order="desc"
                              [appSort]="effectorsTerritoryTableLine"
                              data-name="validityEndDate"
                            >
                              DATE DE FIN&nbsp;<img
                                src="../../../../assets/images/down.png"
                                class="icon-sort"
                                [style]="{
                                  transform:
                                    changeRotate.validityEndDate === true
                                      ? 'rotate(180deg)'
                                      : ''
                                }"
                              />
                            </p>
                          </th>
                        </tr>
                      </thead>
                      <!-- If we want to create a new territory (input to POST) -->
                      <tbody>
                        <tr
                          *ngIf="newEffectorsTerritoryForm.enabled"
                          [formGroup]="newEffectorsTerritoryForm"
                        >
                          <td class="text-muted align-middle py-2">
                            <ng-select
                              [items]="territories"
                              bindLabel="territoryCode"
                              bindValue="territoryCode"
                              labelForId="healthTerritoryCode"
                              id="healthTerritoryCode"
                              ngbTooltip="Code territoire santé"
                              [clearable]="false"
                              required
                              formControlName="healthTerritoryCode"
                              (keyup.enter)="addNewEffectorTerritory()"
                              dropdownPosition="top"
                            ></ng-select>
                          </td>
                          <td class="text-muted align-middle py-2">
                            <input
                              type="date"
                              id="inputStartDate"
                              class="form-control"
                              style="height: 2.3rem"
                              aria-describedby="Validity Start date "
                              formControlName="validityStartDate"
                              (keyup.enter)="addNewEffectorTerritory()"
                              [valueAsDate]="validityStartDateTer"
                              (change)="
                                validityStartDateTer = $event.target.valueAsDate
                              "
                              [ngClass]="{
                                'invalid-date': !isValidityStartDateValid
                              }"
                            />
                            <div *ngIf="!isValidityStartDateValid">
                              Ce champ est obligatoire.
                            </div>
                          </td>
                          <td
                            class="text-muted d-none d-md-table-cell align-middle py-2"
                          >
                            <input
                              type="date"
                              id="inputEndDate"
                              class="form-control"
                              style="height: 2.3rem"
                              aria-describedby="Validity Start date "
                              formControlName="validityEndDate"
                              (keyup.enter)="addNewEffectorTerritory()"
                              min="{{
                                validityStartDateTer | date: 'yyyy-MM-dd'
                              }}"
                              [ngClass]="{
                                'invalid-date': isEndDateInvalidCreation()
                              }"
                              [ngbTooltip]="isEndDateInvalidCreation()"
                              [ngbTooltip]="
                                isEndDateInvalidCreation()
                                  ? 'La date de fin doit être postérieure ou égale à la date de début'
                                  : ''
                              "
                              [ngClass]="{
                                'invalid-date': !isValidityStartDateValid
                              }"
                            />
                            <div *ngIf="!isValidityEndDateValid">
                              Ce champ est obligatoire.
                            </div>
                          </td>
                          <td class="text-center">
                            <button
                              *ngIf="newEffectorsTerritoryForm.enabled"
                              type="button"
                              class="btn check btn-primary"
                              container="body"
                              ngbTooltip="Ajouter ce territoire"
                              (click)="addNewEffectorTerritory()"
                            ></button>
                            <button
                              type="button"
                              class="btn remove btn-danger"
                              container="body"
                              ngbTooltip="Supprimer ce territoire"
                              (click)="resetNewEffectorTerritory()"
                            ></button>
                          </td>
                        </tr>
                        <tr
                          *ngFor="
                            let effectorTerritory of effectorsTerritoryTableLine
                              | paginate
                                : {
                                    itemsPerPage: tableSize,
                                    currentPage: page,
                                    totalItems: count
                                  };
                            let i = index
                          "
                          [formGroup]="effectorTerritory.form"
                          (dblclick)="
                            enableEffectorTerritoryForm(effectorTerritory)
                          "
                        >
                          <ng-container *ngIf="!effectorTerritory.toDelete">
                            <!-- To modify a line -->
                            <ng-container
                              *ngIf="effectorTerritory.isInEditMode"
                            >
                              <td class="text-muted align-middle py-2">
                                <!--MODIFY TERR-->
                                <ng-select
                                  [items]="territories"
                                  bindLabel="territoryCode"
                                  bindValue="territoryCode"
                                  labelForId="healthTerritoryCode"
                                  id="healthTerritoryCode"
                                  ngbTooltip="Code territoire santé"
                                  [clearable]="false"
                                  required
                                  formControlName="healthTerritoryCode"
                                  (keyup.enter)="
                                    modifyEffectorTerritory(effectorTerritory)
                                  "
                                  dropdownPosition="top"
                                ></ng-select>
                              </td>
                              <td class="text-muted align-middle py-2">
                                <input
                                  type="date"
                                  class="form-control"
                                  style="height: 2.3rem"
                                  id="inputStartDateUpdate"
                                  aria-describedby="Validity Start date"
                                  formControlName="validityStartDate"
                                  (keyup.enter)="
                                    modifyEffectorTerritory(effectorTerritory)
                                  "
                                  [valueAsDate]="validityStartDateTerEdit"
                                  (change)="
                                    validityStartDateTerEdit =
                                      $event.target.valueAsDate
                                  "
                                  [ngClass]="{
                                    'invalid-date': !isValidityStartDateValid
                                  }"
                                />
                                <div *ngIf="!isValidityStartDateValid">
                                  Ce champ est obligatoire.
                                </div>
                              </td>
                              <td
                                class="text-muted d-none d-md-table-cell align-middle py-2"
                              >
                                <input
                                  type="date"
                                  class="form-control"
                                  style="height: 2.3rem"
                                  id="inputEndDateUpdate"
                                  aria-describedby="Validity Start date "
                                  formControlName="validityEndDate"
                                  (keyup.enter)="
                                    modifyEffectorTerritory(effectorTerritory)
                                  "
                                  [ngClass]="{
                                    'invalid-date':
                                      isEndDateInvalid(effectorTerritory)
                                  }"
                                  min="{{
                                    effectorTerritory.validityStartDate
                                      | date: 'yyyy-MM-dd'
                                  }}"
                                  [(ngModel)]="
                                    effectorTerritory.form.controls
                                      .validityEndDate.value
                                  "
                                  [ngbTooltip]="
                                    isEndDateInvalid(effectorTerritory)
                                      ? 'La date de fin doit être postérieure ou égale à la date de début'
                                      : ''
                                  "
                                  [ngClass]="{
                                    'invalid-date': !isValidityEndDateValid
                                  }"
                                />
                                <div *ngIf="!isValidityEndDateValid">
                                  Ce champ est obligatoire.
                                </div>
                              </td>
                            </ng-container>
                            <!-- Display all data from back -->
                            <ng-container
                              *ngIf="!effectorTerritory.isInEditMode"
                            >
                              <td class="text-muted align-middle">
                                {{ effectorTerritory.healthTerritoryCode }}
                              </td>
                              <td class="text-muted align-middle">
                                {{
                                  effectorTerritory.validityStartDate
                                    | date: 'dd/MM/yyyy'
                                }}
                              </td>
                              <td
                                class="text-muted d-none d-md-table-cell align-middle"
                              >
                                {{
                                  effectorTerritory.validityEndDate
                                    | date: 'dd/MM/yyyy'
                                }}
                              </td>
                            </ng-container>
                            <td class="text-center">
                              <button
                                *ngIf="effectorTerritory.isInEditMode"
                                type="button"
                                ngbTooltip="Ajouter ce territoire"
                                class="btn check btn-primary"
                                (click)="
                                  modifyEffectorTerritory(effectorTerritory)
                                "
                                container="body"
                              ></button>
                              <button
                                *ngIf="effectorTerritory.form.disabled"
                                style="opacity: 0; width: 2.6rem"
                              ></button>
                              <button
                                *ngIf="!effectorTerritory.isOnlyEndDateEditable"
                                type="button"
                                class="btn remove btn-danger"
                                ngbTooltip="Supprimer ce territoire"
                                (click)="
                                  deleteTerritoryAlert(effectorTerritory)
                                "
                                container="body"
                              ></button>
                            </td>
                          </ng-container>
                        </tr>
                      </tbody>
                    </table>
                    <div
                      class="row"
                      style="float: right; margin-right: 0px; margin-left: 0px"
                    >
                      <pagination-controls
                        (pageChange)="page = $event"
                        previousLabel=""
                        nextLabel=""
                        class="pagination"
                      ></pagination-controls>
                    </div>
                    <div class="row">
                      <label for="items" style="margin-left: 0px"
                        >Afficher&nbsp;</label
                      >
                      <select (change)="onTableSizeChange($event)" id="items">
                        <option *ngFor="let size of tableSizes">
                          {{ size }}
                        </option>
                      </select>
                      <label for="items" style="margin-left: 0.2rem"
                        >éléments</label
                      >
                    </div>
                  </div>
                </div>
              </div>

              <!-- Closure container tab 2 -->
            </form>
          </ng-template>
        </ng-container>

        <!-- Tab 3 -->
        <ng-container ngbNavItem>
          <a ngbNavLink>Informations complémentaires</a>
          <ng-template ngbNavContent>
            <form [formGroup]="addressForm">
              <div class="row">
                <div class="col-12 col-md-8">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label for="labelAddress1">Adresse</label>
                        <input
                          type="text"
                          class="form-control mb-2"
                          id="address1"
                          formControlName="labelAddress1"
                          maxlength="200"
                          name="labelAddress1"
                          ngbTooltip="Adresse de la structure"
                        />
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control mb-2"
                          id="address2"
                          formControlName="labelAddress2"
                          maxlength="200"
                          name="labelAddress2"
                          ngbTooltip="Adresse"
                        />
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control mb-2"
                          id="address3"
                          formControlName="labelAddress3"
                          maxlength="200"
                          name="labelAddress3"
                          ngbTooltip="Adresse"
                        />
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control mb-2"
                          id="address4"
                          formControlName="labelAddress4"
                          maxlength="200"
                          name="labelAddress4"
                          ngbTooltip="Adresse"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-4">
                      <div>
                        <label for="postalCode" class="form-control-label"
                          >Code postal</label
                        >
                        <td class="text-muted ngSelectPostalCode">
                          <ng-select
                            [items]="postalCodesSelector"
                            (change)="onCodePostalChanged()"
                            [addTag]="true"
                            bindLabel="name"
                            bindValue="id"
                            labelForId="postalCode"
                            id="postalCode"
                            ngbTooltip="Code postal de la structure"
                            formControlName="postalCode"
                          ></ng-select>
                        </td>
                      </div>
                    </div>
                    <div class="col-12 col-md-8">
                      <div>
                        <label for="labelCity" class="form-control-label"
                          >Commune</label
                        >
                        <td class="text-muted ngSelectLabelCity">
                          <ng-select
                            [items]="communesSelector"
                            (change)="onCommuneChanged()"
                            [addTag]="true"
                            bindLabel="communeLabel"
                            bindValue="communeLabel"
                            labelForId="labelCity"
                            id="labelCity"
                            ngbTooltip="Commune de la structure"
                            formControlName="labelCity"
                          ></ng-select>
                        </td>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label for="phoneNumber">Téléphone</label>
                        <input
                          type="text"
                          class="form-control"
                          id="phoneNumber"
                          formControlName="phoneNumber"
                          maxlength="10"
                          name="phoneNumber"
                          ngbTooltip="Numéro de téléphone"
                        />
                      </div>
                      <div class="form-group">
                        <label for="webSite">Site internet</label>
                        <input
                          type="text"
                          class="form-control"
                          id="webSite"
                          formControlName="webSite"
                          maxlength="200"
                          name="webSite"
                          ngbTooltip="Site internet"
                        />
                      </div>
                      <div class="col-12 pb-4"></div>
                      <div class="row inputCoordinates">
                        <div class="col-6 form-group">
                          <label for="latitudeCode">Latitude</label>
                          <input
                            type="text"
                            class="form-control"
                            id="latitudeCode"
                            formControlName="latitudeCode"
                            maxlength="20"
                            name="latitudeCode"
                            ngbTooltip="Latitude"
                          />
                        </div>
                        <div class="col-6 form-group">
                          <label for="longitudeCode">Longitude</label>
                          <input
                            type="text"
                            class="form-control"
                            id="longitudeCode"
                            formControlName="longitudeCode"
                            maxlength="20"
                            name="longitudeCode"
                            ngbTooltip="Longitude"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-8 mt-4">
                  <div class="row">
                    <div class="col-12 mt-3">
                      <h3 class="mt-2">Horaires d'ouverture</h3>
                    </div>
                    <div class="col-12 col-md-6 mb-2">
                      <div class="form-group">
                        <label for="mondayHours">Lundi</label>
                        <input
                          type="text"
                          class="form-control"
                          id="mondayHours"
                          formControlName="mondayHours"
                          maxlength="100"
                          name="mondayHours"
                          ngbTooltip="Horaires d'ouverture du lundi"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6 mb-2">
                      <div class="form-group">
                        <label for="tuesdayHours">Mardi</label>
                        <input
                          type="text"
                          class="form-control"
                          id="tuesdayHours"
                          formControlName="tuesdayHours"
                          maxlength="100"
                          name="tuesdayHours"
                          ngbTooltip="Horaires d'ouverture du mardi"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-6 mb-2">
                      <div class="form-group">
                        <label for="wednesdayHours">Mercredi</label>
                        <input
                          type="text"
                          class="form-control"
                          id="wednesdayHours"
                          formControlName="wednesdayHours"
                          maxlength="100"
                          name="wednesdayHours"
                          ngbTooltip="Horaires d'ouverture du mercredi"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6 mb-2">
                      <div class="form-group">
                        <label for="thursdayHours">Jeudi</label>
                        <input
                          type="text"
                          class="form-control"
                          id="thursdayHours"
                          formControlName="thursdayHours"
                          maxlength="100"
                          name="thursdayHours"
                          ngbTooltip="Horaires d'ouverture du jeudi"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-6 mb-2">
                      <div class="form-group">
                        <label for="fridayHours">Vendredi</label>
                        <input
                          type="text"
                          class="form-control"
                          id="fridayHours"
                          formControlName="fridayHours"
                          maxlength="100"
                          name="fridayHours"
                          ngbTooltip="Horaires d'ouverture du vendredi"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6 mb-2">
                      <div class="form-group">
                        <label for="saturdayHours">Samedi</label>
                        <input
                          type="text"
                          class="form-control"
                          id="saturdayHours"
                          formControlName="saturdayHours"
                          maxlength="100"
                          name="saturdayHours"
                          ngbTooltip="Horaires d'ouverture du samedi"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-6 mb-2">
                      <div class="form-group">
                        <label for="sundayHours">Dimanche</label>
                        <input
                          type="text"
                          class="form-control"
                          id="sundayHours"
                          formControlName="sundayHours"
                          maxlength="100"
                          name="sundayHours"
                          ngbTooltip="Horaires d'ouverture du dimanche"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6 mb-2">
                      <div class="form-group">
                        <label for="holidaysHours">Jour férié</label>
                        <input
                          type="text"
                          class="form-control"
                          id="holidaysHours"
                          formControlName="holidaysHours"
                          maxlength="100"
                          name="holidaysHours"
                          ngbTooltip="Horaires d'ouverture lors des jours fériés"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4 mt-5 pt-2">
                  <div class="mb-3 mt-md-2">
                    <label for="comment" class="form-label">Commentaires</label>
                    <textarea
                      class="form-control overflow-auto"
                      style="height: 10rem"
                      id="comment"
                      formControlName="comment"
                      maxlength="200"
                      name="comment"
                      rows="3"
                    ></textarea>
                  </div>
                </div>
              </div>
            </form>
          </ng-template>
        </ng-container>
      </nav>

      <div [ngbNavOutlet]="nav" class="mt-2"></div>
      <!-- form-footer -->
      <div class="form-footer">
        <div class="row">
          <div class="col-md-12">
            <button
              (click)="saveFormAlert()"
              type="button"
              class="btn save"
              name="Enregistrer"
              ngbTooltip="Enregister les données"
              appDisableDoubleClick
            >
              Enregistrer
            </button>

            <button
              type="button"
              class="btn cancel"
              ngbTooltip="Quitter le formulaire sans enregistrer les données"
              (click)="cancelFormAlert()"
            >
              Annuler
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
