import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
  selector: "app-iframe-test",
  templateUrl: "./iframe-test.component.html",
  styleUrls: ["./iframe-test.component.scss"],
})
export class IframeTestComponent implements OnInit {
  ngOnInit() {}
}
