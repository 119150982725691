import {Title} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {Inject, Injectable} from '@angular/core';
import {PiwikProWindow} from '../piwik-pro-window.interface';
import {DOCUMENT} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class PiwikPaqService {
  window: PiwikProWindow | null;

  constructor(
              private titleService: Title,
              private routerService: Router,
              @Inject(DOCUMENT) private document: Document,
  ) {
    this.titleService = titleService;
    this.routerService = routerService;
    this.window = this.document.defaultView;
  }

  push(collection) {
    if (!this.window?._paq) {
      throw new Error('_paq is not available');
    }
    this.window._paq.push(['setCustomUrl', this.routerService.url]);
    this.window._paq.push(['setDocumentTitle', this.titleService.getTitle()]);
    return this.window._paq.push(collection);
  }
}
