<form
  [formGroup]="mobileDecisionTypeRangeForm"
  (ngSubmit)="onSubmit(eventTypes.CLOSE)"
  autocomplete="off"
>
  <div class="table-responsive">
    <table class="table table-striped shadow-sm p-3 bg-white">
      <thead>
        <tr class="noselect header-row">
          <th
            class="fontSize"
            style="
              max-width: 15vw;
              width: 15vw;
              padding: 0px;
              margin: 0px;
              font-weight: normal;
            "
          >
            Nombre de décisions
          </th>

          <th
            *ngFor="let timeSlotLabel of timeSlotLabels; let i = index"
            style="max-width: 4vw; margin: 0px"
          >
            <p style="color: #ffffff">{{ timeSlotLabel }}</p>
            <p style="color: #ffffff">{{ timeSlotDayTypes[i] }}</p>
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container
          *ngFor="let decisionTypeLabel of decisionTypeLabels; let i = index"
        >
          <tr class="cursor noselect hover-effect">
            <td class="ml-2">{{ decisionTypeLabel }}</td>

            <ng-container
              *ngFor="
                let elementGroup of mobileDecisionTypeRangeForm.get('elements')
                  ?.controls;
                let j = index
              "
            >
              <!-- Utilisez la variable i pour accéder aux données de manière appropriée -->
              <ng-container
                *ngIf="
                  elementGroup.get('elements')?.controls[i] as innerElementGroup
                "
              >
                <td>
                  <div class="d-flex justify-content-center align-items-center">
                    <input
                      type="text"
                      class="rounded border inputSize"
                      [ngClass]="{
                        error: elementGroup
                          .get('elements')
                          ?.controls[i].get('count')
                          .hasError('invalidValue'),
                        warning: elementGroup
                          .get('elements')
                          ?.controls[i].get('count')
                          .hasError('emptyValue')
                      }"
                      style="width: 4vw; height: 2vw"
                      [formControl]="innerElementGroup.get('count')"
                      (input)="this.onInputChange()"
                    />
                  </div>
                </td>
              </ng-container>
            </ng-container>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <textarea
    class="commentInput"
    *ngIf="openCommentLet && !hasCommentEntered && isBooleanPlatform"
    formControlName="comment"
    maxlength="450"
    placeholder="Si vous le souhaitez, saisissez ICI votre commentaire puis validez votre saisie..."
  ></textarea>
  <div *ngIf="!hasCommentEntered" class="desktopDisplay">
    <div class="col-12 d-flex justify-content-end heightContaineurComment">
      <ng-container *ngIf="!openCommentLet">
        <img
          src="../../../assets/images/comment.svg"
          alt="comment"
          class="comment"
          placement="left"
          (click)="openComment()"
        />
        <span class="ml-2 commentTextSpecifications"
          >Ajouter un commentaire</span
        >
      </ng-container>
    </div>
  </div>
</form>
