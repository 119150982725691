import { format } from 'date-fns';

export enum StateInputPdsaEnum {
  OPEN = 1,
  CLOSED = 2
}
export interface StateInputPdsa {
  idMonth: number;
  formNumber: number;
  state: StateInputPdsaEnum;
}
export class StateInputPdsaClass implements StateInputPdsa {
  idMonth: number;
  formNumber: number;
  state: StateInputPdsaEnum;

  get formStringByFormNumber(): string {
    switch (this.formNumber) {
      case 1:
        return 'tab_one';
      case 2:
        return 'tab_two';
      case 3:
        return 'tab_three';
      case 4:
        return 'tab_four';
      case 5:
        return 'tab_five';
      case 6:
        return 'tab_six';
      default:
        return 'tab_one';
    }
  }

  static fromStateInputPdsa(stateInput: StateInputPdsa): StateInputPdsaClass {
    return new StateInputPdsaClass(
      stateInput.idMonth,
      stateInput.formNumber,
      stateInput.state
    );
  }

  constructor(idMonth: number, formNumber: number, state: number) {
    this.idMonth = idMonth;
    this.formNumber = formNumber;
    this.state = state;
  }

  static monthIdFor(date: Date): number {
    // Yes, we are converting it into an int because into the BDD it's an int field
    return Number(format(date, 'yyyyMM'));
  }
}
