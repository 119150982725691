import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { NgbdSortableHeader } from 'src/app/directive/sortable/sortable.directive';
import { User } from 'src/app/shared/models/user.model';
import Swal from 'sweetalert2';

import { AppRoutes } from '../../../emergency-depts/routes.enum';
import { NgSelectModel } from '../../../shared/models/ng-select.model';
import { DateHelper } from '../../../shared/util/date.helper';
import { AvailabilitiesClass } from '../..//model/availabilities.model';
import { Commune } from '../..//model/communes.model';
import { EffectorForm } from '../../model/effector-form.model';
import { EffectorFormTerritory } from '../../model/effector-form-territory.model';
import { EffectorClass } from '../../model/effectors.model';
import { EffectorsTerritory } from '../../model/effectors-territory.model';
import { EffectorTerritoryTableLine } from '../../model/effectors-territory-table-line.model';
import { EffectorsAddress } from '../../model/effectorsAddress.model';
import { Territory } from '../../model/territory.model';
import { AvailabilitiesService } from '../../service/availabilities.service';
import { CommunesService } from '../../service/communes.service';
import { EffectorsFormService } from '../../service/effectors-form.service';
import { EffectorsTerritoryService } from '../../service/effectors-territory.service';
import { EffectorsAddressService } from '../../service/effectorsAddress.service';
import { TerritoryService } from '../../service/territory.service';
import { EffectorFormValidators } from '../../validators/effector-form-validators';
@Component({
  selector: 'app-effectors-form',
  templateUrl: './effectors-form.component.html',
  styleUrls: ['./effectors-form.component.scss']
})
export class EffectorsFormComponent implements OnInit {
  startDate: Date;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private effectorsFormService: EffectorsFormService,
    private availabilitiesService: AvailabilitiesService,
    private effectorsTerritoryService: EffectorsTerritoryService,
    private effectorsAddressService: EffectorsAddressService,
    private communeService: CommunesService,
    private fb: FormBuilder,
    private territoryService: TerritoryService
  ) {}

  // Code for editable table
  effectorsTerritoryTableLine: EffectorTerritoryTableLine[] = [];
  effectorTerritory: EffectorsTerritory[] = [];
  newEffectorsTerritoryForm: FormGroup = this.fb.group(
    {
      healthTerritoryCode: [null, Validators.required],
      validityStartDate: [null, Validators.required],
      validityEndDate: [null, Validators.required],
      validityBoolean: [true]
    },
    { validators: EffectorFormValidators.validateDateRange() }
  );

  idEffecteur?: number;
  availabilities: AvailabilitiesClass[] = [];
  effector: EffectorClass;
  validityStartDate: Date;
  validityStartDateTer: Date;
  validityStartDateTerEdit: Date;

  typePoste = [
    { id: 'MMG', name: 'Maison médicale de garde' },
    { id: 'PFIX', name: 'Point fixe de garde' },
    { id: 'CABT', name: 'Cabinet libéral tournant' }
  ];

  departmentCode = [
    { id: '75', name: '75' },
    { id: '77', name: '77' },
    { id: '78', name: '78' },
    { id: '91', name: '91' },
    { id: '92', name: '92' },
    { id: '93', name: '93' },
    { id: '94', name: '94' },
    { id: '95', name: '95' }
  ];

  // MENU DEROULANT AVEC DONNEES DE LA BDD
  healthTerritoryCode: NgSelectModel[] = [];
  public territories: Territory[] = [];

  communes: Commune[] = [];
  communesSelector: Commune[] = [];
  postalCodesSelector: NgSelectModel[] = [];

  // ITEMS PER PAGE
  page = 1;
  count = 0;
  tableSize = 10;
  tableSizes: number[] = [10, 20, 50];

  // SORTING
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  public users: User[] = [];

  // VERIFY DATA FOR TERRITORY INPUT
  isHealthTerritoryCodeValid: boolean = true;
  isValidityStartDateValid: boolean = true;
  isValidityEndDateValid: boolean = true;

  // Construction of FormGroup for the table T_EFFECTEUR
  effectorsForm: FormGroup = this.fb.group(
    {
      effectorArsLabel: [
        null,
        [Validators.required, Validators.maxLength(200)]
      ],
      effectorLabel: [null, [Validators.required, Validators.maxLength(200)]],
      effectorShortLabel: [
        null,
        [Validators.required, Validators.maxLength(50)]
      ],
      domainCode: ['MOB', [Validators.required, Validators.maxLength(5)]],
      typeStructureCode: [null, Validators.maxLength(20)],
      sirenNumber: [null],
      siretNumber: [null],
      finessCode: [null],
      departmentCode: [null, [Validators.required, Validators.maxLength(5)]],
      managerName: [null, Validators.maxLength(200)],
      managingEntityName: [null, Validators.maxLength(200)],
      platformBoolean: [false, Validators.required],
      callFlowBoolean: [false, Validators.required],
      fileFlowBoolean: [false, Validators.required],
      validityStartDate: [null, Validators.required],
      validityEndDate: ['9999-12-31', Validators.required],
      ccrBoolean: [false, Validators.required],
      flowTransmissionBoolean: [false],
      effectorCodeMet: ['', Validators.maxLength(50)],
      firstTransmissionDate: [null],
      callTransmissionDate: [null],
      visitTransmissionDate: [null]
    },
    {
      validators: [
        EffectorFormValidators.validateDateRange(),
        EffectorFormValidators.validateCodeMetier()
      ]
    }
  );

  availabilitiesForm: FormArray = this.fb.array([]);

  addressForm: FormGroup = this.fb.group({
    labelAddress1: [null, Validators.maxLength(200)],
    labelAddress2: [null, Validators.maxLength(200)],
    labelAddress3: [null, Validators.maxLength(200)],
    labelAddress4: [null, Validators.maxLength(200)],
    postalCode: [null, Validators.maxLength(20)],
    labelCity: [null, Validators.maxLength(200)],
    phoneNumber: [null, Validators.maxLength(10)],
    email: [null, Validators.maxLength(200)],
    latitudeCode: [null, Validators.maxLength(20)],
    longitudeCode: [null, Validators.maxLength(20)],
    webSite: [null, Validators.maxLength(200)],
    comment: [null, Validators.maxLength(200)],
    mondayHours: [null, Validators.maxLength(100)],
    tuesdayHours: [null, Validators.maxLength(100)],
    wednesdayHours: [null, Validators.maxLength(100)],
    thursdayHours: [null, Validators.maxLength(100)],
    fridayHours: [null, Validators.maxLength(100)],
    saturdayHours: [null, Validators.maxLength(100)],
    sundayHours: [null, Validators.maxLength(100)],
    holidaysHours: [null, Validators.maxLength(100)],
    id: null
  });

  // I create a formGroup that contains the two formGroup of the table T_EFFECTEUR and T_TRANCHE_HORAIRE
  parentForm: FormGroup = this.fb.group({
    effectorsForm: this.effectorsForm,
    availabilitiesForm: this.availabilitiesForm
  });

  changeRotate = {
    healthTerritoryCode: true,
    validityStartDate: true,
    validityEndDate: true,
    validityBoolean: true
  };

  title = 'appBootstrap';

  closeResult = '';

  async generateAvailabilities() {
    if (this.idEffecteur) {
      await new Promise((res, rej) => {
        this.effectorsFormService
          .getEffectorsById(this.idEffecteur as number)
          .pipe(map((e) => new EffectorClass(e)))
          .subscribe((effector) => {
            res(effector);
          });
      });
      const availabilitiesRequest = forkJoin([
        this.availabilitiesService.getByCodDom(
          this.effectorsForm.get('domainCode')?.value
        ),
        this.availabilitiesService.getEffectorAvailabilities(this.idEffecteur)
      ]);

      availabilitiesRequest.subscribe((results) => {
        this.availabilitiesForm.controls = results[0].map((a) =>
          this.fb.group({
            id: a.id,
            validityBoolean:
              results[1].find((e) => e.id === a.id)?.validityBoolean ?? false
          })
        );
        this.availabilities = results[0].map((a) => new AvailabilitiesClass(a));
      });
    } else {
      this.availabilitiesService
        .getByCodDom(this.effectorsForm.get('domainCode')?.value)
        .subscribe((availabilities) => {
          this.availabilitiesForm.controls = availabilities.map((a) =>
            this.fb.group({
              id: a.id,
              validityBoolean: true
            })
          );
          this.availabilities = availabilities.map(
            (av) => new AvailabilitiesClass(av)
          );
        });
    }
  }

  initCommune(): void {
    this.communeService.getCommunesIDF().subscribe((communes) => {
      this.communes = communes;
      this.onCodePostalChanged();
      this.onCommuneChanged();
    });
  }

  onCommuneChanged(): void {
    const c = this.communes.find(
      (c) => c.communeLabel === this.addressForm.get('labelCity')?.value
    );
    if (c !== undefined) {
      this.postalCodesSelector = [
        new NgSelectModel(c.postalCode, c.postalCode)
      ];
      this.addressForm
        .get('postalCode')
        ?.setValue(c.postalCode, { emitEvent: false });
    } else {
      this.postalCodesSelector = Array.from(
        new Set(this.communes.map((c) => c.postalCode))
      ).map((c) => new NgSelectModel(c, c));
      this.addressForm.get('postalCode')?.setValue(null, { emitEvent: false });
    }
  }

  onCodePostalChanged(): void {
    const cp = this.addressForm.get('postalCode')?.value;
    if (cp != null) {
      this.communesSelector = this.communes.filter((c) => c.postalCode === cp);
    } else {
      this.communesSelector = this.communes;
      this.postalCodesSelector = Array.from(
        new Set(this.communes.map((c) => c.postalCode))
      ).map((c) => new NgSelectModel(c, c));
      this.addressForm.get('labelCity')?.setValue(null, { emitEvent: false });
    }
  }

  ngOnInit(): void {
    this.newEffectorsTerritoryForm.disable();

    this.territoryService.getTerritory().subscribe((territories) => {
      this.territories = territories.filter(
        (territory) =>
          territory.territoryCode != '-1' && territory.territoryCode != '-2'
      );
    });

    // We recup the information of the url effectorsForm/:id
    this.route.paramMap.subscribe((paramMap) => {
      this.idEffecteur =
        Number(paramMap.get('id')) === 0
          ? undefined
          : Number(paramMap.get('id'));
      this.effectorsForm
        .get('effectorCodeMet')
        ?.addAsyncValidators(
          EffectorFormValidators.codeMetValidator(
            this.effectorsFormService,
            this.idEffecteur
          )
        );

      // If the id is not null we display the information of the effectorsForm else we display a new form
      // To set the values, we use the patchValue method because this method can exclude some controls witch is not the case of setValue
      if (this.idEffecteur != null) {
        this.effectorsFormService
          .getEffectorsById(this.idEffecteur)
          .pipe(map((e) => new EffectorClass(e)))
          .subscribe((effector: EffectorClass) => {
            this.effectorsForm.patchValue({
              id: effector.id,
              effectorArsLabel: effector.effectorArsLabel,
              effectorLabel: effector.effectorLabel,
              effectorShortLabel: effector.effectorShortLabel,
              domainCode: effector.domainCode,
              typeStructureCode: effector.typeStructureCode,
              departmentCode: effector.departmentCode,
              managerName: effector.managerName,
              managingEntityName: effector.managingEntityName,
              platformBoolean: effector.platformBoolean,
              callFlowBoolean: effector.callFlowBoolean,
              fileFlowBoolean: effector.fileFlowBoolean,
              validityStartDate: DateHelper.toLocalDate(
                effector.validityStartDate
              ),
              validityEndDate: DateHelper.toLocalDate(effector.validityEndDate),
              userId: effector.userId,
              ccrBoolean: !effector.ccrBoolean,
              validityBoolean: effector.validityBoolean,
              flowTransmissionBoolean: effector.flowTransmissionBoolean,
              firstTransmissionDate: DateHelper.toLocalDate(
                effector.firstTransmissionDate
              ),
              callTransmissionDate: DateHelper.toLocalDate(
                effector.callTransmissionDate
              ),
              visitTransmissionDate: DateHelper.toLocalDate(
                effector.visitTransmissionDate
              ),
              effectorCodeMet: effector.effectorCodeMet
            });
            const dateNow: Date = new Date();
            if (
              effector.validityStartDate <
              new Date(dateNow.setHours(0, 0, 0, 0))
            ) {
              this.effectorsForm.get('validityStartDate')?.disable();
              this.startDate = new Date(effector.validityStartDate);
            }

            this.onDomainCodeChanged();
            this.onFlowChanged();
          });

        this.effectorsAddressService
          .getEffectorsAddressById(this.idEffecteur)
          .subscribe((effectorAddress: EffectorsAddress) => {
            this.addressForm.patchValue({
              id: effectorAddress.id,
              labelAddress1: effectorAddress.labelAddress1,
              labelAddress2: effectorAddress.labelAddress2,
              labelAddress3: effectorAddress.labelAddress3,
              labelAddress4: effectorAddress.labelAddress4,
              postalCode: effectorAddress.postalCode,
              labelCity: effectorAddress.labelCity,
              phoneNumber: effectorAddress.phoneNumber,
              email: effectorAddress.email,
              latitudeCode: effectorAddress.latitudeCode,
              longitudeCode: effectorAddress.longitudeCode,
              webSite: effectorAddress.webSite,
              comment: effectorAddress.comment,
              mondayHours: effectorAddress.mondayHours,
              tuesdayHours: effectorAddress.tuesdayHours,
              wednesdayHours: effectorAddress.wednesdayHours,
              thursdayHours: effectorAddress.thursdayHours,
              fridayHours: effectorAddress.fridayHours,
              saturdayHours: effectorAddress.saturdayHours,
              sundayHours: effectorAddress.sundayHours,
              holidaysHours: effectorAddress.holidaysHours
            });

            this.initCommune();
          });
      } else {
        this.onDomainCodeChanged('MOB');
        this.initCommune();
      }

      this.displayEffectorsTerritory();
    });

    if (this.idEffecteur != null) {
      this.effectorsFormService
        .getUsersByEffectorId(this.idEffecteur)
        .subscribe((users: User[]) => {
          this.users = users;
        });
    }
  }

  onDomainCodeChanged(defaultValue?: string) {
    if (defaultValue != null) {
      this.effectorsForm.get('domainCode')?.setValue(defaultValue);
    }
    const typeStructureCode = this.effectorsForm.get('typeStructureCode');
    const platformBoolean = this.effectorsForm.get('platformBoolean');
    const callFlowBoolean = this.effectorsForm.get('callFlowBoolean');
    const fileFlowBoolean = this.effectorsForm.get('fileFlowBoolean');
    const flowTransmissionBoolean = this.effectorsForm.get(
      'flowTransmissionBoolean'
    );
    typeStructureCode?.clearValidators();
    if (this.effectorsForm.get('domainCode')?.value === 'POS') {
      typeStructureCode?.addValidators(Validators.required);
      platformBoolean?.setValue(false);
      callFlowBoolean?.setValue(false);
      fileFlowBoolean?.setValue(false);
    }
    if (this.effectorsForm.get('domainCode')?.value === 'MOB') {
      flowTransmissionBoolean?.setValue(false);
      typeStructureCode?.setValue('-2');
    }
    this.onFlowChanged();
    this.generateAvailabilities();
  }

  onFlowChanged() {
    const effectorCodeMet = this.effectorsForm.get('effectorCodeMet');
    effectorCodeMet?.clearValidators();
    this.effectorsForm.get('firstTransmissionDate')?.clearValidators();
    this.effectorsForm.get('visitTransmissionDate')?.clearValidators();
    this.effectorsForm.get('callTransmissionDate')?.clearValidators();
    if (
      this.effectorsForm.get('domainCode')?.value === 'POS' &&
      this.effectorsForm.get('flowTransmissionBoolean')?.value === true
    ) {
      effectorCodeMet?.addValidators(Validators.required);
    }
    if (
      this.effectorsForm.get('domainCode')?.value === 'MOB' &&
      (this.effectorsForm.get('fileFlowBoolean')?.value === true ||
        this.effectorsForm.get('callFlowBoolean')?.value === true)
    ) {
      effectorCodeMet?.addValidators(Validators.required);
    }
    effectorCodeMet?.updateValueAndValidity();
    if (
      this.effectorsForm.get('domainCode')?.value === 'POS' &&
      this.effectorsForm.get('flowTransmissionBoolean')?.value === true
    ) {
      this.effectorsForm
        .get('firstTransmissionDate')
        ?.addValidators(Validators.required);
    } else {
      this.effectorsForm.get('firstTransmissionDate')?.clearValidators();
      this.effectorsForm.get('firstTransmissionDate')?.setValue(null);
    }
    this.effectorsForm.get('firstTransmissionDate')?.updateValueAndValidity();

    if (
      this.effectorsForm.get('domainCode')?.value === 'MOB' &&
      this.effectorsForm.get('fileFlowBoolean')?.value === true
    ) {
      this.effectorsForm
        .get('visitTransmissionDate')
        ?.addValidators(Validators.required);
    } else {
      this.effectorsForm.get('visitTransmissionDate')?.clearValidators();
      this.effectorsForm.get('visitTransmissionDate')?.setValue(null);
    }
    this.effectorsForm.get('visitTransmissionDate')?.updateValueAndValidity();

    if (
      this.effectorsForm.get('domainCode')?.value === 'MOB' &&
      this.effectorsForm.get('callFlowBoolean')?.value === true
    ) {
      this.effectorsForm
        .get('callTransmissionDate')
        ?.addValidators(Validators.required);
    } else {
      this.effectorsForm.get('callTransmissionDate')?.clearValidators();
      this.effectorsForm.get('callTransmissionDate')?.setValue(null);
    }
    this.effectorsForm.get('callTransmissionDate')?.updateValueAndValidity();
  }

  // Function to display data from database (T_EFFECTEURS_TERRITOIRES) into the table "TERRITOIRE(S) DE RATTACHEMENT"
  //WORK HERE CHLOE
  displayEffectorsTerritory() {
    if (this.idEffecteur) {
      this.effectorsTerritoryService
        .getEffectorsTerritoryByEffectorId(this.idEffecteur)
        .subscribe((effectors) => {
          this.effectorsTerritoryTableLine = effectors.map((e) => {
            return EffectorTerritoryTableLine.fromEffectorTerritory(e);
          });
        });
    }

    this.effectorsTerritoryService
      .getEffectorsTerritorys()
      .subscribe((effectors) => {
        this.healthTerritoryCode =
          NgSelectModel.fromEffectorsTerritorys(effectors);
      });
  }

  // DISPLAY TITLE FUNCTION
  getTitle() {
    return this.idEffecteur != null
      ? this.effectorsForm.value.effectorShortLabel
      : 'Création d’une structure PDSA';
  }

  isEffectorTerritoryTableLineValid(
    effectorsTerritoryTableLine: EffectorTerritoryTableLine[]
  ) {
    for (const line of effectorsTerritoryTableLine) {
      if (line.validityStartDate > line.validityEndDate) {
        return false;
      }
    }
    return true;
  }

  // Functions to save (POST) or update (PUT) form
  onSaveForm() {
    if (
      this.effectorsForm.valid &&
      this.availabilitiesForm.valid &&
      this.addressForm.valid
    ) {
      if (!this.effectorsForm.value.validityStartDate) {
        this.effectorsForm.value.validityStartDate = this.startDate;
      }
      if (
        this.effectorsForm.value.validityStartDate >
        new Date(this.effectorsForm.value.validityEndDate)
      ) {
        Swal.fire({
          icon: 'warning',
          title: 'Attention !',
          text: 'La date de début de validité doit être inférieure à la date de fin de validité.'
        });
      } else {
        this.effectorsForm.value.ccrBoolean =
          !this.effectorsForm.value.ccrBoolean;
        const effector = EffectorForm.fromEffectorForm(
          this.effectorsForm,
          this.availabilitiesForm,
          this.addressForm,
          this.effectorsTerritoryTableLine
        );
        if (this.idEffecteur == null) {
          this.sendNewEffector(effector);
        } else {
          this.sendModifyEffector(effector);
        }
      }
    } else {
      this.controlsForm();
      if (
        this.effectorsForm.value.validityStartDate >
        this.effectorsForm.value.validityEndDate
      ) {
        Swal.fire({
          icon: 'warning',
          title: 'Attention !',
          text: 'La date de début de validité doit être inférieure à la date de fin de validité.'
        });
      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Attention !',
          html: `<p>Veillez à remplir tous les champs obligatoires avant de valider votre saisie :</p><ul>${this.errorsData.join(
            ''
          )}</ul>`
        });
        this.errorsData = [];
      }
    }
  }

  public errorsData: any[] = [];
  controlsForm() {
    for (const el in this.effectorsForm.controls) {
      if (this.effectorsForm.controls[el].errors) {
        switch (el) {
          case 'effectorArsLabel':
            this.errorsData.push('<li>Libellé ARS de la structure</li>');
            break;
          case 'effectorLabel':
            this.errorsData.push('<li>Libellé de la structure</li>');
            break;
          case 'effectorShortLabel':
            this.errorsData.push('<li>Libellé court de la structure</li>');
            break;
          case 'validityStartDate':
            this.errorsData.push('<li>Date de début de validité</li>');
            break;
          case 'validityEndDate':
            this.errorsData.push('<li>Date de fin de validité</li>');
            break;
          case 'departmentCode':
            this.errorsData.push('<li>Code département</li>');
            break;
          case 'typeStructureCode':
            this.errorsData.push('<li>Type de posté</li>');
            break;
          case 'firstTransmissionDate':
            this.errorsData.push(
              '<li>Date de première transmission du flux consultation</li>'
            );
            break;
          case 'callTransmissionDate':
            this.errorsData.push(
              '<li>Date de première transmission du flux appel</li>'
            );
            break;
          case 'visitTransmissionDate':
            this.errorsData.push(
              '<li>Date de première transmission du flux visite</li>'
            );
            break;

          default:
            '';
        }
      }
    }
  }

  sendNewEffector(effector: EffectorForm) {
    this.effectorsFormService.putEffectorForm(effector).subscribe(
      () => {
        this.router.navigateByUrl(AppRoutes.PDSA_SETTINGS);
        Swal.fire('Effecteur créé avec succès !', '', 'success');
      },
      (error) => {
        Object.keys(this.effectorsForm.controls).forEach((key) => {
          this.effectorsForm.controls[key].setErrors({ incorrect: true });
        });
        // POP-UP ERROR
        Swal.fire({
          icon: 'error',
          title: 'Attention !',
          text: 'Impossible de créer un nouvel effecteur. Veuillez vérifier vos saisies avant de valider le formulaire.'
        });
      }
    );
  }

  sendModifyEffector(effector: EffectorForm) {
    effector.id = this.idEffecteur;

    this.effectorsFormService.postEffectorForm(effector).subscribe(
      () => {
        this.router.navigateByUrl(AppRoutes.PDSA_SETTINGS);
        Swal.fire('Effecteur modifié avec succès !', '', 'success');
      },
      (error) => {
        Swal.fire({
          icon: 'warning',
          title: 'Attention !',
          text: 'La modification semble invalide ! Veuillez vérifier vos saisies avant de valider le formulaire.'
        });
      }
    );
  }

  // ITEMS PER PAGE
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
  }

  changeStyle(column: string) {
    this.changeRotate[column] = !this.changeRotate[column];
  }

  isDisabled() {
    return this.effectorsForm.get('domainCode')?.value === 'MOB';
  }

  isDuplicateEffector(newEffector) {
    for (const existingEffector of this.effectorsTerritoryTableLine) {
      const newEffectorValidityStartDate = new Date(
        newEffector.validityStartDate
      );
      const newEffectorValidityEndDate = new Date(newEffector.validityEndDate);
      if (
        existingEffector.healthTerritoryCode ===
          newEffector.healthTerritoryCode &&
        ((newEffectorValidityStartDate >= existingEffector.validityStartDate &&
          newEffectorValidityStartDate <= existingEffector.validityEndDate) ||
          (newEffectorValidityEndDate >= existingEffector.validityStartDate &&
            newEffectorValidityEndDate <= existingEffector.validityEndDate) ||
          (newEffectorValidityStartDate <= existingEffector.validityStartDate &&
            newEffectorValidityEndDate >= existingEffector.validityEndDate))
      ) {
        return true;
      }
    }
    return false;
  }

  isDuplicateEffectorTerritory(
    startDate,
    endDate,
    healthTerritoryCode,
    effector
  ) {
    for (const existingEffector of this.effectorsTerritoryTableLine) {
      const newEffectorValidityStartDate = new Date(startDate);
      const newEffectorValidityEndDate = new Date(endDate);

      if (
        effector != existingEffector &&
        existingEffector.healthTerritoryCode === healthTerritoryCode &&
        ((newEffectorValidityStartDate >= existingEffector.validityStartDate &&
          newEffectorValidityStartDate <= existingEffector.validityEndDate) ||
          (newEffectorValidityEndDate >= existingEffector.validityStartDate &&
            newEffectorValidityEndDate <= existingEffector.validityEndDate) ||
          (newEffectorValidityStartDate <= existingEffector.validityStartDate &&
            newEffectorValidityEndDate >= existingEffector.validityEndDate))
      ) {
        return true;
      }
    }
    return false;
  }

  // Code for editable angular table
  //4347 : add id temporaire
  //add property sequence = 0 =>
  private sequence = 0;

  private getNextSequence() {
    this.sequence += 1;
    return this.sequence;
  }

  //call getNextSequence => create new field

  addNewEffectorTerritory() {
    if (this.isEndDateInvalidCreation()) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Les dates de début et de fin de validité ne sont pas cohérentes !'
      });
      return;
    }
    this.isHealthTerritoryCodeValid = true;
    this.isValidityStartDateValid = true;
    this.isValidityEndDateValid = true;
    if (!this.newEffectorsTerritoryForm.controls.healthTerritoryCode.value) {
      this.isHealthTerritoryCodeValid = false;
    }
    if (!this.newEffectorsTerritoryForm.controls.validityStartDate.value) {
      this.isValidityStartDateValid = false;
    }
    if (!this.newEffectorsTerritoryForm.controls.validityEndDate.value) {
      this.isValidityEndDateValid = false;
    }
    if (this.newEffectorsTerritoryForm.valid) {
      const effector = EffectorFormTerritory.fromFormGroup(
        this.newEffectorsTerritoryForm
      );
      if (!this.isDuplicateEffector(effector)) {
        this.effectorsTerritoryTableLine.push(
          EffectorTerritoryTableLine.fromEffectorFormTerritory(effector)
        );
        this.resetNewEffectorTerritory();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Ce territoire existe déjà sur la période sélectionnée !'
        });
      }
    }
  }

  resetNewEffectorTerritory() {
    //here when valid territory
    this.newEffectorsTerritoryForm.reset();
    this.newEffectorsTerritoryForm.get('validityBoolean')?.setValue(true);
    this.newEffectorsTerritoryForm.disable();
  }

  enableEffectorTerritoryForm(effectorTerritory: EffectorTerritoryTableLine) {
    effectorTerritory.isInEditMode = true;
  }

  removeEffectorTerritory(effector: EffectorTerritoryTableLine) {
    if (effector.toCreate) {
      this.effectorsTerritoryTableLine =
        this.effectorsTerritoryTableLine.filter(
          (item) => item.sequence !== effector.sequence
        );
    } else {
      effector.toDelete = true;
    }
  }

  modifyEffectorTerritory(effector: EffectorTerritoryTableLine) {
    if (this.isEndDateInvalid(effector)) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Les dates de début et de fin de validité ne sont pas cohérentes !'
      });
      return;
    }
    const inputStartDate = (
      document.getElementById('inputStartDateUpdate') as HTMLInputElement
    ).value;
    const inputEndDate = (
      document.getElementById('inputEndDateUpdate') as HTMLInputElement
    ).value;
    const healthTerritoryCodeInput = document.getElementById(
      'healthTerritoryCode'
    ) as HTMLInputElement;
    const healthTerritoryCodeElement =
      healthTerritoryCodeInput.getElementsByClassName('ng-value-label');
    const healthTerritoryCode = healthTerritoryCodeElement[0].innerHTML;

    const endDate: Date = new Date(inputEndDate);
    const startDate: Date = new Date(inputStartDate);

    if (effector.form.valid) {
      if (
        !this.isDuplicateEffectorTerritory(
          startDate,
          endDate,
          healthTerritoryCode,
          effector
        )
      ) {
        effector.updateFromForm(effector);
        effector.isInEditMode = false;
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Ce territoire existe déjà sur la période sélectionnée !'
        });
      }
    }
  }

  saveFormAlert() {
    Swal.fire({
      title: 'ATTENTION !',
      text: 'Les données entrées seront sauvegardées. Voulez-vous continuer ?',
      showDenyButton: true,
      confirmButtonText: 'Oui',
      denyButtonText: `Non`
    }).then((result) => {
      if (result.isConfirmed) {
        this.onSaveForm();
      }
    });
  }
  cancelFormAlert() {
    Swal.fire({
      title: 'ATTENTION !',
      text: "Vous allez quitter le formulaire sans l'avoir sauvegardé. Les données entrées seront perdues. Voulez-vous continuer ?",
      showDenyButton: true,
      confirmButtonText: 'Oui',
      denyButtonText: `Non`
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigateByUrl(AppRoutes.PDSA_SETTINGS);
      }
    });
  }

  // ALERTS
  deleteTerritoryAlert(effector: EffectorTerritoryTableLine) {
    Swal.fire({
      title: 'ATTENTION !',
      text: 'Le territoire va être supprimé: confirmez vous la suppression ?',
      showDenyButton: true,
      confirmButtonText: 'Oui',
      denyButtonText: `Non`
    }).then((result) => {
      if (result.isConfirmed) {
        this.removeEffectorTerritory(effector);
      }
    });
  }

  isEndDateInvalid(effectorTerritory): boolean {
    const validityStartDate = new Date(
      effectorTerritory.form.controls.validityStartDate.value
    );
    const validityEndDate = new Date(
      effectorTerritory.form.controls.validityEndDate.value
    );
    return validityEndDate < validityStartDate;
  }

  isEndDateInvalidCreation(): boolean {
    const inputStartDate = (
      document.getElementById('inputStartDate') as HTMLInputElement
    ).value;
    const inputEndDate = (
      document.getElementById('inputEndDate') as HTMLInputElement
    ).value;
    const endDate: Date = new Date(inputEndDate);
    const startDate: Date = new Date(inputStartDate);

    if (startDate != null && endDate != null) {
      if (endDate < startDate) {
        return true;
      }
    }
    return false;
  }

  isEndStructureDateInvalidCreation(): boolean {
    const inputStartDate = (
      document.getElementById('validityStartDate') as HTMLInputElement
    ).value;
    const inputEndDate = (
      document.getElementById('validityEndDate') as HTMLInputElement
    ).value;
    const endDate: Date = new Date(inputEndDate);
    const startDate: Date = new Date(inputStartDate);

    if (startDate != null && endDate != null) {
      if (endDate < startDate) {
        return true;
      }
    }
    return false;
  }
}
