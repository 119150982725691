import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Account } from "../core/auth/account.model";
import { AccountService } from "../core/auth/account.service";
import { CookieService } from "ngx-cookie-service";
import { AlertService } from "../emergency-depts/services/alert.service";
import { Alert } from "../emergency-depts/model/alert.model";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";


@Component({
  selector: "app-het",
  templateUrl: "./het.component.html",
  styleUrls: ["./het.component.scss"],
})
export class HetComponent implements OnInit {
  url: string = "/components/het";
  urlSafe: SafeResourceUrl;
  alerts: Alert[] = [];

  constructor(
    private cookie: CookieService,
    public sanitizer: DomSanitizer,
    private alertService: AlertService,
    private readonly titleService: Title,
    private readonly route: ActivatedRoute,
  ) {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

  ngOnInit() {
    this.titleService.setTitle(this.route.snapshot.data['title']);
    const Reg = this.isAraPage() ? 84 : 11;
    if (this.isAraPage() === true) {
      this.alertService
      .getAlertsByPageAlert(Reg, "TENSION_Module-HET")
      .subscribe((res: Alert[]) => {
        this.alerts = res;
        res.map((alert) => this.alertService.get(alert))
      }
      );
    } else {
      console.log("JE SUIS LA")
      this.alertService
      .getAlertsByPageAlert(Reg, "URGENCES_Tension-hospitalière")
      .subscribe((res: Alert[]) => {
        this.alerts = res;
        res.map((alert) => this.alertService.get(alert))
      }
      );
    }


  }

  isAlert() {
    if (this.alerts.length != 0) {
      return true
    }
    else {
      return false
    };
  }

  isAraPage() {
    const str = window.location.href;
    return str.includes("ara") || str.includes(":4201/");
  }
}
