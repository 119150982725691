<!--POSTED EFFECTORS-->
<div style="padding-bottom: 1%; padding-top: 1%" #content>
  <div ngcontent class="card mx-auto my-auto">
    <div class="closeButtonPosition">
      <img
        class="close-button closeButtonSize"
        src="../../../../assets/images/close.svg"
        (click)="closeForm(currentPage)"
      />
    </div>
    <div class="container-fluid mt-0 pt-0">
      <div class="row mb-4 mt-3">
        <div class="col-12">
          <h2 class="mb-0">
            {{ effectorShortLabel }} -
            {{ effectorDate ? capitalizeFirstLetter(effectorDate) : '' }}
          </h2>
        </div>
      </div>

      <div *ngIf="loading" class="my-4">
        <div class="d-flex justify-content-center">
          <div
            class="spinner-border"
            style="width: 6rem; height: 6rem"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>

      <!--NAVIGATION-->
      <nav ngbNav #nav="ngbNav" class="nav-tabs">
        <!-- TAB 1 -->
        <ng-container
          ngbNavItem
          [ngbNavItem]="1"
          [disabled]="currentPage === 1"
        >
          <a ngbNavLink class="tab-view" (click)="selectTabAndSaveCurrentTab(1)"
            >CONSULTATIONS PAR HORAIRE</a
          >

          <ng-template
            ngbNavContent
            class="nav-content shadow p-md-3 mb-2 bg-white rounded w-100"
          >
            <app-pdsa-poste-activity-tab-one
              [form]="getFormGroupFor('tab_one')"
            ></app-pdsa-poste-activity-tab-one>
          </ng-template>
        </ng-container>

        <!-- Tab 2 -->
        <ng-container
          ngbNavItem
          [ngbNavItem]="2"
          [disabled]="
            (postedConsultationEntries &&
              postedConsultationEntries.entry.step < 2) ||
            currentPage === 2
          "
        >
          <a
            ngbNavLink
            [ngClass]="isViewed(2) ? 'tab-view' : 'tab-not-view'"
            (click)="selectTabAndSaveCurrentTab(2)"
            >CONSULTATIONS CRRA15</a
          >
          <ng-template ngbNavContent>
            <app-pdsa-poste-activity-tab-two
              [form]="getFormGroupFor('tab_two')"
              (hasCommentChange)="hasComment = $event"
              #childComponentRef
            ></app-pdsa-poste-activity-tab-two>
          </ng-template>
        </ng-container>

        <ng-container
          ngbNavItem
          *ngIf="hasCommentEntered"
          [ngbNavItem]="3"
          [disabled]="currentPage === 3"
        >
          <a
            ngbNavLink
            [ngClass]="isViewed(2) ? 'tab-view' : 'tab-not-view'"
            (click)="selectTabAndSaveCurrentTab(3)"
            >COMMENTAIRE</a
          >
          <ng-template ngbNavContent [form]="getFormGroupFor('tab_three')">
            <app-pdsa-poste-activity-tab-three-component
              [form]="getFormGroupFor('tab_three')"
            >
            </app-pdsa-poste-activity-tab-three-component>
          </ng-template>
        </ng-container>

        <!-- HIDE -->
        <ng-template #mymodal let-modal>
          <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">
              Aperçu Export PDF
            </h4>
            <button
              type="button"
              class="close"
              aria-label="Close"
              (click)="modal.dismiss('Cross click')"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <app-pdsa-poste-activity-pdf> </app-pdsa-poste-activity-pdf>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-dark"
              (click)="exportToPDF()"
              (click)="modal.dismiss('Cross click')"
            >
              Exporter
            </button>
          </div>
        </ng-template>

        <!-- DOWNLOAD BUTTON -->
        <div class="downloadPosition">
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            ngbTooltip="Téléchargement PDF"
            (click)="showModalPdf(mymodal)"
          >
            <img
              src="../../../../assets/images/download.svg"
              alt="export"
              class="downloadSrc"
            />
          </button>
        </div>
      </nav>

      <div [ngbNavOutlet]="nav" class="mt-2"></div>

      <div class="form-footer row mt-2 mx-0">
        <div class="col-12 col-md-3 p-0 pl-3">
          <div class="row">
            <p
              style="text-align: center"
              class="d-flex align-items-center justify-content-center mb-0"
            >
              Zéro patient
            </p>
            <div
              type="text"
              class="rounded border border-success legendTab1 mr-2"
              ngbTooltip="Saisissez la valeur 0 si aucun patient n’a été vu"
            >
              0
            </div>
            <p
              style="text-align: center"
              class="d-flex align-items-center justify-content-center mb-0"
            >
              La structure est fermée
            </p>
            <div
              type="text"
              class="rounded border border-success legendTab1"
              ngbTooltip="Saisissez la valeur F si la structure été fermée"
            >
              F
            </div>
          </div>
        </div>
        <div class="col-12 col-md-3"></div>
        <div
          class="col-12 col-md-6 m-0 p-0 d-flex flex-fill justify-content-end mt-4 mt-md-0"
        >
          <!--PREVIOUS STEP BUTTON TAB 1-->
          <button
            type="button"
            class="btn previous"
            name="Enregistrer"
            (click)="saveAndSelectPreviousTab(currentPage)"
            [disabled]="currentPage === 1"
            [ngbTooltip]="
              currentPage !== 1 ? 'Vos modifications seront sauvegardées' : null
            "
            [ngClass]="{ 'not-allowed-cursor': currentPage === 1 }"
            [ngStyle]="{ opacity: currentPage === 1 ? '0.45' : '1' }"
          >
            Étape précédente
          </button>
          <button
            type="button"
            class="btn previous2"
            name="Enregistrer"
            (click)="saveAndSelectPreviousTab(currentPage)"
            [disabled]="currentPage === 1"
            [ngbTooltip]="
              currentPage !== 1 ? 'Vos modifications seront sauvegardées' : null
            "
            [ngClass]="{ 'not-allowed-cursor': currentPage === 1 }"
            [ngStyle]="{ opacity: currentPage === 1 ? '0.45' : '1' }"
          >
            <
          </button>

          <p class="pt-2 mr-1 pagesCount" *ngIf="!hasCommentEntered">
            {{ currentPage }} / 2
          </p>
          <p class="pt-2 mr-1 pagesCount" *ngIf="hasCommentEntered">
            {{ currentPage }} / 3
          </p>
          <button
            *ngIf="!hasCommentEntered && currentPage === 2"
            type="button"
            class="btn temporaryValidButton ml-2"
            (click)="childComponentRef2.onSubmit()"
            ngbTooltip="{{ tooltipMessage }}"
            [ngStyle]="{ cursor: cursorStyle, backgroundColor: colorStyle }"
            [disabled]="disableButton"
          >
            VALIDER MA SAISIE
          </button>
          <button
            *ngIf="hasCommentEntered && currentPage === 3"
            type="button"
            class="btn temporaryValidButton ml-2"
            (click)="childComponentRef3.onSubmit(eventTypes.SUBMIT)"
            ngbTooltip="{{ tooltipMessage }}"
            [ngStyle]="{ cursor: cursorStyle, backgroundColor: colorStyle }"
          >
            VALIDER MA SAISIE
          </button>

          <!--NEXT STEP BUTTON TAB 1-->
          <button
            *ngIf="currentPage === 1"
            type="button"
            class="btn next2 ml-2"
            (click)="childComponentRef1.onSubmit()"
            ngbTooltip="Vos modifications seront sauvegardées"
          >
            >
          </button>

          <button
            *ngIf="currentPage === 1"
            type="button"
            class="btn next"
            (click)="childComponentRef1.onSubmit()"
            ngbTooltip="Vos modifications seront sauvegardées"
          >
            Étape suivante
          </button>

          <!--NEXT STEP BUTTON TAB 2 AND COMMENT-->
          <button
            *ngIf="currentPage === 2 && hasCommentEntered"
            type="button"
            class="btn next2 ml-2"
            (click)="childComponentRef2.onSubmitAndSelectNextTab()"
            [disabled]="
              (currentPage === 2 && !hasCommentEntered) || disableButton
            "
            ngbTooltip="{{
              tooltipMessage
                ? tooltipMessage
                : 'Vos modifications seront sauvegardées'
            }}"
            [ngStyle]="{ cursor: cursorStyle, backgroundColor: colorStyleDark }"
          >
            >
          </button>

          <button
            *ngIf="currentPage === 2 && hasCommentEntered"
            type="button"
            class="btn next"
            (click)="childComponentRef2.onSubmitAndSelectNextTab()"
            [disabled]="
              (currentPage === 2 && !hasCommentEntered) || disableButton
            "
            ngbTooltip="{{
              tooltipMessage
                ? tooltipMessage
                : 'Vos modifications seront sauvegardées'
            }}"
            [ngStyle]="{ cursor: cursorStyle, backgroundColor: colorStyleDark }"
          >
            Étape suivante
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
