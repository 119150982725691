import { Inject, Injectable } from "@angular/core";
import { InjectionToken } from "@angular/core";

export interface FaviconsConfig {
  icons: IconsConfig;
  cacheBusting?: boolean;
}

export interface IconsConfig {
  [name: string]: IconConfig;
}

export interface IconConfig {
  type: string;
  href: string;
  isDefault?: boolean;
}

export var BROWSER_FAVICONS_CONFIG = new InjectionToken<FaviconsConfig>(
  "Favicons Configuration"
);

export abstract class Favicons {
  abstract activate(name: string): void;
}

@Injectable()
export class BrowserFavicons implements Favicons {
  private elementId: string;
  private icons: IconsConfig;
  private useCacheBusting: boolean;

  constructor(@Inject(BROWSER_FAVICONS_CONFIG) config: FaviconsConfig) {
    this.elementId = "favicons-service-injected-node";
    this.icons = Object.assign(Object.create(null), config.icons);
    this.useCacheBusting = config.cacheBusting || false;

    this.removeExternalLinkElements();
  }

  public activate(name: string): void {
    if (!this.icons[name]) {
      throw new Error(`Favicon for [ ${name}] note found.`);
    }
    this.setNode(this.icons[name].type, this.icons[name].href);
  }

  private addNode(type: string, href: string): void {
    var linkElement = document.createElement("link");
    linkElement.setAttribute("id", this.elementId);
    linkElement.setAttribute("rel", "icon");
    linkElement.setAttribute("type", type);
    linkElement.setAttribute("href", href);
    document.head.appendChild(linkElement);
  }

  private cacheBustHref(href: string): string {
    var augmentedHref =
      href.indexOf("?") === -1
        ? `${href}?faviconCacheBust=${Date.now()}`
        : `${href}&faviconCacheBust=${Date.now()}`;
    return augmentedHref;
  }

  private removeExternalLinkElements(): void {
    var linkElements = document.querySelectorAll("link[ rel ~= 'icon' i]");

    for (var linkElement of Array.from(linkElements)) {
      if (linkElement.parentNode !== null) {
        linkElement.parentNode.removeChild(linkElement);
      }
    }
  }

  private removeNode(): void {
    var linkElement = document.head.querySelector("#" + this.elementId);
    if (linkElement) {
      document.head.removeChild(linkElement);
    }
  }

  private setNode(type: string, href: string): void {
    var augmentedHref = this.useCacheBusting ? this.cacheBustHref(href) : href;

    this.removeNode();
    this.addNode(type, augmentedHref);
  }
}
