import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { GraphFilter } from '../../models/graph-filter.model';
import { ValueOfGrapsService } from '../services/valueOfGraps.service';
import { Alert } from 'src/app/emergency-depts/model/alert.model';
import { AlertService } from 'src/app/emergency-depts/services/alert.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-home-graph',
  templateUrl: './ntg-graph-home.component.html',
  styleUrls: ['./ntg-graph-home.component.scss']
})
export class NtgGraphHomeComponent {
  alerts: Alert[] = [];
  displayPeriod2: boolean;
  constructor(
    private valueOfGraphService: ValueOfGrapsService,
    private readonly titleService: Title,
    private readonly route: ActivatedRoute,
    private alertService: AlertService
  ) {}

  filter(graphFilter: GraphFilter) {
    this.displayPeriod2 = false;
    this.valueOfGraphService.getValueOfNtgGraph(
      'period1',
      graphFilter,
      graphFilter.period1
    );
    if (graphFilter.isActivePeriod2) {
      this.displayPeriod2 = true;
      this.valueOfGraphService.getValueOfNtgGraph(
        'period2',
        graphFilter,
        graphFilter.period2
      );
    }
  }

  ngOnInit() {
    this.titleService.setTitle(this.route.snapshot.data['title']);
    const Reg = this.isAraPage() ? 84 : 11;
    const alertLabel = this.isAraPage() ? 'TENSION_Evolution-NTG' : 'URGENCES_Evolution-NTG';
    this.alertService
      .getAlertsByPageAlert(Reg, alertLabel)
      .subscribe((res: Alert[]) => {
        this.alerts = res;
        res.map((alert) => this.alertService.get(alert));
      });
  }

  isAlert() {
    if (this.alerts.length != 0) {
      return true;
    } else {
      return false;
    }
  }

  isAraPage() {
    const str = window.location.href;
    return str.includes('ara') || str.includes(':4201/');
  }
}
