import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EmergencyDept } from "src/app/emergency-depts/model/emergency-dept.model";
import { SizeGroup } from "../models/size-group.model";

@Injectable({
  providedIn: "root",
})
export class GraphService {
  constructor(private httpClient: HttpClient) {}

  getSizesOfSau(): Observable<SizeGroup[]> {
    return this.httpClient.get<SizeGroup[]>(`/api/size-groups`);
  }

  getDepartementCodes(): Observable<string[]> {
    return this.httpClient.get<string[]>(
      `/api/emergency-depts/department-codes`
    );
  }

  getEmergencyDepts(
    sizes?: string[],
    departmentCodes?: string[]
  ): Observable<EmergencyDept[]> {
    const params: { [_: string]: string[] } = {};
    if (sizes !== undefined) {
      params.sizes = sizes;
    }
    if (departmentCodes !== undefined) {
      params.departmentCodes = departmentCodes;
    }
    return this.httpClient.get<EmergencyDept[]>(
      `/api/emergency-depts/sizes-and-department-codes`,
      { params }
    );
  }
}
