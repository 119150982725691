export class Alert {
  id: string;
  colorAlert: AlertType;
  textAlert: string;
  enabled: boolean;
  pageAlert: string;
  codReg: number;

  constructor(init?: Partial<Alert>) {
    Object.assign(this, init);
  }
}

export enum AlertType {
  "Primary",
  "Secondary",
  "Success",
  "Error",
  "Info",
  "Warning",
  "Light",
  "Dark",
}
