import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-error-inexistent",
  templateUrl: "./error-inexistent.component.html",
  styleUrls: ["./error-inexistent.component.scss"],
})
export class ErrorInexistentComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {  }

  isAraPage() {
    const str = window.location.href;
    return str.includes("ara") || str.includes(":4201/");
  }
}
