import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, pipe } from "rxjs";
import { groupBy, mergeMap } from "rxjs/operators";
import { Indicateur } from "../emergency-visits-graphs/models/indicateur.model";

@Injectable({
  providedIn: "root",
})
export class IndicateurService {
  constructor(private httpClient: HttpClient) {}

  getTindicateursBySau(
    listIdEmergencySU: string[],
    startDate: Date,
    endDate: Date
  ): Observable<Indicateur[]> {
    const params = {
      ids: listIdEmergencySU,
      startDate: this.dateToString(startDate),
      endDate: this.dateToString(endDate),
    };
    console.log("params", params);
    return this.httpClient.get<Indicateur[]>(`/api/indicateurs/sau`, {
      params,
    });
  }

  getIndicateursByDept(
    listDepts: string[],
    startDate: Date,
    endDate: Date
  ): Observable<Indicateur[]> {
    const params = {
      codes: listDepts,
      startDate: this.dateToString(startDate),
      endDate: this.dateToString(endDate),
    };
    return this.httpClient.get<Indicateur[]>(`/api/indicateurs/departments`, {
      params,
    });
  }

  getAllIndicateursForDepts(startDate: Date, endDate: Date) {
    const params = {
      startDate: this.dateToString(startDate),
      endDate: this.dateToString(endDate),
    };
    return this.httpClient.get<Indicateur[]>(
      "/api/indicateurs/departments/all",
      { params }
    );
  }

  private dateToString(date: Date): string {
    const month = `${date.getMonth() + 1}`;
    const day = `${date.getDate()}`;
    return (
      date.getFullYear() +
      "-" +
      ("00" + month).substring(month.length) +
      "-" +
      ("00" + day).substring(day.length)
    );
  }
}
