import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DateHelper } from 'src/app/shared/util/date.helper';
import Swal from 'sweetalert2';

import { EffectorFormValidators } from '../validators/effector-form-validators';
import { EffectorFormTerritory } from './effector-form-territory.model';
import { EffectorsTerritory } from './effectors-territory.model';

export class EffectorTerritoryTableLine {
  startDate: Date;
  id?: number;
  healthTerritoryCode: string;
  validityBoolean: boolean;
  validityStartDate: Date;
  validityEndDate: Date;
  typeTerritory = 'PDSA';
  form: FormGroup;
  isInEditMode = false;
  toDelete = false;
  sequence = 0;

  get toCreate() {
    this.sequence == this.getNextSequence();
    return this.sequence && this.id == null;
  }

  //to delete one line in EffectorTerritory table if it's new
  getNextSequence() {
    this.sequence += 1;
    return this.sequence;
  }

  //make validityStartDate of EffectorTerritory table disabled
  get isOnlyEndDateEditable(): boolean {
    const now = new Date(DateHelper.toLocalDate(new Date()) ?? new Date());

    if (this.toCreate) {
      return false;
    }
    return (
      this.validityStartDate.setHours(0, 0, 0, 0) < now.setHours(0, 0, 0, 0)
    );
  }

  static fromEffectorTerritory(effectorTerritory: EffectorsTerritory) {
    return new EffectorTerritoryTableLine(effectorTerritory);
  }

  static fromEffectorFormTerritory(
    effectorFormTerritory: EffectorFormTerritory
  ) {
    return new EffectorTerritoryTableLine(effectorFormTerritory);
  }

  private createFormGroup(): FormGroup {
    const form = new FormGroup(
      {
        healthTerritoryCode: new FormControl(
          {
            value: this.healthTerritoryCode,
            disabled: this.isOnlyEndDateEditable
          },
          [Validators.required]
        ),
        validityStartDate: new FormControl(
          {
            value: DateHelper.toLocalDate(this.validityStartDate),
            disabled: this.isOnlyEndDateEditable
          },
          [Validators.required]
        ),
        validityEndDate: new FormControl(
          {
            value: DateHelper.toLocalDate(this.validityEndDate),
            disabled: false
          },
          [Validators.required]
        ),
        validityBoolean: new FormControl({
          value: this.validityBoolean,
          disabled: true
        })
      },
      { validators: EffectorFormValidators.validateDateRange() }
    );
    if (this.isOnlyEndDateEditable) {
      this.startDate = new Date(this.validityStartDate);
    }
    return form;
  }

  /**
   * Update the object values from his form
   */
  updateFromForm(effector) {
    if (
      this.form.get('validityStartDate')?.value >
      this.form.get('validityEndDate')?.value
    ) {
      this.form
        .get('validityEndDate')
        ?.setValue(DateHelper.toLocalDate(new Date(effector.validityEndDate)));
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Les dates de début et de fin de validité ne sont pas cohérentes !'
      });
    } else {
      this.healthTerritoryCode = this.form.get('healthTerritoryCode')?.value;
      this.validityStartDate = new Date(
        this.form.get('validityStartDate')?.value
      );
      this.validityEndDate = new Date(this.form.get('validityEndDate')?.value);
      this.validityBoolean = this.form.get('validityBoolean')?.value;
    }
  }

  constructor(effectorTerritory: EffectorsTerritory | EffectorFormTerritory) {
    this.id = effectorTerritory.id;
    this.healthTerritoryCode = effectorTerritory.healthTerritoryCode;
    this.validityBoolean = effectorTerritory.validityBoolean;
    this.validityStartDate = new Date(effectorTerritory.validityStartDate);
    this.validityEndDate = new Date(effectorTerritory.validityEndDate);
    this.typeTerritory = effectorTerritory.typeTerritory;
    this.form = this.createFormGroup();
  }
}
