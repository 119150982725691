<body>
    <div class="container">  
      <iframe
        width="100%"
        height="100%"
        frameBorder="0"
        src="https://powerbi-predictif.sante-idf.fr/portail/powerbi/R%C3%A9gion%20%C3%8Ele-de-France/Analyse%20demande%20acc%C3%A8s%20pro?rs:embed=true"
        allowfullscreen
      ></iframe>
    </div>
  </body>