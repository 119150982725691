import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { format, subMonths } from 'date-fns';
import { fr } from 'date-fns/locale';
import { User } from 'src/app/shared/models/user.model';
import { Alert } from 'src/app/emergency-depts/model/alert.model';
import { AlertService } from 'src/app/emergency-depts/services/alert.service';
import { Account } from '../../../core/auth/account.model';
import { AccountService } from '../../../core/auth/account.service';
import { AppRoutes } from '../../../emergency-depts/routes.enum';
import { ActivityEffector } from '../../model/activity/activity-effector.model';
import { EffectorEntry } from '../../model/activity/effector-entry.model';
import { StateInputPdsaClass } from '../../model/state-input-pdsa.model';
import { EffectorsAddress } from '../../model/effectorsAddress.model';
import { EffectorService } from '../../service/effector.service';
import { EffectorsFormService } from '../../service/effectors-form.service';
import { EffectorsAddressService } from '../../service/effectorsAddress.service';
import { PdsaStructureActivityMonth } from './pdsa-structure-activity-month-model';
import { ActivityEffectorService } from '../../service/activity/activity-effector.service';
import { EffectorEntryService } from '../../service/activity/effector-entry.service';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, of } from 'rxjs';
import { ReportChangeComponent } from './report-change/report-change.component';
import { Type } from '../../model/activity/effector-type.enum';
import { ParameterService } from '../../service/activity/parameter.service';
import { Parameter } from '../../model/activity/parameter.model';
import { catchError } from 'rxjs/internal/operators/catchError';
import { finalize } from 'rxjs/internal/operators/finalize';

@Component({
  selector: 'app-pdsa-structure-activity',
  templateUrl: './pdsa-structure-activity.component.html',
  styleUrls: ['./pdsa-structure-activity.component.scss']
})
export class PdsaStructureActivityComponent implements OnInit {
  private _success = new Subject<string>();

  selectedEffector: ActivityEffector;
  selectedUsers: User[];
  selectedAddress: EffectorsAddress;
  public address: EffectorsAddress[] = [];
  public users: User[] = [];

  closeResult: string;
  closeResultFormContact: any;

  enterableNumberOfMonths: number;
  currentParameter: Parameter;
  private apiUrl = 'your_backend_api_url';

  account: Account | null;
  filters: string[] = [];
  activityEffectors: ActivityEffector[] = [];
  effectorEntries: EffectorEntry;
  activityEffectorsAllData = this.activityEffectors;
  alerts: Alert[] = [];

  effector: FormGroup = this.fb.group({
    effectorArsLabel: [null],
    effectorLabel: [null],
    effectorShortLabel: [null],
    departmentCode: [null]
  });

  addressForm: FormGroup = this.fb.group({
    labelAddress1: [null],
    labelAddress2: [null],
    labelAddress3: [null],
    labelAddress4: [null],
    postalCode: [null],
    labelCity: [null],
    phoneNumber: [null],
    email: [null],
    webSite: [null],
    id: null
  });

  // PAGINATION
  page = 1;
  count = 0;
  tableSize = 6;
  monthList: PdsaStructureActivityMonth[] = [];

  changeNumberOfMonths(event: any) {
    if (event.target.value < 12 || event.target.value > 0) {
      this.enterableNumberOfMonths = event.target.value;
      this.updateDataBaseParameter();
      this.monthList = [];
      this.generateMonthList();
      this.getData();
    }
  }

  updateDataBaseParameter() {
    this.currentParameter.value = this.enterableNumberOfMonths.toString();
    this.parameterService
      .update(this.currentParameter)
      .pipe(
        catchError((error) => {
          console.log(error);
          return of<Parameter>();
        }),
        finalize(() => {})
      )
      .subscribe();
  }

  findNumberOfMonth() {
    return new Promise((resolve, reject) => {
      this.parameterService.find(103).subscribe(
        (data) => {
          this.currentParameter = data;
          this.enterableNumberOfMonths = Number(data.value);
          resolve('void');
        },
        (error) => {
          console.error('ERROR', error);
          reject(error);
        }
      );
    });
  }

  compareEffector(a: ActivityEffector, b: ActivityEffector): number {
    // Compare par département
    if (a.departmentCode < b.departmentCode) {
      return -1;
    } else if (a.departmentCode > b.departmentCode) {
      return 1;
    } else {
      // Si les départements sont égaux, compare par libellé
      return a.shortLabel.localeCompare(b.shortLabel);
    }
  }

  changeRotate = {
    departmentCode: true,
    domainCode: true,
    effectorLabel: true,
    validityStartDate: true,
    validityEndDate: true,
    validityBoolean: true
  };

  constructor(
    private effectorService: EffectorService,
    private accountService: AccountService,
    private router: Router,
    private readonly titleService: Title,
    private readonly route: ActivatedRoute,
    private activityEffectorService: ActivityEffectorService,
    private fb: FormBuilder,
    private effectorEntryService: EffectorEntryService,
    private modalService: NgbModal,
    private effectorsAddressService: EffectorsAddressService,
    private effectorsFormService: EffectorsFormService,
    private http: HttpClient,
    private parameterService: ParameterService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle(this.route.snapshot.data['title']);
    this.findNumberOfMonth()
      .then(() => {
        this.generateMonthList();
        this.getData();
      })
      .catch((error) => {
        console.error('Error getting month count from data base :', error);
      });
    const Reg = this.isAraPage() ? 84 : 11;
    this.alertService
      .getAlertsByPageAlert(Reg, 'PDSA_Formulaire-de-saisie-PDSA')
      .subscribe((res: Alert[]) => {
        this.alerts = res;
        res.map((alert) => this.alertService.get(alert));
      });
  }

  isAraPage() {
    const str = window.location.href;
    return str.includes('ara') || str.includes(':4201/');
  }

  getTitlePopUp() {
    return this.effector.value.effectorArsLabel;
  }

  getData() {
    this.accountService.identity().subscribe((account) => {
      this.account = account;
    });
    this.activityEffectorService
      .getActivityEffectors(this.enterableNumberOfMonths)
      .subscribe((activityEffector: ActivityEffector[]) => {
        this.activityEffectors = activityEffector.sort(this.compareEffector);
        this.activityEffectorsAllData = activityEffector;
      });
  }

  getEffectorEntries(id: number, yearMonth: string) {
    this.effectorEntryService
      .getEffectorEntry(id, yearMonth)
      .subscribe((data) => {
        this.effectorEntries = data;
      });
  }

  generateMonthList() {
    let dateIndex = subMonths(new Date(), 1);
    for (let i = this.enterableNumberOfMonths - 1; i >= 0; i--) {
      this.monthList[i] = new PdsaStructureActivityMonth(
        format(dateIndex, 'LLL yyyy', { locale: fr }).toUpperCase(),
        dateIndex
      );
      dateIndex = subMonths(dateIndex, 1);
    }
  }

  getStateClassFor(
    effector: ActivityEffector,
    month: PdsaStructureActivityMonth
  ): any {
    const yearMonth = StateInputPdsaClass.monthIdFor(month.date).toString();
    let activityState = {};
    for (const activity of this.activityEffectors) {
      if (activity.id === effector.id) {
        const entries = activity.entries;
        if (Array.isArray(entries)) {
          for (const entry of entries) {
            if (yearMonth == entry?.yearMonth.toString().split('-').join('')) {
              if (
                (entry.yearMonth >= activity.minOpenedYearMonth &&
                  entry.yearMonth < activity.maxOpenedYearMonth) ||
                (entry.yearMonth >= activity.minOpenedYearMonth &&
                  activity.maxOpenedYearMonth === undefined)
              ) {
                activityState = entry?.state;
                if (activity.firstTransmissionDate) {
                  let [yearString, monthString] = entry?.yearMonth
                    .toString()
                    .split('-');
                  let yearNumber = parseInt(yearString, 10);
                  let monthNumber = parseInt(monthString, 10);
                  const transmissionDateString: string =
                    activity.firstTransmissionDate.toString();
                  const transmissionDate: Date = new Date(
                    transmissionDateString
                  );
                  const transmissionDateMonth = transmissionDate.getMonth() + 1;
                  const transmissionDateYear = transmissionDate.getFullYear();
                  if (
                    transmissionDateYear < yearNumber ||
                    (transmissionDateYear === yearNumber &&
                      transmissionDateMonth <= monthNumber)
                  ) {
                    return 'pdsa-state-inactive';
                  }
                }
                if (activityState == 'COMPLETED') {
                  return 'pdsa-state-closed';
                } else if (activityState == 'UNCOMPLETED') {
                  return 'pdsa-state-open';
                } else if (activityState == 'NONE') {
                  return 'pdsa-state-null';
                }
              }
            }
          }
        }
      }
    }
    return 'pdsa-state-inactive';
  }

  getStateLabelFor(
    effector: ActivityEffector,
    month: PdsaStructureActivityMonth
  ): any {
    const yearMonth = StateInputPdsaClass.monthIdFor(month.date).toString();
    let activityState = {};
    for (const activity of this.activityEffectors) {
      if (activity.id === effector.id) {
        const entries = activity.entries;
        if (Array.isArray(entries)) {
          for (const entry of entries) {
            if (yearMonth == entry?.yearMonth.toString().split('-').join('')) {
              if (
                (entry.yearMonth >= activity.minOpenedYearMonth &&
                  entry.yearMonth < activity.maxOpenedYearMonth) ||
                (entry.yearMonth >= activity.minOpenedYearMonth &&
                  activity.maxOpenedYearMonth === undefined)
              ) {
                activityState = entry?.state;
                if (activity.firstTransmissionDate) {
                  let [yearString, monthString] = entry?.yearMonth
                    .toString()
                    .split('-');
                  let yearNumber = parseInt(yearString, 10);
                  let monthNumber = parseInt(monthString, 10);
                  const transmissionDateString: string =
                    activity.firstTransmissionDate.toString();
                  const transmissionDate: Date = new Date(
                    transmissionDateString
                  );
                  const transmissionDateMonth = transmissionDate.getMonth() + 1;
                  const transmissionDateYear = transmissionDate.getFullYear();
                  if (
                    transmissionDateYear < yearNumber ||
                    (transmissionDateYear === yearNumber &&
                      transmissionDateMonth <= monthNumber)
                  ) {
                    return 'Transmission automatisée';
                  }
                }
                if (activityState == 'COMPLETED') {
                  return 'Modifier';
                } else if (activityState == 'UNCOMPLETED') {
                  return 'Compléter';
                } else if (activityState == 'NONE') {
                  return 'Saisir';
                }
              }
            }
          }
        }
      }
    }
    return 'Fermée';
  }

  // TABLE UTILS
  filterData(event: Event) {
    const searchValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.filters['departmentCode'] = searchValue;
    this.filters['shortLabel'] = searchValue;
    this.filterTable();
  }

  filterTable() {
    this.page = 1;
    this.activityEffectors = this.activityEffectorsAllData.filter((item) => {
      return Object.keys(this.filters).some((filter) => {
        return (
          item[filter] &&
          item[filter].toLowerCase().trim().includes(this.filters[filter]) &&
          (filter === 'departmentCode' || filter === 'shortLabel')
        );
      });
    });
  }

  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
  }

  changeStyle(column: string) {
    this.changeRotate[column] = !this.changeRotate[column];
  }

  goToForm(effector: ActivityEffector, month: PdsaStructureActivityMonth) {
    if (
      this.getStateLabelFor(effector, month) === 'Fermée' ||
      this.getStateLabelFor(effector, month) === 'Transmission automatisée'
    ) {
      return;
    }
    if (
      effector.type === Type.MOBILE_WITHOUT_PLATFORM ||
      effector.type === Type.MOBILE_WITH_PLATFORM
    ) {
      this.router.navigate([
        AppRoutes.PDSA_MOBILE_ACTIVITY_FORM,
        StateInputPdsaClass.monthIdFor(month.date),
        effector.id
      ]);
    } else if (effector.type === Type.POSTED) {
      this.router.navigate([
        AppRoutes.PDSA_POSTE_ACTIVITY_FORM,
        StateInputPdsaClass.monthIdFor(month.date),
        effector.id
      ]);
    }
  }

  open(content, effector: ActivityEffector) {
    this.addressForm.reset();
    this.selectedEffector = effector;
    const id = effector.id;
    this.effectorsAddressService
      .getEffectorsAddressById(id)
      .subscribe((effectorAddress: EffectorsAddress) => {
        this.addressForm.patchValue({
          id: effectorAddress.id,
          labelAddress1: effectorAddress.labelAddress1,
          labelAddress2: effectorAddress.labelAddress2,
          labelAddress3: effectorAddress.labelAddress3,
          postalCode: effectorAddress.postalCode,
          labelCity: effectorAddress.labelCity,
          phoneNumber: effectorAddress.phoneNumber,
          email: effectorAddress.email,
          webSite: effectorAddress.webSite
        });
        this.selectedAddress = effectorAddress;
      });
    this.effectorsFormService
      .getUsersByEffectorId(id)
      .subscribe((users: User[]) => {
        this.selectedUsers = users;
      });
    this.modalService
      .open(content, { size: 'xl', ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  openReportChange(selectedEffector: ActivityEffector) {
    const modalRef = this.modalService.open(ReportChangeComponent, {
      size: 'lg'
    });
    // Le componentInstance va servir à accéder aux propriétés et méthodes du composant ouvert et lui passer des données.
    modalRef.componentInstance.setSelectedEffector(selectedEffector);
    modalRef.result.then(
      (result) => {
        this.closeResult = result;
        if (this.closeResultFormContact === true) {
          this._success.next('Demande envoyée avec succès');
        }
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
}
