import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DeptSize } from '../model/dept-size.model';

@Injectable({
  providedIn: 'root'
})
export class DeptSizeService {
  constructor(
    private httpClient: HttpClient,
  ) { }

  public getDeptSizes(): Observable<DeptSize[]> {
    return this.httpClient.get<DeptSize[]>(`/api/size-groups`);
  }
}
