import { DatePipe } from "@angular/common";
import {
  Component,
  Input,
  OnInit,
  SimpleChange,
  SimpleChanges,
} from "@angular/core";
import { ChartService } from "src/app/graph/services/chart.service";
import { IndicateurService } from "../../services/indicateur.service";

@Component({
  selector: "app-visits-graph",
  templateUrl: "./visits-graph.component.html",
  styleUrls: ["./visits-graph.component.scss"],
})
export class VisitsGraphComponent implements OnInit {
  @Input() dataInd1;

  constructor(
    private chartService: ChartService,
    private indicateurService: IndicateurService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(change: SimpleChanges) {
    this.chartService.initChart(
      "visits",
      change.dataInd1.currentValue,
      "Nombre de passages"
    );
  }
}
