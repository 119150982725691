import { Component, OnInit } from "@angular/core";
import * as Highcharts from "highcharts";

declare var require: any;
let Boost = require("highcharts/modules/boost");
let noData = require("highcharts/modules/no-data-to-display");
let More = require("highcharts/highcharts-more");

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);

@Component({
  selector: "app-graph-accueil",
  templateUrl: "./graph-accueil.component.html",
  styleUrls: ["./graph-accueil.component.scss"],
})
export class GraphAccueilComponent implements OnInit {
  public options: any = {
    chart: {
      width: 500,
    },
    title: {
      text: "Passages aux Urgences",
    },

    rangeSelector: {
      enabled: true,
      inputPosition: {
        align: "left",
        x: 0,
        y: 0,
      },
      buttonPosition: {
        align: "right",
        x: 0,
        y: 0,
      },
    },

    yAxis: {
      min: 0,
      max: 2500,
      title: {
        text: "Nombre de passages",
      },
    },

    xAxis: {
      title: {
        text: "Date d'entrée",
      },
      // min: 0,
      min: Date.UTC(1, 0, 0, 0, 0, 0),
      tickInterval: 3600 * 1000 * 24,
      max: Date.UTC(1, 0, 6, 0, 0, 0),
      type: "date",

      // baseUnit: "day",        pointInterval: 3600 * 1000 * 24,
      pointStart: Date.UTC(1, 0, 0, 0, 0, 0),
      labels: {
        format: "{value:%e/%m}",
      },
    },

    legend: {
      layout: "vertical",
      align: "right",
      verticalAlign: "middle",
    },

    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
        pointStart: Date.UTC(1, 0, 0, 0, 0, 0),
      },
    },

    series: [
      {
        name: "Ain",
        data: [1000, 1500, 1400, 1002, 1100, 1200, 1300],
        pointInterval: 3600 * 1000 * 24,
        pointStart: Date.UTC(1, 0, 0, 0, 0, 0),
      },
      {
        name: "Allier",
        data: [300, 500, 400, 500, 430, 600, 550],
        pointInterval: 3600 * 1000 * 24,
        pointStart: Date.UTC(1, 0, 0, 0, 0, 0),
      },
      {
        name: "Ardèche",
        data: [1000, 400, 650, 800, 900, 500, 700],
        pointInterval: 3600 * 1000 * 24,
        pointStart: Date.UTC(1, 0, 0, 0, 0, 0),
      },
      {
        name: "Cantal",
        data: [2400, 2000, 1800, 1900, 2000, 2100, 2300],
        pointInterval: 3600 * 1000 * 24,
        pointStart: Date.UTC(1, 0, 0, 0, 0, 0),
      },
      {
        name: "Drome",
        data: [2300, 2000, 1980, 1500, 1700, 1900, 1880],
        pointInterval: 3600 * 1000 * 24,
        pointStart: Date.UTC(1, 0, 0, 0, 0, 0),
      },
      {
        name: "Haute-Loire",
        data: [500, 600, 700, 600, 500, 700, 880],
        pointInterval: 3600 * 1000 * 24,
        pointStart: Date.UTC(1, 0, 0, 0, 0, 0),
      },
      {
        name: "Haute-savoie",
        data: [100, 200, 900, 600, 670, 810, 990],
        pointInterval: 3600 * 1000 * 24,
        pointStart: Date.UTC(1, 0, 0, 0, 0, 0),
      },
      {
        name: "Isère",
        data: [2000, 2100, 2400, 2300, 2400, 2200, 2450],
        pointInterval: 3600 * 1000 * 24,
        pointStart: Date.UTC(1, 0, 0, 0, 0, 0),
      },
      {
        name: "Loire",
        data: [1800, 1600, 1700, 1550, 1700, 1800, 1950],
        pointInterval: 3600 * 1000 * 24,
        pointStart: Date.UTC(1, 0, 0, 0, 0, 0),
      },
      {
        name: "Puy-de-Dome",
        data: [1400, 1900, 2000, 2200, 2000, 2340, 1980],
        pointInterval: 3600 * 1000 * 24,
        pointStart: Date.UTC(1, 0, 0, 0, 0, 0),
      },
      {
        name: "Rhône",
        data: [600, 800, 1000, 1100, 1200, 1450, 1430],
        pointInterval: 3600 * 1000 * 24,
        pointStart: Date.UTC(1, 0, 0, 0, 0, 0),
      },
      {
        name: "Savoie",
        data: [1150, 1400, 1700, 2000, 2100, 2201, 2000],
        pointInterval: 3600 * 1000 * 24,
        pointStart: Date.UTC(1, 0, 0, 0, 0, 0),
      },
    ],

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    },
  };

  public seriesOptions = [
    {
      name: "Vert",
      data: [
        42, 37, 15, 20, 5, 34, 12, 2, 22, 3, 6, 5, 20, 43, 25, 1, 37, 13, 48,
        45, 1, 37, 1, 1, 36, 62, 11, 17, 14, 30, 6, 64, 16, 28, 12, 0, 54, 37,
        2, 65, 3, 5, 62, 22, 26, 39, 18, 4, 6, 20, 60, 15, 4, 48, 9, 36, 1, 36,
        12, 36, 25, 28, 5, 2, 65, 5, 33, 10, 51, 47, 38, 38, 17, 11, 8, 18, 1,
        30, 30, 8, 12, 20, 7, 1, 27, 26, 43, 9, 23, 26, 1, 13, 1, 47, 4, 14, 22,
        73, 76, 10, 60, 28, 1, 23, 4, 40, 63, 37, 29, 3, 18, 49, 22, 8, 51, 17,
        38, 20, 83, 72, 54, 68, 21, 9, 16, 5, 8, 35, 19, 1, 2, 6, 1, 2, 21, 79,
        1, 33, 11, 6, 1, 1, 57, 7, 5, 19, 53, 28, 66, 6, 11, 1, 44, 5, 78, 11,
        7, 5, 62, 40, 89, 16, 17, 52, 12, 55, 16, 18, 19, 11, 48, 21, 27, 52,
        29, 32, 2, 64, 13, 20, 66, 22, 51, 10, 2, 2, 6, 4, 2, 27, 19, 1, 15, 4,
        2, 2, 39, 28, 5, 6, 17, 93, 7, 15, 25, 25, 2, 4, 18, 9, 91, 53, 49, 94,
        79, 57, 42, 4, 32, 23, 7, 15, 8, 9, 7, 22, 57, 37, 11, 53, 9, 26, 11,
        24, 13, 6, 3, 27, 41, 21, 30, 13, 16, 7, 51, 1, 83, 2, 26, 8, 18, 10,
        32, 25, 6, 72, 55, 16, 8, 6, 27, 1, 42, 4, 9, 3, 35, 19, 27, 10, 16, 49,
        46, 11, 73, 37, 1, 7, 16, 50, 96, 28, 15, 51, 1, 16, 33, 7, 85, 1, 5, 1,
        58, 53, 23, 17, 36, 46, 10, 52, 36, 2, 8, 69, 2, 6, 4, 46, 15, 45, 48,
        65, 32, 72, 19, 15, 66, 11, 35, 1, 5, 50, 7, 21, 30, 10, 1, 2, 0, 62,
        15, 13, 16, 32, 42, 15, 1, 32, 36, 0, 50, 81, 15, 38, 22, 33, 26, 3, 29,
        17, 9, 2, 2, 1, 1, 3, 7, 15, 7, 93, 69, 18, 6, 61, 0, 2, 76, 57, 20, 51,
        1, 3, 21, 48, 25, 24, 3, 0, 3, 47, 22, 29, 4, 0, 0, 30, 21, 14, 33, 3,
        44, 34, 3, 31, 13, 74, 4, 58, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0,
      ],
    },
    {
      name: "Jaune",
      data: [
        25, 94, 32, 18, 10, 0, 4, 40, 55, 63, 35, 12, 25, 64, 0, 50, 30, 32, 35,
        55, 9, 23, 4, 49, 16, 16, 6, 4, 44, 41, 9, 28, 16, 1, 9, 18, 8, 19, 15,
        0, 3, 1, 56, 5, 6, 11, 40, 12, 0, 77, 4, 10, 25, 5, 4, 20, 16, 64, 31,
        4, 19, 12, 14, 3, 75, 11, 76, 46, 23, 55, 12, 3, 10, 71, 9, 7, 56, 15,
        40, 51, 1, 5, 41, 10, 15, 24, 27, 25, 2, 10, 67, 81, 1, 1, 25, 23, 15,
        19, 36, 79, 14, 76, 81, 12, 48, 56, 17, 15, 13, 0, 12, 19, 27, 0, 3, 29,
        60, 70, 2, 4, 10, 51, 8, 49, 28, 78, 34, 56, 19, 17, 2, 6, 22, 46, 22,
        17, 35, 17, 1, 9, 2, 58, 12, 5, 21, 2, 53, 87, 39, 24, 74, 7, 6, 8, 40,
        69, 5, 27, 41, 52, 24, 44, 14, 23, 64, 44, 22, 5, 45, 14, 0, 23, 53, 3,
        5, 2, 16, 6, 13, 38, 26, 17, 5, 11, 4, 47, 36, 4, 39, 21, 6, 5, 15, 77,
        53, 19, 11, 28, 6, 36, 16, 43, 58, 8, 1, 87, 1, 14, 41, 14, 77, 15, 9,
        0, 16, 51, 34, 23, 38, 29, 67, 3, 72, 32, 0, 0, 5, 12, 19, 0, 21, 2, 39,
        26, 34, 15, 13, 89, 10, 4, 16, 31, 16, 27, 49, 10, 61, 23, 2, 67, 58, 4,
        9, 23, 43, 5, 63, 4, 20, 8, 1, 17, 28, 22, 3, 10, 14, 36, 46, 6, 6, 2,
        18, 2, 16, 25, 2, 3, 4, 17, 52, 2, 36, 6, 0, 22, 12, 47, 55, 12, 6, 6,
        30, 40, 21, 39, 8, 47, 6, 14, 8, 5, 15, 34, 13, 5, 18, 28, 6, 72, 17, 4,
        60, 14, 9, 16, 1, 18, 44, 9, 14, 95, 12, 26, 65, 62, 37, 34, 30, 6, 53,
        26, 33, 20, 44, 12, 6, 13, 5, 7, 3, 26, 6, 1, 1, 5, 8, 19, 72, 13, 10,
        9, 3, 30, 2, 10, 86, 18, 6, 4, 17, 1, 21, 10, 18, 6, 11, 33, 17, 5, 26,
        56, 1, 29, 9, 44, 21, 49, 2, 25, 33, 22, 1, 74, 77, 65, 44, 0, 18, 8, 7,
        0, 8, 12, 50, 40, 42, 5, 36, 57,
      ],
    },
    {
      name: "Rouge",
      data: [
        12, 8, 7, 26, 42, 25, 6, 6, 6, 29, 12, 0, 11, 30, 44, 40, 26, 68, 12,
        32, 21, 24, 11, 17, 27, 42, 18, 24, 50, 33, 34, 31, 52, 33, 28, 18, 20,
        25, 31, 12, 9, 27, 40, 9, 10, 32, 15, 10, 11, 39, 22, 3, 4, 23, 28, 10,
        32, 66, 14, 50, 40, 10, 12, 39, 15, 9, 3, 21, 34, 20, 62, 29, 6, 18, 30,
        52, 40, 11, 10, 45, 28, 5, 14, 11, 17, 11, 22, 6, 20, 55, 66, 31, 67,
        23, 5, 20, 22, 22, 32, 26, 26, 9, 14, 63, 19, 6, 53, 7, 29, 46, 18, 40,
        53, 20, 41, 57, 50, 30, 29, 22, 1, 12, 21, 4, 1, 12, 26, 15, 22, 67, 4,
        51, 33, 56, 33, 45, 49, 3, 28, 2, 21, 55, 2, 14, 18, 12, 68, 17, 2, 26,
        17, 17, 21, 27, 33, 19, 17, 4, 27, 17, 14, 19, 8, 54, 28, 35, 23, 39,
        56, 8, 12, 38, 20, 5, 29, 39, 10, 31, 34, 12, 2, 7, 13, 8, 64, 29, 49,
        45, 63, 36, 64, 16, 3, 7, 3, 1, 52, 34, 44, 62, 27, 37, 12, 3, 19, 14,
        20, 9, 1, 10, 1, 2, 54, 4, 23, 4, 12, 29, 43, 26, 36, 29, 10, 64, 25,
        13, 4, 9, 39, 52, 55, 16, 12, 48, 26, 9, 54, 47, 12, 52, 15, 10, 9, 26,
        48, 22, 24, 12, 26, 45, 35, 10, 5, 16, 30, 14, 16, 4, 19, 20, 44, 13, 4,
        20, 38, 66, 64, 25, 1, 47, 17, 53, 18, 20, 3, 37, 34, 4, 28, 15, 27, 6,
        8, 16, 6, 20, 20, 20, 15, 54, 40, 12, 21, 18, 11, 1, 17, 61, 18, 26, 40,
        6, 46, 25, 16, 10, 7, 42, 50, 48, 18, 10, 33, 36, 56, 29, 56, 4, 2, 5,
        22, 9, 61, 10, 57, 6, 52, 58, 1, 9, 25, 46, 13, 12, 28, 18, 53, 44, 26,
        61, 37, 5, 2, 36, 55, 17, 12, 24, 35, 33, 47, 55, 67, 50, 18, 14, 12,
        18, 48, 3, 6, 26, 34, 34, 15, 12, 11, 2, 40, 36, 37, 43, 9, 50, 32, 20,
        20, 48, 61, 8, 12, 9, 27, 10, 12, 28, 41, 32, 29, 35, 26, 16, 32, 0, 25,
        7, 21, 9, 66, 12,
      ],
    },
  ];
  public seriesOptions1 = [
    {
      name: "Vert",
      data: [
        42, 37, 15, 20, 5, 34, 12, 2, 22, 3, 6, 5, 20, 43, 25, 1, 37, 13, 48,
        45, 1, 37, 1, 1, 36, 62, 11, 17, 14, 30, 6, 64, 16, 28, 12, 0, 54, 37,
        2, 65, 3, 5, 62, 22, 26, 39, 18, 4, 6, 20, 60, 15, 4, 48, 9, 36, 1, 36,
        12, 36, 25, 28, 5, 2, 65, 5, 33, 10, 51, 47, 38, 38, 17, 11, 8, 18, 1,
        30, 30, 8, 12, 20, 7, 1, 27, 26, 43, 9, 23, 26, 1, 13, 1, 47, 4, 14, 22,
        73, 76, 10, 60, 28, 1, 23, 4, 40, 63, 37, 29, 3, 18, 49, 22, 8, 51, 17,
        38, 20, 83, 72, 54, 68, 21, 9, 16, 5, 8, 35, 19, 1, 2, 6, 1, 2, 21, 79,
        1, 33, 11, 6, 1, 1, 57, 7, 5, 19, 53, 28, 66, 6, 11, 1, 44, 5, 78, 11,
        7, 5, 62, 40, 89, 16, 17, 52, 12, 55, 16, 18, 19, 11, 48, 21, 27, 52,
        29, 32, 2, 64, 13, 20, 66, 22, 51, 10, 2, 2, 6, 4, 2, 27, 19, 1, 15, 4,
        2, 2, 39, 28, 5, 6, 17, 93, 7, 15, 25, 25, 2, 4, 18, 9, 91, 53, 49, 94,
        79, 57, 42, 4, 32, 23, 7, 15, 8, 9, 7, 22, 57, 37, 11, 53, 9, 26, 11,
        24, 13, 6, 3, 27, 41, 21, 30, 13, 16, 7, 51, 1, 83, 2, 26, 8, 18, 10,
        32, 25, 6, 72, 55, 16, 8, 6, 27, 1, 42, 4, 9, 3, 35, 19, 27, 10, 16, 49,
        46, 11, 73, 37, 1, 7, 16, 50, 96, 28, 15, 51, 1, 16, 33, 7, 85, 1, 5, 1,
        58, 53, 23, 17, 36, 46, 10, 52, 36, 2, 8, 69, 2, 6, 4, 46, 15, 45, 48,
        65, 32, 72, 19, 15, 66, 11, 35, 1, 5, 50, 7, 21, 30, 10, 1, 2, 0, 62,
        15, 13, 16, 32, 42, 15, 1, 32, 36, 0, 50, 81, 15, 38, 22, 33, 26, 3, 29,
        17, 9, 2, 2, 1, 1, 3, 7, 15, 7, 93, 69, 18, 6, 61, 0, 2, 76, 57, 20, 51,
        1, 3, 21, 48, 25, 24, 3, 0, 3, 47, 22, 29, 4, 0, 0, 30, 21, 14, 33, 3,
        44, 34, 3, 31, 13, 74, 4, 58, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0,
      ],
    },
    {
      name: "Jaune",
      data: [
        25, 94, 32, 18, 10, 0, 4, 40, 55, 63, 35, 12, 25, 64, 0, 50, 30, 32, 35,
        55, 9, 23, 4, 49, 16, 16, 6, 4, 44, 41, 9, 28, 16, 1, 9, 18, 8, 19, 15,
        0, 3, 1, 56, 5, 6, 11, 40, 12, 0, 77, 4, 10, 25, 5, 4, 20, 16, 64, 31,
        4, 19, 12, 14, 3, 75, 11, 76, 46, 23, 55, 12, 3, 10, 71, 9, 7, 56, 15,
        40, 51, 1, 5, 41, 10, 15, 24, 27, 25, 2, 10, 67, 81, 1, 1, 25, 23, 15,
        19, 36, 79, 14, 76, 81, 12, 48, 56, 17, 15, 13, 0, 12, 19, 27, 0, 3, 29,
        60, 70, 2, 4, 10, 51, 8, 49, 28, 78, 34, 56, 19, 17, 2, 6, 22, 46, 22,
        17, 35, 17, 1, 9, 2, 58, 12, 5, 21, 2, 53, 87, 39, 24, 74, 7, 6, 8, 40,
        69, 5, 27, 41, 52, 24, 44, 14, 23, 64, 44, 22, 5, 45, 14, 0, 23, 53, 3,
        5, 2, 16, 6, 13, 38, 26, 17, 5, 11, 4, 47, 36, 4, 39, 21, 6, 5, 15, 77,
        53, 19, 11, 28, 6, 36, 16, 43, 58, 8, 1, 87, 1, 14, 41, 14, 77, 15, 9,
        0, 16, 51, 34, 23, 38, 29, 67, 3, 72, 32, 0, 0, 5, 12, 19, 0, 21, 2, 39,
        26, 34, 15, 13, 89, 10, 4, 16, 31, 16, 27, 49, 10, 61, 23, 2, 67, 58, 4,
        9, 23, 43, 5, 63, 4, 20, 8, 1, 17, 28, 22, 3, 10, 14, 36, 46, 6, 6, 2,
        18, 2, 16, 25, 2, 3, 4, 17, 52, 2, 36, 6, 0, 22, 12, 47, 55, 12, 6, 6,
        30, 40, 21, 39, 8, 47, 6, 14, 8, 5, 15, 34, 13, 5, 18, 28, 6, 72, 17, 4,
        60, 14, 9, 16, 1, 18, 44, 9, 14, 95, 12, 26, 65, 62, 37, 34, 30, 6, 53,
        26, 33, 20, 44, 12, 6, 13, 5, 7, 3, 26, 6, 1, 1, 5, 8, 19, 72, 13, 10,
        9, 3, 30, 2, 10, 86, 18, 6, 4, 17, 1, 21, 10, 18, 6, 11, 33, 17, 5, 26,
        56, 1, 29, 9, 44, 21, 49, 2, 25, 33, 22, 1, 74, 77, 65, 44, 0, 18, 8, 7,
        0, 8, 12, 50, 40, 42, 5, 36, 57,
      ],
    },
    {
      name: "Rouge",
      data: [
        12, 8, 7, 26, 42, 25, 6, 6, 6, 29, 12, 0, 11, 30, 44, 40, 26, 68, 12,
        32, 21, 24, 11, 17, 27, 42, 18, 24, 50, 33, 34, 31, 52, 33, 28, 18, 20,
        25, 31, 12, 9, 27, 40, 9, 10, 32, 15, 10, 11, 39, 22, 3, 4, 23, 28, 10,
        32, 66, 14, 50, 40, 10, 12, 39, 15, 9, 3, 21, 34, 20, 62, 29, 6, 18, 30,
        52, 40, 11, 10, 45, 28, 5, 14, 11, 17, 11, 22, 6, 20, 55, 66, 31, 67,
        23, 5, 20, 22, 22, 32, 26, 26, 9, 14, 63, 19, 6, 53, 7, 29, 46, 18, 40,
        53, 20, 41, 57, 50, 30, 29, 22, 1, 12, 21, 4, 1, 12, 26, 15, 22, 67, 4,
        51, 33, 56, 33, 45, 49, 3, 28, 2, 21, 55, 2, 14, 18, 12, 68, 17, 2, 26,
        17, 17, 21, 27, 33, 19, 17, 4, 27, 17, 14, 19, 8, 54, 28, 35, 23, 39,
        56, 8, 12, 38, 20, 5, 29, 39, 10, 31, 34, 12, 2, 7, 13, 8, 64, 29, 49,
        45, 63, 36, 64, 16, 3, 7, 3, 1, 52, 34, 44, 62, 27, 37, 12, 3, 19, 14,
        20, 9, 1, 10, 1, 2, 54, 4, 23, 4, 12, 29, 43, 26, 36, 29, 10, 64, 25,
        13, 4, 9, 39, 52, 55, 16, 12, 48, 26, 9, 54, 47, 12, 52, 15, 10, 9, 26,
        48, 22, 24, 12, 26, 45, 35, 10, 5, 16, 30, 14, 16, 4, 19, 20, 44, 13, 4,
        20, 38, 66, 64, 25, 1, 47, 17, 53, 18, 20, 3, 37, 34, 4, 28, 15, 27, 6,
        8, 16, 6, 20, 20, 20, 15, 54, 40, 12, 21, 18, 11, 1, 17, 61, 18, 26, 40,
        6, 46, 25, 16, 10, 7, 42, 50, 48, 18, 10, 33, 36, 56, 29, 56, 4, 2, 5,
        22, 9, 61, 10, 57, 6, 52, 58, 1, 9, 25, 46, 13, 12, 28, 18, 53, 44, 26,
        61, 37, 5, 2, 36, 55, 17, 12, 24, 35, 33, 47, 55, 67, 50, 18, 14, 12,
        18, 48, 3, 6, 26, 34, 34, 15, 12, 11, 2, 40, 36, 37, 43, 9, 50, 32, 20,
        20, 48, 61, 8, 12, 9, 27, 10, 12, 28, 41, 32, 29, 35, 26, 16, 32, 0, 25,
        7, 21, 9, 66, 12,
      ],
    },
  ];

  public seriesOptions2 = [
    {
      name: "Vert",
      data: [
        2, 2, 39, 28, 5, 6, 17, 93, 7, 15, 25, 25, 2, 4, 18, 9, 91, 53, 49, 94,
        79, 57, 42, 4, 32, 23, 7, 15, 8, 9, 7, 22, 57, 37, 11, 53, 9, 26, 11,
        24, 13, 6, 3, 27, 41, 21, 30, 13, 16, 7, 51, 1, 83, 2, 26, 8, 18, 10,
        32, 25, 6, 72, 55, 16, 8, 6, 27, 1, 42, 4, 9, 3, 35, 19, 27, 10, 16, 49,
        46, 11, 73, 37, 1, 7, 16, 50, 96, 28, 15, 51, 1, 16, 33, 7, 85, 1, 5, 1,
        58, 53, 23, 17, 36, 46, 10, 52, 36, 2, 8, 69, 2, 6, 4, 46, 15, 45, 48,
        65, 32, 72, 19, 15, 66, 11, 35, 1, 5, 50, 7, 21, 30, 10, 1, 2, 0, 62,
        15, 13, 16, 32, 42, 15, 1, 32, 36, 0, 50, 81, 15, 38, 22, 33, 26, 3, 29,
        17, 9, 2, 2, 1, 1, 3, 7, 15, 7, 93, 69, 18, 6, 61, 0, 2, 76, 57, 20, 51,
        1, 3, 21, 48, 25, 24, 3, 0, 3, 47, 22, 29, 4, 0, 0, 30, 21, 14, 33, 3,
        44, 34, 3, 31, 13, 74, 4, 58, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 42, 37, 15, 20, 5, 34, 12, 2, 22, 3, 6, 5, 20, 43, 25,
        1, 37, 13, 48, 45, 1, 37, 1, 1, 36, 62, 11, 17, 14, 30, 6, 64, 16, 28,
        12, 0, 54, 37, 2, 65, 3, 5, 62, 22, 26, 39, 18, 4, 6, 20, 60, 15, 4, 48,
        9, 36, 1, 36, 12, 36, 25, 28, 5, 2, 65, 5, 33, 10, 51, 47, 38, 38, 17,
        11, 8, 18, 1, 30, 30, 8, 12, 20, 7, 1, 27, 26, 43, 9, 23, 26, 1, 13, 1,
        47, 4, 14, 22, 73, 76, 10, 60, 28, 1, 23, 4, 40, 63, 37, 29, 3, 18, 49,
        22, 8, 51, 17, 38, 20, 83, 72, 54, 68, 21, 9, 16, 5, 8, 35, 19, 1, 2, 6,
        1, 2, 21, 79, 1, 33, 11, 6, 1, 1, 57, 7, 5, 19, 53, 28, 66, 6, 11, 1,
        44, 5, 78, 11, 7, 5, 62, 40, 89, 16, 17, 52, 12, 55, 16, 18, 19, 11, 48,
        21, 27, 52, 29, 32, 2, 64, 13, 20, 66, 22, 51, 10, 2, 2, 6, 4, 2, 27,
        19, 1, 15, 4,
      ],
    },
    {
      name: "Jaune",
      data: [
        26, 34, 15, 13, 89, 10, 4, 16, 31, 16, 27, 49, 10, 61, 23, 2, 67, 58, 4,
        9, 23, 43, 5, 63, 4, 20, 8, 1, 17, 28, 22, 3, 10, 14, 36, 46, 6, 6, 2,
        18, 2, 16, 25, 2, 3, 4, 17, 52, 2, 36, 6, 0, 22, 12, 47, 55, 12, 6, 6,
        30, 40, 21, 39, 8, 47, 6, 14, 8, 5, 15, 34, 13, 5, 18, 28, 6, 72, 17, 4,
        60, 14, 9, 16, 1, 18, 44, 9, 14, 95, 12, 26, 65, 62, 37, 34, 30, 6, 53,
        26, 33, 20, 44, 12, 6, 13, 5, 7, 3, 26, 6, 1, 1, 5, 8, 19, 72, 13, 10,
        9, 3, 30, 2, 10, 86, 18, 6, 4, 17, 1, 21, 10, 18, 6, 11, 33, 17, 5, 26,
        56, 1, 29, 9, 44, 21, 49, 2, 25, 33, 22, 1, 74, 77, 65, 44, 0, 18, 8, 7,
        0, 8, 12, 50, 40, 42, 5, 36, 57, 25, 94, 32, 18, 10, 0, 4, 40, 55, 63,
        35, 12, 25, 64, 0, 50, 30, 32, 35, 55, 9, 23, 4, 49, 16, 16, 6, 4, 44,
        41, 9, 28, 16, 1, 9, 18, 8, 19, 15, 0, 3, 1, 56, 5, 6, 11, 40, 12, 0,
        77, 4, 10, 25, 5, 4, 20, 16, 64, 31, 4, 19, 12, 14, 3, 75, 11, 76, 46,
        23, 55, 12, 3, 10, 71, 9, 7, 56, 15, 40, 51, 1, 5, 41, 10, 15, 24, 27,
        25, 2, 10, 67, 81, 1, 1, 25, 23, 15, 19, 36, 79, 14, 76, 81, 12, 48, 56,
        17, 15, 13, 0, 12, 19, 27, 0, 3, 29, 60, 70, 2, 4, 10, 51, 8, 49, 28,
        78, 34, 56, 19, 17, 2, 6, 22, 46, 22, 17, 35, 17, 1, 9, 2, 58, 12, 5,
        21, 2, 53, 87, 39, 24, 74, 7, 6, 8, 40, 69, 5, 27, 41, 52, 24, 44, 14,
        23, 64, 44, 22, 5, 45, 14, 0, 23, 53, 3, 5, 2, 16, 6, 13, 38, 26, 17, 5,
        11, 4, 47, 36, 4, 39, 21, 6, 5, 15, 77, 53, 19, 11, 28, 6, 36, 16, 43,
        58, 8, 1, 87, 1, 14, 41, 14, 77, 15, 9, 0, 16, 51, 34, 23, 38, 29, 67,
        3, 72, 32, 0, 0, 5, 12, 19, 0, 21, 2, 39,
      ],
    },
    {
      name: "Rouge",
      data: [
        45, 63, 36, 64, 16, 3, 7, 3, 1, 52, 34, 44, 62, 27, 37, 12, 3, 19, 14,
        20, 9, 1, 10, 1, 2, 54, 4, 23, 4, 12, 29, 43, 26, 36, 29, 10, 64, 25,
        13, 4, 9, 39, 52, 55, 16, 12, 48, 26, 9, 54, 47, 12, 52, 15, 10, 9, 26,
        48, 22, 24, 12, 26, 45, 35, 10, 5, 16, 30, 14, 16, 4, 19, 20, 44, 13, 4,
        20, 38, 66, 64, 25, 1, 47, 17, 53, 18, 20, 3, 37, 34, 4, 28, 15, 27, 6,
        8, 16, 6, 20, 20, 20, 15, 54, 40, 12, 21, 18, 11, 1, 17, 61, 18, 26, 40,
        6, 46, 25, 16, 10, 7, 42, 50, 48, 18, 10, 33, 36, 56, 29, 56, 4, 2, 5,
        22, 9, 61, 10, 57, 6, 52, 58, 1, 9, 25, 46, 13, 12, 28, 18, 53, 44, 26,
        61, 37, 5, 2, 36, 55, 17, 12, 24, 35, 33, 47, 55, 67, 50, 18, 14, 12,
        18, 48, 3, 6, 26, 34, 34, 15, 12, 11, 2, 40, 36, 37, 43, 9, 50, 32, 20,
        20, 48, 61, 8, 12, 9, 27, 10, 12, 28, 41, 32, 29, 35, 26, 16, 32, 0, 25,
        7, 21, 9, 66, 12, 12, 8, 7, 26, 42, 25, 6, 6, 6, 29, 12, 0, 11, 30, 44,
        40, 26, 68, 12, 32, 21, 24, 11, 17, 27, 42, 18, 24, 50, 33, 34, 31, 52,
        33, 28, 18, 20, 25, 31, 12, 9, 27, 40, 9, 10, 32, 15, 10, 11, 39, 22, 3,
        4, 23, 28, 10, 32, 66, 14, 50, 40, 10, 12, 39, 15, 9, 3, 21, 34, 20, 62,
        29, 6, 18, 30, 52, 40, 11, 10, 45, 28, 5, 14, 11, 17, 11, 22, 6, 20, 55,
        66, 31, 67, 23, 5, 20, 22, 22, 32, 26, 26, 9, 14, 63, 19, 6, 53, 7, 29,
        46, 18, 40, 53, 20, 41, 57, 50, 30, 29, 22, 1, 12, 21, 4, 1, 12, 26, 15,
        22, 67, 4, 51, 33, 56, 33, 45, 49, 3, 28, 2, 21, 55, 2, 14, 18, 12, 68,
        17, 2, 26, 17, 17, 21, 27, 33, 19, 17, 4, 27, 17, 14, 19, 8, 54, 28, 35,
        23, 39, 56, 8, 12, 38, 20, 5, 29, 39, 10, 31, 34, 12, 2, 7, 13, 8, 64,
        29, 49,
      ],
    },
  ];
  public names = ["vert", "orange", "rouge"];
  public colors = ["#0fba31", "#db9f30", "#ff0015"];

  public optionsNtg: any = {
    chart: {
      type: "column",
      width: 500,
    },
    colors: this.colors,
    title: {
      text: "Répartition du Niveau de Tension Global (NTG) dans le temps",
    },
    credits: {
      enabled: false,
    },
    yAxis: {
      min: 0,
      max: 100,
      title: {
        text: "NTG",
      },
    },
    xAxis: {
      labels: {
        enabled: false,
      },
      title: { text: "Date" },
      type: "number",
      min: 0,
      max: 300,
    },
    // xAxis: {
    //   labels: {
    //     format: "{value:%b %e}",
    //     enabled: false,
    //   },
    //   tickInterval: 604800000,
    //   type: "datetime",
    //   min: 1569888000000, // 2019-10-01T00.00.00.000Z
    // },
    // xAxis: {
    //   categories: ["01/01", "02/01", "03/01", "04/01", "05/01", "06/01"],
    // },
    plotOptions: {
      column: {
        stacking: "normal",
      },
    },
    series: this.seriesOptions,
  };

  constructor() {}

  ngOnInit() {
    Highcharts.chart("container", this.options);
    Highcharts.chart("containerNtg", this.optionsNtg);
  }
}
