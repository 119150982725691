import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MobileCallRange } from '../../model/activity/mobile-call-range.model';

@Injectable({
  providedIn: 'root'
})
export class MobileCallRangeService {
  constructor(private httpClient: HttpClient) {}

  public getMobileCallRange(
    id: number,
    yearMonth: string
  ): Observable<MobileCallRange> {
    return this.httpClient.get<MobileCallRange>(
      `api/effectors/${id}/activities/${yearMonth}/call`
    );
  }

  public setMobileCallRange(
    id: number,
    yearMonth: string,
    mobileCallRange: MobileCallRange
  ): Observable<MobileCallRange> {
    return this.httpClient.post<MobileCallRange>(
      `api/effectors/${id}/activities/${yearMonth}/call`,
      mobileCallRange
    );
  }
}
