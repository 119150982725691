import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PdsaPosteActivityFormComponent } from '../pdsa-poste-activity-form.component';
import { PostedConsultationEntryService } from 'src/app/pdsa/service/activity/posted-consultation-entry.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { State } from 'src/app/pdsa/model/activity/effector-state.enum';
import { EventType } from 'src/app/pdsa/model/activity/effector-event-type.enum';

@Component({
  selector: 'app-pdsa-poste-activity-tab-three-component',
  templateUrl: './pdsa-poste-activity-tab-three.component.html',
  styleUrls: ['./pdsa-poste-activity-tab-three.component.scss']
})
export class PdsaPosteActivityTabThreeComponent implements OnInit {
  currentPage: number;
  step: number = 3;
  valueHasChanged: boolean = false;

  commentForm: FormGroup = this.fb.group({
    comment: ['']
  });

  @Input() form!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private pdsaPosteActivityFormComponent: PdsaPosteActivityFormComponent,
    private postedConsultationEntryService: PostedConsultationEntryService
  ) {}

  ngOnInit(): void {
    this.form.addControl(
      'tab-three-form',
      this.fb.group({
        test: 'test'
      })
    );
    this.commentForm
      .get('comment')
      ?.setValue(this.pdsaPosteActivityFormComponent.commentText);
    setTimeout(() => {
      this.pdsaPosteActivityFormComponent.activeTab = 3;
    }, 100);
  }

  updateState(event: EventType, dataTabTwo: any) {
    switch (event) {
      case EventType.SUBMIT:
        dataTabTwo.entry.state = State.COMPLETED;
        break;
      default:
        if (dataTabTwo.entry.state !== State.COMPLETED) {
          dataTabTwo.entry.state = State.UNCOMPLETED;
        }
        break;
    }
  }

  async onSubmit(event: EventType) {
    const id = this.pdsaPosteActivityFormComponent.effectorId;
    const yearMonth = this.pdsaPosteActivityFormComponent.effectorYearMonth;

    const dataTabTwo =
      await this.pdsaPosteActivityFormComponent.getPostedConsultationEntries(
        id,
        yearMonth
      );
    dataTabTwo.entry.comment = this.commentForm.get('comment')?.value;

    this.updateState(event, dataTabTwo);

    this.postedConsultationEntryService
      .setPostedConsultationEntries(id, yearMonth, dataTabTwo)
      .subscribe((data) => {
        this.pdsaPosteActivityFormComponent.commentText =
          this.commentForm.get('comment')?.value;
        switch (event) {
          case EventType.SUBMIT:
            this.router.navigateByUrl('/pdsa-structure-activity').then(() => {
              Swal.fire({
                title: 'Données sauvegardées avec succès !',
                icon: 'success',
                confirmButtonColor: '#2B8CA9',
                confirmButtonText: 'OK'
              });
            });
            break;
          case EventType.NAVIGATE_PREVIOUS:
            this.pdsaPosteActivityFormComponent.selectPreviousTab();
            break;
          case EventType.CLOSE:
            this.router.navigateByUrl('/pdsa-structure-activity').then(() => {
              Swal.fire({
                title: 'Données sauvegardées avec succès !',
                icon: 'success',
                confirmButtonColor: '#2B8CA9',
                confirmButtonText: 'OK'
              });
            });
          default:
            break;
        }
      });
  }

  onInputChange() {
    this.valueHasChanged = true;
  }

  async onNavigateSubmit() {
    if (this.valueHasChanged) {
      Swal.fire({
        title: 'Vos dernières modifications ont été enregistrées !',
        icon: 'success',
        confirmButtonColor: '#2B8CA9',
        confirmButtonText: 'OK'
      });
    }
    this.onSubmit(EventType.NAVIGATE_TO_TAB);
  }
}
