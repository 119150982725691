import {
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "src/environments/environment";
import { Account } from "./core/auth/account.model";
import { AccountService } from "./core/auth/account.service";
import { LoginService } from "./login/login.service";
import { CommonModule, DOCUMENT } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CookieService } from "ngx-cookie-service";
import { Favicons } from "./emergency-depts/services/favicons.service";
import { AnalyticsService } from "./analytics/analytics.service";
import { Alert } from "./emergency-depts/model/alert.model";
import { AppRoutes } from "./emergency-depts/routes.enum";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements AfterViewChecked {
  AppRoutes = AppRoutes;

  account: Account | null = null;
  innerWidth: number;
  closeResult = "";
  selectedTab = "";
  currentRoute: any;
  viewChecked: any;
  viewChild = false;
  url = "";
  openCloseMenu = false;
  cookieValue: string;
  private favicons: Favicons;
  alerts: Alert[] = [];

  constructor(
    private loginService: LoginService,
    private accountService: AccountService,
    private titleService: Title,
    private modalService: NgbModal,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cookieService: CookieService,
    private analyticsService: AnalyticsService,
    favicons: Favicons
  ) {
    this.favicons = favicons;
    this.innerWidth = window.innerWidth;
    this.titleService.setTitle(environment.description);
    this.accountService.identity().subscribe((account) => {
      this.account = account;
      console.warn("account change", account);
    });
    this.activatedRoute.queryParamMap.subscribe((params) => {
      if (params.has("connectedTo")) {
        this.login();
      }
      //TODO
      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe((x) => {
          let url = x as NavigationEnd;
          this.url = url.url;
          this.isActive(this.url);
        });
    });

    console.log("taille écran:", window.innerWidth, window.innerHeight);
  }

  //WORKING FAVICON
  ngOnInit() {
    const name = this.isAraPage() ? "84" : "11";
    this.favicons.activate(name);
  }

  refresh(): void {
    window.location.reload();
  }
  getInitials(): string | undefined {
    if (!this.account || !this.account.firstName || !this.account.lastName) {
      return "";
    }
    return (
      this.account.firstName.slice(0, 1) + this.account.lastName.slice(0, 1)
    );
  }

  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  login(): void {
    this.loginService.login();
  }

  logout(): void {
    this.loginService.logout();
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  isAraPage() {
    const str = window.location.href;
    return str.includes("ara") || str.includes(":4201/");
  }

  isIframe() {
    const str = window.location.href;
    return (
      str.includes("meteo-urgences") ||
      str.includes("meteo-samu") ||
      str.includes("snp-qualite") ||
      str.includes("mes-urgences") ||
      str.includes("mes-urgences-pro") ||
      str.includes("status")
    );
  }

  isAlert() {
    const location = window.location.href;
    console.log("location", location);
  }

  // isHomePage() {
  //   const str = window.location.href;
  //   return str.includes("accueil");
  // }

  isPreprod() {
    const str = window.location.href;
    return str.includes("ppr");
  }

  ngAfterViewChecked(): void {
    // recup cookie
    // alert("Connexion de l'utilisateur (" + this.cookie.get("email"));
    if (
      (this.account !== null &&
        this.viewChild === false &&
        this.url === "/" &&
        window.innerWidth > 768) ||
      (this.account !== null &&
        this.viewChild === false &&
        window.innerWidth > 1200)
    ) {
      // let inputCheked = document.getElementById("check")! as HTMLInputElement;
      // inputCheked.checked = true;
      this.viewChild = true;
    }
    if (this.account !== null) {
      this.cookieService.set("TOKEN_UUID", this.account.email);
    }
  }

  isActive(tabName: string) {
    this.selectedTab = tabName;
    if (this.account !== null) {
      this.openCloseMenu = false;
      console.log("taille:", window.innerWidth);
    }
  }

  closeMenu() {
    if (window.innerWidth < 577) {
      this.openCloseMenu = false;
    }
  }

  // ngAfterContentInit() {
  //     if(this.viewChecked === false) {
  //     let event = new Event('click')

  //     this.buttonSearch.nativeElement.dispatchEvent(event)

  //     console.log(this.viewChecked)
  //     this.viewChecked = true;
  //     console.log(this.viewChecked)
  //     }
  // }

  onSearch() {
    throw new Error("Method not implemented.");
  }
}
