import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { PdsaPosteActivityTabOneComponent } from '../pdsa-poste-activity-tab-one/pdsa-poste-activity-tab-one.component';
import { PdsaPosteActivityTabTwoComponent } from '../pdsa-poste-activity-tab-two/pdsa-poste-activity-tab-two.component';
import { PdsaPosteActivityTabThreeComponent } from '../pdsa-poste-activity-tab-three/pdsa-poste-activity-tab-three.component';
import { PostedConsultationRangeService } from 'src/app/pdsa/service/activity/posted-consultation-range.service';
import { PdsaPosteActivityFormComponent } from '../pdsa-poste-activity-form.component';
import { PostedConsultationEntryService } from 'src/app/pdsa/service/activity/posted-consultation-entry.service';
import { FormGroup } from '@angular/forms';
@Component({
  selector: 'app-pdsa-poste-activity-pdf',
  templateUrl: './pdsa-poste-activity-pdf.component.html',
  styleUrls: ['./pdsa-poste-activity-pdf.component.scss']
})
export class PdsaPosteActivityPDFComponent implements OnInit {
  @ViewChild(PdsaPosteActivityTabOneComponent)
  childComponentRef1: PdsaPosteActivityTabOneComponent;
  @ViewChild(PdsaPosteActivityTabTwoComponent)
  childComponentRef2: PdsaPosteActivityTabTwoComponent;
  @ViewChild(PdsaPosteActivityTabThreeComponent)
  childComponentRef3: PdsaPosteActivityTabThreeComponent;
  @ViewChild('content') content: ElementRef;
  @Input() form!: FormGroup;

  effectorShortLabel: string;
  effectorDate: string;
  items = ['Exemple 1'];
  effectorId: number;
  effectorYearMonth: string;
  timeSlotLabels: string[] = [];
  timeSlotDayTypes: string[] = [];
  postedConsultationEntryValues: { count: string; crraCount: string } = {
    count: '',
    crraCount: ''
  };
  postedConsultationRangeValues: any[] = [];
  commentText: string = '';
  hasCommentEntered = false;
  dateHourExport: string;

  constructor(
    private postedConsultationRangeService: PostedConsultationRangeService,
    private pdsaPosteActivityFormComponent: PdsaPosteActivityFormComponent,
    private postedConsultationEntryService: PostedConsultationEntryService
  ) {}

  capitalizeFirstLetter(string: string | undefined): string {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    } else {
      return '';
    }
  }

  getPostedConsultationRanges() {
    this.postedConsultationRangeService
      .getPostedConsultationRanges(
        this.pdsaPosteActivityFormComponent.effectorId,
        this.pdsaPosteActivityFormComponent.effectorYearMonth
      )
      .subscribe((selectedPostedConsultationRange) => {
        selectedPostedConsultationRange.elements.map((element) => {
          this.timeSlotLabels.push(element.timeSlot.label);
          this.timeSlotDayTypes.push(element.timeSlot.dayType);
          this.postedConsultationRangeValues.push(element.count);
        });
      });
  }

  getPostedConsultationEntries() {
    this.postedConsultationEntryService
      .getPostedConsultationEntries(
        this.pdsaPosteActivityFormComponent.effectorId,
        this.pdsaPosteActivityFormComponent.effectorYearMonth
      )
      .subscribe((selectedPostedConsultationEntry) => {
        this.effectorShortLabel =
          selectedPostedConsultationEntry?.entry?.effector?.shortLabel;

        const dateString = selectedPostedConsultationEntry?.entry.yearMonth;
        const dateObject = new Date(dateString);
        const monthString = dateObject.toLocaleString('default', {
          month: 'long'
        });
        const year = dateObject.getFullYear();
        this.effectorDate = `${monthString} ${year}`;
        this.postedConsultationEntryValues.count =
          selectedPostedConsultationEntry.count;
        this.postedConsultationEntryValues.crraCount =
          selectedPostedConsultationEntry.crraCount;
      });
  }

  ngOnInit(): void {
    this.getPostedConsultationRanges();
    this.getPostedConsultationEntries();
    if (this.pdsaPosteActivityFormComponent.hasCommentEntered === true) {
      this.hasCommentEntered = true;
    }
    this.commentText = this.pdsaPosteActivityFormComponent.commentText ?? '';
    this.dateHourExport = this.pdsaPosteActivityFormComponent.dateHourExport;
  }
}
