<div class="export_content">
  <div class="mx-5 my-5">
    <div style="display: flex; justify-content: end">
      <div>{{ dateHourExport }}</div>
    </div>
    <div class="row mb-4 mt-3">
      <div class="col-12">
        <h2 class="mb-0">
          {{ effectorShortLabel }}
          -
          {{ effectorDate ? capitalizeFirstLetter(effectorDate) : '' }}
        </h2>
      </div>
    </div>
  </div>
  <div class="card mx-auto my-auto">
    <h1 style="font-size: larger">Activités pendant et hors PDSA</h1>
    <table class="table table-striped shadow-sm p-3 bg-white">
      <thead>
        <tr class="noselect header-row">
          <th
            style="max-width: 26vw; width: 26vw; padding: 0px; margin: 0px"
          ></th>
          <th style="max-width: 4vw; padding: 0px; margin: 0px">
            <p class="m-1" style="color: #ffffff">
              Activité aux heures de la PDSA
            </p>
          </th>
          <th style="max-width: 4vw; padding: 0px; margin: 0px">
            <p class="m-1" style="color: #ffffff">
              Activité en dehors des heures de la PDSA
            </p>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="cursor noselect hover-effect">
          <td>
            <div class="d-flex align-items-center">
              <div class="line-name">Nombre total d'appels décrochés</div>
            </div>
          </td>
          <td>
            <div class="d-flex justify-content-center align-items-center">
              <div type="text" class="rounded border inputSize">
                {{ mobileVolumeEntryValues.openedAnsweredCallCount }}
              </div>
            </div>
          </td>
          <td>
            <div class="d-flex justify-content-center align-items-center">
              <div type="text" class="rounded border inputSize">
                {{ mobileVolumeEntryValues.closedAnsweredCallCount }}
              </div>
            </div>
          </td>
        </tr>
        <tr class="cursor noselect hover-effect">
          <td>
            <div class="d-flex align-items-center">
              <div class="line-name">Nombre total de visites</div>
            </div>
          </td>
          <td>
            <div class="d-flex justify-content-center align-items-center">
              <div type="text" class="rounded border inputSize">
                {{ mobileVolumeEntryValues.openedVisitCount }}
              </div>
            </div>
          </td>
          <td>
            <div class="d-flex justify-content-center align-items-center">
              <div type="text" class="rounded border inputSize">
                {{ mobileVolumeEntryValues.closedVisitCount }}
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="export_content" *ngFor="let size of sizes; let i = index">
  <div class="card mx-auto mb-auto mt-3">
    <h1 style="font-size: larger">Visites par territoire</h1>
    <div>
      <table class="table table-striped shadow-sm p-3 bg-white">
        <thead style="position: sticky; top: 0; opacity: 1">
          <tr class="noselect header-row">
            <th
              style="
                max-width: 15vw;
                width: 15vw;
                padding: 0px;
                margin: 0px;
                position: sticky;
                top: 0;
                opacity: 1 !important;
                background-color: #2b8ca9 !important;
              "
            >
              <p style="color: #ffffff; opacity: 1">
                Nombre de visites effectuées par territoire
              </p>
            </th>
            <th
              *ngFor="let timeSlotLabel of timeSlotLabelsTabTwo; let i = index"
              style="
                max-width: 4vw;
                margin: 0px;
                position: sticky;
                top: 0;
                opacity: 1;
              "
            >
              <p style="color: #ffffff">{{ timeSlotLabel }}</p>
              <p style="color: #ffffff">{{ timeSlotDayTypesTabTwo[i] }}</p>
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container
            *ngFor="let territoryName of territoryNames; let i = index"
          >
            <tr
              *ngIf="
                (i + 1) / lineNumberDivider <= size &&
                (i + 1) / lineNumberDivider > size - 1
              "
              class="cursor noselect hover-effect"
            >
              <td class="ml-2">
                <div>{{ territoryName }}</div>
              </td>

              <ng-container
                *ngFor="
                  let elementGroup of mobileTerritoryVisitRangeValues[i];
                  let j = index
                "
              >
                <ng-container>
                  <td>
                    <div
                      class="d-flex justify-content-center align-items-center"
                    >
                      <div
                        type="text"
                        class="rounded border inputSize"
                        style="width: 4vw; height: 2vw"
                      >
                        {{ elementGroup }}
                      </div>
                    </div>
                  </td>
                </ng-container>
              </ng-container>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div class="export_content">
  <div class="card mx-auto mb-auto mt-3">
    <h1 style="font-size: larger">Visites CRRA 15</h1>
    <table class="table table-striped shadow-sm p-3 bg-white my-3">
      <thead>
        <tr class="noselect header-row">
          <th
            style="max-width: 15vw; width: 15vw; padding: 0px; margin: 0px"
          ></th>
          <th
            *ngFor="let timeSlotLabel of timeSlotLabels; let i = index"
            style="max-width: 4vw; margin: 0px"
          >
            <p style="color: #ffffff">{{ timeSlotLabel }}</p>
            <p style="color: #ffffff">{{ timeSlotDayTypes[i] }}</p>
          </th>
        </tr>
      </thead>
      <tbody id="bodyTableTwo">
        <tr
          *ngFor="let item of items; let i = index"
          class="cursor noselect hover-effect"
        >
          <td>
            <div class="d-flex align-items-center">
              <div>
                Nombre de visites effectuées à la demande directe du CRRA15
              </div>
            </div>
          </td>
          <td
            *ngFor="
              let countControl of mobileCrraVisitRangeValues;
              let i = index
            "
          >
            <div class="d-flex justify-content-center align-items-center">
              <div
                type="text"
                class="rounded border inputSize"
                style="width: 4vw; height: 2vw"
              >
                {{ countControl }}
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="export_content">
  <div class="card mx-auto mb-auto mt-3">
    <h1 style="font-size: larger">Certif. Décès</h1>
    <table class="table table-striped shadow-sm p-3 bg-white">
      <thead>
        <tr class="noselect header-row">
          <th
            style="max-width: 15vw; width: 15vw; padding: 0px; margin: 0px"
          ></th>
          <th
            *ngFor="let timeSlotLabel of timeSlotLabelsTabFour; let i = index"
            style="max-width: 4vw; margin: 0px"
          >
            <p style="color: #ffffff">{{ timeSlotLabel }}</p>
            <p style="color: #ffffff">{{ timeSlotDayTypesTabFour[i] }}</p>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="cursor noselect hover-effect">
          <td>
            <div class="d-flex align-items-center">
              <div>
                Nombre total de visites à domicile effectuées pour certificat de
                décès
              </div>
            </div>
          </td>

          <td
            *ngFor="
              let elementGroup of mobileDeathVisitRangeValues.count;
              let i = index
            "
          >
            <div class="d-flex justify-content-center align-items-center">
              <div
                type="text"
                class="rounded border inputSize"
                style="width: 4vw; height: 2vw"
              >
                {{ elementGroup }}
              </div>
            </div>
          </td>
        </tr>
        <tr class="cursor noselect hover-effect">
          <td>
            <div class="d-flex align-items-center">
              <div>
                Nombre de visites à domicile effectuées pour certificat de décès
                à la demande directe du CRRA15
              </div>
            </div>
          </td>
          <td
            *ngFor="
              let elementGroup of mobileDeathVisitRangeValues.crraCount;
              let i = index
            "
          >
            <div class="d-flex justify-content-center align-items-center">
              <div
                type="text"
                class="rounded border inputSize"
                style="width: 4vw; height: 2vw"
              >
                {{ elementGroup }}
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div *ngIf="isBooleanPlatform" class="export_content">
  <div class="card mx-auto mb-auto mt-3">
    <h1 style="font-size: larger">Appels</h1>
    <table
      *ngIf="isBooleanPlatform"
      class="table table-striped shadow-sm p-3 bg-white"
    >
      <thead>
        <tr class="noselect header-row">
          <th
            style="max-width: 15vw; width: 15vw; padding: 0px; margin: 0px"
          ></th>
          <th
            *ngFor="let timeSlotLabel of timeSlotLabelsTabFive; let i = index"
            style="max-width: 4vw; margin: 0px"
          >
            <p style="color: #ffffff">{{ timeSlotLabel }}</p>
            <p style="color: #ffffff">{{ timeSlotDayTypesTabFive[i] }}</p>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="cursor noselect hover-effect">
          <td>
            <div class="d-flex align-items-center">
              <div>Nombre d'appels entrants</div>
            </div>
          </td>
          <td
            *ngFor="
              let elementGroup of mobileCallRangeValues.callCount;
              let i = index
            "
          >
            <div class="d-flex justify-content-center align-items-center">
              <div
                type="text"
                class="rounded border inputSize"
                style="width: 4vw; height: 2vw"
              >
                {{ elementGroup }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="d-flex align-items-center">
              <div>Nombre d'appels décrochés</div>
            </div>
          </td>
          <td
            *ngFor="
              let elementGroup of mobileCallRangeValues.answeredCount;
              let i = index
            "
          >
            <div class="d-flex justify-content-center align-items-center">
              <div
                type="text"
                class="rounded border inputSize"
                style="width: 4vw; height: 2vw"
              >
                {{ elementGroup }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="d-flex align-items-center">
              <div>
                Nombre d'appels pris en charge par un médecin présent sur la
                plateforme
              </div>
            </div>
          </td>
          <td
            *ngFor="
              let elementGroup of mobileCallRangeValues.answeredCountByDoctorOnSite;
              let i = index
            "
          >
            <div class="d-flex justify-content-center align-items-center">
              <div
                type="text"
                class="rounded border inputSize"
                style="width: 4vw; height: 2vw"
              >
                {{ elementGroup }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="d-flex align-items-center">
              <div>
                Nombre d'appels pris en charge par un médecin non présent sur la
                plateforme
              </div>
            </div>
          </td>
          <td
            *ngFor="
              let elementGroup of mobileCallRangeValues.answeredCountByDoctorOffSite;
              let i = index
            "
          >
            <div class="d-flex justify-content-center align-items-center">
              <div
                type="text"
                class="rounded border inputSize"
                style="width: 4vw; height: 2vw"
              >
                {{ elementGroup }}
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div *ngIf="isBooleanPlatform" class="export_content">
  <div class="card mx-auto mb-auto mt-3">
    <h1 style="font-size: larger">Décisions</h1>
    <table
      *ngIf="isBooleanPlatform"
      class="table table-striped shadow-sm p-3 bg-white"
    >
      <thead>
        <tr class="noselect header-row">
          <th
            style="
              max-width: 15vw;
              width: 15vw;
              padding: 0px;
              margin: 0px;
              font-weight: normal;
            "
          >
            Nombre de décisions
          </th>

          <th
            *ngFor="let timeSlotLabel of timeSlotLabelsTabSix; let i = index"
            style="max-width: 4vw; margin: 0px"
          >
            <p style="color: #ffffff">{{ timeSlotLabel }}</p>
            <p style="color: #ffffff">{{ timeSlotDayTypesTabSix[i] }}</p>
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container
          *ngFor="let decisionTypeLabel of decisionTypeLabels; let i = index"
        >
          <tr class="cursor noselect hover-effect">
            <td class="ml-2">{{ decisionTypeLabel }}</td>

            <ng-container
              *ngFor="
                let elementGroup of mobileDecisionTypeRangeValues[i];
                let j = index
              "
            >
              <ng-container>
                <td>
                  <div class="d-flex justify-content-center align-items-center">
                    <div
                      type="text"
                      class="rounded border inputSize"
                      style="width: 4vw; height: 2vw"
                    >
                      {{ elementGroup }}
                    </div>
                  </div>
                </td>
              </ng-container>
            </ng-container>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
<div *ngIf="hasCommentEntered" class="export_content">
  <div class="card mx-auto mb-auto mt-3">
    <h1 style="font-size: larger" class="mb-3">Commentaire</h1>
    <div class="comment-item">
      <textarea
        class="commentInput"
        maxlength="450"
        placeholder="Modifier votre commentaire..."
        >{{ commentText }}</textarea
      >
    </div>
  </div>
</div>
