<body >
  <div  class="div-alert">
  <alert class="alert"></alert>
  </div>
  <div class="container-fluid" style="padding-top:2rem;">
    <div class="row">
      <div class="filtres col-12">
        <app-graph-filter
          (doFilter)="filter($event)"
          [graphIndicateurCharge]="true"
        ></app-graph-filter>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <app-charge-graph [displayPeriod2]="displayPeriod2"></app-charge-graph>
      </div>
    </div>
  </div>
</body>
