import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { ButtonClickService } from '../shared/service/button-click.service';
import { switchMap, take } from 'rxjs/operators';

@Directive({
  selector: '[appDisableDoubleClick]',
})
export class DisableDoubleClickDirective {
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private buttonClickService: ButtonClickService,

  ) {}

  @HostListener('click') onClick(): void {
    this.buttonClickService.getClickStatus().pipe(
      take(1),
      switchMap((canClick) => {
        if (canClick) {
          // Disable the button
          this.renderer.setAttribute(this.el.nativeElement, 'disabled', 'true');

          this.buttonClickService.setClickStatus(false);

          return new Promise<void>((resolve) => {
            setTimeout(() => {
              // Enable the button after 3000 milliseconds
              this.renderer.removeAttribute(this.el.nativeElement, 'disabled');
              resolve();
            }, 3000);
          });
        } else {
          return Promise.resolve();
        }
      })
    ).subscribe(() => {
      this.buttonClickService.setClickStatus(true);
    });
  }
}