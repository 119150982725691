import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { NtgDate } from "../models/ntg-date.model";
import { NtgPourcentage } from "../models/ntg-pourcentage.model";

@Injectable({
  providedIn: "root",
})
export class IndicateurNtgService {
  constructor(private httpClient: HttpClient) {}

  getNtgDates(
    startDate: Date,
    endDate: Date,
    emergencyDeptId: number
  ): Observable<NtgDate[]> {
    const params = {
      startDate: this.dateToString(startDate),
      endDate: this.dateToString(endDate),
      emergencyDeptId,
    };
    return this.httpClient.get<NtgDate[]>(`/api/indicateurs-ntg`, { params });
  }

  getNtgPercentages(
    startDate: Date,
    endDate: Date,
    departmentIds?: string[],
    emergencyDeptIds?: string[]
  ): Observable<NtgPourcentage[]> {
    const params: { [_: string]: string | string[] | number[]} = {
      startDate: this.dateToString(startDate),
      endDate: this.dateToString(endDate),
    };
    if (departmentIds !== undefined) {
      params.departmentIds = departmentIds;
    }
    if (emergencyDeptIds !== undefined) {
      params.emergencyDeptIds = emergencyDeptIds;
    }
    return this.httpClient.get<NtgPourcentage[]>(
      `/api/indicateurs-ntg/percentages`,
      { params }
    );
  }

  private dateToString(date: Date): string {
    const month = `${date.getMonth() + 1}`;
    const day = `${date.getDate()}`;
    return (
      date.getFullYear() +
      "-" +
      ("00" + month).substring(month.length) +
      "-" +
      ("00" + day).substring(day.length)
    );
  }
}
