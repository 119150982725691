import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { PdsaActivityParentFormComponent } from '../pdsa-activity-parent-form.component';
import { EventType } from 'src/app/pdsa/model/activity/effector-event-type.enum';
import { State } from 'src/app/pdsa/model/activity/effector-state.enum';
import Swal from 'sweetalert2';
import { MobileEffectorVolumeEntryService } from 'src/app/pdsa/service/activity/mobile-effector-volume-entry.service';

@Component({
  selector: 'app-pdsa-activity-tab-seven',
  templateUrl: './pdsa-activity-tab-seven.component.html',
  styleUrls: ['./pdsa-activity-tab-seven.component.scss']
})
export class PdsaActivityTabSevenComponent implements OnInit {
  currentPage: number;
  step: number = this.pdsaActivityParentFormComponent.isBooleanPlatform ? 7 : 5;
  valueHasChanged: boolean = false;

  commentForm: FormGroup = this.fb.group({
    comment: ['']
  });

  @Input() form!: FormGroup;
  @Output() onSubmitEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private pdsaActivityParentFormComponent: PdsaActivityParentFormComponent,
    private mobileVolumeEntryService: MobileEffectorVolumeEntryService
  ) {}

  ngOnInit(): void {
    this.form.addControl(
      'tab-seven-form',
      this.fb.group({
        test: 'test'
      })
    );
    this.pdsaActivityParentFormComponent.activeTab = this
      .pdsaActivityParentFormComponent.isBooleanPlatform
      ? 7
      : 5;
    this.commentForm
      .get('comment')
      ?.setValue(this.pdsaActivityParentFormComponent.commentText);
    setTimeout(() => {
      this.pdsaActivityParentFormComponent.activeTab = this
        .pdsaActivityParentFormComponent.isBooleanPlatform
        ? 7
        : 5;
    }, 100);
  }

  updateState(event: EventType, dataTabFour: any) {
    switch (event) {
      case EventType.SUBMIT:
        dataTabFour.entry.state = State.COMPLETED;
        break;
      default:
        if (dataTabFour.entry.state !== State.COMPLETED) {
          dataTabFour.entry.state = State.UNCOMPLETED;
        }
        break;
    }
  }

  onInputChange() {
    this.valueHasChanged = true;
    this.pdsaActivityParentFormComponent.hasCommentChanged = true;
  }

  async onSubmit(event: EventType) {
    const id = this.pdsaActivityParentFormComponent.effectorId;
    const yearMonth = this.pdsaActivityParentFormComponent.effectorYearMonth;

    const dataTabFour =
      await this.pdsaActivityParentFormComponent.getMobileVolumeEntry(
        id,
        yearMonth
      );
    dataTabFour.entry.comment = this.commentForm.get('comment')?.value;

    if (event === EventType.NAVIGATE_TO_TAB && this.valueHasChanged) {
      if (this.valueHasChanged) {
        Swal.fire({
          title: 'Vos dernières modifications ont été enregistrées !',
          icon: 'success',
          confirmButtonColor: '#2B8CA9',
          confirmButtonText: 'OK'
        });
      }

      this.updateState(event, dataTabFour);

      this.mobileVolumeEntryService
        .setMobileVolumeEntry(id, yearMonth, dataTabFour)
        .subscribe((data) => {
          this.pdsaActivityParentFormComponent.commentText =
            this.commentForm.get('comment')?.value;
        });
      return;
    }
    this.updateState(event, dataTabFour);

    this.mobileVolumeEntryService
      .setMobileVolumeEntry(id, yearMonth, dataTabFour)
      .subscribe((data) => {
        this.pdsaActivityParentFormComponent.commentText = data.entry.comment;
      });
    if (event === EventType.NAVIGATE_PREVIOUS) {
      this.pdsaActivityParentFormComponent.selectPreviousTab();
      this.onSubmitEvent.emit();
    } else if (event === EventType.CLOSE || event === EventType.SUBMIT) {
      this.router.navigateByUrl('/pdsa-structure-activity').then(() => {
        Swal.fire({
          title: 'Données sauvegardées avec succès !',
          icon: 'success',
          confirmButtonColor: '#2B8CA9',
          confirmButtonText: 'OK'
        });
      });
    }
  }
}
