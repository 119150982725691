import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "transformSauType",
})
export class TransformSauTypePipe implements PipeTransform {
  transform(value: number): string {
    if (value) {
      return "Pédiatrique";
    } else {
      return "Adulte";
    }
  }
}
