<div class="div-alert">
  <alert class="alert"></alert>
</div>
<div
  class="container col-md-11"
  style="padding-bottom: 2rem; padding-top: 2rem"
>
  <div class="row">
    <h1 class="ml-5">Paramétrage des structures de la PDSA</h1>
  </div>

  <div class="main">
    <form>
      <div class="d-flex justify-content-end">
        <div class="row">
          <div class="col-xs-1 col-sm-auto mb-2" style="padding-left: 0">
            <button
              class="btn btn-primary d-flex align-items-center"
              ngbTooltip="Créer une structure PDSA"
              type="button"
              [routerLink]="['/effectorsForm']"
            >
              <i class="material-icons">add</i>
            </button>
          </div>
        </div>
      </div>

      <table class="table table-striped shadow p-3 bg-white">
        <thead>
          <tr class="noselect">
            <th style="max-width: 4vw">
              <p
                (click)="changeStyle('departmentCode')"
                [appSort]="effectors"
                data-order="desc"
                data-name="departmentCode"
              >
                DEPT. &nbsp;<img
                  src="../../assets/images/down.png"
                  class="icon-sort"
                  [style]="{
                    transform:
                      changeRotate.departmentCode === true
                        ? 'rotate(180deg)'
                        : ''
                  }"
                />
              </p>
              <div class="row d-flex search-area">
                <div class="ml-2">
                  <input
                    class="form-control w-50"
                    type="text"
                    (keyup)="filterData('departmentCode', $event)"
                    autocomplete="off"
                    placeholder="Rechercher"
                  />
                </div>
              </div>
            </th>
            <th style="max-width: 4vw">
              <p
                (click)="changeStyle('domainCode')"
                [appSort]="effectors"
                data-order="desc"
                data-name="domainCode"
              >
                TYPE&nbsp;<img
                  src="../../assets/images/down.png"
                  style="height: 15px; margin-left: 5px; margin-bottom: 2px"
                  class="icon-sort"
                  [style]="{
                    transform: changeRotate.domainCode ? 'rotate(180deg)' : ''
                  }"
                />
              </p>
              <div class="row d-flex search-area">
                <div class="ml-2">
                  <ng-select
                    class="ng-select-status"
                    [items]="typeStructureFilter"
                    [(ngModel)]="typeStructureFilterValue"
                    [ngModelOptions]="{ standalone: true }"
                    (change)="filterTable()"
                    bindLabel="name"
                    bindValue="value"
                  >
                  </ng-select>
                </div>
              </div>
            </th>
            <th style="max-width: 6vw">
              <p
                (click)="changeStyle('effectorShortLabel')"
                [appSort]="effectors"
                data-order="desc"
                data-name="effectorShortLabel"
              >
                STRUCTURE&nbsp;<img
                  src="../../assets/images/down.png"
                  style="height: 15px; margin-left: 5px; margin-bottom: 2px"
                  class="icon-sort"
                  [style]="{
                    transform:
                      changeRotate.effectorShortLabel === true
                        ? 'rotate(180deg)'
                        : ''
                  }"
                />
              </p>
              <div class="row d-flex search-area">
                <div class="ml-2">
                  <input
                    class="form-control"
                    type="text"
                    (keyup)="filterData('effectorShortLabel', $event)"
                    autocomplete="off"
                    placeholder="Rechercher"
                    style="width: 90% !important"
                  />
                </div>
              </div>
            </th>
            <th style="max-width: 10vw">
              <p
                (click)="changeStyle('validityStartDate')"
                [appSort]="effectors"
                data-order="desc"
                data-name="validityStartDate"
              >
                DATE DE DEBUT&nbsp;<img
                  src="../../assets/images/down.png"
                  style="height: 15px; margin-left: 5px; margin-bottom: 2px"
                  class="icon-sort"
                  [style]="{
                    transform:
                      changeRotate.validityStartDate === true
                        ? 'rotate(180deg)'
                        : ''
                  }"
                />
              </p>
              <!-- Code for datepickers range selection part 1 -->
              <div class="row">
                <div class="col-6 px-0 pl-1">
                  <div class="input-group">
                    <input
                      class="form-control"
                      style="
                        font-family: Arial, Helvetica, sans-serif;
                        font-size: 12px;
                        color: #8f8f8f;
                      "
                      type="date"
                      lang="fr-ca"
                      id="validityStartDate"
                      name="validityStartDate"
                      ngbTooltip="Date de début de validité"
                      placeholder="jj-mm-aaaa"
                      (change)="
                        filterDate(
                          'firstValidityStartDate',
                          $event,
                          DateValidityFilterEnum.MIN_TIMESTAMP
                        )
                      "
                    />
                  </div>
                </div>
                <div class="col-6 pl-0">
                  <div class="input-group">
                    <input
                      class="form-control"
                      style="
                        font-family: Arial, Helvetica, sans-serif;
                        font-size: 12px;
                        color: #8f8f8f;
                      "
                      type="date"
                      lang="fr-ca"
                      id="validityStartDate2"
                      name="validityStartDate"
                      ngbTooltip="Date de début de validité"
                      placeholder="jj-mm-aaaa"
                      min="{{
                        dateFilters.firstValidityStartDate | date: 'yyyy-MM-dd'
                      }}"
                      (change)="
                        filterDate(
                          'secondValidityStartDate',
                          $event,
                          DateValidityFilterEnum.MAX_TIMESTAMP
                        )
                      "
                    />
                  </div>
                </div>
              </div>
            </th>
            <th style="max-width: 10vw">
              <p
                (click)="changeStyle('validityEndDate')"
                [appSort]="effectors"
                data-order="desc"
                data-name="validityEndDate"
              >
                DATE DE FIN&nbsp;<img
                  src="../../assets/images/down.png"
                  style="height: 15px; margin-left: 5px; margin-bottom: 2px"
                  class="icon-sort"
                  [style]="{
                    transform:
                      changeRotate.validityEndDate === true
                        ? 'rotate(180deg)'
                        : ''
                  }"
                />
              </p>
              <!-- Code for datepickers range selection part 2 -->
              <div class="row">
                <div class="col-5 px-0 pl-1">
                  <div class="input-group">
                    <input
                      class="form-control"
                      style="
                        font-family: Arial, Helvetica, sans-serif;
                        font-size: 12px;
                        color: #8f8f8f;
                      "
                      type="date"
                      lang="fr-ca"
                      id="validityEndDate"
                      name="validityEndDate"
                      ngbTooltip="Date de fin de validité"
                      [ngModel]="dateFilters.firstValidityEndDateString"
                      placeholder="jj-mm-aaaa"
                      (change)="
                        filterDate(
                          'firstValidityEndDate',
                          $event,
                          DateValidityFilterEnum.MIN_TIMESTAMP
                        )
                      "
                      min="{{
                        dateFilters.firstValidityStartDate | date: 'yyyy-MM-dd'
                      }}"
                    />
                  </div>
                </div>
                <div class="col-5 p-0">
                  <div class="input-group">
                    <input
                      class="form-control"
                      style="
                        font-family: Arial, Helvetica, sans-serif;
                        font-size: 12px;
                        color: #8f8f8f;
                      "
                      type="date"
                      lang="fr-ca"
                      id="validityEndDate2"
                      name="validityEndDate"
                      ngbTooltip="Date de fin de validité"
                      placeholder="jj-mm-aaaa"
                      (change)="
                        filterDate(
                          'secondValidityEndDate',
                          $event,
                          DateValidityFilterEnum.MAX_TIMESTAMP
                        )
                      "
                      min="{{
                        dateFilters.firstValidityStartDate | date: 'yyyy-MM-dd'
                      }}"
                    />
                  </div>
                </div>
              </div>
            </th>
            <th style="max-width: 6vw">
              <p
                (click)="changeStyle('validityBoolean')"
                [appSort]="effectors"
                data-order="desc"
                data-name="validityBoolean"
              >
                STATUT&nbsp;<img
                  src="../../assets/images/down.png"
                  style="height: 15px; margin-left: 5px; margin-bottom: 2px"
                  class="icon-sort"
                  [style]="{
                    transform:
                      changeRotate.validityBoolean === true
                        ? 'rotate(180deg)'
                        : ''
                  }"
                />
              </p>
              <div class="row d-flex search-area">
                <div class="ml-2">
                  <ng-select
                    class="ng-select-status"
                    [items]="statusFilter"
                    [(ngModel)]="statusFilterValue"
                    [ngModelOptions]="{ standalone: true }"
                    (change)="filterTable()"
                    bindLabel="name"
                    bindValue="value"
                  >
                  </ng-select>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let effectorForm of effectors
                | paginate
                  : {
                      itemsPerPage: tableSize,
                      currentPage: page,
                      totalItems: count
                    };
              let i = index
            "
            class="cursor noselect hover-effect"
          >
            <td [routerLink]="['/effectorsForm', effectorForm.id]">
              {{ effectorForm.departmentCode }}
            </td>
            <td [routerLink]="['/effectorsForm', effectorForm.id]">
              {{ getDisplayValueTypeStructure(effectorForm.domainCode) }}
            </td>
            <td [routerLink]="['/effectorsForm', effectorForm.id]">
              {{ effectorForm.effectorShortLabel }}
            </td>
            <td [routerLink]="['/effectorsForm', effectorForm.id]">
              {{ effectorForm.validityStartDate | date: 'dd/MM/yyyy' }}
            </td>
            <td [routerLink]="['/effectorsForm', effectorForm.id]">
              {{ effectorForm.validityEndDate | date: 'dd/MM/yyyy' }}
            </td>
            <td style="width: 8vw">
              <div class="d-flex">
                <div class="statut">
                  <div>
                    {{ effectorForm.validityBoolean ? 'Actif' : 'Inactif' }}
                  </div>
                </div>
                <div
                  class="custom-control custom-switch custom-switch-lg ml-4"
                  ngbTooltip="Activer ou désactiver la structure"
                  placement="left"
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="enabled{{ effectorForm.id }}"
                    [(ngModel)]="effectorForm.validityBoolean"
                    (change)="updateEffectorStatut(effectorForm)"
                    [ngModelOptions]="{ standalone: true }"
                    style="cursor: pointer"
                  />
                  <label
                    class="custom-control-label"
                    for="enabled{{ effectorForm.id }}"
                    style="cursor: pointer"
                  ></label>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div
        class="row"
        style="float: right; margin-left: 0px; margin-right: 0px"
      >
        <pagination-controls
          (pageChange)="page = $event"
          previousLabel=""
          nextLabel=""
          class="pagination"
        ></pagination-controls>
      </div>
      <div class="row">
        <label for="items" style="margin-left: 0px">Afficher&nbsp;</label>
        <select (change)="onTableSizeChange($event)" id="items">
          <option *ngFor="let size of tableSizes">
            {{ size }}
          </option>
        </select>
        <label for="items" style="margin-left: 0.2rem">éléments</label>
      </div>
    </form>
  </div>
</div>
