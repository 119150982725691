import {Injectable} from '@angular/core';
import {PaqService} from '@piwikpro/ngx-piwik-pro';
import {TRACK_EVENT} from '../piwik-track-event.constant';


@Injectable({
  providedIn: 'root'
})
export class PiwikCustomEventService {
    constructor(private paqService: PaqService) {}
    trackEvent(category, action, name, value) {
        const eventArguments = [
            category,
            action,
            ...(name ? [name] : []),
            ...(name ? [value] : []),
        ];
        this.paqService.push([TRACK_EVENT.CUSTOM_EVENT, ...eventArguments]);
    }
}
