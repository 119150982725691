import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { LogicielTechnicalParameter } from "../model/logiciel-technical-parameter.model";

@Injectable({
  providedIn: "root",
})
export class LogicielTechnicalParameterService {
  constructor(private httpClient: HttpClient) {}

  public getLogicielTechnicalParameter(): Observable<
    LogicielTechnicalParameter[]
  > {
    return this.httpClient.get<LogicielTechnicalParameter[]>(
      `/api/logiciel-technical-parameter`
    );
  }
}
