<div class="div-alert">
  <alert class="alert"></alert>
</div>

<div class="container-fluid">
  <div class="my-3">
    <div class="row w-100 no-gutters mb-3">
      <div class="col-12">
        <app-graph-filter
          (doFilter)="filter($event)"
          [needsComparison]="true"
        ></app-graph-filter>
      </div>
    </div>

    <div class="row w-100 no-gutters">
      <div class="col-12">
        <app-ntg-graph [displayPeriod2]="displayPeriod2"></app-ntg-graph>
      </div>
    </div>
  </div>
</div>
