import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PostedConsultationRange } from '../../model/activity/posted-consultation-range.model';

@Injectable({
  providedIn: 'root'
})
export class PostedConsultationRangeService {
  constructor(private httpClient: HttpClient) {}

  public getPostedConsultationRanges(
    id: number,
    yearMonth: string
  ): Observable<PostedConsultationRange> {
    return this.httpClient.get<PostedConsultationRange>(
      `api/effectors/${id}/activities/${yearMonth}/time-consultation`
    );
  }

  public setPostedConsultationRanges(
    id: number,
    yearMonth: string,
    postedConsultationRange: PostedConsultationRange
  ): Observable<PostedConsultationRange> {
    return this.httpClient.post<PostedConsultationRange>(
      `api/effectors/${id}/activities/${yearMonth}/time-consultation`,
      postedConsultationRange
    );
  }
}
