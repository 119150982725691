import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AlertService } from '../emergency-depts/services/alert.service';
import { Alert } from "src/app/emergency-depts/model/alert.model";
import { ActivatedRoute, Router } from "@angular/router";
import { Account } from '../core/auth/account.model';
import { AccountService } from '../core/auth/account.service';

@Component({
  selector: 'app-iframe-emergency-trafic',
  templateUrl: './iframe-emergency-trafic.component.html',
  styleUrls: ['./iframe-emergency-trafic.component.scss']
})
export class IframeEmergencyTraficComponent implements OnInit {
  alerts: Alert[] = [];
  account: Account | null;
  isDisplayed: boolean = false;

  constructor(
    private alertService: AlertService,     
    private readonly titleService: Title,
    private readonly route: ActivatedRoute, 
    private accountService: AccountService,
    ) { 
      this.accountService.identity().subscribe((account) => {
        this.account = account;
        console.warn("account change", account);
      });
    }


  ngOnInit() { 
    this.titleService.setTitle(this.route.snapshot.data['title']);
    const Reg = this.isAraPage() ? 84 : 11;
    this.alertService
      .getAlertsByPageAlert(Reg, "ADMINISTRATION_Trafic-Mes-Urgences",)
      .subscribe((res: Alert[]) => {
        this.alerts = res;
        res.map((alert) => this.alertService.get(alert))
      }
      );
      this.showAccessDeniedDiv();
   }

   showAccessDeniedDiv() {
    setTimeout(() => {
      this.isDisplayed = true;
    }, 2000);
  }

   isAlert() {
    if (this.alerts.length != 0) {
      return true
    }
    else {
      return false
    };
  }
   
  isAraPage() {
    const str = window.location.href;
    return str.includes("ara") || str.includes(":4201/");
  } 

}
