import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { faBullseye } from "@fortawesome/free-solid-svg-icons";
import {
  NgbActiveModal,
  NgbDateParserFormatter,
  NgbDatepickerI18n,
  NgbModal,
} from "@ng-bootstrap/ng-bootstrap";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { EmergencyDept } from "src/app/emergency-depts/model/emergency-dept.model";
import { EmergencyDeptService } from "src/app/emergency-depts/services/emergency-dept.service";
import { CustomDatepickerI18n, I18n } from "src/app/shared/DatePicker";
import { NgbDateParserFormatterExtService } from "src/app/shared/ngDatePickerParse";
import {
  GraphFilter,
  LibSerUrgtoCompare,
  Period1,
  Period1Option,
  Period1OptionKind,
  Period2,
  Period2Option,
  Period2OptionKind,
} from "../../models/graph-filter.model";
import { SizeGroup } from "../../models/size-group.model";
import { GraphService } from "../../services/graph.service";
import { PeriodService } from "../../services/period-service";

@Component({
  selector: "app-modal-filter",
  templateUrl: "./modal-filter.component.html",
  styleUrls: ["./modal-filter.component.scss"],
  providers: [
    I18n,
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
    {
      provide: NgbDateParserFormatter,
      useClass: NgbDateParserFormatterExtService,
    },
  ],
})
export class ModalFilterComponent implements OnInit {
  needsComparison!: boolean;
  sizeGroups!: SizeGroup[];
  departmentCodes!: string[];
  graphFilter!: GraphFilter;
  graphIndicateurCharge!: boolean;

  searching = false;
  emergencyDepts: EmergencyDept[] = [];
  selectedSizeGroupIds: Set<string> = new Set();
  selectedDepartmentCodes: Set<string> = new Set();
  selectedEmergencyDeptIds: Set<number> = new Set();
  emergencyDeptsForNgSelect: EmergencyDept[];
  selectedEmergencyDeptId: number | undefined;

  period1Options: Period1Option[] = this.periodService.getPeriod1Options();
  period2Options: Period2Option[] = this.periodService.getPeriod2Options();
  isCustomPeriod1 = false;
  isCustomPeriod2 = false;
  isDisplayedPeriod2 = false;
  form: FormGroup = this.formBuilder.group({
    period1OptionKind: [null],
    period1StartDate: [null],
    period1EndDate: [null],
    period2OptionKind: [null],
    period2StartDate: [null],
    period2EndDate: [null],
    compareEmergencyDept: [null],
  });

  doFilter: (graphFilter: GraphFilter) => void;

  libSerUrgtoCompare: LibSerUrgtoCompare = {
    codRorEg: "",
    codSerUrg: 0,
  };
  fillInput = new Subject<string>();
  text: string;
  allCheckedDepartments: boolean = false;
  selectedAllEmergencyDept: boolean = false;
  emergencyDeptIds: number[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private graphService: GraphService,
    private periodService: PeriodService,
    private emergencyDeptService: EmergencyDeptService
  ) {}

  ngOnInit(): void {
    this.selectedSizeGroupIds = new Set(
      this.graphFilter.sizeGroups.map((sizeGroup) => sizeGroup.id)
    );
    this.selectedDepartmentCodes = new Set(this.graphFilter.departementCodes);
    this.selectedEmergencyDeptIds = new Set(
      this.graphFilter.emergencyDepts.map((emergencyDept) => emergencyDept.id)
    );
    this.updateSelectableEmergencyDepts();

    let period1: Period1;
    if (
      (this.graphIndicateurCharge || this.needsComparison == false) &&
      this.selectedEmergencyDeptIds.size === 0
    ) {
      period1 = this.periodService.computePeriod1(Period1OptionKind.ONE_MONTH);
    } else {
      period1 = this.graphFilter.period1;
    }

    this.updatePeriodsAndEmergencyDept(
      period1,
      this.graphFilter.period2,
      this.graphFilter.isActivePeriod2
    );
    this.getEmergencyDepts();
    console.log("this.getEmergencyDepts();", this.getEmergencyDepts());
    console.log("this.selectedEmergencyDeptId", this.selectedEmergencyDeptId)
    this.selectedEmergencyDeptId = this.graphFilter.compareEmergencyDeptId;
  }

  toggleSizeGroup(sizeGroup: SizeGroup, selected: boolean): void {
    if (selected) {
      this.selectedSizeGroupIds.add(sizeGroup.id);
    } else {
      this.selectedSizeGroupIds.delete(sizeGroup.id);
    }
    this.updateSelectableEmergencyDepts();
  }

  toggleDepartmentCode(departmentCode: string, selected: boolean) {
    if (selected) {
      this.selectedDepartmentCodes.add(departmentCode);
      if (this.selectedDepartmentCodes.size === this.departmentCodes.length) {
        this.allCheckedDepartments = true;
      }
    } else {
      this.selectedDepartmentCodes.delete(departmentCode);
      this.allCheckedDepartments = false;
    }
    this.updateSelectableEmergencyDepts();
  }

  updateSelectableEmergencyDepts() {
    this.searching = true;

    const selectedSizeGroupIds = Array.from(this.selectedSizeGroupIds);
    const selectedDepartmentCodes = Array.from(this.selectedDepartmentCodes);

    this.graphService
      .getEmergencyDepts(
        selectedSizeGroupIds.length !== 0 ? selectedSizeGroupIds : undefined,
        selectedDepartmentCodes.length !== 0
          ? selectedDepartmentCodes
          : undefined
      )
      .subscribe(
        (emergencyDepts) => {
          this.selectedEmergencyDeptIds = new Set(
            emergencyDepts
              .map((emergencyDept) => emergencyDept.id)
              .filter((emergencyDeptId) =>
                this.selectedEmergencyDeptIds.has(emergencyDeptId)
              )
          );
          this.emergencyDepts = emergencyDepts;
          this.searching = false;
        },
        (error) => {
          console.error(error);

          this.searching = false;
        }
      );
  }

  toggleEmergencyDept(emergencyDept: EmergencyDept, selected: boolean) {
    if (selected) {
      this.selectedEmergencyDeptIds.add(emergencyDept.id);

      if (this.selectedEmergencyDeptIds.size === this.emergencyDeptIds.length) {
        this.selectedAllEmergencyDept = true;
      }
    } else {
      this.selectedEmergencyDeptIds.delete(emergencyDept.id);
      this.selectedAllEmergencyDept = false;
    }
  }

  toggleOneEmergencyDept(emergencyDept: EmergencyDept, selected: boolean) {
    this.selectedEmergencyDeptIds = new Set();
    if (selected) {
      this.selectedEmergencyDeptIds.add(emergencyDept.id);
    } else {
      this.selectedEmergencyDeptIds.delete(emergencyDept.id);
    }
  }

  updatePeriodsAndEmergencyDept(
    period1: Period1,
    period2: Period2,
    isActivePeriod2: boolean,
    compareEmergencyDept?: EmergencyDept
  ) {
    this.form.patchValue({
      period1OptionKind: period1.optionKind,
      period1StartDate: this.periodService.dateToNgbDateStruct(
        period1.startDate
      ),
      period1EndDate: this.periodService.dateToNgbDateStruct(period1.endDate),
      period2OptionKind: period2.optionKind,
      period2StartDate: this.periodService.dateToNgbDateStruct(
        period2.startDate
      ),
      period2EndDate: this.periodService.dateToNgbDateStruct(period2.endDate),
      compareEmergencyDept,
    });
    this.displayPeriod2(isActivePeriod2);
    this.selectPeriod1Option();
    this.selectPeriod2Option();
  }

  displayPeriod2(display: boolean) {
    this.isDisplayedPeriod2 = display;
  }

  selectPeriod1Option() {
    const period1OptionKind: Period1OptionKind = this.form.get([
      "period1OptionKind",
    ])!.value;
    this.isCustomPeriod1 = period1OptionKind === Period1OptionKind.CUSTOM;
    this.displayPeriod2EnDate();
  }

  changePeriod1StartDate() {
    this.displayPeriod2EnDate();
  }

  changePeriod1EndDate() {
    this.displayPeriod2EnDate();
  }

  selectPeriod2Option() {
    const period2OptionKind: Period2OptionKind = this.form.get([
      "period2OptionKind",
    ])!.value;
    this.isCustomPeriod2 = period2OptionKind === Period2OptionKind.CUSTOM;
    this.displayPeriod2EnDate();
  }

  changePeriod2StartDate(date) {
    this.displayPeriod2EnDate(date);
  }

  clearFilters() {
    this.selectedSizeGroupIds = new Set();
    this.selectedDepartmentCodes = new Set();
    this.selectedEmergencyDeptIds = new Set();
    this.updateSelectableEmergencyDepts();

    const period1 = this.periodService.computePeriod1();
    const period2 = this.periodService.computePeriod2(period1);
    this.updatePeriodsAndEmergencyDept(period1, period2, false);
    this.allCheckedDepartments = false;
  }

  getSau(codEg, codSerUrg) {
    this.libSerUrgtoCompare.codRorEg = codEg;
    this.libSerUrgtoCompare.codSerUrg = codSerUrg;
  }

  getEmergencyDepts(): void {
    this.emergencyDeptService
      .getAllEmergencyDeptsByRegionSortFinessLibSerUrg(this.isAraPage() ? 84 : 11)
      .subscribe((emergencydepts) => {
        this.emergencyDeptsForNgSelect = emergencydepts;
      });
      console.log("je passe là")
  }

  onSubmit() {
    this.graphFilter.sizeGroups = this.sizeGroups.filter((sizeGroup) =>
      this.selectedSizeGroupIds.has(sizeGroup.id)
    );
    this.graphFilter.departementCodes = this.departmentCodes.filter(
      (departementCode) => 
        this.selectedDepartmentCodes.has(departementCode)
    );
    this.graphFilter.emergencyDepts = this.emergencyDepts.filter(
      (emergencyDept) => 
      this.selectedEmergencyDeptIds.has(emergencyDept.id)
    );
    const period1OptionKind = this.form.get(["period1OptionKind"])!.value;
    const period1StartDate = this.periodService.ngbDateToDate(
      this.form.get(["period1StartDate"])!.value
    );
    const period1EndDate = this.periodService.ngbDateToDate(
      this.form.get(["period1EndDate"])!.value
    );
    this.graphFilter.period1 = this.periodService.computePeriod1(
      period1OptionKind,
      period1StartDate,
      period1EndDate
    );
    const period2OptionKind = this.form.get(["period2OptionKind"])!.value;
    const period2StartDate = this.periodService.ngbDateToDate(
      this.form.get(["period2StartDate"])!.value
    );
    this.graphFilter.period2 = this.periodService.computePeriod2(
      this.graphFilter.period1,
      period2OptionKind,
      period2StartDate
    );
    this.graphFilter.isActivePeriod2 = this.isDisplayedPeriod2;
    //RETURN UNDEFINED 
    this.graphFilter.compareEmergencyDeptId = this.form.get([
      "compareEmergencyDept",
    ])!.value;

    this.activeModal.close(this.graphFilter);
  }

  close(): void {
    this.activeModal.dismiss("Cross click");
  }

  toggleAllDepartments(selected: boolean) {
    if (selected) {
      this.allCheckedDepartments = true;
      this.selectedDepartmentCodes = new Set(this.departmentCodes);
    } else {
      this.allCheckedDepartments = false;
      this.selectedDepartmentCodes = new Set();
    }
    this.updateSelectableEmergencyDepts();
  }

  toggleAllEmergencyDept(selected: boolean) {
    if (selected) {
      this.emergencyDeptIds = this.emergencyDepts.map((value) => {
        return value.id;
      });
      this.selectedAllEmergencyDept = true;
      this.selectedEmergencyDeptIds = new Set(this.emergencyDeptIds);
    } else {
      this.selectedAllEmergencyDept = false;
      this.selectedEmergencyDeptIds = new Set();
    }
  }

  private displayPeriod2EnDate(date?) {
    const period1OptionKind = this.form.get(["period1OptionKind"])!.value;
    const period1StartDate = this.periodService.ngbDateToDate(
      this.form.get(["period1StartDate"])!.value
    );
    const period1EndDate = this.periodService.ngbDateToDate(
      this.form.get(["period1EndDate"])!.value
    );
    const period1 = this.periodService.computePeriod1(
      period1OptionKind,
      period1StartDate,
      period1EndDate
    );

    const period2OptionKind = this.form.get(["period2OptionKind"])!.value;
    const period2StartDate = this.periodService.ngbDateToDate(
      this.form.get(["period2StartDate"])!.value
    );

    const period2 = this.periodService.computePeriod2(
      period1,
      period2OptionKind,
      period2StartDate
    );
    this.form.patchValue({
      period2EndDate: this.periodService.dateToNgbDateStruct(period2.endDate),
    });
  }

  isAraPage() {
    const str = window.location.href;
    return str.includes("ara") || str.includes(":4201/");
  }
}
