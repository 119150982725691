<!-- <div id="main-head" style="text-align: center"> -->
<!-- <app-first-graph></app-first-graph> -->
<body>
  <ul ngbNav #nav="ngbNav" class="nav-tabs">
    <li ngbNavItem>
      <a ngbNavLink>Passages aux urgences</a>
      <ng-template ngbNavContent>
        <app-first-graph></app-first-graph>
      </ng-template>
    </li>
    <li ngbNavItem>
      <a ngbNavLink>Nb de passages des -1 an</a>
      <ng-template ngbNavContent>
        <app-second-graph></app-second-graph>
      </ng-template>
    </li>
    <li ngbNavItem>
      <a ngbNavLink>Nb de passages des +75 ans</a>
      <ng-template ngbNavContent
        ><app-third-graph></app-third-graph
      ></ng-template>
    </li>
    <li ngbNavItem>
      <a ngbNavLink>Nb d'hospitalisation</a>
      <ng-template ngbNavContent
        ><app-fourth-graph></app-fourth-graph
      ></ng-template>
    </li>
    <li ngbNavItem>
      <a ngbNavLink>Taux d'hospitalisation</a>
      <ng-template ngbNavContent
        ><app-fifth-graph></app-fifth-graph
      ></ng-template>
    </li>
    <li ngbNavItem>
      <a ngbNavLink>Taux d'hospitalisation enfants -1 an</a>
      <ng-template ngbNavContent
        ><app-sixth-graph></app-sixth-graph
      ></ng-template>
    </li>
    <li ngbNavItem>
      <a ngbNavLink>Nb d'hospitalisation enfants -1 an</a>
      <ng-template ngbNavContent
        ><app-seventh-graph></app-seventh-graph
      ></ng-template>
    </li>
  </ul>
  <div class="row filtre">
    <div class="col-1">
      <img src="assets/images/chart-areaspline.svg" style="margin-left: 12px" />
    </div>
    <div class="col-3">
      <select name="dpt" id="dpt-select">
        <option value="">Département</option>
        <option value="Ain">Ain</option>
        <option value="Allier">Allier</option>
        <option value="Ardèche">Ardèche</option>
        <option value="Cantal">Cantal</option>
        <option value="Drôme">Drôme</option>
        <option value="Haute-Loire">Haute-Loire</option>
        <option value="Haute-Savoie">Haute-Savoie</option>
        <option value="Isère">Isère</option>
        <option value="Loire">Loire</option>
        <option value="Puy-De-Dôme">Puy-De-Dôme</option>
        <option value="Rhône">Rhône</option>
      </select>
    </div>
    <div class="col-3">
      <select name="dpt" id="dpt-select">
        <option value="">Service d'urgences</option>
        <option value="sau">Nom du SAU</option>
        <option value="sau">Nom du SAU</option>
        <option value="sau">Nom du SAU</option>
        <option value="sau">Nom du SAU</option>
        <option value="sau">Nom du SAU</option>
        <option value="sau">Nom du SAU</option>
        <option value="sau">Nom du SAU</option>
        <option value="sau">Nom du SAU</option>
      </select>
    </div>
    <div class="col-4">
      <div class="row">
        <p>du</p>
        <input
          type="date"
          id="start"
          name="trip-start"
          value="2018-07-22"
          min="2018-01-01"
          max="2018-12-31"
        />
        <p>au</p>
        <input
          type="date"
          id="start"
          name="trip-start"
          value="2018-07-22"
          min="2018-01-01"
          max="2018-12-31"
        />
      </div>
    </div>
  </div>
  <div [ngbNavOutlet]="nav"></div>
</body>
