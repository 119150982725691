import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MobileTerritoryVisitRange } from '../../model/activity/mobile-territory-visit-range.model';

@Injectable({
  providedIn: 'root'
})
export class MobileTerritoryVisitRangeService {
  constructor(private httpClient: HttpClient) {}

  public getMobileTerritoryVisitRange(
    id: number,
    yearMonth: string
  ): Observable<MobileTerritoryVisitRange> {
    return this.httpClient.get<MobileTerritoryVisitRange>(
      `api/effectors/${id}/activities/${yearMonth}/territory`
    );
  }

  public setMobileTerritoryVisitRange(
    id: number,
    yearMonth: string,
    mobileTerritoryVisitRange: MobileTerritoryVisitRange
  ): Observable<MobileTerritoryVisitRange> {
    return this.httpClient.post<MobileTerritoryVisitRange>(
      `api/effectors/${id}/activities/${yearMonth}/territory`,
      mobileTerritoryVisitRange
    );
  }
}
