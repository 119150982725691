import { Routes } from '@angular/router';

import { UserRouteAccessService } from '../core/auth/user-route-access.service';
import { ErrorInexistentComponent } from '../error-inexistent/error-inexistent.component';
import { ErrorPageComponent } from '../error-page/error-page.component';
import { ChargeGraphHomeComponent } from '../graph/charge/charge-graph-home/charge-graph-home.component';
import { EmergencyVisitsGraphsComponent } from '../graph/emergency-visits-graphs/emergency-visits-graphs.component';
import { NtgGraphHomeComponent } from '../graph/ntg/ntg-graph-home/ntg-graph-home.component';
import { HetComponent } from '../het/het.component';
import { HomePageIdfComponent } from '../home-page-idf/home-page-idf.component';
import { IframeEmergencyTraficComponent } from '../iframe-emergency-trafic/iframe-emergency-trafic.component';
import { IframeMesUrgencesComponent } from '../iframe-mes-urgences/iframe-mes-urgences.component';
import { IframeMesUrgencesProComponent } from '../iframe-mes-urgences-pro/iframe-mes-urgences-pro.component';
import { IframeMeteoComponent } from '../iframe-meteo/iframe-meteo.component';
import { IframePowerBiComponent } from '../iframe-power-bi/iframe-power-bi.component';
import { IframeSamuComponent } from '../iframe-samu/iframe-samu.component';
import { IframeSnpQualiteComponent } from '../iframe-snp-qualite/iframe-snp-qualite.component';
import { IframeTestComponent } from '../iframe-test/iframe-test.component';
import { MentionsLegalesComponent } from '../mentions-legales/mentions-legales.component';
import { EffectorsFormComponent } from '../pdsa/components/effectors-form/effectors-form.component';
import { PdsaActivityParentFormComponent } from '../pdsa/components/pdsa-mobile-activity-form/pdsa-activity-parent-form.component';
import { PdsaPosteActivityFormComponent } from '../pdsa/components/pdsa-poste-activity-form/pdsa-poste-activity-form.component';
import { PdsaSettingsComponent } from '../pdsa/components/pdsa-settings/pdsa-settings.component';
import { PdsaStructureActivityComponent } from '../pdsa/components/pdsa-structure-activity/pdsa-structure-activity.component';
import { QualiteAraComponent } from '../qualite-ara/qualite-ara.component';
import { StatusComponent } from '../status/status.component';
import { CerveauEmergencyServiceFormComponent } from './cerveau-emergency-service-form/cerveau-emergency-service-form.component';
import { RoleUser } from './model/role-user.model';
import { AppRoutes } from './routes.enum';

export const emergencyDeptRoutes: Routes = [
  {
    path: 'emergency-depts',
    data: {
      authorities: [RoleUser.ROLE_PARAM_SAU_IDF, RoleUser.ROLE_PARAM_SAU_ARA],
      title: 'Paramétrage des SAU'
    },
    component: CerveauEmergencyServiceFormComponent,

    canActivate: [UserRouteAccessService]
  },
  { path: '', redirectTo: 'accueil', pathMatch: 'full' },
  { path: 'status', component: StatusComponent },

  //ROUTE FOR EFFECTORS + EFFECTORS : ID
  { path: 'effectorsForm', redirectTo: 'effectorsForm/', pathMatch: 'full' },
  {
    path: 'effectorsForm/:id',
    data: {
      authorities: [RoleUser.CERVEAU_PARAM_PDSA, RoleUser.CERVEAU_ADMIN_PDSA]
    },
    component: EffectorsFormComponent,
    canActivate: [UserRouteAccessService]
  },

  //ROUTE FOR PARAMETRAGE PDSA
  {
    path: AppRoutes.PDSA_SETTINGS,
    data: {
      authorities: [RoleUser.CERVEAU_PARAM_PDSA, RoleUser.CERVEAU_ADMIN_PDSA],
      title: 'Paramétrage des PDSA'
    },
    component: PdsaSettingsComponent,

    canActivate: [UserRouteAccessService]
  },

  //ROUTE FOR ACTIVITY PDSA DECLARATION
  {
    path: AppRoutes.PDSA_STRUCTURE_ACTIVITY,
    data: {
      authorities: [RoleUser.CERVEAU_SAISIE_PDSA, RoleUser.CERVEAU_ADMIN_PDSA],
      title: 'Formulaires PDSA'
    },
    component: PdsaStructureActivityComponent,
    canActivate: [UserRouteAccessService]
  },

  //ROUTE FOR STRUCTURE MOBILE ACTIVITY
  {
    path: AppRoutes.PDSA_MOBILE_ACTIVITY_FORM + '/:idMonth/:idEffector',
    data: {
      authorities: [RoleUser.CERVEAU_SAISIE_PDSA, RoleUser.CERVEAU_ADMIN_PDSA]
    },
    component: PdsaActivityParentFormComponent,
    canActivate: [UserRouteAccessService]
  },
  {
    path: AppRoutes.PDSA_MOBILE_ACTIVITY_FORM,
    data: {
      authorities: [RoleUser.CERVEAU_SAISIE_PDSA, RoleUser.CERVEAU_ADMIN_PDSA]
    },
    component: PdsaActivityParentFormComponent,
    canActivate: [UserRouteAccessService]
  },

  //ROUTE FOR STRUCTURE POSTE ACTIVITY
  {
    path: AppRoutes.PDSA_POSTE_ACTIVITY_FORM + '/:idMonth/:idEffector',
    data: {
      authorities: [RoleUser.CERVEAU_SAISIE_PDSA, RoleUser.CERVEAU_ADMIN_PDSA]
    },
    component: PdsaPosteActivityFormComponent,
    canActivate: [UserRouteAccessService]
  },
  {
    path: AppRoutes.PDSA_POSTE_ACTIVITY_FORM,
    data: {
      authorities: [RoleUser.CERVEAU_SAISIE_PDSA, RoleUser.CERVEAU_ADMIN_PDSA]
    },
    component: PdsaPosteActivityFormComponent,
    canActivate: [UserRouteAccessService]
  },
  {
    path: 'accueil',
    data: { title: 'eCerveau' },
    component: HomePageIdfComponent
  },
  { path: 'accessdenied', component: ErrorPageComponent },
  { path: 'mentions-legales', component: MentionsLegalesComponent },
  {
    path: 'pocshiny',
    component: IframeTestComponent,
    data: { authorities: [RoleUser.ROLE_SHINY] },
    canActivate: [UserRouteAccessService]
  },
  {
    path: 'evolution-donnees-urgences',
    component: EmergencyVisitsGraphsComponent,
    canActivate: [UserRouteAccessService],
    data: {
      authorities: [RoleUser.CERVEAU_NEB_INDICATEUR],
      title: 'Evolution données urgences'
    }
  },
  {
    path: 'evolution-ntg',
    component: NtgGraphHomeComponent,
    canActivate: [UserRouteAccessService],
    data: {
      authorities: [RoleUser.CERVEAU_NEB_NTG],
      title: 'Evolution NTG'
    }
  },
  {
    path: 'diagramme-de-charge',
    component: ChargeGraphHomeComponent,
    canActivate: [UserRouteAccessService],
    data: {
      authorities: [RoleUser.CERVEAU_NEB_CHARGE],
      title: 'Diagramme de charge'
    }
  },
  {
    path: 'meteo-urgences',
    component: IframeMeteoComponent,
    data: { authorities: [RoleUser.ROLE_METEO], title: 'Météo des Urgences' },
    canActivate: [UserRouteAccessService]
  },
  {
    path: 'meteo-samu',
    component: IframeSamuComponent,
    data: { authorities: [RoleUser.ROLE_METEO_SAMU], title: 'Météo des SAMU' },
    canActivate: [UserRouteAccessService]
  },
  {
    path: 'het',
    component: HetComponent,
    data: {
      authorities: [RoleUser.ROLE_TENSION_IDF, RoleUser.ROLE_TENSION_ARA],
      title: 'Tension'
    },
    canActivate: [UserRouteAccessService]
  },
  {
    path: 'snp-qualite',
    data: {
      authorities: [RoleUser.ROLE_QUALITE_IDF, RoleUser.ROLE_QUALITE_ARA],
      title: 'Qualité'
    },
    component: IframeSnpQualiteComponent
  },
  {
    path: 'qualite',
    data: {
      authorities: [RoleUser.ROLE_QUALITE_ARA_BIS],
      title: 'Qualité'
    },
    component: QualiteAraComponent
  },
  {
    path: 'trafic-mes-urgences',
    data: { authorities: [RoleUser.ROLE_TRAFIC], title: 'Trafic Mes Urgences' },
    component: IframeEmergencyTraficComponent
  },
  {
    path: 'mes-urgences',
    data: {
      authorities: [RoleUser.ROLE_MES_URGENCES_GP],
      title: 'Mes Urgences'
    },
    component: IframeMesUrgencesComponent
  },
  {
    path: 'mes-urgences-pro',
    data: {
      authorities: [RoleUser.ROLE_MES_URGENCES_PRO],
      title: 'Mes Urgences PRO'
    },
    component: IframeMesUrgencesProComponent
  },
  {
    path: 'power-bi',
    data: { authorities: [RoleUser.ROLE_POWER_BI] },
    component: IframePowerBiComponent
  },
  { path: 'pageNotFound', component: ErrorInexistentComponent },
  { path: '**', redirectTo: 'pageNotFound', pathMatch: 'full' }
];
