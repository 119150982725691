import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  innerWidth: number;

  constructor() {
    this.innerWidth = window.innerWidth;
   }

  ngOnInit(): void {
  }

  isPreprod() {
    const str = window.location.href;
    return str.includes("ppr");
  }

}
