import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MobileDecisionTypeRange } from 'src/app/pdsa/model/activity/mobile-decision-type-range.model';
import { MobileDecisionTypeRangeService } from 'src/app/pdsa/service/activity/mobile-decision-type-range.service';

import { PdsaPosteActivityService } from '../../pdsa-poste-activity-form/pdsa-poste-activity-service';
import { EventType } from 'src/app/pdsa/model/activity/effector-event-type.enum';
import Swal from 'sweetalert2';
import { PdsaActivityParentFormComponent } from '../pdsa-activity-parent-form.component';

@Component({
  selector: 'app-pdsa-activity-tab-six',
  templateUrl: './pdsa-activity-tab-six.component.html',
  styleUrls: ['./pdsa-activity-tab-six.component.scss'],
  providers: [PdsaPosteActivityService]
})
export class PdsaActivityTabSixComponent implements OnInit {
  @Input() form!: FormGroup;
  @Output() onSubmitEvent: EventEmitter<any> = new EventEmitter();
  @Output() hasCommentChange = new EventEmitter<boolean>();

  hasComment = false;
  currentPage: number;
  eventTypes = EventType;
  valueHasChanged: boolean = false;
  openCommentLet = false;
  hasCommentEntered = this.pdsaActivityParentFormComponent.hasCommentEntered;
  commentText: string | undefined = '';
  step: number = 6;
  isBooleanPlatform: boolean =
    this.pdsaActivityParentFormComponent.isBooleanPlatform;

  effectorId: number;
  effectorYearMonth: string;
  timeSlotLabels: string[] = [];
  timeSlotDayTypes: string[] = [];
  decisionTypeLabels: string[] = [];

  mobileDecisionTypeRangeForm = this.fb.group({
    entry: this.fb.group({
      yearMonth: [''],
      effector: this.fb.group({
        id: []
      })
    }),
    comment: this.fb.control(''),
    elements: this.fb.array([])
  });

  mobileDecisionTypeRange: MobileDecisionTypeRange;

  constructor(
    private readonly route: ActivatedRoute,
    private mobileDecisionTypeRangeService: MobileDecisionTypeRangeService,
    private fb: FormBuilder,
    private pdsaPosteActivityService: PdsaPosteActivityService,
    private pdsaActivityParentFormComponent: PdsaActivityParentFormComponent
  ) {}

  ngOnInit() {
    this.pdsaPosteActivityService.currentPage$.subscribe((page) => {
      this.currentPage = page;
    });
    this.form.addControl(
      'tab-six-form',
      this.fb.group({
        test: 'test'
      })
    );

    this.getEffectorId()
      .then((effectorId) => {
        return effectorId;
      })
      .then((effectorId) => {
        return this.getEffectorYearMonth().then((effectorYearMonth) => {
          return { effectorId, effectorYearMonth };
        });
      })
      .then(({ effectorId, effectorYearMonth }) => {
        this.getMobileDecisionTypeRange(effectorId, effectorYearMonth).then(
          () => {
            this.pdsaActivityParentFormComponent.activeTab = 6;
            this.mobileDecisionTypeRange.entry.step =
              this.pdsaActivityParentFormComponent.updateStep(this.step);
            this.updateSubmitButton();
          }
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }

  toggleComment() {
    this.hasComment = !this.hasComment;
    this.hasCommentChange.emit(this.hasComment);
  }

  openComment() {
    this.openCommentLet = true;
  }

  getEffectorId(): Promise<number> {
    return new Promise((resolve, reject) => {
      this.route.url.subscribe((segments) => {
        if (segments.length > 0) {
          const lastSegment = segments[segments.length - 1].path;
          const effectorId: number = parseInt(lastSegment, 10);
          resolve(effectorId);
        } else {
          reject("Aucun segment trouvé dans l'URL.");
        }
      });
    });
  }

  getEffectorYearMonth(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.route.url.subscribe((segments) => {
        if (segments.length > 0) {
          const lastSegment = segments[segments.length - 2].path;
          const position = lastSegment.length - 2;
          const effectorYearMonth =
            lastSegment.slice(0, position) + '-' + lastSegment.slice(position);
          resolve(effectorYearMonth);
        } else {
          reject("Aucun segment trouvé dans l'URL.");
        }
      });
    });
  }

  getMobileDecisionTypeRange(
    id: number = this.effectorId,
    yearMonth: string = this.effectorYearMonth
  ): Promise<MobileDecisionTypeRange> {
    return new Promise((resolve) =>
      this.mobileDecisionTypeRangeService
        .getMobileDecisionTypeRange(id, yearMonth)
        .subscribe((selectedMobileDecisionTypeRange) => {
          selectedMobileDecisionTypeRange.elements.map(() => {
            this.sortTimeSlotBySortCode(selectedMobileDecisionTypeRange);
          })
          this.timeSlotLabels = selectedMobileDecisionTypeRange.elements.map(
            (element) => element.timeSlot.label
          );
          this.timeSlotDayTypes = selectedMobileDecisionTypeRange.elements.map(
            (element) => element.timeSlot.dayType
          );
          const uniqueDecisionTypeLabelsSet = new Set<string>();

          this.decisionTypeLabels =
            selectedMobileDecisionTypeRange.elements.reduce((acc, element) => {
              element.elements.forEach((innerElement) => {
                uniqueDecisionTypeLabelsSet.add(
                  innerElement.decisionType.label
                );
              });

              return acc;
            }, [] as string[]);

          this.decisionTypeLabels = Array.from(uniqueDecisionTypeLabelsSet);

          this.mobileDecisionTypeRange = selectedMobileDecisionTypeRange;
          this.mobileDecisionTypeRangeForm.patchValue(
            selectedMobileDecisionTypeRange
          );

          this.commentText =
            this.pdsaActivityParentFormComponent.mobileEffectorVolumeEntry.entry.comment;
          if (this.commentText && this.commentText.trim() !== '') {
            this.pdsaActivityParentFormComponent.hasCommentEntered = true;
          }

          const elementsArray = this.mobileDecisionTypeRangeForm.get(
            'elements'
          ) as FormArray;

          selectedMobileDecisionTypeRange.elements.forEach((element) => {
            const innerElementsArray = new FormArray([]);
            element.elements.forEach((innerElement) => {
              const innerElementGroup = new FormGroup({
                count: new FormControl(innerElement.count)
              });
              innerElementsArray.push(innerElementGroup);
            });
            const elementGroup = new FormGroup({
              elements: innerElementsArray
            });
            elementsArray.push(elementGroup);
          });
          if (this.commentText && this.commentText.trim() !== '') {
            this.pdsaActivityParentFormComponent.hasCommentEntered = true;
          }
          this.mobileDecisionTypeRange = selectedMobileDecisionTypeRange;
          resolve(selectedMobileDecisionTypeRange);
        })
    );
  }

  private sortTimeSlotBySortCode(selectedMobileTerritoryVisitRange): MobileDecisionTypeRange{
    return selectedMobileTerritoryVisitRange.elements.sort((a,b) => a.timeSlot.sortCode - b.timeSlot.sortCode);
  }


  setMobileDecisionTypeRange(
    effectorId,
    effectorYearMonth,
    mobileDecisionTypeRange
  ): Promise<MobileDecisionTypeRange> {
    return new Promise((resolve) => {
      const elementsArray = this.mobileDecisionTypeRangeForm.get(
        'elements'
      ) as FormArray;

      for (let i = 0; i < elementsArray.controls.length; i++) {
        const elementGroup = elementsArray.controls[i] as FormGroup;
        const elementsControl = elementGroup.get('elements');
        if (elementsControl instanceof FormArray && elementsControl.value) {
          for (let j = 0; j < elementsControl.value.length; j++) {
            const countValue = elementGroup.get(`elements.${j}.count`)?.value;

            if (
              mobileDecisionTypeRange.elements &&
              mobileDecisionTypeRange.elements[i] &&
              mobileDecisionTypeRange.elements[i].elements &&
              mobileDecisionTypeRange.elements[i].elements[j]
            ) {
              mobileDecisionTypeRange.elements[i].elements[j].count =
                countValue;
            }
          }
        }
      }
      this.mobileDecisionTypeRangeService
        .setMobileDecisionTypeRange(
          effectorId,
          effectorYearMonth,
          mobileDecisionTypeRange
        )
        .subscribe((response) => {
          this.mobileDecisionTypeRange = response;
          resolve(response);
        });
    });
  }

  handleError(elementsArray: FormArray, attributsName: string[]) {
    let hasInvalidValue = false;
    let hasEmptyValue = false;
    let hasValue = false;

    if (elementsArray && elementsArray.controls) {
      elementsArray.controls.forEach((elementGroup: FormGroup) => {
        const elementControl = elementGroup.controls.elements as FormArray;
        elementControl.controls.forEach((innerElementGroup: FormGroup) => {
          attributsName.forEach((attributName) => {
            const innerElementControl = innerElementGroup.get(attributName);
            if (innerElementControl) {
              const elementValue = innerElementControl.value;

              const isInvalid = isNaN(elementValue) && elementValue !== 'F';
              const isEmpty = !elementValue || elementValue.trim() === '';

              innerElementControl.markAsTouched();
              innerElementControl.updateValueAndValidity();

              if (isInvalid) {
                hasInvalidValue = true;
                return innerElementControl.setErrors({ invalidValue: true });
              } else if (isEmpty) {
                hasEmptyValue = true;
                return innerElementControl.setErrors({ emptyValue: true });
              } else {
                hasValue = true;
                return innerElementControl.setErrors(null);
              }
            }
          });
        });
      });
    }
    return { hasInvalidValue, hasEmptyValue, hasValue };
  }

  onInputChange() {
    this.updateSubmitButton();
    this.valueHasChanged = true;
  }

  async onSubmit(event: EventType) {
    if (event === EventType.NAVIGATE_TO_TAB && this.valueHasChanged) {
      Swal.fire({
        title: 'Vos dernières modifications ont été enregistrées !',
        icon: 'success',
        confirmButtonColor: '#2B8CA9',
        confirmButtonText: 'OK'
      });
    }

    const elementsArray = this.mobileDecisionTypeRangeForm.controls
      .elements as FormArray;

    const { hasInvalidValue, hasEmptyValue, hasValue } = this.handleError(
      elementsArray,
      ['count']
    );

    if (hasInvalidValue) {
      this.pdsaActivityParentFormComponent.handleInvalidValueMessage(event);
      return;
    }

    if (hasEmptyValue && event !== EventType.NAVIGATE_TO_TAB) {
      const result = await Swal.fire({
        title:
          "Certaines données n'ont pas été saisies. Etes-vous sûr de vouloir continuer ?",
        icon: 'info',
        showDenyButton: true,
        confirmButtonColor: '#56CC9D',
        confirmButtonText: 'Oui',
        denyButtonText: 'Non',
        denyButtonColor: '#E0796A'
      });
      if (result.isDenied) {
        return;
      }
    }

    this.pdsaActivityParentFormComponent.hasValuesEnteredSix = hasValue;

    this.pdsaActivityParentFormComponent.updateState(
      event,
      this.mobileDecisionTypeRange
    );

    if (
      hasValue ||
      (event === EventType.SUBMIT &&
        this.pdsaActivityParentFormComponent.hasValuesEntered()) ||
      this.pdsaActivityParentFormComponent.lastSavedStep >= this.step
    ) {
      await this.getEffectorId()
        .then((effectorId) => {
          return effectorId;
        })
        .then((effectorId) => {
          return this.getEffectorYearMonth().then((effectorYearMonth) => {
            return { effectorId, effectorYearMonth };
          });
        })
        .then(({ effectorId, effectorYearMonth }) => {
          if (this.mobileDecisionTypeRangeForm.get('comment')?.value) {
            this.mobileDecisionTypeRange.entry.comment =
              this.mobileDecisionTypeRangeForm.get('comment')?.value;
          } else {
            this.mobileDecisionTypeRange.entry.comment = this.commentText;
          }

          return this.setMobileDecisionTypeRange(
            effectorId,
            effectorYearMonth,
            this.mobileDecisionTypeRange
          );
        })
        .then((mobileDecisionTypeRange) => {
          this.pdsaActivityParentFormComponent.lastSavedStep = Math.max(
            this.mobileDecisionTypeRange.entry.step,
            this.pdsaActivityParentFormComponent.lastSavedStep
          );
          return mobileDecisionTypeRange;
        })
        .catch((error) => {
          console.error(error);
        });
    }

    const submitEvent = await this.pdsaActivityParentFormComponent.afterSubmit(
      event
    );

    if (submitEvent) {
      this.onSubmitEvent.emit();
    }
    return;
  }

  updateSubmitButton() {
    this.mobileDecisionTypeRangeForm.controls.elements.value.forEach(
      (element) => {
        element.elements.forEach((innerElement) => {
          if (innerElement.count) {
            return (this.pdsaActivityParentFormComponent.hasValuesEnteredFour =
              true);
          }
        });
      }
    );
    this.pdsaActivityParentFormComponent.updateSubmitButton();
  }
}
