import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { GraphFilter } from "../../models/graph-filter.model";
import { ValueOfGrapsService } from "../../ntg/services/valueOfGraps.service";
import { ValueOfGraphChargeService } from "../service/valueOfGraphCharge.service";
import { Alert } from "src/app/emergency-depts/model/alert.model";
import { AlertService } from "src/app/emergency-depts/services/alert.service";
import { ActivatedRoute, Router } from "@angular/router";


@Component({
  selector: "app-charge-graph-home",
  templateUrl: "./charge-graph-home.component.html",
  styleUrls: ["./charge-graph-home.component.scss"],
})
export class ChargeGraphHomeComponent {
  alerts: Alert[] = []
  displayPeriod2: boolean;
  graphIndicateurCharge = true;

  constructor(private valueOfGraphChargeService: ValueOfGraphChargeService,     
    private alertService: AlertService,
    private readonly titleService: Title,
    private readonly route: ActivatedRoute,) {}
  filter(graphFilter: GraphFilter) {
    this.displayPeriod2 = false;
    this.valueOfGraphChargeService.getValueOfChargeGraph(
      "period1",
      graphFilter,
      graphFilter.period1
    );
    if (graphFilter.isActivePeriod2) {
      this.displayPeriod2 = true;
      this.valueOfGraphChargeService.getValueOfChargeGraph(
        "period2",
        graphFilter,
        graphFilter.period2
      );
    }
  }

  ngOnInit() { 
    this.titleService.setTitle(this.route.snapshot.data['title']);
    console.log(this.route.snapshot.data['title'])
    const Reg = this.isAraPage() ? 84 : 11;
   const alertLabel = this.isAraPage() ? 'DONNEES-DES-URGENCES_Diagramme-de-charge' : 'URGENCES_Diagramme-de-charge';
    this.alertService
      .getAlertsByPageAlert(Reg, alertLabel)
      .subscribe((res: Alert[]) => {
        this.alerts = res;
        res.map((alert) => this.alertService.get(alert))
      }
      );
  }

  isAlert() {
    if (this.alerts.length != 0) {
      return true
    }
    else {
      return false
    };
  }

  isAraPage() {
    const str = window.location.href;
    return str.includes("ara") || str.includes(":4201/");
  }
}
