import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { EffectorsTerritory } from '../model/effectors-territory.model';

@Injectable({
  providedIn: 'root'
})
export class EffectorsTerritoryService {
  constructor(private httpClient: HttpClient) {}

  public getEffectorsTerritorys(): Observable<EffectorsTerritory[]> {
    return this.httpClient.get<EffectorsTerritory[]>(`/api/effectorsTerritory`);
  }

  public getEffectorsTerritoryByEffectorId(
    id: number
  ): Observable<EffectorsTerritory[]> {
    return this.httpClient.get<EffectorsTerritory[]>(
      `/api/effectorsTerritory/effector/${id}`
    );
  }

  public getEffectorsTerritoryById(id: any): Observable<EffectorsTerritory> {
    return this.httpClient.get<EffectorsTerritory>(
      `/api/effectorsTerritory/${id}`
    );
  }

  public updateEffectorsTerritory(
    id: Number,
    effectorsTerritory: EffectorsTerritory
  ): Observable<EffectorsTerritory> {
    return this.httpClient.put<EffectorsTerritory>(
      `api/effectorsTerritory/${id}`,
      effectorsTerritory
    );
  }

  public saveEffectorsTerritory(
    effectorsTerritory: EffectorsTerritory
  ): Observable<EffectorsTerritory> {
    return this.httpClient.post<EffectorsTerritory>(
      `api/effectorsTerritory`,
      effectorsTerritory
    );
  }

  public deleteEffectorsTerritory(id: Number): Observable<EffectorsTerritory> {
    return this.httpClient.delete<EffectorsTerritory>(
      `api/effectorsTerritory/${id}`
    );
  }
}
