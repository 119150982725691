import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GeoEntity } from '../model/geo-entity.model';

@Injectable({
  providedIn: 'root'
})
export class GeoEntityService {
  constructor(
    private httpClient: HttpClient,
  ) { }

  public getGeographicEntities(): Observable<GeoEntity[]> {
    return this.httpClient.get<GeoEntity[]>(`/api/geo-entities`);
  }
}
