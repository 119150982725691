import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { User } from "../models/user.model";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(private httpClient: HttpClient) {}

  public getUserByPortalId(userPortalId: string): Observable<User> {
    return this.httpClient.get<User>(
      `api/utilisateurs-by-portalId/${userPortalId}`
    );
  }

  public getUsers(): Observable<User[]> {
    if (this.isAraPage() === true) {
      return this.httpClient.get<User[]>(
        `api/utilisateurs/ara`
    )
    }
    else {
      return this.httpClient.get<User[]>(
        `api/utilisateurs/idf`
    )
    }
}

isAraPage() {
  const str = window.location.href;
  return str.includes("ara") || str.includes(":4201/");
}

}
