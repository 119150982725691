import { PdsaStructureActivityMonth } from '../components/pdsa-structure-activity/pdsa-structure-activity-month-model';
import { StateInputPdsa, StateInputPdsaClass } from './state-input-pdsa.model';

export interface Effector {
  id: number;
  effectorArsLabel: string;
  effectorLabel: string;
  effectorShortLabel: string;
  domainCode: string;
  typeStructureCode: string;
  sirenNumber: string;
  siretNumber: string;
  finessCode: string;
  departmentCode: string;
  managerName: string;
  managingEntityName: string;
  platformBoolean: boolean;
  callFlowBoolean: boolean;
  fileFlowBoolean: boolean;
  validityStartDate?: string;
  validityEndDate?: string;
  userId: number;
  ccrBoolean: boolean;
  createdDate: string;
  lastModifiedDate: string;
  validityBoolean: boolean;
  flowTransmissionBoolean: boolean;
  effectorCodeMet: string;
  firstTransmissionDate?: string;
  callTransmissionDate?: Date;
  visitTransmissionDate?: Date;
  stateInputsPDSA: StateInputPdsa[];
}

export class EffectorClass {
  id: number;
  effectorArsLabel: string;
  effectorLabel: string;
  effectorShortLabel: string;
  domainCode: string;
  typeStructureCode: string;
  sirenNumber: string;
  siretNumber: string;
  finessCode: string;
  departmentCode: string;
  managerName: string;
  managingEntityName: string;
  platformBoolean: boolean;
  callFlowBoolean: boolean;
  fileFlowBoolean: boolean;
  validityStartDate: Date;
  validityEndDate: Date;
  userId: number;
  ccrBoolean: boolean;
  createdDate: Date;
  lastModifiedDate: Date;
  validityBoolean: boolean;
  flowTransmissionBoolean: boolean;
  effectorCodeMet: string;
  firstTransmissionDate?: Date;
  callTransmissionDate?: Date;
  visitTransmissionDate?: Date;
  stateInputsPDSA: StateInputPdsaClass[];

  getStateInputPDSAFor(month: PdsaStructureActivityMonth) {
    return this.stateInputsPDSA.find(
      (p) => p.idMonth === StateInputPdsaClass.monthIdFor(month.date)
    );
  }

  constructor(effector: Effector) {
    this.id = effector.id;
    this.effectorArsLabel = effector.effectorArsLabel;
    this.effectorLabel = effector.effectorLabel;
    this.effectorShortLabel = effector.effectorShortLabel;
    this.domainCode = effector.domainCode;
    this.typeStructureCode = effector.typeStructureCode;
    this.sirenNumber = effector.sirenNumber;
    this.siretNumber = effector.siretNumber;
    this.finessCode = effector.finessCode;
    this.departmentCode = effector.departmentCode;
    this.managerName = effector.managerName;
    this.managingEntityName = effector.managingEntityName;
    this.platformBoolean = effector.platformBoolean;
    this.callFlowBoolean = effector.callFlowBoolean;
    this.fileFlowBoolean = effector.fileFlowBoolean;
    this.validityStartDate = new Date(effector.validityStartDate ?? '');
    this.validityEndDate = new Date(effector.validityEndDate ?? '');
    this.userId = effector.userId;
    this.ccrBoolean = effector.ccrBoolean;
    this.createdDate = new Date(effector.createdDate);
    this.lastModifiedDate = new Date(effector.lastModifiedDate);
    this.validityBoolean = effector.validityBoolean;
    this.flowTransmissionBoolean = effector.flowTransmissionBoolean;
    this.effectorCodeMet = effector.effectorCodeMet;
    this.firstTransmissionDate =
      effector.firstTransmissionDate == null
        ? undefined
        : new Date(effector.firstTransmissionDate);
    this.callTransmissionDate =
      effector.callTransmissionDate == null
        ? undefined
        : new Date(effector.callTransmissionDate);
    this.visitTransmissionDate =
      effector.visitTransmissionDate == null
        ? undefined
        : new Date(effector.visitTransmissionDate);
    this.stateInputsPDSA =
      effector.stateInputsPDSA == null
        ? []
        : effector.stateInputsPDSA.map(
            (s) => new StateInputPdsaClass(s.idMonth, s.formNumber, s.state)
          );
  }
}
