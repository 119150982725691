import { Component, OnInit } from '@angular/core';
import {
  NgbModal,
  ModalDismissReasons,
  NgbActiveModal
} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactMailService } from '../../../../home-page-idf/services/contact-mail.service';
import { Account } from 'src/app/core/auth/account.model';
import { AccountService } from 'src/app/core/auth/account.service';
import { ContactRequest } from '../../../../home-page-idf/model/ContactRequestModel';
import { UserService } from 'src/app/shared/service/user.service';
import { User } from 'src/app/shared/models/user.model';
import { ActivityEffector } from 'src/app/pdsa/model/activity/activity-effector.model';

@Component({
  selector: 'app-report-change',
  templateUrl: './report-change.component.html',
  styleUrls: ['./report-change.component.scss']
})
export class ReportChangeComponent implements OnInit {
  account: Account | null = null;
  contactForm: FormGroup;
  closeResult = '';
  contactRequest: ContactRequest = new ContactRequest();

  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private contactMailService: ContactMailService,
    private accountService: AccountService,
    private userService: UserService
  ) {
    this.accountService.identity().subscribe((account) => {
      this.account = account;
    });

    this.contactForm = this.fb.group({
      email: [this.account?.email, Validators.email],
      firstname: [this.account?.firstName, Validators.required],
      lastname: [this.account?.lastName, Validators.required],
      tel: [this.account?.phoneNumber],
      fonction: [this.account?.professionLabel, Validators.required],
      details: [null, Validators.required],
      effectorArsLabel: [null, Validators.required]
    });
  }

  ngOnInit(): void {}

  setSelectedEffector(selectedEffector: ActivityEffector) {
    this.contactForm
      .get('effectorArsLabel')
      ?.setValue(selectedEffector.arsLabel);
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onSubmit(content) {
    this.contactRequest.firstname = this.contactForm.get(['firstname'])?.value;
    this.contactRequest.lastname = this.contactForm.get(['lastname'])?.value;
    this.contactRequest.mail = this.contactForm.get(['email'])?.value;
    this.contactRequest.telephone = this.contactForm.get(['tel'])?.value;
    this.contactRequest.function = this.contactForm.get(['fonction'])?.value;
    this.contactRequest.detail = this.contactForm.get(['details'])?.value;
    this.contactRequest.establishment = this.contactForm.get([
      'effectorArsLabel'
    ])?.value;
    if (this.account) {
      this.userService
        .getUserByPortalId(this.account.id)
        .subscribe((data: User) => {
          this.contactRequest.idUser = data.id.toString();
          if (this.contactForm.valid) {
            this.contactMailService
              .sendToAdmin(this.contactRequest)
              .subscribe((data) => {
                this.activeModal.close(true);
                this.modalService.open(content);
                setTimeout(() => {
                  this.modalService.dismissAll();
                }, 8000);
              });
          } else {
            this.contactForm.markAllAsTouched();
          }
        });
    }
  }

  closeModal() {
    this.activeModal.close();
    this.modalService.dismissAll();
  }
}
