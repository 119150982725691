<body>
  <div
    class="changestyle"
    [ngClass]="isAraPage() == true ? 'ara-style' : 'idf-style'"
  >
    <div class="container">
      <div class="row">
        <div class="col-1">
          <div class="error card" style="width: 450px">
            <h1>404</h1>
            <p>
              Nous sommes désolés, la page que vous avez demandé n'existe pas.
            </p>
            <div class="lien row" *ngIf="isAraPage() == false">
              <a routerLink="/accueil">Retour à la page d'accueil</a>
            </div>
            <div class="lien row" *ngIf="isAraPage() == true">
              <a routerLink="/accueil">Retour à la page d'accueil</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</body>
