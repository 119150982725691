import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Availabilities } from '../model/availabilities.model';
import { EffectorAvailabilities } from '../model/effector-availabilities.model';

@Injectable({
  providedIn: 'root'
})
export class AvailabilitiesService {
  constructor(private httpClient: HttpClient) {}

  public getAvailabilities(): Observable<Availabilities[]> {
    return this.httpClient.get<Availabilities[]>(`/api/availabilities`);
  }

  public getByCodDom(type: string): Observable<Availabilities[]> {
    return this.httpClient.get<Availabilities[]>(
      `/api/availabilities/dom/${type}`
    );
  }

  public getEffectorAvailabilities(
    effectorId: number
  ): Observable<EffectorAvailabilities[]> {
    return this.httpClient.get<EffectorAvailabilities[]>(
      `/api/effectorsAvailabilities/effector/${effectorId}`
    );
  }
}
