import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MobileCrraVisitRange } from 'src/app/pdsa/model/activity/mobile-crra-visit-range.model';
import { MobileCrraVisitRangeService } from 'src/app/pdsa/service/activity/mobile-crra-visit-range.service';

import { PdsaPosteActivityService } from '../../pdsa-poste-activity-form/pdsa-poste-activity-service';
import Swal from 'sweetalert2';
import { PdsaActivityParentFormComponent } from '../pdsa-activity-parent-form.component';
import { EventType } from 'src/app/pdsa/model/activity/effector-event-type.enum';

@Component({
  selector: 'app-pdsa-activity-tab-three',
  templateUrl: './pdsa-activity-tab-three.component.html',
  styleUrls: ['./pdsa-activity-tab-three.component.scss'],
  providers: [PdsaPosteActivityService]
})
export class PdsaActivityTabThreeComponent implements OnInit {
  @Input() form!: FormGroup;
  @Output() formChange = new EventEmitter<FormGroup>();
  @Output() onSubmitEvent: EventEmitter<any> = new EventEmitter();

  effectorId: number;
  effectorYearMonth: string;
  timeSlotLabels: string[] = [];
  timeSlotDayTypes: string[] = [];
  currentPage: number;
  eventTypes = EventType;
  valueHasChanged: boolean = false;
  step: number = 3;

  mobileCrraVisitRangeForm = this.fb.group({
    entry: this.fb.group({
      yearMonth: [''],
      effector: this.fb.group({
        id: []
      })
    }),
    elements: this.fb.array([])
  });

  mobileCrraVisitRange: MobileCrraVisitRange;

  items = ['Exemple 1'];

  constructor(
    private readonly route: ActivatedRoute,
    private mobileCrraVisitRangeService: MobileCrraVisitRangeService,
    private fb: FormBuilder,
    private pdsaPosteActivityService: PdsaPosteActivityService,
    private pdsaActivityParentFormComponent: PdsaActivityParentFormComponent
  ) {}

  ngOnInit() {
    this.pdsaPosteActivityService.currentPage$.subscribe((page) => {
      this.currentPage = page;
    });
    this.form.addControl(
      'tab-three-form',
      this.fb.group({
        test: 'test'
      })
    );
    this.getEffectorId()
      .then((effectorId) => {
        return effectorId;
      })
      .then((effectorId) => {
        return this.getEffectorYearMonth().then((effectorYearMonth) => {
          return { effectorId, effectorYearMonth };
        });
      })
      .then(({ effectorId, effectorYearMonth }) => {
        return this.getMobileCrraVisitRange(effectorId, effectorYearMonth);
      })
      .then((mobileCrraVisitRange) => {
        this.mobileCrraVisitRange = mobileCrraVisitRange;
        this.pdsaActivityParentFormComponent.activeTab = 3;
        this.mobileCrraVisitRange.entry.step =
          this.pdsaActivityParentFormComponent.updateStep(this.step);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getEffectorId(): Promise<number> {
    return new Promise((resolve, reject) => {
      this.route.url.subscribe((segments) => {
        if (segments.length > 0) {
          const lastSegment = segments[segments.length - 1].path;
          const effectorId: number = parseInt(lastSegment, 10);
          resolve(effectorId);
        } else {
          reject("Aucun segment trouvé dans l'URL.");
        }
      });
    });
  }

  getEffectorYearMonth(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.route.url.subscribe((segments) => {
        if (segments.length > 0) {
          const lastSegment = segments[segments.length - 2].path;
          let position = lastSegment.length - 2;
          const effectorYearMonth =
            lastSegment.slice(0, position) + '-' + lastSegment.slice(position);
          resolve(effectorYearMonth);
        } else {
          reject("Aucun segment trouvé dans l'URL.");
        }
      });
    });
  }

  getMobileCrraVisitRange(
    id: number = this.effectorId,
    yearMonth: string = this.effectorYearMonth
  ): Promise<MobileCrraVisitRange> {
    return new Promise((resolve) =>
      this.mobileCrraVisitRangeService
        .getMobileCrraVisitRange(id, yearMonth)
        .subscribe((data) => {
          data.elements.map(() => {
            this.sortTimeSlotBySortCode(data);
          })

          this.timeSlotLabels = data.elements.map(
            (element) => element.timeSlot.label
          );
          this.timeSlotDayTypes = data.elements.map(
            (element) => element.timeSlot.dayType
          );
          this.mobileCrraVisitRange = data;
          this.mobileCrraVisitRangeForm.patchValue(data);
          const elementsArray = this.mobileCrraVisitRangeForm.controls
            .elements as FormArray;
          data.elements.forEach((element) => {
            const countControl = new FormGroup({
              count: new FormControl(element.count)
            });
            elementsArray.push(countControl);
          });
          this.mobileCrraVisitRange = data;
          resolve(data);
        })
    );
  }

  private sortTimeSlotBySortCode(selectedMobileTerritoryVisitRange): MobileCrraVisitRange{
    return selectedMobileTerritoryVisitRange.elements.sort((a,b) => a.timeSlot.sortCode - b.timeSlot.sortCode);
  }


  setMobileCrraVisitRange(effectorId, effectorYearMonth, mobileCrraVisitRange) {
    const elementsArray = this.mobileCrraVisitRangeForm.get(
      'elements'
    ) as FormArray;

    elementsArray.controls.forEach((elementControl, index) => {
      const elementValue = elementControl.get('count')?.value;
      this.mobileCrraVisitRange.elements[index].count = elementValue;
    });
    this.mobileCrraVisitRangeService
      .setMobileCrraVisitRange(
        effectorId,
        effectorYearMonth,
        mobileCrraVisitRange
      )
      .subscribe((Response) => {});
  }

  handleError(elementsArray: FormArray, attributsName: string[]) {
    let hasInvalidValue = false;
    let hasEmptyValue = false;
    let hasValue = false;

    for (let i = 0; i < elementsArray.length; i++) {
      const elementGroup = elementsArray.at(i) as FormGroup;
      const elementValue = elementGroup.get('count')?.value;

      for (const attributName of attributsName) {
        if (elementValue !== undefined) {
          const isInvalid = isNaN(elementValue) && elementValue !== 'F';
          const isEmpty = !elementValue || elementValue.trim() === '';

          if (isInvalid) {
            hasInvalidValue = true;
            elementGroup.get('count')?.setErrors({ invalidValue: true });
          } else if (isEmpty) {
            hasEmptyValue = true;
            elementGroup.get('count')?.setErrors({ emptyValue: true });
          } else {
            hasValue = true;
            elementGroup.get('count')?.setErrors(null);
          }
        }
      }
    }

    return { hasInvalidValue, hasEmptyValue, hasValue };
  }

  onInputChange() {
    this.valueHasChanged = true;
  }

  async onSubmit(event: EventType) {
    if (event === EventType.NAVIGATE_TO_TAB && this.valueHasChanged) {
      Swal.fire({
        title: 'Vos dernières modifications ont été enregistrées !',
        icon: 'success',
        confirmButtonColor: '#2B8CA9',
        confirmButtonText: 'OK'
      });
    }

    const elementsArray = this.mobileCrraVisitRangeForm.get(
      'elements'
    ) as FormArray;

    const { hasInvalidValue, hasEmptyValue, hasValue } = this.handleError(
      elementsArray,
      ['count']
    );

    if (hasInvalidValue) {
      this.pdsaActivityParentFormComponent.handleInvalidValueMessage(event);
      return;
    }

    if (hasEmptyValue && event !== EventType.NAVIGATE_TO_TAB) {
      const result = await Swal.fire({
        title:
          "Certaines données n'ont pas été saisies. Etes-vous sûr de vouloir continuer ?",
        icon: 'info',
        showDenyButton: true,        
        confirmButtonColor: '#56CC9D',
        confirmButtonText: 'Oui',
        denyButtonText: 'Non',
        denyButtonColor: '#E0796A',
        didClose: () => {
          window.scrollTo(0, 0);
        }
      });
      if (result.isDenied) {
        return;
      }
    }
    this.pdsaActivityParentFormComponent.hasValuesEnteredThree = hasValue;

    this.pdsaActivityParentFormComponent.updateState(
      event,
      this.mobileCrraVisitRange
    );

    if (
      hasValue ||
      this.pdsaActivityParentFormComponent.lastSavedStep >= this.step
    ) {
      await this.getEffectorId()
        .then((effectorId) => {
          return effectorId;
        })
        .then((effectorId) => {
          return this.getEffectorYearMonth().then((effectorYearMonth) => {
            return { effectorId, effectorYearMonth };
          });
        })
        .then(({ effectorId, effectorYearMonth }) => {
          this.setMobileCrraVisitRange(
            effectorId,
            effectorYearMonth,
            this.mobileCrraVisitRange
          );
          this.pdsaActivityParentFormComponent.lastSavedStep = Math.max(
            this.mobileCrraVisitRange.entry.step,
            this.pdsaActivityParentFormComponent.lastSavedStep
          );
        })
        .catch((error) => {
          console.error(error);
        });
    }

    const submitEvent = await this.pdsaActivityParentFormComponent.afterSubmit(
      event
    );

    if (submitEvent) {
      this.onSubmitEvent.emit();
    }
    return;
  }
}
