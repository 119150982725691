<form [formGroup]="commentForm">
  <div class="comments-container">
    <div class="comment-item">
      <textarea
        class="commentInput"
        formControlName="comment"
        maxlength="450"
        placeholder="Modifier votre commentaire..."
        (input)="onInputChange()"
      ></textarea>
    </div>
  </div>
</form>
