<div  class="div-alert">
  <alert class="alert"></alert>
</div>
<div class="container-fluid" style="padding-top:2rem;">
  <div class="row">
    <div class="filtres col-12">
      <app-graph-filter
        (doFilter)="filter($event)"
        [needsComparison]="false"
      ></app-graph-filter>
    </div>
  </div>

  <div class="first-container" style="align-items: stretch;">
    <div class="row" style="display: flex; align-items: flex-end">
      <div class="col-12 btn help-button">
        <a type="button" href="assets/docs/EnCoursElaboration.pdf" target="_blank" class="aide"
          >?</a
        >
      </div>
    </div>
    <div class="graph-container" style="align-items: stretch;;">
      <div class="row">
        <div class="col-12 tabs">
          <ul ngbNav #nav="ngbNav" class="nav-tabs">
            <li [ngbNavItem]="1">
              <a ngbNavLink
                ><span>Nombre de passages</span>
                <!-- src="../../../assets/images/IconTitleIT1.svg" -->
                <img
                  class="icones"
                  src="../../../assets/images/nb_passages.svg"
                />
              </a>
              <ng-template ngbNavContent>
                <app-visits-graph [dataInd1]="dataInd1"></app-visits-graph>
              </ng-template>
            </li>
            <li [ngbNavItem]="2">
              <a ngbNavLink
                ><span>Nombre de passages enfants < 1 an</span>
                <img
                  class="icones-ped"
                  src="../../../assets/images/nb_passages_ped.svg"
              /></a>
              <ng-template ngbNavContent>
                <app-visits-child-graph
                  [dataInd2]="dataInd2"
                ></app-visits-child-graph>
              </ng-template>
            </li>
            <li [ngbNavItem]="3">
              <a ngbNavLink
                ><span>Nombre de passages > 75 ans</span>
                <img
                  class="icones-ped"
                  src="../../../assets/images/nb_passages_75.svg"
                />
              </a>
              <ng-template ngbNavContent
                ><app-visits-above-seventy-five-years-old-graph
                  [dataInd3]="dataInd3"
                ></app-visits-above-seventy-five-years-old-graph
              ></ng-template>
            </li>
            <li [ngbNavItem]="4">
              <a ngbNavLink
                ><span>Nombre d'hospitalisations</span>
                <img
                  class="icones"
                  src="../../../assets/images/nb_hospit.svg"
                />
              </a>
              <ng-template ngbNavContent
                ><app-hospitalisations-graph
                  [dataInd4]="dataInd4"
                ></app-hospitalisations-graph
              ></ng-template>
            </li>
            <li [ngbNavItem]="5">
              <a ngbNavLink
                ><span>Taux d'hospitalisation</span>
                <img
                  class="icones"
                  src="../../../assets/images/taux_hospit.svg"
                />
              </a>
              <ng-template ngbNavContent
                ><app-hospitalisations-rate-graph
                  [dataInd5]="dataInd5"
                ></app-hospitalisations-rate-graph
              ></ng-template>
            </li>
            <li [ngbNavItem]="6">
              <a ngbNavLink
                ><span>Nombre d'hospitalisations enfants < 1 an</span>
                <img
                  class="icones"
                  src="../../../assets/images/nb_hospit_ped_4.svg"
              /></a>
              <ng-template ngbNavContent
                ><app-hospitalisations-rate-child-graph
                  [dataInd6]="dataInd6"
                ></app-hospitalisations-rate-child-graph
              ></ng-template>
            </li>
            <li [ngbNavItem]="7">
              <a ngbNavLink
                ><span>Taux d'hospitalisation enfants < 1 an</span>
                <img
                  class="icones"
                  src="../../../assets/images/taux_hospit_ped_4.svg"
              /></a>
              <ng-template ngbNavContent
                ><app-hospitalisations-child-graph
                  [dataInd7]="dataInd7"
                ></app-hospitalisations-child-graph
              ></ng-template>
            </li>
          </ul>

          <div [ngbNavOutlet]="nav"></div>
        </div>
      </div>
    </div>
  </div>
</div>
