import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import * as Highcharts from "highcharts";
import { ChartService } from "src/app/graph/services/chart.service";

@Component({
  selector: "app-visits-above-seventy-five-years-old-graph",
  templateUrl: "./visits-above-seventy-five-years-old-graph.component.html",
  styleUrls: ["./visits-above-seventy-five-years-old-graph.component.scss"],
})
export class VisitsAboveSeventyFiveYearsOldGraphComponent implements OnInit {
  @Input() dataInd3;

  constructor(private chartService: ChartService) {}

  ngOnInit(): void {}
  ngOnChanges(change: SimpleChanges) {
    this.chartService.initChart(
      "visits-above-seventy-five-years-old",
      change.dataInd3.currentValue,
      "Nombre de passages > 75 ans"
    );
  }
}
