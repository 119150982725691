import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MobileCallRange } from 'src/app/pdsa/model/activity/mobile-call-range.model';
import { MobileCallRangeService } from 'src/app/pdsa/service/activity/mobile-call-range.service';

import { PdsaPosteActivityService } from '../../pdsa-poste-activity-form/pdsa-poste-activity-service';
import { EventType } from 'src/app/pdsa/model/activity/effector-event-type.enum';
import Swal from 'sweetalert2';
import { PdsaActivityParentFormComponent } from '../pdsa-activity-parent-form.component';

@Component({
  selector: 'app-pdsa-activity-tab-five',
  templateUrl: './pdsa-activity-tab-five.component.html',
  styleUrls: ['./pdsa-activity-tab-five.component.scss'],
  providers: [PdsaPosteActivityService]
})
export class PdsaActivityTabFiveComponent implements OnInit {
  @Input() form!: FormGroup;
  @Output() formChange = new EventEmitter<FormGroup>();

  @Output() onSubmitEvent: EventEmitter<any> = new EventEmitter();

  effectorId: number;
  effectorYearMonth: string;
  timeSlotLabels: string[] = [];
  timeSlotDayTypes: string[] = [];
  currentPage: number;
  eventTypes = EventType;
  valueHasChanged: boolean = false;
  step: number = 5;

  mobileCallRangeForm = this.fb.group({
    entry: this.fb.group({
      yearMonth: [''],
      effector: this.fb.group({
        id: []
      })
    }),
    elements: this.fb.array([])
  });

  mobileCallRange: MobileCallRange;

  constructor(
    private readonly route: ActivatedRoute,
    private mobileCallRangeService: MobileCallRangeService,
    private pdsaPosteActivityService: PdsaPosteActivityService,
    private fb: FormBuilder,
    private pdsaActivityParentFormComponent: PdsaActivityParentFormComponent
  ) {}

  ngOnInit() {
    this.pdsaPosteActivityService.currentPage$.subscribe((page) => {
      this.currentPage = page;
    });
    this.form.addControl(
      'tab-five-form',
      this.fb.group({
        test: 'test'
      })
    );

    this.getEffectorId()
      .then((effectorId) => {
        return effectorId;
      })
      .then((effectorId) => {
        return this.getEffectorYearMonth().then((effectorYearMonth) => {
          return { effectorId, effectorYearMonth };
        });
      })
      .then(({ effectorId, effectorYearMonth }) => {
        this.getMobileCallRange(effectorId, effectorYearMonth).then(() => {
          this.pdsaActivityParentFormComponent.activeTab = 5;
          this.mobileCallRange.entry.step =
            this.pdsaActivityParentFormComponent.updateStep(this.step);
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getEffectorId(): Promise<number> {
    return new Promise((resolve, reject) => {
      this.route.url.subscribe((segments) => {
        if (segments.length > 0) {
          const lastSegment = segments[segments.length - 1].path;
          const effectorId: number = parseInt(lastSegment, 10);
          resolve(effectorId);
        } else {
          reject("Aucun segment trouvé dans l'URL.");
        }
      });
    });
  }

  getEffectorYearMonth(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.route.url.subscribe((segments) => {
        if (segments.length > 0) {
          const lastSegment = segments[segments.length - 2].path;
          let position = lastSegment.length - 2;
          const effectorYearMonth =
            lastSegment.slice(0, position) + '-' + lastSegment.slice(position);
          resolve(effectorYearMonth);
        } else {
          reject("Aucun segment trouvé dans l'URL.");
        }
      });
    });
  }

  getMobileCallRange(
    id: number = this.effectorId,
    yearMonth: string = this.effectorYearMonth
  ): Promise<MobileCallRange> {
    return new Promise((resolve) =>
      this.mobileCallRangeService
        .getMobileCallRange(id, yearMonth)
        .subscribe((selectedMobileCall) => {
          selectedMobileCall.elements.map(() => {
            this.sortTimeSlotBySortCode(selectedMobileCall);
          })
          this.timeSlotLabels = selectedMobileCall.elements.map(
            (element) => element.timeSlot.label
          );
          this.timeSlotDayTypes = selectedMobileCall.elements.map(
            (element) => element.timeSlot.dayType
          );

          this.mobileCallRange = selectedMobileCall;
          this.mobileCallRangeForm.patchValue(selectedMobileCall);

          const elementsArray = this.mobileCallRangeForm.controls
            .elements as FormArray;
          selectedMobileCall.elements.forEach((element) => {
            const elementGroup = new FormGroup({
              callCount: new FormControl(element.callCount),
              answeredCount: new FormControl(element.answeredCount),
              answeredCountByDoctorOnSite: new FormControl(
                element.answeredCountByDoctorOnSite
              ),
              answeredCountByDoctorOffSite: new FormControl(
                element.answeredCountByDoctorOffSite
              )
            });
            elementsArray.push(elementGroup);
          });
          this.mobileCallRange = selectedMobileCall;
          resolve(selectedMobileCall);
        })
    );
  }

  private sortTimeSlotBySortCode(selectedMobileTerritoryVisitRange): MobileCallRange{
    return selectedMobileTerritoryVisitRange.elements.sort((a,b) => a.timeSlot.sortCode - b.timeSlot.sortCode);
  }

  setMobileCallRange(effectorId, effectorYearMonth, mobileCallEntry) {
    const elementsArray = this.mobileCallRangeForm.get('elements') as FormArray;

    elementsArray.controls.forEach((elementControl, index) => {
      const callCountValue = elementControl.get('callCount')?.value;
      this.mobileCallRange.elements[index].callCount = callCountValue;
      const answeredCountValue = elementControl.get('answeredCount')?.value;
      this.mobileCallRange.elements[index].answeredCount = answeredCountValue;
      const answeredCountByDoctorOnSiteValue = elementControl.get(
        'answeredCountByDoctorOnSite'
      )?.value;
      this.mobileCallRange.elements[index].answeredCountByDoctorOnSite =
        answeredCountByDoctorOnSiteValue;
      const answeredCountByDoctorOffSiteValue = elementControl.get(
        'answeredCountByDoctorOffSite'
      )?.value;
      this.mobileCallRange.elements[index].answeredCountByDoctorOffSite =
        answeredCountByDoctorOffSiteValue;
    });
    this.mobileCallRangeService
      .setMobileCallRange(effectorId, effectorYearMonth, mobileCallEntry)
      .subscribe((response) => {});
  }

  handleError(elementsArray: FormArray, attributsName: string[]) {
    let hasInvalidValue = false;
    let hasEmptyValue = false;
    let hasValue = false;

    if (elementsArray && elementsArray.controls) {
      elementsArray.controls.forEach((elementGroup: FormGroup) => {
        attributsName.forEach((attributName) => {
          const elementControl = elementGroup.get(attributName);

          if (elementControl) {
            const elementValue = elementControl.value;

            const isInvalid = isNaN(elementValue) && elementValue !== 'F';
            const isEmpty = !elementValue || elementValue.trim() === '';

            elementControl.markAsTouched();
            elementControl.updateValueAndValidity();

            if (isInvalid) {
              hasInvalidValue = true;
              return elementControl.setErrors({ invalidValue: true });
            } else if (isEmpty) {
              hasEmptyValue = true;
              return elementControl.setErrors({ emptyValue: true });
            } else {
              hasValue = true;
              return elementControl.setErrors(null);
            }
          }
        });
      });
    }

    return { hasInvalidValue, hasEmptyValue, hasValue };
  }

  onInputChange() {
    this.valueHasChanged = true;
  }

  async onSubmit(event: EventType) {
    if (event === EventType.NAVIGATE_TO_TAB && this.valueHasChanged) {
      Swal.fire({
        title: 'Vos dernières modifications ont été enregistrées !',
        icon: 'success',
        confirmButtonColor: '#2B8CA9',
        confirmButtonText: 'OK'
      });
    }

    const elementsArray = this.mobileCallRangeForm.controls
      .elements as FormArray;

    const { hasInvalidValue, hasEmptyValue, hasValue } = this.handleError(
      elementsArray,
      [
        'callCount',
        'answeredCount',
        'answeredCountByDoctorOnSite',
        'answeredCountByDoctorOffSite'
      ]
    );

    if (hasInvalidValue) {
      this.pdsaActivityParentFormComponent.handleInvalidValueMessage(event);
      return;
    }

    if (hasEmptyValue && event !== EventType.NAVIGATE_TO_TAB) {
      const result = await Swal.fire({
        title:
          "Certaines données n'ont pas été saisies. Etes-vous sûr de vouloir continuer ?",
        icon: 'info',
        showDenyButton: true,
        confirmButtonColor: '#56CC9D',
        confirmButtonText: 'Oui',
        denyButtonText: 'Non',
        denyButtonColor: '#E0796A'
      });
      if (result.isDenied) {
        return;
      }
    }

    this.pdsaActivityParentFormComponent.hasValuesEnteredFive = hasValue;

    this.pdsaActivityParentFormComponent.updateState(
      event,
      this.mobileCallRange
    );

    if (
      hasValue ||
      this.pdsaActivityParentFormComponent.lastSavedStep >= this.step
    ) {
      await this.getEffectorId()
        .then((effectorId) => {
          return effectorId;
        })
        .then((effectorId) => {
          return this.getEffectorYearMonth().then((effectorYearMonth) => {
            return { effectorId, effectorYearMonth };
          });
        })
        .then(({ effectorId, effectorYearMonth }) => {
          this.setMobileCallRange(
            effectorId,
            effectorYearMonth,
            this.mobileCallRange
          );
          this.pdsaActivityParentFormComponent.lastSavedStep = Math.max(
            this.pdsaActivityParentFormComponent.lastSavedStep,
            this.mobileCallRange.entry.step
          );
        })
        .catch((error) => {
          console.error(error);
        });
    }

    const submitEvent = await this.pdsaActivityParentFormComponent.afterSubmit(
      event
    );

    if (submitEvent) {
      this.onSubmitEvent.emit();
    }
    return;
  }
}
