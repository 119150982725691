import { Component, OnInit } from "@angular/core";
import {
  NgbModal,
  ModalDismissReasons,
  NgbActiveModal,
} from "@ng-bootstrap/ng-bootstrap";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ContactMailService } from "../services/contact-mail.service";
import { Account } from "src/app/core/auth/account.model";
import { AccountService } from "src/app/core/auth/account.service";
import { ContactRequest } from "../model/ContactRequestModel";
import { UserService } from "src/app/shared/service/user.service";
import { User } from "src/app/shared/models/user.model";
import { Content } from "@angular/compiler/src/render3/r3_ast";

@Component({
  selector: "app-home-form-contact",
  templateUrl: "./home-form-contact.component.html",
  styleUrls: ["./home-form-contact.component.scss"],
})
export class HomeFormContactComponent implements OnInit {
  account: Account | null = null;

  contactForm: FormGroup;

  closeResult = "";
  contactRequest: ContactRequest = new ContactRequest();
  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private contactMailService: ContactMailService,
    private accountService: AccountService,
    private userService: UserService
  ) {
    this.accountService.identity().subscribe((account) => {
      console.log(account);
      this.account = account;
    });

    this.contactForm = this.fb.group({
      email: [this.account?.email, Validators.email],
      firstname: [this.account?.firstName, Validators.required],
      lastname: [this.account?.lastName, Validators.required],
      tel: [this.account?.phoneNumber],
      etab: [null, Validators.required],
      fonction: [this.account?.professionLabel, Validators.required],
      details: [null, Validators.required],
    });
  }

  ngOnInit(): void {}

  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  onSubmit(content) {
    console.log(this.contactForm.get(["firstname"])?.valid);
    console.log("cest le contactForm:", this.contactForm);
    console.log("contact mail model", this.contactRequest);

    this.contactRequest.firstname = this.contactForm.get(["firstname"])?.value;
    this.contactRequest.lastname = this.contactForm.get(["lastname"])?.value;
    this.contactRequest.mail = this.contactForm.get(["email"])?.value;
    this.contactRequest.telephone = this.contactForm.get(["tel"])?.value;
    this.contactRequest.function = this.contactForm.get(["fonction"])?.value;
    this.contactRequest.detail = this.contactForm.get(["details"])?.value;
    this.contactRequest.establishment = this.contactForm.get(["etab"])?.value;
    if (this.account) {
      console.log("form valid");
      this.userService
        .getUserByPortalId(this.account.id)
        .subscribe((data: User) => {
          console.log(data.id, "id user");

          this.contactRequest.idUser = data.id.toString();
          if (this.contactForm.valid) {
            this.contactMailService
              .sendToAdmin(this.contactRequest)
              .subscribe((data) => {
                this.activeModal.close(true);
                this.modalService.open(content);
                setTimeout(() => {
                  this.modalService.dismissAll();
                }, 8000);
              });
          } else {
            console.log("form not valid");
            this.contactForm.markAllAsTouched();
          }
        });
    }

    //  this.contactMailService.sendMail(this.contactMail).subscribe((data) => {});
  }

  close() {
    this.activeModal.close();
    this.modalService.dismissAll();
  }
  closeModal() {
    this.activeModal.close();
  }
}
