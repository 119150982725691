    <div class="container-trafic" *ngIf="account?.authorities.includes('18')">
      <div class="div-alert"
      style="display: flex; flex-direction:column">
        <alert class="alert"
        style= "padding-right: 0px !important;
        padding-left: 0px !important;
        padding-bottom: 0px !important;
        padding-top: 0px !important;"></alert>
      </div>
      <iframe
        width="100%"
        frameBorder="0"
        src="https://trafic-mes-urgences.sesan.fr/mesurgences"
        scrolling="yes" 
      ></iframe>
    </div>
    <div class="accessdenied" *ngIf="isDisplayed && !account?.authorities.includes('18')">
      <app-error-page></app-error-page>
    </div>


