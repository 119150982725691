<div
  class="changestyle"
  [ngClass]="isAraPage() == true ? 'ara-style' : 'idf-style'"
>
  <!--MODAL CONTACT FORM-->

  <div *ngIf="isAraPage() == false" class="scroll">
    <div class="page">
      <div *ngIf="isAraPage() == false">
        <div
          class="first-co"
          *ngIf="
            account?.authorities.includes('34') &&
            !account?.authorities.includes('35') &&
            768 < innerWidth
          "
        >
          <div class="container">
            <div class="row" style="margin-top: 1%; margin-bottom: 1%">
              <div class="col-md-8 col-12">
                <p class="rights-title">Vous n'avez actuellement aucun droit</p>
                <p class="rights-text" style="margin-bottom: 0px">
                  Pour pouvoir accéder au contenu du site, demandez vos droits
                  d'accès à l'équipe eCerveau :
                </p>
              </div>
              <div
                class="col-4 my-auto d-flex flex-row-reverse"
                style="text-align: -webkit-center"
              >
                <button
                  type="button"
                  class="btn btn-primary btn-sm"
                  (click)="openFormContact()"
                >
                  Demande de droits
                </button>
              </div>
              <div
                *ngIf="768 > innerWidth"
                class="col-12 my-auto d-flex"
                style="text-align: -webkit-center"
              >
                <button
                  type="button"
                  class="btn btn-primary btn-sm"
                  (click)="openFormContact()"
                  style="margin: auto; margin-top: 5%"
                >
                  Demande de droits
                </button>
              </div>
            </div>
          </div>
        </div>
        <!--MOBILE-->
        <div
          class="first-co"
          *ngIf="
            account?.authorities.includes('34') &&
            !account?.authorities.includes('35') &&
            768 > innerWidth
          "
        >
          <div class="container">
            <div
              class="row"
              style="margin-top: 1%; margin-bottom: 1%; padding: 2%"
            >
              <div class="col-md-8 col-12">
                <p class="rights-title">Vous n'avez actuellement aucun droit</p>
                <p class="rights-text" style="margin-bottom: 0px">
                  Pour pouvoir accéder au contenu du site, demandez vos droits
                  d'accès à l'équipe eCerveau :
                </p>
              </div>
              <div
                class="col-12 my-auto d-flex"
                style="text-align: -webkit-center"
              >
                <button
                  type="button"
                  class="btn btn-primary btn-sm"
                  (click)="openFormContact()"
                  style="margin: auto; margin-top: 5%"
                >
                  Demande de droits
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="div-alert">
          <alert
            [ngClass]="alertNumber != 0 || alertNumber ? '' : 'alert-disable'"
            (alertNumber)="getAlertNumber($event)"
            class="alert"
            style="
              padding-right: 0px !important;
              padding-left: 0px !important;
              padding-bottom: 0px !important;
              padding-top: 0px !important;
              margin-bottom: 0px !important;
            "
          ></alert>
        </div>
        <div class="background">
          <div class="anchor" *ngIf="768 < innerWidth">
            <button
              (click)="onClick('anchor-ecerveau')"
              class="btn-anchor"
              ngbTooltip="eCerveau"
              placement="left"
              [ngClass]="{ active: anchor === 'anchor-ecerveau' }"
            ></button
            ><button
              (click)="onClick('anchor-solutions')"
              class="btn-anchor"
              ngbTooltip="Découvrez toutes nos solutions de pilotage"
              placement="left"
              [ngClass]="{ active: anchor === 'anchor-solutions' }"
            ></button>
            <button
              (click)="onClick('anchor-mes-urgences')"
              class="btn-anchor"
              ngbTooltip="Mes urgences"
              placement="left"
              [ngClass]="{ active: anchor === 'anchor-mes-urgences' }"
            ></button>
            <button
              (click)="onClick('anchor-emergency-meteo')"
              class="btn-anchor"
              ngbTooltip="Météo des urgences"
              placement="left"
              [ngClass]="{ active: anchor === 'anchor-emergency-meteo' }"
            ></button>
            <button
              (click)="onClick('anchor-samu-meteo')"
              class="btn-anchor"
              ngbTooltip="Météo des samu"
              placement="left"
              [ngClass]="{ active: anchor === 'anchor-samu-meteo' }"
            ></button>
            <button
              (click)="onClick('anchor-het')"
              class="btn-anchor"
              ngbTooltip="Tension hospitalière"
              placement="left"
              [ngClass]="{ active: anchor === 'anchor-het' }"
            ></button>
            <button
              (click)="onClick('anchor-rpu')"
              class="btn-anchor"
              ngbTooltip="SNP Qualité"
              placement="left"
              [ngClass]="{ active: anchor === 'anchor-rpu' }"
            ></button>
          </div>

          <div class="mouse-scroll" *ngIf="scrolled == 0">
            <button (click)="goToBottom()">
              <img src="assets/images/scroll-mouse.svg" class="mouse" />
            </button>
          </div>
          <div class="mouse-scroll" *ngIf="scrolled == 1"></div>

          <div class="container" id="anchor-ecerveau">
            <div
              *ngIf="768 < innerWidth"
              (mouseenter)="mouseEnter('anchor-ecerveau')"
              style="padding-top: 2%"
            >
              <div class="row logo">
                <img
                  src="assets/images/Logo_bleu_eCerveau.svg"
                  style="height: 40px; margin-bottom: 20px"
                  alt="logo ecerveau"
                />
              </div>
              <div class="row">
                <p class="subtiles">
                  Le site de veille sanitaire des Soins Non Programmés
                  d'île-De-France
                </p>
              </div>
            </div>

            <!--MOBILE-->
            <div
              class="header-phone"
              *ngIf="768 > innerWidth"
              style="padding-left: 30px"
            >
              <div
                class="row"
                *ngIf="768 > innerWidth"
                style="justify-content: left"
              >
                <img
                  src="assets/images/Logo_bleu_eCerveau.svg"
                  style="width: 161px; margin-bottom: 20px; margin-top: 5%"
                  alt="logo ecerveau"
                />
              </div>
              <p class="subtiles-mobile" style="width: 170px">
                Le site de veille sanitaire des soins Non programmés
                d'Ide-De-France
              </p>
            </div>

            <div *ngIf="768 < innerWidth" class="row">
              <div class="live-emergency">
                <p>
                  Urgences Île-de-France - en direct
                  <img src="assets/images/direct.png" style="height: 25px" />
                </p>
                <!--TO DO : ajouter le rond rouge direct-->
              </div>
            </div>

            <!--MOBILE-->
            <div
              class="row"
              *ngIf="768 > innerWidth"
              style="justify-content: center"
            >
              <div class="col-11 live-emergency">
                <p>
                  Urgences Île-de-France - en direct
                  <img src="assets/images/direct.png" style="height: 25px" />
                </p>
                <!--TO DO : ajouter le rond rouge direct-->
              </div>
            </div>

            <!-- CARTE URGENCES -->
            <div class="row">
              <!--CARTE -->
              <!--DESKTOP-->
              <div
                class="col-md-8 col-10 map"
                *ngIf="768 < innerWidth"
                routerLink="/mes-urgences"
                style="cursor: pointer"
              >
                <div class="col-4" style="padding: 0px">
                  <div
                    class="card"
                    routerLink="/mes-urgences"
                    style="cursor: pointer"
                  >
                    <div class="card-body">
                      <div
                        class="row justify-content-end date-card"
                        style="margin-bottom: -20px"
                      >
                        <p class="day">{{ today | date: 'EEEE' }},&nbsp;</p>
                        <p class="direct">En direct</p>
                      </div>
                      <img
                        *ngIf="isPresenceEmergencyUndefined() === true"
                        src="/assets/images/data-off.svg"
                        style="margin-bottom: 7%"
                      />
                      <h6 *ngIf="isPresenceEmergencyUndefined() === false">
                        {{ presenceEmergency }}
                      </h6>

                      <p
                        *ngIf="992 > innerWidth"
                        class="map-p-mobile"
                        style="font-size: 11px"
                      >
                        Patients présents dans les services d'urgences
                      </p>
                      <p *ngIf="992 < innerWidth" style="font-size: 16px">
                        Patients présents dans les services d'urgences
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--MOBILE-->
            <div class="row" style="justify-content: center">
              <div
                class="col-11 map-mobile"
                *ngIf="768 > innerWidth"
                routerLink="/mes-urgences"
              >
                <div class="col-8" style="padding-left: 0px">
                  <div
                    class="card"
                    routerLink="/mes-urgences"
                    style="cursor: pointer"
                  >
                    <div class="card-body">
                      <div class="row justify-content-end date-card">
                        <p class="day-mobile">
                          {{ today | date: 'EEEE' }},&nbsp;
                        </p>
                        <p class="direct-mobile">En direct</p>
                      </div>
                      <img
                        *ngIf="isPresenceEmergencyUndefined() === true"
                        src="/assets/images/data-off.svg"
                        style="margin-bottom: 7%"
                      />
                      <h6
                        *ngIf="isPresenceEmergencyUndefined() === false"
                        class="h6-mobile"
                      >
                        {{ presenceEmergency }}
                      </h6>
                      <p class="map-p-mobile">
                        Patients présents dans les services d'urgences
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--URGENCES IDF CARDS-->
            <div class="row">
              <h3 class="h3-mobile">
                Urgences - {{ indicateurRpu[0]?.idJou | date: 'dd/MM/yyyy' }}
              </h3>
            </div>

            <!--TO DO : DATES REACTIVES-->
            <div class="row cards">
              <!--TO DO : DONNEES REACTIVES-->
              <div
                class="col-md-3 col-6 d-flex align-items-stretch card-left-mobile"
              >
                <div class="card principal" routerLink="/meteo-urgences">
                  <div class="row" *ngIf="isNbrSauTrsUndefined() === true">
                    <img
                      src="/assets/images/data-off.svg"
                      style="margin-bottom: 7%"
                    />
                    <img
                      src="/assets/images/hospital-building.svg"
                      class="picto-hosp"
                    />
                  </div>
                  <div class="row" *ngIf="isNbrSauTrsUndefined() === false">
                    <h5 class="h5-mobile">{{ indicateurRpu[0]?.nbrSauTrs }}</h5>
                    <p
                      *ngIf="768 < innerWidth"
                      class="fraction fraction-mobile"
                    >
                      /{{ indicateurRpu[0]?.nbrSauTheo }}
                    </p>
                    <!--MOBILE-->
                    <p *ngIf="768 > innerWidth" class="fraction-mobile">
                      /{{ indicateurRpu[0]?.nbrSauTheo }}
                    </p>
                    <div
                      *ngIf="
                        768 < innerWidth &&
                        isPercentSauTrsInfiny() === false &&
                        isNbrSauTrsDBeforeUndefined() === false
                      "
                      [ngClass]="
                        isPercentSauTrsNegative() == true
                          ? 'percentage'
                          : 'negative-percentage'
                      "
                    >
                      <p *ngIf="isPercentSauTrsNegative() == false">
                        +{{ percentSauTrs }}%
                      </p>
                      <p *ngIf="isPercentSauTrsNegative() == true">
                        {{ percentSauTrs }}%
                      </p>
                    </div>
                    <!--MOBILE-->
                    <div
                      *ngIf="
                        768 > innerWidth &&
                        isPercentSauTrsInfiny() === false &&
                        isNbrSauTrsDBeforeUndefined() === false
                      "
                      [ngClass]="
                        isPercentSauTrsNegative() == true
                          ? 'percentage-mobile'
                          : 'negative-percentage-mobile'
                      "
                    >
                      <p *ngIf="isPercentSauTrsNegative() == false">
                        +{{ percentSauTrs }}%
                      </p>
                      <p *ngIf="isPercentSauTrsNegative() == true">
                        {{ percentSauTrs }}%
                      </p>
                    </div>
                    <img
                      src="/assets/images/hospital-building.svg"
                      class="picto-hosp"
                    />
                  </div>
                  <p *ngIf="768 < innerWidth" class="card-text">
                    Services d'urgences connectés
                  </p>
                  <!--MOBILE-->
                  <p *ngIf="768 > innerWidth" class="card-text-mobile">
                    Services d'urgences connectés
                  </p>
                </div>
              </div>
              <div
                class="col-md-3 col-6 d-flex align-items-stretch card-right-mobile"
              >
                <div class="card second" routerLink="/meteo-urgences">
                  <div class="row" *ngIf="isNbrPasUndefined() === true">
                    <img
                      src="/assets/images/data-off.svg"
                      style="margin-bottom: 7%"
                    />
                  </div>
                  <div class="row" *ngIf="isNbrPasUndefined() === false">
                    <h5 class="h5-mobile">
                      {{ indicateurRpu[0]?.nbrPas | formatNumber }}
                    </h5>
                    <div
                      *ngIf="
                        768 < innerWidth &&
                        isPercentNbrPasInfiny() === false &&
                        isNbrPasDBeforeUndefined() === false
                      "
                      [ngClass]="
                        isPercentNbrPasNegative() == true
                          ? 'negative-percentage'
                          : 'percentage'
                      "
                    >
                      <p *ngIf="isPercentNbrPasNegative() == false">
                        +{{ percentNbrPas }}%
                      </p>
                      <p *ngIf="isPercentNbrPasNegative() == true">
                        {{ percentNbrPas }}%
                      </p>
                    </div>
                    <div
                      *ngIf="
                        768 > innerWidth &&
                        isPercentNbrPasInfiny() === false &&
                        isNbrPasDBeforeUndefined() === false
                      "
                      [ngClass]="
                        isPercentNbrPasNegative() == true
                          ? 'negative-percentage-mobile'
                          : 'percentage-mobile'
                      "
                    >
                      <p *ngIf="isPercentNbrPasNegative() == false">
                        +{{ percentNbrPas }}%
                      </p>
                      <p *ngIf="isPercentNbrPasNegative() == true">
                        {{ percentNbrPas }}%
                      </p>
                    </div>
                    <p
                      *ngIf="
                        768 < innerWidth &&
                        isPercentNbrPasInfiny() === false &&
                        isNbrPasDBeforeUndefined() === false
                      "
                      class="time"
                    >
                      En 24h
                    </p>
                    <p
                      *ngIf="
                        768 > innerWidth &&
                        isPercentNbrPasInfiny() === false &&
                        isNbrPasDBeforeUndefined() === false
                      "
                      class="time-mobile"
                    >
                      En 24h
                    </p>
                  </div>
                  <p *ngIf="768 < innerWidth" class="card-text">
                    Passages aux urgences
                  </p>
                  <p *ngIf="768 > innerWidth" class="card-text-mobile-second">
                    Passages aux urgences
                  </p>
                </div>
              </div>

              <div
                class="col-md-3 col-6 d-flex align-items-stretch card-left-mobile"
              >
                <div class="card second" routerLink="/meteo-urgences">
                  <div class="row" *ngIf="isNbrPasPedUndefined() === true">
                    <img
                      src="/assets/images/data-off.svg"
                      style="margin-bottom: 7%"
                    />
                  </div>
                  <div class="row" *ngIf="isNbrPasPedUndefined() === false">
                    <h5 class="h5-mobile">
                      {{ indicateurRpu[0]?.nbrPasPed | formatNumber }}
                    </h5>
                    <div
                      *ngIf="
                        768 < innerWidth &&
                        isPercentNbrPasPedInfiny() === false &&
                        isNbrPasPedDBeforeUndefined() === false
                      "
                      [ngClass]="
                        isPercentNbrPasPedNegative() == true
                          ? 'negative-percentage'
                          : 'percentage'
                      "
                    >
                      <p *ngIf="isPercentNbrPasPedNegative() == false">
                        +{{ percentNbrPasPed }}%
                      </p>
                      <p *ngIf="isPercentNbrPasPedNegative() == true">
                        {{ percentNbrPasPed }}%
                      </p>
                    </div>
                    <div
                      *ngIf="
                        768 > innerWidth &&
                        isPercentNbrPasPedInfiny() === false &&
                        isNbrPasPedDBeforeUndefined() === false
                      "
                      [ngClass]="
                        isPercentNbrPasPedNegative() == true
                          ? 'negative-percentage-mobile'
                          : 'percentage-mobile'
                      "
                    >
                      <p *ngIf="isPercentNbrPasPedNegative() == false">
                        +{{ percentNbrPasPed }}%
                      </p>
                      <p *ngIf="isPercentNbrPasPedNegative() == true">
                        {{ percentNbrPasPed }}%
                      </p>
                    </div>
                    <p
                      *ngIf="
                        768 < innerWidth &&
                        isPercentNbrPasPedInfiny() === false &&
                        isNbrPasPedDBeforeUndefined() === false
                      "
                      class="time"
                    >
                      En 24h
                    </p>
                    <p
                      *ngIf="
                        768 > innerWidth &&
                        isPercentNbrPasPedInfiny() === false &&
                        isNbrPasPedDBeforeUndefined() === false
                      "
                      class="time-mobile"
                    >
                      En 24h
                    </p>
                  </div>
                  <p *ngIf="768 < innerWidth" class="card-text">
                    Passages aux urgences Pédiatriques
                  </p>
                  <p *ngIf="768 > innerWidth" class="card-text-mobile-second">
                    Passages aux urgences Pédiatriques
                  </p>
                </div>
              </div>

              <div
                class="col-md-3 col-6 d-flex align-items-stretch card-right-mobile"
              >
                <div class="card second" routerLink="/meteo-urgences">
                  <div class="row" *ngIf="isNbrPasHospUndefined() === true">
                    <img
                      src="/assets/images/data-off.svg"
                      style="margin-bottom: 7%"
                    />
                  </div>
                  <div class="row" *ngIf="isNbrPasHospUndefined() === false">
                    <h5 class="h5-mobile">
                      {{ indicateurRpu[0]?.nbrPasHosp | formatNumber }}
                    </h5>
                    <div
                      *ngIf="
                        768 < innerWidth &&
                        isPercentNbrPasHospInfiny() === false &&
                        isNbrPasHospDBeforeUndefined() === false
                      "
                      [ngClass]="
                        isPercentNbrPasHospNegative() == true
                          ? 'negative-percentage'
                          : 'percentage'
                      "
                    >
                      <p *ngIf="isPercentNbrPasHospNegative() == false">
                        +{{ percentNbrPasHosp }}%
                      </p>
                      <p *ngIf="isPercentNbrPasHospNegative() == true">
                        {{ percentNbrPasHosp }}%
                      </p>
                    </div>
                    <div
                      *ngIf="
                        768 > innerWidth &&
                        isPercentNbrPasHospInfiny() === false &&
                        isNbrPasHospDBeforeUndefined() === false
                      "
                      [ngClass]="
                        isPercentNbrPasHospNegative() == true
                          ? 'negative-percentage-mobile'
                          : 'percentage-mobile'
                      "
                    >
                      <p *ngIf="isPercentNbrPasHospNegative() == false">
                        +{{ percentNbrPasHosp }}%
                      </p>
                      <p *ngIf="isPercentNbrPasHospNegative() == true">
                        {{ percentNbrPasHosp }}%
                      </p>
                    </div>
                    <p
                      *ngIf="
                        768 < innerWidth &&
                        isPercentNbrPasHospInfiny() === false &&
                        isNbrPasHospDBeforeUndefined() === false
                      "
                      class="time"
                    >
                      En 24h
                    </p>
                    <p
                      *ngIf="
                        768 > innerWidth &&
                        isPercentNbrPasHospInfiny() === false &&
                        isNbrPasHospDBeforeUndefined() === false
                      "
                      class="time-mobile"
                    >
                      En 24h
                    </p>
                  </div>
                  <p *ngIf="768 < innerWidth" class="card-text">
                    Passages suivi d'hospitalisation
                  </p>
                  <p *ngIf="768 > innerWidth" class="card-text-mobile-second">
                    Passages suivi d'hospitalisation
                  </p>
                </div>
              </div>
            </div>

            <!--SAMU CARDS-->
            <div class="row">
              <h3 class="h3-mobile">
                SAMU - {{ indicateurSamu[0]?.idJou | date: 'dd/MM/yyyy' }}
              </h3>
            </div>
          </div>
        </div>

        <div style="background-color: #f4f9fc">
          <div class="container">
            <!--fin image fond -->
            <!--TO DO : DATES REACTIVES-->
            <div class="row cards">
              <!--TO DO : DONNEES REACTIVES-->
              <div
                class="col-md-3 col-6 d-flex align-items-stretch card-left-mobile"
              >
                <div class="card principal" routerLink="/meteo-samu">
                  <div class="row" *ngIf="isIndConUndefined() === true">
                    <img
                      src="/assets/images/data-off.svg"
                      style="margin-bottom: 7%"
                    />
                    <img src="/assets/images/ambulance.svg" class="picto" />
                  </div>
                  <div class="row" *ngIf="isIndConUndefined() === false">
                    <h5 class="h5-mobile">{{ indicateurSamu[0]?.indCon }}</h5>
                    <!--DESKTOP-->
                    <p *ngIf="768 < innerWidth" class="fraction">/8</p>
                    <!--MOBILE-->
                    <p *ngIf="768 > innerWidth" class="fraction-mobile">/8</p>
                    <!--DESKTOP-->
                    <div
                      *ngIf="
                        768 < innerWidth &&
                        isPercentIndConInfiny() === false &&
                        isIndConDBeforeUndefined() === false
                      "
                      [ngClass]="
                        isPercentIndConNegative() == true
                          ? 'percentage'
                          : 'negative-percentage'
                      "
                    >
                      <p *ngIf="isPercentIndConNegative() == false">
                        +{{ percentIndCon }}%
                      </p>
                      <p *ngIf="isPercentIndConNegative() == true">
                        {{ percentIndCon }}%
                      </p>
                    </div>
                    <!--MOBILE-->
                    <div
                      *ngIf="
                        768 > innerWidth &&
                        isPercentIndConInfiny() === false &&
                        isIndConDBeforeUndefined() === false
                      "
                      [ngClass]="
                        isPercentIndConNegative() == true
                          ? 'percentage-mobile'
                          : 'negative-percentage-mobile'
                      "
                    >
                      <p *ngIf="isPercentIndConNegative() == false">
                        +{{ percentIndCon }}%
                      </p>
                      <p *ngIf="isPercentIndConNegative() == true">
                        {{ percentIndCon }}%
                      </p>
                    </div>
                    <img src="/assets/images/ambulance.svg" class="picto" />
                  </div>

                  <!--DESKTOP-->
                  <p *ngIf="768 < innerWidth" class="card-text">
                    SAMU connectés
                  </p>
                  <!--MOBILE-->
                  <p *ngIf="768 > innerWidth" class="card-text-mobile">
                    SAMU connectés
                  </p>
                </div>
              </div>
              <div
                class="col-md-3 col-6 d-flex align-items-stretch card-right-mobile"
              >
                <div class="card second" routerLink="/meteo-samu">
                  <div class="row" *ngIf="isIndDosUndefined() === true">
                    <img
                      src="/assets/images/data-off.svg"
                      style="margin-bottom: 7%"
                    />
                  </div>
                  <div class="row" *ngIf="isIndDosUndefined() === false">
                    <h5 class="h5-mobile">
                      {{ indicateurSamu[0]?.indDos | formatNumber }}
                    </h5>
                    <!--DESKTOP-->
                    <div
                      *ngIf="
                        768 < innerWidth &&
                        isPercentIndDosInfiny() === false &&
                        isIndDosDBeforeUndefined() === false
                      "
                      [ngClass]="
                        isPercentIndDosNegative() == true
                          ? 'negative-percentage'
                          : 'percentage'
                      "
                    >
                      <p *ngIf="isPercentIndDosNegative() == false">
                        +{{ percentIndDos }}%
                      </p>
                      <p *ngIf="isPercentIndDosNegative() == true">
                        {{ percentIndDos }}%
                      </p>
                    </div>
                    <!--MOBILE-->
                    <div
                      *ngIf="
                        768 > innerWidth &&
                        isPercentIndDosInfiny() === false &&
                        isIndDosDBeforeUndefined() === false
                      "
                      [ngClass]="
                        isPercentIndDosNegative() == true
                          ? 'negative-percentage-mobile'
                          : 'percentage-mobile'
                      "
                    >
                      <p *ngIf="isPercentIndDosNegative() == false">
                        +{{ percentIndDos }}%
                      </p>
                      <p *ngIf="isPercentIndDosNegative() == true">
                        {{ percentIndDos }}%
                      </p>
                    </div>
                    <p
                      *ngIf="
                        768 < innerWidth &&
                        isPercentIndDosInfiny() === false &&
                        isIndDosDBeforeUndefined() === false
                      "
                      class="time"
                    >
                      En 24h
                    </p>
                    <p
                      *ngIf="
                        768 > innerWidth &&
                        isPercentIndDosInfiny() === false &&
                        isIndDosDBeforeUndefined() === false
                      "
                      class="time-mobile"
                    >
                      En 24h
                    </p>
                  </div>
                  <p *ngIf="768 < innerWidth" class="card-text">
                    Dossiers de Régulation Médicale
                  </p>
                  <p *ngIf="768 > innerWidth" class="card-text-mobile-second">
                    Dossiers de Régulation Médicale
                  </p>
                </div>
              </div>

              <div
                class="col-md-3 col-6 d-flex align-items-stretch card-left-mobile"
              >
                <div class="card second" routerLink="/meteo-samu">
                  <div class="row" *ngIf="isIndUmhUndefined() === true">
                    <img
                      src="/assets/images/data-off.svg"
                      style="margin-bottom: 7%"
                    />
                  </div>
                  <div class="row" *ngIf="isIndUmhUndefined() === false">
                    <h5 class="h5-mobile">
                      {{ indicateurSamu[0]?.indUmh | formatNumber }}
                    </h5>
                    <!--DESKTOP-->
                    <div
                      *ngIf="
                        768 < innerWidth &&
                        isPercentIndUmhInfiny() === false &&
                        isIndUmhDBeforeUndefined() === false
                      "
                      [ngClass]="
                        isPercentIndUmhNegative() == true
                          ? 'negative-percentage'
                          : 'percentage'
                      "
                    >
                      <p *ngIf="isPercentIndUmhNegative() == false">
                        +{{ percentIndUmh }}%
                      </p>
                      <p *ngIf="isPercentIndUmhNegative() == true">
                        {{ percentIndUmh }}%
                      </p>
                    </div>
                    <!--MOBILE-->
                    <div
                      *ngIf="
                        768 > innerWidth &&
                        isPercentIndUmhInfiny() === false &&
                        isIndUmhDBeforeUndefined() === false
                      "
                      [ngClass]=""
                    >
                      <p *ngIf="isPercentIndUmhNegative() == false">
                        +{{ percentIndUmh }}%
                      </p>
                      <p *ngIf="isPercentIndUmhNegative() == true">
                        {{ percentIndUmh }}%
                      </p>
                    </div>
                    <p
                      *ngIf="
                        768 < innerWidth &&
                        isPercentIndUmhInfiny() === false &&
                        isIndUmhDBeforeUndefined() === false
                      "
                      class="time"
                    >
                      En 24h
                    </p>
                    <p
                      *ngIf="
                        768 > innerWidth &&
                        isPercentIndUmhInfiny() === false &&
                        isIndUmhDBeforeUndefined() === false
                      "
                      class="time-mobile"
                    >
                      En 24h
                    </p>
                  </div>
                  <p *ngIf="768 < innerWidth" class="card-text">
                    Interventions d'Unités Mobiles hospitalières
                  </p>
                  <p *ngIf="768 > innerWidth" class="card-text-mobile-second">
                    Interventions d'Unités Mobiles hospitalières
                  </p>
                </div>
              </div>
            </div>

            <!--TEXTE eCERVEAU-->
            <div class="eCerveau">
              <h4>eCerveau</h4>
              <div class="row">
                <div class="col-sm-7">
                  <p>
                    eCerveau est une solution de veille et d’alerte sanitaire
                    qui permet d’organiser au mieux les ressources hospitalières
                    d’Île-de-France. eCerveau collecte chaque jour les Résumés
                    de Passage aux Urgences (RPU) comportant les informations
                    concernant l’activité des services d’urgence (heure
                    d’arrivée, motif de consultation, etc.). Ces RPU sont
                    définis selon un format national réglementaire, et sont
                    collectés automatiquement depuis les logiciels métiers des
                    services d’urgence franciliens. Particularité de la région
                    francilienne, eCerveau collecte également un flux dit «
                    d’activité » qui permet de récupérer en temps réel
                    l’activité des services d’urgences.
                  </p>
                </div>
                <div class="col-sm-5">
                  <ul
                    *ngFor="let rapportName of rapportNames; let index = index"
                    class="rapport-activite"
                    class="rapport"
                  >
                    <li>
                      <a
                        [href]="
                          '/static/rapport-activite-SAU/' +
                          filePdfName[index].name
                        "
                        target="_blank"
                        ><img
                          src="assets/images/rapport.png"
                          style="height: 20px"
                        />
                        {{ rapportName }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style="background-color: white; width: 100%"
          class="discover"
          id="anchor-solutions"
          (mouseenter)="mouseEnter('anchor-solutions')"
        >
          <div class="container">
            <div
              class="row justify-content-center mb-4"
              style="text-align: center"
            >
              <div class="col-10">
                <h2 class="h2-mobile" style="justify-content: center">
                  Découvrez toutes nos solutions de pilotage
                </h2>
              </div>
            </div>
          </div>

          <div
            *ngIf="768 < innerWidth"
            class="row justify-content-center btn-row"
            id="anchor-mes-urgences"
          >
            <a
              role="button"
              class="btn btn-outline btn-sm"
              (click)="onClick('anchor-mes-urgences')"
            >
              <p>Mes urgences</p>
            </a>

            <button
              type="button"
              class="btn btn-outline btn-sm"
              (click)="onClick('anchor-emergency-meteo')"
            >
              <p>Météo des urgences</p>
            </button>

            <button
              type="button"
              class="btn btn-outline btn-sm"
              (click)="onClick('anchor-samu-meteo')"
            >
              <p>Météo des SAMU</p>
            </button>

            <button
              type="button"
              class="btn btn-outline btn-sm"
              (click)="onClick('anchor-het')"
            >
              <p>Tension hospitalière</p>
            </button>

            <button
              type="button"
              class="btn btn-outline btn-sm"
              (click)="onClick('anchor-rpu')"
            >
              <p>SNP Qualité</p>
            </button>
          </div>
          <ng-component id="anchor-mes-urgences"></ng-component>
        </div>
      </div>

      <!-- PARTIE MES URGENCES -->
      <div
        style="background-color: #f4f9fc"
        class="grey-frame"
        (mouseenter)="mouseEnter('anchor-mes-urgences')"
      >
        <div class="container appli">
          <h3 class="h3-app-mobile">Mes Urgences</h3>
          <img src="assets/images/mes_urgences.png" style="width: 100%" />
        </div>

        <div style="background-color: white" class="white-frame">
          <div class="container">
            <div class="row list">
              <div class="col-sm-6">
                <h4>Mes Urgences : l'affluence en temps réel d'urgences</h4>
                <h4 class="h4-mobile" *ngIf="768 > innerWidth">
                  <img
                    src="assets/images/monitor-cellphone.svg"
                    style="height: 20px"
                  />
                  Compatibilité WEB/iOS/Android
                </h4>
                <p>
                  Mes Urgences est une application mobile proposée par l’Agence
                  Régionale de Santé d’Île-de-France et le SESAN.
                </p>
                <p>
                  Mes Urgences fournit à l’Utilisateur pour la région
                  Île-de-France, une estimation en temps réel, du niveau
                  d’affluence attendu dans les services d’urgence franciliens
                  partenaires, et pour chaque heure, une durée de passage total
                  estimée pour une consultation sans hospitalisation.
                </p>

                <p class="p-list">
                  Pour les professionnels de santé des urgences habilités, « Mes
                  Urgences » fournit :
                </p>
                <ul>
                  <li>
                    Le taux d’occupation des services d’urgence en temps réel,
                  </li>
                  <li>
                    Une vision détaillée de la localisation des patients dans
                    les services.
                  </li>
                </ul>
              </div>
              <div class="col-sm-6">
                <h4 class="compatibilite" *ngIf="768 < innerWidth">
                  <img
                    src="assets/images/monitor-cellphone.svg"
                    style="height: 20px"
                  />
                  Compatibilité WEB/iOS/Android
                </h4>
                <p class="p-list">
                  Pour le grand public, Mes urgences permet également d'obtenir
                  :
                </p>

                <ul>
                  <li>Le niveau d’affluence des services d’urgence,</li>
                  <li>Une estimation du temps de passage total,</li>
                  <li>
                    La géolocalisation de l’utilisateur afin de connaître le
                    niveau d’affluence des service d’urgence les plus proches du
                    lieu où il se trouve,
                  </li>
                  <li>
                    L’Itinéraire vers le service d’urgence que vous aurez
                    sélectionné.
                  </li>
                </ul>
                <p>
                  Mes Urgences est accessible depuis les stores iOS et Android.
                </p>
              </div>
            </div>

            <div class="row justify-content-center">
              <div class="col-sm-4">
                <a
                  role="button"
                  routerLink="/mes-urgences"
                  class="btn btn-primary btn-block know-more"
                  style="margin-left: auto; margin-right: auto"
                >
                  Accéder
                </a>
              </div>
            </div>
          </div>
          <ng-component id="anchor-emergency-meteo"></ng-component>
        </div>
      </div>

      <!-- PARTIE METEO DES URGENCES IDF -->
      <div
        style="background-color: #f4f9fc"
        class="grey-frame"
        (mouseenter)="mouseEnter('anchor-emergency-meteo')"
      >
        <div class="container appli">
          <h3 class="h3-app-mobile">Météo des Urgences</h3>
          <img src="assets/images/meteo_urgences.png" style="width: 100%" />
        </div>
      </div>

      <div
        style="background-color: white"
        class="white-frame"
        (mouseenter)="mouseEnter('anchor-emergency-meteo')"
      >
        <div class="container">
          <div class="row">
            <div class="col-sm-6 list">
              <h4>Météo quotidienne des Urgences</h4>
              <h4 class="h4-mobile" *ngIf="768 > innerWidth">
                <img
                  src="assets/images/monitor-cellphone.svg"
                  style="height: 20px"
                />
                Compatibilité PC/Mobile
              </h4>
              <p>
                La « Météo des Urgences Île-de-France » est un tableau de bord
                automatique, mis à jour quotidiennement, disponible sur eCerveau
                et adressé par mail, qui restitue l’activité des SAU franciliens
                et leur typologie.
              </p>
              <p>
                Elle est mise à disposition des directions de service d’urgence,
                des directions hospitalières, tutelles et sociétés savantes.
              </p>
              <p>
                La « Météo des Urgences Île-de-France » a été élaboré par le
                SESAN avec la collaboration avec l’Observatoire des Soins Non
                Programmés (ORNSP) d’Ile de France et son collège urgences, sous
                l’égide de l’ARS IdF.
              </p>
            </div>
            <div class="col-sm-6 list">
              <h4 class="compatibilite" *ngIf="768 < innerWidth">
                <img
                  src="assets/images/monitor-cellphone.svg"
                  style="height: 20px"
                />
                Compatibilité PC/Mobile
              </h4>
              <p class="p-list">
                Elle met à disposition, sur la base des Résumés de Passages aux
                Urgences, remontées chaque jour depuis les logiciels métiers les
                indicateurs suivants modulables par tranche d'âge :
              </p>
              <ul style="margin-bottom: 0px">
                <li>
                  Le nombre des passages journaliers, des hospitalisations,
                </li>
                <li>Le temps de passage médian,</li>
                <li>Le top 5 des diagnostics principaux,</li>
                <li>
                  Les modes de sortie (domicile, hospitalisation,…) et leur
                  évolution,
                </li>
                <li>
                  Les courbes de suivi d’activité (passages et hospitalisation)
                  et les tendances à J3,
                </li>
                <li>Une restitution cartographique départementale,</li>
                <li>
                  Les courbes de suivi épidémiologiques et syndromiques CIM10
                  par rapport aux 3 années précédentes.
                </li>
              </ul>
            </div>
          </div>

          <div class="row justify-content-center">
            <div class="col-sm-4">
              <button
                type="button"
                class="btn btn-primary btn-block know-more"
                routerLink="/meteo-urgences"
                style="margin-left: auto; margin-right: auto"
              >
                Accéder
              </button>
            </div>
          </div>
          <ng-component id="anchor-samu-meteo"></ng-component>
        </div>
      </div>

      <!-- PARTIE METEO DES SAMU IDF -->
      <div
        style="background-color: #f4f9fc"
        class="grey-frame"
        (mouseenter)="mouseEnter('anchor-samu-meteo')"
      >
        <div class="container appli">
          <h3 class="h3-app-mobile">Météo des SAMU</h3>
          <img src="assets/images/meteo_samu.png" style="width: 100%" />
        </div>
      </div>

      <div
        style="background-color: white"
        class="white-frame"
        (mouseenter)="mouseEnter('anchor-samu-meteo')"
      >
        <div class="container">
          <div class="row list">
            <div class="col-sm-6">
              <h4>Météo quotidienne des SAMU</h4>
              <h4 class="h4-mobile" *ngIf="768 > innerWidth">
                <img
                  src="assets/images/monitor-cellphone.svg"
                  style="height: 20px"
                />
                Compatibilité PC/Mobile
              </h4>
              <p>
                La « Météo des SAMU Île-de-France » est un tableau de bord
                automatique, mis à jour quotidiennement, disponible sur eCerveau
                et adressé par mail, qui restitue l’activité des 8 Samu
                franciliens et sa typologie.
              </p>
              <p>
                Elle est mis à disposition des directions des SAMU, des SMUR,
                des directions hospitalières, tutelles et sociétés savantes.
              </p>
              <p>
                Elle a été élaboré par le SESAN en collaboration avec
                l’Observatoire des Soins Non Programmés (ORNSP) d’Île-de-France
                et son collège Samu, sous l’égide de l’ARS IdF.
              </p>
            </div>
            <div class="col-sm-6">
              <h4 class="compatibilite" *ngIf="768 < innerWidth">
                <img
                  src="assets/images/monitor-cellphone.svg"
                  style="height: 20px"
                />
                Compatibilité PC/Mobile
              </h4>
              <p class="p-list">
                Elle met à disposition, sur la base des informations remontées
                quotidiennement depuis les logiciels métiers qui alimentent le
                « SI Samu francilien », les indicateurs suivants modulables par
                département :
              </p>
              <ul>
                <li>Le nombre des appels entrants journaliers,</li>
                <li>
                  Le nombre des Dossiers de Régulation Médicale et d’engagements
                  des Unités Mobiles Hospitalières,
                </li>
                <li>Le top 5 des motifs d’appels,</li>
                <li>
                  Les différents types de décisions prises et leur évolution sur
                  3 jours,
                </li>
                <li>
                  les courbes de suivi d’activité des appels et des DRM et les
                  tendances à J3,
                </li>
                <li>Une restitution cartographique départementale,</li>
                <li>
                  Les courbes de suivi par motifs d’appels par rapport aux 3
                  années précédentes,
                </li>
                <li>Les services de destination des UMH</li>
              </ul>
            </div>
          </div>

          <div class="row justify-content-center know-more">
            <div class="col-sm-4">
              <button
                type="button"
                class="btn btn-primary btn-block"
                routerLink="/meteo-samu"
                style="margin-left: auto; margin-right: auto"
              >
                Accéder
              </button>
            </div>
          </div>
          <ng-component id="anchor-het"></ng-component>
        </div>
      </div>

      <!-- PARTIE HET  -->
      <div
        style="background-color: #f4f9fc"
        class="grey-frame"
        (mouseenter)="mouseEnter('anchor-het')"
      >
        <div class="container appli">
          <h3 class="h3-app-mobile">Tension hospitalière</h3>
          <img src="assets/images/HET.png" style="width: 100%" />
        </div>
      </div>

      <div
        style="background-color: white"
        class="white-frame"
        (mouseenter)="mouseEnter('anchor-het')"
      >
        <div class="container">
          <div class="row list">
            <div class="col-sm-6">
              <h4>Hôpital en Tension</h4>
              <h4 class="h4-mobile" *ngIf="768 > innerWidth">
                <img src="assets/images/desktop.svg" style="height: 20px" />
                Compatibilité PC
              </h4>
              <p class="p-list">
                Le module « Tension Hospitalière » compile les données issues
                des Services d’Accueil des Urgences remontées quotidiennement
                dans les Résumés de Passages aux Urgences. Ces RPU, remontés
                quotidiennement depuis les logiciels métier, permettent de
                déduire 6 indicateurs afin d’objectiver les situations de
                tension au sein des établissements hébergeant des SAU :
              </p>
              <ul>
                <li>Le nombre des passages,</li>
                <li>Le nombre des patients présents à 15h,</li>
                <li>
                  Le nombre des patients présents de plus de 75 ans, ou de moins
                  de 1 an pour les SAU pédiatriques,
                </li>
                <li>La durée de présence,</li>
                <li>Le taux d’hospitalisation depuis le SAU,</li>
                <li>
                  La disponibilité en lits (pour l’instant non fonctionnel).
                </li>
              </ul>
            </div>
            <div class="col-sm-6">
              <h4 class="compatibilite" *ngIf="768 < innerWidth">
                <img src="assets/images/desktop.svg" style="height: 20px" />
                Compatibilité PC
              </h4>
              <p>
                Chaque indicateur dispose de seuils de tension dynamiques
                déterminés sur un échantillon d’historique de données pour
                chaque service, et aboutit à un niveau de tension global pour le
                service (nombre des indicateurs ayant dépassé le seuil
                rouge).<br />
              </p>
              <p>
                L’historique de la tension est restitué à l’échelle de
                l’établissement, consultable selon une frise chronologique. La
                tension hospitalière est également restituée à l’échelle
                départementale, puis de la région.
              </p>
              <p>
                Le module « Tension Hospitalière » a été élaboré en
                collaboration avec le GCS et le réseau des urgences de la région
                Auvergne-Rhone-Alpes, selon les recommandations de la FEDORU,
                mis à disposition de l’ORSNP d’Ile de France, sous l’égide des
                l’ARS Auvergne-Rhones-Alpes et d’Ile de France.
              </p>
            </div>
          </div>

          <div class="row justify-content-center">
            <div class="col-sm-4">
              <button
                type="button"
                class="btn btn-primary btn-block know-more"
                routerLink="/het"
                style="margin-left: auto; margin-right: auto"
              >
                Accéder
              </button>
            </div>
          </div>
          <ng-component id="anchor-rpu"></ng-component>
        </div>
      </div>

      <!-- PARTIE Qualité RPU -->
      <div
        style="background-color: #f4f9fc"
        class="grey-frame"
        (mouseenter)="mouseEnter('anchor-rpu')"
      >
        <div class="container appli">
          <h3 class="h3-app-mobile">SNP Qualité</h3>
          <img src="assets/images/SNP.png" style="width: 100%" />
        </div>
      </div>

      <div
        style="background-color: white"
        class="white-frame"
        (mouseenter)="mouseEnter('anchor-rpu')"
      >
        <div class="container">
          <div class="row">
            <div class="col-sm-6">
              <h4>Qualité des Résumés de Passages aux Urgences</h4>
              <h4 class="h4-mobile" *ngIf="768 > innerWidth">
                <img src="assets/images/desktop.svg" style="height: 20px" />
                Compatibilité PC/Mobile
              </h4>
              <p>
                Le module « Qualité Soins Non Programmés » permet le monitorage
                de la transmission (exhaustivité), de la conformité des
                informations transmises et in fine, de l’exploitabilité de
                chacun des champs prévus réglementairement des Résumés de
                Passages aux Urgences (RPU) émis quotidiennement par les
                services d’urgences de la région vers l’entrepot de données
                régional.<br />
                Ces RPU sont utilisés aussi bien en région qu’au niveau national
                (panorama FEDORU, études nationales, ...) a des fins de
                monitorage de l’activité, de pilotage des organisations, mais
                aussi à visée épidémiologique. Les dimensions d'exhaustivité, de
                conformité et d’exploitabilité sont restituées pour chaque
                établissement, par département puis à l’échelle régionale selon
                des graphes dont la temporalité est paramétrable.
              </p>
            </div>
            <div class="col-sm-6">
              <h4 class="compatibilite" *ngIf="768 < innerWidth">
                <img src="assets/images/desktop.svg" style="height: 20px" />
                Compatibilité PC/Mobile
              </h4>
              <p>
                Enfin, pour chaque service, un diagramme de flux permet de
                vérifier les règles de cohérence entre les différents champs
                attendus selon les recommandations proposées par la Fédération
                des Observatoires Régionaux des Urgences (FEDORU). Le module
                s’adresse tout particulièrement aux tutelles, directions
                hospitalières, responsables d’unités de soins ainsi qu’aux
                directions des systèmes d’information, permettant de garantir
                que les RPU reflètent la réalité de l’activité, ou à défaut, de
                permettre d’identifier des restrictions/limitations dans l’usage
                et l’interprétation qui en seront fait. Le module « Qualité
                Soins Non Programmés » a été élaboré par le Sesan en lien avec
                l’Observatoire des Soins Non Programmés (ORNSP) d’Ile de France,
                et partagé avec le GCS de la région Auvergne-Rhone-Alpes.
              </p>
            </div>
          </div>

          <div class="row justify-content-center know-more">
            <div class="col-sm-4">
              <a
                role="button"
                class="btn btn-primary btn-block"
                routerLink="/snp-qualite"
                style="margin-left: auto; margin-right: auto"
              >
                Accéder
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--PAGE ARA-->
  <div *ngIf="isAraPage() == true">
    <div class="div-alert">
      <alert class="alert"></alert>
    </div>
    <div class="container" style="padding-top: 3rem">
      <div id="flex">
        <div class="row">
          <div class="col-12" style="margin-top: -5%">
            <H1> Bienvenue sur eCerveau</H1>
          </div>
        </div>
        <div class="row">
          <div class="col-12" style="padding: 0px">
            <img
              src="assets/images/bandeau_accueil_cut.png"
              class="doctor w-100"
              alt="Médecins"
              style="margin-bottom: 1%"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <!-- 1ERE CARD -->
        <div class="col-md-6 col-sm-12" style="padding: 0px">
          <div class="col-12" style="padding: 0px; padding-right: 1%">
            <div class="card">
              <div class="card-body" style="padding-bottom: 0px">
                <h2>Qu'est-ce que eCerveau ?</h2>
                <p>
                  eCerveau concentre et agrège les données issues des Résumés de
                  Passages aux Urgences.*<br />
                  Il restitue les informations auprès des acteurs habilités via
                  des rapports, tableaux de bords et modules applicatifs dédiés.
                </p>
                <p class="note">
                  (*) La transmission des RPU est rendue obligatoire par
                  <a
                    href="https://fra01.safelinks.protection.outlook.com/?url=https%3A%2F%2Furldefense.com%2Fv3%2F__https%3A%2F%2Ffra01.safelinks.protection.outlook.com%2F%3Furl%3Dhttps*3A*2F*2Furldefense.com*2Fv3*2F__https*3A*2F*2Fwww.legifrance.gouv.fr*2Floda*2Fid*2FJORFTEXT000027825549*2F__*3B!!FgWLK78r!Y9JOXhk7i9eXp6KKwcrLDbOFvl3cWRaYLBlVzAx42EWKJUpNUzc9TkJWYMOHatPZu3T-NKEnGp91yMdMbfamLUQcZL7_od_U*24%26data%3D05*7C01*7CJulien.Delamotte*40keyrus.com*7C8029ea21bf0a46bc230f08da552dc62e*7C168e48b281f04aacbc77d58d07d205e2*7C0*7C0*7C637915952349346922*7CUnknown*7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0*3D*7C3000*7C*7C*7C%26sdata%3DGNEDAZcun4tH7pHFqkzaRu*2BuGgfEtLbdS0dgrZGFLBk*3D%26reserved%3D0__%3BJSUlJSUlJSUlJSUlJSUlJSUlJSUlJSUlJSUlJSUlJQ!!FgWLK78r!YmxFlgfBXsR68gWS4ynU9hugMqO88QhViBTk3zRpjpsMHQGW6bDjzH74HSdFdw15oecDY1-WWPj8ocPwVQBnbObsqdsU9Jdg%24&data=05%7C01%7CMaelys.Lefebvre%40keyrus.com%7C90d8a95cd445474b6a9208da55db6268%7C168e48b281f04aacbc77d58d07d205e2%7C0%7C0%7C637916697983344446%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=fHeli9ZKszuT828aVx4Egwnx3gNjS2MUKTmEUvV1Fpk%3D&reserved=0"
                    target="_blank"
                    >l'arrêté du 24 juillets 2013</a
                  >
                  et
                  <a
                    href="https://www.legifrance.gouv.fr/circulaire/id/37381"
                    target="_blank"
                    >l'instruction du 31 juillet 2013</a
                  >.
                </p>
                <br />
              </div>
            </div>
          </div>
          <div class="col-12" style="padding: 0px; padding-right: 1%">
            <div class="card">
              <div class="card-body">
                <h2 style="margin-bottom: -5px">Quelques chiffres</h2>
                <div class="row date">
                  <div class="col-12">
                    <p class="date" style="margin-bottom: -8px">En 2023</p>
                  </div>
                </div>
                <div class="row chiffres">
                  <div class="col-md-4 col-sm-12 chiffres">
                    <p class="number">79</p>
                    <p>services d'urgences</p>
                  </div>
                  <div class="col-md-4 col-sm-12 chiffres">
                    <p class="number">2,4</p>
                    <p>millions de passages</p>
                  </div>
                  <div class="col-md-4 col-sm-12 chiffres">
                    <p class="number">
                      <img
                        src="assets/images/homepage_ecerveauv2_baisse.png"
                        style="width: 15px; margin-right: 3%"
                      />-4%
                    </p>
                    <p>par rapport à 2022</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12" style="padding: 0px; padding-right: 1%">
            <div class="card">
              <div class="card-body">
                <h2>Une question ?</h2>
                <div *ngIf="767 > innerWidth" class="center-row">
                  <p>Vous pouvez contacter notre équipe à l'adresse :</p>
                  <div class="center-row">
                    <div class="row">
                      <div class="col-12">
                        <img class="arobase-icon" />
                        <a class="a-card1" href="mailto: cerveau@sante-ara.fr"
                          >cerveau@sante-ara.fr</a
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  *ngIf="innerWidth > 767"
                  class="row"
                  style="padding-left: 3%"
                >
                  <p>Vous pouvez contacter notre équipe à l'adresse :</p>
                  <div class="col-4" style="padding-left: 1%">
                    <img class="arobase-icon" />
                    <a class="a-card1" href="mailto: cerveau@sante-ara.fr"
                      >cerveau@sante-ara.fr</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-sm-12" style="padding: 0px">
          <div class="col-12" style="padding: 0px">
            <div class="card">
              <div class="card-body">
                <h2>Accès rapide</h2>
                <div
                  class="row"
                  style="text-align: center; margin-top: 3%; margin-bottom: 3%"
                >
                  <div class="col-lg-6 col-md-12" style="padding: 0px">
                    <a routerLink="/het">
                      <img
                        src="assets/images/homepage_ecerveauv2_lien1.png"
                        style="margin-bottom: 2%; cursor: pointer"
                      />
                    </a>
                  </div>
                  <div class="col-lg-6 col-md-12" style="padding: 0px">
                    <a
                      *ngIf="isPreprod() == true"
                      href="https://ppr-snp-qualite-ara.sesan.fr"
                      target="”_blank”"
                    >
                      <img
                        src="assets/images/qualiteRPU.png"
                        style="margin-bottom: 2%; cursor: pointer"
                      />
                    </a>
                    <a
                      *ngIf="isPreprod() == false"
                      href="https://snp-qualite-ara.sante-idf.fr"
                      target="”_blank”"
                    >
                      <img
                        src="assets/images/qualiteRPU.png"
                        style="margin-bottom: 2%; cursor: pointer"
                      />
                    </a>
                  </div>
                </div>
                <div class="row" style="text-align: center; margin-bottom: 1%">
                  <div class="col-lg-6 col-md-12" style="padding: 0px">
                    <a routerLink="/evolution-donnees-urgences"
                      ><img
                        src="assets/images/homepage_ecerveauv2_lien3.png"
                        style="margin-bottom: 2%; cursor: pointer"
                      />
                    </a>
                  </div>
                  <div class="col-lg-6 col-md-12" style="padding: 0px">
                    <img
                      routerLink="/diagramme-de-charge"
                      src="assets/images/homepage_ecerveauv2_lien4.png"
                      style="margin-bottom: 2%; cursor: pointer"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12" style="padding: 0px">
          <div class="col-12" style="padding: 0px">
            <div class="card">
              <div class="card-body">
                <h3>En collaboration avec :</h3>
                <div class="row collab">
                  <a href="https://www.sante-ara.fr/" target="_blank">
                    <img
                      src="assets/images/logo_gcs-sara.png"
                      style="height: 40px"
                    />
                  </a>
                  <a href="http://www.urgences-ara.fr/" target="_blank">
                    <img
                      src="assets/images/logo_urg-ara.png"
                      style="height: 40px"
                    />
                  </a>
                  <a
                    href="https://www.auvergne-rhone-alpes.ars.sante.fr/"
                    target="_blank"
                  >
                    <img
                      src="assets/images/LogoARS-ARA.png"
                      style="height: 40px"
                    />
                  </a>
                  <a href="https://www.sesan.fr/" target="_blank">
                    <img
                      src="assets/images/logo-sesan.png"
                      style="height: 40px"
                    />
                  </a>
                  <a href="https://orsnp-idf.fr/" target="_blank">
                    <img
                      src="assets/images/LOGO-ORSNP-couleur-sur-blanc.png"
                      style="height: 40px"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div
          class="col-sm-12 my-auto"
          style="margin-bottom: 2% !important; text-align: center;"
        >
          <a
            routerLink="/mentions-legales"
            style="text-decoration: underline"
            class="a-card1"
            >Mentions Légales</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
