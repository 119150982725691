import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EffectorEntry } from "../../model/activity/effector-entry.model";
import { HttpClient } from "@angular/common/http";

@Injectable({
    providedIn: "root",
  })

export class EffectorEntryService {
    constructor(private httpClient: HttpClient) {}

    public getEffectorEntry(id: number, yearMonth: string): Observable<EffectorEntry> {
        return this.httpClient.get<EffectorEntry>(`api/effectors/${id}/activities/${yearMonth}`)
    }
}