import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { HasUniqueCodeMetRequest } from '../model/has-unique-code-met.model';
import { EffectorsFormService } from '../service/effectors-form.service';

export class EffectorFormValidators {
  static validateDateRange(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.get('validityStartDate')?.disabled) {
        return null;
      }
      const startDate = new Date(control.value.validityStartDate ?? Date.now());
      const endDate = new Date(control.value.validityEndDate);
      if (startDate > endDate) {
        return { invalidValidityStartEndDate: {} };
      } else {
        return null;
      }
    };
  }

  static validateCodeMetier(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (
        control.get('flowTransmissionBoolean')?.value === true &&
        control.get('effectorCodeMet')?.value === ''
      ) {
        return { requiredCodeMetier: {} };
      } else {
        return null;
      }
    };
  }

  static codeMetValidator(
    effectorService: EffectorsFormService,
    effectorId?: number
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (control.value == null || control.value === '') {
        return of(null);
      }
      return effectorService
        .hasUniqueCodeMet(
          new HasUniqueCodeMetRequest(control.value, effectorId)
        )
        .pipe(
          map((result: boolean) =>
            !result ? { codeMetAlreadyExist: true } : null
          )
        );
    };
  }
}
