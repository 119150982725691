<div class="export_content">
  <div class="mx-5 my-5">
    <div style="display: flex; justify-content: end">
      <div>{{ dateHourExport }}</div>
    </div>
    <div class="row mb-4 mt-3">
      <div class="col-12">
        <h2 class="mb-0">
          {{ effectorShortLabel }}
          -
          {{ effectorDate ? capitalizeFirstLetter(effectorDate) : '' }}
        </h2>
      </div>
    </div>
  </div>
  <div class="card mx-auto my-auto">
    <h1 style="font-size: larger">Consultations par horaire</h1>
    <table class="table table-striped shadow-sm p-3 bg-white my-3">
      <thead>
        <tr class="noselect header-row">
          <th
            style="max-width: 15vw; width: 15vw; padding: 0px; margin: 0px"
          ></th>
          <th
            *ngFor="let timeSlotLabel of timeSlotLabels; let i = index"
            style="max-width: 4vw; margin: 0px"
          >
            <p style="color: #ffffff">{{ timeSlotLabel }}</p>
            <p style="color: #ffffff">{{ timeSlotDayTypes[i] }}</p>
          </th>
        </tr>
      </thead>

      <tbody>
        <tr class="noselect hover-effect">
          <td>
            <div class="d-flex align-items-center">
              <div class="line-name fontSize">Nombre de consultations</div>
            </div>
          </td>
          <td
            *ngFor="
              let countControl of postedConsultationRangeValues;
              let i = index
            "
          >
            <div class="d-flex justify-content-center align-items-center">
              <div class="rounded border inputSize">
                {{ countControl }}
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="export_content">
  <div class="card mx-auto mb-auto mt-3">
    <h1 style="font-size: larger">Consultations CRRA 15</h1>
    <table class="table table-striped shadow-sm p-3 bg-white my-3">
      <thead>
        <tr class="noselect header-row">
          <th
            style="max-width: 26vw; width: 26vw; padding: 0px; margin: 0px"
          ></th>
          <th style="max-width: 4vw; padding: 0px; margin: 0px">
            <p class="m-1" style="font-size: 14px; color: #ffffff">
              Total des consultations
            </p>
          </th>
          <th style="max-width: 4vw; padding: 0px; margin: 0px">
            <p class="m-1" style="font-size: 14px; color: #ffffff">
              Dont effectuées à la demande directe du CRRA15
            </p>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="noselect hover-effect">
          <td>
            <div class="d-flex align-items-center">
              <div class="line-name" style="font-size: 14px">
                Nombre de consultations effectuées aux heures de la PDSA
              </div>
            </div>
          </td>
          <td>
            <div class="d-flex justify-content-center align-items-center">
              <div class="rounded border inputSize">
                {{ postedConsultationEntryValues.count }}
              </div>
            </div>
          </td>
          <td>
            <div class="d-flex justify-content-center align-items-center">
              <div class="rounded border inputSize">
                {{ postedConsultationEntryValues.crraCount }}
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div *ngIf="hasCommentEntered" class="export_content">
  <div class="card mx-auto mb-auto mt-3">
    <h1 style="font-size: larger">Commentaire</h1>
    <textarea
      class="commentInput"
      maxlength="450"
      placeholder="Modifier votre commentaire..."
      >{{ commentText }}</textarea
    >
  </div>
</div>
