import { Component, NgModule, OnInit } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FirstGraphComponent } from "./first-graph/first-graph.component";
import { SecondGraphComponent } from "./second-graph/second-graph.component";

@Component({
  selector: "app-graph-test",
  templateUrl: "./graph-test.component.html",
  styleUrls: ["./graph-test.component.scss"],
})
// @NgModule({
//   imports: [NgModule],
//   declarations: [FirstGraphComponent, SecondGraphComponent, NgbModule],
// })
export class GraphTestComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
