<form
  [formGroup]="mobileVolumeEntryForm"
  (ngSubmit)="onSubmit(eventTypes.CLOSE)"
>
  <table class="table table-striped shadow-sm p-3 bg-white">
    <thead>
      <tr class="noselect header-row">
        <th
          style="max-width: 26vw; width: 26vw; padding: 0px; margin: 0px"
        ></th>
        <th style="max-width: 4vw; padding: 0px; margin: 0px">
          <p class="m-1 fontSize" style="color: #ffffff">
            Activité aux heures de la PDSA
          </p>
        </th>
        <th style="max-width: 4vw; padding: 0px; margin: 0px">
          <p class="m-1 fontSize" style="color: #ffffff">
            Activité en dehors des heures de la PDSA
          </p>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr class="cursor noselect hover-effect">
        <td>
          <div class="d-flex align-items-center">
            <div class="line-name fontSize">
              Nombre total d'appels décrochés
            </div>
          </div>
        </td>
        <td>
          <div class="d-flex justify-content-center align-items-center">
            <input
              type="text"
              class="rounded border inputSize"
              [ngClass]="{
                error: mobileVolumeEntryForm
                  .get('openedAnsweredCallCount')
                  ?.getError('invalidValue'),
                warning: mobileVolumeEntryForm
                  .get('openedAnsweredCallCount')
                  ?.getError('emptyValue')
              }"
              formControlName="openedAnsweredCallCount"
              (input)="this.onInputChange()"
            />
          </div>
        </td>
        <td>
          <div class="d-flex justify-content-center align-items-center">
            <input
              type="text"
              class="rounded border inputSize"
              [ngClass]="{
                error: mobileVolumeEntryForm
                  .get('closedAnsweredCallCount')
                  ?.getError('invalidValue'),
                warning: mobileVolumeEntryForm
                  .get('closedAnsweredCallCount')
                  ?.getError('emptyValue')
              }"
              formControlName="closedAnsweredCallCount"
              (input)="this.onInputChange()"
            />
          </div>
        </td>
      </tr>
      <tr class="cursor noselect hover-effect">
        <td>
          <div class="d-flex align-items-center">
            <div class="line-name fontSize">Nombre total de visites</div>
          </div>
        </td>
        <td>
          <div class="d-flex justify-content-center align-items-center">
            <input
              type="text"
              class="rounded border inputSize"
              [ngClass]="{
                error: mobileVolumeEntryForm
                  .get('openedVisitCount')
                  ?.getError('invalidValue'),
                warning: mobileVolumeEntryForm
                  .get('openedVisitCount')
                  ?.getError('emptyValue')
              }"
              formControlName="openedVisitCount"
              (input)="this.onInputChange()"
            />
          </div>
        </td>
        <td>
          <div class="d-flex justify-content-center align-items-center">
            <input
              type="text"
              class="rounded border inputSize"
              [ngClass]="{
                error: mobileVolumeEntryForm
                  .get('closedVisitCount')
                  ?.getError('invalidValue'),
                warning: mobileVolumeEntryForm
                  .get('closedVisitCount')
                  ?.getError('emptyValue')
              }"
              formControlName="closedVisitCount"
              (input)="this.onInputChange()"
            />
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</form>
