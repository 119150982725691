<body>
  <div class="row">
    <div class="col-1"></div>
    <div class="col-11">
      <H1>Données des urgences</H1>
    </div>
  </div>
  <div class="row highcharts">
    <div class="col-1"></div>
    <div class="col-5 line">
      <a routerLink="/graph">
        <div class="card shadow">
          <div class="card-body">
            <figure class="highcharts-figure">
              <div id="container"></div>
            </figure>
          </div>
        </div>
      </a>
    </div>
    <div class="col-5">
      <a routerLink="/ntg">
        <div class="card shadow">
          <div class="card-body">
            <figure class="highcharts-figure column">
              <div id="containerNtg"></div>
            </figure>
          </div>
        </div>
      </a>
    </div>
    <div class="col-1"></div>
  </div>

  <div class="row circle">
    <div class="col-3">
      <div class="card shadow first-it">
        <div class="card-body">
          <h2>82</h2>
          <br />
          <p>services d'urgences</p>
        </div>
      </div>
    </div>
    <div class="col-3">
      <div class="card shadow second-it">
        <div class="card-body">
          <h2>90%</h2>
          <br />
          <p>d'occupation des lits</p>
        </div>
      </div>
    </div>
    <div class="col-3">
      <div class="card shadow third-it">
        <div class="card-body">
          <h2>2H30</h2>
          <br />
          <p>d'attente aux urgences</p>
        </div>
      </div>
    </div>
    <div class="col-3">
      <div class="card shadow first-it">
        <div class="card-body">
          <h2>54 ans</h2>
          <br />
          <p>d'âge moyen des patients</p>
        </div>
      </div>
    </div>
  </div>
</body>

<!-- <body>
  <div class="row">
    <div class="col-1"></div>
    <div class="col-5 line">
      <a routerLink="/graph">
        <figure class="highcharts-figure">
          <div id="container"></div>
        </figure>
      </a>
    </div>
    <div class="col-5">
      <a routerLink="/ntg">
        <figure class="highcharts-figure column">
          <div id="containerNtg"></div>
        </figure>
      </a>
    </div>
    <div class="col-1"></div>
  </div>
</body> -->
