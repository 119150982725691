import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'checkCircle'
  })
  export class checkCirclePipe implements PipeTransform {
    transform(value: number): string {
      if (value) {
        return "check_circle"
      }else{
          return "panorama_fish_eye"
      }
    }
  }