import { StateInputPdsa, StateInputPdsaClass } from '../state-input-pdsa.model';
import {
  MobileVolumeEntry,
  MobileVolumeEntryClass
} from './mobile-volume-entry.model';

export interface EffectorActivity {
  effectorLabel: string;
  stateInputPDSA?: StateInputPdsa;
  mobileVolumeEntries: MobileVolumeEntry[];
}

export class EffectorActivityClass {
  effectorLabel: string;
  stateInputPDSA?: StateInputPdsaClass;
  mobileVolumeEntries: MobileVolumeEntryClass[];

  constructor(effector: EffectorActivity) {
    this.effectorLabel = effector.effectorLabel;
    this.stateInputPDSA =
      effector.stateInputPDSA == null
        ? undefined
        : StateInputPdsaClass.fromStateInputPdsa(effector.stateInputPDSA);

    this.mobileVolumeEntries =
      effector.mobileVolumeEntries == null
        ? []
        : effector.mobileVolumeEntries.map((e) =>
            MobileVolumeEntryClass.fromMobileVolumeEntry(e)
          );
  }
}
