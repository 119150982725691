import { Injectable } from "@angular/core";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import {
  Period1,
  Period1Option,
  Period1OptionKind,
  Period2,
  Period2Option,
  Period2OptionKind,
} from "../models/graph-filter.model";

@Injectable({
  providedIn: "root",
})
export class PeriodService {
  getPeriod1Options(): Period1Option[] {
    return [
      {
        optionKind: Period1OptionKind.ONE_MONTH,
        label: "30 derniers jours (1 mois)",
      },
      {
        optionKind: Period1OptionKind.ONE_SEMESTER,
        label: "6 derniers mois",
      },
      {
        optionKind: Period1OptionKind.ONE_YEAR,
        label: "Depuis 1 an",
      },
      {
        optionKind: Period1OptionKind.START_YEAR,
        label: "Depuis le début de l’année",
      },
      {
        optionKind: Period1OptionKind.CUSTOM,
        label: "Personnalisée",
      },
    ];
  }

  getPeriod2Options(): Period2Option[] {
    return [
      {
        optionKind: Period2OptionKind.PREVIOUS_PERIOD,
        label: "Période précédente",
      },
      {
        optionKind: Period2OptionKind.YEAR_NM1,
        label: "Année précédente",
      },
      {
        optionKind: Period2OptionKind.YEAR_NM2,
        label: "Année N-2",
      },
      {
        optionKind: Period2OptionKind.CUSTOM,
        label: "Personnalisée",
      },
    ];
  }

  computePeriod1(
    optionKind = Period1OptionKind.ONE_SEMESTER,
    customStartDate?: Date,
    customEndDate?: Date
  ): Period1 {
    return {
      optionKind,
      startDate: this.computePeriod1StartDate(optionKind, customStartDate),
      endDate:
        optionKind === Period1OptionKind.CUSTOM && customEndDate !== undefined
          ? customEndDate
          : new Date(),
    };
  }

  private computePeriod1StartDate(
    optionKind: Period1OptionKind,
    customStartDate?: Date
  ): Date {
    switch (optionKind) {
      case Period1OptionKind.ONE_MONTH:
        return this.remove30Days();
      case Period1OptionKind.ONE_SEMESTER:
        return this.remove6Months();
      case Period1OptionKind.ONE_YEAR:
        return this.remove1Year();
      case Period1OptionKind.START_YEAR:
        return this.removeUntilStartOfYear();
      case Period1OptionKind.CUSTOM:
        return customStartDate !== undefined
          ? customStartDate
          : this.remove6Months();
    }
  }

  computePeriod2(
    period1: Period1,
    optionKind = Period2OptionKind.YEAR_NM1,
    customStartDate?: Date
  ): Period2 {
    const p1Sart = new Date(period1.startDate);
    const startDate = this.computePeriod2StartDate(
      period1,
      optionKind,
      customStartDate
    );

    let endDate;
    //  let p1EndDate = new Date(period1.endDate);
    if (optionKind == Period2OptionKind.YEAR_NM1) {
      endDate = this.remove1YearsFromPeriode1(period1.endDate);
    }
    if (optionKind == Period2OptionKind.YEAR_NM2) {
      endDate = this.remove2YearsFromPeriode1(period1.endDate);
    }
    if (optionKind == Period2OptionKind.PREVIOUS_PERIOD) {
      endDate = period1.startDate;
    }

    if (optionKind == Period2OptionKind.CUSTOM) {
      endDate = period1.startDate;

    }

    return {
      optionKind,
      startDate,
      endDate: endDate,
    };
  }

  private computePeriod2StartDate(
    period1: Period1,
    optionKind: Period2OptionKind,
    customStartDate?: Date
  ): Date {
    switch (optionKind) {
      case Period2OptionKind.PREVIOUS_PERIOD:
        return this.diffBetween2Date(period1.startDate);
      case Period2OptionKind.YEAR_NM1:
        return this.remove1YearsFromPeriode1(period1.startDate);
      case Period2OptionKind.YEAR_NM2:
        return this.remove2YearsFromPeriode1(period1.startDate);
      case Period2OptionKind.CUSTOM:
        return customStartDate !== undefined
          ? customStartDate
          : this.remove6Months();
    }
  }

  dateToNgbDateStruct(date: Date): {
    day: number;
    month: number;
    year: number;
  } {
    return {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    };
  }

  ngbDateToDate(date: NgbDate): Date {
    return new Date(date.year, date.month - 1, date.day);
  }

  private today(): Date {
    let date = new Date();
    return date;
  }

  private remove30Days(): Date {
    let date = new Date();
    date.setDate(date.getDate() - 30);
    return date;
  }

  private remove1Day(date: Date): Date {
    let date2 = new Date(date);
    date2.setDate(date.getDate() - 1);
    return date2;
  }

  private remove6Months(): Date {
    let date = new Date();
    date.setMonth(date.getMonth() - 6);
    return date;
  }

  private removeUntilStartOfYear(): Date {
    let date = new Date();
    date.setDate(1);
    date.setMonth(0);
    return date;
  }

  private remove1Year(): Date {
    let date = new Date();
    date.setFullYear(date.getFullYear() - 1);
    return date;
  }

  private remove2YearsFromPeriode1(date2: Date) {
    let date3 = new Date(date2);
    date3.setFullYear(date3.getFullYear() - 2);
    return new Date(date3);
  }

  private remove1YearsFromPeriode1(date2: Date) {
    let date3 = new Date(date2);

    date3.setFullYear(date3.getFullYear() - 1);
    return date3;
  }

  private diffBetween2Date(date2: Date) {
    let date1: Date = new Date();
    let date3 = new Date(date2).getTime();
    const dateGapP1 = date1.getTime() - date2.getTime();
    let date4 = new Date(date3 - dateGapP1);
    return new Date(date4);
  }
}
