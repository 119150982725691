import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CerveauEmergencyServiceFormComponent } from './cerveau-emergency-service-form/cerveau-emergency-service-form.component';
import { emergencyDeptRoutes } from './emergency-dept.route';
import { NgbdNavMarkup } from './nav-markup/nav-markup.component';
import { HeaderTableComponent } from './cerveau-emergency-service-form/header-table/header-table.component';
import { SharedModule } from '../shared/shared.module';
@NgModule({
  imports: [
    SharedModule,
    ReactiveFormsModule,
    RouterModule.forRoot(emergencyDeptRoutes, {
      relativeLinkResolution: 'legacy'
    }),
    BrowserModule,
    NgbModule,
    FormsModule
  ],
  declarations: [
    CerveauEmergencyServiceFormComponent,
    NgbdNavMarkup,
    HeaderTableComponent
  ]
})
export class EmergencyServiceModule {}
