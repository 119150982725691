<div class="container-fluid">
  <div class="input-group d-flex justify-content-between label-search">
    <div
      class="loop input-group-text d-flex justify-content-center"
      id="basic-addon1"
    >
      <i class="material-icons">&#xE8B6;</i>
    </div>
    <div class="input-search">
      <ng-select
        notFoundText="Aucun SAU"
        class="inputSearch"
        placeholder="Recherche de SAU"
        (change)="addSAU($event)"
        [addTag]="false"
        (clear)="(false)"
        [clearSearchOnAdd]="true"
        [closeOnSelect]="true"
        [hideSelected]="true"
        (click)="removeSelectedSau()"
      >
        <ng-option
          class="inputSelect"
          *ngFor="let emergencyDept of emergencyDepts"
          [value]="emergencyDept"
          >{{ emergencyDept.finess | slice : 0 : 2 }} -
          {{ emergencyDept.abrSerUrg }}</ng-option
        >
      </ng-select>

      <button
        class="filterButton col-1 btn btn-primary d-flex justify-content-center"
        type="submit"
        (click)="filterModalOpen()"
      >
        <span class="d-none d-lg-block">Filtrer</span>
        <i class="material-icons">filter_list</i>
      </button>
    </div>
  </div>

  <!-- TAG Atention couleur primary set à #67af72 dans css de ce composant pour la region ara-->
  <div
    class="d-flex justify-content-between box-tagSupFilter container-fluid"
    *ngIf="
      graphFilter.emergencyDepts.length > 0 ||
      graphFilter.departementCodes.length > 0 ||
      graphFilter.emergencyDepts.length > 0 ||
      graphFilter.sizeGroups.length > 0
    "
  >
    <div class="col-1 loop d-none d-md-block" *ngIf="524 < innerWidth"></div>
    <div
      [ngClass]="524 < innerWidth ? 'input-search ' : 'input-search col-10'"
      [ngStyle]="524 < innerWidth ? 'padding-left:0px;' : ''"
    >
      <div *ngIf="graphFilter" class="d-flex">
        <div class="box-tags d-sm-flex-column">
          <div
            class="badge badge-primary"
            *ngFor="let sizeGroup of graphFilter.sizeGroups"
          >
            <span> {{ sizeGroup.lib }}</span>
            <em
              role="button"
              class="bi bi-x-square"
              (click)="removeSizeGroup(sizeGroup)"
            ></em>
          </div>
          <div
            class="badge badge-primary"
            *ngFor="let departementCode of graphFilter.departementCodes"
          >
            <span> {{ departementCode | departmentName }}</span>
            <em
              role="button"
              class="bi bi-x-square"
              (click)="removeDepartementCode(departementCode)"
            ></em>
          </div>
          <div
            class="badge badge-primary"
            *ngFor="let emergencyDept of graphFilter.emergencyDepts"
          >
            <span> {{ emergencyDept.abrSerUrg }}</span>
            <em
              role="button"
              class="bi bi-x-square"
              (click)="removeEmergencyDept(emergencyDept)"
            ></em>
          </div>
        </div>
      </div>
    </div>
    <div class="col-1 supFilterButton">
      <a
        *ngIf="
          this.graphFilter.emergencyDepts.length === 0 ||
          this.graphFilter.sizeGroups.length === 0 ||
          this.graphFilter.departementCodes.length === 0
        "
        role="button"
        class="supprimerFiltre"
        (click)="clearFilters()"
        ><img
          src="/assets/images/delete.png"
          style="width: 25px; margin-top: 5px"
      /></a>
    </div>
  </div>
</div>
