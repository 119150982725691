import { fr } from 'date-fns/locale';
import { formatInTimeZone } from 'date-fns-tz';

export class DateHelper {
  static toUTCDate(value: string | null): string | undefined {
    if (value == null) {
      return undefined;
    }
    const dateTest = new Date(value);
    const datTest2 = new Date(value).toISOString();
    const dateTest3 = new Date(
      formatInTimeZone(value, 'Europe/Paris', 'yyyy-MM-dd', {
        locale: fr
      })
    ).setHours(0, 0, 0, 0);

    // Conversion du nombre en une date
    const dateConverted = new Date(dateTest3);

    // Ou si vous souhaitez obtenir une chaîne de caractères formatée
    const dateConvertedString = dateConverted.toISOString();

    return new Date(value).toISOString();
  }
  static toLocalDate(date?: Date): string | undefined {
    if (date != null) {
      // date.setHours(0, 0, 0, 0);
      return formatInTimeZone(date, 'Europe/Paris', 'yyyy-MM-dd', {
        locale: fr
      });
    } else {
      return undefined;
    }
  }
}
