import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EmergencySpeciality } from "../model/emergency-speciality.model";

export interface EmergecencySpeciality {
  specialiteSau: string;
}

@Injectable({
  providedIn: "root",
})
export class EmergecencySpecialityService {
  value: any;
  get: any;

  constructor(private httpClient: HttpClient) {}

  public getEmergencySpeciality(): Observable<EmergencySpeciality[]> {
    return this.httpClient.get<EmergencySpeciality[]>(
      `/api/emergency-speciality`
    );
  }
}
