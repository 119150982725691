import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ClassificationHet } from "../model/classification-het.model";

@Injectable({
  providedIn: "root",
})
export class ClassificationHetService {
  constructor(private httpClient: HttpClient) {}

  public getClassificationHet(): Observable<ClassificationHet[]> {
    return this.httpClient.get<ClassificationHet[]>(`/api/classification-het`);
  }

  //à ajouter pour récup code EG et code Serv urg
  public getClassificationHetBySU(
    codEg: string,
    codSerUrg: number
  ): Observable<ClassificationHet[]> {
    return this.httpClient.get<ClassificationHet[]>(
      `/api/classification-het/${codEg}-${codSerUrg}`
    );
  }

  public setClassificationHet(
    classificationHet: ClassificationHet
  ): Observable<ClassificationHet> {
    return this.httpClient.put<ClassificationHet>(
      `/api/classification-het`,
      classificationHet
    );
  }
}
