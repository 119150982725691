<div class="container">
  <div class="div-alert">
    <alert class="alert"
    style= "padding-right: 0px !important;
    padding-left: 0px !important;
    padding-bottom: 0px !important;
    padding-top: 0px !important;"
    ></alert>
  </div>
  <iframe width="100%" height="100%" frameBorder="0" [src]="urlSafe" ></iframe>
</div>

