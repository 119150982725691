import { Component, forwardRef, OnInit, ViewChild } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl, NG_VALUE_ACCESSOR
} from '@angular/forms';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { ActivityFormValidator } from '../../../validators/activity-form-validators';

@Component({
  selector: 'app-pdsa-activity-input',
  templateUrl: './pdsa-activity-input.component.html',
  styleUrls: ['./pdsa-activity-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PdsaActivityInputComponent),
      multi: true
    }
  ]
})
export class PdsaActivityInputComponent
  implements OnInit, ControlValueAccessor
{
  control = new FormControl(
    null,
    ActivityFormValidator.activityFormInputValidator()
  );
  @ViewChild('invalidTooltip') invalidTooltip: NgbTooltip;

  ngOnInit(): void {
    this.controlForm.valueChanges.subscribe(() => {
      if (!this.isInvalid) this.invalidTooltip.close();
      if (this.isInvalid) this.invalidTooltip.open();
    });
  }

  public onTouched: () => void = () => {};

  writeValue(val: never): void {
    val && this.control.setValue(val, { emitEvent: false });
  }
  registerOnChange(fn: never): void {
    this.control.valueChanges.subscribe(fn);
  }
  registerOnTouched(fn: never): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.control.disable() : this.control.enable();
  }

  get controlForm(): FormControl {
    return this.control as FormControl;
  }

  get isInWarning(): boolean {
    return (
      this.controlForm.value === '' &&
      (this.controlForm.touched || this.controlForm.dirty)
    );
  }

  get isInvalid(): boolean {
    return (
      this.controlForm.invalid &&
      (this.controlForm.touched || this.controlForm.dirty)
    );
  }

  get isValid(): boolean {
    return (
      this.controlForm.valid &&
      this.controlForm.value !== '' &&
      (this.controlForm.touched || this.controlForm.dirty)
    );
  }
}
