
    <p [ngClass]="sectionTitle">{{titleHeader}}</p>
    <div class="row d-flex justify-content-center">
      <span
      [ngClass]="filterUp"
      role="button"
      (click)="actionClickDesc()">
        arrow_drop_up
      </span>
      <span [ngClass]="filterDown"
       role="button"
       (click)="actionClickAsc()">
        arrow_drop_down </span>
    </div>
  
