import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ParameterHet } from "../model/parameter-het.model";

@Injectable({
  providedIn: "root",
})
export class ParameterHetService {
  constructor(private httpClient: HttpClient) {}

  public getParameterHet(): Observable<ParameterHet[]> {
    return this.httpClient.get<ParameterHet[]>(`/api/parameter-het`);
  }

  //à ajouter pour récup code EG et code Serv urg
  public getParameterHetBySu(
    codeEg: string,
    codeSerUrg: number
  ): Observable<{ [it: string]: ParameterHet }> {
    return this.httpClient.get<{ [it: string]: ParameterHet }>(
      `/api/parameter-het/${codeEg}-${codeSerUrg}`
    );
  }

  //à modifie rpour save tout
  public saveParameterHet(
    parameterHet: any
    ): Observable<any> {
    return this.httpClient.post<any>(
      `/api/parameter-het`, 
      parameterHet);
  }
}
