import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { CookieService } from "ngx-cookie-service";
import { NgxWebstorageModule } from "ngx-webstorage";
import { AlertModule } from "../alert/alert/alert.module";
import { AppRoutingModule } from "../app-routing.module";
import { FooterComponent } from "../footer/footer.component";
import { actifOrInactifPipe } from "./pipes/actifOrInactif.pipe";
import { checkCirclePipe } from "./pipes/checkCircle.pipe";
import { TransformSauTypePipe } from "./pipes/TransformSauType.pipe";
import { yesOrNoPipe } from "./pipes/yesOrNo.pipe";
import { DisableDoubleClickDirective } from "../directive/disable-double-click.directive";

@NgModule({
  declarations: [
    TransformSauTypePipe,
    yesOrNoPipe,
    checkCirclePipe,
    actifOrInactifPipe,
    FooterComponent,
    DisableDoubleClickDirective
  ],
  imports: [
    AppRoutingModule,
    CommonModule,
    FontAwesomeModule,
    HttpClientModule,
    NgbModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgxWebstorageModule.forRoot(),
    AlertModule,
  ],
  exports: [
    AppRoutingModule,
    CommonModule,
    FontAwesomeModule,
    HttpClientModule,
    NgbModule,
    ReactiveFormsModule,
    TransformSauTypePipe,
    yesOrNoPipe,
    checkCirclePipe,
    actifOrInactifPipe,
    NgSelectModule,
    AlertModule,
    FooterComponent,
    DisableDoubleClickDirective
  ],
  providers: [CookieService],
})
export class SharedModule {}
