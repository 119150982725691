<html>
  <head>
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
    <link
      href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap"
      rel="stylesheet"
    />
    <link
      href="https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700&family=Open+Sans:wght@400;600&display=swap"
      rel="stylesheet"
    />
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css"
    />
    <script
      src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"
      charset="utf-8"
    ></script>
    <script src="https://code.highcharts.com/highcharts.js"></script>
    <script src="https://code.highcharts.com/modules/exporting.js"></script>
    <script src="https://code.highcharts.com/modules/export-data.js"></script>
    <script src="https://code.highcharts.com/modules/accessibility.js"></script>
  </head>

  <body class="d-flex flex-column min-vh-100">
    <div
      class="changestyle"
      [ngClass]="isAraPage() == true ? 'ara-style' : 'idf-style'"
    >
      <div class="col-sm-12">
        <nav class="navbar navbar-expand navbar-light bg-light" id="navbar">
          <div class="container-fluid">
            <div class="navbar-collapse collapse w-100 order-3">
              <!--DESKTOP-->
              <img
                class="logo-cerveau-nav"
                alt="logo ecerveau"
                *ngIf="isAraPage() == false && 768 < innerWidth"
              />
              <!--MOBILE-->
              <img
                class="logo-cerveau-nav-mobile"
                alt="logo ecerveau"
                *ngIf="isAraPage() == false && 768 > innerWidth"
              />
              <ul class="navbar-nav ml-auto">
                <li class="nav-item" *ngIf="account === null">
                  <!--BOUTON CONNEXION-->
                  <button
                    type="button"
                    class="btn connect btn-sm mr-2"
                    id="btn-co"
                    (click)="login()"
                  >
                    Se connecter
                  </button>
                </li>
                <li class="nav-item" *ngIf="account !== null">
                  <!--BOUTON USER + POP-OVER-->
                  <ng-template #popContent class="popover">
                    <p class="popoverp">
                      Ces informations sont récupérées de votre profil ENRS.
                      Vous pouvez les modifier sur le
                      <a *ngIf="isAraPage() == false && isPreprod() == true" href="https://testpro.sante-idf.fr" class="link-enrs">site de l'ENRS</a>
                      <a *ngIf="isAraPage() == false && isPreprod() == false" href="https://pro.sante-idf.fr" class="link-enrs">site de l'ENRS</a>
                      <a *ngIf="isAraPage() == true && isPreprod() == true" href="https://recette.sante-ra.fr/AutoConnectSSO/PortalHome/" class="link-enrs">site de l'ENRS</a>
                      <a *ngIf="isAraPage() == true && isPreprod() == false" href="https://www.sante-ra.fr/AutoConnectSSO/PortalHome/" class="link-enrs">site de l'ENRS</a>
                      .
                    </p>
                    <hr />
                    <p class="user">
                      <img
                        src="assets/images/user.svg"
                        class="user"
                        width="20"
                      />{{ account?.name }}
                    </p>
                    <hr />
                    <p class="mail">
                      <img
                        src="assets/images/mail.svg"
                        class="mail"
                        width="20"
                      />{{ account?.email }}
                    </p>
                    <hr />
                    <p class="tel">
                      <img
                        src="assets/images/tel.svg"
                        class="tel"
                        width="20"
                      />{{ account?.phoneNumber }}
                    </p>
                    <hr />
                    <p class="work">
                      <img
                        src="assets/images/work.svg"
                        class="work"
                        width="20"
                      />{{ account?.professionLabel }}
                    </p>
                    <hr />
                    <button
                      type="button"
                      id="deco"
                      class="btn btn-sm btn-block"
                      (click)="logout()"
                    >
                      Se déconnecter
                    </button>
                  </ng-template>
                  <button
                    type="button"
                    id="btn-user"
                    class="btn btn-sm mr-2"
                    [ngbPopover]="popContent"
                    [autoClose]="'outside'"
                    *ngIf="innerWidth > 767"
                  >
                    {{ getInitials() }}
                  </button>

                  <ng-template #content let-modal class="modal-dialog">
                    <div class="modal-header">
                      <div class="row">
                        <div class="col-11">
                          <p class="pmodal-text">
                            Ces informations sont récupérées de votre profil
                            ENRS. Vous pouvez les modifier sur le
                            <a
                              href="https://testpro.sante-idf.fr/AutoConnectSSO/Connexion.aspx"
                              class="link-enrs"
                              target="_blank"
                              >site de l'ENRS</a
                            >.
                          </p>
                        </div>
                        <div class="col-1">
                          <button
                            type="button"
                            class="close"
                            aria-label="Close"
                            (click)="modal.dismiss('Cross click')"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="modal-body">
                      <hr />
                      <p class="pmodal-user">
                        <img
                          src="assets/images/user.svg"
                          class="iconmodal"
                          width="20"
                        />{{ account?.name }}
                      </p>
                      <hr />
                      <p class="pmodal">
                        <img
                          src="assets/images/mail.svg"
                          class="iconmodal"
                          width="16,94"
                          height="13,55"
                        />{{ account?.email }}
                      </p>
                      <hr />
                      <p class="pmodal">
                        <img
                          src="assets/images/tel.svg"
                          class="iconmodal"
                          width="16,94"
                        />{{ account?.phoneNumber }}
                      </p>
                      <hr />
                      <p class="pmodal">
                        <img
                          src="assets/images/work.svg"
                          class="iconmodal"
                          width="16,94"
                          height="15,24"
                        />{{ account?.professionLabel }}
                      </p>
                      <hr />
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        id="decomodal"
                        class="btn btn-sm btn-block"
                        (click)="logout()"
                      >
                        Se déconnecter
                      </button>
                    </div>
                  </ng-template>
                  <button
                    type="button"
                    id="btn-user"
                    class="btn btn-sm mr-2"
                    (click)="open(content)"
                    *ngIf="767 > innerWidth"
                  >
                    {{ getInitials() }}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>

      <!-- MENU SUR LE COTE-->
      <!--SIDEBAR IDF-->
      <div>
        <ng-container *ngIf="account !== null">
          <input type="checkbox" id="check" [(ngModel)]="openCloseMenu" />
          <img
            class="menu-burger"
            id="btn"
            alt="bouton burger"
            (click)="this.openCloseMenu = true"
          />

          <div class="sidebar">
            <header>
              <img
                id="btn-close"
                alt="bouton close"
                (click)="this.openCloseMenu = false"
              />
              <!-- LOGO QUI M'INTERESSE -->
              <a routerLink="/accueil"
                ><img class="logo-cerveau" alt="logo ecerveau"
              /></a>
            </header>

            <!--IDF-->
            <div *ngIf="isAraPage() == false" class="menu-scroll">
              <ngb-accordion [closeOthers]="true" class="menu-list">
                <ngb-panel class="accordion-header" id="static-1">
                  <ng-template ngbPanelHeader let-opened="opened"
                    ><a
                      routerLink="/accueil"
                      [ngClass]="{
                        active:
                          selectedTab === '/' || selectedTab === '/accueil'
                      }"
                      >Accueil</a
                    ></ng-template
                  >
                </ngb-panel>

                <ngb-panel
                  id="static-2"
                  *ngIf="
                    account?.authorities.includes('33') ||
                    account?.authorities.includes('23') ||
                    account?.authorities.includes('8')
                  "
                >
                  <ng-template ngbPanelTitle>
                    <div class="d-flex justify-content-between">
                      <div class="d-flex">Samu</div>
                      <i class="ion-ios-arrow-up"></i>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <ul>
                      <li *ngIf="account?.authorities.includes('33')">
                        <a
                          routerLink="/meteo-samu"
                          [ngClass]="{
                            activedown: selectedTab === '/meteo-samu'
                          }"
                          >Météo des Samu</a
                        >
                      </li>
                      <li *ngIf="account?.authorities.includes('23')">
                        <a
                          href="https://santegraphie.fr/mviewer/?config=apps/SMURPediatrique_dev.xml#"
                          target="_blank"
                          >Carto SAMU Pédiatrique</a
                        >
                      </li>
                    </ul>
                  </ng-template>
                </ngb-panel>

                <ngb-panel
                  id="static-3"
                  *ngIf="
                    account?.authorities.includes('28') ||
                    account?.authorities.includes('7') ||
                    account?.authorities.includes('19') ||
                    account?.authorities.includes('31') ||
                    account?.authorities.includes('12') ||
                    account?.authorities.includes('9') ||
                    account?.authorities.includes('10') ||
                    account?.authorities.includes('73') ||
                    account?.authorities.includes('74') ||
                    account?.authorities.includes('75')
                  "
                >
                  <ng-template ngbPanelTitle>
                    <div class="d-flex justify-content-between">
                      <div class="d-flex">Urgences</div>
                      <i class="ion-ios-arrow-up"></i>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <ul>
                      <li *ngIf="account?.authorities.includes('28')">
                        <a
                          routerLink="/meteo-urgences"
                          [ngClass]="{
                            activedown: selectedTab === '/meteo-urgences'
                          }"
                          >Météo des Urgences</a
                        >
                      </li>
                      <li *ngIf="account?.authorities.includes('7')">
                        <a
                          routerLink="/het"
                          [ngClass]="{
                            activedown: selectedTab === '/het'
                          }"
                          >Tension Hospitalière</a
                        >
                      </li>
                      <li *ngIf="account?.authorities.includes('19')">
                        <a
                          routerLink="/mes-urgences"
                          [ngClass]="{
                            activedown: selectedTab === '/mes-urgences'
                          }"
                          >Mes Urgences GP</a
                        >
                      </li>
                      <li *ngIf="account?.authorities.includes('31')">
                        <a
                          routerLink="/mes-urgences-pro"
                          [ngClass]="{
                            activedown: selectedTab === '/mes-urgences-pro'
                          }"
                          >Mes Urgences Pro</a
                        >
                      </li>
                      <li *ngIf="account?.authorities.includes('11')">
                        <a routerLink="/snp-qualite">SNP QUALITE</a>
                      </li>
                      <li *ngIf="account?.authorities.includes('12')">
                        <a href="https://litsbrancards.sesan.fr" target="_blank"
                          >Lits Brancards</a
                        >
                      </li>
                      <li *ngIf="account?.authorities.includes('73')">
                        <a routerLink="/diagramme-de-charge"
                          >Diagramme de charge</a
                        >
                      </li>
                      <li *ngIf="account?.authorities.includes('74')">
                        <a routerLink="/evolution-donnees-urgences"
                          >Evolution indic. d'urgence</a
                        >
                      </li>
                      <li *ngIf="account?.authorities.includes('75')">
                        <a routerLink="/evolution-ntg">Evolution ntg</a>
                      </li>
                    </ul>
                  </ng-template>
                </ngb-panel>

                <!-- A supprimer quand vous voudrez passer au second comportement : avoir PDSA comme titre et plusieurs sous-menu -->
                <ngb-panel
                  class="accordion-header"
                  id="static-8"
                  *ngIf="
                    account?.authorities.includes('70') ||
                    account?.authorities.includes('71')
                  "
                >
                  <ng-template
                    ngbPanelHeader
                    let-opened="opened"
                    *ngIf="
                      account?.authorities.includes('70') ||
                      account?.authorities.includes('71')
                    "
                  >
                    <a
                      routerLink="{{ AppRoutes.PDSA_STRUCTURE_ACTIVITY }}"
                      [ngClass]="{
                        activedown:
                          selectedTab === AppRoutes.PDSA_STRUCTURE_ACTIVITY
                      }"
                      >PDSA</a
                    ></ng-template
                  >
                </ngb-panel>

                <!-- A décommenter quand vous voudrez passer au second comportement : avoir PDSA comme titre et plusieurs sous-menu -->
                <!-- <ngb-panel
                  id="static-8"
                  *ngIf="account?.authorities.includes('69')"
                >
                  <ng-template ngbPanelTitle>
                    <div class="d-flex justify-content-between">
                      <div class="d-flex">PDSA</div>
                      <i class="ion-ios-arrow-up"></i>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <ul>
                      <li *ngIf="account?.authorities.includes('69')">
                        <a
                          routerLink="{{ AppRoutes.PDSA_STRUCTURE_ACTIVITY }}"
                          [ngClass]="{
                            activedown:
                              selectedTab === AppRoutes.PDSA_STRUCTURE_ACTIVITY
                          }"
                          >Saisie d'activité</a
                        >
                      </li>
                    </ul>
                  </ng-template>
                </ngb-panel> -->

                <ngb-panel
                  class="accordion-header"
                  id="static-4"
                  *ngIf="account?.authorities.includes('13')"
                >
                  <ng-template
                    ngbPanelHeader
                    let-opened="opened"
                    *ngIf="
                      account?.authorities.includes('13') && isPreprod() == true
                    "
                    ><a href="https://adhoc.sesan.fr" target="_blank"
                      >Adhoc</a
                    ></ng-template
                  >
                  <ng-template
                    ngbPanelHeader
                    let-opened="opened"
                    *ngIf="
                      account?.authorities.includes('13') &&
                      isPreprod() == false
                    "
                    ><a href="https://adhoc.sante-idf.fr" target="_blank"
                      >Adhoc</a
                    ></ng-template
                  >
                </ngb-panel>

                <ngb-panel
                  id="static-5"
                  *ngIf="
                    account?.authorities.includes('15') ||
                    account?.authorities.includes('16') ||
                    account?.authorities.includes('21')
                  "
                >
                  <ng-template ngbPanelTitle>
                    <div class="d-flex justify-content-between">
                      <div class="d-flex">Gestion des lits</div>
                      <i class="ion-ios-arrow-up"></i>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <ul>
                      <li *ngIf="account?.authorities.includes('21')">
                        <a
                          href="https://santegraphie.fr/mviewer3/?config=app/ror_dispo_lits.xml"
                          target="_blank"
                          >Carto dispo en lits</a
                        >
                      </li>
                    </ul>
                  </ng-template>
                </ngb-panel>

                <!-- ADD MENU POWER BI IDF -->
                <ngb-panel
                  class="accordion-header"
                  id="static-7"
                  *ngIf="account?.authorities.includes('67')"
                >
                  <ng-template
                    ngbPanelHeader
                    let-opened="opened"
                    *ngIf="account?.authorities.includes('67')"
                    ><a
                      *ngIf="
                        account?.authorities.includes('67') &&
                        isPreprod() == true
                      "
                      href="https://ppr-powerbi-predictif.sante-idf.fr/portail"
                      target="_blank"
                      >Portail BI</a
                    >
                    <a
                      *ngIf="
                        account?.authorities.includes('67') &&
                        isPreprod() == false
                      "
                      href="https://powerbi-predictif.sante-idf.fr/portail"
                      target="_blank"
                      >Portail BI</a
                    ></ng-template
                  >
                </ngb-panel>

                <ngb-panel
                  id="static-6"
                  *ngIf="
                    account?.authorities.includes('26') ||
                    account?.authorities.includes('27') ||
                    account?.authorities.includes('18') ||
                    account?.authorities.includes('37') ||
                    account?.authorities.includes('29') ||
                    account?.authorities.includes('32') ||
                    account?.authorities.includes('69')
                  "
                >
                  <ng-template ngbPanelTitle>
                    <div class="d-flex justify-content-between">
                      <div class="d-flex">Administration</div>
                      <i class="ion-ios-arrow-up"></i>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <ul>
                      <li *ngIf="account?.authorities.includes('26')">
                        <a href="#">Exploitation des flux</a>
                      </li>
                      <li *ngIf="account?.authorities.includes('27')">
                        <a
                          routerLink="/emergency-depts"
                          [ngClass]="{
                            activedown: selectedTab === '/emergency-depts'
                          }"
                          (click)="(selectedTab === '/emergency-depts')"
                          >Paramétrage des SAU</a
                        >
                      </li>
                      <li *ngIf="account?.authorities.includes('18')">
                        <a routerLink="/trafic-mes-urgences"
                          >Trafic Mes Urgences</a
                        >
                      </li>
                      <li *ngIf="account?.authorities.includes('37')">
                        <a
                          *ngIf="isPreprod() == true"
                          href="https://ppr-admin-predictif.sesan.fr/"
                          target="_blank"
                          >Page d'administration</a
                        >
                        <a
                          *ngIf="isPreprod() == false"
                          href="https://admin-predictif.sante-idf.fr/"
                          target="_blank"
                          >Page d'administration</a
                        >
                      </li>
                      <li *ngIf="account?.authorities.includes('29')">
                        <a
                          routerLink="/pocshiny"
                          [ngClass]="{
                            activedown: selectedTab === '/pocshiny'
                          }"
                          >iFrame Shiny</a
                        >
                      </li>
                      <li *ngIf="account?.authorities.includes('32')">
                        <a
                          routerLink="/power-bi"
                          [ngClass]="{
                            activedown: selectedTab === '/power-bi'
                          }"
                          >iFrame Power BI</a
                        >
                      </li>
                      <li *ngIf="account?.authorities.includes('69')">
                        <a
                          routerLink="{{ AppRoutes.PDSA_SETTINGS }}"
                          [ngClass]="{
                            activedown: selectedTab === AppRoutes.PDSA_SETTINGS
                          }"
                          >Paramétrage Structures PDSA</a
                        >
                      </li>
                    </ul>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
            </div>

            <!--ARA-->
            <!-- <div *ngIf='account && account.ssoAuthOrigin !== "ssoAuthOrigin"'> -->
            <div *ngIf="isAraPage() == true" class="menu-scroll">
              <ngb-accordion [closeOthers]="true">
                <ngb-panel class="accordion-header" id="static-1">
                  <ng-template ngbPanelHeader let-opened="opened"
                    ><a
                      routerLink="/accueil"
                      [ngClass]="{
                        active:
                          selectedTab === '/' || selectedTab === '/accueil'
                      }"
                      >Accueil</a
                    ></ng-template
                  >
                </ngb-panel>

                <ngb-panel
                  id="static-2"
                  *ngIf="
                    account?.authorities.includes('43') ||
                    account?.authorities.includes('73') ||
                    account?.authorities.includes('74')
                  "
                >
                  <ng-template ngbPanelTitle>
                    <div class="d-flex justify-content-between">
                      <div class="d-flex">Données des Urgences</div>
                      <i class="ion-ios-arrow-up"></i>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <ul>
                      <li *ngIf="account?.authorities.includes('73')">
                        <a
                          href="/diagramme-de-charge"
                          [ngClass]="{
                            activedown: selectedTab === '/diagramme-de-charge'
                          }"
                          >Diagramme de charge</a
                        >
                      </li>
                      <li *ngIf="account?.authorities.includes('74')">
                        <a
                          routerLink="/evolution-donnees-urgences"
                          [ngClass]="{
                            activedown:
                              selectedTab === '/evolution-donnees-urgences'
                          }"
                          >Evolution</a
                        >
                      </li>
                      <li *ngIf="account?.authorities.includes('43')">
                        <a
                          routerLink="/snp-qualite"
                          [ngClass]="{
                            activedown: selectedTab === '/snp-qualite'
                          }"
                          >SNP Qualité Admin</a
                        >
                      </li>
                      <li *ngIf="account?.authorities.includes('44')">
                        <a routerLink="/qualite" target="_blank">Qualité</a>
                      </li>
                    </ul>
                  </ng-template>
                </ngb-panel>

                <ngb-panel
                  id="static-3"
                  id="static-3"
                  *ngIf="
                    account?.authorities.includes('42') ||
                    account?.authorities.includes('75')
                  "
                >
                  <ng-template ngbPanelTitle>
                    <div class="d-flex justify-content-between">
                      <div class="d-flex">Tension</div>
                      <i class="ion-ios-arrow-up"></i>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <ul>
                      <li *ngIf="account?.authorities.includes('42')">
                        <a
                          routerLink="/het"
                          [ngClass]="{
                            activedown: selectedTab === '/het'
                          }"
                        >
                          Module HET</a
                        >
                      </li>
                      <li *ngIf="account?.authorities.includes('75')">
                        <a
                          routerLink="/evolution-ntg"
                          [ngClass]="{
                            activedown: selectedTab === '/evolution-ntg'
                          }"
                          >Evolution NTG</a
                        >
                      </li>
                    </ul>
                  </ng-template>
                </ngb-panel>

                <ngb-panel
                  class="accordion-header"
                  id="static-4"
                  *ngIf="account?.authorities.includes('24')"
                >
                  <ng-template
                    ngbPanelHeader
                    let-opened="opened"
                    *ngIf="account?.authorities.includes('24')"
                    ><a
                      href="https://www.sante-ra.fr/AutoConnectSSO/Redirect?target=https%3a%2f%2fror.sante-ara.fr%2fror%2f"
                      target="_blank"
                      *ngIf="isPreprod() == false"
                      >ROR</a
                    >
                    <a
                      href="https://ror-test.sante-ara.fr/ror/"
                      target="_blank"
                      *ngIf="isPreprod() == true"
                      >ROR</a
                    ></ng-template
                  >
                </ngb-panel>

                <!-- ADD MENU POWER BI ARA -->
                <ngb-panel
                  class="accordion-header"
                  id="static-7"
                  *ngIf="account?.authorities.includes('68')"
                >
                  <ng-template
                    ngbPanelHeader
                    let-opened="opened"
                    *ngIf="account?.authorities.includes('68')"
                    ><a
                      *ngIf="
                        account?.authorities.includes('68') &&
                        isPreprod() == true
                      "
                      href="https://ppr-powerbi-predictif.sante-idf.fr/portail"
                      target="_blank"
                      >Rapports BI</a
                    >
                    <a
                      *ngIf="
                        account?.authorities.includes('68') &&
                        isPreprod() == false
                      "
                      href="https://powerbi-predictif.sante-idf.fr/portail"
                      target="_blank"
                      >Rapports BI</a
                    ></ng-template
                  >
                </ngb-panel>

                <!-- ADD MENU ADHOC ARA -->
                <ngb-panel
                  class="accordion-header"
                  id="static-7"
                  *ngIf="account?.authorities.includes('72')"
                >
                  <ng-template
                    ngbPanelHeader
                    let-opened="opened"
                    *ngIf="account?.authorities.includes('72')"
                    ><a
                      *ngIf="
                        account?.authorities.includes('72') &&
                        isPreprod() == true
                      "
                      href="https://ppr-adhoc-ara.sesan.fr"
                      target="_blank"
                      >ADHOC</a
                    >
                    <a
                      *ngIf="
                        account?.authorities.includes('72') &&
                        isPreprod() == false
                      "
                      href="https://adhoc-ara.sante-idf.fr"
                      target="_blank"
                      >ADHOC</a
                    ></ng-template
                  >
                </ngb-panel>

                <ngb-panel
                  id="static-6"
                  *ngIf="
                    account?.authorities.includes('49') ||
                    account?.authorities.includes('65')
                  "
                  class="admin-menu"
                >
                  <ng-template ngbPanelTitle>
                    <div class="d-flex justify-content-between">
                      <div class="d-flex">Administration</div>
                      <i class="ion-ios-arrow-up"></i>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <ul>
                      <li *ngIf="account?.authorities.includes('49')">
                        <a
                          routerLink="/emergency-depts"
                          [ngClass]="{
                            activedown: selectedTab === '/emergency-depts'
                          }"
                          >Paramétrage des SAU</a
                        >
                      </li>
                      <li *ngIf="account?.authorities.includes('65')">
                        <a
                          *ngIf="isPreprod() == true"
                          href="https://ppr-admin-predictif-ara.sesan.fr/habilitations/utilisateurs-web"
                          target="_blank"
                          >Utilisateurs</a
                        >
                        <a
                          *ngIf="isPreprod() == false"
                          href="https://admin-predictif-ara.sante-idf.fr/habilitations/utilisateurs-web"
                          target="_blank"
                          >Utilisateurs</a
                        >
                      </li>
                      <li *ngIf="account?.authorities.includes('65')">
                        <a
                          *ngIf="isPreprod() == true"
                          href="https://ppr-admin-predictif-ara.sesan.fr/habilitations/profils"
                          target="_blank"
                          >Profils</a
                        >
                        <a
                          *ngIf="isPreprod() == false"
                          href="https://admin-predictif-ara.sante-idf.fr/habilitations/profils"
                          target="_blank"
                          >Profils</a
                        >
                      </li>
                      <li *ngIf="account?.authorities.includes('65')">
                        <a
                          *ngIf="isPreprod() == true"
                          href="https://ppr-admin-predictif-ara.sesan.fr/eCerveau/messages"
                          target="_blank"
                          >Messages d'informations</a
                        >
                        <a
                          *ngIf="isPreprod() == false"
                          href="https://admin-predictif-ara.sante-idf.fr/eCerveau/messages"
                          target="_blank"
                          >Messages d'informations</a
                        >
                      </li>
                    </ul>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
            </div>

            <footer>
              <div *ngIf="isAraPage() == true">
                <a href="https://www.sante-ra.fr/" target="_blank"
                  ><img class="logo-region" alt="logo region" />
                </a>
                <p class="footer-para">V.2.0.</p>
              </div>
              <div *ngIf="isAraPage() == false">
                <a href="https://www.sesan.fr/" target="_blank"
                  ><img class="logo-region" alt="logo region"
                /></a>
                <p class="footer-pidf">V.2.0.</p>
              </div>
            </footer>
          </div>
        </ng-container>

        <!--CONTENU DE LA PAGE -->

        <section (click)="closeMenu()">
          <router-outlet></router-outlet>
        </section>
      </div>
    </div>

    <footer
      *ngIf="isAraPage() == false && isIframe() == false"
      class="mt-auto w-100"
    >
      <app-footer></app-footer>
    </footer>
  </body>
</html>
