import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-charge-graph',
  templateUrl: './charge-graph.component.html',
  styleUrls: ['./charge-graph.component.scss']
})
export class ChargeGraphComponent implements OnInit {

  @Input() displayPeriod2: boolean

  constructor() { }

  ngOnInit(): void {
  }

}
