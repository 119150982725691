import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { ChartService } from "src/app/graph/services/chart.service";

@Component({
  selector: "app-visits-child-graph",
  templateUrl: "./visits-child-graph.component.html",
  styleUrls: ["./visits-child-graph.component.scss"],
})
export class VisitsChildGraphComponent implements OnInit {
  @Input() dataInd2;
  constructor(private chartService: ChartService) {}

  ngOnInit(): void {}
  ngOnChanges(change: SimpleChanges) {
    this.chartService.initChart(
      "visitsChild",
      change.dataInd2.currentValue,
      "Nombre de passages enfants < 1 an"
    );
  }
}
