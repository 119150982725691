import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from 'src/app/shared/models/user.model';

import { EffectorForm } from '../model/effector-form.model';
import { Effector, EffectorClass } from '../model/effectors.model';
import { HasUniqueCodeMetRequest } from '../model/has-unique-code-met.model';

@Injectable({
  providedIn: 'root'
})
export class EffectorService {
  constructor(private httpClient: HttpClient) {}

  public getEffectors(): Observable<EffectorClass[]> {
    return this.httpClient
      .get<Effector[]>(`/api/effectors`)
      .pipe(map((e) => e.map((ef) => new EffectorClass(ef))));
  }

  public hasUniqueCodeMet(
    codeMet: HasUniqueCodeMetRequest
  ): Observable<boolean> {
    return this.httpClient.post<boolean>(
      `/api/effectors/hasUniqueCodeMet`,
      codeMet
    );
  }

  public getEffectorsById(id: number): Observable<EffectorClass> {
    return this.httpClient
      .get<Effector>(`/api/effectors/${id}`)
      .pipe(map((e) => new EffectorClass(e)));
  }

  public getEffectorById(id: number) : Observable<Effector> {
    return this.httpClient.get<Effector>(`/api/effectors/${id}`);
  }

  public getUsersByEffectorId(id: number): Observable<User[]> {
    return this.httpClient.get<User[]>(`/api/effectors/user/${id}`);
  }

  public putEffectorForm(effectorsForm: EffectorForm): Observable<Effector> {
    return this.httpClient.put<Effector>(`api/effectors`, effectorsForm);
  }

  public postEffectorForm(newEffector: EffectorForm): Observable<Effector> {
    return this.httpClient.post<Effector>(`api/effectors`, newEffector);
  }

  public updateEffectorStatus(effectorId: number, status: boolean) {
    return this.httpClient.post<Effector>(
      `api/effectors/${effectorId}/status`,
      status
    );
  }

  public deleteEffectorsForm(id: number): Observable<Effector> {
    return this.httpClient.delete<Effector>(`api/effectors/${id}`);
  }
}
