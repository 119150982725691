<body>
  <div class="container">
    <!-- <iframe width="100%" height="100%" frameBorder="0" [src]="urlSafe"></iframe> -->

    <iframe
      width="100%"
      height="100%"
      frameBorder="0"
      src="https://ppr-pocshiny-indicateurs.sesan.fr/"
      allowfullscreen
    ></iframe>
  </div>
</body>
