import { DateHelper } from 'src/app/shared/util/date.helper';

export class DateValidityFilter {
  firstValidityStartDate: Date = new Date(DateValidityFilterEnum.MIN_TIMESTAMP);
  secondValidityStartDate: Date = new Date(
    DateValidityFilterEnum.MAX_TIMESTAMP
  );
  firstValidityEndDate: Date = new Date();
  secondValidityEndDate: Date = new Date(DateValidityFilterEnum.MAX_TIMESTAMP);

  get firstValidityEndDateString(): string {
    if (this.firstValidityEndDate.getTime() === 0) {
      return '';
    }
    return DateHelper.toLocalDate(this.firstValidityEndDate) ?? '';
  }
}

export enum DateValidityFilterEnum {
  MAX_TIMESTAMP = 8640000000000000,
  MIN_TIMESTAMP = 0
}
