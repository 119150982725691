export class ContactRequest {
  mail: string;
  firstname: string;
  lastname: string;
  establishment: string;
  function: string;
  detail: string;
  idUser: string;
  telephone: string;
  effectorArsLabel: string;
}
