import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AsyncSubject } from "rxjs";
import { map, retry } from "rxjs/operators";
import { Context } from "./context.model";

@Injectable({ providedIn: "root" })
export class ContextStore {
  private contextSubject = new AsyncSubject<Context>();
  readonly context = this.contextSubject.asObservable();
  readonly isIdf = this.contextSubject.asObservable().pipe(map(context => context.regionId === 11));
  readonly isAra = this.contextSubject.asObservable().pipe(map(context => context.regionId === 84));

  constructor(http: HttpClient) {
    http
      .get<Context>("/api/context")
      .pipe(
        retry(2)
      )
      .subscribe(
        (context) => this.contextSubject.next(context),
        (error) => console.error(error),
        () => this.contextSubject.complete(),
      );
  }
}
