import { FormArray, FormGroup } from '@angular/forms';
import { DateHelper } from 'src/app/shared/util/date.helper';

import { EffectorFormAddress } from './effector-form-address.model';
import { EffectorFormAvailabilities } from './effector-form-availabilities.model';
import { EffectorFormTerritory } from './effector-form-territory.model';
import { EffectorTerritoryTableLine } from './effectors-territory-table-line.model';

export class EffectorForm {
  id?: number;
  effectorArsLabel: string;
  effectorLabel: string;
  effectorShortLabel: string;
  domainCode: string;
  typeStructureCode?: string;
  departmentCode: string;
  managerName: string;
  managingEntityName: string;
  platformBoolean: boolean;
  validityStartDate?: string;
  validityEndDate: string;
  ccrBoolean: boolean;
  flowTransmissionBoolean: boolean;
  firstTransmissionDate?: string;
  effectorCodeMet?: string;
  callFlowBoolean: boolean;
  fileFlowBoolean: boolean;
  callTransmissionDate?: string;
  visitTransmissionDate?: string;

  // effectorsTerritory: NewEffectorTerritory[];
  address: EffectorFormAddress;
  availabilities: EffectorFormAvailabilities[];
  territorys: EffectorFormTerritory[];

  static fromEffectorForm(
    effectorFormGroup: FormGroup,
    availabilitiesForm: FormArray,
    addressForm: FormGroup,
    effectorTerritoryTableLines: EffectorTerritoryTableLine[]
  ): EffectorForm {
    // const validityStartDate =
    //   effectorFormGroup.get("validityStartDate")?.value == null
    //     ? undefined
    //     : new Date(
    //         effectorFormGroup.get("validityStartDate")?.value,
    //       ).toISOString();

    const validityStartDate = DateHelper.toUTCDate(
      effectorFormGroup.get('validityStartDate')?.value
    );

    const callTransmissionDate =
      effectorFormGroup.get('callTransmissionDate')?.value == null
        ? undefined
        : new Date(
            effectorFormGroup.get('callTransmissionDate')?.value
          ).toISOString();

    const visitTransmissionDate =
      effectorFormGroup.get('visitTransmissionDate')?.value == null
        ? undefined
        : new Date(
            effectorFormGroup.get('visitTransmissionDate')?.value
          ).toISOString();

    const firstTransmissionDate =
      effectorFormGroup.get('firstTransmissionDate')?.value == null
        ? undefined
        : new Date(
            effectorFormGroup.get('firstTransmissionDate')?.value
          ).toISOString();

    const e = new EffectorForm();
    e.id = effectorFormGroup.value.id;

    e.effectorArsLabel = effectorFormGroup.value.effectorArsLabel;
    e.effectorLabel = effectorFormGroup.value.effectorLabel;
    e.effectorShortLabel = effectorFormGroup.value.effectorShortLabel;
    e.domainCode = effectorFormGroup.value.domainCode;
    e.typeStructureCode = effectorFormGroup.value.typeStructureCode;
    e.departmentCode = effectorFormGroup.value.departmentCode;
    e.managerName = effectorFormGroup.value.managerName;
    e.managingEntityName = effectorFormGroup.value.managingEntityName;
    e.platformBoolean = effectorFormGroup.value.platformBoolean;
    e.validityEndDate = new Date(
      effectorFormGroup.value.validityEndDate
    ).toISOString();
    e.ccrBoolean = effectorFormGroup.value.ccrBoolean;
    e.flowTransmissionBoolean = effectorFormGroup.value.flowTransmissionBoolean;
    e.firstTransmissionDate = firstTransmissionDate;
    e.callFlowBoolean = effectorFormGroup.value.callFlowBoolean;
    e.callTransmissionDate = callTransmissionDate;
    e.fileFlowBoolean = effectorFormGroup.value.fileFlowBoolean;
    e.visitTransmissionDate = visitTransmissionDate;
    e.validityStartDate = validityStartDate;
    e.effectorCodeMet = effectorFormGroup.value.effectorCodeMet;

    e.address = new EffectorFormAddress(addressForm);
    e.availabilities = availabilitiesForm.controls.map(
      (c) => new EffectorFormAvailabilities(c as FormGroup)
    );
    if (effectorTerritoryTableLines == null) {
      return e;
    }
    e.territorys = effectorTerritoryTableLines.map((e) =>
      EffectorFormTerritory.fromEffectorTerritoryTableLine(e)
    );
    return e;
  }

  constructor() {}
}
