<!--MOBILE EFFECTORS-->
<div style="padding-bottom: 1%; padding-top: 1%" #content>
  <div ngcontent class="card mx-auto my-auto">
    <div class="closeButtonPosition">
      <img
        class="close-button closeButtonSize"
        src="../../../../assets/images/close.svg"
        (click)="closeForm(currentPage)"
      />
    </div>
    <div class="container-fluid mt-0 pt-0">
      <div class="row mb-4 mt-3">
        <div class="col-12">
          <h2 class="mb-0">
            {{ effectorShortLabel }} -
            {{ effectorDate ? capitalizeFirstLetter(effectorDate) : '' }}
          </h2>
        </div>
      </div>

      <div *ngIf="loading" class="my-4">
        <div class="d-flex justify-content-center">
          <div
            class="spinner-border"
            style="width: 6rem; height: 6rem"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>

      <!--NAVIGATION-->
      <nav ngbNav #nav="ngbNav" class="nav-tabs">
        <!-- TAB 1 -->
        <ng-container
          ngbNavItem
          [ngbNavItem]="1"
          [disabled]="currentPage === 1"
        >
          <a
            ngbNavLink
            class="tab-view textTansformUppercase"
            (click)="selectTabAndSaveCurrentTab(1)"
            >Activité pendant et hors PDSA</a
          >

          <ng-template
            ngbNavContent
            class="nav-content shadow p-md-3 mb-2 bg-white rounded w-100"
          >
            <app-pdsa-activity-tab-one
              [form]="getFormGroupFor('tab_one')"
            ></app-pdsa-activity-tab-one>
          </ng-template>
        </ng-container>

        <!-- Tab 2 -->
        <ng-container
          ngbNavItem
          [ngbNavItem]="2"
          [disabled]="
            (mobileEffectorVolumeEntry &&
              mobileEffectorVolumeEntry.entry.step < 2) ||
            currentPage === 2
          "
        >
          <a
            ngbNavLink
            class="textTansformUppercase"
            [ngClass]="isViewed(2) ? 'tab-view' : 'tab-not-view'"
            (click)="selectTabAndSaveCurrentTab(2)"
            >Visites par territoire</a
          >
          <ng-template ngbNavContent>
            <app-pdsa-activity-tab-two
              [form]="getFormGroupFor('tab_two')"
            ></app-pdsa-activity-tab-two>
          </ng-template>
        </ng-container>

        <!-- Tab 3 -->
        <ng-container
          ngbNavItem
          [ngbNavItem]="3"
          [disabled]="
            (mobileEffectorVolumeEntry &&
              mobileEffectorVolumeEntry.entry.step < 3) ||
            currentPage === 3
          "
        >
          <a
            ngbNavLink
            class="textTansformUppercase"
            [ngClass]="isViewed(3) ? 'tab-view' : 'tab-not-view'"
            (click)="selectTabAndSaveCurrentTab(3)"
            >Visites CRRA15</a
          >
          <ng-template ngbNavContent>
            <app-pdsa-activity-tab-three
              [form]="getFormGroupFor('tab_three')"
            ></app-pdsa-activity-tab-three>
          </ng-template>
        </ng-container>

        <!-- Tab 4 -->
        <ng-container
          ngbNavItem
          [ngbNavItem]="4"
          [disabled]="
            (mobileEffectorVolumeEntry &&
              mobileEffectorVolumeEntry.entry.step < 4) ||
            currentPage === 4
          "
        >
          <a
            ngbNavLink
            class="textTansformUppercase"
            [ngClass]="isViewed(4) ? 'tab-view' : 'tab-not-view'"
            (click)="selectTabAndSaveCurrentTab(4)"
            >Certif. décès</a
          >
          <ng-template ngbNavContent>
            <app-pdsa-activity-tab-four
              [form]="getFormGroupFor('tab_four')"
            ></app-pdsa-activity-tab-four>
          </ng-template>
        </ng-container>

        <!-- Tab 5 -->
        <ng-container
          ngbNavItem
          [ngbNavItem]="5"
          *ngIf="isBooleanPlatform"
          [disabled]="
            (mobileEffectorVolumeEntry &&
              mobileEffectorVolumeEntry.entry.step < 5) ||
            currentPage === 5
          "
        >
          <a
            ngbNavLink
            class="textTansformUppercase"
            [ngClass]="isViewed(5) ? 'tab-view' : 'tab-not-view'"
            (click)="selectTabAndSaveCurrentTab(5)"
            >Appels</a
          >
          <ng-template ngbNavContent>
            <app-pdsa-activity-tab-five
              [form]="getFormGroupFor('tab_five')"
            ></app-pdsa-activity-tab-five>
          </ng-template>
        </ng-container>

        <!-- Tab 6 -->
        <ng-container
          ngbNavItem
          [ngbNavItem]="6"
          *ngIf="isBooleanPlatform"
          [disabled]="
            (mobileEffectorVolumeEntry &&
              mobileEffectorVolumeEntry.entry.step < 6) ||
            currentPage === 6
          "
        >
          <a
            ngbNavLink
            class="textTansformUppercase"
            [ngClass]="isViewed(6) ? 'tab-view' : 'tab-not-view'"
            (click)="selectTabAndSaveCurrentTab(6)"
            >Décisions</a
          >
          <ng-template ngbNavContent>
            <app-pdsa-activity-tab-six
              [form]="getFormGroupFor('tab_six')"
              (hasCommentChange)="hasComment = $event"
            ></app-pdsa-activity-tab-six>
          </ng-template>
        </ng-container>

        <!-- TAB 7 -->
        <ng-container
          ngbNavItem
          [ngbNavItem]="isBooleanPlatform ? 7 : 5"
          *ngIf="hasCommentEntered"
          [disabled]="
            currentPage === 7 || (!isBooleanPlatform && currentPage === 5)
          "
        >
          <a
            ngbNavLink
            class="tab-view"
            (click)="selectTabAndSaveCurrentTab(isBooleanPlatform ? 7 : 5)"
            >COMMENTAIRE</a
          >
          <ng-template ngbNavContent [form]="getFormGroupFor('tab_seven')">
            <app-pdsa-activity-tab-seven
              [form]="getFormGroupFor('tab_seven')"
            ></app-pdsa-activity-tab-seven>
          </ng-template>
        </ng-container>

        <!-- HIDE -->
        <ng-template #mymodal let-modal>
          <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">
              Aperçu de l'export
            </h4>
            <button
              type="button"
              class="close"
              aria-label="Close"
              (click)="modal.dismiss('Cross click')"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <app-pdsa-mobile-activity-pdf> </app-pdsa-mobile-activity-pdf>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-dark"
              (click)="exportToPDF()"
              (click)="modal.dismiss('Cross click')"
            >
              Exporter
            </button>
          </div>
        </ng-template>

        <!-- DOWNLOAD BUTTON -->
        <div class="downloadPosition">
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            ngbTooltip="Téléchargement PDF"
            (click)="showModalPdf(mymodal)"
          >
            <img
              src="../../../../assets/images/download.svg"
              alt="export"
              class="downloadSrc"
            />
          </button>
        </div>
      </nav>

      <div [ngbNavOutlet]="nav" class="mt-2"></div>

      <div class="form-footer row mt-2 mx-0">
        <div class="col-12 col-md-3 p-0 pl-3">
          <div class="row">
            <p
              style="text-align: center"
              class="d-flex align-items-center justify-content-center mb-0"
            >
              Zéro patient
            </p>
            <div
              type="text"
              class="rounded border border-success legendTab1 mr-2"
              ngbTooltip="Saisissez la valeur 0 si aucun patient n’a été vu"
            >
              0
            </div>
            <p
              style="text-align: center"
              class="d-flex align-items-center justify-content-center mb-0"
            >
              La structure est fermée
            </p>
            <div
              type="text"
              class="rounded border border-success legendTab1"
              ngbTooltip="Saisissez la valeur F si la structure été fermée"
            >
              F
            </div>
          </div>
        </div>
        <div class="col-12 col-md-3"></div>
        <div
          class="col-12 col-md-6 m-0 p-0 d-flex flex-fill justify-content-end mt-4 mt-md-0"
        >
          <button
            type="button"
            class="btn previous"
            name="Enregistrer"
            (click)="saveAndSelectPreviousTab(currentPage)"
            [disabled]="currentPage === 1"
            [ngbTooltip]="
              currentPage !== 1 ? 'Vos modifications seront sauvegardées' : null
            "
            [ngClass]="{ 'not-allowed-cursor': currentPage === 1 }"
            [ngStyle]="{ opacity: currentPage === 1 ? '0.45' : '1' }"
          >
            Étape précédente
          </button>
          <button
            type="button"
            class="btn previous2"
            name="Enregistrer"
            (click)="saveAndSelectPreviousTab(currentPage)"
            [disabled]="currentPage === 1"
            [ngbTooltip]="
              currentPage !== 1 ? 'Vos modifications seront sauvegardées' : null
            "
            [ngClass]="{ 'not-allowed-cursor': currentPage === 1 }"
            [ngStyle]="{ opacity: currentPage === 1 ? '0.45' : '1' }"
          >
            <
          </button>

          <p
            class="pt-2 mr-1 pagesCount"
            *ngIf="!hasCommentEntered && !isBooleanPlatform"
          >
            {{ currentPage }} / 4
          </p>
          <p
            class="pt-2 mr-1 pagesCount"
            *ngIf="hasCommentEntered && !isBooleanPlatform"
          >
            {{ currentPage }} / 5
          </p>
          <p
            class="pt-2 mr-1 pagesCount"
            *ngIf="!hasCommentEntered && isBooleanPlatform"
          >
            {{ currentPage }} / 6
          </p>
          <p
            class="pt-2 mr-1 pagesCount"
            *ngIf="hasCommentEntered && isBooleanPlatform"
          >
            {{ currentPage }} / 7
          </p>
          <button
            *ngIf="
              !isBooleanPlatform && !hasCommentEntered && currentPage === 4
            "
            type="button"
            class="btn temporaryValidButton ml-2"
            (click)="fourthChildComponentRef.onSubmit(eventTypes.SUBMIT)"
            ngbTooltip="{{ tooltipMessage }}"
            [ngStyle]="{ cursor: cursorStyle, backgroundColor: colorStyle }"
            [disabled]="disableButton"
          >
            VALIDER MA SAISIE
          </button>
          <button
            *ngIf="!isBooleanPlatform && hasCommentEntered && currentPage === 5"
            type="button"
            class="btn temporaryValidButton ml-2"
            (click)="seventhChildComponentRef.onSubmit(eventTypes.SUBMIT)"
          >
            VALIDER MA SAISIE
          </button>
          <!-- If isBooleanPlatfform === true -->
          <button
            *ngIf="isBooleanPlatform && !hasCommentEntered && currentPage === 6"
            type="button"
            class="btn temporaryValidButton ml-2"
            (click)="sixhChildComponentRef.onSubmit(eventTypes.SUBMIT)"
            ngbTooltip="{{ tooltipMessage }}"
            [ngStyle]="{ cursor: cursorStyle, backgroundColor: colorStyle }"
            [disabled]="disableButton"
          >
            VALIDER MA SAISIE
          </button>
          <button
            *ngIf="isBooleanPlatform && hasCommentEntered && currentPage === 7"
            type="button"
            class="btn temporaryValidButton ml-2"
            (click)="seventhChildComponentRef.onSubmit(eventTypes.SUBMIT)"
          >
            VALIDER MA SAISIE
          </button>

          <!--NEXT BUTTONS TAB 1-->
          <button
            *ngIf="
              currentPage === 1 ||
              currentPage === 2 ||
              currentPage === 3 ||
              (isBooleanPlatform && currentPage === 5) ||
              (currentPage === 4 && isBooleanPlatform) ||
              (currentPage === 4 &&
                !isBooleanPlatform &&
                hasCommentEntered === true)
            "
            type="button"
            class="btn next2 ml-2"
            (click)="saveAndSelectNextTab(currentPage)"
            ngbTooltip="Vos modifications seront sauvegardées"
          >
            >
          </button>

          <button
            *ngIf="
              currentPage === 1 ||
              currentPage === 2 ||
              currentPage === 3 ||
              (isBooleanPlatform && currentPage === 5) ||
              (currentPage === 4 && isBooleanPlatform) ||
              (currentPage === 4 &&
                !isBooleanPlatform &&
                hasCommentEntered === true)
            "
            type="button"
            class="btn next"
            (click)="saveAndSelectNextTab(currentPage)"
            ngbTooltip="Vos modifications seront sauvegardées"
          >
            Étape suivante
          </button>

          <!-- NEXT BUTTONS 6-->
          <button
            *ngIf="currentPage === 6 && hasCommentEntered && isBooleanPlatform"
            type="button"
            class="btn next2 ml-2"
            (click)="sixhChildComponentRef.onSubmit(eventTypes.NAVIGATE_NEXT)"
            [disabled]="currentPage === 6 && !hasCommentEntered"
            ngbTooltip="Vos modifications seront sauvegardées"
          >
            >
          </button>

          <button
            *ngIf="currentPage === 6 && hasCommentEntered && isBooleanPlatform"
            type="button"
            class="btn next"
            (click)="sixhChildComponentRef.onSubmit(eventTypes.NAVIGATE_NEXT)"
            [disabled]="currentPage === 6 && !hasCommentEntered"
            ngbTooltip="Vos modifications seront sauvegardées"
          >
            Étape suivante
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
