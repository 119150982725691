import {Injectable} from '@angular/core';
import {filter, skip} from 'rxjs/operators';
import {NavigationEnd, Router} from '@angular/router';
import {PiwikPaqService} from './piwik-paq.service';
import {TRACK_EVENT} from '../piwik-track-event.constant';
import {AccountService} from '../../../core/auth/account.service';
import {PiwikCustomEventService} from './custom-events.service';

@Injectable({
  providedIn: 'root'
})
export class PiwikService {
  constructor(private paqService: PiwikPaqService,
              private router: Router,
              private accountService: AccountService,
              private customEventService: PiwikCustomEventService
  ) {}

  containerId = 'ff802d29-b8e2-4db7-b9ea-0a2e34e0f625';
  containerURL = 'https://gcsesan.piwik.pro';

  public init(containerId: string): void {
      this.containerId = containerId;
      const s = document.createElement('script');
      s.async = true;
      s.text = `(function(window, document, dataLayerName, id) {
              window[dataLayerName]=window[dataLayerName]||[],window[dataLayerName].push({start:(new Date).getTime(),event:"stg.start"});var scripts=document.getElementsByTagName('script')[0],tags=document.createElement('script');
              function stgCreateCookie(a,b,c){var d="";if(c){var e=new Date;e.setTime(e.getTime()+24*c*60*60*1e3),d="; expires="+e.toUTCString()}document.cookie=a+"="+b+d+"; path=/"}
              var isStgDebug=(window.location.href.match("stg_debug")||document.cookie.match("stg_debug"))&&!window.location.href.match("stg_disable_debug");stgCreateCookie("stg_debug",isStgDebug?1:"",isStgDebug?14:-1);
              var qP=[];dataLayerName!=="dataLayer"&&qP.push("data_layer_name="+dataLayerName),isStgDebug&&qP.push("stg_debug");var qPString=qP.length>0?("?"+qP.join("&")):"";
              tags.async=!0,tags.src="${this.containerURL}/containers/"+id+".js"+qPString,scripts.parentNode.insertBefore(tags,scripts);
              !function(a,n,i){a[n]=a[n]||{};for(var c=0;c<i.length;c++)!function(i){a[n][i]=a[n][i]||{},a[n][i].api=a[n][i].api||function(){var a=[].slice.call(arguments,0);"string"==typeof a[0]&&window[dataLayerName].push({event:n+"."+i+":"+a[0],parameters:[].slice.call(arguments,1)})}}(i[c])}(window,"ppms",["tm","cm"]);
              })(window, document, 'dataLayer', '${this.containerId}')`;
      const head = document.getElementsByTagName('head')[0];
      head.appendChild(s);
      this.initRouteTracking();
      //account observable piwik
      this.accountService.account.subscribe(account => {
        if (account != null) {
          this.customEventService.trackEvent(
            'ACCOUNT',
            'LOGIN',
            account.lastName + ' ' + account.firstName,
            1);
        }
      });
  }

  private initRouteTracking(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        skip(1) // Prevend double views on the first tigger (because PiwikPro Already send one ping on setup)
      )
      .subscribe((val) => {
        this.trackPageView();
      });
  }

  public trackPageView(customPageTitle?: string): void {
    this.paqService.push([
      TRACK_EVENT.PAGE_VIEW,
      ...(customPageTitle ? [customPageTitle] : [])
    ]);
  }
}
