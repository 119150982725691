<form [formGroup]="mobileCallRangeForm" (ngSubmit)="onSubmit(eventTypes.CLOSE)">
  <div class="table-responsive">
    <table class="table table-striped shadow-sm p-3 bg-white">
      <thead>
        <tr class="noselect header-row">
          <th
            style="max-width: 15vw; width: 15vw; padding: 0px; margin: 0px"
          ></th>
          <th
            *ngFor="let timeSlotLabel of timeSlotLabels; let i = index"
            style="max-width: 4vw; margin: 0px"
          >
            <p style="color: #ffffff">{{ timeSlotLabel }}</p>
            <p style="color: #ffffff">{{ timeSlotDayTypes[i] }}</p>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="cursor noselect hover-effect">
          <td>
            <div class="d-flex align-items-center">
              <div class="fontSize">Nombre d'appels entrants</div>
            </div>
          </td>
          <td
            *ngFor="
              let elementGroup of mobileCallRangeForm.get('elements')?.controls;
              let i = index
            "
          >
            <div class="d-flex justify-content-center align-items-center">
              <input
                type="text"
                class="rounded border inputSize"
                [ngClass]="{
                  error: elementGroup.get('callCount').hasError('invalidValue'),
                  warning: elementGroup.get('callCount').hasError('emptyValue')
                }"
                style="width: 4vw; height: 2vw"
                [formControl]="elementGroup.get('callCount')"
                (input)="this.onInputChange()"
              />
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="d-flex align-items-center">
              <div class="fontSize">Nombre d'appels décrochés</div>
            </div>
          </td>
          <td
            *ngFor="
              let elementGroup of mobileCallRangeForm.get('elements')?.controls;
              let i = index
            "
          >
            <div class="d-flex justify-content-center align-items-center">
              <input
                type="text"
                class="rounded border inputSize"
                [ngClass]="{
                  error: elementGroup
                    .get('answeredCount')
                    .hasError('invalidValue'),
                  warning: elementGroup
                    .get('answeredCount')
                    .hasError('emptyValue')
                }"
                style="width: 4vw; height: 2vw"
                [formControl]="elementGroup.get('answeredCount')"
                (input)="this.onInputChange()"
              />
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="d-flex align-items-center">
              <div class="fontSize">
                Nombre d'appels pris en charge par un médecin présent sur la
                plateforme
              </div>
            </div>
          </td>
          <td
            *ngFor="
              let elementGroup of mobileCallRangeForm.get('elements')?.controls;
              let i = index
            "
          >
            <div class="d-flex justify-content-center align-items-center">
              <input
                type="text"
                class="rounded border inputSize"
                [ngClass]="{
                  error: elementGroup
                    .get('answeredCountByDoctorOnSite')
                    .hasError('invalidValue'),
                  warning: elementGroup
                    .get('answeredCountByDoctorOnSite')
                    .hasError('emptyValue')
                }"
                style="width: 4vw; height: 2vw"
                [formControl]="elementGroup.get('answeredCountByDoctorOnSite')"
                (input)="this.onInputChange()"
              />
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="d-flex align-items-center">
              <div class="fontSize">
                Nombre d'appels pris en charge par un médecin non présent sur la
                plateforme
              </div>
            </div>
          </td>
          <td
            *ngFor="
              let elementGroup of mobileCallRangeForm.get('elements')?.controls;
              let i = index
            "
          >
            <div class="d-flex justify-content-center align-items-center">
              <input
                type="text"
                class="rounded border inputSize"
                [ngClass]="{
                  error: elementGroup
                    .get('answeredCountByDoctorOffSite')
                    .hasError('invalidValue'),
                  warning: elementGroup
                    .get('answeredCountByDoctorOffSite')
                    .hasError('emptyValue')
                }"
                style="width: 4vw; height: 2vw"
                [formControl]="elementGroup.get('answeredCountByDoctorOffSite')"
                (input)="this.onInputChange()"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</form>
