import { FormGroup } from '@angular/forms';

import { EffectorTerritoryTableLine } from './effectors-territory-table-line.model';

export class EffectorFormTerritory {
  id?: number;
  typeTerritory = 'PDSA';
  healthTerritoryCode: string;
  validityBoolean: boolean;
  validityStartDate: string;
  validityEndDate: string;
  toDelete: boolean;

  static fromEffectorTerritoryTableLine(
    effectorTerritoryTableLine: EffectorTerritoryTableLine
  ): EffectorFormTerritory {
    return new EffectorFormTerritory(
      effectorTerritoryTableLine.typeTerritory,
      effectorTerritoryTableLine.healthTerritoryCode,
      effectorTerritoryTableLine.validityBoolean,
      effectorTerritoryTableLine.validityStartDate.toISOString(),
      effectorTerritoryTableLine.validityEndDate.toISOString(),
      effectorTerritoryTableLine.toDelete,
      effectorTerritoryTableLine.id
    );
  }

  static fromFormGroup(formGroup: FormGroup): EffectorFormTerritory {
    return new EffectorFormTerritory(
      'PDSA',
      formGroup.value.healthTerritoryCode,
      formGroup.value.validityBoolean,
      formGroup.value.validityStartDate,
      formGroup.value.validityEndDate,
      formGroup.value.id
    );
  }

  constructor(
    typeTerritory: string,
    healthTerritoryCode: string,
    validityBoolean: boolean,
    validityStartDate: string,
    validityEndDate: string,
    toDelete: boolean,
    id?: number
  ) {
    this.id = id;
    this.typeTerritory = typeTerritory;
    this.healthTerritoryCode = healthTerritoryCode;
    this.validityBoolean = validityBoolean;
    this.validityStartDate = validityStartDate;
    this.validityEndDate = validityEndDate;
    this.toDelete = toDelete;
  }
}
