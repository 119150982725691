<div *ngIf="isAraPage() == false">
  <div
    style="width: 1000px; margin-right: auto; margin-left: auto"
    class="mention"
  >
    <p class="MsoNormal" style="text-align: justify">
      <span style="font-size: 20pt; line-height: 107%; color: #0b7ec2"
        >Mentions légales</span
      >
    </p>

    <!-- <! Initialisation > -->
    <p class="MsoNormal" style="text-align: justify">
      Le site eCerveau (https://cerveau.sante-idf.fr,
      https://ppr-cerveau.gcsdsisif.fr, https://ppr-ecerveau-predictif.sesan.fr,
      https://ecerveau-predictif.sante-idf.fr) est site édité par le Groupement
      d'Intérêt Public service numérique de santé (ci-après SESAN), structure de
      droit privé régie par l’article L6133-1 du Code de la Santé Publique, sis
      6-8 rue Firmin Gillot 75015 PARIS (01 48 78 99 88 - Siret 513 654 715 000
      46 -contact@sesan.fr ), au sens de l’article 6 III de la loi n° 2004-575
      du 21 juin 2004.
    </p>

    <p class="MsoNormal" style="text-align: justify">A ce titre&nbsp;:</p>

    <ul style="text-align: left">
      <li>
        Le directeur de la publication est Naima MEZAOUR, Directrice du GIP
        SESAN (contact@sesan.fr)
      </li>
      <li>
        Le responsable de rédaction est Nabia MEBARKI, Directeur de projet
        (nabia.mebarki@sesan.fr)
      </li>
    </ul>

    <!-- <! PARTIE HEBERGEMENT > -->
    <p class="MsoNormal" style="text-align: justify">
      <span style="font-size: 18pt; line-height: 107%; color: #a7358b">
        Hébergement
      </span>
    </p>

    <p class="MsoNormal" style="text-align: justify">
      La société Claranet SAS (18-20, rue du Faubourg du Temple 75011 Paris -
      RCS de Paris B 419 632 286) est hébergeur du site eCerveau
      (https://cerveau.sante-idf.fr, https://ppr-cerveau.gcsdsisif.fr) au sens
      de l’article 6 I 2 de la loi n° 2004-575 du 21 juin 2004 pour des
      informations de toute nature stockées dans le site par les utilisateurs.
    </p>

    <!-- <! PROPRIETE INTELLECTUELLE > -->
    <p class="MsoNormal" style="text-align: justify">
      <span style="font-size: 18pt; line-height: 107%; color: #a7358b">
        Propriété intellectuelle
      </span>
    </p>

    <p class="MsoNormal" style="text-align: justify">
      SESAN est l’auteur du site eCerveau (https://cerveau.sante-idf.fr,
      https://ppr-cerveau.gcsdsisif.fr, https://ppr-ecerveau-predictif.sesan.fr,
      https://ecerveau-predictif.sante-idf.fr) au sens du Code de la propriété
      intellectuelle.
    </p>

    <p class="MsoNormal" style="text-align: justify">
      <span style="font-size: 18pt; line-height: 107%; color: #a7358b">
        Conception graphique et technique du site
      </span>
    </p>

    <div style="text-align: justify">Keyrus</div>

    <div style="text-align: justify">79 Bd de Stalingrad</div>

    <div style="text-align: justify">69100 Villeurbanne</div>

    <div style="text-align: justify">Tél: +33 (0)4 37 49 69 49</div>

    <div style="text-align: justify">SIRET : 400 149 647 00192</div>

    <!-- <! PARTIE PROTECTION DES DONNEES PERSONNELLES > -->
    <p class="MsoNormal" style="text-align: justify">
      <span style="font-size: 18pt; line-height: 107%; color: #a7358b">
        Protection des données à caractère personnel
      </span>
    </p>

    <p class="MsoNormal" style="text-align: justify">
      Les données à caractère personnel collectées via le portail ENRS
      (https://pro.sante-idf.fr) peuvent faire l’objet de traitements sous la
      responsabilité de SESAN, responsable de traitement.
    </p>

    <p class="MsoNormal" style="text-align: justify">
      Ces données sont utilisées pour recontacter ou envoyer des communications
      (commerciales) à la personne concernée et seront conservées 5 ans après le
      dernier contact.
    </p>

    <p class="MsoNormal" style="text-align: justify">
      En application de la réglementation en vigueur relative à la protection
      des données, aux fichiers et aux libertés, vous disposez d’un droit
      d’accès, d’opposition et de rectification, de limitation du traitement et
      d’effacement des données vous concernant.
    </p>

    <p class="MsoNormal" style="text-align: justify">
      Dans le cadre du respect de ces droits, vous pouvez contacter la Déléguée
      à la Protection des données: dpd@sesan.fr - 01 48 78 72 42 – 6-8 rue
      Firmin Gillot 75015 Paris.
    </p>

    <p class="MsoNormal" style="text-align: justify">
      <span style="font-size: 18pt; line-height: 107%; color: #a7358b">
        Cookies
      </span>
    </p>

    <p class="MsoNormal" style="text-align: justify">
      Un cookie est un bloc de données qui ne permet pas d’identifier
      l’utilisateur mais sert à enregistrer des informations relatives à la
      navigation de celui-ci sur le site. Le paramétrage du logiciel de
      navigation permet de supprimer la présence de cookies.
    </p>

    <p class="MsoNormal" style="text-align: justify">
      L’utilisateur est informé que, lors de ses visites sur le site eCerveau
      (https://cerveau.sante-idf.fr, https://ppr-cerveau.gcsdsisif.fr), des
      cookies sont installés sur son logiciel de navigation (via ordinateur,
      mobile ou tablette).
    </p>

    <p class="MsoNormal" style="text-align: justify">
      Ces cookies permettent essentiellement&nbsp;:
    </p>

    <ul style="text-align: left">
      <li>
        d'afficher, lors de votre première visite, le bandeau signalant la
        présence de cookies et la faculté que vous avez de les accepter ou de
        les refuser,
      </li>
      <li>
        de permettre l’authentification des utilisateurs et la gestion de leur
        session,
      </li>
      <li>
        d'établir des mesures statistiques anonymes de fréquentation et
        d'utilisation du site.
      </li>
    </ul>
  </div>
</div>

<div *ngIf="isAraPage() == true">
  <div
    style="width: 1000px; margin-right: auto; margin-left: auto"
    class="mention"
  >
    <p class="MsoNormal" style="text-align: justify">
      <span style="font-size: 20pt; line-height: 107%; color: #0b7ec2"
        >Mentions légales</span
      >
    </p>

    <!-- Initialisation -->
    <p class="MsoNormal" style="text-align: justify">
      L’intégralité des conditions générales d’utilisation des services SISRA est
      consultable au lien suivant :
      <a
        href="https://urldefense.com/v3/__https://www.sante-ra.fr/AutoConnectSSO/Content/StaticPages/charte-ra.html__;!!FgWLK78r!LCSago6k6agkwKi6jCKugdh86gxN3PGFkYFaRqQwhb6s2DSBsgtQ5Qw1udc4wbDfB0BVmQ$"
        target="_blank"
        >www.sante-ra.fr/leportail/StaticPages/charte-ra.html</a
      ><br />
      L’utilisation de ce site Web constitue votre acceptation des conditions
      générales et mentions d’avertissement. Vous vous engagez à ne pas utiliser
      ce site à des fins illicites ou interdites par les lois ou par les
      mentions légales et contractuelles.<br />
      Directeur de publication : Thierry Durand, administrateur.
    </p>

    <p class="MsoNormal" style="text-align: justify">
      En application de la réglementation en vigueur relative à la protection des données, aux fichiers et aux libertés, vous disposez d’un droit d’accès, d’opposition et de rectification, de limitation du traitement et d’effacement des données vous concernant.
    </p>

    <p class="MsoNormal" style="text-align: justify">
      Dans le cadre du respect de ces droits, vous pouvez contacter la Déléguée
      à la Protection des données :
      <a href="mailto://dpd@sante-ara.fr">dpd@sante-ara.fr</a>
    </p>

    <p class="MsoNormal" style="text-align: justify">
      Dans le cadre d’un partenariat entre les régions Auvergne-Rhône-Alpes et
      Ile de France, le site eCerveau (<a
        href="https://cerveau.sante-ara.fr"
        >https://cerveau.sante-ara.fr</a
      >,
      <a
        href="https://ppr-ecerveau-ara.sesan.fr"
        >https://ppr-ecerveau-ara.sesan.fr</a
      >) est édité par le Groupement d'Intérêt Public service numérique
      de santé (ci-après SESAN), structure de droit privé régie par l’article
      L6133-1 du Code de la Santé Publique, sis 6-8 rue Firmin Gillot 75015
      PARIS (01 48 78 99 88 - Siret 513 654 715 000 46 -
      <a href="mailto:contact@sesan.fr">contact@sesan.fr</a> ), au sens de
      l’article 6 III de la loi n° 2004-575 du 21 juin 2004.
    </p>
    <!-- 	<ul style="text-align: left;">
		<li>
			Le directeur de la publication est Pierre BOIRON, Directeur du GCS SESAN (contact@sesan.fr)
		</li>
		<li>
			Le responsable de rédaction est Nabia MEBARKI, Directeur de projet (nabia.mebarki@sesan.fr)
		</li>
	</ul> -->

    <!-- PARTIE HEBERGEMENT -->
    <p class="MsoNormal" style="text-align: justify">
      <span style="font-size: 18pt; line-height: 107%; color: #0b7ec2">
        Hébergement
      </span>
    </p>

    <p class="MsoNormal" style="text-align: justify">
      La société Claranet SAS (18-20, rue du Faubourg du Temple 75011 Paris -
      RCS de Paris B 419 632 286) est hébergeur du site eCerveau (<a
        href="https://cerveau.sante-ara.fr"
        >https://cerveau.sante-ara.fr</a
      >,
      <a
        href="https://ppr-ecerveau-ara.sesan.fr"
        >https://ppr-ecerveau-ara.sesan.fr</a
      >) au sens de l’article 6 I 2 de la loi n° 2004-575 du 21 juin 2004 pour
      des informations de toute nature stockées dans le site par les
      utilisateurs.
    </p>

    <!-- PROPRIETE INTELLECTUELLE -->
    <p class="MsoNormal" style="text-align: justify">
      <span style="font-size: 18pt; line-height: 107%; color: #0b7ec2">
        Propriété intellectuelle
      </span>
    </p>

    <p class="MsoNormal" style="text-align: justify">
      SESAN est l’auteur du site eCerveau (<a
        href="https://cerveau.sante-ara.fr"
        >https://cerveau.sante-ara.fr</a
      >,
      <a
        href="https://ppr-ecerveau-ara.sesan.fr"
        >https://ppr-ecerveau-ara.sesan.fr</a
      >) au sens du Code de la propriété intellectuelle.
    </p>

    <!-- PARTIE CONCEPTION -->
    <p class="MsoNormal" style="text-align: justify">
      <span style="font-size: 18pt; line-height: 107%; color: #0b7ec2">
        Conception graphique et technique du site
      </span>
    </p>

    <div style="text-align: justify">Keyrus</div>

    <div style="text-align: justify">79 Bd de Stalingrad</div>

    <div style="text-align: justify">69100 Villeurbanne</div>

    <div style="text-align: justify">Tél: +33 (0)4 37 49 69 49</div>

    <div style="text-align: justify">SIRET : 400 149 647 00192</div>

    <!-- PARTIE COOKIES -->
    <p class="MsoNormal" style="text-align: justify">
      <span style="font-size: 18pt; line-height: 107%; color: #0b7ec2">
        Cookies
      </span>
    </p>

    <p class="MsoNormal" style="text-align: justify">
      Un cookie est un bloc de données qui ne permet pas d’identifier
      l’utilisateur mais sert à enregistrer des informations relatives à la
      navigation de celui-ci sur le site. Le paramétrage du logiciel de
      navigation permet de supprimer la présence de cookies.
    </p>

    <p class="MsoNormal" style="text-align: justify">
      L’utilisateur est informé que, lors de ses visites sur le site eCerveau
      (<a
        href="https://ppr-ecerveau-ara.sesan.fr"
        >https://ppr-ecerveau-ara.sesan.fr</a
      >,
      <a
        href="https://ppr-ecerveau-ara.sesan.fr"
        >https://ppr-ecerveau-ara.sesan.fr</a
      >), des cookies sont installés sur son logiciel de navigation (via
      ordinateur, mobile ou tablette).
    </p>

    <p class="MsoNormal" style="text-align: justify">
      Ces cookies permettent essentiellement :
    </p>

    <ul style="text-align: left">
      <li>
        d'afficher, lors de votre première visite, le bandeau signalant la
        présence de cookies et la faculté que vous avez de les accepter ou de
        les refuser,
      </li>
      <li>
        de permettre l’authentification des utilisateurs et la gestion de leur
        session,
      </li>
      <li>
        d'établir des mesures statistiques anonymes de fréquentation et
        d'utilisation du site.
      </li>
    </ul>
  </div>
</div>
