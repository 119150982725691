import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MobileEffectorVolumeEntry } from 'src/app/pdsa/model/activity/mobile-effector-volume-entry.model';
import { MobileEffectorVolumeEntryService } from 'src/app/pdsa/service/activity/mobile-effector-volume-entry.service';

import {
  MobileVolumeEntryClass,
  PeriodCodePdsa
} from '../../../model/activity/mobile-volume-entry.model';
import { ActivityFormValidator } from '../../../validators/activity-form-validators';
import { EventType } from 'src/app/pdsa/model/activity/effector-event-type.enum';
import { PdsaActivityParentFormComponent } from '../pdsa-activity-parent-form.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-pdsa-activity-tab-one',
  templateUrl: './pdsa-activity-tab-one.component.html',
  styleUrls: ['./pdsa-activity-tab-one.component.scss']
})
export class PdsaActivityTabOneComponent implements OnInit, OnChanges {
  @Output() onSubmitEvent: EventEmitter<any> = new EventEmitter();
  @Input() form!: FormGroup;
  @Input() mobileVolumeEntries?: MobileVolumeEntryClass[];
  mobileVolumeEntry: MobileEffectorVolumeEntry;
  @Output() formChange = new EventEmitter<FormGroup>();

  items = ['Exemple 1'];
  effectorId: number;
  effectorYearMonth: string;
  eventTypes = EventType;
  valueHasChanged: boolean = false;
  step: number = 1;

  mobileVolumeEntryForm: FormGroup = this.fb.group({
    openedAnsweredCallCount: [''],
    openedVisitCount: [''],
    closedAnsweredCallCount: [''],
    closedVisitCount: [''],
    entry: this.fb.group({
      yearMonth: [''],
      effector: this.fb.group({
        id: []
      })
    })
  });

  constructor(
    private fb: FormBuilder,
    private readonly route: ActivatedRoute,
    private mobileVolumeEntryService: MobileEffectorVolumeEntryService,
    private pdsaActivityParentFormComponent: PdsaActivityParentFormComponent
  ) {
    this.initForm();
  }

  ngOnInit() {
    this.getEffectorId()
      .then((effectorId) => {
        return effectorId;
      })
      .then((effectorId) => {
        return this.getEffectorYearMonth().then((effectorYearMonth) => {
          return { effectorId, effectorYearMonth };
        });
      })
      .then(({ effectorId, effectorYearMonth }) => {
        return this.getMobileVolumeEntry(effectorId, effectorYearMonth);
      })
      .then(() => {
        this.pdsaActivityParentFormComponent.activeTab = 1;
        this.mobileVolumeEntry.entry.step =
          this.pdsaActivityParentFormComponent.updateStep(this.step);
      })
      .catch((error) => {
        console.error(error);
      });
    this.formChange.emit(this.form);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initFormData();
  }

  initForm() {
    this.form = this.fb.group({
      pdsaType0: this.fb.group({
        nbOfCallsAnswered: [
          null,
          ActivityFormValidator.activityFormInputValidator()
        ],
        numberOfVisits: [
          null,
          ActivityFormValidator.activityFormInputValidator()
        ]
      }),
      pdsaType1: this.fb.group({
        nbOfCallsAnswered: [
          null,
          ActivityFormValidator.activityFormInputValidator()
        ],
        numberOfVisits: [
          null,
          ActivityFormValidator.activityFormInputValidator()
        ]
      })
    });
  }

  initFormData() {
    if (this.mobileVolumeEntries) {
      const pdsaType0 = this.mobileVolumeEntries.find(
        (m) => m.periodCodePdsa == PeriodCodePdsa.IN_PDSA_HOURS
      );
      const pdsaType1 = this.mobileVolumeEntries.find(
        (m) => m.periodCodePdsa == PeriodCodePdsa.OUT_PDSA_HOURS
      );
      this.form.get('pdsaType0')?.patchValue({
        nbOfCallsAnswered: pdsaType0?.nbOfCallsAnswered,
        numberOfVisits: pdsaType0?.numberOfVisits
      });
      this.form.get('pdsaType1')?.patchValue({
        nbOfCallsAnswered: pdsaType1?.nbOfCallsAnswered,
        numberOfVisits: pdsaType1?.numberOfVisits
      });
    }
  }

  getEffectorId(): Promise<number> {
    return new Promise((resolve, reject) => {
      this.route.url.subscribe((segments) => {
        if (segments.length > 0) {
          const lastSegment = segments[segments.length - 1].path;
          const effectorId: number = parseInt(lastSegment, 10);
          resolve(effectorId);
        } else {
          reject("Aucun segment trouvé dans l'URL.");
        }
      });
    });
  }

  getEffectorYearMonth(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.route.url.subscribe((segments) => {
        if (segments.length > 0) {
          const lastSegment = segments[segments.length - 2].path;
          let position = lastSegment.length - 2;
          const effectorYearMonth =
            lastSegment.slice(0, position) + '-' + lastSegment.slice(position);
          resolve(effectorYearMonth);
        } else {
          reject("Aucun segment trouvé dans l'URL.");
        }
      });
    });
  }

  getMobileVolumeEntry(
    id: number = this.effectorId,
    yearMonth: string = this.effectorYearMonth
  ): Promise<MobileEffectorVolumeEntry> {
    return new Promise((resolve, reject) => {
      this.mobileVolumeEntryService
        .getMobileVolumeEntry(id, yearMonth)
        .subscribe((selectedMobileVolumeEntry) => {
          this.mobileVolumeEntry = selectedMobileVolumeEntry;
          this.mobileVolumeEntryForm.patchValue(this.mobileVolumeEntry);
          resolve(this.mobileVolumeEntry);
        });
    });
  }

  setMobileVolumeEntry(
    id: number = this.effectorId,
    yearMonth: string = this.effectorYearMonth,
    mobileVolumeEntry
  ) {
    this.mobileVolumeEntry.openedAnsweredCallCount =
      this.mobileVolumeEntryForm.get(['openedAnsweredCallCount'])?.value;
    this.mobileVolumeEntry.closedAnsweredCallCount =
      this.mobileVolumeEntryForm.get(['closedAnsweredCallCount'])?.value;
    this.mobileVolumeEntry.openedVisitCount = this.mobileVolumeEntryForm.get([
      'openedVisitCount'
    ])?.value;
    this.mobileVolumeEntry.closedVisitCount = this.mobileVolumeEntryForm.get([
      'closedVisitCount'
    ])?.value;

    this.mobileVolumeEntryService
      .setMobileVolumeEntry(id, yearMonth, mobileVolumeEntry)
      .subscribe((response) => {});
  }

  onInputChange() {
    this.valueHasChanged = true;
  }

  async onSubmit(event: EventType) {
    if (event === EventType.NAVIGATE_TO_TAB && this.valueHasChanged) {
      Swal.fire({
        title: 'Vos dernières modifications ont été enregistrées !',
        icon: 'success',
        confirmButtonColor: '#2B8CA9',
        confirmButtonText: 'OK'
      });
    }

    const elementsArray = this.mobileVolumeEntryForm as unknown as FormArray;

    const { hasInvalidValue, hasEmptyValue, hasValue } =
      this.pdsaActivityParentFormComponent.handleError(elementsArray, [
        'openedAnsweredCallCount',
        'openedVisitCount',
        'closedAnsweredCallCount',
        'closedVisitCount'
      ]);

    if (hasInvalidValue) {
      this.pdsaActivityParentFormComponent.handleInvalidValueMessage(event);
      return;
    }

    if (hasEmptyValue && event !== EventType.NAVIGATE_TO_TAB) {
      const result = await Swal.fire({
        title:
          "Certaines données n'ont pas été saisies. Etes-vous sûr de vouloir continuer ?",
        icon: 'info',
        showDenyButton: true,
        confirmButtonColor: '#56CC9D',
        confirmButtonText: 'Oui',
        denyButtonText: 'Non',
        denyButtonColor: '#E0796A',
        didClose: () => {
          window.scrollTo(0, 0);
        }
      });
      if (result.isDenied) {
        return;
      }
    }

    this.pdsaActivityParentFormComponent.hasValuesEnteredOne = hasValue;

    if (
      hasValue ||
      this.pdsaActivityParentFormComponent.lastSavedStep >= this.step
    ) {
      await this.getEffectorId()
        .then((effectorId) => {
          return effectorId;
        })
        .then((effectorId) => {
          return this.getEffectorYearMonth().then((effectorYearMonth) => {
            return { effectorId, effectorYearMonth };
          });
        })
        .then(({ effectorId, effectorYearMonth }) => {
          this.setMobileVolumeEntry(
            effectorId,
            effectorYearMonth,
            this.mobileVolumeEntry
          );
          this.pdsaActivityParentFormComponent.lastSavedStep = Math.max(
            this.mobileVolumeEntry.entry.step,
            this.pdsaActivityParentFormComponent.lastSavedStep
          );
        })
        .catch((error) => {
          console.error(error);
        });
    }

    const submitEvent = await this.pdsaActivityParentFormComponent.afterSubmit(
      event
    );

    if (submitEvent) {
      this.onSubmitEvent.emit();
    }
    return;
  }
}
