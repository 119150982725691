import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class LoginService {

  login(): void {
    const currentLocation = location.pathname + location.search + location.hash;
    location.href = '/api/login?post-login-redirect=' + encodeURIComponent(currentLocation);
  }

  logout(): void {
    location.href = '/api/logout';
  }
}
