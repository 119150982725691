    <div class="container-mes-urgences" style="background-color: white;" *ngIf="account?.authorities.includes('19')">  
      <div  class="div-alert">
        <alert class="alert"></alert>
      </div>
      <!-- <iframe width="100%" height="100%" frameBorder="0" [src]="urlSafe"></iframe> -->
      <iframe
        *ngIf="isPreprod() == false"
        width="100%"
        frameBorder="0"
        src="https://mesurgences-gp.sante-idf.fr"
        scrolling="yes"
      ></iframe>
      <iframe
      *ngIf="isPreprod() == true"
      width="100%"
      frameBorder="0"
      src="https://ppr-mesurgencesgp-predictif.sesan.fr"
      scrolling="yes"
      ></iframe>
    </div>

    <div class="accessdenied" *ngIf="isDisplayed && !account?.authorities.includes('19')">
      <app-error-page></app-error-page>
    </div>
