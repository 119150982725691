import {
  Component,
  EventEmitter,
  Output,
  ViewChild,
  Input,
  HostListener,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectComponent } from "@ng-select/ng-select";
import { Observable, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import { EmergencyDept } from "src/app/emergency-depts/model/emergency-dept.model";
import { EmergencyDeptService } from "src/app/emergency-depts/services/emergency-dept.service";
import { GraphFilter } from "../../models/graph-filter.model";
import { SizeGroup } from "../../models/size-group.model";
import { GraphService } from "../../services/graph.service";
import { PeriodService } from "../../services/period-service";
import { ModalFilterComponent } from "../modal-filter/modal-filter.component";

type LibserUrg = {
  id: number;
  name: string;
};

@Component({
  selector: "app-graph-filter",
  templateUrl: "./graph-filter.component.html",
  styleUrls: ["./graph-filter.component.scss"],
})
export class GraphFilterComponent {
  @Output()
  doFilter = new EventEmitter<GraphFilter>();
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;

  sizeGroups: SizeGroup[] = [];
  departmentCodes: string[] = [];
  graphFilter = new GraphFilter();
  text = "";
  fillInput = new Subject<string>();
  emergencyDepts: EmergencyDept[] = [];
  emergencyDeptsForNgSelect: Observable<EmergencyDept[]>;
  selectedLibSerUrg: EmergencyDept[];
  @Input() needsComparison: boolean;
  @Input() graphIndicateurCharge: boolean;
  @Input() isLoading: boolean;
  innerWidth: number;
  constructor(
    private modalService: NgbModal,
    private emergencyDeptService: EmergencyDeptService,
    private graphService: GraphService,
    private periodService: PeriodService
  ) {
    this.innerWidth = window.innerWidth;
    this.graphFilter.period1 = this.periodService.computePeriod1();
    this.graphFilter.period2 = this.periodService.computePeriod2(
      this.graphFilter.period1
    );

    this.fillInput
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((searchValue) => {
        if (searchValue === "") {
          this.emergencyDepts = [];
          return;
        }
        this.emergencyDeptService
          .getEmergencyBySearch(searchValue, "84")
          .subscribe((emergencyDepts) => {
            this.emergencyDepts = emergencyDepts.content;
          });
      });
  }

  @HostListener('window:resize', ['$event'])
onResize(event) {
  this.innerWidth = window.innerWidth;
}

  ngOnInit(): void {
    this.graphService.getSizesOfSau().subscribe(
      (sizeGroups) => {
        if(this.isAraPage()) {
          this.sizeGroups = sizeGroups.filter(
            (sizeGroup) => +sizeGroup.id >= 1 && +sizeGroup.id < 5
          );
        } else {
          this.sizeGroups = sizeGroups.filter(
            (sizeGroup) => +sizeGroup.id >= 5 && +sizeGroup.id < 9
          );
        }
      },
      (error) => {
        console.error(error);
      }
    );

    this.graphService.getDepartementCodes().subscribe(
      (departmentCodes) => {
        this.departmentCodes = departmentCodes;
      },
      (error) => {
        console.error(error);
      },
      () => {
        if (this.graphIndicateurCharge) {
          this.filterModalOpen();
        }
      }
    );

    if (this.graphIndicateurCharge != true) {
      this.doFilter.emit(this.graphFilter);
    }
    this.getEmergencyDepts();
    console.log("taille écran:", window.innerWidth, window.innerHeight);

  }

  filterModalOpen(): void {
    const modalRef = this.modalService.open(ModalFilterComponent, {
      size: "xl",
    });
    const instance: ModalFilterComponent = modalRef.componentInstance;
    instance.sizeGroups = this.sizeGroups;
    instance.departmentCodes = this.departmentCodes;
    instance.graphFilter = this.graphFilter;
    instance.needsComparison = this.needsComparison;
    instance.graphIndicateurCharge = this.graphIndicateurCharge;

    modalRef.closed.subscribe((graphFilter: GraphFilter) => {
      this.graphFilter = graphFilter;
      const selectedAbrSerUrgName = this.emergencyDepts.find(el => el.code === this.graphFilter.compareEmergencyDeptId?.toString().split('-')[0])?.abrSerUrg;
      this.graphFilter.selectedAbrSerUrgName = selectedAbrSerUrgName
      this.doFilter.emit(this.graphFilter);
    });
  }

  removeSizeGroup(sizeGroup: SizeGroup): void {
    const sizeGroups = this.graphFilter.sizeGroups.filter(
      (s) => s.id !== sizeGroup.id
    );
    this.updateEmergencyDepts(sizeGroups, this.graphFilter.departementCodes);
  }

  removeDepartementCode(departementCode: string): void {
    const departementCodes = this.graphFilter.departementCodes.filter(
      (d) => d !== departementCode
    );
    this.updateEmergencyDepts(this.graphFilter.sizeGroups, departementCodes);
  }

  private updateEmergencyDepts(
    sizeGroups: SizeGroup[],
    departmentCodes: string[]
  ) {
    const sizeGroupIds = sizeGroups.map((sizeGroup) => sizeGroup.id);
    const emergencyDeptIds = new Set(
      this.graphFilter.emergencyDepts.map((emergencyDept) => emergencyDept.id)
    );

    this.graphService
      .getEmergencyDepts(
        sizeGroupIds.length !== 0 ? sizeGroupIds : undefined,
        departmentCodes.length !== 0 ? departmentCodes : undefined
      )
      .subscribe(
        (emergencyDepts) => {
          console.log(emergencyDepts);
          this.graphFilter.sizeGroups = sizeGroups;
          this.graphFilter.departementCodes = departmentCodes;
          this.graphFilter.emergencyDepts =
            sizeGroups.length > 0 || departmentCodes.length > 0
              ? emergencyDepts.filter((emergencyDept) =>
                  emergencyDeptIds.has(emergencyDept.id)
                )
              : [];
          this.doFilter.emit(this.graphFilter);
        },
        (error) => {
          console.error(error);
        }
      );
  }

  removeEmergencyDept(emergencyDept: EmergencyDept) {
    this.graphFilter.emergencyDepts = this.graphFilter.emergencyDepts.filter(
      (e) => e.id !== emergencyDept.id
    );
    this.doFilter.emit(this.graphFilter);
    this.emergencyDepts.push(emergencyDept);
  }

  getEmergencyDepts(): void {
    this.emergencyDeptService
      .getAllEmergencyDeptsByRegionSortFinessLibSerUrg(this.isAraPage() ? 84 : 11)
      .subscribe((emergencydepts) => {
        this.emergencyDepts = emergencydepts;
      });
      console.log("je passe ici")
  }

  addSAU(event: EmergencyDept) {
    console.log(this.graphFilter.emergencyDepts);

    if (event != undefined) {
      let emergencyDeptIsTwice = this.graphFilter.emergencyDepts.filter(
        (element) => element.id === event.id
      ).length;

      this.graphFilter.emergencyDepts.push(event);

      if (
        this.graphIndicateurCharge &&
        this.graphFilter.emergencyDepts.length > 1
      ) {
        this.graphFilter.emergencyDepts.splice(0, 1);
      }

      const emergencyDepts = new Set(this.graphFilter.emergencyDepts);
      this.graphFilter.emergencyDepts = Array.from(emergencyDepts);
      if (emergencyDeptIsTwice == 0) {
        this.doFilter.emit(this.graphFilter);
      }
    }
  }

  removeSelectedSau() {
    this.ngSelectComponent.handleClearClick();
  }

  clearFilters() {
    this.graphFilter.emergencyDepts = [];
    this.graphFilter.sizeGroups = [];
    this.graphFilter.departementCodes = [];
    this.doFilter.emit(this.graphFilter);
  }

  isAraPage() {
    const str = window.location.href;
    return str.includes("ara") || str.includes(":4201/");
  }

  // hello(event) {
  //   this.emergencyDepts.map((element, index) => {
  //     if (event.abrSerUrg === element.abrSerUrg) {
  //       this.emergencyDepts.splice(index, 1);
  //     }
  //   });
  // }
}
