import {
  Component,
  HostListener,
  Input,
  NgModule,
  OnInit,
  Pipe,
  PipeTransform,
  ViewChild
} from '@angular/core';
import { Account } from '../core/auth/account.model';
import { AccountService } from '../core/auth/account.service';
import {
  NgbModal,
  ModalDismissReasons,
  NgbAlert
} from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { RapportActivite } from '../emergency-depts/model/rapport-activite.model';
import { RapportActiviteService } from '../emergency-depts/services/rapport-activite.service';
import { HttpClient } from '@angular/common/http';
import { IndicateurSamuService } from './services/indicateur-samu.service';
import { IndicateurSamu } from './model/indicateur-samu.model';
import { IndicateurRpuService } from './services/indicateur-rpu.service';
import { IndicateurRpu } from './model/indicateur-rpu.model';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { HomeFormContactComponent } from './home-form-contact/home-form-contact.component';
import { PresenceEmergencyService } from './services/presence-emergency.service';
import { catchError, debounceTime, endWith } from 'rxjs/operators';
import { AlertService } from 'src/app/emergency-depts/services/alert.service';
import { Alert } from 'src/app/emergency-depts/model/alert.model';
import { Title } from '@angular/platform-browser';
import { of, Subject } from 'rxjs';

@Pipe({
  name: 'formatNumber'
})
export class FormatNumberPipe implements PipeTransform {
  transform(value: number | string, ...args: unknown[]): string {
    let v = String(value);
    v = v.replace(/[^\d.]/g, '');
    v = v.replace(/\./g, ',');
    let parts = v.split(',');
    let result = parts[0].replace(/(?!^)(?=(?:\d{3})+$)/g, ' ');

    if (parts[1]) {
      const glueSymbol = args[0] ? args[0] : ',';
      result += glueSymbol + parts[1];
    }

    return result;
  }
}

@Component({
  selector: 'app-home-page-idf',
  templateUrl: './home-page-idf.component.html',
  styleUrls: ['./home-page-idf.component.scss']
})
export class HomePageIdfComponent implements OnInit {
  isPreprod() {
    const str = window.location.href;
    return str.includes('ppr');
  }

  today = new Date();
  public rapportNames: string[] = [];
  public filePdfName: RapportActivite[];
  public indicateurSamu: IndicateurSamu[] = [];
  public indicateurRpu: IndicateurRpu[] = [];
  public percentIndCon: number;
  public percentIndDos: number;
  public percentIndUmh: number;
  public selectedIndicateurSamu: IndicateurSamu[] = [];
  public percentNbrPas: number;
  public percentNbrPasPed: number;
  public percentNbrPasHosp: number;
  public percentSauTrs: number;
  alerts: Alert[] = [];
  alertNumber: number;

  mailContact = this.fb.group({
    name: ['', Validators.required],
    lastName: ['', Validators.required],
    tel: [],
    etab: [''],
    fonction: [''],
    details: ['']
  });

  closeResult: any;
  exist: boolean;
  account: Account | null = null;
  innerWidth: number;
  nbrPas: number | null | undefined;
  scrolled = 0;
  presenceEmergency: Number;
  anchor: string;
  errorServeur: boolean = false;

  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    const numb = window.scrollY;
    if (numb >= 50) {
      this.scrolled = 1;
    } else {
      this.scrolled = 0;
    }
  }

  constructor(
    private fb: FormBuilder,
    private accountService: AccountService,
    private modalService: NgbModal,
    private router: Router,
    private viewportScroller: ViewportScroller,
    private http: HttpClient,
    private rapportActiviteService: RapportActiviteService,
    private indicateurSamuService: IndicateurSamuService,
    private indicateurRpuService: IndicateurRpuService,
    private presenceEmergencyService: PresenceEmergencyService,
    private alertService: AlertService,
    private readonly titleService: Title,
    private readonly route: ActivatedRoute
  ) {
    this.innerWidth = window.innerWidth;
    this.accountService.identity().subscribe((account) => {
      this.account = account;
    });
    this.accountService.exists().subscribe((exist) => {
      this.exist = exist;
      console.log('EXISTE:', exist);
    });
    console.log('taille écran:', window.innerWidth, window.innerHeight);
  }

  private _success = new Subject<string>();

  staticAlertClosed = false;
  successMessage = '';

  @ViewChild('staticAlert', { static: false }) staticAlert: NgbAlert;
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert;

  ngOnInit() {
    setTimeout(() => this.staticAlert.close(), 20000);

    this._success.subscribe((message) => (this.successMessage = message));
    this._success.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
      }
    });
    console.log('su');

    this.titleService.setTitle(this.route.snapshot.data['title']);
    this.getPresence();
    console.log('presence undefined:', this.isPresenceEmergencyUndefined());
    this.indicateurRpuService.getAllByIdJou().subscribe((indicateurRpu) => {
      this.indicateurRpu = indicateurRpu;
      console.log(indicateurRpu[0]?.nbrPas);
      this.percentNbrPas = Math.round(
        ((indicateurRpu[0]?.nbrPas - indicateurRpu[1]?.nbrPas) /
          indicateurRpu[1]?.nbrPas) *
          100
      );
      this.percentNbrPasPed = Math.round(
        ((indicateurRpu[0]?.nbrPasPed - indicateurRpu[1]?.nbrPasPed) /
          indicateurRpu[1]?.nbrPasPed) *
          100
      );
      this.percentNbrPasHosp = Math.round(
        ((indicateurRpu[0]?.nbrPasHosp - indicateurRpu[1]?.nbrPasHosp) /
          indicateurRpu[1]?.nbrPasHosp) *
          100
      );
      this.percentSauTrs = Math.round(
        ((indicateurRpu[0]?.nbrSauTrs - indicateurRpu[1]?.nbrSauTrs) /
          indicateurRpu[1]?.nbrSauTrs) *
          100
      );
    });

    this.indicateurSamuService.getAllByIdJou().subscribe((indicateurSamu) => {
      this.indicateurSamu = indicateurSamu;
      this.percentIndCon = Math.round(
        ((indicateurSamu[0]?.indCon - indicateurSamu[1]?.indCon) /
          indicateurSamu[1]?.indCon) *
          100
      );
      this.percentIndDos = Math.round(
        ((indicateurSamu[0]?.indDos - indicateurSamu[1]?.indDos) /
          indicateurSamu[1]?.indDos) *
          100
      );
      this.percentIndUmh = Math.round(
        ((indicateurSamu[0]?.indUmh - indicateurSamu[1]?.indUmh) /
          indicateurSamu[1]?.indUmh) *
          100
      );
    });

    console.log('percentIndCon', this.percentIndCon);
    console.log('percentIndDos', this.percentIndDos);
    console.log('percentIndUmh', this.percentIndUmh);
    console.log('isNbrPasDBeforeUndefined()', this.isNbrPasDBeforeUndefined());
    console.log('isPercentNbrPasInfiny()', this.isPercentNbrPasInfiny());

    this.rapportActiviteService
      .getRapports()
      .subscribe(
        (data) => (
          (this.filePdfName = data.sort((a, b) =>
            b.name.trim().localeCompare(a.name.trim())
          )),
          data.forEach((element) =>
            this.rapportNames.push(
              element.name
                .replaceAll('_', ' ')
                .replaceAll('-', "'")
                .replace('e', 'é')
                .replace('.pdf', '')
            )
          )
        )
      );
    //this.title.setTitle("eCerveau");
    const Reg = this.isAraPage() ? 84 : 11;
    this.alertService
      .getAlertsByPageAlert(Reg, 'Accueil')
      .subscribe((res: Alert[]) => {
        this.alerts = res;

        res.map((alert) => this.alertService.get(alert));
      });

    console.log('alertNumber', this.alertNumber);
  }

  public changeSuccessMessage() {
    this._success.next(`${new Date()} - Message successfully changed.`);
  }

  mouseEnter(anchor: string) {
    this.anchor = anchor;

    console.log('mouse enter : ' + this.anchor);
  }

  getPresence() {
    console.error('JE SUIS LES PRESENCES:', this.presenceEmergency);
    this.presenceEmergencyService.getPresence().subscribe(
      (data) => {
        console.log('JE SUIS LES PRESENCES:', data);
        this.presenceEmergency = data;
      },
      (error) => {
        this.errorServeur = true;
        console.error('error', error.message);
      },
      () => console.log('done')
    );
  }

  isAlert() {
    if (this.alerts.length != 0) {
      return true;
    } else {
      return false;
    }
  }

  isAraPage() {
    const str = window.location.href;
    return str.includes('ara') || str.includes(':4201/');
  }

  isPercentNbrPasNegative() {
    if (this.percentNbrPas > 0) {
      return false;
    } else {
      return true;
    }
  }

  isPercentNbrPasInfiny() {
    if (this.percentNbrPas === Infinity) {
      return true;
    } else {
      return false;
    }
  }

  isPresenceEmergencyUndefined() {
    if (this.presenceEmergency) {
      return false;
    } else {
      return true;
    }
  }

  isNbrPasUndefined(): boolean {
    if (this.indicateurRpu[0]?.nbrPas) {
      return false;
    } else {
      return true;
    }
  }

  isNbrPasDBeforeUndefined(): boolean {
    if (this.indicateurRpu[1]?.nbrPas) {
      return false;
    } else {
      return true;
    }
  }

  // isNbrPasNotZero(): boolean {
  //   if (this.indicateurRpu[0]?.nbrPas )
  // }

  isPercentNbrPasPedNegative() {
    if (this.percentNbrPasPed > 0) {
      return false;
    } else {
      return true;
    }
  }

  isPercentNbrPasHospInfiny() {
    if (this.percentNbrPasHosp === Infinity) {
      return true;
    } else {
      return false;
    }
  }

  isNbrPasPedUndefined(): boolean {
    if (this.indicateurRpu[0]?.nbrPasPed) {
      return false;
    } else {
      return true;
    }
  }

  isNbrPasPedDBeforeUndefined(): boolean {
    if (this.indicateurRpu[1]?.nbrPasPed) {
      return false;
    } else {
      return true;
    }
  }

  isPercentNbrPasHospNegative() {
    if (this.percentNbrPasHosp > 0) {
      return false;
    } else {
      return true;
    }
  }

  isPercentNbrPasPedInfiny() {
    if (this.percentNbrPasPed === Infinity) {
      return true;
    } else {
      return false;
    }
  }

  isNbrPasHospUndefined(): boolean {
    if (this.indicateurRpu[0]?.nbrPasHosp) {
      return false;
    } else {
      return true;
    }
  }

  isNbrPasHospDBeforeUndefined(): boolean {
    if (this.indicateurRpu[1]?.nbrPasHosp) {
      return false;
    } else {
      return true;
    }
  }

  isPercentIndConNegative() {
    if (this.percentIndCon >= 0) {
      return false;
    } else {
      return true;
    }
  }

  isPercentIndConInfiny() {
    if (this.percentIndCon === Infinity) {
      return true;
    } else {
      return false;
    }
  }

  isIndConUndefined(): boolean {
    if (this.indicateurSamu[0]?.indCon) {
      return false;
    } else {
      return true;
    }
  }

  isIndConDBeforeUndefined(): boolean {
    if (this.indicateurSamu[1]?.indCon) {
      return false;
    } else {
      return true;
    }
  }

  isPercentIndDosNegative() {
    if (this.percentIndDos > 0) {
      return false;
    } else {
      return true;
    }
  }

  isPercentIndDosInfiny() {
    if (this.percentIndDos === Infinity) {
      return true;
    } else {
      return false;
    }
  }

  isIndDosUndefined(): boolean {
    if (this.indicateurSamu[0]?.indDos) {
      return false;
    } else {
      return true;
    }
  }

  isIndDosDBeforeUndefined(): boolean {
    if (this.indicateurSamu[1]?.indDos) {
      return false;
    } else {
      return true;
    }
  }

  isPercentIndUmhNegative() {
    if (this.percentIndUmh > 0) {
      return false;
    } else {
      return true;
    }
  }

  isPercentIndUmhInfiny() {
    if (this.percentIndUmh === Infinity) {
      return true;
    } else {
      return false;
    }
  }

  isIndUmhUndefined(): boolean {
    if (this.indicateurSamu[0]?.indUmh) {
      return false;
    } else {
      return true;
    }
  }

  isIndUmhDBeforeUndefined(): boolean {
    if (this.indicateurSamu[1]?.indUmh) {
      return false;
    } else {
      return true;
    }
  }

  isPercentSauTrsNegative() {
    if (this.percentSauTrs >= 0) {
      return false;
    } else {
      return true;
    }
  }

  isPercentSauTrsInfiny() {
    if (this.percentSauTrs === Infinity) {
      return true;
    } else {
      return false;
    }
  }

  isNbrSauTrsUndefined(): boolean {
    if (this.indicateurRpu[0]?.nbrSauTrs) {
      return false;
    } else {
      return true;
    }
  }

  isNbrSauTrsDBeforeUndefined(): boolean {
    if (this.indicateurRpu[1]?.nbrSauTrs) {
      return false;
    } else {
      return true;
    }
  }

  openFormContact() {
    this.modalService
      .open(HomeFormContactComponent, { size: 'lg' })
      .result.then(
        (result) => {
          console.log(result, 'result');
          this.closeResult = result;
          if (this.closeResult === true) {
            this._success.next('Demande envoyée avec succès');
          }
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    // this.pageIs = true;
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  public buttonClick(fragment: string): void {
    this.router.navigateByUrl('#' + fragment);
  }

  public onClick(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }

  goToBottom() {
    window.scrollTo(0, document.body.scrollHeight);
  }

  getAlertNumber(alertNumber) {
    this.alertNumber = alertNumber;
  }
}
