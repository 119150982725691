import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Page } from "src/app/shared/util/page.model";
import { EmergencyDept } from "../model/emergency-dept.model";
import { ParamOfEmergencyTable } from "../model/paramOfEmergencyTable.model";

@Injectable({
  providedIn: "root",
})
export class EmergencyDeptService {
  paramOfEmergencyTable: ParamOfEmergencyTable = new ParamOfEmergencyTable();
  constructor(private httpClient: HttpClient) {}

  public getEmergencyDepts(
    regionId?: string,
    pageNumber?: string,
    search?: string
  ): Observable<Page<EmergencyDept>> {
      return this.httpClient.get<Page<EmergencyDept>>(`/api/emergency-depts`, {
        params: {
          size: this.paramOfEmergencyTable.size,
          ...(regionId && {regionId: regionId}),
          ...(search && {libSerUrg: search!.toUpperCase(),
           finess: search!.toUpperCase(),}),
          ...(pageNumber && { page: pageNumber})
        },
      });
  }
  public getEmergencyBySearch(
    search: string,
    regionId?: string
  ): Observable<Page<EmergencyDept>> {
    if (regionId) {
      console.log("hello", regionId);
      const searchUpperCase = search.toUpperCase();
      console.log("searchUpperCase:", searchUpperCase);
      return this.httpClient.get<Page<EmergencyDept>>(`/api/emergency-depts`, {
        params: {
          regionId: regionId,
          libSerUrg: searchUpperCase,
          finess: searchUpperCase,
        },
      });
    } else {
      const searchUpperCase = search.toUpperCase();
      console.log("salut", search.toUpperCase())
      return this.httpClient.get<Page<EmergencyDept>>(`/api/emergency-depts`, {
        params: {
          libSerUrg: searchUpperCase,
          finess: searchUpperCase,
        },
      });
    }
  }

  public getEmergencyByDepts(
    regionId: string,
    headerTitle: string
  ): Observable<Page<EmergencyDept>> {
    const title = headerTitle.split(",", 1);
    if (this.paramOfEmergencyTable.sortBy == title + "desc") {
      this.paramOfEmergencyTable.sortBy = headerTitle;
      return this.httpClient.get<Page<EmergencyDept>>(`/api/emergency-depts`, {
        params: {
          size: this.paramOfEmergencyTable.size,
          page: this.paramOfEmergencyTable.page,
          sort: this.paramOfEmergencyTable.sortBy,
          regionId: regionId,
        },
      });
    } else {
      this.paramOfEmergencyTable.sortBy = headerTitle;
      return this.httpClient.get<Page<EmergencyDept>>(`/api/emergency-depts`, {
        params: {
          size: this.paramOfEmergencyTable.size,
          page: this.paramOfEmergencyTable.page,
          sort: this.paramOfEmergencyTable.sortBy,
          regionId: regionId,
        },
      });
    }
  }

  public getElementOfEmergencyDepts(
    sizeOfElement: string,
    regionId: string
  ): Observable<Page<EmergencyDept>> {
    this.paramOfEmergencyTable.size = sizeOfElement;
    return this.httpClient.get<Page<EmergencyDept>>(`/api/emergency-depts`, {
      params: {
        size: this.paramOfEmergencyTable.size,
        page: this.paramOfEmergencyTable.page,
        sortBy: this.paramOfEmergencyTable.sortBy,
        regionId: regionId,
      },
    });
  }

  public getEmergencyDept(
    code: string,
    type: number
  ): Observable<EmergencyDept> {
    return this.httpClient.get<EmergencyDept>(
      `/api/emergency-depts/service/${code}-${type}`
    );
  }

  //TO DO : DELETE ?
  public getEmergencyDeptForGraph(id): Observable<EmergencyDept> {
    return this.httpClient.get<EmergencyDept>(
      `/api/emergency-depts/${id}`
    );
  }

  //OK! 17092023
  public setEmergencyDept(
    emergencyDept: EmergencyDept
  ): Observable<EmergencyDept> {
    console.log("EMERGENCY ID", emergencyDept.id)
    if (emergencyDept.id) {
      return this.httpClient.put<EmergencyDept>(
        `api/emergency-depts`, 
        emergencyDept
        );
    }
    else {
      return this.httpClient.post<EmergencyDept>(
        `/api/emergency-depts`,
        emergencyDept
      );
    }
    
  }

  // TO DELETE
  public getAllEmergencyDept() {
    return this.httpClient.get<Array<EmergencyDept>>(
      `/api/get-all-emergency-depts`
    );
  }

  //OK
  public getAllEmergencyDeptByRegion(
    regionId: string
  ): Observable<Page<EmergencyDept>> {
    return this.httpClient.get<Page<EmergencyDept>>(`/api/emergency-depts`, {
      params: { regionId, size: 400 },
    });
  }

  //KO
  public getAllEmergencyDeptsByRegionSortFinessLibSerUrg(
    regionId
  ): Observable<EmergencyDept[]> {
    console.log("appel", regionId)
    return this.httpClient.get<EmergencyDept[]>(
      `/api/emergency-depts/sort-finess-libserUrg/${regionId}`
    );
  }
  
}
