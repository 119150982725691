import './styles.css';

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import Swal from 'sweetalert2';

import { EffectorActivityClass } from '../../model/activity/effector-activity.model';
import { MobileEffectorVolumeEntry } from '../../model/activity/mobile-effector-volume-entry.model';
import { MobileEffectorVolumeEntryService } from '../../service/activity/mobile-effector-volume-entry.service';
import { EffectorActivityService } from '../../service/effector-activity.service';
import { PdsaActivityTabOneComponent } from './pdsa-activity-tab-one/pdsa-activity-tab-one.component';
import { PdsaPosteActivityService } from '../pdsa-poste-activity-form/pdsa-poste-activity-service';
import { PdsaActivityTabThreeComponent } from './pdsa-activity-tab-three/pdsa-activity-tab-three.component';
import { PdsaActivityTabFiveComponent } from './pdsa-activity-tab-five/pdsa-activity-tab-five.component';
import { PdsaActivityTabFourComponent } from './pdsa-activity-tab-four/pdsa-activity-tab-four.component';
import { PdsaActivityTabTwoComponent } from './pdsa-activity-tab-two/pdsa-activity-tab-two.component';
import { PdsaActivityTabSixComponent } from './pdsa-activity-tab-six/pdsa-activity-tab-six.component';
import { EffectorService } from '../../service/effector.service';
import { EventType } from '../../model/activity/effector-event-type.enum';
import { PdsaActivityTabSevenComponent } from './pdsa-activity-tab-seven/pdsa-activity-tab-seven.component';
import { State } from '../../model/activity/effector-state.enum';
import { MobileVolumeEntry } from '../../model/activity/mobile-volume-entry.model';
import { MobileTerritoryVisitRange } from '../../model/activity/mobile-territory-visit-range.model';
import { MobileCrraVisitRange } from '../../model/activity/mobile-crra-visit-range.model';
import { MobileDeathVisitRange } from '../../model/activity/mobile-death-visit-range.model';
import { MobileCallRange } from '../../model/activity/mobile-call-range.model';
import { MobileDecisionTypeRange } from '../../model/activity/mobile-decision-type-range.model';
import * as html2pdf from 'html2pdf.js';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import jsPDF, { HTMLOptions, jsPDFOptions } from 'jspdf';
@Component({
  selector: 'app-pdsa-activity-form',
  templateUrl: './pdsa-activity-parent-form.component.html',
  styleUrls: ['./pdsa-activity-parent-form.component.scss'],
  providers: [PdsaPosteActivityService]
})
export class PdsaActivityParentFormComponent implements OnInit {
  @ViewChild(PdsaActivityTabOneComponent)
  firstChildComponentRef: PdsaActivityTabOneComponent;
  @ViewChild(PdsaActivityTabTwoComponent)
  secondChildComponentRef: PdsaActivityTabTwoComponent;
  @ViewChild(PdsaActivityTabThreeComponent)
  thirdChildComponentRef: PdsaActivityTabThreeComponent;
  @ViewChild(PdsaActivityTabFourComponent)
  fourthChildComponentRef: PdsaActivityTabFourComponent;
  @ViewChild(PdsaActivityTabFiveComponent)
  fifthChildComponentRef: PdsaActivityTabFiveComponent;
  @ViewChild(PdsaActivityTabSixComponent)
  sixhChildComponentRef: PdsaActivityTabSixComponent;
  @ViewChild(PdsaActivityTabSevenComponent)
  seventhChildComponentRef: PdsaActivityTabSevenComponent;

  mobileEffectorVolumeEntry: MobileEffectorVolumeEntry;
  mobileVolumeEntry: MobileVolumeEntry;
  effectorShortLabel: string;
  effectorDate: string;
  effectorId: number;
  effectorYearMonth: string;
  childComponentRef: any;
  isBooleanPlatform: boolean;
  hasValuesEnteredOne: boolean = false;
  hasValuesEnteredTwo: boolean = false;
  hasValuesEnteredThree: boolean = false;
  hasValuesEnteredFour: boolean = false;
  hasValuesEnteredFive: boolean = false;
  hasValuesEnteredSix: boolean = false;
  eventTypes = EventType;
  activeTab: number = 1;
  effectorActivity?: EffectorActivityClass;
  formDate?: Date;
  hasCommentChanged: boolean = false;
  lastSavedStep: number = 0;
  loading: boolean = false;

  disableButton: boolean = true;
  tooltipMessage: string = '';
  cursorStyle: string = 'pointer';
  colorStyle: string = '#56CC9D';
  colorStyleDark: string = '#1E3050';
  closeResult = '';
  dateHourExport: string = '';

  mobileVolumeEntryForm: FormGroup = this.fb.group({
    entry: this.fb.group({
      effector: this.fb.group({
        id: [],
        shortLabel: ['']
      })
    })
  });

  constructor(
    private fb: FormBuilder,
    private effectorActivityService: EffectorActivityService,
    private router: Router,
    private route: ActivatedRoute,
    private mobileVolumeEntryService: MobileEffectorVolumeEntryService,
    private effectorService: EffectorService,
    private modalService: NgbModal
  ) {
    this.form = this.fb.group({
      tab_one: this.fb.group({}),
      tab_two: this.fb.group({}),
      tab_three: this.fb.group({}),
      tab_four: this.fb.group({}),
      tab_five: this.fb.group({}),
      tab_six: this.fb.group({}),
      tab_seven: this.fb.group({})
    });
  }
  comments: string[] = [];

  @Input() form!: FormGroup;
  @Output() formChange = new EventEmitter<FormGroup>();

  @Output() hasCommentChange = new EventEmitter<boolean>();
  hasComment = false;
  hasCommentEntered = false;
  commentText: string | undefined = '';
  openCommentLet = false;
  currentPage: number = 1;

  @ViewChild('content') content: ElementRef;
  @ViewChild('nav')
  nav;

  ngOnInit() {
    this.getEffectorId()
      .then((id) => {
        this.effectorId = id;
        return this.getEffectorYearMonth();
      })
      .then((yearMonth) => {
        this.effectorYearMonth = yearMonth;
        return this.isPlatformBoolean(this.effectorId);
      })
      .then(() => {
        return this.getMobileVolumeEntry(
          this.effectorId,
          this.effectorYearMonth
        );
      })
      .then((mobileEffectorVolumeEntry) => {
        this.mobileEffectorVolumeEntry = mobileEffectorVolumeEntry;
        this.commentText = mobileEffectorVolumeEntry.entry.comment;
        if (this.commentText && this.commentText.trim() !== '') {
          this.hasCommentEntered = true;
        }
        this.lastSavedStep = mobileEffectorVolumeEntry.entry.step;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  hasValuesEntered() {
    return (
      this.hasValuesEnteredOne ||
      this.hasValuesEnteredTwo ||
      this.hasValuesEnteredThree ||
      this.hasValuesEnteredFour ||
      this.hasValuesEnteredFive ||
      this.hasValuesEnteredSix
    );
  }

  updateStep(step: number): number {
    if (this.mobileEffectorVolumeEntry) {
      this.mobileEffectorVolumeEntry.entry.step = Math.max(
        this.mobileEffectorVolumeEntry.entry.step,
        step
      );
      return this.mobileEffectorVolumeEntry.entry.step;
    }
    return 1;
  }

  getEffectorId(): Promise<number> {
    return new Promise((resolve, reject) => {
      this.route.url.subscribe((segments) => {
        if (segments.length > 0) {
          const lastSegment = segments[segments.length - 1].path;
          const effectorId: number = parseInt(lastSegment, 10);
          resolve(effectorId);
        } else {
          reject("Aucun segment trouvé dans l'URL.");
        }
      });
    });
  }

  isPlatformBoolean(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this.effectorService.getEffectorsById(id).subscribe(
        (result) => {
          this.isBooleanPlatform = result.platformBoolean;
          resolve();
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getEffectorYearMonth(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.route.url.subscribe((segments) => {
        if (segments.length > 0) {
          const lastSegment = segments[segments.length - 2].path;
          let position = lastSegment.length - 2;
          const effectorYearMonth =
            lastSegment.slice(0, position) + '-' + lastSegment.slice(position);
          resolve(effectorYearMonth);
        } else {
          reject("Aucun segment trouvé dans l'URL.");
        }
      });
    });
  }

  getMobileVolumeEntry(
    effectorId: number,
    effectorYearMonth: string
  ): Promise<MobileEffectorVolumeEntry> {
    return new Promise((resolve, reject) => {
      this.mobileVolumeEntryService
        .getMobileVolumeEntry(effectorId, effectorYearMonth)
        .subscribe(
          (selectedMobileVolumeEntry) => {
            this.effectorShortLabel =
              selectedMobileVolumeEntry?.entry?.effector?.shortLabel;

            const dateString = selectedMobileVolumeEntry?.entry.yearMonth;
            const dateObject = new Date(dateString);
            const monthString = dateObject.toLocaleString('default', {
              month: 'long'
            });
            const year = dateObject.getFullYear();
            this.effectorDate = `${monthString} ${year}`;

            this.mobileVolumeEntryForm.patchValue(selectedMobileVolumeEntry);
            this.mobileEffectorVolumeEntry = selectedMobileVolumeEntry;

            return resolve(selectedMobileVolumeEntry);
          },
          (error) => {
            console.error(error);
            reject(error);
          }
        );
    });
  }

  validateData(close: boolean): boolean {
    if (!this.hasValuesEntered) {
      this.tooltipMessage = 'Validation impossible car aucune donnée saisie';
      this.cursorStyle = 'not-allowed';
      this.colorStyle = '#abdbe3';
      this.colorStyleDark = '#56647C';
      if (close) {
        Swal.fire({
          title: 'Aucune donnée saisie',
          text: "Vous ne pouvez pas valider car aucune donnée n'a été saisie",
          icon: 'warning',
          confirmButtonColor: '#2B8CA9',
          confirmButtonText: 'OK'
        });
      }
      return false;
    }
    return true;
  }

  getFormGroupFor(control: string): FormGroup {
    return this.form.get(control) as FormGroup;
  }

  selectTab(tabNumber: number) {
    this.nav.select(tabNumber);
  }

  selectNextTab() {
    if (
      (this.currentPage < 6 && !this.hasCommentEntered) ||
      (this.currentPage < 7 && this.hasCommentEntered)
    ) {
      this.currentPage++;
      this.effectorActivityService.setCurrentPage(this.currentPage);
      this.selectTab(this.currentPage);
    }
  }

  selectPreviousTab() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.effectorActivityService.setCurrentPage(this.currentPage);
      this.selectTab(this.currentPage);
    }
  }

  capitalizeFirstLetter(string: string | undefined): string {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    } else {
      return '';
    }
  }

  saveAndSelectPreviousTab(currentPage: number) {
    if (currentPage === 2) {
      this.secondChildComponentRef.onSubmit(EventType.NAVIGATE_PREVIOUS);
    } else if (currentPage === 3) {
      this.thirdChildComponentRef.onSubmit(EventType.NAVIGATE_PREVIOUS);
    } else if (currentPage === 4) {
      this.fourthChildComponentRef.onSubmit(EventType.NAVIGATE_PREVIOUS);
    } else if (this.isBooleanPlatform && currentPage === 5) {
      this.fifthChildComponentRef.onSubmit(EventType.NAVIGATE_PREVIOUS);
    } else if (currentPage === 6) {
      this.sixhChildComponentRef.onSubmit(EventType.NAVIGATE_PREVIOUS);
    } else if (currentPage === 7) {
      this.seventhChildComponentRef.onSubmit(EventType.NAVIGATE_PREVIOUS);
    } else if (!this.isBooleanPlatform && currentPage === 5) {
      this.seventhChildComponentRef.onSubmit(EventType.NAVIGATE_PREVIOUS);
    }
  }

  saveAndSelectNextTab(currentPage: number) {
    if (currentPage === 1) {
      this.firstChildComponentRef.onSubmit(EventType.NAVIGATE_NEXT);
    } else if (currentPage === 2) {
      this.secondChildComponentRef.onSubmit(EventType.NAVIGATE_NEXT);
    } else if (currentPage === 3) {
      this.thirdChildComponentRef.onSubmit(EventType.NAVIGATE_NEXT);
    } else if (currentPage === 4) {
      this.fourthChildComponentRef.onSubmit(EventType.NAVIGATE_NEXT);
    } else if (currentPage === 5) {
      this.fifthChildComponentRef.onSubmit(EventType.NAVIGATE_NEXT);
    }
  }

  selectTabAndSaveCurrentTab(tabNumber: number) {
    this.currentPage = tabNumber;
    this.saveCurrentTab(this.activeTab);
  }

  saveCurrentTab(activeTab: number) {
    switch (activeTab) {
      case 1:
        this.firstChildComponentRef.onSubmit(EventType.NAVIGATE_TO_TAB);
        break;
      case 2:
        this.secondChildComponentRef.onSubmit(EventType.NAVIGATE_TO_TAB);
        break;
      case 3:
        this.thirdChildComponentRef.onSubmit(EventType.NAVIGATE_TO_TAB);
        break;
      case 4:
        this.fourthChildComponentRef.onSubmit(EventType.NAVIGATE_TO_TAB);
        break;
      case this.isBooleanPlatform && 5:
        this.fifthChildComponentRef.onSubmit(EventType.NAVIGATE_TO_TAB);
        break;
      case 6:
        this.sixhChildComponentRef.onSubmit(EventType.NAVIGATE_TO_TAB);
        break;
      case 7:
        this.seventhChildComponentRef.onSubmit(EventType.NAVIGATE_TO_TAB);
        break;
      case !this.isBooleanPlatform && 5:
        this.seventhChildComponentRef.onSubmit(EventType.NAVIGATE_TO_TAB);
        break;
      default:
        break;
    }
  }

  handleError(elementsArray: FormArray, attributsName: string[]) {
    let hasInvalidValue = false;
    let hasEmptyValue = false;
    let hasValue = false;
    const elementControl = elementsArray.controls;

    for (const attributName of attributsName) {
      const elementValue = elementControl[attributName].value;

      if (elementValue !== undefined) {
        const isInvalid = isNaN(elementValue) && elementValue !== 'F';
        const isEmpty = !elementValue || elementValue.trim() === '';

        if (isInvalid) {
          hasInvalidValue = true;
          elementControl[attributName]?.setErrors({ invalidValue: true });
        } else if (isEmpty) {
          hasEmptyValue = true;
          elementControl[attributName]?.setErrors({ emptyValue: true });
        } else {
          hasValue = true;
          elementControl[attributName]?.setErrors(null);
        }
      }
    }
    return { hasInvalidValue, hasEmptyValue, hasValue };
  }

  closeForm(currentPage: number) {
    if (
      (this.firstChildComponentRef &&
        !this.firstChildComponentRef.valueHasChanged) ||
      (!this.firstChildComponentRef &&
        !this.hasValuesEntered() &&
        !this.hasCommentChanged)
    ) {
      this.router.navigateByUrl('/pdsa-structure-activity');
      return;
    }

    Swal.fire({
      title: 'ATTENTION !',
      text: 'Vous allez sortir du formulaire. Souhaitez-vous enregistrer vos dernières modifications avant de sortir ?',
      showCancelButton: true,
      showDenyButton: true,
      confirmButtonText: 'OUI',
      confirmButtonColor: '#56CC9D',
      denyButtonText: `NON`,
      denyButtonColor: '#F98776',
      cancelButtonText: `ANNULER`,
      cancelButtonColor: '#56647C',
      customClass: {
        actions: 'my-actions',
        cancelButton: 'order-3 ml-5',
        confirmButton: 'order-1',
        denyButton: 'order-2 tool-tip mr-3'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        if (currentPage === 1) {
          this.firstChildComponentRef.onSubmit(EventType.CLOSE);
        } else if (currentPage === 2) {
          this.secondChildComponentRef.onSubmit(EventType.CLOSE);
        } else if (currentPage === 3) {
          this.thirdChildComponentRef.onSubmit(EventType.CLOSE);
        } else if (currentPage === 4) {
          this.fourthChildComponentRef.onSubmit(EventType.CLOSE);
        } else if (this.isBooleanPlatform && currentPage === 5) {
          this.fifthChildComponentRef.onSubmit(EventType.CLOSE);
        } else if (currentPage === 6) {
          this.sixhChildComponentRef.onSubmit(EventType.CLOSE);
        } else if (currentPage === 7) {
          this.seventhChildComponentRef.onSubmit(EventType.CLOSE);
        } else if (!this.isBooleanPlatform && currentPage === 5) {
          this.seventhChildComponentRef.onSubmit(EventType.CLOSE);
        }
      } else if (result.isDenied) {
        this.router.navigateByUrl('/pdsa-structure-activity');
        Swal.fire({
          title: 'Dernières modifications non sauvegardées',
          icon: 'info',
          confirmButtonColor: '#2B8CA9',
          confirmButtonText: 'OK'
        });
      }
    });
  }

  areNoValuesEntered(): boolean {
    const elementsArray = this.firstChildComponentRef.mobileVolumeEntryForm.get(
      'elements'
    ) as FormArray;

    if (elementsArray && elementsArray.controls) {
      for (const elementControl of elementsArray.controls) {
        const elementValue = elementControl.get('count')?.value;

        if (
          elementValue != null &&
          elementValue !== undefined &&
          elementValue !== ''
        ) {
          return false;
        }
      }
    }

    return true;
  }

  get dateLabel(): string | undefined {
    if (this.formDate) {
      const returnDate = format(this.formDate, 'LLLL yyyy', { locale: fr });
      return returnDate.charAt(0).toUpperCase() + returnDate.slice(1);
    } else {
      return undefined;
    }
  }

  getControlFor(control: string): FormGroup {
    const c = this.form.get(control);
    if (c == null || !(c instanceof FormGroup)) {
      throw Error('Please provide an existing control');
    } else {
      return c as FormGroup;
    }
  }

  setControlFor(control: string, newValue: FormGroup): void {
    return this.form.setControl(control, newValue);
  }

  saveAlert() {
    this.router.navigateByUrl('/pdsa-structure-activity').then(() => {
      Swal.fire({
        title: 'Données sauvegardées avec succès !',
        icon: 'success',
        confirmButtonColor: '#2B8CA9',
        confirmButtonText: 'OK'
      });
    });
    if (this.commentText?.trim() !== '') {
      this.hasCommentEntered = true;
    }
  }

  isViewed(step): boolean {
    if (
      this.mobileEffectorVolumeEntry &&
      this.mobileEffectorVolumeEntry.entry.step
    ) {
      return step <= this.mobileEffectorVolumeEntry.entry.step;
    }
    return false;
  }

  updateStateWithoutCallPlatform(
    event: EventType,
    mobileConsultation:
      | MobileTerritoryVisitRange
      | MobileCrraVisitRange
      | MobileDeathVisitRange
  ) {
    switch (event) {
      case EventType.SUBMIT:
        if (mobileConsultation.entry.state !== State.COMPLETED) {
          mobileConsultation.entry.state = State.COMPLETED;
        } else {
          mobileConsultation.entry.state !== State.COMPLETED;
        }
        break;
      default:
        if (mobileConsultation.entry.state !== State.COMPLETED) {
          mobileConsultation.entry.state = State.UNCOMPLETED;
        } else {
          mobileConsultation.entry.state = State.COMPLETED;
        }
        break;
    }
  }

  updateState(
    event: EventType,
    mobileConsultation:
      | MobileTerritoryVisitRange
      | MobileCrraVisitRange
      | MobileDeathVisitRange
      | MobileCallRange
      | MobileDecisionTypeRange
  ) {
    switch (event) {
      case EventType.SUBMIT:
        mobileConsultation.entry.state = State.COMPLETED;
        break;
      default:
        if (mobileConsultation.entry.state !== State.COMPLETED) {
          mobileConsultation.entry.state = State.UNCOMPLETED;
        }
        break;
    }
  }

  async afterSubmit(event: EventType) {
    switch (event) {
      case EventType.CLOSE:
      case EventType.SUBMIT:
        return await this.router
          .navigateByUrl('/pdsa-structure-activity')
          .then(() => {
            Swal.fire({
              title: 'Données sauvegardées avec succès !',
              icon: 'success',
              confirmButtonColor: '#2B8CA9',
              confirmButtonText: 'OK'
            });
          });
      case EventType.NAVIGATE_NEXT:
        this.selectNextTab();
        break;
      case EventType.NAVIGATE_PREVIOUS:
        this.selectPreviousTab();
        break;
      default:
        break;
    }
    return true;
  }

  updateSubmitButton() {
    const hasValuesEntered = this.hasValuesEntered();

    this.disableButton = !hasValuesEntered;

    this.tooltipMessage = hasValuesEntered ? '' : 'Aucune donnée saisie';
    this.cursorStyle = hasValuesEntered ? 'pointer' : 'not-allowed';
    this.colorStyle = hasValuesEntered ? '#56CC9D' : '#abdbe3';
    this.colorStyleDark = hasValuesEntered ? '#1E3050' : '#56647C';
  }

  handleInvalidValueMessage(event: EventType) {
    if (event !== EventType.NAVIGATE_TO_TAB) {
      Swal.fire({
        title: 'Des erreurs ont été détectées. Veuillez corriger vos données.',
        icon: 'error',
        confirmButtonColor: '#2B8CA9',
        confirmButtonText: 'OK'
      });
    } else {
      Swal.fire({
        title:
          'Des erreurs ont été détectées et vos modifications n’ont pas pu être sauvegardées. Veuillez vérifier vos données.',
        icon: 'warning',
        confirmButtonColor: '#2B8CA9',
        confirmButtonText: 'OK'
      });
    }
    return;
  }

  // EXPORT PDF
  async exportToPDF() {
    this.loading = true;
    const options: jsPDFOptions = {
      orientation: 'landscape'
    };

    let doc = new jsPDF(options);

    const htmlOptions: HTMLOptions = {
      jsPDF: doc,
      autoPaging: false,
      windowWidth: 1500,
      width: 297,
      html2canvas: {
        useCORS: true,
        allowTaint: false
      },
      x: 0,
      y: 0
    };

    const docDiv = document.querySelectorAll(
      '.export_content'
    ) as NodeListOf<HTMLDivElement>;

    await doc.html(docDiv[0], htmlOptions);

    for (let i = 0; i < docDiv.length; i++) {
      if (i > 0) {
        doc.addPage();
      }
      await doc.html(docDiv[i], htmlOptions);
    }

    doc.save(this.effectorShortLabel + '-' + this.effectorDate + '.pdf');
    this.loading = false;
  }

  showModalPdf(content: any) {
    const currentDate = new Date();
    const dateString = currentDate.toLocaleDateString();
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const timeString = `${hours < 10 ? '0' : ''}${hours}:${
      minutes < 10 ? '0' : ''
    }${minutes}`;

    this.dateHourExport = `Export du ${dateString} ${timeString}`;
    this.modalService
      .open(content, { size: 'xl', ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  /**
   * Write code on Method
   *
   * @return response()
   */
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
