import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MobileEffectorVolumeEntry } from '../../model/activity/mobile-effector-volume-entry.model';

@Injectable({
  providedIn: 'root'
})
export class MobileEffectorVolumeEntryService {
  constructor(private httpClient: HttpClient) {}

  public getMobileVolumeEntry(
    id: number,
    yearMonth: string
  ): Observable<MobileEffectorVolumeEntry> {
    return this.httpClient.get<MobileEffectorVolumeEntry>(
      `api/effectors/${id}/activities/${yearMonth}/volume`
    );
  }

  public setMobileVolumeEntry(
    id: number,
    yearMonth: string,
    mobileEffectorVolumeEntry: MobileEffectorVolumeEntry
  ): Observable<MobileEffectorVolumeEntry> {
    return this.httpClient.post<MobileEffectorVolumeEntry>(
      `api/effectors/${id}/activities/${yearMonth}/volume`,
      mobileEffectorVolumeEntry
    );
  }
}
