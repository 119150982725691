    <div class="container-mes-urgences-pro" style="background-color: white;" *ngIf="account?.authorities.includes('31')">
      <div  class="div-alert">
      <alert class="alert"></alert>
      </div>
  
      <iframe
      *ngIf="isPreprod() == true"
        width="100%"
        height="100%"
        frameBorder="0"
        src="https://ppr-mesurgencespro-predictif.sesan.fr"
        scrolling="yes"
      ></iframe>
      <iframe
      *ngIf="isPreprod() == false"
        width="100%"
        height="100%"
        frameBorder="0"
        src="https://mesurgencespro-predictif.sante-idf.fr"
        scrolling="yes"
      ></iframe>
    </div>
    <div class="accessdenied" *ngIf="isDisplayed && !account?.authorities.includes('31')">
      <app-error-page></app-error-page>
    </div>
