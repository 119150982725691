import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { ChartService } from "src/app/graph/services/chart.service";

@Component({
  selector: "app-hospitalisations-child-graph",
  templateUrl: "./hospitalisations-child-graph.component.html",
  styleUrls: ["./hospitalisations-child-graph.component.scss"],
})
export class HospitalisationsChildGraphComponent implements OnInit {
  @Input() dataInd7;
  constructor(private chartService: ChartService) {}

  ngOnInit(): void {}

  ngOnChanges(change: SimpleChanges) {
    this.chartService.initChart(
      "hospitalisations-child-graph",
      change.dataInd7.currentValue,
      "Taux d'hospitalisation enfants < 1 an"
    );
  }
}
