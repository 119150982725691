export enum RoleUser {
  ROLE_SAMU = '3',

  ROLE_URGENCE = '6',

  ROLE_TENSION_IDF = '7',

  ROLE_TENSION_ARA = '42',

  ROLE_EPIDEMIOLOGIE = '9',

  ROLE_ANALYSE_SAMU = '8',

  ROLE_QUALITE_IDF = '11',

  ROLE_QUALITE_ARA = '43',

  ROLE_QUALITE_ARA_BIS = '44',

  ROLE_ZERO_BRANCARD = '12',

  ROLE_ADHOC = '13',

  ROLE_GESTION_LITS_IDF = '14',

  ROLE_PREVISION_IDF = '15',

  ROLE_PREVISION_ARA = '45',

  ROLE_ETAT_SAISIES_IDF = '16',

  ROLE_ETAT_SAISIES_ARA = '46',

  ROLE_TRAFIC = '18',

  ROLE_DISPO_LITS_IDF = '21',

  ROLE_DISPO_LITS_ARA = "47'",

  ROLE_SI_SAMU_PED = '23',

  ROLE_ROR = '24',

  ROLE_ADMIN = '25',

  ROLE_EXPLOIT_FLUX = '26',

  ROLE_PARAM_SAU_IDF = '27',

  ROLE_PARAM_SAU_ARA = '49',

  ROLE_METEO = '28',

  ROLE_SHINY = '29',

  ROLE_SCREEN = '30',

  ROLE_MES_URGENCES_GP = '19',

  ROLE_MES_URGENCES_PRO = '31',

  ROLE_POWER_BI = '32',

  ROLE_METEO_SAMU = '33',

  ROLE_BANDEAU = '34',

  ROLE_BANDEAU_KO = '35',

  ROLE_PAGE_ADMIN = '37',

  CERVEAU_ADMINISTRATEUR = '65',

  CERVEAU_POWER_BI_IDF = '67',

  CERVEAU_POWER_BI_ARA = '68',

  CERVEAU_PARAM_PDSA = '69', //PARAM PDSA

  CERVEAU_SAISIE_PDSA = '70', //SAISIE PDSA

  CERVEAU_ADMIN_PDSA = '71', //ALL PDSA

  CERVEAU_ADHOC_ARA = '72',

  CERVEAU_NEB_CHARGE = '73',

  CERVEAU_NEB_INDICATEUR = '74',

  CERVEAU_NEB_NTG = '75'
}
