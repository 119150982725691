import { EffectorEntry } from './effector-entry.model';

export enum PeriodCodePdsa {
  IN_PDSA_HOURS = 0,
  OUT_PDSA_HOURS = 1
}

export interface MobileVolumeEntry {
  entry: EffectorEntry;
  id: string;
  periodCodePdsa: PeriodCodePdsa;
  nbOfCallsAnswered?: string;
  numberOfVisits?: string;
}

export class MobileVolumeEntryClass implements MobileVolumeEntry {
  entry: EffectorEntry;
  id: string;
  periodCodePdsa: PeriodCodePdsa;
  nbOfCallsAnswered?: string;
  numberOfVisits?: string;

  static fromMobileVolumeEntry(mobileVolumeEntry: MobileVolumeEntry) {
    return new MobileVolumeEntryClass(
      mobileVolumeEntry.id,
      mobileVolumeEntry.periodCodePdsa,
      mobileVolumeEntry.nbOfCallsAnswered,
      mobileVolumeEntry.numberOfVisits
    );
  }
  constructor(
    id: string,
    periodCodePdsa: PeriodCodePdsa,
    nbOfCallsAnswered?: string,
    numberOfVisits?: string
  ) {
    this.id = id;
    this.periodCodePdsa = periodCodePdsa;
    this.nbOfCallsAnswered = nbOfCallsAnswered;
    this.numberOfVisits = numberOfVisits;
  }
}
