import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { ChartService } from "src/app/graph/services/chart.service";

@Component({
  selector: "app-hospitalisations-rate-child-graph",
  templateUrl: "./hospitalisations-rate-child-graph.component.html",
  styleUrls: ["./hospitalisations-rate-child-graph.component.scss"],
})
export class HospitalisationsRateChildGraphComponent implements OnInit {
  @Input() dataInd6;

  constructor(private chartService: ChartService) {}

  ngOnInit(): void {}

  ngOnChanges(change: SimpleChanges) {
    this.chartService.initChart(
      "hospitalisations-rate-child-graph",
      change.dataInd6.currentValue,
      "Nombre d'hospitalisations enfants < 1 an"
    );
  }
}
