<div
  class="container-shiny"
  style="overflow: hidden; background-color: white"
  *ngIf="isAccess() === true"
>
  <div class="div-alert">
    <alert class="alert"></alert>
  </div>

  <iframe
    width="100%"
    height="100%"
    frameBorder="0"
    *ngIf="isAraPage() == false && isPreprod() == false"
    src="https://snp-qualite.sante-idf.fr"
    scrolling="yes"
  ></iframe>

  <iframe
    width="100%"
    height="100%"
    frameBorder="0"
    *ngIf="isAraPage() == false && isPreprod() == true"
    src="https://ppr-snp-qualite.sesan.fr"
    scrolling="yes"
  ></iframe>
</div>

<iframe
width="100%"
height="100%"
frameBorder="0"
*ngIf="isAraPage() == true && isPreprod() == true"
src="https://ppr-snp-qualite-ara.sesan.fr"
scrolling="yes"
></iframe>

<iframe
width="100%"
height="100%"
frameBorder="0"
*ngIf="isAraPage() == true && isPreprod() == false"
src="https://snp-qualite-ara.sante-idf.fr"
scrolling="yes"
></iframe>


<div class="accessdenied" id="accessdenied" *ngIf="isDisplayed && isAccess() === false">
  <app-error-page></app-error-page>
</div>


