import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { TechnicalParameter } from "../model/technical-parameter.model";

@Injectable({
  providedIn: "root",
})
export class TechnicalParameterService {
  constructor(private httpClient: HttpClient) {}

  public getTechnicalParameter(): Observable<TechnicalParameter[]> {
    return this.httpClient.get<TechnicalParameter[]>(
      `/api/technical-parameter`
    );
  }

  //GET codeEg and codeSerUrg
  public getTechnicalParameterById(
    codeEg: string,
    codeSerUrg: number
  ): Observable<TechnicalParameter> {
    return this.httpClient.get<TechnicalParameter>(
      `/api/technical-parameter/${codeEg}-${codeSerUrg}`
    );
  }

  public setTechnicalParameter(
    technicalParameter: TechnicalParameter
  ): Observable<TechnicalParameter> {
    return this.httpClient.post<TechnicalParameter>(
      `/api/technical-parameter`,
      technicalParameter
    );
  }
}
