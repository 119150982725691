import { Component, OnInit } from "@angular/core";
import { PresenceEmergencyService } from "../home-page-idf/services/presence-emergency.service";

@Component({
  selector: "app-status",
  templateUrl: "./status.component.html",
  styleUrls: ["./status.component.scss"],
})
export class StatusComponent implements OnInit {
  errorServer: boolean = false;

  constructor(private presenceEmergencyService: PresenceEmergencyService) {}

  ngOnInit() {

  }
}
