import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ContactTechnicalParameter } from "../model/contact-technical-parameter.model";

@Injectable({
    providedIn: 'root'
})

export class ContactTechnicalParameterService {
    constructor(
        private httpClient: HttpClient,
    ) { }

    public getContactTechnicalParameter(): Observable<ContactTechnicalParameter[]> {
        return this.httpClient.get<ContactTechnicalParameter[]>(`/api/contact-param`);
    }

      //à ajouter pour récup code EG et code Serv urg
      public getContactTechnicalParameterBySu(codeEg: string, codeSerUrg: number): Observable<ContactTechnicalParameter[]> {
          return this.httpClient.get<ContactTechnicalParameter[]>(`/api/contact-param/${codeEg}-${codeSerUrg}`);
      }

      public updateContactTechnicalParameter(id: Number, contactTechnicalParameter: ContactTechnicalParameter): Observable<ContactTechnicalParameter> {
          return this.httpClient.put<ContactTechnicalParameter>(`api/contact-param/${id}`, contactTechnicalParameter);
      }
      public setContactTechnicalParameter(contactTechnicalParameter: ContactTechnicalParameter): Observable<ContactTechnicalParameter> {
        return this.httpClient.post<ContactTechnicalParameter>(`api/contact-param`, contactTechnicalParameter);
    }
      public deleteContactTechnicalParameter(id: Number): Observable<ContactTechnicalParameter> {
        return this.httpClient.delete<ContactTechnicalParameter>(`api/contact-param/${id}`);
    }
}