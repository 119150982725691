import {AnalyticsLibrary} from '../core/context/analytics-library.enum';
import {ContextStore} from '../core/context/context.store';
import {Inject, Injectable} from '@angular/core';
import {MatomoRouteTracker} from './matomo/services/matomo-route-tracker.service';
import {MatomoInjector} from './matomo/services/matomo-injector.service';
import {MATOMO_CONFIGURATION, MatomoConfiguration} from './matomo/matomo-configuration';
import {PiwikService} from './piwik/services/piwik.service';
import {Observable, of} from 'rxjs';
import {RegionIdEnum} from './region-id.enum';


@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(private piwikService: PiwikService,
              private matomoInjector: MatomoInjector,
              private contextStore: ContextStore,
              private matomoRouteTracker: MatomoRouteTracker,
              @Inject(MATOMO_CONFIGURATION) private readonly configuration: MatomoConfiguration,
  ) {
    this.contextStore.context.subscribe((context) => {
      if (context.analyticsLibrary === AnalyticsLibrary.PIWIK) {
        this.initPiwikLibrary(context.piwikContainerId);
      } else if (context.analyticsLibrary === AnalyticsLibrary.MATOMO) {
        this.initMatomoLibrary(context.siteId);
      }
    });
  }

  private initPiwikLibrary(containerId: string): void {
    this.piwikService.init(containerId);
  }

  private initMatomoLibrary(siteId: number): void {
    // Inject the Matomo script and create trackers.
    this.configuration.trackers[0].siteId = siteId;
    this.matomoInjector.init();
    // Enable route tracking if requested.
    if (this.configuration?.routeTracking?.enable === true) {
      this.matomoRouteTracker.startTracking();
    }
  }

  isAraPage() {
    const str = window.location.href;
    return str.includes('ara') || str.includes(':4201/');
  }
}
