import { Component, OnInit } from "@angular/core";
import * as Highcharts from "highcharts";

declare var require: any;
let Boost = require("highcharts/modules/boost");
let noData = require("highcharts/modules/no-data-to-display");
let More = require("highcharts/highcharts-more");

@Component({
  selector: "app-third-graph",
  templateUrl: "./third-graph.component.html",
  styleUrls: ["./third-graph.component.scss"],
})
export class ThirdGraphComponent implements OnInit {
  public options: any = {
    chart: {
      backgroundColor: "#f8f9f9",
    },
    title: {
      text: "Passages aux Urgences des plus de 75 ans",
    },

    rangeSelector: {
      enabled: true,
      inputPosition: {
        align: "left",
        x: 0,
        y: 0,
      },
      buttonPosition: {
        align: "right",
        x: 0,
        y: 0,
      },
    },

    yAxis: {
      min: 0,
      max: 2500,
      title: {
        text: "Nb de passages aux urgences (+75 ans)",
      },
    },

    xAxis: {
      title: {
        text: "Date d'entrée",
      },
      // min: 0,
      min: Date.UTC(1, 0, 0, 0, 0, 0),
      tickInterval: 3600 * 1000 * 24,
      max: Date.UTC(1, 0, 6, 0, 0, 0),
      type: "date",

      // baseUnit: "day",        pointInterval: 3600 * 1000 * 24,
      pointStart: Date.UTC(1, 0, 0, 0, 0, 0),
      labels: {
        format: "{value:%e/%m}",
      },
    },

    legend: {
      layout: "vertical",
      align: "right",
      verticalAlign: "middle",
    },

    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
        pointStart: Date.UTC(1, 0, 0, 0, 0, 0),
      },
    },

    series: [
      {
        name: "Ain",
        data: [1000, 1500, 1400, 1002, 1100, 1200, 1300],
        pointInterval: 3600 * 1000 * 24,
        pointStart: Date.UTC(1, 0, 0, 0, 0, 0),
      },
      {
        name: "Allier",
        data: [300, 500, 400, 500, 430, 600, 550],
        pointInterval: 3600 * 1000 * 24,
        pointStart: Date.UTC(1, 0, 0, 0, 0, 0),
      },
      {
        name: "Ardèche",
        data: [1000, 400, 650, 800, 900, 500, 700],
        pointInterval: 3600 * 1000 * 24,
        pointStart: Date.UTC(1, 0, 0, 0, 0, 0),
      },
      {
        name: "Cantal",
        data: [2400, 2000, 1800, 1900, 2000, 2100, 2300],
        pointInterval: 3600 * 1000 * 24,
        pointStart: Date.UTC(1, 0, 0, 0, 0, 0),
      },
      {
        name: "Drome",
        data: [2300, 2000, 1980, 1500, 1700, 1900, 1880],
        pointInterval: 3600 * 1000 * 24,
        pointStart: Date.UTC(1, 0, 0, 0, 0, 0),
      },
      {
        name: "Haute-Loire",
        data: [500, 600, 700, 600, 500, 700, 880],
        pointInterval: 3600 * 1000 * 24,
        pointStart: Date.UTC(1, 0, 0, 0, 0, 0),
      },
      {
        name: "Haute-savoie",
        data: [100, 200, 900, 600, 670, 810, 990],
        pointInterval: 3600 * 1000 * 24,
        pointStart: Date.UTC(1, 0, 0, 0, 0, 0),
      },
      {
        name: "Isère",
        data: [2000, 2100, 2400, 2300, 2400, 2200, 2450],
        pointInterval: 3600 * 1000 * 24,
        pointStart: Date.UTC(1, 0, 0, 0, 0, 0),
      },
      {
        name: "Loire",
        data: [1800, 1600, 1700, 1550, 1700, 1800, 1950],
        pointInterval: 3600 * 1000 * 24,
        pointStart: Date.UTC(1, 0, 0, 0, 0, 0),
      },
      {
        name: "Puy-de-Dome",
        data: [1400, 1900, 2000, 2200, 2000, 2340, 1980],
        pointInterval: 3600 * 1000 * 24,
        pointStart: Date.UTC(1, 0, 0, 0, 0, 0),
      },
      {
        name: "Rhône",
        data: [600, 800, 1000, 1100, 1200, 1450, 1430],
        pointInterval: 3600 * 1000 * 24,
        pointStart: Date.UTC(1, 0, 0, 0, 0, 0),
      },
      {
        name: "Savoie",
        data: [1150, 1400, 1700, 2000, 2100, 2201, 2000],
        pointInterval: 3600 * 1000 * 24,
        pointStart: Date.UTC(1, 0, 0, 0, 0, 0),
      },
    ],

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    },
  };
  // subscription: Subscription;

  constructor() {}

  ngOnInit() {
    Highcharts.chart("container", this.options);
  }
}
