<link
  rel="stylesheet"
  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
/>

<!--NAVIGATION-->
<nav ngbNav #nav="ngbNav" class="nav-tabs">
  <!--ONGLET 1-->
  <ng-container ngbNavItem>
    <a ngbNavLink>Paramètres établissement</a>
    <ng-template ngbNavContent class="nav-content">
      <form [formGroup]="emergencyForm">
        <div class="row">
          <div class="col-md-8">
            <label for="libelleEGROR" class="form-control-label required"
              >Libellé EG ROR</label
            >
            <ng-select
              type="text"
              formControlName="libelleEGROR"
              [items]="geoEntities"
              bindLabel="name"
              [clearable]="false"
              labelForId="libelleEGROR"
              (change)="changeGeoEntity($event)"
              required
              ngbTooltip="Libellé ROR de l'établissement auquel est rattaché le service d'urgence"
            ></ng-select>
          </div>
          <div class="col-md-3">
            <label for="codeROR" class="form-control-label">Code ROR</label>
            <input
              type="text"
              class="form-control"
              formControlName="codeROR"
              name="codeROR"
              id="codeROR"
              [attr.disabled]="true"
              ngbTooltip="Code ROR de l'établissement auquel est rattaché le service d'urgence"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-md-8">
            <label
              for="libelleEtablissement"
              class="form-control-label required"
              >Libellé Etablissement</label
            >
            <input
              type="text"
              class="form-control"
              formControlName="libelleEtablissement"
              value="{{ emergencyForm.value.libelleEtablissement }}"
              maxlength="250"
              name="libelleEtablissement"
              id="libelleEtablissement"
              required
              ngbTooltip="Libellé Prédictif de l'établissement auquel est rattaché le service d'urgence"
            />
          </div>
          <div class="col-md-3">
            <label for="finess" class="form-control-label">Finess</label>
            <input
              type="text"
              class="form-control"
              formControlName="finess"
              name="finess"
              id="finess"
              [attr.disabled]="true"
              ngbTooltip="Code finess de l'établissement auquel est rattaché le service d'urgence"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <label for="libelleCourt" class="form-control-label required"
              >Libellé Court Etablissement</label
            >
          </div>
          <div class="col-md-5">
            <input
              type="text"
              class="form-control"
              formControlName="libelleCourt"
              name="libelleCourt"
              id="libelleCourt"
              maxlength="50"
              ngbTooltip="Libellé court Prédictif de l'établissement auquel est rattaché le service d'urgence"
            />
          </div>
          <div class="col-md-3"></div>
          <div class="col-md-4">
            <div class="checkbox icheck-primary" [hidden]="regionId === 84">
              <input
                id="checkboxAphp"
                type="checkbox"
                formControlName="aphp"
                [checked]="emergencyForm.value.aphp"
              />
              <label
                for="checkboxAphp"
                ngbTooltip="Booléen permettant de filtrer les établissements AP-HP"
                >Etablissement AP-HP</label
              >
            </div>
          </div>
        </div>

        <hr />
        <div class="row">
          <div class="col-md-8">
            <label for="libelleSU" class="form-control-label required"
              >Libellé Service d'urgence</label
            >
            <input
              type="text"
              class="form-control"
              formControlName="libelleSU"
              name="libelleSU"
              id="libelleSU"
              maxlength="250"
              ngbTooltip="Libellé Prédictif du service d'urgence"
            />
          </div>

          <ng-container *ngIf="isAraPage() == false">
            <div class="col-md-3" [hidden]="regionId === 84" style="margin-left:4px">
              <div class="checkbox icheck-primary">
                <input
                  id="checkboxRepresentation"
                  type="checkbox"
                  formControlName="representation"
                />
                <label
                  for="checkboxRepresentation"
                  ngbTooltip="Permet d'afficher ou non le service d'urgences dans l'application Mes Urgences"
                >
                  Affichage dans Mes Urgences
                </label>
              </div>
            </div>
          </ng-container>
        </div>

        <div class="row">
          <div class="col-md-8">
            <div class="row">
              <div class="col-md-7">
                <label for="libelleCourtSU" class="form-control-label required"
                  >Libellé court Service d'urgence</label
                >
                <input
                  type="text"
                  class="form-control"
                  formControlName="libelleCourtSU"
                  name="libelleCourtSU"
                  id="libelleCourtSU"
                  maxlength="50"
                  ngbTooltip="Libellé court Prédictif du service d'urgence"
                />
              </div>
              <div class="col-md-5">
                <label for="typeSU" class="form-control-label required"
                  >Type de service d'urgence</label
                >
                <select
                  class="form-control"
                  nom="typeSU"
                  id="typeSU"
                  formControlName="typeSU"
                  ngbTooltip="Correspond au numéro d'ordre du flux RPU"
                  (change)="changeTypeSu()"
                >
                  <option value="0">Adulte</option>
                  <option value="1">Pédiatrique</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-md-7">
                <label for="departmentHead" class="form-control-label"
                  >Chef de service</label
                >
                <ng-select
                  formControlName="user"
                  [items]="users"
                  bindLabel="fullName"
                  clearAllText="Désélectionner"
                  labelForId="user"
                  ngbTooltip="Chef du service d'urgence. Afin d'être sélectionnable, l'utilisateur voulu doit avoir la fonction 'Chef de service'."
                ></ng-select>
              </div>
              <div class="col-md-5">
                <label for="typePat" class="form-control-label required"
                  >Patientèle</label
                >
                <select
                  class="form-control"
                  nom="typePat"
                  formControlName="typePat"
                  ngbTooltip="Type de patientèle réel"
                >
                  <option value="0">Adulte</option>
                  <option value="1">Pédiatrique</option>
                  <option value="2">Mixte</option>
                </select>
              </div>
            </div>
          </div>
          <ng-container>
            <div class="col-md-4">
              <div class="row">
                <div class="form-check">
                  <div class="checkbox icheck-primary">
                    <input
                      id="checkboxPedAd"
                      type="checkbox"
                      formControlName="pedAdu"
                    />
                    <label
                      for="checkboxPedAd"
                      ngbTooltip="Cocher cette case si l'établissement transmet un seul flux RPU avec les passages à la fois adultes et pédiatriques"
                    >
                      Pédiatrie sur flux adulte
                    </label>
                  </div>
                </div>
              </div>
              <div class="row"></div>
            </div>
          </ng-container>
        </div>
        <div class="row">
          <div class="col-md-8">
            <div class="row">
              <div class="col-md-4" [hidden]="regionId === 84">
                <label for="CapUhcd" class="form-control-label"
                  >Capacité UHCD</label
                >
                <input
                  type="number"
                  class="form-control"
                  formControlName="CapUhcd"
                  name="CapUhcd"
                  id="CapUhcd"
                  (keypress)="preventNonNumericalInput($event)"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  maxlength="5"
                  ngbTooltip="Capacité en lits du service d'Unité d'Hospitalisation de Courte Durée"
                />
              </div>
              <div class="col-md-4" [hidden]="regionId === 84">
                <label for="CapSauv" class="form-control-label"
                  >Capacité SAUV</label
                >
                <input
                  type="number"
                  class="form-control"
                  formControlName="CapSauv"
                  name="CapSauv"
                  id="CapSauv"
                  (keypress)="preventNonNumericalInput($event)"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  maxlength="5"
                  ngbTooltip="Capacité du Service d'Accueil aux Urgences Vitales"
                />
              </div>

              <div class="col-md-4" [hidden]="regionId === 84">
                <label for="CapBox" class="form-control-label"
                  >Capacité Box</label
                >
                <input
                  type="number"
                  class="form-control"
                  formControlName="CapBox"
                  name="CapBox"
                  id="CapBox"
                  (keypress)="preventNonNumericalInput($event)"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  maxlength="5"
                  ngbTooltip="Capacité des boxs"
                />
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <label for="emergencySpeciality" class="form-control-label"
              >Spécialité</label
            >
            <select
              class="form-control"
              formControlName="emergencySpeciality"
              nom="emergencySpeciality"
              id="emergencySpeciality"
            >
              <option
                [value]="emergencySpecialities.specialiteSau"
                *ngFor="let emergencySpecialities of emergencySpeciality"
              >
                {{ emergencySpecialities.specialiteSau }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="row">
            <label for="ferm-adm" class="form-control-label"
              >Fermeture administrative</label
            >
          </div>
          <div class="row">
            <div class="col-12">
              <div class="input-group">
                <input
                  class="form-control"
                  formControlName="FermAdm"
                  type="date"
                  lang="fr-ca"
                  id="ferm-adm"
                  name="ferm-adm"
                  [value]="selectedEmergencyDept?.fermAdm"
                  ngbTooltip="Date de fermeture officielle du service d'urgence"
                  placeholder="dd-mm-yyyy"
                />
                <!-- <input
                  class="form-control"
                  formControlName="FermAdm"
                  placeholder="dd/mm/yyyy"
                  ngbDatepicker
                  #d="ngbDatepicker"
                  id="ferm-adm"
                  name="ferm-adm"
                  [value]="selectedEmergencyDept.fermAdm"
                  ngbTooltip="Date de fermeture officielle du service d'urgence"
                />
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="d.toggle()"
                  type="button"
                ></button> -->
              </div>
            </div>
          </div>
        </div>

        <hr />
        <div class="row">
          <div class="col-md-8">
            <div class="row">
              <div class="col-md-4">
                <label for="Lat" class="form-control-label">Latitude</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="Lat"
                  name="Lat"
                  id="Lat"
                  placeholder="Ex:6.01010"
                  ngbTooltip="Latitude du service d'urgence utilisée pour la cartographie"
                />
              </div>

              <div class="col-md-4">
                <label for="Long" class="form-control-label">Longitude</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="Long"
                  name="Long"
                  id="Long"
                  placeholder="Ex:10.098"
                  ngbTooltip="Longitude du service d'urgence utilisée pour la cartographie"
                />
              </div>
            </div>
          </div>

          <div class="col-md-4" [hidden]="regionId === 84">
            <div class="row">
              <div class="form-check">
                <div class="checkbox icheck-primary">
                  <input
                    id="checkboxTauPub"
                    type="checkbox"
                    formControlName="tauGP"
                  />
                  <label
                    for="checkboxTauPub"
                    ngbTooltip="Dans 'Mes urgences' et connecté comme grand public. 
                              Si la case est cochée, le service est affiché en couleur avec les données associées.
                              Si la case est décochée, le service est affiché avec une croix grise et sans donnée."
                  >
                    Service d'urgence connecté pour le grand public
                  </label>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="form-check">
                <div class="checkbox icheck-primary">
                  <input
                    id="checkboxTauPro"
                    type="checkbox"
                    formControlName="tauPR"
                  />
                  <label
                    for="checkboxTauPro"
                    ngbTooltip="Dans 'Mes urgences' et connecté comme professionnel de santé. 
                              Si la case est cochée, le service est affiché en couleurs avec les données associes.
                              Si la case est décochés, le service est affiché avec une croix grise et sans donnée."
                  >
                    Service d'urgence connecté pour les professionnels
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div class="row">
          <div class="col-md-8">
            <div class="row">
              <div class="col-md-4">
                <label for="tailleSU" class="form-control-label"
                  >Taille du service</label
                >
                <ng-select
                  formControlName="tailleSU"
                  [items]="deptSizes"
                  dropdownPosition="top"
                  bindLabel="lib"
                  [clearable]="false"
                  labelForId="tailleSU"
                  ngbTooltip="Taille de service d'urgence permettant de filtrer les différents services dans HET"
                ></ng-select>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ng-template>
  </ng-container>

  <!--ONGLET 2-->
  <ng-container ngbNavItem>
    <a ngbNavLink>Paramètres techniques</a>
    <ng-template ngbNavContent>
      <!--FORMULAIRE-->
      <form [formGroup]="technicalParameterForm">
        <!--BOUTON FLUX-->
        <h3>Flux de données</h3>
        <div class="row" align-items-start>
          <div class="col-sm">
            <label for="fluxRpu" class="form-control-label"><h4>RPU</h4></label>
            <div class="row">
              <div class="col-sm">
                <div class="btn-group btn-group-toggle btn-xl">
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    (click)="setFluxRpu(0)"
                    [ngClass]="{
                      active:
                        technicalParameterForm.controls['fluxRpu'].value === 0
                    }"
                  >
                    Production
                  </button>
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    (click)="setFluxRpu(1)"
                    [ngClass]="{
                      active:
                        technicalParameterForm.controls['fluxRpu'].value === 1
                    }"
                  >
                    Pré-production
                  </button>
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    (click)="setFluxRpu(2)"
                    [ngClass]="{
                      active:
                        technicalParameterForm.controls['fluxRpu'].value === 2
                    }"
                  >
                    Non connecté
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm" *ngIf="isAraPage() == false">
            <label for="fluxAu" class="form-control-label"
              ><h4>Activités urgences</h4></label
            >
            <div class="row">
              <div class="col-sm">
                <div
                  class="btn-group btn-group-toggle btn-xl"
                  data-toggle="buttons"
                >
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    (click)="setFluxAu(0)"
                    [ngClass]="{
                      active:
                        technicalParameterForm.controls['fluxAu'].value === 0
                    }"
                  >
                    Production
                  </button>
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    (click)="setFluxAu(1)"
                    [ngClass]="{
                      active:
                        technicalParameterForm.controls['fluxAu'].value === 1
                    }"
                  >
                    Pré-production
                  </button>
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    (click)="setFluxAu(2)"
                    [ngClass]="{
                      active:
                        technicalParameterForm.controls['fluxAu'].value === 2
                    }"
                  >
                    Non connecté
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />

        <!--OBSERVATION ET LOGICIELS METIERS-->

        <div class="row">
          <!--OBSERVATIONS-->
          <div class="col-md-9">
            <label for="obs" class="form-control-label"
              ><h3>Observations</h3></label
            >
            <div class="comment">
              <textarea
                class="textinput"
                formControlName="obs"
                name="obs"
                id="obs"
                [value]="technicalParameterForm.value.obs"
                maxlength="5000"
              >
              </textarea>
            </div>
          </div>

          <!--LOGICIELS METIERS-->

          <div class="col-md-3">
            <h3>Logiciel métier</h3>
            <label for="logicielMetier" class="form-control-label"
              ><h4>Logiciels</h4></label
            >
            <ng-select
              class="logiciel"
              formControlName="logicielMetier"
              [items]="logicielTechnicalParameter"
              bindLabel="log"
              bindValue="id"
              [clearable]="false"
              labelForId="logicielMetier"
              (change)="setLogicielMetier($event)"
            >
            </ng-select>
            <!-- <ng-select
              [items]="logiciels"
              bindLabel="name"
              bindValue="id"
              [(ngModel)]="logicielMetierStartId"
              [ngModelOptions]="{ standalone: true }"
              (change)="setLogicielMetier($event)"
            > 
            </ng-select>-->

            <div class="checkbox icheck-primary">
              <input
                id="checkboxLog"
                type="checkbox"
                formControlName="enabled"
                [checked]="technicalParameterForm.value.enabled"
              />
              <label for="checkboxLog" class="labellog"
                ><h4>Changement en cours</h4>
              </label>
            </div>
          </div>
        </div>
        <hr />

        <!--CONTACT-->
        <div class="row">
          <div class="col-md-12">
            <label for="contact" class="form-control-label"
              ><h3 class="contact">Contacts</h3></label
            >
          </div>
        </div>
        <div class="row">
          <div class="col-md-9 border p-3 mb-5 bg-white rounded">
            <div class="table-responsive">
              <form [formGroup]="contactsForm">
                <table
                  id="matable"
                  class="table table-striped table-sm table-hover"
                  cellspacing="0"
                  width="100%"
                >
                  <thead class="text-muted text-center">
                    <tr>
                      <th class="primary text-left">NOM PRENOM</th>
                      <th class="text-left">MAIL</th>
                      <th class="text-left">FIXE</th>
                      <th class="text-left">N° MOBILE</th>
                      <th class="text-center">ACTION</th>
                    </tr>
                  </thead>
                  <tbody class="text-muted text-right">
                    <tr
                      formArrayName="contacts"
                      *ngFor="
                        let contact of contactsArrayForm!['controls'];
                        let i = index
                      "
                    >
                      <td [formGroupName]="i" class="primary main text-left">
                        <p
                          *ngIf="
                            !contactsForm.get('contacts')!['controls'][i][
                              'controls'
                            ].editable?.value
                          "
                        >
                          {{
                            contactsForm.get("contacts")!["controls"][i][
                              "controls"
                            ].nomPrenom?.value
                          }}
                        </p>
                        <input
                          type="text"
                          class="form-control"
                          id="InputContact"
                          aria-describedby="contact"
                          placeholder="contact"
                          maxlength="200"
                          formControlName="nomPrenom"
                          autocomplete="off"
                          *ngIf="
                            contactsForm.get('contacts')!['controls'][i][
                              'controls'
                            ].editable?.value
                          "
                          (keyup.enter)="modifyContact(i)"
                        />
                      </td>
                      <td [formGroupName]="i" class="text-left">
                        <p
                          *ngIf="
                            !contactsForm.get('contacts')!['controls'][i][
                              'controls'
                            ].editable?.value
                          "
                        >
                          {{
                            contactsForm.get("contacts")!["controls"][i][
                              "controls"
                            ].mail?.value
                          }}
                        </p>
                        <input
                          type="email"
                          class="form-control"
                          id="InputContact"
                          aria-describedby="contact"
                          placeholder="email"
                          maxlength="200"
                          formControlName="mail"
                          autocomplete="off"
                          *ngIf="
                            contactsForm.get('contacts')!['controls'][i][
                              'controls'
                            ].editable?.value
                          "
                          (keyup.enter)="modifyContact(i)"
                        />
                      </td>
                      <td [formGroupName]="i" class="text-left">
                        <p
                          *ngIf="
                            !contactsForm.get('contacts')!['controls'][i][
                              'controls'
                            ].editable?.value
                          "
                        >
                          {{
                            contactsForm.get("contacts")!["controls"][i][
                              "controls"
                            ].telFixe?.value
                          }}
                        </p>
                        <input
                          type="text"
                          class="form-control"
                          id="InputContact"
                          aria-describedby="contact"
                          placeholder="Fixe"
                          maxlength="10"
                          formControlName="telFixe"
                          autocomplete="off"
                          *ngIf="
                            contactsForm.get('contacts')!['controls'][i][
                              'controls'
                            ].editable?.value
                          "
                          (keypress)="preventNonNumericalInput($event)"
                          (keyup.enter)="modifyContact(i)"
                        />
                      </td>
                      <td [formGroupName]="i" class="text-left">
                        <p
                          *ngIf="
                            !contactsForm.get('contacts')!['controls'][i][
                              'controls'
                            ].editable?.value
                          "
                        >
                          {{
                            contactsForm.get("contacts")!["controls"][i][
                              "controls"
                            ].telMobile?.value
                          }}
                        </p>
                        <input
                          type="text"
                          class="form-control"
                          id="InputContact"
                          aria-describedby="contact"
                          placeholder="Mobile"
                          formControlName="telMobile"
                          maxlength="10"
                          autocomplete="off"
                          *ngIf="
                            contactsForm.get('contacts')!['controls'][i][
                              'controls'
                            ].editable?.value
                          "
                          (keypress)="preventNonNumericalInput($event)"
                          (keyup.enter)="modifyContact(i)"
                        />
                      </td>

                      <td class="text-center">
                        <button
                          type="button"
                          class="btn modify btn-primary"
                          (click)="editContact(i)"
                          container="body"
                        ></button>
                        <button
                          type="button"
                          class="btn remove btn-danger"
                          (click)="removeContact(i)"
                          container="body"
                        ></button>
                      </td>
                    </tr>

                    <tr [formGroup]="newContactsForm">
                      <td class="primary main text-left">
                        <input
                          type="text"
                          class="form-control"
                          id="InputContact"
                          aria-describedby="contactHelp"
                          placeholder="NOM Prénom"
                          maxlength="200"
                          formControlName="nomPrenom"
                          autocomplete="off"
                          (keyup.enter)="addNewContact()"
                        />
                      </td>
                      <td class="text-left">
                        <input
                          type="email"
                          class="form-control"
                          id="InputEmail"
                          aria-describedby="emailHelp"
                          placeholder="Email"
                          maxlength="200"
                          formControlName="mail"
                          autocomplete="off"
                          (keyup.enter)="addNewContact()"
                        />
                      </td>
                      <td class="text-left">
                        <input
                          type="text"
                          maxlength="10"
                          min="0"
                          max="10"
                          class="form-control"
                          id="InputTel"
                          aria-describedby="telHelp"
                          placeholder="Fixe"
                          formControlName="telFixe"
                          autocomplete="off"
                          (keypress)="preventNonNumericalInput($event)"
                          (keyup.enter)="addNewContact()"
                        />
                      </td>
                      <td class="text-left">
                        <input
                          type="text"
                          min="0"
                          max="10"
                          class="form-control"
                          id="InputMobile"
                          aria-describedby="mobileHelp"
                          placeholder="Mobile"
                          maxlength="10"
                          formControlName="telMobile"
                          autocomplete="off"
                          (keypress)="preventNonNumericalInput($event)"
                          (keyup.enter)="addNewContact()"
                        />
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>
      </form>
    </ng-template>
  </ng-container>

  <!--ONGLET 3-->
  <ng-container ngbNavItem>
    <a ngbNavLink>Paramètres HET</a>
    <ng-template ngbNavContent class="nav-content">
      <form [formGroup]="emergencyForm">
        <div class="row">
          <div class="col-md-4">
            <label for="territory" class="form-control-label">Territoire</label>
            <ng-select
              formControlName="territory"
              [items]="edTerritories"
              bindLabel="label"
              [clearable]="true"
              labelForId="territory"
              ngbTooltip="Territoire auquel est rattaché le service d'urgence dans HET"
            ></ng-select>
          </div>

          <div class="form-check col-md-8 doubleAut" style="padding-left: 5%">
            <div class="checkbox icheck-primary" [hidden]="doubleauthidden">
              <input
                id="checkboxDoubleAut"
                type="checkbox"
                formControlName="duoAut"
              />
              <label
                for="checkboxDoubleAut"
                ngbTooltip="Etablissement envoyant 2 flux RPU (Ped et Adulte) mais disposant d'une seule autorisation.
Impact : le NTG le plus en tension est affiché sur la carto (1 seule puce) et est comptabilisé au niveau des compteurs (Rég.,  Dpt et Territoire)"
              >
                Etablissement ne possédant pas de double autorisation
              </label>
            </div>
          </div>
        </div>
      </form>
      <hr />

      <div class="row">
        <div class="col-12">
          <h3
            ngbTooltip="Coefficients et percentile appliqués pour le calcul des seuils des indicateurs"
          >
            Configuration des indicateurs
          </h3>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <form [formGroup]="itForm">
              <table
                id="table-it"
                class="table table-striped table-sm table-hover table-it"
                cellspacing="0"
                width="100%"
              >
                <thead class="table-striped" style="width: 100%">
                  <tr>
                    <th scope="col-3" style="width: 5%"></th>
                    <th scope="col-1" style="width: 5%">
                      <h5 ngbTooltip="Indicateur Nb de lits disponibles">
                        IT1
                      </h5>
                    </th>
                    <th scope="col-3" style="width: 20%"></th>
                    <th scope="col-1" style="width: 5%">
                      <h5 ngbTooltip="Indicateur Nb de passages">IT2</h5>
                    </th>
                    <th scope="col-1" style="width: 5%">
                      <h5 ngbTooltip="Indicateur Nb de patients à 15h">IT3</h5>
                    </th>
                    <th scope="col-1" style="width: 5%">
                      <h5
                        ngbTooltip="Indicateur Nb de patients >75 ans ou <1 an pour les SAU Péd."
                      >
                        IT4
                      </h5>
                    </th>
                    <th scope="col-1" style="width: 5%">
                      <h5 ngbTooltip="Indicateur Durée de présence">IT5</h5>
                    </th>
                    <th scope="col-1" style="width: 5%">
                      <h5 ngbTooltip="Indicateur Taux d'hospitalisation">
                        IT6
                      </h5>
                    </th>
                  </tr>
                </thead>
                <tbody class="text-center">
                  <tr>
                    <th scope="row">Percentile seuil orange</th>
                    <td [formGroup]="itForm.controls['IT1']">
                      <input
                        type="number"
                        autocomplete="off"
                        maxlength="4"
                        formControlName="coeffOrangeBrut"
                      />
                      <!--<br>
                       <span *ngIf="!itForm.controls['IT1'].invalid" style="color:red; font-size:10px">Chiffres uniquement</span> -->
                    </td>
                    <th style="text-align: start; padding-left: 20%">
                      Mode brut - Coeff. pondération seuil orange
                    </th>
                    <td [formGroup]="itForm.controls['IT2']">
                      <input
                        type="number"
                        maxlength="4"
                        autocomplete="off"
                        formControlName="coeffOrangeBrut"
                      />
                    </td>
                    <td [formGroup]="itForm.controls['IT3']">
                      <input
                        type="number"
                        maxlength="4"
                        formControlName="coeffOrangeBrut"
                      />
                    </td>
                    <td [formGroup]="itForm.controls['IT4']">
                      <input
                        type="number"
                        maxlength="4"
                        autocomplete="off"
                        formControlName="coeffOrangeBrut"
                      />
                    </td>
                    <td [formGroup]="itForm.controls['IT5']">
                      <input
                        type="number"
                        maxlength="4"
                        autocomplete="off"
                        formControlName="coeffOrangeBrut"
                      />
                    </td>
                    <td [formGroup]="itForm.controls['IT6']">
                      <input
                        type="number"
                        maxlength="4"
                        autocomplete="off"
                        formControlName="coeffOrangeBrut"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Percentile seuil rouge</th>
                    <td [formGroup]="itForm.controls['IT1']">
                      <input
                        type="number"
                        maxlength="4"
                        autocomplete="off"
                        formControlName="coeffRougeBrut"
                      />
                    </td>
                    <th style="text-align: start; padding-left: 20%">
                      Mode brut - Coeff.pondération seuil rouge
                    </th>
                    <td [formGroup]="itForm.controls['IT2']">
                      <input
                        type="number"
                        maxlength="4"
                        autocomplete="off"
                        formControlName="coeffRougeBrut"
                      />
                    </td>
                    <td [formGroup]="itForm.controls['IT3']">
                      <input
                        type="number"
                        maxlength="4"
                        autocomplete="off"
                        formControlName="coeffRougeBrut"
                      />
                    </td>
                    <td [formGroup]="itForm.controls['IT4']">
                      <input
                        type="number"
                        maxlength="4"
                        autocomplete="off"
                        formControlName="coeffRougeBrut"
                      />
                    </td>
                    <td [formGroup]="itForm.controls['IT5']">
                      <input
                        type="number"
                        maxlength="4"
                        autocomplete="off"
                        formControlName="coeffRougeBrut"
                      />
                    </td>
                    <td [formGroup]="itForm.controls['IT6']">
                      <input
                        type="number"
                        maxlength="4"
                        autocomplete="off"
                        formControlName="coeffRougeBrut"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <!-- Percentile des seuils -->
                    </th>
                    <td [formGroup]="itForm.controls['IT1']">
                      <input
                        type="number"
                        maxlength="4"
                        autocomplete="off"
                        formControlName="coeffSeuil"
                        style="visibility: hidden"
                      />
                    </td>
                    <th style="text-align: start; padding-left: 20%">
                      Tout mode - Coeff. pondération des seuils
                    </th>
                    <td [formGroup]="itForm.controls['IT2']">
                      <input
                        type="number"
                        maxlength="4"
                        autocomplete="off"
                        formControlName="coeffSeuil"
                      />
                    </td>
                    <td [formGroup]="itForm.controls['IT3']">
                      <input
                        type="number"
                        maxlength="4"
                        autocomplete="off"
                        formControlName="coeffSeuil"
                      />
                    </td>
                    <td [formGroup]="itForm.controls['IT4']">
                      <input
                        type="number"
                        maxlength="4"
                        autocomplete="off"
                        formControlName="coeffSeuil"
                      />
                    </td>
                    <td [formGroup]="itForm.controls['IT5']">
                      <input
                        type="number"
                        maxlength="4"
                        autocomplete="off"
                        formControlName="coeffSeuil"
                      />
                    </td>
                    <td [formGroup]="itForm.controls['IT6']">
                      <input
                        type="number"
                        maxlength="4"
                        autocomplete="off"
                        formControlName="coeffSeuil"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <!-- Percentile orange lissé -->
                    </th>
                    <td [formGroup]="itForm.controls['IT1']">
                      <input
                        type="number"
                        maxlength="4"
                        autocomplete="off"
                        formControlName="coeffOrangeLis"
                        style="visibility: hidden"
                      />
                    </td>
                    <th style="text-align: start; padding-left: 20%">
                      Mode lissé - Coeff. pondération seuil orange
                    </th>
                    <td [formGroup]="itForm.controls['IT2']">
                      <input
                        type="number"
                        maxlength="4"
                        autocomplete="off"
                        formControlName="coeffOrangeLis"
                      />
                    </td>
                    <td [formGroup]="itForm.controls['IT3']">
                      <input
                        type="number"
                        maxlength="4"
                        autocomplete="off"
                        formControlName="coeffOrangeLis"
                      />
                    </td>
                    <td [formGroup]="itForm.controls['IT4']">
                      <input
                        type="number"
                        maxlength="4"
                        autocomplete="off"
                        formControlName="coeffOrangeLis"
                      />
                    </td>
                    <td [formGroup]="itForm.controls['IT5']">
                      <input
                        type="number"
                        maxlength="4"
                        autocomplete="off"
                        formControlName="coeffOrangeLis"
                      />
                    </td>
                    <td [formGroup]="itForm.controls['IT6']">
                      <input
                        type="number"
                        maxlength="4"
                        autocomplete="off"
                        formControlName="coeffOrangeLis"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <!-- Percentile rouge lissé -->
                    </th>
                    <td [formGroup]="itForm.controls['IT1']">
                      <input
                        type="number"
                        maxlength="4"
                        autocomplete="off"
                        formControlName="coeffRougeLis"
                        style="visibility: hidden"
                      />
                    </td>
                    <th style="text-align: start; padding-left: 20%">
                      Mode lissé - Coeff. pondération seuil rouge
                    </th>
                    <td [formGroup]="itForm.controls['IT2']">
                      <input
                        type="number"
                        maxlength="4"
                        autocomplete="off"
                        formControlName="coeffRougeLis"
                      />
                    </td>
                    <td [formGroup]="itForm.controls['IT3']">
                      <input
                        type="number"
                        maxlength="4"
                        autocomplete="off"
                        formControlName="coeffRougeLis"
                      />
                    </td>
                    <td [formGroup]="itForm.controls['IT4']">
                      <input
                        type="number"
                        maxlength="4"
                        autocomplete="off"
                        formControlName="coeffRougeLis"
                      />
                    </td>
                    <td [formGroup]="itForm.controls['IT5']">
                      <input
                        type="number"
                        maxlength="4"
                        autocomplete="off"
                        formControlName="coeffRougeLis"
                      />
                    </td>
                    <td [formGroup]="itForm.controls['IT6']">
                      <input
                        type="number"
                        maxlength="4"
                        autocomplete="off"
                        formControlName="coeffRougeLis"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <!-- Mode de calcul lissé -->
                    </th>
                    <td [formGroup]="itForm.controls['IT1']">
                      <input
                        class="table-checkbox"
                        type="checkbox"
                        maxlength="4"
                        autocomplete="off"
                        formControlName="calcLis"
                        [value]="itForm.controls['IT1']?.value.calcLis"
                        style="visibility: hidden"
                        (keyup)="comaToDot($event)"
                      />
                    </td>
                    <th style="text-align: start; padding-left: 20%">
                      Activation du mode lissé
                    </th>
                    <td [formGroup]="itForm.controls['IT2']">
                      <input
                        class="table-checkbox"
                        type="checkbox"
                        maxlength="4"
                        autocomplete="off"
                        formControlName="calcLis"
                        [value]="itForm.controls['IT2']?.value.calcLis"
                        ngbTooltip="En cas de désactivation, c'est le mode de calcul brut qui est utilisé  (--> Impact sur les valeurs et niveau de tension affichés dans le module)"
                        (keyup)="comaToDot($event)"
                      />
                    </td>
                    <td [formGroup]="itForm.controls['IT3']">
                      <input
                        class="table-checkbox"
                        type="checkbox"
                        maxlength="4"
                        autocomplete="off"
                        formControlName="calcLis"
                        [value]="itForm.controls['IT3']?.value.calcLis"
                        ngbTooltip="En cas de désactivation, c'est le mode de calcul brut qui est utilisé  (--> Impact sur les valeurs et niveau de tension affichés dans le module)"
                        (keyup)="comaToDot($event)"
                      />
                    </td>
                    <td [formGroup]="itForm.controls['IT4']">
                      <input
                        class="table-checkbox"
                        type="checkbox"
                        maxlength="4"
                        autocomplete="off"
                        formControlName="calcLis"
                        [value]="itForm.controls['IT4']?.value.calcLis"
                        ngbTooltip="En cas de désactivation, c'est le mode de calcul brut qui est utilisé (--> Impact sur les valeurs et niveau de tension affichés dans le module)"
                        (keyup)="comaToDot($event)"
                      />
                    </td>
                    <td [formGroup]="itForm.controls['IT5']">
                      <input
                        class="table-checkbox"
                        type="checkbox"
                        maxlength="4"
                        autocomplete="off"
                        formControlName="calcLis"
                        [value]="itForm.controls['IT5']?.value.calcLis"
                        ngbTooltip="En cas de désactivation, c'est le mode de calcul brut qui est utilisé (--> Impact sur les valeurs et niveau de tension affichés dans le module)"
                        (keyup)="comaToDot($event)"
                      />
                    </td>
                    <td [formGroup]="itForm.controls['IT6']">
                      <input
                        class="table-checkbox"
                        type="checkbox"
                        maxlength="4"
                        autocomplete="off"
                        formControlName="calcLis"
                        [value]="itForm.controls['IT6']?.value.calcLis"
                        ngbTooltip="En cas de désactivation, c'est le mode de calcul brut qui est utilisé (--> Impact sur les valeurs et niveau de tension affichés dans le module)"
                        (keyup)="comaToDot($event)"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Activation de l'indicateur</th>
                    <td [formGroup]="itForm.controls['IT1']">
                      <input
                        class="table-checkbox"
                        type="checkbox"
                        maxlength="4"
                        autocomplete="off"
                        formControlName="enabled"
                        [value]="itForm.controls['IT6']?.value.enabled"
                        ngbTooltip="En cas de désactivation l'indicateur apparaît grisé et n'est pas comptabilisé dans le calcul du Niveau de Tension Global."
                        (keyup)="comaToDot($event)"
                      />
                    </td>
                    <th style="text-align: start; padding-left: 20%">
                      Activation de l'indicateur
                    </th>
                    <td [formGroup]="itForm.controls['IT2']">
                      <input
                        class="table-checkbox"
                        type="checkbox"
                        maxlength="4"
                        autocomplete="off"
                        formControlName="enabled"
                        [value]="itForm.controls['IT2']?.value.enabled"
                        ngbTooltip="En cas de désactivation l'indicateur apparaît grisé et n'est pas comptabilisé dans le calcul du Niveau de Tension Global."
                        (keyup)="comaToDot($event)"
                      />
                    </td>
                    <td [formGroup]="itForm.controls['IT3']">
                      <input
                        class="table-checkbox"
                        type="checkbox"
                        maxlength="4"
                        autocomplete="off"
                        formControlName="enabled"
                        [value]="itForm.controls['IT3']?.value.enabled"
                        ngbTooltip="En cas de désactivation l'indicateur apparaît grisé et n'est pas comptabilisé dans le calcul du Niveau de Tension Global."
                        (keyup)="comaToDot($event)"
                      />
                    </td>
                    <td [formGroup]="itForm.controls['IT4']">
                      <input
                        class="table-checkbox"
                        type="checkbox"
                        maxlength="4"
                        autocomplete="off"
                        formControlName="enabled"
                        [value]="itForm.controls['IT4']?.value.enabled"
                        ngbTooltip="En cas de désactivation l'indicateur apparaît grisé et n'est pas comptabilisé dans le calcul du Niveau de Tension Global."
                        (keyup)="comaToDot($event)"
                      />
                    </td>
                    <td [formGroup]="itForm.controls['IT5']">
                      <input
                        class="table-checkbox"
                        type="checkbox"
                        maxlength="4"
                        autocomplete="off"
                        formControlName="enabled"
                        [value]="itForm.controls['IT5']?.value.enabled"
                        ngbTooltip="En cas de désactivation l'indicateur apparaît grisé et n'est pas comptabilisé dans le calcul du Niveau de Tension Global."
                        (keyup)="comaToDot($event)"
                      />
                    </td>
                    <td [formGroup]="itForm.controls['IT6']">
                      <input
                        class="table-checkbox"
                        type="checkbox"
                        maxlength="4"
                        autocomplete="off"
                        formControlName="enabled"
                        [value]="itForm.controls['IT6']?.value.enabled"
                        ngbTooltip="En cas de désactivation l'indicateur apparaît grisé et n'est pas comptabilisé dans le calcul du Niveau de Tension Global."
                        (keyup)="comaToDot($event)"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
          </div>
        </div>
      </div>

      <hr />

      <div class="row">
        <div class="col-12">
          <h3 ngbTooltip="Dispo en lit comptabilisées pour le calcul d'IT1">
            IT1: typage des lits disponibles
          </h3>
        </div>
      </div>
      <div class="row" class="treetable">
        <div class="col-md-12">
          <div class="collapse-button">
            <button
              type="button"
              class="btn btn-sm ml-2"
              (click)="acc.expandAll()"
            >
              Tout développer
            </button>
            <button
              type="button"
              class="btn btn-sm ml-2"
              (click)="acc.collapseAll()"
            >
              Tout réduire
            </button>
          </div>
          <ngb-accordion [closeOthers]="false" #acc="ngbAccordion">
            <ng-container *ngFor="let nivAgg of inputHierarchy | keyvalue">
              <ngb-panel [id]="nivAgg.value[0].codNivAgg">
                <ng-template ngbPanelTitle>
                  <div class="niv-agg">
                    <i class="ion-ios-arrow-down"></i
                    >{{ nivAgg.value[0].nomNivAgg }}
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <form [formGroup]="classificationForm">
                    <ul>
                      <li *ngFor="let nivSemiAgg of nivAgg.value; let index">
                        <div class="row">
                          <div class="niv-sem-agg col-md-4">
                            {{ nivSemiAgg.nomNivSemAgg }}
                          </div>
                          <div class="col-md-2">
                            <input
                              [id]="
                                nivSemiAgg.codNivSemAgg + index + 'medecine'
                              "
                              type="radio"
                              name="{{
                                nivAgg.value[0].codNivAgg +
                                  '-' +
                                  nivSemiAgg.codNivSemAgg
                              }}"
                              [value]="1"
                              formControlName="{{
                                nivAgg.value[0].codNivAgg +
                                  '-' +
                                  nivSemiAgg.codNivSemAgg
                              }}"
                            />
                            <label
                              [for]="
                                nivSemiAgg.codNivSemAgg + index + 'medecine'
                              "
                              >Médecine</label
                            >
                          </div>
                          <div class="col-md-2">
                            <input
                              [id]="
                                nivSemiAgg.codNivSemAgg + index + 'chirurgie'
                              "
                              type="radio"
                              name="{{
                                nivAgg.value[0].codNivAgg +
                                  '-' +
                                  nivSemiAgg.codNivSemAgg
                              }}"
                              [value]="0"
                              formControlName="{{
                                nivAgg.value[0].codNivAgg +
                                  '-' +
                                  nivSemiAgg.codNivSemAgg
                              }}"
                            />
                            <label
                              [for]="
                                nivSemiAgg.codNivSemAgg + index + 'chirurgie'
                              "
                              >Chirurgie</label
                            >
                          </div>
                          <div class="col-md-2">
                            <input
                              [id]="nivSemiAgg.codNivSemAgg + index + 'SO'"
                              [value]="-2"
                              formControlName="{{
                                nivAgg.value[0].codNivAgg +
                                  '-' +
                                  nivSemiAgg.codNivSemAgg
                              }}"
                              type="radio"
                            />
                            <label
                              [for]="nivSemiAgg.codNivSemAgg + index + 'SO'"
                              >Sans objet</label
                            >
                          </div>
                        </div>
                      </li>
                    </ul>
                  </form>
                </ng-template>
              </ngb-panel>
            </ng-container>
          </ngb-accordion>
        </div>
      </div>
      <hr />
    </ng-template>
  </ng-container>
</nav>

<div [ngbNavOutlet]="nav" class="mt-2"></div>
