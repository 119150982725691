import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { filter } from "rxjs/operators";
import { Alert, AlertType } from "../model/alert.model";
import { HttpClient } from "@angular/common/http";

@Injectable({ providedIn: "root" })
export class AlertService {
  constructor(private httpClient: HttpClient) {}
  private subject = new Subject<Alert>();
  private defaultId = "default-alert";

  public getAlerts(): Observable<Alert[]> {
    return this.httpClient.get<Alert[]>(`/api/alerts`);
  }

  // public getAlertsByPageAlert(pageAlert: string): Observable<Alert[]> {
  //   return this.httpClient.get<Alert[]>(`/api/alerts/${pageAlert}`);
  // }

  public getAlertsByPageAlert(
    codReg: number,
    pageAlert: string
  ): Observable<Alert[]> {
    return this.httpClient.get<Alert[]>(`/api/alerts/${codReg}/${pageAlert}`);
  }

  // enable subscribing to alerts observable
  onAlert(id = this.defaultId): Observable<Alert> {
    return this.subject.asObservable().pipe(filter((x) => x && x.id === id));
  }

  get(alert: Alert) {
    this.alert(
      new Alert({ textAlert: alert.textAlert, colorAlert: alert.colorAlert, pageAlert : alert.pageAlert })
    );
  }

  // main alert method
  alert(alert: Alert) {
    alert.id = alert.id || this.defaultId;

    this.subject.next(alert);
  }

  // clear alerts
  clear(id = this.defaultId) {
    this.subject.next(new Alert({ id }));
  }
}
