import { Component, Input, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { PdsaPosteActivityService } from '../pdsa-poste-activity-service';
import { ActivatedRoute } from '@angular/router';
import { PostedConsultationEntryService } from 'src/app/pdsa/service/activity/posted-consultation-entry.service';
import { PostedConsultationEntry } from 'src/app/pdsa/model/activity/posted-consultation-entry.model';
import { PdsaPosteActivityFormComponent } from '../pdsa-poste-activity-form.component';
import Swal from 'sweetalert2';
import { EventType } from 'src/app/pdsa/model/activity/effector-event-type.enum';

@Component({
  selector: 'app-pdsa-poste-activity-tab-two',
  templateUrl: './pdsa-poste-activity-tab-two.component.html',
  styleUrls: ['./pdsa-poste-activity-tab-two.component.scss'],
  providers: [PdsaPosteActivityService]
})
export class PdsaPosteActivityTabTwoComponent implements OnInit {
  currentPage: number;
  effectorId: number;
  effectorYearMonth: string;
  step: number = 2;
  commentText: string | undefined = '';
  openCommentLet = false;
  hasCommentEntered = this.pdsaPosteActivityFormComponent.hasCommentEntered;
  @Input() form!: FormGroup;

  items = ['Exemple 1'];
  @Output() hasCommentChange = new EventEmitter<boolean>();
  @Output() onSubmitEvent: EventEmitter<any> = new EventEmitter();
  hasComment = false;
  postedConsultationEntry: PostedConsultationEntry;
  selectedPostedConsultationEntry: PostedConsultationEntry;
  valueHasChanged: boolean = false;

  postedConsultationEntriesForm = new FormGroup({
    crraCount: new FormControl(''),
    count: new FormControl(''),
    entry: new FormGroup({
      yearMonth: new FormControl(''),
      effector: new FormGroup({
        id: new FormControl('')
      })
    }),
    comment: new FormControl(''),
    elements: new FormArray([])
  });
  nativeElement: any;

  constructor(
    private fb: FormBuilder,
    private pdsaPosteActivityService: PdsaPosteActivityService,
    private readonly route: ActivatedRoute,
    private postedConsultationEntryService: PostedConsultationEntryService,
    private pdsaPosteActivityFormComponent: PdsaPosteActivityFormComponent
  ) {}

  openComment() {
    this.openCommentLet = true;
  }

  updateSubmitButton() {
    const countValue = this.postedConsultationEntriesForm.get('count')?.value;
    const crraCountValue =
      this.postedConsultationEntriesForm.get('crraCount')?.value;
    const commentValue =
      this.postedConsultationEntriesForm.get('comment')?.value;
    this.postedConsultationEntriesForm
      .get('entry')
      ?.get('comment')
      ?.setValue(commentValue);
    //Est-ce que le pb de comment viendrait de là ?
    const isDataEntered =
      countValue ||
      crraCountValue ||
      this.pdsaPosteActivityFormComponent.hasValuesEntered;

    this.pdsaPosteActivityFormComponent.disableButton = !isDataEntered;

    this.pdsaPosteActivityFormComponent.tooltipMessage = isDataEntered
      ? ''
      : 'Aucune donnée saisie';
    this.pdsaPosteActivityFormComponent.cursorStyle = isDataEntered
      ? 'pointer'
      : 'not-allowed';
    this.pdsaPosteActivityFormComponent.colorStyle = isDataEntered
      ? '#56CC9D'
      : '#abdbe3';
    this.pdsaPosteActivityFormComponent.colorStyleDark = isDataEntered
      ? '#1E3050'
      : '#56647C';
  }

  toggleComment() {
    this.hasComment = !this.hasComment;
    this.hasCommentChange.emit(this.hasComment);
  }

  ngOnInit() {
    this.pdsaPosteActivityService.currentPage$.subscribe((page) => {
      this.currentPage = page;
    });
    this.form.addControl(
      'tab-two-form',
      this.fb.group({
        test: 'test'
      })
    );
    this.getEffectorId()
      .then((effectorId) => {
        return effectorId;
      })
      .then((effectorId) => {
        return this.getEffectorYearMonth().then((effectorYearMonth) => {
          return { effectorId, effectorYearMonth };
        });
      })
      .then(({ effectorId, effectorYearMonth }) => {
        return this.getPostedConsultationEntries(effectorId, effectorYearMonth);
      })
      .then((postedConsultationEntry) => {
        this.postedConsultationEntry = postedConsultationEntry;
        this.postedConsultationEntry.entry.step =
          this.pdsaPosteActivityFormComponent.updateStep(this.step);
        if (this.pdsaPosteActivityFormComponent.commentText) {
          this.commentText = this.pdsaPosteActivityFormComponent.commentText;
        }
        this.updateSubmitButton();
        this.pdsaPosteActivityFormComponent.activeTab = 2;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getEffectorId(): Promise<number> {
    return new Promise((resolve, reject) => {
      this.route.url.subscribe((segments) => {
        if (segments.length > 0) {
          const lastSegment = segments[segments.length - 1].path;
          const effectorId: number = parseInt(lastSegment, 10);
          resolve(effectorId);
        } else {
          reject("Aucun segment trouvé dans l'URL.");
        }
      });
    });
  }

  getEffectorYearMonth(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.route.url.subscribe((segments) => {
        if (segments.length > 0) {
          const lastSegment = segments[segments.length - 2].path;
          let position = lastSegment.length - 2;
          const effectorYearMonth =
            lastSegment.slice(0, position) + '-' + lastSegment.slice(position);
          resolve(effectorYearMonth);
        } else {
          reject("Aucun segment trouvé dans l'URL.");
        }
      });
    });
  }

  getPostedConsultationEntries(
    id: number = this.effectorId,
    yearMonth: string = this.effectorYearMonth
  ): Promise<PostedConsultationEntry> {
    return new Promise((resolve, reject) => {
      this.postedConsultationEntryService
        .getPostedConsultationEntries(id, yearMonth)
        .subscribe((selectedPostedConsultationEntry) => {
          this.postedConsultationEntry = selectedPostedConsultationEntry;
          this.postedConsultationEntriesForm.patchValue(
            this.postedConsultationEntry
          );
          this.commentText =
            this.pdsaPosteActivityFormComponent.postedConsultationEntries.entry.comment;
          if (this.commentText && this.commentText.trim() !== '') {
            this.pdsaPosteActivityFormComponent.hasCommentEntered = true;
          }
          this.postedConsultationEntry = selectedPostedConsultationEntry;
          return resolve(this.postedConsultationEntry);
        });
    });
  }

  //Function call when click on the button "VALIDER MA SAISIE"
  async onSubmit() {
    const elementsArray = new FormArray([this.postedConsultationEntriesForm]);

    const { hasInvalidValue, hasEmptyValue, hasValue } =
      this.pdsaPosteActivityFormComponent.handleError(elementsArray, [
        'count',
        'crraCount'
      ]);

    if (hasInvalidValue) {
      Swal.fire({
        title: 'Des erreurs ont été détectées. Veuillez corriger vos données.',
        icon: 'error',
        confirmButtonColor: '#2B8CA9',
        confirmButtonText: 'OK'
      });
      return;
    }

    if (hasEmptyValue) {
      const result = await Swal.fire({
        title:
          "Certaines données n'ont pas été saisies. Etes-vous sûr de vouloir continuer ?",
        icon: 'info',
        showDenyButton: true,
        confirmButtonColor: '#56CC9D',
        confirmButtonText: 'Oui',
        denyButtonText: 'Non',
        denyButtonColor: '#E0796A'
      });
      if (result.isDenied) {
        return;
      }
    }
    this.getEffectorId()
      .then((effectorId) => {
        return effectorId;
      })
      .then((effectorId) => {
        return this.getEffectorYearMonth().then((effectorYearMonth) => {
          return { effectorId, effectorYearMonth };
        });
      })
      .then(({ effectorId, effectorYearMonth }) => {
        this.pdsaPosteActivityFormComponent.lastStepVisited = this.step;
        this.postedConsultationEntry.entry.step = this.step;
        this.pdsaPosteActivityFormComponent.handleSaveFormTabTwo(
          effectorId,
          effectorYearMonth,
          EventType.SUBMIT,
          hasValue,
          this.step
        );
      })
      .catch((error) => {
        console.error(error);
      });
    this.onSubmitEvent.emit();
  }

  onInputChange() {
    this.valueHasChanged = true;
  }

  //Function call when click on another tab from the tab 2
  async onNavigateSubmit() {
    if (this.valueHasChanged) {
      Swal.fire({
        title: 'Vos dernières modifications ont été enregistrées !',
        icon: 'success',
        confirmButtonColor: '#2B8CA9',
        confirmButtonText: 'OK'
      });
    }

    const elementsArray = new FormArray([this.postedConsultationEntriesForm]);

    const { hasInvalidValue, hasValue } =
      this.pdsaPosteActivityFormComponent.handleError(elementsArray, [
        'count',
        'crraCount'
      ]);

    if (hasInvalidValue) {
      Swal.fire({
        title:
          'Des erreurs ont été détectées et vos modifications n’ont pas pu être sauvegardées. Veuillez vérifier vos données.',
        icon: 'warning',
        confirmButtonColor: '#2B8CA9',
        confirmButtonText: 'OK'
      });
      return;
    }

    this.getEffectorId()
      .then((effectorId) => {
        return effectorId;
      })
      .then((effectorId) => {
        return this.getEffectorYearMonth().then((effectorYearMonth) => {
          return { effectorId, effectorYearMonth };
        });
      })
      .then(({ effectorId, effectorYearMonth }) => {
        this.pdsaPosteActivityFormComponent.lastStepVisited = this.step;
        this.postedConsultationEntry.entry.step = this.step;
        this.pdsaPosteActivityFormComponent.handleSaveFormTabTwo(
          effectorId,
          effectorYearMonth,
          EventType.NAVIGATE_TO_TAB,
          hasValue,
          this.step
        );
      })
      .catch((error) => {
        console.error(error);
      });
    this.onSubmitEvent.emit();
  }

  //Function call when click on the button "Etape suivante" on the tab 2
  async onSubmitAndSelectNextTab() {
    const elementsArray = new FormArray([this.postedConsultationEntriesForm]);
    this.pdsaPosteActivityFormComponent;
    if (
      !this.pdsaPosteActivityFormComponent.hasValuesEntered &&
      elementsArray.untouched
    ) {
      return;
    }

    const { hasInvalidValue, hasEmptyValue, hasValue } =
      this.pdsaPosteActivityFormComponent.handleError(elementsArray, [
        'count',
        'crraCount'
      ]);

    if (hasInvalidValue) {
      Swal.fire({
        title: 'Des erreurs ont été détectées. Veuillez corriger vos données.',
        icon: 'error',
        confirmButtonColor: '#2B8CA9',
        confirmButtonText: 'OK'
      });
      return;
    }

    if (hasEmptyValue) {
      const result = await Swal.fire({
        title:
          "Certaines données n'ont pas été saisies. Etes-vous sûr de vouloir continuer ?",
        icon: 'info',
        showDenyButton: true,
        confirmButtonColor: '#56CC9D',
        confirmButtonText: 'Oui',
        denyButtonText: 'Non',
        denyButtonColor: '#E0796A'
      });
      if (result.isDenied) {
        return;
      }
    }

    this.getEffectorId()
      .then((effectorId) => {
        return effectorId;
      })
      .then((effectorId) => {
        return this.getEffectorYearMonth().then((effectorYearMonth) => {
          return { effectorId, effectorYearMonth };
        });
      })
      .then(({ effectorId, effectorYearMonth }) => {
        this.pdsaPosteActivityFormComponent.lastStepVisited = this.step;
        this.postedConsultationEntry.entry.step = this.step;
        this.pdsaPosteActivityFormComponent.handleSaveFormTabTwo(
          effectorId,
          effectorYearMonth,
          EventType.NAVIGATE_NEXT,
          hasValue,
          this.step
        );
      })
      .catch((error) => {
        console.error(error);
      });
    this.onSubmitEvent.emit();
  }

  //Function call when click on the button "Etape précédente" on the tab 2
  async onSubmitAndSelectPreviousTab() {
    const elementsArray = new FormArray([this.postedConsultationEntriesForm]);

    const { hasInvalidValue, hasEmptyValue, hasValue } =
      this.pdsaPosteActivityFormComponent.handleError(elementsArray, [
        'count',
        'crraCount'
      ]);

    if (hasInvalidValue) {
      Swal.fire({
        title: 'Des erreurs ont été détectées. Veuillez corriger vos données.',
        icon: 'error',
        confirmButtonColor: '#2B8CA9',
        confirmButtonText: 'OK'
      });
      return;
    }

    if (hasEmptyValue) {
      const result = await Swal.fire({
        title:
          "Certaines données n'ont pas été saisies. Etes-vous sûr de vouloir continuer ?",
        icon: 'info',
        showDenyButton: true,
        confirmButtonColor: '#56CC9D',
        confirmButtonText: 'Oui',
        denyButtonText: 'Non',
        denyButtonColor: '#E0796A'
      });
      if (result.isDenied) {
        return;
      }
    }

    this.getEffectorId()
      .then((effectorId) => {
        return effectorId;
      })
      .then((effectorId) => {
        return this.getEffectorYearMonth().then((effectorYearMonth) => {
          return { effectorId, effectorYearMonth };
        });
      })
      .then(({ effectorId, effectorYearMonth }) => {
        this.pdsaPosteActivityFormComponent.lastStepVisited = this.step;
        this.postedConsultationEntry.entry.step = this.step;
        this.pdsaPosteActivityFormComponent.handleSaveFormTabTwo(
          effectorId,
          effectorYearMonth,
          EventType.NAVIGATE_PREVIOUS,
          hasValue,
          this.step
        );
      })
      .catch((error) => {
        console.error(error);
      });
    this.onSubmitEvent.emit();
  }

  //Function when click on the cross when on tab 2
  async onConfirm() {
    const elementsArray = new FormArray([this.postedConsultationEntriesForm]);

    const { hasInvalidValue, hasEmptyValue, hasValue } =
      this.pdsaPosteActivityFormComponent.handleError(elementsArray, [
        'count',
        'crraCount'
      ]);

    if (hasInvalidValue) {
      Swal.fire({
        title: 'Des erreurs ont été détectées. Veuillez corriger vos données.',
        icon: 'error',
        confirmButtonColor: '#2B8CA9',
        confirmButtonText: 'OK'
      });
      return;
    }
    if (hasEmptyValue) {
      const result = await Swal.fire({
        title:
          "Certaines données n'ont pas été saisies. Etes-vous sûr de vouloir continuer ?",
        icon: 'info',
        showDenyButton: true,
        confirmButtonColor: '#56CC9D',
        confirmButtonText: 'Oui',
        denyButtonText: 'Non',
        denyButtonColor: '#E0796A'
      });
      if (result.isDenied) {
        return;
      }
    }
    this.getEffectorId()
      .then((effectorId) => {
        return effectorId;
      })
      .then((effectorId) => {
        return this.getEffectorYearMonth().then((effectorYearMonth) => {
          return { effectorId, effectorYearMonth };
        });
      })
      .then(({ effectorId, effectorYearMonth }) => {
        this.pdsaPosteActivityFormComponent.lastStepVisited = this.step;
        this.postedConsultationEntry.entry.step = this.step;
        this.pdsaPosteActivityFormComponent.handleSaveFormTabTwo(
          effectorId,
          effectorYearMonth,
          EventType.CLOSE,
          hasValue,
          this.step
        );
      })
      .catch((error) => {
        console.error(error);
      });
    this.onSubmitEvent.emit();
  }
}
