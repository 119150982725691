import { NgModule } from "@angular/core";
import { ExtraOptions, RouterModule, Routes } from "@angular/router";
import { environment } from "src/environments/environment";

const enableTracing = !environment.production;
const routes: Routes = [];
const routerOptions: ExtraOptions = {
  scrollPositionRestoration: "enabled",
  anchorScrolling: "enabled",
};

@NgModule({
  // imports: [RouterModule.forRoot(routes, routerOptions)],
  imports: [
    RouterModule.forRoot([], {
      enableTracing,
      relativeLinkResolution: "legacy",
      anchorScrolling: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
