import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
    providedIn:'root'
})

export class PresenceEmergencyService {
    constructor(
        private httpClient: HttpClient,
    ) { }

    public getPresence(): Observable<Number> {
        return this.httpClient.get<Number>(
            `api/presence-su`
        )
    }
}