import {
  Component,
  Directive,
  OnInit,
  Renderer2,
  ElementRef,
} from "@angular/core";
import { Title } from "@angular/platform-browser";
import { AlertService } from "src/app/emergency-depts/services/alert.service";
import { Alert } from "src/app/emergency-depts/model/alert.model";
import { ActivatedRoute, Router } from "@angular/router";
import { AccountService } from "../core/auth/account.service";
import { Account } from "../core/auth/account.model";

@Component({
  selector: "app-iframe-snp-qualite",
  templateUrl: "./iframe-snp-qualite.component.html",
  styleUrls: ["./iframe-snp-qualite.component.scss"],
})
export class IframeSnpQualiteComponent implements OnInit {
  alerts: Alert[] = [];
  account: any;
  isDisplayed: boolean = false;

  constructor(
    private alertService: AlertService,
    private readonly titleService: Title,
    private readonly route: ActivatedRoute,
    private accountService: AccountService
  ) {}

  ngOnInit() {
    this.accountService.identity().subscribe((account) => {
      this.account = account;
      console.warn("account change", account);
    });
    this.titleService.setTitle(this.route.snapshot.data["title"]);
    const Reg = this.isAraPage() ? 84 : 11;
    if (this.isAraPage() === true) {
      this.alertService
        .getAlertsByPageAlert(Reg, "DONNEES-DES-URGENCES_SNP-Qualité")
        .subscribe((res: Alert[]) => {
          this.alerts = res;
          console.log("alert", res);
          console.log("alert.length", this.alerts.length);
          console.log("FDGHJUIOPOHGFDFGHJKL", this.isAlert());
          res.map((alert) => this.alertService.get(alert));
        });

    } else {
      this.alertService
        .getAlertsByPageAlert(Reg, "URGENCES_SNP-Qualité")
        .subscribe((res: Alert[]) => {
          this.alerts = res;
          console.log("alert", res);
          console.log("alert.length", this.alerts.length);
          console.log("FDGHJUIOPOHGFDFGHJKL", this.isAlert());
          res.map((alert) => this.alertService.get(alert));
        });
    }
    this.showAccessDeniedDiv();
  }


  showAccessDeniedDiv() {
    setTimeout(() => {
      this.isDisplayed = true;
    }, 2000);
  }

  isAccess() {
    if (this.account?.authorities.includes("11") || this.account?.authorities.includes("43") ) {
      return true
    }
    else {
      return false
    }
  }

  isAraPage() {
    const str = window.location.href;
    return str.includes("ara") || str.includes(":4201/");
  }

  isAlert() {
    if (this.alerts.length != 0) {
      return true;
    } else {
      return false;
    }
  }

  isPreprod() {
    const str = window.location.href;
    return str.includes("ppr");
  }
}
