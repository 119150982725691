<div class="div-alert">
  <alert class="alert"></alert>
</div>
<div
  class="container col-md-11"
  style="padding-bottom: 2rem; padding-top: 2rem"
>
  <div class="row positionNbOfMonth">
    <h1 class="iPosition">Déclarez votre activité de PDSA</h1>
    <div
      class="row mb-md-3 mt-2 mr-3 justify-content-end"
      *ngIf="account?.authorities.includes('71')"
    >
      <div class="d-flex justify-content-end">
        Nb de mois saisissables :
        <input
          class="text rounded border border-secondary ml-1 widthMonthInput text-center"
          value="{{ enterableNumberOfMonths }}"
          (change)="changeNumberOfMonths($event)"
        />
      </div>
    </div>
  </div>

  <div class="main mb-5">
    <form>
      <div class="my-4 ml-3">
        <div class="table-responsive">
          <table class="table table-striped p-3 bg-white">
            <thead>
              <tr class="noselect header-row">
                <th
                  style="padding: 0.5rem"
                  class="widthQueriesStructure specificColor"
                >
                  <div class="tableTop row">
                    <div class="col-12 col-md-3 cusorPointer p-0">
                      <p
                        (click)="changeStyle('departmentCode')"
                        [appSort]="activityEffectors"
                        data-order="desc"
                        data-name="departmentCode"
                        class="mb-0 placeOfStructureText"
                      >
                        Structure&nbsp;
                        <img
                          src="../../../../assets/images/white_down.svg"
                          class="icon-sort"
                          [style]="{
                            transform:
                              changeRotate.departmentCode === true
                                ? 'rotate(180deg)'
                                : ''
                          }"
                        />
                      </p>
                    </div>
                    <div class="col-12 col-md-9 ml-4 ml-md-0 pl-5">
                      <div class="row d-flex search-area">
                        <input
                          class="form-control positionSearchInput widthSearchBar"
                          type="text"
                          (keyup)="filterData($event)"
                          autocomplete="off"
                          placeholder="Rechercher..."
                        />
                      </div>
                    </div>
                  </div>
                </th>
                <th
                  style="
                    width: 4vw;
                    padding: 0.5rem;
                    text-align: center;
                    vertical-align: middle;
                  "
                  *ngFor="
                    let month of monthList.slice(0, enterableNumberOfMonths)
                  "
                  class="scrollable-month specificColor"
                >
                  <p class="m-0 sizeWeigthFont">
                    {{
                      month.label.charAt(0).toUpperCase() +
                        month.label.slice(1).toLowerCase()
                    }}
                  </p>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let effector of activityEffectors
                    | paginate
                      : {
                          itemsPerPage: tableSize,
                          currentPage: page,
                          totalItems: count
                        };
                  let i = index
                "
                class="cursor noselect hover-effect"
              >
                <td class="ml-2 widthQueriesStructure">
                  <div class="row">
                    <div
                      class="col-10 fontSizeMobile"
                      [title]="effector.shortLabel"
                    >
                      {{ effector.departmentCode }} -
                      {{
                        enterableNumberOfMonths >= 7
                          ? effector.shortLabel.length > 23
                            ? (effector.shortLabel | slice: 0 : 23) + '...'
                            : effector.shortLabel
                          : effector.shortLabel.length > 30
                          ? (effector.shortLabel | slice: 0 : 30) + '...'
                          : effector.shortLabel
                      }}
                    </div>
                    <div class="col-2 d-flex justify-content-md-end">
                      <button
                        class="buttonEye"
                        (click)="open(content, effector)"
                        type="button"
                      >
                        <img
                          src="../../../../assets/images/eye.png"
                          ngbTooltip="Vérifier mes informations"
                          placement="left"
                          class="eyeToModifyStructure"
                        />
                      </button>
                    </div>
                  </div>
                </td>
                <td *ngFor="let month of monthList" class="zIndexOtherColumn">
                  <div class="d-flex justify-content-center align-items-center">
                    <div
                      (click)="goToForm(effector, month)"
                      class="{{ getStateClassFor(effector, month) }} px-3"
                    >
                      {{ getStateLabelFor(effector, month) }}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </form>

    <!-- <ngb-tooltip-window role="tooltip" id="ngb-tooltip-1" class="tooltip fade show bs-tooltip-top" style="position: absolute; top: 0px; left: 0px; will-change: transform; transform: translate(107px, 510px);">
      <div class="arrow">::before</div>
      <div class="tooltip-inner">
        Vous avez validé votre déclaration, mais vous pouvez toujours la modifier
      </div>
    </ngb-tooltip-window> -->

    <div class="row d-flex justify-content-between mr-1 ml-3">
      <div class="row mb-3 desktopDisplayLegend">
        <p class="ml-3 mr-3 mb-0 textWeight">Etat de la saisie :</p>
        <div class="custom-tooltip-1-style">
          <div
            class="legend-state-closed px-3 mr-2"
            ngbTooltip="Vous avez validé votre déclaration, mais vous pouvez toujours la modifier"
          >
            Modifier
          </div>
        </div>
        <div class="d-flex align-items-center mr-4 fontSize">Validée</div>
        <div class="custom-tooltip-2-style">
          <div
            class="legend-state-open"
            ngbTooltip="Complétez et validez votre déclaration pour la finaliser"
          >
            Compléter
          </div>
        </div>
        <div class="d-flex align-items-center mr-4 fontSize">Partielle</div>
        <div class="custom-tooltip-3-style">
          <div
            class="legend-state-null px-3 mr-2"
            ngbTooltip="Déclarez votre activité"
          >
            Saisir
          </div>
        </div>
        <div class="mr-3 d-flex align-items-center fontSize">Nulle</div>
      </div>
      <div
        class="row d-flex align-items-center"
        style="float: right; margin-left: 0px; margin-right: 0px"
      >
        <pagination-controls
          (pageChange)="page = $event"
          previousLabel=""
          nextLabel=""
          class="pagination"
        ></pagination-controls>
      </div>
    </div>
  </div>

  <div class="col-12 mobileDisplayLegend mb-2">
    <div class="row">
      <div class="d-flex align-items-center col-12 p-0 mb-1 mobileFont">
        Etat de la saisie :
      </div>
      <div class="col-12 mb-2">
        <div class="row">
          <div class="pdsa-state-closed px-3 mr-2">Modifier</div>
          <div class="d-flex align-items-center mobileFont">Validée</div>
        </div>
      </div>
      <div class="col-12 mb-2">
        <div class="row">
          <div class="pdsa-state-open px-3 mr-2">Compléter</div>
          <div class="d-flex align-items-center mobileFont">Partielle</div>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="pdsa-state-null px-3 mr-2">Saisir</div>
          <div class="mr-3 d-flex align-items-center mobileFont">Nulle</div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- MODALE -->
<div class="modal" id="myModal">
  <div class="modal-dialog modal-dialog-centered modal-xl">
    <div class="modal-content">
      <ng-template #content let-modal sizeOfModal>
        <div class="modal-header borderTitle">
          <h1 class="modal-title titleModal" id="modal-basic-title">
            {{ selectedEffector.arsLabel }}
          </h1>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body pb-0">
          <form [formGroup]="addressForm">
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label for="labelAddress1">Adresse</label>
                      <input
                        type="text"
                        class="form-control mb-2"
                        id="address1"
                        formControlName="labelAddress1"
                        name="labelAddress1"
                        ngbTooltip="Adresse de la structure"
                        disabled="true"
                      />
                    </div>
                  </div>
                  <!-- <p>{{ selectedAddress.labelAddress1 }}</p> -->
                  <div class="col-12">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control mb-2"
                        id="address2"
                        formControlName="labelAddress2"
                        name="labelAddress2"
                        ngbTooltip="Adresse"
                        disabled="true"
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control mb-2"
                        id="address3"
                        formControlName="labelAddress3"
                        name="labelAddress3"
                        ngbTooltip="Adresse"
                        disabled="true"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-3 mb-3 mb-md-0">
                    <input
                      type="text"
                      class="form-control"
                      id="postalCode"
                      formControlName="postalCode"
                      name="postalCode"
                      ngbTooltip="Code postal"
                      disabled="true"
                    />
                  </div>
                  <div class="col-12 col-md-9 mb-1 mb-md-0">
                    <input
                      type="text"
                      class="form-control"
                      id="labelCity"
                      formControlName="labelCity"
                      name="labelCity"
                      ngbTooltip="Commune"
                      disabled="true"
                    />
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label for="phoneNumber">Téléphone</label>
                      <input
                        type="text"
                        class="form-control"
                        id="phoneNumber"
                        formControlName="phoneNumber"
                        name="phoneNumber"
                        ngbTooltip="Numéro de téléphone"
                        disabled="true"
                      />
                    </div>
                    <div class="form-group">
                      <label for="webSite">Site internet</label>
                      <input
                        type="text"
                        class="form-control"
                        id="webSite"
                        formControlName="webSite"
                        name="webSite"
                        ngbTooltip="Site internet"
                        disabled="true"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div class="row mb-2 mt-3">
            <div class="col-12">
              <div class="row">
                <div class="col-12">Contacts de la structure</div>
              </div>
            </div>
          </div>

          <div class="row" *ngFor="let user of selectedUsers">
            <div class="col-12 col-md-6">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      [value]="user.firstName + ' ' + user.lastName"
                      ngbTooltip="Prénom Nom"
                      [disabled]="true"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="row">
                <div class="col-3">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      [value]="user.phone || ''"
                      ngbTooltip="Numéro de téléphone"
                      [disabled]="true"
                    />
                  </div>
                </div>
                <div class="col-9">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      [value]="user.mail"
                      ngbTooltip="Email"
                      [disabled]="true"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn buttonColor"
              (click)="openReportChange(selectedEffector)"
            >
              Signaler un changement
            </button>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
