export class Sort {

    private sortOrder = 1;
    private collator = new Intl.Collator(undefined, {
        numeric: true,
        sensitivity: 'base',
    });

    constructor() {}

    public startSort(property, order) {
        if (order === 'desc') {
            this.sortOrder = -1;
        }
        return (a, b) => {
          if (a[property] instanceof Date && b[property] instanceof Date) {
            return this.collator.compare(a[property].getTime(), b[property].getTime()) * this.sortOrder;
          } else {
            return this.collator.compare(a[property], b[property]) * this.sortOrder;
          }
        };

    }

    private sortData(a, b) {
        if (a < b) {
            return -1 * this.sortOrder;
        } else if (a > b ) {
            return 1 * this.sortOrder;
        } else {
            return 0 * this.sortOrder;
        }
    }
}
