<form
  [formGroup]="mobileDeathVisitRangeForm"
  (ngSubmit)="onSubmit(eventTypes.CLOSE)"
>
  <div class="table-responsive">
    <table class="table table-striped shadow-sm p-3 bg-white">
      <thead>
        <tr class="noselect header-row">
          <th
            style="max-width: 15vw; width: 15vw; padding: 0px; margin: 0px"
          ></th>
          <th
            *ngFor="let timeSlotLabel of timeSlotLabels; let i = index"
            style="max-width: 4vw; margin: 0px"
          >
            <p style="color: #ffffff">{{ timeSlotLabel }}</p>
            <p style="color: #ffffff">{{ timeSlotDayTypes[i] }}</p>
          </th>
        </tr>
      </thead>
      <tbody formArrayName="elements">
        <tr class="cursor noselect hover-effect">
          <td>
            <div class="d-flex align-items-center">
              <div class="fontSize">
                Nombre total de visites à domicile effectuées pour certificat de
                décès
              </div>
            </div>
          </td>

          <td
            *ngFor="
              let elementGroup of mobileDeathVisitRangeForm.get('elements')
                ?.controls;
              let i = index
            "
          >
            <div class="d-flex justify-content-center align-items-center">
              <input
                type="text"
                class="rounded border inputSize"
                style="width: 4vw; height: 2vw"
                [ngClass]="{
                  error: elementGroup.get('count').hasError('invalidValue'),
                  warning: elementGroup.get('count').hasError('emptyValue')
                }"
                [formControl]="elementGroup.get('count')"
                (input)="this.onInputChange()"
              />
            </div>
          </td>
        </tr>
        <tr class="cursor noselect hover-effect">
          <td>
            <div class="d-flex align-items-center">
              <div class="fontSize">
                Nombre de visites à domicile effectuées pour certificat de décès
                à la demande directe du CRRA15
              </div>
            </div>
          </td>
          <td
            *ngFor="
              let elementGroup of mobileDeathVisitRangeForm.get('elements')
                ?.controls;
              let i = index
            "
          >
            <div class="d-flex justify-content-center align-items-center">
              <input
                type="text"
                class="rounded border inputSize"
                style="width: 4vw; height: 2vw"
                [ngClass]="{
                  error: elementGroup
                    .get('crraCount')
                    ?.hasError('invalidValue'),
                  warning: elementGroup.get('crraCount')?.hasError('emptyValue')
                }"
                [formControl]="elementGroup.get('crraCount')"
                (input)="this.onInputChange()"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <textarea
    class="commentInput"
    *ngIf="openCommentLet && !hasCommentEntered && !isBooleanPlatform"
    formControlName="comment"
    maxlength="450"
    placeholder="Si vous le souhaitez, saisissez ICI votre commentaire puis validez votre saisie..."
  ></textarea>
  <div *ngIf="!hasCommentEntered && !isBooleanPlatform" class="desktopDisplay">
    <div class="col-12 d-flex justify-content-end heightContaineurComment">
      <ng-container *ngIf="!openCommentLet">
        <img
          src="../../../assets/images/comment.svg"
          alt="comment"
          class="comment"
          placement="left"
          (click)="openComment()"
        />
        <span class="ml-2 commentTextSpecifications"
          >Ajouter un commentaire</span
        >
      </ng-container>
    </div>
  </div>
</form>
