import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { ChartService } from "src/app/graph/services/chart.service";

@Component({
  selector: "app-hospitalisations-graph",
  templateUrl: "./hospitalisations-graph.component.html",
  styleUrls: ["./hospitalisations-graph.component.scss"],
})
export class HospitalisationsGraphComponent implements OnInit {
  @Input() dataInd4;
  constructor(private chartService: ChartService) {}

  ngOnInit(): void {}
  ngOnChanges(change: SimpleChanges) {
    this.chartService.initChart(
      "hospitalisations-graph",
      change.dataInd4.currentValue,
      "Nombre d'hospitalisations"
    );
  }
}
