import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Territory } from '../model/territory.model';

@Injectable({
  providedIn: 'root'
})
export class TerritoryService {
  constructor(private httpClient: HttpClient) {}

  public getTerritory(): Observable<Territory[]> {
    return this.httpClient.get<Territory[]>(`/api/territory`);
  }

  public getTerritoryById(id: any): Observable<Territory> {
    return this.httpClient.get<Territory>(`/api/territory/${id}`);
  }

  public updateTerritoryForm(
    id: Number,
    territory: Territory
  ): Observable<Territory> {
    return this.httpClient.put<Territory>(`api/territory/${id}`, territory);
  }

  public setTerritoryForm(territory: Territory): Observable<Territory> {
    return this.httpClient.post<Territory>(`api/territory`, territory);
  }

  public deleteTerritoryForm(id: Number): Observable<Territory> {
    return this.httpClient.delete<Territory>(`api/territory/${id}`);
  }
}
