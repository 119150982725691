<form
  [formGroup]="postedConsultationEntriesForm"
  (ngSubmit)="onSubmit()"
  id="tab_two"
>
  <table class="table table-striped shadow-sm p-3 bg-white">
    <thead>
      <tr class="noselect header-row">
        <th
          style="max-width: 26vw; width: 26vw; padding: 0px; margin: 0px"
        ></th>
        <th style="max-width: 4vw; padding: 0px; margin: 0px">
          <p class="m-1" style="color: #ffffff; font-size: 14px">
            Total des consultations
          </p>
        </th>
        <th style="max-width: 4vw; padding: 0px; margin: 0px">
          <p class="m-1" style="color: #ffffff; font-size: 14px">
            Dont effectuées à la demande directe du CRRA15
          </p>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let item of items; let i = index"
        class="noselect hover-effect"
      >
        <td>
          <div class="d-flex align-items-center">
            <div class="line-name" style="font-size: 14px">
              Nombre de consultations effectuées aux heures de la PDSA
            </div>
          </div>
        </td>
        <td>
          <div class="d-flex justify-content-center align-items-center">
            <input
              type="text"
              class="rounded border inputSize"
              [ngClass]="{
                error: this.postedConsultationEntriesForm
                  .get('count')
                  ?.getError('invalidValue'),
                warning: this.postedConsultationEntriesForm
                  .get('count')
                  ?.getError('emptyValue')
              }"
              formControlName="count"
              (input)="updateSubmitButton()"
              (input)="this.onInputChange()"
            />
          </div>
        </td>
        <td>
          <div class="d-flex justify-content-center align-items-center">
            <input
              type="text"
              class="rounded border inputSize"
              [ngClass]="{
                error: this.postedConsultationEntriesForm
                  .get('crraCount')
                  ?.getError('invalidValue'),
                warning: this.postedConsultationEntriesForm
                  .get('crraCount')
                  ?.getError('emptyValue')
              }"
              formControlName="crraCount"
              (input)="updateSubmitButton()"
              (input)="this.onInputChange()"
            />
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <textarea
    class="commentInput"
    *ngIf="openCommentLet && !hasCommentEntered"
    formControlName="comment"
    maxlength="450"
    placeholder="Si vous le souhaitez, saisissez ICI votre commentaire puis validez votre saisie..."
  ></textarea>
  <div *ngIf="!hasCommentEntered" class="desktopDisplay">
    <div class="col-12 d-flex justify-content-end heightContaineurComment">
      <ng-container *ngIf="!openCommentLet">
        <img
          src="../../../assets/images/comment.svg"
          alt="comment"
          class="comment"
          placement="left"
          (click)="openComment()"
        />
        <span class="ml-2 commentTextSpecifications"
          >Ajouter un commentaire</span
        >
      </ng-container>
    </div>
  </div>
</form>
