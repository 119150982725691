import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PostedConsultationRange } from 'src/app/pdsa/model/activity/posted-consultation-range.model';
import { PostedConsultationRangeService } from 'src/app/pdsa/service/activity/posted-consultation-range.service';
import Swal from 'sweetalert2';
import { PdsaPosteActivityFormComponent } from '../pdsa-poste-activity-form.component';
import { EventType } from 'src/app/pdsa/model/activity/effector-event-type.enum';

@Component({
  selector: 'app-pdsa-poste-activity-tab-one',
  templateUrl: './pdsa-poste-activity-tab-one.component.html',
  styleUrls: ['./pdsa-poste-activity-tab-one.component.scss']
})
export class PdsaPosteActivityTabOneComponent implements OnInit {
  Number(arg0: any): any {
    throw new Error('Method not implemented.');
  }
  @Input() form!: FormGroup;
  effectorId: number;
  effectorYearMonth: string;
  timeSlotLabels: string[] = [];
  timeSlotDayTypes: string[] = [];
  @Output() onSubmitEvent: EventEmitter<any> = new EventEmitter();
  step: number = 1;
  valueHasChanged: boolean = false;

  postedConsultationRangeForm = new FormGroup({
    entry: new FormGroup({
      yearMonth: new FormControl(''),
      effector: new FormGroup({
        id: new FormControl('')
      })
    }),
    elements: new FormArray([])
  });

  postedConsultationRanges: PostedConsultationRange;

  constructor(
    private readonly route: ActivatedRoute,
    private postedConsultationRangeService: PostedConsultationRangeService,
    private fb: FormBuilder,
    private router: Router,
    private pdsaPosteActivityFormComponent: PdsaPosteActivityFormComponent
  ) {}

  getFormGroupFor(control: string): FormGroup {
    return this.form.get(control) as FormGroup;
  }

  callSelectNextTab() {
    this.pdsaPosteActivityFormComponent.selectNextTab();
  }

  ngOnInit() {
    this.form.addControl(
      'tab-one-form',
      this.fb.group({
        test: 'test'
      })
    );
    this.getEffectorId()
      .then((effectorId) => {
        return effectorId;
      })
      .then((effectorId) => {
        return this.getEffectorYearMonth().then((effectorYearMonth) => {
          return { effectorId, effectorYearMonth };
        });
      })
      .then(({ effectorId, effectorYearMonth }) => {
        return this.getPostedConsultationRanges(effectorId, effectorYearMonth);
      })
      .then((elementsArray) => {
        this.pdsaPosteActivityFormComponent.activeTab = 1;
        this.postedConsultationRanges.entry.step =
          this.pdsaPosteActivityFormComponent.updateStep(this.step);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getEffectorId(): Promise<number> {
    return new Promise((resolve, reject) => {
      this.route.url.subscribe((segments) => {
        if (segments.length > 0) {
          const lastSegment = segments[segments.length - 1].path;
          const effectorId: number = parseInt(lastSegment, 10);
          resolve(effectorId);
        } else {
          reject("Aucun segment trouvé dans l'URL.");
        }
      });
    });
  }

  getEffectorYearMonth(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.route.url.subscribe((segments) => {
        if (segments.length > 0) {
          const lastSegment = segments[segments.length - 2].path;
          let position = lastSegment.length - 2;
          const effectorYearMonth =
            lastSegment.slice(0, position) + '-' + lastSegment.slice(position);
          resolve(effectorYearMonth);
        } else {
          reject("Aucun segment trouvé dans l'URL.");
        }
      });
    });
  }

  getPostedConsultationRanges(id: number = this.effectorId, yearMonth: string = this.effectorYearMonth) {
    return new Promise((resolve, reject) => {
      this.postedConsultationRangeService
        .getPostedConsultationRanges(id, yearMonth)
        .subscribe((selectedPostedConsultationRange) => {
          selectedPostedConsultationRange.elements.map(el => {
            this.sortTimeSlotBySortCode(selectedPostedConsultationRange);
          })
    
          //store timeSlot.label on an array to display them on front
          this.timeSlotLabels = selectedPostedConsultationRange.elements.map(
            (element) => element.timeSlot.label
          );
          this.timeSlotDayTypes = selectedPostedConsultationRange.elements.map(
            (element) => element.timeSlot.dayType
          );
          this.postedConsultationRanges = selectedPostedConsultationRange;
          this.postedConsultationRangeForm.patchValue(
            selectedPostedConsultationRange
          );

          this.postedConsultationRanges.entry.comment =
            this.pdsaPosteActivityFormComponent.commentText;
          if (
            this.pdsaPosteActivityFormComponent.commentText &&
            this.pdsaPosteActivityFormComponent.commentText.trim() !== ''
          ) {
            this.pdsaPosteActivityFormComponent.hasCommentEntered = true;
          }

          //for each elements, add a controler on formArray
          const elementsArray = this.postedConsultationRangeForm.controls
            .elements as FormArray;
          //for each elements, add a controler on formArray
          selectedPostedConsultationRange.elements.forEach((element) => {
            const countControl = new FormGroup({
              count: new FormControl(element.count)
            });
            elementsArray.push(countControl);
          });
          return resolve(elementsArray);
        });
    });
  }

  private sortTimeSlotBySortCode(selectedPostedConsultationRange): PostedConsultationRange{
    return selectedPostedConsultationRange.elements.sort((a,b) => a.timeSlot.sortCode - b.timeSlot.sortCode);
  }

  onInputChange() {
    this.pdsaPosteActivityFormComponent.onInputChange();
    this.valueHasChanged = true;
  }

  //ON GARDE croix et next step 1
  async onSubmit(close: boolean = false) {
    const elementsArray = this.postedConsultationRangeForm.get(
      'elements'
    ) as FormArray;

    let event: EventType;

    close ? (event = EventType.CLOSE) : (event = EventType.NAVIGATE_NEXT);

    const { hasInvalidValue, hasEmptyValue, hasValue } =
      this.pdsaPosteActivityFormComponent.handleError(elementsArray, ['count']);

    if (hasInvalidValue) {
      Swal.fire({
        title: 'Des erreurs ont été détectées. Veuillez corriger vos données.',
        icon: 'error',
        confirmButtonColor: '#2B8CA9',
        confirmButtonText: 'OK'
      });
      return;
    }

    if (hasEmptyValue) {
      const result = await Swal.fire({
        title:
          "Certaines données n'ont pas été saisies. Etes-vous sûr de vouloir continuer ?",
        icon: 'info',
        showDenyButton: true,
        confirmButtonColor: '#56CC9D',
        confirmButtonText: 'Oui',
        denyButtonText: 'Non',
        denyButtonColor: '#E0796A'
      });
      if (result.isDenied) {
        return;
      }
    }
    this.postedConsultationRanges =
      await this.pdsaPosteActivityFormComponent.handleSaveFormTabOne(
        this.pdsaPosteActivityFormComponent.effectorId,
        this.pdsaPosteActivityFormComponent.effectorYearMonth,
        event,
        hasValue,
        this.step
      );

    if (!close) {
      this.callSelectNextTab();
      this.onSubmitEvent.emit();
    } else {
      this.router.navigateByUrl('/pdsa-structure-activity').then(() => {
        Swal.fire({
          title: 'Données sauvegardées avec succès !',
          icon: 'success',
          confirmButtonColor: '#2B8CA9',
          confirmButtonText: 'OK'
        });
      });
    }
  }

  //ON GARDE tab
  async onNavigateSubmit() {
    if (this.valueHasChanged) {
      Swal.fire({
        title: 'Vos dernières modifications ont été enregistrées !',
        icon: 'success',
        confirmButtonColor: '#2B8CA9',
        confirmButtonText: 'OK'
      });
    }
    const elementsArray = this.postedConsultationRangeForm.get(
      'elements'
    ) as FormArray;

    const { hasInvalidValue, hasValue } =
      this.pdsaPosteActivityFormComponent.handleError(elementsArray, ['count']);

    if (hasInvalidValue) {
      Swal.fire({
        title:
          'Des erreurs ont été détectées et vos modifications n’ont pas pu être sauvegardées. Veuillez vérifier vos données.',
        icon: 'warning',
        confirmButtonColor: '#2B8CA9',
        confirmButtonText: 'OK'
      });
      return;
    }

    this.postedConsultationRanges =
      await this.pdsaPosteActivityFormComponent.handleSaveFormTabOne(
        this.pdsaPosteActivityFormComponent.effectorId,
        this.pdsaPosteActivityFormComponent.effectorYearMonth,
        EventType.NAVIGATE_TO_TAB,
        hasValue,
        this.step
      );

    this.onSubmitEvent.emit();
  }

  areAnyValuesEntered(): boolean {
    const elementsArray = this.postedConsultationRangeForm.get(
      'elements'
    ) as FormArray;

    for (const elementControl of elementsArray.controls) {
      const elementValue = elementControl.get('count')?.value;
      if (elementValue) {
        return true;
      }
    }

    return false;
  }
}
