import { Component, Input, OnInit } from "@angular/core";
import * as Highcharts from "highcharts";
import { GraphDataModel } from "../../models/graph-data.model";
import { ValueOfGrapsService } from "../services/valueOfGraps.service";

declare var require: any;
let Boost = require("highcharts/modules/boost");
let noData = require("highcharts/modules/no-data-to-display");
let More = require("highcharts/highcharts-more");

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);

@Component({
  selector: "app-ntg-graph",
  templateUrl: "./ntg-graph.component.html",
  styleUrls: ["./ntg-graph.component.scss"],
})
export class NtgGraphComponent implements OnInit {
  @Input() displayPeriod2: boolean;
  options: {};
  ntgBySau: number[] = [];
  dates: string[] = [];
  NTGPOurcentage: GraphDataModel[] = [];
  NTG = [
    {
      name: "NTG1",
      color: "#2bda0f",
    },
    {
      name: "NTG2",
      color: "#acfb14",
    },
    {
      name: "NTG3",
      color: "#fff000",
    },
    {
      name: "NTG4",
      color: "#ffa800",
    },
    {
      name: "NTG5",
      color: "#ff1e00",
    },
    {
      name: "NTG6",
      color: "#c50000",
    },
    {
      name: "NTG7",
      color: "#900000",
    },
  ];
  public names = ["0", "1", "2", "3", "4", "5", "6", "7"];
  public colors = [
    "#D8D8D8",
    "#2bda0f",
    "#acfb14",
    "#fff000",
    "#ffa800",
    "#ff1e00",
    "#c50000",
    "#900000",
    "#000000",
  ];
  sizeOfPeriod: any;
  isPeriod2: any;

  constructor(valueOfGraps: ValueOfGrapsService) {}
  ngOnChanges() {
    console.log("DO CHECK");
  }

  ngOnInit() {}

  refresh() {
    Highcharts.chart("container", this.options);
  }
}
