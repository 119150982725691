import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  AfterViewInit
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { Alert } from '../model/alert.model';
import { DeptSize } from '../model/dept-size.model';
import { HttpClient } from '@angular/common/http';
import { LogicielTechnicalParameter } from '../model/logiciel-technical-parameter.model';

import { EDTerritory } from '../model/ed-territory.model';
import { EmergencyDept } from '../model/emergency-dept.model';
import { GeoEntity } from '../model/geo-entity.model';
import { TechnicalParameter } from '../model/technical-parameter.model';
import { EmergencySpeciality } from '../model/emergency-speciality.model';
import { AlertService } from '../services/alert.service';
import { DeptSizeService } from '../services/dept-size.service';

import { LogicielTechnicalParameterService } from '../services/logiciel-technical-parameter.service';

import { EDTerritoryService } from '../services/ed-territory.service';
import { EmergencyDeptService } from '../services/emergency-dept.service';
import { GeoEntityService } from '../services/geo-entity.service';
import { TechnicalParameterService } from '../services/technical-parameter.service';
import { EmergecencySpecialityService } from '../services/emergency-speciality.service';
import { Title } from '@angular/platform-browser';
import { ContactTechnicalParameterService } from '../services/contact-technical-parameter.service';
import { ContactTechnicalParameter } from '../model/contact-technical-parameter.model';
import { forkJoin, Observable, of, ReplaySubject, Subject } from 'rxjs';

import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  shareReplay,
  tap
} from 'rxjs/operators';
import { Page } from '../../shared/util/page.model';
import { HeaderDefinition } from './header-table/header-table.component';
import { ParameterHetService } from '../services/parameter-het.service';
import { ParameterHet } from '../model/parameter-het.model';
import { ClassificationHetService } from '../services/classification-het.service';
import { ClassificationHet } from '../model/classification-het.model';
import { InputHierarchyService } from '../services/input-hierarchy.service';
import { InputHierarchy } from '../model/input-hierarchy.model';
import { ActivatedRoute, Router } from '@angular/router';
import { PageViewsService } from '@piwikpro/ngx-piwik-pro';
import { AccountService } from 'src/app/core/auth/account.service';
import { Account } from 'src/app/core/auth/account.model';
import { StateStorageService } from 'src/app/core/auth/state-storage.service';
import { User } from 'src/app/shared/models/user.model';
import { UserService } from 'src/app/shared/service/user.service';

@Component({
  selector: 'app-emergency-service-form',
  templateUrl: './cerveau-emergency-service-form.component.html',
  styleUrls: ['./cerveau-emergency-service-form.component.scss']
})
export class CerveauEmergencyServiceFormComponent implements OnInit {
  pageIs: boolean = false;
  @ViewChild('input', { static: true }) input: ElementRef;
  emergencyForm = this.fb.group({
    libelleEGROR: [null, Validators.required],
    codeROR: [],
    libelleEtablissement: new FormControl(
      '{{emergencyForm.value.libelleEtablissement}}'
    ),
    finess: [],
    libelleCourt: [null, [Validators.required, Validators.maxLength(50)]],
    aphp: [],
    libelleSU: [null, [Validators.required, Validators.maxLength(250)]],
    representation: [],
    libelleCourtSU: [null, [Validators.required, Validators.maxLength(50)]],
    typeSU: [],
    user: [],
    typePat: [null, Validators.required],
    pedAdu: [],
    CapUhcd: [],
    CapSauv: [],
    CapBox: [],
    emergencySpeciality: [],
    Lat: [],
    Long: [],
    tauGP: [],
    tauPR: [],
    territory: [null],
    tailleSU: [null],
    duoAut: [],
    FermAdm: []
  });

  technicalParameterForm = this.fb.group({
    codEg: [''],
    codSerUrg: [''],
    fluxRpu: [2],
    fluxAu: [2],
    obs: [null],
    logicielMetier: [-2],
    enabled: []
  });

  // itForm: FormGroup;
  indicateur = ['IT1', 'IT2', 'IT3', 'IT4', 'IT5', 'IT6'];
  itForm = this.fb.group({
    [this.indicateur[0]]: this.fb.group({
      codEg: [''],
      codSerUrg: [],
      it: [''],
      enabled: [],
      region: [],
      calcLis: [],
      coeffSeuil: [],
      coeffOrangeLis: [],
      coeffRougeLis: [],
      coeffOrangeBrut: [],
      coeffRougeBrut: []
    }),
    [this.indicateur[1]]: this.fb.group({
      codEg: [''],
      codSerUrg: [],
      it: [''],
      enabled: [],
      region: [],
      calcLis: [],
      coeffSeuil: [],
      coeffOrangeLis: [],
      coeffRougeLis: [],
      coeffOrangeBrut: [],
      coeffRougeBrut: []
    }),
    [this.indicateur[2]]: this.fb.group({
      codEg: [''],
      codSerUrg: [],
      it: [''],
      enabled: [],
      region: [],
      calcLis: [],
      coeffSeuil: [],
      coeffOrangeLis: [],
      coeffRougeLis: [],
      coeffOrangeBrut: [],
      coeffRougeBrut: []
    }),
    [this.indicateur[3]]: this.fb.group({
      codEg: [''],
      codSerUrg: [],
      it: [''],
      enabled: [],
      region: [],
      calcLis: [],
      coeffSeuil: [],
      coeffOrangeLis: [],
      coeffRougeLis: [],
      coeffOrangeBrut: [],
      coeffRougeBrut: []
    }),
    [this.indicateur[4]]: this.fb.group({
      codEg: [''],
      codSerUrg: [],
      it: [''],
      enabled: [],
      region: [],
      calcLis: [],
      coeffSeuil: [],
      coeffOrangeLis: [],
      coeffRougeLis: [],
      coeffOrangeBrut: [],
      coeffRougeBrut: []
    }),
    [this.indicateur[5]]: this.fb.group({
      codEg: [''],
      codSerUrg: [],
      it: [''],
      enabled: [],
      region: [],
      calcLis: [],
      coeffSeuil: [],
      coeffOrangeLis: [],
      coeffRougeLis: [],
      coeffOrangeBrut: [],
      coeffRougeBrut: []
    })
  });

  contactsToDelete: Array<number> = [];
  contactsToUpdate: Array<any> = [];

  private regionIdString: string;
  private regionRORCode: string;
  regionId: number;
  dis = 'none';
  isNew = false;
  margin = '0%';
  public emergencyDepts: EmergencyDept[] = [];
  public selectedEmergencyDept: EmergencyDept;
  public classifHet: ClassificationHet;
  public selectedClassifHet: ClassificationHet[];

  public technicalParameter: TechnicalParameter;
  public selectedTechnicalParameter: TechnicalParameter;
  public contactTechnicalParameter: ContactTechnicalParameter[] = [];
  public selectedContactTechnicalParameter: ContactTechnicalParameter[] = [];
  public geoEntities: GeoEntity[] = [];
  public selectedEmergencySpeciality: EmergencySpeciality[] = [];
  public selectedLogicielTechnicalParameter: LogicielTechnicalParameter[] = [];
  public PagesOfEmergencyDepts: Page<EmergencyDept>;
  public edTerritories: EDTerritory[] = [];
  public logicielTechnicalParameter: LogicielTechnicalParameter[] = [];
  public parameterHet: ParameterHet[] = [];
  public selectedParameterHet: { [it: string]: ParameterHet };

  public users: User[] = [];
  public emergencySpeciality: EmergencySpeciality[] = [];
  public deptSizes: DeptSize[];
  public doubleauthidden: Boolean = true;
  contactsForm: FormGroup;
  newContactsForm: FormGroup;
  contactsArrayForm: FormArray;

  // it = ["it1", "it2"];

  logicielMetierStartId: number | undefined;
  page: number = 1;
  size: string = '50';
  emptyRows: number[] = [];
  finessAsc: boolean | undefined = undefined;
  libSerUrgAsc: boolean | undefined = undefined;
  idCodSerUrgAsc: boolean | undefined = undefined;
  pedAduAsc: boolean | undefined = undefined;
  enabledAsc: boolean | undefined = undefined;
  deptAsc: boolean | undefined = undefined;
  text: string;
  userQuestionUpdate = new Subject<string>();
  public consoleMessages: string[] = [];
  public userQuestion: string;
  numberOfPages: number;
  alerts: Alert[] = [];
  headerDefintions: HeaderDefinition[] = [
    {
      fieldName: 'DEPT',
      title: 'finess',
      sortAsc: undefined
    },
    {
      fieldName: "SERVICE D'URGENCE",
      title: 'libSerUrg',
      sortAsc: undefined
    },
    {
      fieldName: 'FINESS',
      title: 'finess',
      sortAsc: undefined
    },
    {
      fieldName: 'TYPE DE SAU',
      title: 'idCodSerUrg',
      sortAsc: undefined
    },
    {
      fieldName: 'PEDIATRIQUE SUR ADULTE',
      title: 'pedAdu',
      sortAsc: undefined
    },
    {
      fieldName: 'STATUT',
      title: 'enabled',
      sortAsc: undefined
    }
  ];
  classificationHet: ClassificationHet[];
  inputHierarchy: {};
  classificationForm: FormGroup;
  exist: boolean;
  private userIdentity: Account | null = null;
  private authenticationState = new ReplaySubject<Account | null>(1);
  private accountCache$?: Observable<Account | null>;
  public account = this.authenticationState.asObservable();

  constructor(
    private router: Router,
    private readonly titleService: Title,
    private readonly route: ActivatedRoute,
    private deptSizeService: DeptSizeService,
    private emergencyDeptService: EmergencyDeptService,
    private technicalParameterService: TechnicalParameterService,
    private contactTechnicalParameterService: ContactTechnicalParameterService,
    private geoEntityService: GeoEntityService,
    private edTerritoryService: EDTerritoryService,
    private logicielTechnicalParameterService: LogicielTechnicalParameterService,
    private userService: UserService,
    private emergencySpecialityService: EmergecencySpecialityService,
    private fb: FormBuilder,
    private chRef: ChangeDetectorRef,
    private alertService: AlertService,
    private parameterHetService: ParameterHetService,
    private classificationHetService: ClassificationHetService,
    private inputHierarchyService: InputHierarchyService,
    private accountService: AccountService,
    private http: HttpClient,
    private stateStorageService: StateStorageService
  ) {
    if (window.location.href.includes('ara')) {
      this.regionRORCode = 'R82';
      this.regionIdString = '84';
      this.regionId = 84;
    } else {
      this.regionRORCode = 'R11';
      this.regionIdString = '11';
      this.regionId = 11;
    }
    this.userQuestionUpdate
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((value) => {
        this.consoleMessages.push(value);
        this.emergencyDeptService
          .getEmergencyBySearch(
            this.consoleMessages[this.consoleMessages.length - 1],
            this.regionId.toString()
          )
          .subscribe((emergencyDepts) => {
            this.emergencyDepts = emergencyDepts.content;
            this.numberOfPages = emergencyDepts.totalPages;
          });
      });
  }

  ngOnInit() {
    this.titleService.setTitle(this.route.snapshot.data['title']);
    //this.title.setTitle("Paramétrage des SAU");
    const Reg = this.isAraPage() ? 84 : 11;
    this.alertService
      .getAlertsByPageAlert(Reg, 'ADMINISTRATION_Paramétrage-des-SAU')
      .subscribe((res: Alert[]) => {
        this.alerts = res;
        res.map((alert) => this.alertService.get(alert));
      });

    this.emergencyDeptService
      .getEmergencyDepts(this.regionId.toString())
      .subscribe((emergencyDepts) => {
        this.emergencyDepts = emergencyDepts.content;
        this.numberOfPages = emergencyDepts.totalPages;
        this.chRef.detectChanges();

        this.geoEntityService
          .getGeographicEntities()
          .subscribe((geoEntities) => {
            this.geoEntities = geoEntities
              .filter((item) => item.code.startsWith(this.regionRORCode))
              .sort((a, b) => a.name.trim().localeCompare(b.name.trim()));
          });

        this.emergencySpecialityService
          .getEmergencySpeciality()
          .subscribe((emergencySpeciality) => {
            this.emergencySpeciality = emergencySpeciality;
            console.log('em.spe:', this.emergencySpeciality);
          });

        this.userService.getUsers().subscribe((users) => {
          this.users = users
            .map((user) =>
              Object.assign(user, {
                fullName: user.firstName + ' ' + user.lastName + ' ' + user.mail
              })
            )
            .sort((a, b) => a.lastName.trim().localeCompare(b.lastName.trim()));
        });

        this.logicielTechnicalParameterService
          .getLogicielTechnicalParameter()
          .subscribe((logicielTechnicalParameter) => {
            this.logicielTechnicalParameter = logicielTechnicalParameter;
          });

        this.deptSizeService.getDeptSizes().subscribe((deptSizes) => {
          this.deptSizes = deptSizes
            .filter(
              (item) =>
                item.codReg === this.regionIdString || item.codReg === '-2'
            )
            .filter((item) => item.id !== '-1')
            .filter((item) => item.enabled);
        });
        this.edTerritoryService
          .getEDTerritoriesByRegionId(Reg)
          .subscribe((edTerritories) => {
            this.edTerritories = edTerritories;
          });
        this.parameterHetService.getParameterHet().subscribe((parameterHet) => {
          this.parameterHet = parameterHet;
          console.log('PARAMETER HET', parameterHet);
        });
      });
  }

  private authenticate(identity: Account | null): void {
    this.userIdentity = identity;
    this.authenticationState.next(this.userIdentity);
  }

  identity(force?: boolean): Observable<Account | null> {
    if (!this.accountCache$ || force || !this.isAuthenticated()) {
      this.accountCache$ = this.fetch().pipe(
        catchError(() => of(null)),
        tap((account: Account | null) => {
          this.authenticate(account);
          if (account) {
            this.navigateToStoredUrl();
          }
        }),
        shareReplay()
      );
    }
    return this.accountCache$;
  }

  private fetch(): Observable<Account> {
    return this.http.get<Account>('/api/account');
  }

  isAuthenticated(): boolean {
    return this.userIdentity !== null;
  }

  private navigateToStoredUrl(): void {
    // previousState can be set in the authExpiredInterceptor and in the userRouteAccessService
    // if login is successful, go to stored previousState and clear previousState
    const previousUrl = this.stateStorageService.getUrl();
    if (previousUrl) {
      this.stateStorageService.clearUrl();
      this.router.navigateByUrl(previousUrl);
    }
  }

  coordinates(event: MouseEvent): void {
    this.margin = String(((event.clientY - 300) / 1000) * 20) + '%';
  }

  openSau(codEg: string, codSerUrg: number) {
    console.log('codEg, codserurg', codEg, codSerUrg);
    this.contactsToDelete = [];
    this.contactsToUpdate = [];
    this.getField('libelleEGROR').enable();
    this.getField('typeSU').enable();
    this.isNew = false;
    this.getInputHierarchy(codEg, codSerUrg);
    this.emergencyDeptService
      .getEmergencyDept(codEg, codSerUrg)
      .subscribe((emergencyDept) => {
        this.selectedEmergencyDept = emergencyDept;

        this.parameterHetService
          .getParameterHetBySu(codEg, codSerUrg)
          .subscribe((parameterHet) => {
            this.indicateur.forEach((indicateur) => {
              const it = parameterHet[indicateur].it;
              this.itForm.controls[it]?.setValue({
                codEg: parameterHet[indicateur].codEg,
                codSerUrg: parameterHet[indicateur].codSerUrg,
                it: parameterHet[indicateur].it,
                enabled: parameterHet[indicateur].enabled,
                region: parameterHet[indicateur].region,
                calcLis: parameterHet[indicateur].calcLis,
                coeffSeuil: parameterHet[indicateur].coeffSeuil,
                coeffOrangeLis: parameterHet[indicateur].coeffOrangeLis,
                coeffRougeLis: parameterHet[indicateur].coeffRougeLis,
                coeffOrangeBrut: parameterHet[indicateur].coeffOrangeBrut,
                coeffRougeBrut: parameterHet[indicateur].coeffRougeBrut
              });
            });

            this.selectedParameterHet = parameterHet;
          });

        this.classificationHetService
          .getClassificationHetBySU(codEg, codSerUrg)
          .subscribe((classifHet) => {
            console.log('CLASSIFICATION HET GET BY SU', classifHet);
            this.selectedClassifHet = classifHet;
          });

        this.technicalParameterService
          .getTechnicalParameterById(codEg, codSerUrg)
          .subscribe((technicalParameter) => {
            this.selectedTechnicalParameter = technicalParameter;
            console.log('TECHNICAL PARAMETER', technicalParameter);
            this.contactTechnicalParameterService
              .getContactTechnicalParameterBySu(codEg, codSerUrg)
              .subscribe((contactTechnicalParameter) => {
                this.selectedContactTechnicalParameter =
                  contactTechnicalParameter;
                const arrayContactForm: Array<FormGroup> = [];
                this.selectedContactTechnicalParameter.forEach((contact) => {
                  arrayContactForm.push(this.createContact(contact));
                });

                this.newContactsForm = this.fb.group({
                  nomPrenom: '',
                  mail: '',
                  telFixe: '',
                  telMobile: '',
                  editable: false,
                  state: 'new',
                  codEg: codEg,
                  codSerUrg: codSerUrg,
                  enabled: true
                });
                this.contactsForm = this.fb.group({
                  contacts: this.fb.array(arrayContactForm)
                });
                this.contactsArrayForm = this.contactsForm.get(
                  'contacts'
                ) as FormArray;

                if (this.technicalParameterForm.value.logicielMetier) {
                  this.logicielMetierStartId =
                    this.technicalParameterForm.value.logicielMetier;
                }
                this.getField('libelleEGROR').disable();
                this.getField('typeSU').disable();
                this.fillForm(
                  this.selectedEmergencyDept,
                  this.selectedTechnicalParameter
                );
              });
          });
      });

    if (this.emergencyDepts.filter((item) => item.code === codEg).length > 1) {
      this.doubleauthidden = false;
    } else {
      this.doubleauthidden = true;
    }
    this.pageIs = true;
    this.getService(codEg, codSerUrg);
  }

  getInputHierarchy(codeEg: string, codeSerUrg: number) {
    this.inputHierarchyService
      .getInputHierarchyBySu(codeEg, codeSerUrg)
      .subscribe((responseGetInputHierarchy) => {
        this.classificationHetService
          .getClassificationHetBySU(codeEg, codeSerUrg)
          .subscribe((responseGetClassificationHet) => {
            this.classificationHet = responseGetClassificationHet;
            this.inputHierarchy = this.transformInputHierarchy(
              responseGetInputHierarchy,
              responseGetClassificationHet
            );
            this.toFormGroup(this.inputHierarchy);
          });
      });
  }

  transformInputHierarchy(
    inputHierarchys: InputHierarchy[],
    classificationHet: ClassificationHet[]
  ): {} {
    let transformedHierarchy = inputHierarchys.reduce(
      (inputHierarchy, inputHierarchyObject) => {
        const { codNivAgg } = inputHierarchyObject;
        inputHierarchy[codNivAgg] = inputHierarchy[codNivAgg] ?? [];
        const getClassificationHet = classificationHet.find(
          (classification) =>
            classification.codAgg === inputHierarchyObject.codNivSemAgg
        );
        const inputHierarchyWithClassification = {
          ...inputHierarchyObject,
          codClass: getClassificationHet ? getClassificationHet!.codClass : 0,
          classificationHet: getClassificationHet
        };
        inputHierarchy[codNivAgg].push(inputHierarchyWithClassification);
        return inputHierarchy;
      },
      {}
    );
    return transformedHierarchy;
  }

  toFormGroup(transformedHierarchy: {}) {
    this.classificationForm = this.fb.group({});
    Object.keys(transformedHierarchy).forEach((inputHierarchy) => {
      transformedHierarchy[inputHierarchy].forEach((e) => {
        this.classificationForm.addControl(
          `${inputHierarchy}-${e.codNivSemAgg}`,
          this.fb.control(e.codClass)
        );
      });
    });
  }

  openNewSau() {
    this.parameterHet = [];
    this.contactsToDelete = [];
    this.contactsToUpdate = [];
    this.contactsForm = this.fb.group({ contacts: this.fb.array([]) });
    this.contactsArrayForm = this.contactsForm.get('contacts') as FormArray;
    this.newContactsForm = this.fb.group({
      nomPrenom: '',
      mail: '',
      telFixe: '',
      telMobile: '',
      editable: false,
      state: 'new',
      codEg: '',
      codSerUrg: '',
      enabled: true
    });
    this.isNew = true;
    this.getField('libelleEGROR').enable();
    this.getField('typeSU').enable();
    this.selectedEmergencyDept = {
      id: -1,
      code: '',
      type: 0,
      finess: '',
      libSerEg: '',
      abrSerEg: '',
      aphp: false,
      libSerUrg: '',
      abrSerUrg: '',
      representationType: 9,
      pedAdu: false,
      fixedType: 1,
      serviceHeadCode: -2,
      enabled: true,
      createdDate: '',
      lastModifiedDate: '',
      source: 'FORM',
      alreadyConnected: false,
      connexionDate: '',
      uhcdCapacity: undefined,
      cpctCapacity: undefined,
      boxCapacity: undefined,
      specialite: 'Pas de spécialité',
      regionId: -2,
      longitude: undefined,
      latitude: undefined,
      image: undefined,
      territory: '-2',
      size: '-2',
      onlineForPublic: false,
      onlineForProfessionals: false,
      duoAut: false,
      fermAdm: undefined
    };
    this.selectedTechnicalParameter = {
      codEg: '',
      codSerUrg: 0,
      fluxRpu: 2,
      fluxAu: 2,
      obs: null,
      logicielMetier: null,
      enabled: false
    };
    this.itForm = this.fb.group({
      [this.indicateur[0]]: this.fb.group({
        codEg: '',
        codSerUrg: 0,
        it: 'IT1',
        enabled: false,
        region: this.regionId,
        calcLis: true,
        coeffSeuil: 1,
        coeffOrangeLis: 50,
        coeffRougeLis: 25,
        coeffOrangeBrut: 50,
        coeffRougeBrut: 25
      }),
      [this.indicateur[1]]: this.fb.group({
        codEg: '',
        codSerUrg: 0,
        it: 'IT2',
        enabled: false,
        region: this.regionId,
        calcLis: true,
        coeffSeuil: 1,
        coeffOrangeLis: 1,
        coeffRougeLis: 2,
        coeffOrangeBrut: 2,
        coeffRougeBrut: 3
      }),
      [this.indicateur[2]]: this.fb.group({
        codEg: '',
        codSerUrg: 0,
        it: 'IT3',
        enabled: false,
        region: this.regionId,
        calcLis: true,
        coeffSeuil: 1,
        coeffOrangeLis: 1,
        coeffRougeLis: 2,
        coeffOrangeBrut: 2,
        coeffRougeBrut: 3
      }),
      [this.indicateur[3]]: this.fb.group({
        codEg: '',
        codSerUrg: 0,
        it: 'IT4',
        enabled: false,
        region: this.regionId,
        calcLis: true,
        coeffSeuil: 1,
        coeffOrangeLis: 1,
        coeffRougeLis: 2,
        coeffOrangeBrut: 2,
        coeffRougeBrut: 3
      }),
      [this.indicateur[4]]: this.fb.group({
        codEg: '',
        codSerUrg: 0,
        it: 'IT5',
        enabled: false,
        region: this.regionId,
        calcLis: true,
        coeffSeuil: 1,
        coeffOrangeLis: 1,
        coeffRougeLis: 2,
        coeffOrangeBrut: 2,
        coeffRougeBrut: 3
      }),
      [this.indicateur[5]]: this.fb.group({
        codEg: '',
        codSerUrg: 0,
        it: 'IT6',
        enabled: false,
        region: this.regionId,
        calcLis: true,
        coeffSeuil: 1,
        coeffOrangeLis: 1,
        coeffRougeLis: 2,
        coeffOrangeBrut: 2,
        coeffRougeBrut: 3
      })
    });

    this.emergencyForm.reset();
    this.fillForm(this.selectedEmergencyDept, this.selectedTechnicalParameter);
    this.margin = '2%';
    this.doubleauthidden = true;
    this.pageIs = true;
  }

  private fillForm(
    emergencyDept: EmergencyDept,
    technicalParameter: TechnicalParameter
  ) {
    const geoEntity = this.geoEntities.find(
      (value) => value.code === emergencyDept.code
    );
    const user = this.users.find(
      (value) => value.id == emergencyDept.serviceHeadCode
    );
    const edTerritory = this.edTerritories.find(
      (value) => value.code === emergencyDept.territory
    );

    const deptSize = this.deptSizes.find(
      (value) => value.id === emergencyDept.size
    );
    const emergencySpeciality = this.emergencySpeciality.find(
      (value) => value.specialiteSau === emergencyDept.specialite
    );

    this.emergencyForm.patchValue({
      libelleEGROR: geoEntity,
      codeROR: geoEntity === undefined ? undefined : geoEntity.code,
      libelleEtablissement: emergencyDept.libSerEg,
      finess: geoEntity === undefined ? undefined : geoEntity.finessCode,
      libelleCourt: emergencyDept.abrSerEg,
      aphp: emergencyDept.aphp,
      libelleSU: emergencyDept.libSerUrg,
      representation: emergencyDept.representationType,
      libelleCourtSU: emergencyDept.abrSerUrg,
      typeSU: emergencyDept.type,
      user,
      typePat: emergencyDept.fixedType,
      pedAdu: emergencyDept.pedAdu,
      CapUhcd: emergencyDept.uhcdCapacity,
      CapSauv: emergencyDept.cpctCapacity,
      CapBox: emergencyDept.boxCapacity,
      emergencySpeciality: emergencyDept.specialite,
      Lat: emergencyDept.latitude,
      Long: emergencyDept.longitude,
      tauGP: emergencyDept.onlineForPublic,
      tauPR: emergencyDept.onlineForProfessionals,
      territory: edTerritory,
      tailleSU: deptSize,
      duoAut: emergencyDept.duoAut,
      FermAdm: emergencyDept.fermAdm
    });
    console.log('emergencyDept.fixedType,', emergencyDept.fixedType);

    this.technicalParameterForm.patchValue({
      codEg: geoEntity ? geoEntity.code : technicalParameter.codEg,
      codSerUrg: technicalParameter.codSerUrg,
      fluxRpu: technicalParameter.fluxRpu,
      fluxAu: technicalParameter.fluxAu,
      obs: technicalParameter.obs === undefined ? null : technicalParameter.obs,
      logicielMetier:
        technicalParameter.logicielMetier === undefined
          ? -2
          : technicalParameter.logicielMetier,
      enabled: technicalParameter.enabled
    });
    // this.classifHetForm.patchValue({
    //   codClass: classifHet.codClass,
    // });
  }

  createContact(contact): FormGroup {
    return this.fb.group({
      id: contact.id,
      nomPrenom: contact.nomPrenom,
      mail: contact.mail,
      telFixe: contact.telFixe,
      telMobile: contact.telMobile,
      editable: false,
      state: 'submitted',
      codEg: contact.codEg,
      codSerUrg: contact.codSerUrg,
      enabled: contact.enabled
    });
  }

  onSubmit() {
    let modified: boolean = false;
    this.fillSelectedSU();
    this.emergencyDeptService
      .setEmergencyDept(this.selectedEmergencyDept)
      .subscribe((emergencyDeptModified) => {
        this.emergencyDeptService
          .getEmergencyDepts(
            this.regionId.toString(),
            (this.page - 1).toString(),
            this.consoleMessages[this.consoleMessages.length - 1]
          )
          .subscribe((emergencyDepts) => {
            this.emergencyDepts = emergencyDepts.content;
          });

        this.pageIs = false;
        this.onSubmitTechnicalParameter();
        this.fillSelectedParameterHet();
        this.submitClassificationParameter();
      });
  }

  submitClassificationParameter() {
    Object.keys(this.classificationForm.value).map((classification) => {
      const semiAgg = classification.split('-')[1];
      const agg = classification.split('-')[0];
      const hierarchy = this.inputHierarchy[agg].find(
        (hierarchy) => hierarchy.codNivSemAgg === semiAgg
      );
      const newClassification: ClassificationHet = {
        codAgg: hierarchy.codNivSemAgg,
        codClass: this.classificationForm.value[classification],
        codEg: hierarchy.codEg,
        codSerUrg: hierarchy.codSerUrg,
        enabled: true,
        region: hierarchy.region
      };
      this.classificationHetService
        .setClassificationHet(newClassification)
        .subscribe((res) => console.info(res));
    });
  }

  onSubmitParameterHet() {}

  onSubmitTechnicalParameter() {
    this.fillSelectedTechnicalParameter();
    let obs: Observable<any>[] = [];
    if (this.contactsToDelete.length >= 1) {
      this.contactsToDelete.forEach((id) => {
        obs.push(
          this.contactTechnicalParameterService.deleteContactTechnicalParameter(
            id
          )
        );
      });
    }
    this.contactsToUpdate
      .filter((contact) => contact.state === 'updated')
      .forEach((contactToUpdate) => {
        const { state, editable, ...contact } = contactToUpdate;
        obs.push(
          this.contactTechnicalParameterService.updateContactTechnicalParameter(
            contact.id,
            contact
          )
        );
      });
    this.contactsToUpdate
      .filter((contact) => contact.state === 'new')
      .forEach((contactToCreate) => {
        const { state, editable, ...contact } = contactToCreate;
        if (!contactToCreate.codEg || contactToCreate.codEg === 0) {
          contact.codEg = this.selectedEmergencyDept.code;
        }
        if (!contactToCreate.codSerUrg || contactToCreate.codSerUrg === '') {
          contact.codSerUrg = this.selectedEmergencyDept.type;
        }

        obs.push(
          this.contactTechnicalParameterService.setContactTechnicalParameter(
            contact
          )
        );
      });
    this.technicalParameterService
      .setTechnicalParameter(this.selectedTechnicalParameter)
      .subscribe((_) => {
        if (obs.length >= 1) {
          forkJoin(obs).subscribe(
            (response) => {},
            (error) => console.log(error)
          );
        } else {
        }
      });
  }

  onSubmitContactTechnicalParameter() {}
  updateContacts($event) {
    this.contactsToUpdate = $event;
  }

  // onSubmitParameterHet() {
  //   this.selectedParameterHet(this.selectedParameterHet)

  // }

  addDeleteIds(event) {
    this.contactsToDelete.push(event);
  }

  onCloseHandled() {
    this.pageIs = false;
  }
  getPage(page, increment?) {
    if (increment) {
      this.page = this.page + increment;
      const pageNumber = this.page - 1;
      this.emergencyDeptService
        .getEmergencyDepts(
          this.regionId.toString(),
          pageNumber.toString(),
          this.consoleMessages[this.consoleMessages.length - 1]
        )
        .subscribe((emergencyDepts) => {
          this.emergencyDepts = emergencyDepts.content;
        });
    } else {
      this.page = page;
      const pageNumber = page - 1;

      this.emergencyDeptService
        .getEmergencyDepts(
          this.regionId.toString(),
          pageNumber.toString(),
          this.consoleMessages[this.consoleMessages.length - 1]
        )
        .subscribe((emergencyDepts) => {
          this.emergencyDepts = emergencyDepts.content;
        });
    }
  }
  // from output when you create New SAU
  updateInputHierarchySaisie() {
    this.getInputHierarchy(
      this.emergencyForm.value.codeROR,
      this.getField('typeSU').value
    );
  }

  fillSelectedSU() {
    this.selectedEmergencyDept.code = this.emergencyForm.value.codeROR;
    this.selectedEmergencyDept.libSerEg =
      this.emergencyForm.value.libelleEtablissement;
    this.selectedEmergencyDept.abrSerEg = this.emergencyForm.value.libelleCourt;
    this.selectedEmergencyDept.finess = this.emergencyForm.value.finess;
    this.selectedEmergencyDept.aphp =
      this.emergencyForm.value.aphp == null
        ? false
        : this.emergencyForm.value.aphp;
    this.selectedEmergencyDept.libSerUrg = this.emergencyForm.value.libelleSU;
    //REPRESENTATION
    this.selectedEmergencyDept.representationType =
      this.emergencyForm.value.representation == null
        ? 9
        : this.emergencyForm.value.representation;
    this.selectedEmergencyDept.abrSerUrg =
      this.emergencyForm.value.libelleCourtSU;
    this.selectedEmergencyDept.serviceHeadCode =
      this.emergencyForm.value.user == null
        ? undefined
        : this.emergencyForm.value.user.id;
    this.selectedEmergencyDept.type = this.getField('typeSU').value;
    this.selectedEmergencyDept.fixedType = this.emergencyForm.value.typePat;
    this.selectedEmergencyDept.pedAdu =
      this.emergencyForm.value.pedAdu == null
        ? false
        : this.emergencyForm.value.pedAdu;
    this.selectedEmergencyDept.uhcdCapacity =
      this.emergencyForm.value.CapUhcd == ''
        ? 0
        : this.emergencyForm.value.CapUhcd;
    this.selectedEmergencyDept.fermAdm =
      this.emergencyForm.value.FermAdm == ''
        ? 0
        : this.emergencyForm.value.FermAdm;
    this.selectedEmergencyDept.cpctCapacity =
      this.emergencyForm.value.CapSauv == ''
        ? 0
        : this.emergencyForm.value.CapSauv;
    this.selectedEmergencyDept.boxCapacity =
      this.emergencyForm.value.CapBox == ''
        ? 0
        : this.emergencyForm.value.CapBox;
    this.selectedEmergencyDept.specialite =
      this.emergencyForm.value.emergencySpeciality;
    this.selectedEmergencyDept.latitude =
      this.emergencyForm.value.Lat == '' ? null : this.emergencyForm.value.Lat;
    this.selectedEmergencyDept.longitude =
      this.emergencyForm.value.Long == ''
        ? null
        : this.emergencyForm.value.Long;
    this.selectedEmergencyDept.onlineForPublic =
      this.emergencyForm.value.tauGP == null
        ? false
        : this.emergencyForm.value.tauGP;
    this.selectedEmergencyDept.onlineForProfessionals =
      this.emergencyForm.value.tauPR == null
        ? false
        : this.emergencyForm.value.tauPR;
    this.selectedEmergencyDept.territory =
      this.emergencyForm.value.territory == null
        ? -2
        : this.emergencyForm.value.territory.code;
    this.selectedEmergencyDept.size =
      this.emergencyForm.value.tailleSU == null
        ? -2
        : this.emergencyForm.value.tailleSU.id;
    this.selectedEmergencyDept.duoAut =
      this.emergencyForm.value.duoAut == null
        ? false
        : this.emergencyForm.value.duoAut;
    this.selectedEmergencyDept.enabled = true;
    this.selectedEmergencyDept.regionId = this.regionId;
  }

  fillSelectedTechnicalParameter() {
    this.selectedTechnicalParameter.codEg = this.emergencyForm.value.codeROR;
    this.selectedTechnicalParameter.codSerUrg = this.getField('typeSU').value;
    this.selectedTechnicalParameter.fluxRpu =
      this.technicalParameterForm.value.fluxRpu;
    this.selectedTechnicalParameter.fluxAu =
      this.technicalParameterForm.value.fluxAu;
    this.selectedTechnicalParameter.obs = this.technicalParameterForm.value.obs;
    this.selectedTechnicalParameter.logicielMetier =
      this.technicalParameterForm.value.logicielMetier;
    this.selectedTechnicalParameter.enabled =
      this.technicalParameterForm.value.enabled == null
        ? false
        : this.technicalParameterForm.value.enabled;
  }

  // fillSelectedClassifHet() {
  //   this.selectedClassifHet.codClass = this.classifHetForm.value.codClass;
  // }

  fillSelectedParameterHet() {
    this.parameterHetService
      .saveParameterHet(this.itForm.controls['IT1'].value)
      .subscribe(
        (response) => {
          console.log('réponse', response);
        },
        (error) => console.log('erreur', error)
      );
    this.parameterHetService
      .saveParameterHet(this.itForm.controls['IT2'].value)
      .subscribe(
        (response) => {
          console.log('réponse IT2', response);
        },
        (error) => console.log('erreur IT2', error)
      );
    this.parameterHetService
      .saveParameterHet(this.itForm.controls['IT3'].value)
      .subscribe(
        (response) => {
          console.log('réponse IT3', response);
        },
        (error) => console.log('erreur IT3', error)
      );
    this.parameterHetService
      .saveParameterHet(this.itForm.controls['IT4'].value)
      .subscribe(
        (response) => {
          console.log('réponse IT4', response);
        },
        (error) => console.log('erreur IT4', error)
      );
    this.parameterHetService
      .saveParameterHet(this.itForm.controls['IT5'].value)
      .subscribe(
        (response) => {
          console.log('réponse IT5', response);
        },
        (error) => console.log('erreur IT5', error)
      );
    this.parameterHetService
      .saveParameterHet(this.itForm.controls['IT6'].value)
      .subscribe(
        (response) => {
          console.log('réponse IT6', response);
        },
        (error) => console.log('erreur IT6', error)
      );
  }

  openWind(codeEg: string, codeSerUrg: number, libSerUrg: string) {
    console.log('libSerUrg', libSerUrg);
    this.emergencyDepts.find((emergencyDept) => {
      if (
        emergencyDept.code == codeEg &&
        emergencyDept.libSerUrg == libSerUrg &&
        emergencyDept.type == codeSerUrg
      ) {
        emergencyDept.enabled = true;
      }
    });
    this.emergencyDeptService
      .getEmergencyDept(codeEg, codeSerUrg)
      .subscribe((data) => {
        this.selectedEmergencyDept = data;
        this.dis = 'block';
      });
    this.classificationHetService
      .getClassificationHetBySU(codeEg, codeSerUrg)
      .subscribe((data) => {
        this.selectedClassifHet = data;
        this.dis = 'block';
      });
    this.technicalParameterService.getTechnicalParameter().subscribe((data) => {
      this.dis = 'block';
    });
    this.contactTechnicalParameterService
      .getContactTechnicalParameter()
      .subscribe((data) => {
        this.dis = 'block';
      });
  }

  //TO DO : A VERIFIER
  saveWind() {
    if ((this.selectedEmergencyDept.enabled = true)) {
      console.log(this.selectedEmergencyDept);
      this.selectedEmergencyDept.enabled = false;

      this.emergencyDeptService
        .setEmergencyDept(this.selectedEmergencyDept)
        .subscribe((emergencyDeptModified) => {
          const page = this.page - 1;
          this.emergencyDepts.forEach((element, index) => {
            if (element.id == emergencyDeptModified.id) {
              this.emergencyDepts[index].enabled = false;
            }
          });

          this.dis = 'none';
        });
    }
    this.pageIs = false;
  }

  closeWind(
    finess: string,
    libSerUrg: string,
    pedAdu: boolean,
    codeSerUrg: number
  ) {
    this.dis = 'none';
    this.emergencyDepts.find((emergencyDept) => {
      if (
        emergencyDept.finess == finess &&
        emergencyDept.pedAdu == pedAdu &&
        emergencyDept.type == codeSerUrg
      ) {
        emergencyDept.enabled = true;
      }
    });
  }

  getService(codeEg: string, codeSerUrg: number) {
    this.technicalParameterService
      .getTechnicalParameter()
      .subscribe((data) => {});
    this.technicalParameterService
      .getTechnicalParameterById(codeEg, codeSerUrg)
      .subscribe((data) => {
        this.technicalParameter = data;
      });

    this.contactTechnicalParameterService
      .getContactTechnicalParameterBySu(codeEg, codeSerUrg)
      .subscribe((data) => {
        this.contactTechnicalParameter = data;
      });
  }

  getField(name: string): AbstractControl {
    const field = this.emergencyForm.get(name);
    if (field === null) {
      throw new Error(`The field ${name} does not exist.`);
    }
    return field;
  }

  isAlert() {
    if (this.alerts.length != 0) {
      return true;
    } else {
      return false;
    }
  }

  isAraPage() {
    const str = window.location.href;
    return str.includes('ara') || str.includes(':4201/');
  }

  sortEmergencyDepts($event) {
    this.headerDefintions.forEach((headerDefintion) => {
      if (
        $event.title.split(',', 1) == headerDefintion.title &&
        headerDefintion.fieldName != 'FINESS'
      ) {
        headerDefintion.sortAsc = $event.asc;
      }
      if (
        $event.title.split(',', 1) == headerDefintion.title &&
        headerDefintion.fieldName == 'FINESS' &&
        $event.titleHeader == 'FINESS'
      ) {
        headerDefintion.sortAsc = $event.asc;
      }
    });
    this.emergencyDeptService
      .getEmergencyByDepts(this.regionId.toString(), $event.title)
      .subscribe((emergencyDepts) => {
        this.emergencyDepts = emergencyDepts.content;
      });
  }
  sizeOfTable(size) {
    this.emergencyDeptService
      .getElementOfEmergencyDepts(size, this.regionId.toString())
      .subscribe((emergencyDepts) => {
        this.emergencyDepts = emergencyDepts.content;
        this.numberOfPages = emergencyDepts.totalPages;
      });
  }

  counter(i: number) {
    return new Array(this.numberOfPages);
  }
}
