import { FormGroup } from '@angular/forms';

export class EffectorFormAddress {
  id?: number;
  labelAddress1: string;
  labelAddress2: string;
  labelAddress3: string;
  labelAddress4: string;
  postalCode: string;
  labelCity: string;
  phoneNumber: string;
  email: string;
  latitudeCode: string;
  longitudeCode: string;
  webSite: string;
  comment: string;
  mondayHours: string;
  tuesdayHours: string;
  wednesdayHours: string;
  thursdayHours: string;
  fridayHours: string;
  saturdayHours: string;
  sundayHours: string;
  holidaysHours: string;

  constructor(formGroup: FormGroup) {
    this.labelAddress1 = formGroup.value.labelAddress1;
    this.labelAddress2 = formGroup.value.labelAddress2;
    this.labelAddress3 = formGroup.value.labelAddress3;
    this.labelAddress4 = formGroup.value.labelAddress4;
    this.postalCode = formGroup.value.postalCode;
    this.labelCity = formGroup.value.labelCity;
    this.phoneNumber = formGroup.value.phoneNumber;
    this.email = formGroup.value.email;
    this.latitudeCode = formGroup.value.latitudeCode;
    this.longitudeCode = formGroup.value.longitudeCode;
    this.webSite = formGroup.value.webSite;
    this.comment = formGroup.value.comment;
    this.mondayHours = formGroup.value.mondayHours;
    this.tuesdayHours = formGroup.value.tuesdayHours;
    this.wednesdayHours = formGroup.value.wednesdayHours;
    this.thursdayHours = formGroup.value.thursdayHours;
    this.fridayHours = formGroup.value.fridayHours;
    this.saturdayHours = formGroup.value.saturdayHours;
    this.sundayHours = formGroup.value.sundayHours;
    this.holidaysHours = formGroup.value.holidaysHours;
    this.id = formGroup.value.id;
  }
}
