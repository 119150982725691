<script src="https://code.highcharts.com/highcharts.js"></script>
<script src="https://code.highcharts.com/modules/exporting.js"></script>
<script src="https://code.highcharts.com/modules/export-data.js"></script>
<script src="https://code.highcharts.com/modules/accessibility.js"></script>

<div class="first-container">
  <div class="row" style="display: flex; align-items: flex-end">
    <div class="col-12 btn help-button">
      <a type="button" href="assets/docs/EnCoursElaboration.pdf" target="_blank">?</a>
    </div>
  </div>
  <div class="graph-container">
    <div class="row justify-content-center">
      <div class="col-12 filtre">
        <figure class="highcharts-figure" >
          <div id="period1"></div>
        </figure>
      </div>
    </div>
  </div>

  <div *ngIf="displayPeriod2" class="row">
    <div class="col-12">
      <figure class="highcharts-figure second-graph-container">
        <div id="period2"></div>
      </figure>
    </div>
  </div>
</div>
