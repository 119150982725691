import { FormGroup } from '@angular/forms';

export class EffectorFormAvailabilities {
  id: number;
  validityBoolean: boolean;

  constructor(formGroup: FormGroup) {
    this.id = formGroup.value.id;
    this.validityBoolean = formGroup.value.validityBoolean;
  }
}
