import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ContactMail } from "../model/contact-mail.model";
import { ContactRequest } from "../model/ContactRequestModel";

@Injectable({
  providedIn: "root",
})
export class ContactMailService {
  constructor(private httpClient: HttpClient) {}

  public sendMail(contactMail: ContactMail): Observable<ContactMail> {
    return this.httpClient.post<ContactMail>(`api/sendMail`, contactMail);
  }

  public sendToAdmin(contactRequest: ContactRequest) {
    console.log(contactRequest);
    return this.httpClient.post<ContactMail>(`api/request`, contactRequest);
  }
}
