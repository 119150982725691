import { Injectable } from '@angular/core';
import * as Highcharts from 'highcharts';
import { Observable } from 'rxjs';
import { GraphDataModel } from '../../models/graph-data.model';
import { GraphFilter, Period } from '../../models/graph-filter.model';

import { ChargeIndicateur } from '../../model/ChargeIndicateur.model';
import { ChargeGraphService } from './charge-graph.service';

declare const require: any;
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require('highcharts/modules/annotations')(Highcharts);

type NbOfpeopleForGraph = {
  name: string;
  data: number[];
  type: string;
  color: string;
  events: {};
  marker?: {
    enabled: boolean;
  };
};

@Injectable({
  providedIn: 'root'
})
export class ValueOfGraphChargeService {
  chart: Highcharts.Chart;
  constructor(private chargeGraphService: ChargeGraphService) {}

  getValueOfChargeGraph(
    graphId: string,
    graphFilter: GraphFilter,
    period: Period
  ): void {
    const requestData = this.chargeGraphService.getIndicateurCharge(
      graphFilter.emergencyDepts.map(
        (emergencyDept) => emergencyDept.code + '-' + emergencyDept.type
      ),
      period.startDate,
      period.endDate
    );

    this.setDataInGraph(requestData, graphId, period.startDate, period.endDate);
  }

  private setDataInGraph(
    typeOfGet: Observable<ChargeIndicateur[]>,
    graphId: string,
    dateFrom: Date,
    dateTo: Date
  ) {
    console.log('typeOfGet', typeOfGet);
    const periodChargeIndicateur = this.initializeChargeIndicateur();

    const nbOfpeopleEnter: NbOfpeopleForGraph = {
      name: 'Entrée(s)',
      data: [],
      type: 'line',
      color: 'green',
      events: {
        legendItemClick: function () {
          return false;
        }
      },
      marker: {
        enabled: false
      }
    };

    const nbOfpeopleOut: NbOfpeopleForGraph = {
      name: 'Sortie(s)',
      data: [],
      type: 'line',
      color: 'red',
      events: {
        legendItemClick: function () {
          return false;
        }
      },
      marker: {
        enabled: false
      }
    };

    typeOfGet.subscribe(
      (data) => {
        const hours = data.map((element) => {
          const h: number[] = [];
          h.push(element['veille']);
          for (let i = 0; i < 24; i++) {
            h.push(element['h' + i]);
          }
          h.push(element['total']);
          return h;
        });

        nbOfpeopleEnter.data = hours.map((element, index) => {
          return Math.round(element[index + 1] * 10) / 10;
        });
        console.log('nbOfpeopleEnter', nbOfpeopleEnter);

        // nbOfpeopleOut.data = hours.map((element, index) => {
        //   return Math.round(element[index - 1] * 10) / 10;
        // })

        nbOfpeopleOut.data = hours.map((element, index, array) => {
          console.log('hours', hours);
          let out = 0;
          for (let i = 0; i < index; i++) {
            out += array[index - 1][i] - element[i];
          }
          if (index > 0) {
            out += array[index - 1][index] - element[index];
          } else {
            out += element[array.length + 1] - array[index][index];
          }
          console.log('out', out);
          return Math.round(out * 10) / 10;
        });
        console.log('hours 2', hours);

        data.forEach((element) => {
          periodChargeIndicateur[0].data.push(
            Math.round(element.veille * 10) / 10
          );
          periodChargeIndicateur[1].data.push(Math.round(element.h0 * 10) / 10);
          periodChargeIndicateur[2].data.push(Math.round(element.h1 * 10) / 10);
          periodChargeIndicateur[3].data.push(Math.round(element.h2 * 10) / 10);
          periodChargeIndicateur[4].data.push(Math.round(element.h3 * 10) / 10);
          periodChargeIndicateur[5].data.push(Math.round(element.h4 * 10) / 10);
          periodChargeIndicateur[6].data.push(Math.round(element.h5 * 10) / 10);
          periodChargeIndicateur[7].data.push(Math.round(element.h6 * 10) / 10);
          periodChargeIndicateur[8].data.push(Math.round(element.h7 * 10) / 10);
          periodChargeIndicateur[9].data.push(Math.round(element.h8 * 10) / 10);
          periodChargeIndicateur[10].data.push(
            Math.round(element.h9 * 10) / 10
          );
          periodChargeIndicateur[11].data.push(
            Math.round(element.h10 * 10) / 10
          );
          periodChargeIndicateur[12].data.push(
            Math.round(element.h11 * 10) / 10
          );
          periodChargeIndicateur[13].data.push(
            Math.round(element.h12 * 10) / 10
          );
          periodChargeIndicateur[14].data.push(
            Math.round(element.h13 * 10) / 10
          );
          periodChargeIndicateur[15].data.push(
            Math.round(element.h14 * 10) / 10
          );
          periodChargeIndicateur[16].data.push(
            Math.round(element.h15 * 10) / 10
          );
          periodChargeIndicateur[17].data.push(
            Math.round(element.h16 * 10) / 10
          );
          periodChargeIndicateur[18].data.push(
            Math.round(element.h17 * 10) / 10
          );
          periodChargeIndicateur[19].data.push(
            Math.round(element.h18 * 10) / 10
          );
          periodChargeIndicateur[20].data.push(
            Math.round(element.h19 * 10) / 10
          );
          periodChargeIndicateur[21].data.push(
            Math.round(element.h20 * 10) / 10
          );
          periodChargeIndicateur[22].data.push(
            Math.round(element.h21 * 10) / 10
          );
          periodChargeIndicateur[23].data.push(
            Math.round(element.h22 * 10) / 10
          );
          periodChargeIndicateur[24].data.push(
            Math.round(element.h23 * 10) / 10
          );
        });

        // periodChargeIndicateur.forEach((element) => {
        //   console.log("max", Math.max.apply(null, element.data));
        // });

        periodChargeIndicateur.push(nbOfpeopleEnter);
        periodChargeIndicateur.push(nbOfpeopleOut);
        console.log('nbOfpeopleOut', nbOfpeopleOut);
        console.log('nbOfpeopleOut.data', nbOfpeopleOut.data);

        const dateFromFormat = dateFrom.toLocaleDateString();
        const dateToFormat = dateTo.toLocaleDateString();

        this.setOptionGraph(
          dateFromFormat,
          dateToFormat,
          periodChargeIndicateur,
          graphId,
          nbOfpeopleEnter,
          nbOfpeopleOut
        );
      },

      (_error) => {
        const dateFromFormat = dateFrom.toLocaleDateString();
        const dateToFormat = dateTo.toLocaleDateString();

        this.setOptionGraph(
          dateFromFormat,
          dateToFormat,
          periodChargeIndicateur,
          graphId,
          nbOfpeopleEnter,
          nbOfpeopleOut
        );
      }
    );
  }

  private setOptionGraph(
    dateFrom: string,
    dateTo: string,
    periodChargeIndicateur: GraphDataModel[],
    graphId: string,
    nbOfpeopleEnter: NbOfpeopleForGraph,
    nbOfpeopleOut: NbOfpeopleForGraph
  ): void {
    console.log(periodChargeIndicateur);
    let graphOptions = {};

    const graphContainerWidth: number =
      document.getElementsByClassName('graph-container')[0].clientWidth;

    setTimeout(() => {
      const period2 = document.getElementById('period2');
      if (period2) {
        const svgChild = period2?.children[0].children[0] as SVGSVGElement;
        svgChild.width.baseVal.value = graphContainerWidth - 15;
      }
    }, 1000);

    graphOptions = {
      chart: {
        type: 'area',
        height: '40%',
        backgroundColor: '#f8f9f9',
        width: graphContainerWidth - 15
      },
      credits: {
        enabled: false
      },

      title: {
        text:
          '<span style="font-weight: normal;">' +
          "Occupation horaire du service d'urgences en nombre moyen de patients par heure, selon les heures d'arrivée des patients du " +
          dateFrom +
          ' au ' +
          dateTo +
          '</span>'
      },
      lang: {
        noData: 'Aucune donnée disponible',
        printChart: 'Imprimer le graphique',
        downloadPNG: 'Télécharger en PNG',
        downloadJPEG: 'Télécharger en JPEG',
        downloadPDF: 'Télécharger en PDF',
        downloadSVG: 'Télécharger en SVG',
        downloadCSV: 'Télécharger en CSV',
        downloadXLS: 'Télécharger en XLS',
        contextButtonTitle: 'Menu export',
        viewFullscreen: 'Voir en plein écran',
        viewData: '',
        exitFullscreen: 'Sortir du plein écran'
      },
      exporting: {
        sourceWidth: 2208,
        sourceHeight: 1180,
        buttons: {
          contextButton: {
            x: -12,
            menuItems: [
              'viewFullscreen',
              'printChart',
              'separator',
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadSVG',
              'separator',
              'downloadCSV',
              'downloadXLS'
            ]
          }
        }
      },
      tooltip: {
        backgroundColor: '#FFFFFF',
        shared: true,
        formatter: function () {
          return (
            ' <br>Patients présents ' +
            this.points.reduce(function (s, point) {
              return (
                s +
                (point.y == 0
                  ? ''
                  : '<span style="color:' +
                    point.series.color +
                    '";>' +
                    point.y +
                    ' ' +
                    point.series.name +
                    '</span>' +
                    '</br>')
              );
            }, '<b>' + this.x + '</br>')
          );
        }
      },
      legend: {
        title: {
          text: 'Patients entrés la veille ou avant : '
        },
        x: 170,
        y: 0,
        //useHTML: true,

        labelFormatter: function () {
          return this.name == 'Entré(s) la veille'
            ? this.name
            : '' + this.name == 'Sortie(s)'
            ? this.name
            : '' + this.name == 'Entrée(s)'
            ? this.name
            : '' +
              `<span style="color:${this.color};">${this.name[15]}${
                this.name[16] != 'h' ? this.name[16] : ''
              }</span> h`;
        }
      },

      xAxis: {
        title: {
          enabled: false
        },
        categories: [
          'avant 1h',
          'avant 2h',
          'avant 3h',
          'avant 4h',
          'avant 5h',
          'avant 6h',
          'avant 7h',
          'avant 8h',
          'avant 9h',
          'avant 10h',
          'avant 11h',
          'avant 12h',
          'avant 13h',
          'avant 14h',
          'avant 15h',
          'avant 16h',
          'avant 17h',
          'avant 18h',
          'avant 19h',
          'avant 20h',
          'avant 21h',
          'avant 22h',
          'avant 23h',
          'avant 24h'
        ]
      },

      yAxis: [
        {
          floor: 0,
          title: {
            text: ''
          },
          labels: {}
        },
        {
          title: {
            text: nbOfpeopleEnter.name
          },
          visible: false
        }
      ],

      plotOptions: {
        area: {
          stacking: 'normal',
          lineColor: '#666666',
          lineWidth: 1
        }
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 750
            },
            chartOptions: {
              chart: {
                height: '160%'
              },
              legend: {
                enabled: false,
                maxHeight: 90,
                x: 0
              }
            }
          },
          {
            condition: {
              minWidth: 500,
              maxWidth: 800
            },
            chartOptions: {
              chart: {
                height: '100%'
              }
              // legend: {
              //   enabled: false,
              //   maxHeight: 90,
              //   x: 0,
              // },
            }
          }
        ]
      },
      series: periodChargeIndicateur
    };

    Highcharts.chart(graphId, graphOptions).legend.title.translate(-250, 25);
  }

  private initializeChargeIndicateur(): GraphDataModel[] {
    const model: GraphDataModel[] = [];
    model.push({
      name: 'Entré(s) la veille',
      data: [],
      marker: {
        enabled: false
      },
      color: 'black',
      events: {
        legendItemClick: function () {
          return false;
        }
      }
    });
    model.push({
      name: 'Entré(s) entre 0h et 1h',
      data: [],
      marker: {
        enabled: false
      },
      events: {
        legendItemClick: function () {
          return false;
        }
      }
    });
    model.push({
      name: 'Entré(s) entre 1h et 2h',
      data: [],
      marker: {
        enabled: false
      },
      events: {
        legendItemClick: function () {
          return false;
        }
      }
    });
    model.push({
      name: 'Entré(s) entre 2h et 3h',
      data: [],
      marker: {
        enabled: false
      },
      events: {
        legendItemClick: function () {
          return false;
        }
      }
    });
    model.push({
      name: 'Entré(s) entre 3h et 4h',
      data: [],
      marker: {
        enabled: false
      },
      events: {
        legendItemClick: function () {
          return false;
        }
      }
    });
    model.push({
      name: 'Entré(s) entre 4h et 5h',
      data: [],
      marker: {
        enabled: false
      },
      events: {
        legendItemClick: function () {
          return false;
        }
      }
    });
    model.push({
      name: 'Entré(s) entre 5h et 6h',
      data: [],
      marker: {
        enabled: false
      },
      events: {
        legendItemClick: function () {
          return false;
        }
      }
    });
    model.push({
      name: 'Entré(s) entre 6h et 7h',
      data: [],
      marker: {
        enabled: false
      },
      events: {
        legendItemClick: function () {
          return false;
        }
      }
    });
    model.push({
      name: 'Entré(s) entre 7h et 8h',
      data: [],
      marker: {
        enabled: false
      },
      events: {
        legendItemClick: function () {
          return false;
        }
      }
    });
    model.push({
      name: 'Entré(s) entre 8h et 9h',
      data: [],
      marker: {
        enabled: false
      },
      events: {
        legendItemClick: function () {
          return false;
        }
      }
    });
    model.push({
      name: 'Entré(s) entre 9h et 10h',
      data: [],
      marker: {
        enabled: false
      },
      events: {
        legendItemClick: function () {
          return false;
        }
      }
    });
    model.push({
      name: 'Entré(s) entre 10h et 11h',
      data: [],
      marker: {
        enabled: false
      },
      events: {
        legendItemClick: function () {
          return false;
        }
      }
    });
    model.push({
      name: 'Entré(s) entre 11h et 12h',
      data: [],
      marker: {
        enabled: false
      },
      events: {
        legendItemClick: function () {
          return false;
        }
      }
    });
    model.push({
      name: 'Entré(s) entre 12h et 13h',
      data: [],
      marker: {
        enabled: false
      },
      events: {
        legendItemClick: function () {
          return false;
        }
      }
    });
    model.push({
      name: 'Entré(s) entre 13h et 14h',
      data: [],
      marker: {
        enabled: false
      },
      events: {
        legendItemClick: function () {
          return false;
        }
      }
    });
    model.push({
      name: 'Entré(s) entre 14h et 15h',
      data: [],
      marker: {
        enabled: false
      },
      events: {
        legendItemClick: function () {
          return false;
        }
      }
    });
    model.push({
      name: 'Entré(s) entre 15h et 16h',
      data: [],
      marker: {
        enabled: false
      },
      events: {
        legendItemClick: function () {
          return false;
        }
      }
    });
    model.push({
      name: 'Entré(s) entre 16h et 17h',
      data: [],
      marker: {
        enabled: false
      },
      events: {
        legendItemClick: function () {
          return false;
        }
      }
    });
    model.push({
      name: 'Entré(s) entre 17h et 18h',
      data: [],
      marker: {
        enabled: false
      },
      events: {
        legendItemClick: function () {
          return false;
        }
      }
    });
    model.push({
      name: 'Entré(s) entre 18h et 19h',
      data: [],
      marker: {
        enabled: false
      },
      events: {
        legendItemClick: function () {
          return false;
        }
      }
    });
    model.push({
      name: 'Entré(s) entre 19h et 20h',
      data: [],
      marker: {
        enabled: false
      },
      events: {
        legendItemClick: function () {
          return false;
        }
      }
    });
    model.push({
      name: 'Entré(s) entre 20h et 21h',
      data: [],
      marker: {
        enabled: false
      },
      events: {
        legendItemClick: function () {
          return false;
        }
      }
    });
    model.push({
      name: 'Entré(s) entre 21h et 22h',
      data: [],
      marker: {
        enabled: false
      },
      events: {
        legendItemClick: function () {
          return false;
        }
      }
    });
    model.push({
      name: 'Entré(s) entre 22h et 23h',
      data: [],
      marker: {
        enabled: false
      },
      events: {
        legendItemClick: function () {
          return false;
        }
      }
    });
    model.push({
      name: 'Entré(s) entre 23h et 00h',
      data: [],
      marker: {
        enabled: false
      },
      events: {
        legendItemClick: function () {
          return false;
        }
      }
    });

    return model;
  }
}
