import { Component, HostListener, OnInit } from '@angular/core';
import { GraphFilter } from '../models/graph-filter.model';
import { Indicateur } from './models/indicateur.model';
import { ChartService } from '../services/chart.service';
import { IndicateurService } from '../services/indicateur.service';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/emergency-depts/services/alert.service';
import { Alert } from 'src/app/emergency-depts/model/alert.model';

@Component({
  selector: 'app-emergency-visits-graphs',
  templateUrl: './emergency-visits-graphs.component.html',
  styleUrls: ['./emergency-visits-graphs.component.scss']
})
export class EmergencyVisitsGraphsComponent implements OnInit {
  dates: Array<Date>;
  dataInd1: Array<{ name: string; data: Array<Array<any>>; id?: string }> = [];
  dataInd2: Array<{ name: string; data: Array<Array<any>>; id?: string }> = [];
  dataInd3: Array<{ name: string; data: Array<Array<any>>; id?: string }> = [];
  dataInd4: Array<{ name: string; data: Array<Array<any>>; id?: string }> = [];
  dataInd5: Array<{ name: string; data: Array<Array<any>>; id?: string }> = [];
  dataInd6: Array<{ name: string; data: Array<Array<any>>; id?: string }> = [];
  dataInd7: Array<{ name: string; data: Array<Array<any>>; id?: string }> = [];
  alerts: Alert[] = [];

  constructor(
    private indicateurService: IndicateurService,
    private chartService: ChartService,
    private readonly titleService: Title,
    private readonly route: ActivatedRoute,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.titleService.setTitle(this.route.snapshot.data['title']);
    const Reg = this.isAraPage() ? 84 : 11;
    const alertLabel = this.isAraPage() ? 'DONNEES-DES-URGENCES_Evolution' : 'URGENCES_Evolution-indic-urgences';
    this.alertService
      .getAlertsByPageAlert(Reg, alertLabel)
      .subscribe((res: Alert[]) => {
        this.alerts = res;
        res.map((alert) => this.alertService.get(alert));
      });
  }

  filter(filters: GraphFilter) {
    console.log('filter', filters);
    if (filters.emergencyDepts.length >= 1) {
      this.generateData(filters);
    } else if (filters.departementCodes.length >= 1) {
      this.generateByDept(filters);
    } else {
      this.generateDataAllDepts(filters);
    }
  }

  isAlert() {
    if (this.alerts.length != 0) {
      return true;
    } else {
      return false;
    }
  }

  isAraPage() {
    const str = window.location.href;
    return str.includes('ara') || str.includes(':4201/');
  }

  generateDataAllDepts(filters: GraphFilter) {
    this.indicateurService
      .getAllIndicateursForDepts(
        filters.period1.startDate,
        filters.period1.endDate
      )
      .subscribe((response) => {
        if (this.isAraPage()) {
          response = response.filter((field) => field.codeRegion == 84);
        } else {
          response = response.filter((field) => field.codeRegion == 11);
        }
        this.generateDataGraph(response, filters, true);
      });
  }

  generateData(filters: GraphFilter) {
    this.indicateurService
      .getTindicateursBySau(
        filters.emergencyDepts.map(
          (emergencyDept) => emergencyDept.code + '-' + emergencyDept.type
        ),
        filters.period1.startDate,
        filters.period1.endDate
      )
      .subscribe((response) => {
        this.generateDataGraph(response, filters, false);
      });
  }

  generateDataGraph(
    response: Indicateur[],
    filters: GraphFilter,
    isDepts: boolean
  ) {
    const data1: Array<{
      name: string;
      data: Array<Array<any>>;
      id?: string;
    }> = [];
    const data2: Array<{
      name: string;
      data: Array<Array<any>>;
      id?: string;
    }> = [];
    const data3: Array<{
      name: string;
      data: Array<Array<any>>;
      id?: string;
    }> = [];
    const data4: Array<{
      name: string;
      data: Array<Array<any>>;
      id?: string;
    }> = [];
    const data5: Array<{
      name: string;
      data: Array<Array<any>>;
      id?: string;
    }> = [];
    const data6: Array<{
      name: string;
      data: Array<Array<any>>;
      id?: string;
    }> = [];
    const data7: Array<{
      name: string;
      data: Array<Array<any>>;
      id?: string;
    }> = [];

    if (isDepts) {
      response.map((element) => {
        if (element.code != undefined) {
          element.code = this.transformCodDepInDepartmentName(element.code);
        }
      });
    }

    response.map((indicateur) => {
      let index = data1.findIndex((serie) => {
        return serie.id === indicateur.code;
      });
      const date = new Date(indicateur.date).getTime();
      if (index === -1) {
        let name = isDepts
          ? indicateur.code
          : filters!.emergencyDepts!.find(
              (emergenciesServ) =>
                emergenciesServ.code + '-' + emergenciesServ.type ===
                indicateur.code
            )!.abrSerUrg;

        data1.push({
          id: indicateur.code,
          name: name,
          data: [[date, indicateur.ind1]]
        });
        data2.push({
          id: indicateur.code,
          name: name,
          data: [[date, indicateur.ind2]]
        });
        data3.push({
          id: indicateur.code,
          name: name,
          data: [[date, indicateur.ind3]]
        });
        data4.push({
          id: indicateur.code,
          name: name,
          data: [[date, indicateur.ind4]]
        });
        data5.push({
          id: indicateur.code,
          name: name,
          data: [[date, indicateur.ind5 / 100]]
        });
        data6.push({
          id: indicateur.code,
          name: name,
          data: [[date, indicateur.ind6]]
        });
        data7.push({
          id: indicateur.code,
          name: name,
          data: [[date, indicateur.ind7 / 100]]
        });
      } else {
        data1[index].data.push([date, indicateur.ind1]);
        data2[index].data.push([date, indicateur.ind2]);
        data3[index].data.push([date, indicateur.ind3]);
        data4[index].data.push([date, indicateur.ind4]);
        data5[index].data.push([date, indicateur.ind5]);
        data6[index].data.push([date, indicateur.ind6]);
        data7[index].data.push([date, indicateur.ind7]);
      }
    });
    console.log(data5);

    this.dataInd1 = data1;
    this.dataInd2 = data2;
    this.dataInd3 = data3;
    this.dataInd4 = data4;
    this.dataInd5 = data5;
    this.dataInd6 = data6;
    this.dataInd7 = data7;
  }

  generateByDept(filters: GraphFilter) {
    this.indicateurService
      .getIndicateursByDept(
        filters.departementCodes,
        filters.period1.startDate,
        filters.period1.endDate
      )
      .subscribe((response) => {
        this.generateDataGraph(response, filters, true);
      });
  }

  transformCodDepInDepartmentName(codeDep: string): string {
    switch (codeDep) {
      // ARA
      case '01':
        return 'Ain';
      case '03':
        return 'Allier';
      case '07':
        return 'Ardèche';
      case '15':
        return 'Cantal';
      case '26':
        return 'Drôme';
      case '38':
        return 'Isère';
      case '42':
        return 'Loire';
      case '43':
        return 'Haute-Loire';
      case '63':
        return 'Puy-de-dôme';
      case '69':
        return 'Rhône';
      case '73':
        return 'Savoie';
      case '74':
        return 'Haute-savoie';
      case '82':
        return 'Tarn-et-Garonne';

      // IDF
      case '75':
        return 'Paris';
      case '77':
        return 'Seine-et-Marne';
      case '78':
        return 'Yvelines';
      case '91':
        return 'Essonne';
      case '92':
        return 'Hauts-de-Seine';
      case '93':
        return 'Seine-Saint-Denis';
      case '94':
        return 'Val-de-Marne';
      case '95':
        return "Val-d'Oise";
    }

    return '';
  }
}
