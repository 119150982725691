import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ActivityEffector } from '../../model/activity/activity-effector.model';

@Injectable({
  providedIn: 'root'
})
export class ActivityEffectorService {
  constructor(private httpClient: HttpClient) {}

  public getActivityEffectors(monthCount: number): Observable<ActivityEffector[]> {
    const params = { monthCount: monthCount, size: 10000 };
    return this.httpClient.get<ActivityEffector[]>(`api/effectors/activities`, { params });
    }
}
