import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'yesOrNo'
  })
  export class yesOrNoPipe implements PipeTransform {
    transform(value: boolean): string {
      if (value) {
        return "Oui"
      }else{
          return "Non"
      }
    }
  }