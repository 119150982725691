import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MobileDeathVisitRange } from '../../model/activity/mobile-death-visit-range.model';

@Injectable({
  providedIn: 'root'
})
export class MobileDeathVisitRangeService {
  constructor(private httpClient: HttpClient) {}

  public getMobileDeathVisitRange(
    id: number,
    yearMonth: string
  ): Observable<MobileDeathVisitRange> {
    return this.httpClient.get<MobileDeathVisitRange>(
      `api/effectors/${id}/activities/${yearMonth}/death`
    );
  }

  public setMobileDeathVisitRange(
    id: number,
    yearMonth: string,
    mobileDeathVisitRange: MobileDeathVisitRange
  ): Observable<MobileDeathVisitRange> {
    return this.httpClient.post<MobileDeathVisitRange>(
      `api/effectors/${id}/activities/${yearMonth}/death`,
      mobileDeathVisitRange
    );
  }
}
