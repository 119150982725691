import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EDTerritory } from '../model/ed-territory.model';

@Injectable({
  providedIn: 'root'
})
export class EDTerritoryService {
  constructor(
    private httpClient: HttpClient,
  ) { }

  public getEDTerritories(): Observable<EDTerritory[]> {
    return this.httpClient.get<EDTerritory[]>(`/api/ed-territories`);
  }

  public getEDTerritoriesByRegionId(regionId): Observable<EDTerritory[]> {
    return this.httpClient.get<EDTerritory[]>(`/api/ed-territories/region/${regionId}`);
  }
}
