<div class="container">
  <div class="modal-body">
    <!--CLOSE-->
    <div class="row d-flex justify-content-end">
      <em
        role="button"
        (click)="close()"
        class="bi bi-x-square"
        style="font-size: 1.5rem"
      ></em>
    </div>

    <!--TAILLE DE SAU-->
    <div class="row d-flex size">
      <h5 class="col-2 my-auto">Taille des SAU :</h5>
      <div class="col-10">
        <ul class="my-auto">
          <li *ngFor="let sizeGroup of sizeGroups">
            <input
              type="checkbox"
              [id]="'sizeGroup-' + sizeGroup.id"
              [checked]="selectedSizeGroupIds.has(sizeGroup.id)"
              [disabled]="searching"
              (click)="toggleSizeGroup(sizeGroup, $event.target.checked)"
              class="form-check-input"
              style="margin-top: 7px"
            />
            <label
              [for]="'sizeGroup-' + sizeGroup.id"
              class="form-check-label"
              >{{ sizeGroup.lib }}</label
            >
          </li>
        </ul>
      </div>
    </div>

    <!--LOADING-->
    <div class="row justify-content-center">
      <div
        *ngIf="searching"
        class="searching-border d-flex-column"
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="row table-dept-sau">
        <!--TABLE DEPARTEMENTS-->
        <div class="col-4 table-responsive">
          <table
            class="table table-sm table-bordered table-striped align-self-start dept-table"
          >
            <thead>
              <tr>
                <th scope="col">
                  <input
                    *ngIf="graphIndicateurCharge != true"
                    class="mt-1 mb-1"
                    style="margin-left: 3px"
                    type="checkbox"
                    id="allepartment "
                    [checked]="allCheckedDepartments"
                    (click)="toggleAllDepartments($event.target.checked)"
                  />
                </th>
                <th scope="col" class="col-11">Départements</th>
              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let departmentCode of departmentCodes">
                <th scope="row">
                  <div class="form-check form-check-inline dept-checkbox">
                    <input
                      type="checkbox"
                      [id]="'department' + departmentCode"
                      [checked]="selectedDepartmentCodes.has(departmentCode)"
                      (click)="
                        toggleDepartmentCode(
                          departmentCode,
                          $event.target.checked
                        )
                      "
                      class="form-check-input"
                    />
                  </div>
                </th>
                <td>
                  <label
                    style="margin-bottom: 0px"
                    [for]="'department' + departmentCode"
                    >{{ departmentCode }} -
                    {{ departmentCode | departmentName }}
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!--TABLE SAU-->
        <div class="table-responsive col-8">
          <table
            class="table table-sm table-bordered table-striped"
            *ngIf="searching == false"
          >
            <thead>
              <tr>
                <th scope="col">
                  <input
                    *ngIf="graphIndicateurCharge != true"
                    style="margin-left: 14px; margin-bottom: 4px"
                    name="radiogroup"
                    type="checkbox"
                    id="AllEmergencyDept"
                    [checked]="selectedAllEmergencyDept"
                    (click)="toggleAllEmergencyDept($event.target.checked)"
                  />
                </th>
                <th scope="col" class="col-11">SAU</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let emergencyDept of emergencyDepts">
                <th scope="row">
                  <div class="form-check form-check-inline sau-checkbox">
                    <input
                      [name]="graphIndicateurCharge ? 'radiogroup' : ''"
                      name="radiogroup"
                      class="form-check-input"
                      [type]="graphIndicateurCharge ? 'radio' : 'checkbox'"
                      [id]="'emergencyDept' + emergencyDept.id"
                      [checked]="selectedEmergencyDeptIds.has(emergencyDept.id)"
                      (click)="
                        graphIndicateurCharge
                          ? toggleOneEmergencyDept(
                              emergencyDept,
                              $event.target.checked
                            )
                          : toggleEmergencyDept(
                              emergencyDept,
                              $event.target.checked
                            )
                      "
                    />
                  </div>
                </th>
                <td>
                  <label
                    style="margin-bottom: 0px"
                    [for]="'emergencyDept' + emergencyDept.id"
                    >{{ emergencyDept.finess | slice: 0:2 }} -
                    {{ emergencyDept.abrSerUrg }}</label
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <hr />

      <!--PERIODS-->
      <div
        class="row d-flex mb-3"
        *ngIf="needsComparison || graphIndicateurCharge"
      >
        <h5 class="col-12">Périodes</h5>
      </div>

      <div
        class="row d-flex mb-3"
        *ngIf="needsComparison == false || graphIndicateurCharge == false"
      >
        <h5 class="col-12">Période</h5>
      </div>

      <!--PERIOD 1-->
      <div class="row d-flex align-items-center mb-3 period1">
        <div
          *ngIf="needsComparison || graphIndicateurCharge"
          class="col-2 period"
        >
          Période 1 :
        </div>
        <div class="col-3">
          <select
            class="form-select"
            (change)="selectPeriod1Option()"
            formControlName="period1OptionKind"
          >
            <option
              *ngFor="let period1Option of period1Options"
              [value]="period1Option.optionKind"
              [label]="period1Option.label"
            ></option>
          </select>
        </div>

        <ng-container *ngIf="isCustomPeriod1" class="d-flex personalize-period">
          <div class="input-group col-3">
            <input
              [minDate]="
                graphIndicateurCharge ? { year: 2019, month: 1, day: 1 } : ''
              "
              class="form-control"
              placeholder="jj-mm-aaaa"
              name="dp1Periode1"
              ngbDatepicker
              #d1="ngbDatepicker"
              formControlName="period1StartDate"
              data-date-format="dd-mm-yyyy"
              (ngModelChange)="changePeriod1StartDate()"
            />
            <button
              class="btn btn-outline-secondary calendar"
              (click)="d1.toggle()"
              type="button"
            >
              <em class="bi bi-calendar-week"></em>
            </button>
          </div>
          <div class="col-1 text-center my-auto">à</div>
          <div class="input-group col-3">
            <input
              [minDate]="
                graphIndicateurCharge ? { year: 2019, month: 1, day: 1 } : ''
              "
              class="form-control"
              placeholder="jj-mm-aaaa"
              name="dp2Periode1"
              ngbDatepicker
              #d2="ngbDatepicker"
              formControlName="period1EndDate"
              data-date-format="dd-mm-yyyy"
              (ngModelChange)="changePeriod1EndDate()"
            />
            <button
              class="btn btn-outline-secondary calendar"
              (click)="d2.toggle()"
              type="button"
            >
              <em class="bi bi-calendar-week"></em>
            </button>
          </div>
        </ng-container>
      </div>

      <!--PERIOD 1 DATES-->

      <!-- BTN COMPARE PERIOD-->
      <div *ngIf="needsComparison || graphIndicateurCharge">
        <div class="row d-flex justify-content-center mb-4">
          <button
            *ngIf="!isDisplayedPeriod2"
            type="button"
            class="btn btn-primary compare-buttons"
            (click)="displayPeriod2(true)"
          >
            Comparer à une autre période
          </button>
          <button
            *ngIf="isDisplayedPeriod2"
            type="button"
            class="btn btn-primary compare-buttons"
            (click)="displayPeriod2(false)"
          >
            Supprimer la période 2
          </button>
        </div>

        <!--PERIOD 2-->
        <div *ngIf="isDisplayedPeriod2" class="row d-flex mb-3 period1">
          <div class="col-2 period">Période 2 :</div>
          <div class="col-3">
            <select
              class="form-select"
              (change)="selectPeriod2Option()"
              formControlName="period2OptionKind"
            >
              <option
                *ngFor="let period2Option of period2Options"
                [value]="period2Option.optionKind"
                [label]="period2Option.label"
              >
                {{ period2Option.label }}
              </option>
            </select>
          </div>
          <!--PERIOD 2 DATES-->
          <ng-container
            *ngIf="isDisplayedPeriod2 && isCustomPeriod2"
            class="row d-flex mb-5 personalize-period"
          >
            <div class="input-group col-3">
              <input
                class="form-control"
                placeholder="jj-mm-aaaa"
                name="dp"
                ngbDatepicker
                #d3="ngbDatepicker"
                formControlName="period2StartDate"
                data-date-format="dd-mm-yyyy"
                (ngModelChange)="changePeriod2StartDate($event)"
              />
              <button
                class="btn btn-outline-secondary calendar"
                (click)="d3.toggle()"
                type="button"
              >
                <em class="bi bi-calendar-week"></em>
              </button>
            </div>
            <div class="col-1 text-center my-auto">à</div>
            <div class="input-group col-3">
              <input
                class="form-control"
                name="dp"
                ngbDatepicker
                #d4="ngbDatepicker"
                formControlName="period2EndDate"
                data-date-format="dd-mm-yyyy"
              />
              <button
                class="btn btn-outline-secondary calendar"
                (click)="d4.toggle()"
                type="button"
                disabled="true"
              >
                <em class="bi bi-calendar-week"></em>
              </button>
            </div>
          </ng-container>
        </div>

        <hr />

        <div class="row mb-3" *ngIf="graphIndicateurCharge != true">
          <h5 class="col-12">Comparer à un établissement</h5>
        </div>
        <div class="row" *ngIf="graphIndicateurCharge != true">
          <div class="col-12"></div>
          <div class="col-12 search-result">
            <ng-select
              notFoundText="Aucun résultats"
              placeholder="Recheche de SAU"
              [(ngModel)]="selectedEmergencyDeptId"
              formControlName="compareEmergencyDept"
            >
              <ng-option
                *ngFor="let emergencyDept of emergencyDeptsForNgSelect"
                value="{{emergencyDept.code}}-{{emergencyDept.type}}"
                >{{ emergencyDept.finess | slice: 0:2 }} -
                {{ emergencyDept.abrSerUrg }}</ng-option
              >
            </ng-select>
          </div>
          <div class="row mb-3" *ngIf="emergencyDepts.length">
            <!-- <select
            class="form-select"
            [size]="emergencyDepts.length"
            aria-label="size 7 select example"
            formControlName="emergencyDept"
          >
            <option
              *ngFor="let emergencyDept of emergencyDepts"
              (click)="getSau(emergencyDept.codEg, emergencyDept.codSerUrg)"
            >
              {{ emergencyDept.libSerUrg }}
            </option>
          </select> -->
          </div>
        </div>
      </div>

      <!--FOOTER-->
      <div class="row d-flex justify-content-between mb-5 footer">
        <div class="col-3 my-auto">
          <a role="button" (click)="clearFilters()">Supprimer les filtres</a>
        </div>
        <div>
          <button class="btn btn-light mr-5" type="button" (click)="close()">
            Annuler
          </button>
          <button
            [disabled]="
              graphIndicateurCharge && selectedEmergencyDeptIds.size == 0
                ? true
                : false
            "
            class="btn btn-primary valid-btn"
            type="submit"
          >
            Valider
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
