import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup
} from '@angular/forms';
import {
  NgbCalendar,
  NgbDateAdapter,
  NgbDateStruct
} from '@ng-bootstrap/ng-bootstrap';
import { Account } from 'src/app/core/auth/account.model';

import { EmergencySpeciality } from '../model/emergency-speciality.model';
import { GeoEntity } from '../model/geo-entity.model';
import { TechnicalParameter } from '../model/technical-parameter.model';

@Component({
  selector: 'ngbd-nav-markup',
  templateUrl: './nav-markup.component.html',
  styleUrls: ['./nav-markup.component.scss']
})
export class NgbdNavMarkup implements OnInit {
  @Input() emergencyForm;
  @Input() emergencyDepts;
  @Input() selectedEmergencyDept;
  @Input() geoEntities;
  @Input() edTerritories;
  @Input() users;
  @Input() deptSizes;
  @Input() doubleauthidden;
  @Input() technicalParameter;
  @Input() technicalParameterForm;
  @Input() selectedTechnicalParameter: TechnicalParameter;
  @Input() contactTechnicalParameter;
  @Input() contactTechnicalParameterForm;
  @Input() selectedContactTechnicalParameter;
  @Output() updateContacts = new EventEmitter<any>();
  @Output() addDeleteIds = new EventEmitter<any>();
  @Input() logicielMetierStartId: number | undefined;
  @Input() isNew;
  @Input() emergencySpeciality;
  @Input() selectedEmergencySpeciality: EmergencySpeciality;
  @Input() logicielTechnicalParameter;
  @Input() inputHierarchyForm;
  @Input() inputHierarchy: Object = {};
  @Input() classificationHet;
  @Input() classificationForm: FormGroup;
  @Input() classification: FormArray;
  @Output() updateInputHierarchySaisie = new EventEmitter<any>();
  @Input() contactsArrayForm: FormArray;
  @Input() contactsForm: FormGroup;
  @Input() newContactsForm: FormGroup;
  account: Account | null = null;

  @Input() itFormGroup: FormGroup;
  @Input() itForm: FormGroup;
  @Input() indicateur;

  model: NgbDateStruct;

  private regionIdString: string;
  private regionRORCode: string;
  regionId: number;
  display = "none";
  dis = "none";
  margin = "0%";
  emergencySpecialityService: any;

  constructor(
    private fb: FormBuilder,
    private ngbCalendar: NgbCalendar,
    private dateAdapter: NgbDateAdapter<string>
  ) {
    if (window.location.href.includes('ara')) {
      this.regionRORCode = 'R82';
      this.regionIdString = '84';
      this.regionId = 84;
    } else {
      this.regionRORCode = 'R11';
      this.regionIdString = '11';
      this.regionId = 11;
    }
  }

  get lessons() {
    return this.classificationForm.controls['classification'] as FormArray;
  }

  ngOnInit() {
    this.logicielTechnicalParameter = this.logicielTechnicalParameter.sort(
      (a, b) => (a.id > b.id ? 1 : -1)
    );
  }

  addNewContact() {
    this.contactsArrayForm.push(
      this.fb.group({
        nomPrenom: this.newContactsForm.value.nomPrenom,
        mail: this.newContactsForm.value.mail,
        telFixe: this.newContactsForm.value.telFixe,
        telMobile: this.newContactsForm.value.telMobile,
        editable: false,
        state: 'new',
        codEg: this.newContactsForm.value.codEg,
        codSerUrg: this.newContactsForm.value.codSerUrg,
        enabled: true
      })
    );
    console.log('inputhierarchy', this.inputHierarchy);
    console.log('classification', this.classificationHet);
    this.newContactsForm.reset();
    this.updateContacts.emit(this.contactsArrayForm.value);
  }

  removeContact(i) {
    this.addDeleteIds.emit(this.contactsArrayForm.value[i].id);
    this.contactsArrayForm.removeAt(i);
  }

  editContact(i) {
    this.contactsArrayForm.controls[i].patchValue({
      editable: true
    });
  }

  modifyContact(i) {
    if (this.contactsArrayForm.controls[i].value.state !== 'new') {
      this.contactsArrayForm.controls[i].patchValue({
        editable: false,
        state: 'updated'
      });
    } else {
      this.contactsArrayForm.controls[i].patchValue({
        editable: false
      });
    }
    this.updateContacts.emit(this.contactsArrayForm.value);
  }

  preventNonNumericalInput(e) {
    e = e || window.event;
    var charCode = typeof e.which == 'undefined' ? e.keyCode : e.which;
    var charStr = String.fromCharCode(charCode);

    if (!charStr.match(/^[0-9]+$/)) e.preventDefault();
  }

  changeTypeSu() {
    this.itForm.controls['IT1'].patchValue({
      codSerUrg: this.getField('typeSU').value
    });
    this.itForm.controls['IT2'].patchValue({
      codSerUrg: this.getField('typeSU').value
    });
    this.itForm.controls['IT3'].patchValue({
      codSerUrg: this.getField('typeSU').value
    });
    this.itForm.controls['IT4'].patchValue({
      codSerUrg: this.getField('typeSU').value
    });
    this.itForm.controls['IT5'].patchValue({
      codSerUrg: this.getField('typeSU').value
    });
    this.itForm.controls['IT6'].patchValue({
      codSerUrg: this.getField('typeSU').value
    });
    this.updateInputHierarchySaisie.emit();
  }
  changeGeoEntity(geoEntity: GeoEntity) {
    this.emergencyForm.patchValue({ codeROR: geoEntity.code });
    this.emergencyForm.patchValue({ finess: geoEntity.finessCode });
    this.itForm.controls['IT1'].patchValue({ codEg: geoEntity.code });
    this.itForm.controls['IT2'].patchValue({ codEg: geoEntity.code });
    this.itForm.controls['IT3'].patchValue({ codEg: geoEntity.code });
    this.itForm.controls['IT4'].patchValue({ codEg: geoEntity.code });
    this.itForm.controls['IT5'].patchValue({ codEg: geoEntity.code });
    this.itForm.controls['IT6'].patchValue({ codEg: geoEntity.code });
    this.updateInputHierarchySaisie.emit();
  }

  getField(name: string): AbstractControl {
    const field = this.emergencyForm.get(name);
    if (field === null) {
      throw new Error(`The field ${name} does not exist.`);
    }
    return field;
  }

  setLogicielMetier(event) {
    this.technicalParameterForm.controls.logicielMetier.patchValue(event.id);
  }

  setFluxRpu(value: number) {
    this.technicalParameterForm.controls.fluxRpu.patchValue(value);
  }

  setFluxAu(value: number) {
    this.technicalParameterForm.controls.fluxAu.patchValue(value);
  }

  isAraPage() {
    const str = window.location.href;
    return str.includes('ara') || str.includes(':4201/');
  }

  //TO DO : DELETE ?
  sortEmergencyDepts() {}

  comaToDot(srcObj) {
    console.log('coucou', srcObj);
    if (srcObj.target.value.indexOf(',') != -1) {
      srcObj.target.value =
        srcObj.target.value.split(',')[0] +
        '.' +
        srcObj.target.value.split(',')[1];
    }
    if (
      (srcObj.keyCode >= 65 && srcObj.keyCode <= 90) ||
      (srcObj.keyCode >= 97 && srcObj.keyCode <= 122) ||
      srcObj.keyCode == 45 ||
      srcObj.keyCode == 32 ||
      srcObj.key == '?' ||
      srcObj.key == ';' ||
      srcObj.key == '/' ||
      srcObj.key == '!' ||
      srcObj.key == '§' ||
      srcObj.key == ':'
    ) {
      srcObj.target.value = srcObj.target.value.split('')[0];
    }
  }
}
