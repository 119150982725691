import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MobileCrraVisitRange } from '../../model/activity/mobile-crra-visit-range.model';

@Injectable({
  providedIn: 'root'
})
export class MobileCrraVisitRangeService {
  constructor(private httpClient: HttpClient) {}

  public getMobileCrraVisitRange(
    id: number,
    yearMonth: string
  ): Observable<MobileCrraVisitRange> {
    return this.httpClient.get<MobileCrraVisitRange>(
      `api/effectors/${id}/activities/${yearMonth}/crra`
    );
  }

  public setMobileCrraVisitRange(
    id: number,
    yearMonth: string,
    mobileCrraVisitRange: MobileCrraVisitRange
  ): Observable<MobileCrraVisitRange> {
    return this.httpClient.post<MobileCrraVisitRange>(
      `api/effectors/${id}/activities/${yearMonth}/crra`,
      mobileCrraVisitRange
    );
  }
}
