import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  EffectorActivity,
  EffectorActivityClass
} from '../model/activity/effector-activity.model';

@Injectable({
  providedIn: 'root'
})
export class EffectorActivityService {
  constructor(private httpClient: HttpClient) {}

  public getEffectorActivityForm(
    monthId: string,
    effectorId: number
  ): Observable<EffectorActivityClass> {
    return this.httpClient
      .get<EffectorActivity>(
        `/api/pdsaActivity/mobileForm/${monthId}/${effectorId}`
      )
      .pipe(map((e) => new EffectorActivityClass(e)));
  }

  // Dans cette ligne, on créer une instance de la classe Subject qui est un observable qui peut être utilisé pour émettre des valeurs à partir d'une source et les envoyer à un ou plusieurs observateurs.
  private currentPageSource = new Subject<number>();
  currentPage$ = this.currentPageSource.asObservable();

  //setCurrentPage(page: number) { ... } : C'est une méthode du service qui permet de mettre à jour la valeur actuelle de la page. Lorsque vous appelez cette méthode avec un nombre en tant qu'argument, elle émet cette valeur à travers le Subject currentPageSource, de sorte que tous les observateurs qui se sont abonnés à currentPage$ recevront cette nouvelle valeur.
  setCurrentPage(page: number) {
    this.currentPageSource.next(page);
  }
}
