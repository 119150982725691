<form [formGroup]="contactForm" (ngSubmit)="onSubmit(content)">
  <div class="modal-header">
    <h4 class="modal-title w-100" id="modal-basic-title">
      Signaler un changement
    </h4>

    <button class="btn-close" (click)="closeModal()">
      <img id="btn-close" alt="bouton burger" />
    </button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-sm-6">
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <label for="inputLastName"
                >Prénom
                <span class="required"
                  >*
                  <span
                    *ngIf="
                      contactForm.get('firstname')?.invalid &&
                      contactForm.get('firstname')?.touched
                    "
                    class="error"
                    >Le prénom est requis</span
                  >
                </span>
              </label>

              <input
                type="text"
                [class]="
                  contactForm.get('firstname')?.invalid &&
                  contactForm.get('firstname')?.touched
                    ? 'form-control is-invalid'
                    : 'form-control'
                "
                id="inputName"
                aria-describedby="Prénom"
                placeholder="Prénom"
                name="prénom"
                formControlName="firstname"
                required
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <label for="inputName">Nom <span class="required">*</span></label>

              <span
                *ngIf="
                  contactForm.get('lastname')?.invalid &&
                  contactForm.get('lastname')?.touched
                "
                class="error"
                >Le nom est requis</span
              >

              <input
                type="name"
                [class]="
                  contactForm.get('lastname')?.invalid &&
                  contactForm.get('lastname')?.touched
                    ? 'form-control is-invalid'
                    : 'form-control'
                "
                id="inputLastName"
                aria-describedby="nom"
                placeholder="Nom"
                name="nom"
                formControlName="lastname"
                required
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <label for="inputName"
                >Email<span class="required">*</span></label
              >

              <span *ngIf="contactForm.get('email')?.invalid" class="error"
                >L'email est requis</span
              >

              <span
                *ngIf="contactForm.get('email')?.hasError('email')"
                class="error"
                >L'email n'est pas au format mail</span
              >

              <input
                type="mail"
                [class]="
                  (contactForm.get('email')?.invalid &&
                    contactForm.get('email')?.touched) ||
                  contactForm.get('email')?.hasError('email')
                    ? 'form-control is-invalid'
                    : 'form-control'
                "
                id="inputEmail"
                aria-describedby="email"
                placeholder="Email"
                name="email"
                formControlName="email"
                required
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <label for="inputTel">Téléphone mobile</label>
              <input
                type="number"
                class="form-control"
                id="inputtel"
                aria-describedby="tel"
                placeholder="Téléphone mobile"
                name="tel"
                formControlName="tel"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <label for="inputStructure"
                >Structure PDSA<span class="required">*</span></label
              >

              <span
                *ngIf="
                  contactForm.get('effectorArsLabel')?.touched &&
                  contactForm.get('effectorArsLabel')?.invalid
                "
                class="error"
                >La structure est requise</span
              >

              <input
                type="text"
                [class]="
                  contactForm.get('effectorArsLabel')?.touched &&
                  contactForm.get('effectorArsLabel')?.invalid
                    ? 'form-control is-invalid'
                    : 'form-control'
                "
                id="inputStructure"
                aria-describedby="Structure PDSA"
                placeholder="Structure PDSA"
                name="effectorArsLabel"
                formControlName="effectorArsLabel"
                required
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <label for="inputOffice"
                >Fonction<span class="required">*</span></label
              >

              <span
                *ngIf="
                  contactForm.get('fonction')?.invalid &&
                  contactForm.get('fonction')?.touched
                "
                class="error"
                >La fonction est requise</span
              >

              <input
                type="text"
                [class]="
                  contactForm.get('fonction')?.invalid &&
                  contactForm.get('fonction')?.touched
                    ? 'form-control is-invalid'
                    : 'form-control'
                "
                id="inputOffice"
                aria-describedby="fonction"
                placeholder="Fonction"
                name="fonction"
                formControlName="fonction"
                required
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label for="inputDetails"
            >Détail de la demande<span class="required">*</span></label
          >

          <span
            *ngIf="
              contactForm.get('details')?.touched &&
              contactForm.get('details')?.invalid
            "
            class="error"
            >Le détails sont requis</span
          >

          <textarea
            [class]="
              contactForm.get('details')?.touched &&
              contactForm.get('details')?.invalid
                ? 'textinput form-control details is-invalid'
                : 'textinput form-control details'
            "
            formControlName="details"
            name="details"
            id="inputDetails"
            maxlength="1000"
            aria-describedby="details"
            placeholder="Ma demande concerne... (Merci de détailler votre poste et les pages auxquelles vous souhaitez accéder)"
            required
          >
          </textarea>
        </div>
      </div>
    </div>
  </div>
  <div class="required text-right mr-3">
    Les champs suivis d'une *(étoile en rouge) sont obligatoires
  </div>

  <div class="modal-footer" style="justify-content: flex-start">
    <button type="submit" class="btn btn-lg submit">Envoyer</button>
    <button type="button" class="btn btn-lg cancel" (click)="closeModal()">
      Annuler
    </button>
  </div>
</form>
<!-- Modal ALERT SUCCESS -->
<ng-template #content let-modal="modal">
  <div class="alert-success">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Message envoyé</h4>
      <button class="btn-close" (click)="closeModal()">
        <img id="btn-close" alt="bouton burger" />
      </button>
    </div>
    <div class="modal-body">
      <p>Votre message a bien été envoyé !</p>
    </div>
  </div>
</ng-template>
