<form
  [formGroup]="mobileTerritoryVisitRangeFrom"
  (ngSubmit)="onSubmit(eventTypes.CLOSE)"
>
  <div class="table-responsive" style="overflow-y: auto; max-height: 600px">
    <table class="table table-striped shadow-sm p-3 bg-white">
      <thead style="position: sticky; top: 0; opacity: 1">
        <tr class="noselect header-row">
          <th
            style="
              max-width: 15vw;
              width: 15vw;
              padding: 0px;
              margin: 0px;
              position: sticky;
              top: 0;
              opacity: 1 !important;
              background-color: #2b8ca9 !important;
            "
          >
            <p class="fontSize" style="color: #ffffff; opacity: 1">
              Nombre de visites effectuées par territoire
            </p>
          </th>
          <th
            *ngFor="let timeSlotLabel of timeSlotLabels; let i = index"
            style="
              max-width: 4vw;
              margin: 0px;
              position: sticky;
              top: 0;
              opacity: 1;
            "
          >
            <p style="color: #ffffff">{{ timeSlotLabel }}</p>
            <p style="color: #ffffff">{{ timeSlotDayTypes[i] }}</p>
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container
          *ngFor="let territoryName of territoryNames; let i = index"
        >
          <tr class="cursor noselect hover-effect">
            <td class="ml-2">
              <div>{{ territoryName }}</div>
            </td>

            <ng-container
              *ngFor="
                let elementGroup of mobileTerritoryVisitRangeFrom.get(
                  'elements'
                )?.controls;
                let j = index
              "
            >
              <!-- Utilisez la variable i pour accéder aux données de manière appropriée -->
              <ng-container
                *ngIf="
                  elementGroup.get('elements')?.controls[i] as innerElementGroup
                "
              >
                <td>
                  <div class="d-flex justify-content-center align-items-center">
                    <input
                      type="text"
                      class="rounded border inputSize"
                      style="width: 4vw; height: 2vw"
                      [ngClass]="{
                        error: innerElementGroup
                          .get('count')
                          .hasError('invalidValue'),
                        warning: innerElementGroup
                          .get('count')
                          .hasError('emptyValue')
                      }"
                      [formControl]="innerElementGroup.get('count')"
                      (input)="this.onInputChange()"
                    />
                  </div>
                </td>
              </ng-container>
            </ng-container>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</form>
