import { Component, OnInit, ViewChild } from '@angular/core';
import { PdsaActivityTabOneComponent } from '../pdsa-activity-tab-one/pdsa-activity-tab-one.component';
import { PdsaActivityTabTwoComponent } from '../pdsa-activity-tab-two/pdsa-activity-tab-two.component';
import { PdsaActivityTabThreeComponent } from '../pdsa-activity-tab-three/pdsa-activity-tab-three.component';
import { PdsaActivityTabFourComponent } from '../pdsa-activity-tab-four/pdsa-activity-tab-four.component';
import { PdsaActivityTabFiveComponent } from '../pdsa-activity-tab-five/pdsa-activity-tab-five.component';
import { PdsaActivityTabSixComponent } from '../pdsa-activity-tab-six/pdsa-activity-tab-six.component';
import { PdsaActivityTabSevenComponent } from '../pdsa-activity-tab-seven/pdsa-activity-tab-seven.component';
import { PdsaActivityParentFormComponent } from '../pdsa-activity-parent-form.component';
import { MobileEffectorVolumeEntry } from 'src/app/pdsa/model/activity/mobile-effector-volume-entry.model';
import { MobileEffectorVolumeEntryService } from 'src/app/pdsa/service/activity/mobile-effector-volume-entry.service';
import { MobileTerritoryVisitRangeService } from 'src/app/pdsa/service/activity/mobile-territory-visit-range.service';
import { MobileCrraVisitRangeService } from 'src/app/pdsa/service/activity/mobile-crra-visit-range.service';
import { MobileDeathVisitRangeService } from 'src/app/pdsa/service/activity/mobile-death-visit-range.service';
import { MobileCallRangeService } from 'src/app/pdsa/service/activity/mobile-call-range.service';
import { MobileDecisionTypeRangeService } from 'src/app/pdsa/service/activity/mobile-decision-type-range.service';

@Component({
  selector: 'app-pdsa-mobile-activity-pdf',
  templateUrl: './pdsa-mobile-activity-pdf.component.html',
  styleUrls: ['./pdsa-mobile-activity-pdf.component.scss']
})
export class PdsaMobileActivityPdfComponent implements OnInit {
  @ViewChild(PdsaActivityTabOneComponent)
  firstChildComponentRef: PdsaActivityTabOneComponent;
  @ViewChild(PdsaActivityTabTwoComponent)
  secondChildComponentRef: PdsaActivityTabTwoComponent;
  @ViewChild(PdsaActivityTabThreeComponent)
  thirdChildComponentRef: PdsaActivityTabThreeComponent;
  @ViewChild(PdsaActivityTabFourComponent)
  fourthChildComponentRef: PdsaActivityTabFourComponent;
  @ViewChild(PdsaActivityTabFiveComponent)
  fifthChildComponentRef: PdsaActivityTabFiveComponent;
  @ViewChild(PdsaActivityTabSixComponent)
  sixhChildComponentRef: PdsaActivityTabSixComponent;
  @ViewChild(PdsaActivityTabSevenComponent)
  seventhChildComponentRef: PdsaActivityTabSevenComponent;

  effectorShortLabel: string;
  effectorDate: string;
  mobileVolumeEntryValues: {
    closedAnsweredCallCount: string;
    closedVisitCount: string;
    openedVisitCount: string;
    openedAnsweredCallCount: string;
  } = {
    closedAnsweredCallCount: '',
    closedVisitCount: '',
    openedVisitCount: '',
    openedAnsweredCallCount: ''
  };
  mobileTerritoryVisitRangeValues: any[] = [];
  mobileDecisionTypeRangeValues: any[] = [];
  mobileCrraVisitRangeValues: any[] = [];
  mobileVolumeEntry: MobileEffectorVolumeEntry;
  commentText: string = '';
  hasCommentEntered = false;
  effectorId: number;
  effectorYearMonth: string;
  timeSlotLabels: string[] = [];
  timeSlotDayTypes: string[] = [];
  timeSlotLabelsTabTwo: string[] = [];
  timeSlotDayTypesTabTwo: string[] = [];
  timeSlotLabelsTabFour: string[] = [];
  timeSlotDayTypesTabFour: string[] = [];
  timeSlotLabelsTabFive: string[] = [];
  timeSlotDayTypesTabFive: string[] = [];
  timeSlotLabelsTabSix: string[] = [];
  timeSlotDayTypesTabSix: string[] = [];
  items = ['Exemple 1'];
  territoryNames: string[] = [];
  decisionTypeLabels: string[] = [];
  mobileDeathVisitRangeValues: { count: string[]; crraCount: string[] } = {
    count: [],
    crraCount: []
  };
  mobileCallRangeValues: {
    callCount: string[];
    answeredCount: string[];
    answeredCountByDoctorOnSite: string[];
    answeredCountByDoctorOffSite: string[];
  } = {
    callCount: [],
    answeredCount: [],
    answeredCountByDoctorOnSite: [],
    answeredCountByDoctorOffSite: []
  };
  isBooleanPlatform: boolean;
  dateHourExport: string;
  sizes: number[] = [];
  lineNumberDivider = 11;

  constructor(
    private mobileVolumeEntryService: MobileEffectorVolumeEntryService,
    private pdsaActivityParentFormComponent: PdsaActivityParentFormComponent,
    private mobileCrraVisitRangeService: MobileCrraVisitRangeService,
    private mobileDeathVisitRangeService: MobileDeathVisitRangeService,
    private mobileTerritoryVisitRangeService: MobileTerritoryVisitRangeService,
    private mobileDecisionTypeRangeService: MobileDecisionTypeRangeService,
    private mobileCallRangeService: MobileCallRangeService
  ) {}

  ngOnInit(): void {
    this.isBooleanPlatform =
      this.pdsaActivityParentFormComponent.isBooleanPlatform;
    this.commentText = this.pdsaActivityParentFormComponent.commentText ?? '';
    this.getMobileVolumeEntry();
    this.getMobileCrraVisitRange();
    this.getMobileTerritoryVisitRange();
    this.getMobileDeathVisitRange();
    this.getMobileCallRange();
    this.getMobileDecisionTypeRange();
    if (this.pdsaActivityParentFormComponent.hasCommentEntered === true) {
      this.hasCommentEntered = true;
    }
    this.dateHourExport = this.pdsaActivityParentFormComponent.dateHourExport;
  }

  capitalizeFirstLetter(string: string | undefined): string {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    } else {
      return '';
    }
  }

  // TAB ONE
  getMobileVolumeEntry(
    id: number = this.pdsaActivityParentFormComponent.effectorId,
    yearMonth: string = this.pdsaActivityParentFormComponent.effectorYearMonth
  ) {
    this.mobileVolumeEntryService
      .getMobileVolumeEntry(id, yearMonth)
      .subscribe((selectedMobileVolumeEntry) => {
        this.effectorShortLabel =
          selectedMobileVolumeEntry?.entry?.effector?.shortLabel;

        const dateString = selectedMobileVolumeEntry?.entry.yearMonth;
        const dateObject = new Date(dateString);
        const monthString = dateObject.toLocaleString('default', {
          month: 'long'
        });
        const year = dateObject.getFullYear();
        this.effectorDate = `${monthString} ${year}`;
        this.mobileVolumeEntryValues = selectedMobileVolumeEntry;
        this.mobileVolumeEntryValues.closedAnsweredCallCount =
          selectedMobileVolumeEntry.closedAnsweredCallCount;
        this.mobileVolumeEntryValues.closedVisitCount =
          selectedMobileVolumeEntry.closedVisitCount;
        this.mobileVolumeEntryValues.openedAnsweredCallCount =
          selectedMobileVolumeEntry.openedAnsweredCallCount;
        this.mobileVolumeEntryValues.openedVisitCount =
          selectedMobileVolumeEntry.openedVisitCount;
      });
  }

  // TAB TWO
  getMobileTerritoryVisitRange(
    id: number = this.pdsaActivityParentFormComponent.effectorId,
    yearMonth: string = this.pdsaActivityParentFormComponent.effectorYearMonth
  ) {
    this.mobileTerritoryVisitRangeService
      .getMobileTerritoryVisitRange(id, yearMonth)
      .subscribe((selectedMobileTerritoryVisitRange) => {
        this.timeSlotLabelsTabTwo =
          selectedMobileTerritoryVisitRange.elements.map(
            (element) => element.timeSlot.label
          );
        this.timeSlotDayTypesTabTwo =
          selectedMobileTerritoryVisitRange.elements.map(
            (element) => element.timeSlot.dayType
          );
        const uniqueDecisionTypeLabelsSet = new Set<string>();

        this.territoryNames = selectedMobileTerritoryVisitRange.elements.reduce(
          (acc, element) => {
            element.elements.forEach((innerElement) => {
              uniqueDecisionTypeLabelsSet.add(
                innerElement.territory.code +
                  ' - ' +
                  innerElement.territory.name
              );
            });

            return acc;
          },
          [] as string[]
        );
        this.territoryNames = Array.from(uniqueDecisionTypeLabelsSet);
        this.territoryNames.sort((a, b) => {
          const getOrder = (str: string): number => {
            if (str.includes('EXT')) {
              return 1;
            } else {
              return 0;
            }
          };

          const orderA = getOrder(a);
          const orderB = getOrder(b);

          if (orderA !== orderB) {
            return orderA - orderB;
          } else {
            const codeA = parseInt(a.split(' - ')[0]);
            const codeB = parseInt(b.split(' - ')[0]);
            return codeA - codeB;
          }
        });

        for (
          let i = 0;
          i < selectedMobileTerritoryVisitRange.elements[0].elements.length;
          i++
        ) {
          this.mobileTerritoryVisitRangeValues[i] = [];
          for (
            let j = 0;
            j < selectedMobileTerritoryVisitRange.elements.length;
            j++
          ) {
            this.mobileTerritoryVisitRangeValues[i].push(
              selectedMobileTerritoryVisitRange.elements[j]?.elements[i]?.count
            );
          }
        }
        const size = Math.ceil(
          this.mobileTerritoryVisitRangeValues.length / this.lineNumberDivider
        );
        for (let i = 1; i <= size; i++) {
          this.sizes.push(i);
        }
      });
  }

  // TAB THREE
  getMobileCrraVisitRange() {
    this.mobileCrraVisitRangeService
      .getMobileCrraVisitRange(
        this.pdsaActivityParentFormComponent.effectorId,
        this.pdsaActivityParentFormComponent.effectorYearMonth
      )
      .subscribe((selectedMobileCrraVisitRange) => {
        selectedMobileCrraVisitRange.elements.map((element) => {
          this.timeSlotLabels.push(element.timeSlot.label);
          this.timeSlotDayTypes.push(element.timeSlot.dayType);
          this.mobileCrraVisitRangeValues.push(element.count);
        });
      });
  }

  // TAB FOUR
  getMobileDeathVisitRange(
    id: number = this.pdsaActivityParentFormComponent.effectorId,
    yearMonth: string = this.pdsaActivityParentFormComponent.effectorYearMonth
  ) {
    this.mobileDeathVisitRangeService
      .getMobileDeathVisitRange(id, yearMonth)
      .subscribe((selectedMobileDeathVisitRange) => {
        selectedMobileDeathVisitRange.elements.map((element) => {
          this.timeSlotLabelsTabFour.push(element.timeSlot.label);
          this.timeSlotDayTypesTabFour.push(element.timeSlot.dayType);
          this.mobileDeathVisitRangeValues.count.push(element.count);
          this.mobileDeathVisitRangeValues.crraCount.push(element.crraCount);
        });
      });
  }

  // TAB FIVE
  getMobileCallRange(
    id: number = this.pdsaActivityParentFormComponent.effectorId,
    yearMonth: string = this.pdsaActivityParentFormComponent.effectorYearMonth
  ) {
    this.mobileCallRangeService
      .getMobileCallRange(id, yearMonth)
      .subscribe((selectedMobileCall) => {
        selectedMobileCall.elements.map((element) => {
          this.timeSlotLabelsTabFive.push(element.timeSlot.label);
          this.timeSlotDayTypesTabFive.push(element.timeSlot.dayType);
          this.mobileCallRangeValues.answeredCount.push(element.answeredCount);
          this.mobileCallRangeValues.answeredCountByDoctorOffSite.push(
            element.answeredCountByDoctorOffSite
          );
          this.mobileCallRangeValues.answeredCountByDoctorOnSite.push(
            element.answeredCountByDoctorOnSite
          );
          this.mobileCallRangeValues.callCount.push(element.callCount);
        });
      });
  }

  // TAB SIX
  getMobileDecisionTypeRange(
    id: number = this.pdsaActivityParentFormComponent.effectorId,
    yearMonth: string = this.pdsaActivityParentFormComponent.effectorYearMonth
  ) {
    this.mobileDecisionTypeRangeService
      .getMobileDecisionTypeRange(id, yearMonth)
      .subscribe((selectedMobileDecisionTypeRange) => {
        this.timeSlotLabelsTabSix =
          selectedMobileDecisionTypeRange.elements.map(
            (element) => element.timeSlot.label
          );
        this.timeSlotDayTypesTabSix =
          selectedMobileDecisionTypeRange.elements.map(
            (element) => element.timeSlot.dayType
          );
        const uniqueDecisionTypeLabelsSetTabSix = new Set<string>();

        this.decisionTypeLabels =
          selectedMobileDecisionTypeRange.elements.reduce((acc, element) => {
            element.elements.forEach((innerElement) => {
              uniqueDecisionTypeLabelsSetTabSix.add(
                innerElement.decisionType.label
              );
            });

            return acc;
          }, [] as string[]);

        this.decisionTypeLabels = Array.from(uniqueDecisionTypeLabelsSetTabSix);

        for (
          let i = 0;
          i < selectedMobileDecisionTypeRange.elements[0].elements.length;
          i++
        ) {
          this.mobileDecisionTypeRangeValues[i] = [];
          for (
            let j = 0;
            j < selectedMobileDecisionTypeRange.elements.length;
            j++
          ) {
            this.mobileDecisionTypeRangeValues[i].push(
              selectedMobileDecisionTypeRange.elements[j]?.elements[i]?.count
            );
          }
        }
      });
  }
}
