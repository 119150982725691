import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IndicateurRpu } from "../model/indicateur-rpu.model";

@Injectable({
    providedIn:'root'
})

export class IndicateurRpuService {
    constructor(
        private httpClient: HttpClient,
    ) { }

    public getIndicateurRpu(): Observable<IndicateurRpu[]> {
        return this.httpClient.get<IndicateurRpu[]>(`/api/indicateur-rpu`);
    }

    public getAllByIdJou(): Observable<IndicateurRpu[]> {
        return this.httpClient.get<IndicateurRpu[]>(`api/indicateur-rpu/idJou`)
    }
}