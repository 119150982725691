import { HttpClient } from '@angular/common/http';
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Alert } from 'src/app/emergency-depts/model/alert.model';
import { AlertService } from 'src/app/emergency-depts/services/alert.service';
import { NgbdSortableHeader } from '../../../directive/sortable/sortable.directive';
import {
  DateValidityFilter,
  DateValidityFilterEnum
} from '../../model/date-validity-filter.model';
import { Effector, EffectorClass } from '../../model/effectors.model';
import { EffectorService } from '../../service/effector.service';
import { EffectorsTerritoryService } from '../../service/effectors-territory.service';
import { EffectorsAddressService } from '../../service/effectorsAddress.service';

@Component({
  selector: 'app-pdsa-settings',
  templateUrl: './pdsa-settings.component.html',
  styleUrls: ['./pdsa-settings.component.scss'],
  providers: [EffectorService]
})
export class PdsaSettingsComponent implements OnInit {
  constructor(
    private http: HttpClient,
    private effectorsFormService: EffectorService,
    private effectorsTerritoryService: EffectorsTerritoryService,
    private effectorsAddressService: EffectorsAddressService,
    private modalService: NgbModal,
    private readonly titleService: Title,
    private readonly route: ActivatedRoute,
    private alertService: AlertService
  ) {}

  // SORTING
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  // ITEMS PER PAGE
  page = 1;
  count = 0;
  tableSize = 10;
  tableSizes = [10, 20, 50];
  alerts: Alert[] = [];

  statusFilter = [
    { value: true, name: 'Actif' },
    { value: false, name: 'Inactif' }
  ];
  statusFilterValue = true;
  typeStructureFilter = [
    { value: 'MOB', name: 'Mobile' },
    { value: 'POS', name: 'Posté' }
  ];
  typeStructureFilterValue = null;

  public effectors: EffectorClass[] = [];
  filters: string[] = [];
  dateFilters = new DateValidityFilter();
  effectorsFormAllData = this.effectors;

  public DateValidityFilterEnum = DateValidityFilterEnum;

  changeRotate = {
    departmentCode: true,
    domainCode: true,
    effectorShortLabel: true,
    validityStartDate: true,
    validityEndDate: true,
    validityBoolean: true
  };

  title = 'appBootstrap';

  closeResult = '';

  ngOnInit(): void {
    this.titleService.setTitle(this.route.snapshot.data['title']);
    this.displayEffectors();
    const Reg = this.isAraPage() ? 84 : 11;
    this.alertService
      .getAlertsByPageAlert(Reg, 'ADMINISTRATION_Paramétrage-structures-PDSA')
      .subscribe((res: Alert[]) => {
        this.alerts = res;
        res.map((alert) => this.alertService.get(alert));
      });
  }

  isAraPage() {
    const str = window.location.href;
    return str.includes('ara') || str.includes(':4201/');
  }

  getDisplayValueTypeStructure(domainCode: string) {
    if (domainCode === 'MOB') {
      return 'Mobile';
    } else if (domainCode === 'POS') {
      return 'Posté';
    }
  }

  displayEffectors() {
    this.effectorsFormService.getEffectors().subscribe((effectors) => {
      this.effectors = effectors;
      this.effectors.sort((a, b) =>
        a.effectorShortLabel
          .toLowerCase()
          .trim()
          .localeCompare(b.effectorShortLabel.toLowerCase().trim())
      );
      this.effectors.sort((a, b) => a.domainCode.localeCompare(b.domainCode));
      this.effectorsFormAllData = this.effectors;
      this.filterTable();
    });
  }

  filterDate(
    column: string,
    event: Event,
    defaultTimestamp: DateValidityFilterEnum
  ) {
    const value = (event.target as HTMLInputElement).value;
    if (value === '') {
      this.dateFilters[column] = new Date(defaultTimestamp);
    } else {
      this.dateFilters[column] = new Date(
        (event.target as HTMLInputElement).value
      );
    }
    this.filterTable();
  }

  filterData(column: string, event: Event) {
    this.filters[column] = (
      event.target as HTMLInputElement
    ).value.toLowerCase();
    this.filterTable();
  }

  filterTable() {
    this.page = 1;
    this.effectors = this.effectorsFormAllData.filter((item) => {
      const basicFilterAllowed = Object.keys(this.filters).every((filter) => {
        return item[filter].toLowerCase().includes(this.filters[filter]);
      });
      const statusFilterAllowed =
        this.statusFilterValue == null
          ? true
          : item.validityBoolean === this.statusFilterValue;
      const dateFilterAllowed =
        this.dateFilters.firstValidityStartDate.setHours(0, 0, 0, 0) <=
          item.validityStartDate.setHours(0, 0, 0, 0) &&
        this.dateFilters.secondValidityStartDate.setHours(0, 0, 0, 0) >=
          item.validityStartDate.setHours(0, 0, 0, 0) &&
        this.dateFilters.firstValidityEndDate.setHours(0, 0, 0, 0) <=
          item.validityEndDate.setHours(0, 0, 0, 0) &&
        this.dateFilters.secondValidityEndDate.setHours(0, 0, 0, 0) >=
          item.validityEndDate.setHours(0, 0, 0, 0);
      const typeStructureFilterAllowed =
        this.typeStructureFilterValue == null
          ? true
          : item.domainCode === this.typeStructureFilterValue;

      return (
        basicFilterAllowed &&
        dateFilterAllowed &&
        statusFilterAllowed &&
        typeStructureFilterAllowed
      );
    });
  }

  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
  }

  updateEffectorStatut(effector: Effector) {
    this.effectorsFormService
      .updateEffectorStatus(effector.id, effector.validityBoolean)
      .subscribe(
        (result) => {
          console.log('update effector statut', result);
        },
        (error) => {
          effector.validityBoolean = !effector.validityBoolean;
        }
      );
  }

  changeStyle(column: string) {
    this.changeRotate[column] = !this.changeRotate[column];
  }

  // FUNCTION TO OPEN PDSA TERRITORY TABS BY ID_EFFECTEUR
  openPdsa(id: number) {
    this.effectorsFormService.getEffectorsById(id).subscribe((data) => {});

    this.effectorsTerritoryService
      .getEffectorsTerritoryById(id)
      .subscribe((data) => {});
    this.effectorsAddressService
      .getEffectorsAddressById(id)
      .subscribe((data) => {});
    this.effectorsFormService.getUsersByEffectorId(id).subscribe((data) => {});
  }

  // OPEN MODALE
  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
