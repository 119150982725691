import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-mentions-legales",
  templateUrl: "./mentions-legales.component.html",
  styleUrls: ["./mentions-legales.component.scss"],
})
export class MentionsLegalesComponent implements OnInit {
  constructor( ) {}

  ngOnInit(): void { }

  isAraPage() {
    const str = window.location.href;
    return str.includes("ara") || str.includes(":4201/");
  }
}
