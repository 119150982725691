import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';

import { EffectorActivityClass } from '../../model/activity/effector-activity.model';
import { PostedConsultationEntry } from '../../model/activity/posted-consultation-entry.model';
import { EffectorClass } from '../../model/effectors.model';
import { PostedConsultationEntryService } from '../../service/activity/posted-consultation-entry.service';
import { PdsaPosteActivityService } from './pdsa-poste-activity-service';
import { PdsaPosteActivityTabOneComponent } from './pdsa-poste-activity-tab-one/pdsa-poste-activity-tab-one.component';
import { PdsaPosteActivityTabTwoComponent } from './pdsa-poste-activity-tab-two/pdsa-poste-activity-tab-two.component';
import { State } from '../../model/activity/effector-state.enum';
import { PostedConsultationRangeService } from '../../service/activity/posted-consultation-range.service';
import { PostedConsultationRange } from '../../model/activity/posted-consultation-range.model';
import * as html2pdf from 'html2pdf.js';
import { PdsaPosteActivityTabThreeComponent } from './pdsa-poste-activity-tab-three/pdsa-poste-activity-tab-three.component';
import { EventType } from '../../model/activity/effector-event-type.enum';
import { ChangeDetectorRef } from '@angular/core';
import { PdsaPosteActivityPDFComponent } from './pdsa-poste-activity-pdf/pdsa-poste-activity-pdf.component';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import jsPDF, { HTMLOptions, jsPDFOptions } from 'jspdf';

@Component({
  selector: 'app-pdsa-poste-activity-form',
  templateUrl: './pdsa-poste-activity-form.component.html',
  styleUrls: ['./pdsa-poste-activity-form.component.scss'],
  providers: [PdsaPosteActivityService]
})
export class PdsaPosteActivityFormComponent implements OnInit {
  @ViewChild(PdsaPosteActivityTabOneComponent)
  childComponentRef1: PdsaPosteActivityTabOneComponent;
  @ViewChild(PdsaPosteActivityTabTwoComponent)
  childComponentRef2: PdsaPosteActivityTabTwoComponent;
  @ViewChild(PdsaPosteActivityTabThreeComponent)
  childComponentRef3: PdsaPosteActivityTabThreeComponent;
  @ViewChild(PdsaPosteActivityPDFComponent)
  childComponentRefPDF: PdsaPosteActivityPDFComponent;

  @ViewChild('content') content: ElementRef;
  @ViewChild('nav')
  nav;

  @Input() effectorsForm;
  @Input() selectedEffector;
  @Input() form!: FormGroup;
  @Output() formChange = new EventEmitter<FormGroup>();

  @Output() hasCommentChange = new EventEmitter<boolean>();

  hasComment = false;
  openCommentLet = false;
  hasCommentEntered = false;
  commentText: string | undefined = '';
  effectorShortLabel: string;
  effectorDate: string;
  hasValuesEntered: boolean = false;
  disableButton: boolean = false;
  tooltipMessage: string = '';
  cursorStyle: string = 'pointer';
  colorStyle: string = '#56CC9D';
  colorStyleDark: string = '#1E3050';
  lastSavedStep: number = 0;
  loading: boolean = false;

  eventTypes = EventType;

  public effectors: EffectorClass[] = [];
  effectorId: number;
  effectorYearMonth: string;
  currentPage: number = 1;
  lastStepVisited: number;
  currentTab: number;
  activeTab: number = 1;
  dateHourExport: string = '';

  effectorActivity?: EffectorActivityClass;
  closeResult = '';

  postedConsultationEntries: PostedConsultationEntry;

  postedConsultationEntriesForm: FormGroup = this.fb.group({
    entry: this.fb.group({
      effector: this.fb.group({
        id: [],
        shortLabel: ['']
      })
    })
  });

  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    private pdsaPosteActivityService: PdsaPosteActivityService,
    private router: Router,
    private readonly route: ActivatedRoute,
    private postedConsultationEntryService: PostedConsultationEntryService,
    private postedConsultationRangeService: PostedConsultationRangeService,
    private cdr: ChangeDetectorRef
  ) {
    this.form = this.fb.group({
      tab_one: this.fb.group({}),
      tab_two: this.fb.group({}),
      tab_three: this.fb.group({})
    });
  }

  getTitle() {
    this.effectors.values;
  }

  ngOnInit() {
    this.getEffectorId()
      .then((effectorId) => {
        this.effectorId = effectorId;
        return effectorId;
      })
      .then((effectorId) => {
        return this.getEffectorYearMonth().then((effectorYearMonth) => {
          this.effectorYearMonth = effectorYearMonth;
          return { effectorId, effectorYearMonth };
        });
      })
      .then(({ effectorId, effectorYearMonth }) => {
        return this.getPostedConsultationEntries(effectorId, effectorYearMonth);
        // Je récupère les données du tab 2, j'en profite pour setter la valeur du commentaire dans le .then suivant
      })
      .then((postedConsultationEntry) => {
        // HERE on récupère le mauvais step
        this.postedConsultationEntries = postedConsultationEntry;
        this.lastStepVisited = postedConsultationEntry.entry.step;
        this.commentText = postedConsultationEntry.entry.comment;
        this.lastSavedStep = postedConsultationEntry.entry.step;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getEffectorId(): Promise<number> {
    return new Promise((resolve, reject) => {
      this.route.url.subscribe((segments) => {
        if (segments.length > 0) {
          const lastSegment = segments[segments.length - 1].path;
          const effectorId: number = parseInt(lastSegment, 10);
          resolve(effectorId);
        } else {
          reject("Aucun segment trouvé dans l'URL.");
        }
      });
    });
  }

  getEffectorYearMonth(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.route.url.subscribe((segments) => {
        if (segments.length > 0) {
          const lastSegment = segments[segments.length - 2].path;
          const position = lastSegment.length - 2;
          const effectorYearMonth =
            lastSegment.slice(0, position) + '-' + lastSegment.slice(position);
          resolve(effectorYearMonth);
        } else {
          reject("Aucun segment trouvé dans l'URL.");
        }
      });
    });
  }

  getPostedConsultationEntries(
    id: number = this.effectorId,
    yearMonth: string = this.effectorYearMonth
  ): Promise<PostedConsultationEntry> {
    return new Promise((resolve, reject) => {
      this.postedConsultationEntryService
        .getPostedConsultationEntries(id, yearMonth)
        .subscribe((selectedPostedConsultationEntry) => {
          this.postedConsultationEntries = selectedPostedConsultationEntry;
          this.effectorShortLabel =
            selectedPostedConsultationEntry?.entry?.effector?.shortLabel;

          const dateString = selectedPostedConsultationEntry?.entry.yearMonth;
          const dateObject = new Date(dateString);
          const monthString = dateObject.toLocaleString('default', {
            month: 'long'
          });
          const year = dateObject.getFullYear();
          this.effectorDate = `${monthString} ${year}`;

          this.postedConsultationEntriesForm.patchValue(
            this.postedConsultationEntries
          );
          this.commentText = this.postedConsultationEntries.entry.comment;
          if (this.commentText && this.commentText.trim() !== '') {
            this.hasCommentEntered = true;
          }
          return resolve(this.postedConsultationEntries);
        });
    });
  }

  openComment() {
    this.openCommentLet = true;
  }

  selectTab(tabNumber: number) {
    this.nav.select(tabNumber);
  }

  selectTabNumber(tabNumber: number) {
    this.currentTab = tabNumber;
  }

  selectTabAndSaveCurrentTab(tabNumber: number) {
    this.currentPage = tabNumber;
    this.saveCurrentTab(this.activeTab);
  }

  saveCurrentTab(activeTab: number) {
    switch (activeTab) {
      case 1:
        this.childComponentRef1.onNavigateSubmit();
        break;
      case 2:
        this.childComponentRef2.onNavigateSubmit();
        break;
      case 3:
        this.childComponentRef3.onNavigateSubmit();
        break;
      default:
        break;
    }
  }

  //Function call when we click one of the previous button
  saveAndSelectPreviousTab(currentPage: number) {
    if (currentPage === 2) {
      this.childComponentRef2.onSubmitAndSelectPreviousTab();
    }
    if (currentPage === 3) {
      this.childComponentRef3.onSubmit(EventType.NAVIGATE_PREVIOUS);
    }
  }

  selectNextTab() {
    if (
      (this.currentPage < 2 && !this.hasCommentEntered) ||
      (this.currentPage < 3 && this.hasCommentEntered)
    ) {
      this.currentPage++;
      this.pdsaPosteActivityService.setCurrentPage(this.currentPage);
      this.selectTab(this.currentPage);
    }
  }

  selectPreviousTab() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.pdsaPosteActivityService.setCurrentPage(this.currentPage);
      this.selectTab(this.currentPage);
    }
  }

  selectTabOne() {
    this.currentPage = 1;
    this.pdsaPosteActivityService.setCurrentPage(this.currentPage);
    this.selectTab(this.currentPage);
  }

  selectTabTwo() {
    this.currentPage = 2;
    this.pdsaPosteActivityService.setCurrentPage(this.currentPage);
    this.selectTab(this.currentPage);
  }

  selectTabThree() {
    this.currentPage = 3;
    this.pdsaPosteActivityService.setCurrentPage(this.currentPage);
    this.selectTab(this.currentPage);
  }

  // Variable to track the consultation state of the tabs
  public tabView = {
    tab2: false,
    tab3: false
  };

  getFormGroupFor(control: string): FormGroup {
    return this.form.get(control) as FormGroup;
  }

  capitalizeFirstLetter(string: string | undefined): string {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    } else {
      return '';
    }
  }

  onInputChange() {
    const fullFields =
      this.childComponentRef1.postedConsultationRangeForm.value.elements.map(
        (element: any) => element.count
      );
    if (fullFields.length === 0) {
      this.router.navigateByUrl('/pdsa-structure-activity');
      return;
    }
  }

  closeForm(currentPage: number) {
    const id = this.effectorId;
    const yearMonth = this.effectorYearMonth;
    if (
      currentPage === 1 &&
      this.areNoValuesEntered() &&
      this.lastSavedStep === 0
    ) {
      this.router.navigateByUrl('/pdsa-structure-activity');
      return;
    }
    if (currentPage === 2) {
      this.postedConsultationRangeService
        .getPostedConsultationRanges(id, yearMonth)
        .subscribe((postedConsultationRanges) => {
          this.commentText = postedConsultationRanges.entry.comment;
          let hasValue = false;
          postedConsultationRanges.elements.forEach((element) => {
            if (
              element.count !== null &&
              element.count !== undefined &&
              element.count !== ''
            ) {
              hasValue = true;
            }
          });
          const values =
            this.childComponentRef2.postedConsultationEntriesForm.value;
          if (
            (values.count !== null &&
              values.count !== undefined &&
              values.count !== '') ||
            (values.crraCount !== null &&
              values.crraCount !== undefined &&
              values.crraCount !== '')
          ) {
            hasValue = true;
          }
          if (hasValue) {
            Swal.fire({
              title: 'ATTENTION !',
              text: 'Vous allez sortir du formulaire. Souhaitez-vous enregistrer vos dernières modifications avant de sortir ?',
              showCancelButton: true,
              showDenyButton: true,
              confirmButtonText: 'OUI',
              confirmButtonColor: '#56CC9D',
              denyButtonText: `NON`,
              denyButtonColor: '#F98776',
              cancelButtonText: `ANNULER`,
              cancelButtonColor: '#56647C',
              customClass: {
                actions: 'my-actions',
                cancelButton: 'order-3 ml-5',
                confirmButton: 'order-1',
                denyButton: 'order-2 tool-tip mr-3'
              }
            }).then((result) => {
              if (result.isConfirmed) {
                this.childComponentRef2.onConfirm();
                return;
              } else if (result.isDenied) {
                this.router.navigateByUrl('/pdsa-structure-activity');
                Swal.fire({
                  title: 'Dernières modifications non sauvegardées',
                  icon: 'info',
                  confirmButtonColor: '#2B8CA9',
                  confirmButtonText: 'OK'
                });
              }
            });
          } else {
            this.router.navigateByUrl('/pdsa-structure-activity');
            return;
          }
        });
      return;
    }
    Swal.fire({
      title: 'ATTENTION !',
      text: 'Vous allez sortir du formulaire. Souhaitez-vous enregistrer vos dernières modifications avant de sortir ?',
      showCancelButton: true,
      showDenyButton: true,
      confirmButtonText: 'OUI',
      confirmButtonColor: '#56CC9D',
      denyButtonText: `NON`,
      denyButtonColor: '#F98776',
      cancelButtonText: `ANNULER`,
      cancelButtonColor: '#56647C',
      customClass: {
        actions: 'my-actions',
        cancelButton: 'order-3 ml-5',
        confirmButton: 'order-1',
        denyButton: 'order-2 tool-tip mr-3'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        if (currentPage === 1) {
          this.childComponentRef1.onSubmit(true);
        } else if (currentPage === 3) {
          this.childComponentRef3.onSubmit(EventType.CLOSE);
        }
      } else if (result.isDenied) {
        this.router.navigateByUrl('/pdsa-structure-activity');
        Swal.fire({
          title: 'Dernières modifications non sauvegardées',
          icon: 'info',
          confirmButtonColor: '#2B8CA9',
          confirmButtonText: 'OK'
        });
      }
    });
  }

  areAllValuesProvided(dataTabOne: PostedConsultationRange): boolean {
    let areAllValuesProvided = true;
    dataTabOne.elements.forEach((element) => {
      if (!element.count) {
        areAllValuesProvided = false;
      }
    });
    return areAllValuesProvided;
  }

  areNoValuesProvided(dataTabOne: PostedConsultationRange): boolean {
    dataTabOne.elements.forEach((element) => {
      if (element.count) {
        return false;
      }
    });
    return true;
  }

  areNoValuesEntered(): boolean {
    const elementsArray =
      this.childComponentRef1.postedConsultationRangeForm.get(
        'elements'
      ) as FormArray;

    for (const elementControl of elementsArray.controls) {
      const elementValue = elementControl.get('count')?.value;
      if (
        elementValue !== null &&
        elementValue !== undefined &&
        elementValue !== ''
      ) {
        return false;
      }
    }

    return true;
  }

  validateData(close: boolean): boolean {
    //We verify that the data are well entered otherwise we display an error message
    if (
      !this.hasValuesEntered &&
      !this.childComponentRef2.postedConsultationEntry.count &&
      !this.childComponentRef2.postedConsultationEntry.crraCount
    ) {
      this.tooltipMessage = 'Validation impossible car aucune donnée saisie';
      this.cursorStyle = 'not-allowed';
      this.colorStyle = '#abdbe3';
      this.colorStyleDark = '#56647C';
      if (close) {
        Swal.fire({
          title: 'Aucune donnée saisie',
          text: "Vous ne pouvez pas valider car aucune donnée n'a été saisie",
          icon: 'warning',
          confirmButtonColor: '#2B8CA9',
          confirmButtonText: 'OK'
        });
      }
      return false;
    }
    return true;
  }

  getDataFromFormTwo() {
    this.childComponentRef2.postedConsultationEntry.count =
      this.childComponentRef2.postedConsultationEntriesForm.get('count')?.value;
    this.childComponentRef2.postedConsultationEntry.crraCount =
      this.childComponentRef2.postedConsultationEntriesForm.get('crraCount')
        ?.value;

    if (
      this.childComponentRef2.postedConsultationEntriesForm.get('comment')
        ?.value
    ) {
      this.childComponentRef2.postedConsultationEntry.entry.comment =
        this.childComponentRef2.postedConsultationEntriesForm.get('comment')
          ?.value;
    } else {
      // Si j'ai une valeur dans le comment sa valeur est celle que je viens de récupérer dans le textarea sinon ici, le comment prend la valeur du parent
      this.childComponentRef2.postedConsultationEntry.entry.comment =
        this.commentText;
    }
  }

  updateState(
    event: EventType,
    postedConsultation: PostedConsultationRange | PostedConsultationEntry
  ) {
    switch (event) {
      case EventType.SUBMIT:
        postedConsultation.entry.state = State.COMPLETED;
        break;
      default:
        if (postedConsultation.entry.state !== State.COMPLETED) {
          postedConsultation.entry.state = State.UNCOMPLETED;
        }
        break;
    }
  }

  refreshTabTwo() {
    this.getEffectorId()
      .then((effectorId) => {
        return effectorId;
      })
      .then((effectorId) => {
        return this.getEffectorYearMonth().then((effectorYearMonth) => {
          return { effectorId, effectorYearMonth };
        });
      })
      .then(({ effectorId, effectorYearMonth }) => {
        this.getPostedConsultationEntries(effectorId, effectorYearMonth);
        this.commentText = this.postedConsultationEntries.entry.comment;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleSaveFormTabTwo(
    id: number,
    yearMonth: string,
    event: EventType,
    hasValue: boolean,
    step: number
  ) {
    this.getDataFromFormTwo();

    this.updateState(event, this.childComponentRef2.postedConsultationEntry);

    if (hasValue || this.lastSavedStep >= step || this.hasValuesEntered) {
      this.postedConsultationEntryService
        .setPostedConsultationEntries(
          id,
          yearMonth,
          this.childComponentRef2.postedConsultationEntry
        )
        .subscribe((response) => {
          this.lastSavedStep = this.postedConsultationEntries.entry.step;
          if (response) {
            this.postedConsultationEntries = response;
          }
        });
    }
    switch (event) {
      case EventType.SUBMIT:
      case EventType.CLOSE:
        this.router.navigateByUrl('/pdsa-structure-activity').then(() => {
          Swal.fire({
            title: 'Données sauvegardées avec succès !',
            icon: 'success',
            confirmButtonColor: '#2B8CA9',
            confirmButtonText: 'OK'
          });
        });
        break;
      case EventType.NAVIGATE_NEXT:
        this.selectNextTab();
        break;
      case EventType.NAVIGATE_PREVIOUS:
        this.selectPreviousTab();
        break;
    }
  }

  updateStep(step: number): number {
    this.postedConsultationEntries.entry.step = Math.max(
      this.postedConsultationEntries.entry.step,
      step
    );
    return this.postedConsultationEntries.entry.step;
  }

  getDataFromTabOne() {
    const elementsArray =
      this.childComponentRef1.postedConsultationRangeForm.get(
        'elements'
      ) as FormArray;
    return elementsArray.controls.forEach((elementControl, index) => {
      const elementValue = elementControl.get('count')?.value;
      this.childComponentRef1.postedConsultationRanges.elements[index].count =
        elementValue;
    });
  }

  handleSaveFormTabOne(
    id: number,
    yearMonth: string,
    event: EventType,
    hasValue: boolean,
    step: number
  ): Promise<PostedConsultationRange> {
    this.hasValuesEntered = hasValue;
    return new Promise((resolve, reject) => {
      try {
        this.updateState(
          event,
          this.childComponentRef1.postedConsultationRanges
        );

        this.getDataFromTabOne();
        // Ici, je set la valeur du comment tj avec celle du parent
        const postedConsultationRanges =
          this.childComponentRef1.postedConsultationRanges;
        postedConsultationRanges.entry.comment = this.commentText;
        postedConsultationRanges.entry.step = this.lastStepVisited;

        if (hasValue || this.lastSavedStep >= step) {
          this.postedConsultationRangeService
            .setPostedConsultationRanges(
              id,
              yearMonth,
              postedConsultationRanges
            )
            .subscribe((response) => {
              this.lastSavedStep = this.postedConsultationEntries.entry.step;
              if (response) {
                return resolve(response);
              }
              return resolve(this.childComponentRef1.postedConsultationRanges);
            });
        }
        return resolve(this.childComponentRef1.postedConsultationRanges);
      } catch (error) {
        reject(error);
      }
    });
  }

  isViewed(step): boolean {
    if (
      this.postedConsultationEntries &&
      this.postedConsultationEntries.entry.step
    ) {
      return step <= this.postedConsultationEntries.entry.step;
    }
    return false;
  }

  handleError(elementsArray: FormArray, attributsName: string[]) {
    let hasInvalidValue = false;
    let hasEmptyValue = false;
    let hasValue = false;

    elementsArray.controls.forEach((elementControl) => {
      for (const attributName of attributsName) {
        const elementValue = elementControl.get(attributName)?.value;

        if (elementValue !== undefined) {
          const isInvalid = isNaN(elementValue) && elementValue !== 'F';
          const isEmpty = !elementValue || elementValue.trim() === '';

          if (isInvalid) {
            hasInvalidValue = true;
            elementControl.get(attributName)?.setErrors({ invalidValue: true });
          } else if (isEmpty) {
            hasEmptyValue = true;
            elementControl.get(attributName)?.setErrors({ emptyValue: true });
          } else {
            hasValue = true;
            elementControl.get(attributName)?.setErrors(null);
          }
        } else {
          hasEmptyValue = true;
          elementControl.get(attributName)?.setErrors({ emptyValue: true });
        }
      }
    });

    return { hasInvalidValue, hasEmptyValue, hasValue };
  }

  // EXPORT PDF
  async exportToPDF() {
    this.loading = true;
    const options: jsPDFOptions = {
      orientation: 'landscape'
    };

    let doc = new jsPDF(options);

    const htmlOptions: HTMLOptions = {
      jsPDF: doc,
      autoPaging: false,
      windowWidth: 1600,
      width: 297,
      html2canvas: {
        useCORS: true,
        allowTaint: false
      },
      x: 0,
      y: 0
    };

    const docDiv = document.querySelectorAll(
      '.export_content'
    ) as NodeListOf<HTMLDivElement>;

    await doc.html(docDiv[0], htmlOptions);

    for (let i = 0; i < docDiv.length; i++) {
      if (i > 0) {
        doc.addPage();
      }
      await doc.html(docDiv[i], htmlOptions);
    }

    doc.save(this.effectorShortLabel + ' - ' + this.effectorDate + '.pdf');
    this.loading = false;
  }

  showModalPdf(content: any) {
    const currentDate = new Date();
    const dateString = currentDate.toLocaleDateString();
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const timeString = `${hours < 10 ? '0' : ''}${hours}:${
      minutes < 10 ? '0' : ''
    }${minutes}`;
    this.dateHourExport = `Export du ${dateString} ${timeString}`;
    this.modalService
      .open(content, { size: 'xl', ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  /**
   * Write code on Method
   *
   * @return response()
   */
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
