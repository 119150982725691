import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IndicateurSamu } from "../model/indicateur-samu.model";

@Injectable({
    providedIn: 'root'
})

export class IndicateurSamuService {
    constructor(
        private httpClient: HttpClient,
    ) { }

    public getIndicateurSamu(): Observable<IndicateurSamu[]> {
        return this.httpClient.get<IndicateurSamu[]>(`/api/indicateur-samu`);
    }

    public getAllByIdJou(): Observable<IndicateurSamu[]> {
        return this.httpClient.get<IndicateurSamu[]>(`api/indicateur-samu/idJou`)
    }
}