import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MobileDeathVisitRange } from 'src/app/pdsa/model/activity/mobile-death-visit-range.model';
import { MobileDeathVisitRangeService } from 'src/app/pdsa/service/activity/mobile-death-visit-range.service';

import { PdsaPosteActivityService } from '../../pdsa-poste-activity-form/pdsa-poste-activity-service';
import { EventType } from 'src/app/pdsa/model/activity/effector-event-type.enum';
import Swal from 'sweetalert2';
import { PdsaActivityParentFormComponent } from '../pdsa-activity-parent-form.component';

@Component({
  selector: 'app-pdsa-activity-tab-four',
  templateUrl: './pdsa-activity-tab-four.component.html',
  styleUrls: ['./pdsa-activity-tab-four.component.scss'],
  providers: [PdsaPosteActivityService]
})
export class PdsaActivityTabFourComponent implements OnInit {
  @Input() form!: FormGroup;
  @Output() formChange = new EventEmitter<FormGroup>();
  @Output() onSubmitEvent: EventEmitter<any> = new EventEmitter();
  @Output() hasCommentChange = new EventEmitter<boolean>();

  effectorId: number;
  effectorYearMonth: string;
  timeSlotLabels: string[] = [];
  timeSlotDayTypes: string[] = [];
  currentPage: number;
  eventTypes = EventType;
  valueHasChanged: boolean = false;
  openCommentLet = false;
  hasCommentEntered = this.pdsaActivityParentFormComponent.hasCommentEntered;
  hasComment = false;
  commentText: string | undefined = '';
  isBooleanPlatform: boolean =
    this.pdsaActivityParentFormComponent.isBooleanPlatform;
  step: number = 4;

  mobileDeathVisitRangeForm = this.fb.group({
    entry: this.fb.group({
      yearMonth: [''],
      effector: this.fb.group({
        id: []
      })
    }),
    comment: this.fb.control(''),
    elements: this.fb.array([])
  });

  mobileDeathVisitRange: MobileDeathVisitRange;

  constructor(
    private readonly route: ActivatedRoute,
    private mobileDeathVisitRangeService: MobileDeathVisitRangeService,
    private fb: FormBuilder,
    private pdsaPosteActivityService: PdsaPosteActivityService,
    private pdsaActivityParentFormComponent: PdsaActivityParentFormComponent
  ) {}

  ngOnInit() {
    this.pdsaPosteActivityService.currentPage$.subscribe((page) => {
      this.currentPage = page;
    });
    this.form.addControl(
      'tab-four-form',
      this.fb.group({
        test: 'test'
      })
    );

    this.getEffectorId()
      .then((effectorId) => {
        return effectorId;
      })
      .then((effectorId) => {
        return this.getEffectorYearMonth().then((effectorYearMonth) => {
          return { effectorId, effectorYearMonth };
        });
      })
      .then(({ effectorId, effectorYearMonth }) => {
        this.getMobileDeathVisitRange(effectorId, effectorYearMonth).then(
          () => {
            this.pdsaActivityParentFormComponent.activeTab = 4;
            this.mobileDeathVisitRange.entry.step =
              this.pdsaActivityParentFormComponent.updateStep(this.step);
            this.updateSubmitButton();
          }
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }

  updateSubmitButton() {
    this.mobileDeathVisitRangeForm.controls.elements.value.forEach(
      (element) => {
        if (element.count || element.crraCount) {
          return (this.pdsaActivityParentFormComponent.hasValuesEnteredFour =
            true);
        }
      }
    );
    this.pdsaActivityParentFormComponent.updateSubmitButton();
  }

  toggleComment() {
    this.hasComment = !this.hasComment;
    this.hasCommentChange.emit(this.hasComment);
  }

  openComment() {
    this.openCommentLet = true;
  }

  getEffectorId(): Promise<number> {
    return new Promise((resolve, reject) => {
      this.route.url.subscribe((segments) => {
        if (segments.length > 0) {
          const lastSegment = segments[segments.length - 1].path;
          const effectorId: number = parseInt(lastSegment, 10);
          resolve(effectorId);
        } else {
          reject("Aucun segment trouvé dans l'URL.");
        }
      });
    });
  }

  getEffectorYearMonth(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.route.url.subscribe((segments) => {
        if (segments.length > 0) {
          const lastSegment = segments[segments.length - 2].path;
          let position = lastSegment.length - 2;
          const effectorYearMonth =
            lastSegment.slice(0, position) + '-' + lastSegment.slice(position);
          resolve(effectorYearMonth);
        } else {
          reject("Aucun segment trouvé dans l'URL.");
        }
      });
    });
  }

  getMobileDeathVisitRange(
    effectorId,
    effectorYearMonth
  ): Promise<MobileDeathVisitRange> {
    return new Promise((resolve) =>
      this.mobileDeathVisitRangeService
        .getMobileDeathVisitRange(effectorId, effectorYearMonth)
        .subscribe((selectedMobileDeathVisitRange) => {
          selectedMobileDeathVisitRange.elements.map(() => {
            this.sortTimeSlotBySortCode(selectedMobileDeathVisitRange);
          })
          this.timeSlotLabels = selectedMobileDeathVisitRange.elements.map(
            (element) => element.timeSlot.label
          );
          this.timeSlotDayTypes = selectedMobileDeathVisitRange.elements.map(
            (element) => element.timeSlot.dayType
          );

          this.mobileDeathVisitRange = selectedMobileDeathVisitRange;
          this.mobileDeathVisitRangeForm.patchValue(
            selectedMobileDeathVisitRange
          );
          this.commentText =
            this.pdsaActivityParentFormComponent.mobileEffectorVolumeEntry.entry.comment;
          if (this.commentText && this.commentText.trim() !== '') {
            this.pdsaActivityParentFormComponent.hasCommentEntered = true;
          }

          const elementsArray = this.mobileDeathVisitRangeForm.controls
            .elements as FormArray;
          selectedMobileDeathVisitRange.elements.forEach((element) => {
            const elementGroup = new FormGroup({
              count: new FormControl(element.count),
              crraCount: new FormControl(element.crraCount)
            });
            elementsArray.push(elementGroup);
          });
          if (this.commentText && this.commentText.trim() !== '') {
            this.pdsaActivityParentFormComponent.hasCommentEntered = true;
          }
          this.mobileDeathVisitRange = selectedMobileDeathVisitRange;
          resolve(selectedMobileDeathVisitRange);
        })
    );
  }

  private sortTimeSlotBySortCode(selectedMobileTerritoryVisitRange): MobileDeathVisitRange{
    return selectedMobileTerritoryVisitRange.elements.sort((a,b) => a.timeSlot.sortCode - b.timeSlot.sortCode);
  }

  setMobileDeathVisitRange(
    effectorId,
    effectorYearMonth,
    mobileDeathVisitRange
  ): Promise<MobileDeathVisitRange> {
    return new Promise((resolve) => {
      const elementsArray = this.mobileDeathVisitRangeForm.get(
        'elements'
      ) as FormArray;

      elementsArray.controls.forEach((elementControl, index) => {
        const countValue = elementControl.get('count')?.value;
        this.mobileDeathVisitRange.elements[index].count = countValue;
        const crraCountValue = elementControl.get('crraCount')?.value;
        this.mobileDeathVisitRange.elements[index].crraCount = crraCountValue;
      });
      this.mobileDeathVisitRangeService
        .setMobileDeathVisitRange(
          effectorId,
          effectorYearMonth,
          mobileDeathVisitRange
        )
        .subscribe((response) => {
          if (response) {
            this.mobileDeathVisitRange = response;
          }
          resolve(response);
        });
    });
  }

  handleError(elementsArray: FormArray, attributsName: string[]) {
    let hasInvalidValue = false;
    let hasEmptyValue = false;
    let hasValue = false;

    if (elementsArray && elementsArray.controls) {
      elementsArray.controls.forEach((elementGroup: FormGroup) => {
        attributsName.forEach((attributName) => {
          const elementControl = elementGroup.get(attributName);

          if (elementControl) {
            const elementValue = elementControl.value;

            const isInvalid = isNaN(elementValue) && elementValue !== 'F';
            const isEmpty = !elementValue || elementValue.trim() === '';

            elementControl.markAsTouched();
            elementControl.updateValueAndValidity();

            if (isInvalid) {
              hasInvalidValue = true;
              return elementControl.setErrors({ invalidValue: true });
            } else if (isEmpty) {
              hasEmptyValue = true;
              return elementControl.setErrors({ emptyValue: true });
            } else {
              hasValue = true;
              return elementControl.setErrors(null);
            }
          }
        });
      });
    }

    return { hasInvalidValue, hasEmptyValue, hasValue };
  }

  onInputChange() {
    this.updateSubmitButton();
    this.valueHasChanged = true;
  }

  refreshTabFour() {
    this.getEffectorId()
      .then((effectorId) => {
        return effectorId;
      })
      .then((effectorId) => {
        return this.getEffectorYearMonth().then((effectorYearMonth) => {
          return { effectorId, effectorYearMonth };
        });
      })
      .then(({ effectorId, effectorYearMonth }) => {
        this.getMobileDeathVisitRange(effectorId, effectorYearMonth);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async onSubmit(event: EventType) {
    if (event === EventType.NAVIGATE_TO_TAB && this.valueHasChanged) {
      Swal.fire({
        title: 'Vos dernières modifications ont été enregistrées !',
        icon: 'success',
        confirmButtonColor: '#2B8CA9',
        confirmButtonText: 'OK'
      });
    }

    const elementsArray = this.mobileDeathVisitRangeForm.controls
      .elements as FormArray;

    const { hasInvalidValue, hasEmptyValue, hasValue } = this.handleError(
      elementsArray,
      ['count', 'crraCount']
    );

    if (hasInvalidValue) {
      this.pdsaActivityParentFormComponent.handleInvalidValueMessage(event);
      return;
    }

    if (hasEmptyValue && event !== EventType.NAVIGATE_TO_TAB) {
      const result = await Swal.fire({
        title:
          "Certaines données n'ont pas été saisies. Etes-vous sûr de vouloir continuer ?",
        icon: 'info',
        showDenyButton: true,
        confirmButtonColor: '#56CC9D',
        confirmButtonText: 'Oui',
        denyButtonText: 'Non',
        denyButtonColor: '#E0796A'
      });
      if (result.isDenied) {
        return;
      }
    }

    this.pdsaActivityParentFormComponent.hasValuesEnteredFour = hasValue;

    this.pdsaActivityParentFormComponent.updateStateWithoutCallPlatform(
      event,
      this.mobileDeathVisitRange
    );

    if (
      hasValue ||
      (event === EventType.SUBMIT &&
        this.pdsaActivityParentFormComponent.hasValuesEntered()) ||
      this.pdsaActivityParentFormComponent.lastSavedStep >= this.step
    ) {
      await this.getEffectorId()
        .then((effectorId) => {
          return effectorId;
        })
        .then((effectorId) => {
          return this.getEffectorYearMonth().then((effectorYearMonth) => {
            return { effectorId, effectorYearMonth };
          });
        })
        .then(({ effectorId, effectorYearMonth }) => {
          if (this.mobileDeathVisitRangeForm.get('comment')?.value) {
            this.mobileDeathVisitRange.entry.comment =
              this.mobileDeathVisitRangeForm.get('comment')?.value;
          } else {
            this.mobileDeathVisitRange.entry.comment = this.commentText;
          }
          return this.setMobileDeathVisitRange(
            effectorId,
            effectorYearMonth,
            this.mobileDeathVisitRange
          );
        })
        .then((mobileDeathVisitRange) => {
          this.pdsaActivityParentFormComponent.lastSavedStep = Math.max(
            this.pdsaActivityParentFormComponent.lastSavedStep,
            this.mobileDeathVisitRange.entry.step
          );
          return mobileDeathVisitRange;
        })
        .catch((error) => {
          console.error(error);
        });
    }

    const submitEvent = await this.pdsaActivityParentFormComponent.afterSubmit(
      event
    );

    if (submitEvent) {
      this.onSubmitEvent.emit();
    }

    return;
  }
}
