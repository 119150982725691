import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'actifOrInactif'
  })
  export class actifOrInactifPipe implements PipeTransform {
    transform(value: number): string {
      if (value) {
        return "Actif"
      }else{
          return "Inactif"
      }
    }
  }