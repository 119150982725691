import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RapportActivite } from "../model/rapport-activite.model";

@Injectable({
  providedIn: "root",
})
export class RapportActiviteService {
  // private url: string = "/assets/docs/rapport-activite.json";
  private url: string = "/static/rapport-activite-SAU/";

  constructor(private http: HttpClient) {}

  //   getRapports(): Observable<RapportActivite[]> {
  //     return this.http.get<RapportActivite[]>(this._url);
  //   }

  getRapports(): Observable<RapportActivite[]> {
    return this.http.get<RapportActivite[]>(this.url);
  }
}
