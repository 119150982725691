import { Injectable } from '@angular/core';
import * as Highcharts from 'highcharts';
import * as Highstock from 'highcharts/highstock';
declare var require: any;
require('highcharts/modules/exporting')(Highstock);
require('highcharts/modules/export-data')(Highstock);
require('highcharts/modules/annotations')(Highstock);

@Injectable({
  providedIn: 'root'
})
export class ChartService {
  public options: any = {
    chart: {
      marginLeft: 20,
      marginRight: 70,
      height: '30%'
    },

    rangeSelector: {
      enabled: false
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false
        },
        showInNavigator: true,
        connectNulls: false
      }
    },
    series: [],
    title: {
      text: ''
    },
    tooltip: {
      shared: true,
      split: false,
      xDateFormat: '%A, %d %b %Y',
      backgroundColor: '#FFFFFF'
    },
    yAxis: {
      labels: {
        align: 'left',
        x: 1,
        y: 0
      }
    },

    xAxis: {
      type: 'DateTime',
      labels: {
        format: '{value:%e/%m/%Y}',
        rotation: -20
      },

      baseUnit: 'day',
      pointInterval: 3600 * 1000 * 24
      // pointStart: Date.UTC(2022, 5, 6),
    },
    exporting: {
      sourceWidth: 2208,
      sourceHeight: 1180,
      enabled: true,
      buttons: {
        contextButton: {
          x: -12,
          menuItems: [
            'viewFullscreen',
            'printChart',
            'separator',
            'downloadPNG',
            'downloadJPEG',
            'downloadPDF',
            'downloadSVG',
            'separator',
            'downloadCSV',
            'downloadXLS'
          ]
        }
      }
    },
    function(chart) {
      var arr = chart.options.lang;
      var index = arr.indexOf('viewData');
      if (index !== -1) arr.splice(index, 1);
    },

    lang: {
      loading: 'Chargement...',
      printChart: 'Imprimer le graphique',
      downloadPNG: 'Télécharger en PNG',
      downloadJPEG: 'Télécharger en JPEG',
      downloadPDF: 'Télécharger en PDF',
      downloadSVG: 'Télécharger en SVG',
      downloadCSV: 'Télécharger en CSV',
      downloadXLS: 'Télécharger en XLS',
      contextButtonTitle: 'Menu export',
      viewFullscreen: 'Voir en plein écran',
      exitFullscreen: 'Sortir du plein écran'
    },
    legend: {
      maxHeight: 90,
      align: 'center',
      verticalAlign: 'bottom',
      layout: 'horizontal',
      x: 0,
      y: 0,
      enabled: true
    },
    credits: {
      enabled: false
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 750
          },
          chartOptions: {
            chart: {
              height: '160%'
            },
            legend: {
              enabled: false,
              maxHeight: 90
            },
            xAxis: {
              labels: {
                rotation: 0
              }
            }
          }
        }
      ]
    }
  };

  constructor() {}

  initChart(name, data, title: string) {
    console.log(title);

    if (
      title === "Nombre d'hospitalisations enfants < 1 an" ||
      title === "Nombre d'hospitalisations" ||
      title === 'Nombre de passages > 75 ans' ||
      title === 'Nombre de passages enfants < 1 an' ||
      title === 'Nombre de passages'
    ) {
      this.options.tooltip.pointFormat =
        '<span style="color:{series.color};">{series.name}</span>: <b> <span>{point.y}</span></b><br/>';
    } else {
      this.options.tooltip.pointFormat =
        '<span style="color:{series.color};">{series.name}</span>: <b> <span>{point.y} %</span></b><br/>';
    }
    this.options.series = data;
    this.options.title.text = title;
    this.setFrLanguage();
    Highstock.stockChart(name, this.options);
  }

  private setFrLanguage() {
    Highstock.setOptions({
      lang: {
        months: [
          'Janvier',
          'Février',
          'Mars',
          'Avril',
          'Mai',
          'Juin',
          'Juillet',
          'Août',
          'Septembre',
          'Octobre',
          'Novembre',
          'Décembre'
        ],
        weekdays: [
          'Dimanche',
          'Lundi',
          'Mardi',
          'Mercredi',
          'Jeudi',
          'Vendredi',
          'Samedi'
        ],
        shortMonths: [
          'jan',
          'fév',
          'mar',
          'avr',
          'mai',
          'juin',
          'juil',
          'aoû',
          'sep',
          'oct',
          'nov',
          'déc'
        ]
      }
    });
  }
}
