import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class ActivityFormValidator {
  static activityFormInputValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == 'F') return null;
      if (!isNaN(control.value)) return null;
      return { invalid: {} };
    };
  }
}
