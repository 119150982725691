import { Injectable } from '@angular/core';
import * as Highcharts from 'highcharts';
import { Observable } from 'rxjs';
import { GraphFilter, Period } from '../../models/graph-filter.model';
import { NtgPourcentage } from '../models/ntg-pourcentage.model';
import { IndicateurNtgService } from './indicateur-ntg.service';
import { EmergencyDept } from 'src/app/emergency-depts/model/emergency-dept.model';
import { EmergencyDeptService } from 'src/app/emergency-depts/services/emergency-dept.service';

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require('highcharts/modules/annotations')(Highcharts);

declare var require: any;

@Injectable({
  providedIn: 'root'
})
export class ValueOfGrapsService {
  chart: Highcharts.Chart;
  emergencyDeptToCompare: EmergencyDept;

  constructor(
    private indicateurNtgService: IndicateurNtgService,
    private emergencyDeptService: EmergencyDeptService
  ) {}

  getValueOfNtgGraph(
    graphId: string,
    graphFilter: GraphFilter,
    period: Period
  ): boolean {
    if (graphId == 'period1') {
      this.chart = Highcharts.chart(graphId, this.setNewOption());
      this.chart.showLoading();
    }

    this.emergencyDeptService
      .getEmergencyDeptForGraph(
        graphFilter.emergencyDepts.map((emergencyDept) => emergencyDept.id)
      )
      .subscribe(
        (data) => {
          this.emergencyDeptToCompare = data;
        },
        (error) => console.log(error)
      );

    const requestData = this.indicateurNtgService.getNtgPercentages(
      period.startDate,
      period.endDate,
      graphFilter.departementCodes,
      graphFilter.emergencyDepts.map(
        (emergencyDept) => emergencyDept.code + '-' + emergencyDept.type
      )
    );

    this.setDataInGraph(
      requestData,
      graphId,
      period.startDate,
      period.endDate,
      graphFilter.compareEmergencyDeptId,
      graphFilter.selectedAbrSerUrgName
    );
    return false;
  }

  private setDataInGraph(
    typeOfGet: Observable<NtgPourcentage[]>,
    graphId: string,
    dateFrom: Date,
    dateTo: Date,
    compareEmergencyDeptId?: number,
    selectedAbrSerUrgName?: string
  ) {
    typeOfGet.subscribe(
      (data) => {
        const period1NtgPourcentage = this.initializeNtgPourcentage();
        const sizeOfPeriod1 = data.length;
        const dateArray: string[] = [];
        data.forEach((data2) => {
          //GET DATA FOR EACH DAYS
          const date = new Date(data2.date);

          dateArray.push(this.dateToString(date));

          period1NtgPourcentage[0].data.push(
            /// 0
            Math.round(data2.ntg0 * 100) / 100
          );

          period1NtgPourcentage[1].data.push(
            // 1
            Math.round(data2.ntg1 * 100) / 100
          );

          period1NtgPourcentage[2].data.push(
            // 2
            Math.round(data2.ntg2 * 100) / 100
          );

          period1NtgPourcentage[3].data.push(
            // 3
            Math.round(data2.ntg3 * 100) / 100
          );

          period1NtgPourcentage[4].data.push(
            // 4
            Math.round(data2.ntg4 * 100) / 100
          );

          period1NtgPourcentage[5].data.push(
            // 5
            Math.round(data2.ntg5 * 100) / 100
          );

          period1NtgPourcentage[6].data.push(
            // 6
            Math.round(data2.ntg6 * 100) / 100
          );

          period1NtgPourcentage[7].data.push(
            /// inconnu
            Math.round(data2.ntg7 * 100) / 100
          );
        });

        let axisCompare: any = {
          title: {
            text: 'NTG'
          },
          visible: false,
          min: 0,
          max: 7,
          opposite: true
        };

        if (compareEmergencyDeptId !== undefined) {
          this.indicateurNtgService
            .getNtgDates(dateFrom, dateTo, compareEmergencyDeptId)
            .subscribe((data2) => {
              period1NtgPourcentage.push({
                name: '',

                type: 'line',

                yAxis: 1,

                xAxis: 1,

                data: [],
                color: 'black',
                marker: {
                  lineColor: null,
                  states: {
                    hover: {
                      fillColor: 'white',
                      radius: 3
                    }
                  }
                }
              });
              data2.forEach((data3) => {
                period1NtgPourcentage[8].data.push(data3.ntg);
              });

              let ntgBySau: any[] = [];

              period1NtgPourcentage[8].data.forEach((element) => {
                if (element == 7) {
                  ntgBySau.push(' ');
                } else {
                  ntgBySau.push(element);
                }
              });
              period1NtgPourcentage[8].data = ntgBySau;

              axisCompare = {
                title: {
                  text: 'Niveau de Tension du SU'
                },
                allowDecimals: false,
                visible: true,
                min: 0,
                max: 6,
                tickAmount: 7,
                opposite: true
              };
              //  period1NtgPourcentage[8].data.push(data3.ntg);
              period1NtgPourcentage[8].name = selectedAbrSerUrgName;
              this.setOptionGraph(
                dateArray,
                sizeOfPeriod1,
                ntgBySau.length,
                period1NtgPourcentage,
                graphId,
                axisCompare
              );
            });
        } else {
          this.setOptionGraph(
            dateArray,
            sizeOfPeriod1,
            0,
            period1NtgPourcentage,
            graphId,
            axisCompare
          );
        }
      },
      (error) => console.log(error),
      () => {
        if (graphId == 'period1') {
          this.chart.hideLoading();
        }
      }
    );
  }

  private setOptionGraph(
    dateArray,
    sizeOfPeriod1,
    siseOfResultNtgBySau,
    period1NtgPourcentage,
    graphId,
    axisCompare
  ) {
    let graphOptions = {};
    const graphContainerWidth: number =
      document.getElementsByClassName('graph-container')[0].clientWidth;

    setTimeout(() => {
      const period2 = document.getElementById('period2');
      if (period2) {
        const svgChild = period2?.children[0].children[0] as SVGSVGElement;
        svgChild.width.baseVal.value = graphContainerWidth - 15;
      }
    }, 1000);

    graphOptions = {
      chart: {
        type: 'column',
        height: 500,
        backgroundColor: '#f8f9f9',
        width: graphContainerWidth - 15
      },
      title: {
        text: '<span style="font-weight: normal;">Répartition du Niveau de Tension Global (NTG) dans le temps</span>'
      },
      credits: {
        enabled: false
      },

      lang: {
        loading: 'Chargement...',
        noData: 'Aucune donnée disponible',
        printChart: 'Imprimer le graphique',
        downloadPNG: 'Télécharger en PNG',
        downloadJPEG: 'Télécharger en JPEG',
        downloadPDF: 'Télécharger en PDF',
        downloadSVG: 'Télécharger en SVG',
        downloadCSV: 'Télécharger en CSV',
        downloadXLS: 'Télécharger en XLS',
        contextButtonTitle: 'Menu export',
        viewFullscreen: 'Voir en plein écran',
        exitFullscreen: 'Sortir du plein écran'
      },
      legend: {
        title: {
          text: 'Niveaux de tension: ',
          style: {
            fontStyle: 'bold'
          }
        }
      },

      yAxis: [
        {
          reversedStacks: false,
          min: 0,
          max: 100,
          title: {
            text: 'Répartition en % des SU par Niveau de Tension'
          }
        },
        axisCompare
      ],
      xAxis: [
        {
          categories: dateArray,
          labels: {
            enabled: true
          },
          type: 'DateTime'
        },
        {
          visible: false,
          title: { text: 'Date' },
          type: 'DateTime',
          labels: {
            enabled: false
          },
          stackLabels: {
            enabled: false
          },
          min: 0,
          max: siseOfResultNtgBySau
        }
      ],
      plotOptions: {
        column: {
          stacking: 'stream',
          pointPadding: 0,
          groupPadding: 0
        }
      },
      series: period1NtgPourcentage,

      tooltip: {
        formatter: function () {
          return (
            'Répartition du Niveau de Tension Global le ' +
            this.x +
            ' :<br>' +
            this.points.reduce(function (s, point, title) {
              if (
                point.series.name === 'inconnu' ||
                point.series.name === '0' ||
                point.series.name === '1' ||
                point.series.name === '2' ||
                point.series.name === '3' ||
                point.series.name === '4' ||
                point.series.name === '5' ||
                point.series.name === '6'
              ) {
                return (
                  s +
                  '<br/><span style="color:' +
                  point.series.color +
                  ';">' +
                  point.series.name +
                  '</span> : ' +
                  point.y +
                  '%'
                );
              } else {
                return s + '<br/>' + point.series.name + ': ' + point.y;
              }
            }, '<b>' + '')
          );
        },
        backgroundColor: '#FFFFFF',
        shared: true,

        pointFormat:
          '<span style="color:{series.color};">{series.name}</span> - {point.y} %<br>'
      },
      exporting: {
        sourceWidth: 2208,
        sourceHeight: 1180,
        buttons: {
          contextButton: {
            menuItems: [
              'viewFullscreen',
              'printChart',
              'separator',
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadSVG',
              'separator',
              'downloadCSV',
              'downloadXLS'
            ]
          }
        }
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 750
            },
            chartOptions: {
              legend: {
                enabled: false,
                maxHeight: 90,
                x: 0
              }
            }
          }
        ]
      }
    };

    Highcharts.chart(graphId, graphOptions).legend.title.translate(-120, 26);
  }

  private initializeNtgPourcentage() {
    const model: any[] = [];

    model.push({
      name: '0',
      data: [],
      color: '#2bda0f'
    });
    model.push({
      name: '1',
      data: [],
      color: '#acfb14'
    });
    model.push({
      name: '2',
      data: [],
      color: '#fff000'
    });
    model.push({
      name: '3',
      data: [],
      color: '#ffa800'
    });
    model.push({
      name: '4',
      data: [],
      color: '#ff1e00'
    });
    model.push({
      name: '5',
      data: [],
      color: '#c50000'
    });
    model.push({
      name: '6',
      data: [],
      color: '#900000'
    });
    model.push({
      name: 'inconnu',
      data: [],
      color: '#D8D8D8'
    });

    return model;
  }

  // private colors = [
  //   "#2bda0f",
  //   "#acfb14",
  //   "#fff000",
  //   "#ffa800",
  //   "#ff1e00",
  //   "#c50000",
  //   "#900000",
  //   "#D8D8D8",
  // ];

  private dateToString(date: Date): string {
    const month = `${date.getMonth() + 1}`;
    const day = `${date.getDate()}`;
    return (
      ('00' + day).substring(day.length) +
      '/' +
      ('00' + month).substring(month.length) +
      '/' +
      date.getFullYear()
    );
  }

  private setNewOption(): {} {
    return {
      chart: {
        type: 'column',
        height: 500,
        backgroundColor: '#f8f9f9'
      },
      // colors: this.colors,
      title: {
        text: '<span style="font-weight: normal;">Répartition du Niveau de Tension Global (NTG) dans le temps</span>'
      },
      credits: {
        enabled: false
      },
      lang: {
        loading:
          '<div class="spinner-border" role="status"><span class="sr-only">Loading...</span> </div>',
        noData: '',
        printChart: 'Imprimer le graphique',
        downloadPNG: 'Télécharger en PNG',
        downloadJPEG: 'Télécharger en JPEG',
        downloadPDF: 'Télécharger en PDF',
        downloadSVG: 'Télécharger en SVG',
        downloadCSV: 'Télécharger en CSV',
        downloadXLS: 'Télécharger en XLS',
        contextButtonTitle: 'Menu export',
        viewFullscreen: 'Voir en plein écran',
        exitFullscreen: 'Sortir du plein écran'
      },
      legend: {
        title: {
          text: 'Niveaux de tension: ',
          style: {
            fontStyle: 'bold'
          }
        }
      },

      yAxis: [
        {
          reversedStacks: false,
          min: 0,
          max: 100,
          title: {
            text: 'Répartition en % des SU par Niveau de Tension'
          }
        }
      ],
      xAxis: [
        {
          labels: {
            enabled: true
          },
          type: 'DateTime',
          min: 0
        },
        {
          visible: false,
          title: { text: 'Date' },
          type: 'DateTime',
          labels: {
            enabled: false
          },
          stackLabels: {
            enabled: false
          },
          min: 0
        }
      ],
      plotOptions: {
        column: {
          stacking: 'stream',
          pointWidth: 2
        }
      },

      tooltip: {
        title: 'Répartition par niveau de tension',
        shared: true,
        headerFormat: 'Répartition par niveau de tension <br>{point.key}<br>',

        pointFormat:
          '<span style="font-size: 1.6em;color:{series.color};text-shadow: black 0.1em 0.1em 0.1em ">{series.name} </span>  {point.y} %<br>'
      },
      exporting: {
        enabled: true
      }
    };
  }
}
