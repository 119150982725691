import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Alert } from '../emergency-depts/model/alert.model';
import { AlertService } from '../emergency-depts/services/alert.service';
import { ActivatedRoute } from '@angular/router';
import { AccountService } from '../core/auth/account.service';
import { Account } from '../core/auth/account.model';

@Component({
  selector: 'app-iframe-meteo',
  templateUrl: './iframe-meteo.component.html',
  styleUrls: ['./iframe-meteo.component.scss']
})
export class IframeMeteoComponent implements OnInit {
  public alerts: Alert[] = [];
  public accessDenied = false;
  public account: Account | null;

  constructor(
    public sanitizer: DomSanitizer,
    private alertService: AlertService,
    private readonly titleService: Title,
    private readonly route: ActivatedRoute,
    private accountService: AccountService
  ) {}

  public ngOnInit(): void {
    this.titleService.setTitle(this.route.snapshot.data['title']);
    this.getAlerts();
    this.getAccount();
    this.showAccessDeniedDiv();
  }

  private getAccount(): void {
    this.accountService.identity().subscribe((account) => {
      this.account = account;
    });
  }

  private getAlerts(): void {
    const Reg = this.isAraPage() ? 84 : 11;
    this.alertService
      .getAlertsByPageAlert(Reg, 'URGENCES_Météo-des-Urgences')
      .subscribe((res: Alert[]) => {
        this.alerts = res;
        res.map((alert) => this.alertService.get(alert));
      });
  }

  isAlert(): boolean {
    return this.alerts.length > 0;
  }

  public isAraPage(): boolean {
    const str = window.location.href;
    return str.includes('ara') || str.includes(':4201/');
  }

  private showAccessDeniedDiv(): void {
    setTimeout(() => {
      this.accessDenied = true;
    }, 2000);
  }

  public isPreprod(): boolean {
    const str = window.location.href;
    return str.includes('ppr');
  }
}
