import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export type HeaderDefinition= {
  fieldName: string,
  title: string,
  sortAsc: boolean | undefined
}

@Component({
  selector: "app-header-table",
  templateUrl: "./header-table.component.html",
  styleUrls: ["./header-table.component.scss"],
})
export class HeaderTableComponent implements OnInit {
  @Input() sectionTitle: string;
  @Input() filterUp: string;
  @Input() filterDown: string;
  @Output() sortDesc = new EventEmitter();
  @Output() sortAsc = new EventEmitter();
  @Input() isFinessDesc: boolean;
  @Input() titleHeader: string;

  constructor() {}

  ngOnInit() {}

  actionClickDesc() {
    console.log(this.titleHeader);
    if (this.titleHeader == "DEPT") {
      const parameterHeader = {
        title: "finess,desc",
        desc: true,
        asc: false,
      };

      this.sortDesc.emit(parameterHeader);
    }

    if (this.titleHeader == "FINESS") {
      const parameterHeader = {
        titleHeader: this.titleHeader,
        title: "finess,desc",
        desc: true,
        asc: false,
      };

      this.sortDesc.emit(parameterHeader);
    }

    if (this.titleHeader == "SERVICE D'URGENCE") {
      const parameterHeader = {
        title: "libSerUrg,desc",
        desc: true,
        asc: false,
      };
      this.sortDesc.emit(parameterHeader);
    }
    if (this.titleHeader == "TYPE DE SAU") {
      const parameterHeader = {
        title: "idCodSerUrg,desc",
        desc: true,
        asc: false,
      };
      this.sortDesc.emit(parameterHeader);
    }
    if (this.titleHeader == "PEDIATRIQUE SUR ADULTE") {
      const parameterHeader = {
        title: "pedAdu,desc",
        desc: true,
        asc: false,
      };
      this.sortDesc.emit(parameterHeader);
    }
    if (this.titleHeader == "STATUT") {
      const parameterHeader = {
        title: "enabled,desc",
        desc: true,
        asc: false,
      };
      this.sortDesc.emit(parameterHeader);
    }
  }

  actionClickAsc() {
    if (this.titleHeader == "DEPT") {
      const parameterHeader = {
        title: "finess,asc",
        desc: false,
        asc: true,
      };

      this.sortAsc.emit(parameterHeader);
    }
    if (this.titleHeader == "FINESS") {
      const parameterHeader = {
        titleHeader: this.titleHeader,
        title: "finess,asc",
        desc: false,
        asc: true,
      };

      this.sortAsc.emit(parameterHeader);
    }
    if (this.titleHeader == "SERVICE D'URGENCE") {
      const parameterHeader = {
        title: "libSerUrg,asc",
        desc: false,
        asc: true,
      };
      this.sortDesc.emit(parameterHeader);
    }
    if (this.titleHeader == "TYPE DE SAU") {
      const parameterHeader = {
        title: "idCodSerUrg,asc",
        desc: false,
        asc: true,
      };
      this.sortDesc.emit(parameterHeader);
    }

    if (this.titleHeader == "PEDIATRIQUE SUR ADULTE") {
      const parameterHeader = {
        title: "pedAdu,asc",
        desc: false,
        asc: true,
      };
      this.sortDesc.emit(parameterHeader);
    }
    if (this.titleHeader == "STATUT") {
      const parameterHeader = {
        title: "enabled,asc",
        desc: false,
        asc: true,
      };
      this.sortDesc.emit(parameterHeader);
    }
  }
}
