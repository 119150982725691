import { EffectorsTerritory } from '../../pdsa/model/effectors-territory.model';

export class NgSelectModel {
  id: string;
  name: string;

  constructor(id: string, name: string) {
    this.id = id;
    this.name = name;
  }

  static fromEffectorsTerritorys(
    effectorsTerritorys: EffectorsTerritory[]
  ): NgSelectModel[] {
    const uniqueHealthTerritoryCodes = Array.from(
      new Set(
        effectorsTerritorys
          .map((e) => e.healthTerritoryCode)
          .sort((a, b) => a.localeCompare(b))
      )
    );
    return uniqueHealthTerritoryCodes.map(
      (code) => new NgSelectModel(code, code)
    );
  }
}
