export interface Availabilities {
  id: number;
  timeSlotTime: number;
  dayType: string;
  timeSlotCode: string;
  slotTimeLabel: string;
  beginHours: number;
  endHours: number;
  validityBoolean: boolean;
  createdDate: Date;
  lastModifiedDate: Date;
  originData: string;
  sortCode: number;
  codDom: string;
}

export class AvailabilitiesClass implements Availabilities {
  id: number;
  timeSlotTime: number;
  dayType: string;
  timeSlotCode: string;
  slotTimeLabel: string;
  beginHours: number;
  endHours: number;
  validityBoolean: boolean;
  createdDate: Date;
  lastModifiedDate: Date;
  originData: string;
  sortCode: number;
  codDom: string;

  constructor(availabilities: Availabilities) {
    this.id = availabilities.id;
    this.beginHours = availabilities.beginHours;
    this.codDom = availabilities.codDom;
    this.createdDate = availabilities.createdDate;
    this.dayType = availabilities.dayType;
    this.endHours = availabilities.endHours;
    this.lastModifiedDate = availabilities.lastModifiedDate;
    this.originData = availabilities.originData;
    this.slotTimeLabel = availabilities.slotTimeLabel;
    this.sortCode = availabilities.sortCode;
    this.timeSlotCode = availabilities.timeSlotCode;
    this.timeSlotTime = availabilities.timeSlotTime;
    this.validityBoolean = availabilities.validityBoolean;
  }
}
