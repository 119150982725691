import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MobileTerritoryVisitRange } from 'src/app/pdsa/model/activity/mobile-territory-visit-range.model';
import { MobileTerritoryVisitRangeService } from 'src/app/pdsa/service/activity/mobile-territory-visit-range.service';

import { PdsaPosteActivityService } from '../../pdsa-poste-activity-form/pdsa-poste-activity-service';
import { EventType } from 'src/app/pdsa/model/activity/effector-event-type.enum';
import { PdsaActivityParentFormComponent } from '../pdsa-activity-parent-form.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-pdsa-activity-tab-two',
  templateUrl: './pdsa-activity-tab-two.component.html',
  styleUrls: ['./pdsa-activity-tab-two.component.scss'],
  providers: [PdsaPosteActivityService]
})
export class PdsaActivityTabTwoComponent implements OnInit {
  @Input() form!: FormGroup;
  @Output() formChange = new EventEmitter<FormGroup>();
  @Output() onSubmitEvent: EventEmitter<any> = new EventEmitter();

  // ITEMS PER PAGE
  page = 1;
  count = 0;
  tableSize = 10;

  effectorId: number;
  effectorYearMonth: string;
  timeSlotLabels: string[] = [];
  timeSlotDayTypes: string[] = [];
  territoryNames: string[] = [];
  currentPage: number;
  eventTypes = EventType;
  valueHasChanged: boolean = false;
  step: number = 2;

  mobileTerritoryVisitRangeFrom = this.fb.group({
    entry: this.fb.group({
      yearMonth: [''],
      effector: this.fb.group({
        id: []
      })
    }),
    elements: this.fb.array([])
  });

  mobileTerritoryVisitRange: MobileTerritoryVisitRange;

  constructor(
    private readonly route: ActivatedRoute,
    private mobileTerritoryVisitRangeService: MobileTerritoryVisitRangeService,
    private fb: FormBuilder,
    private pdsaPosteActivityService: PdsaPosteActivityService,
    private pdsaActivityParentFormComponent: PdsaActivityParentFormComponent
  ) {}

  ngOnInit() {
    this.pdsaPosteActivityService.currentPage$.subscribe((page) => {
      this.currentPage = page;
    });
    this.form.addControl(
      'tab-two-form',
      this.fb.group({
        test: 'test'
      })
    );

    this.getEffectorId()
      .then((effectorId) => {
        return effectorId;
      })
      .then((effectorId) => {
        return this.getEffectorYearMonth().then((effectorYearMonth) => {
          return { effectorId, effectorYearMonth };
        });
      })
      .then(({ effectorId, effectorYearMonth }) => {
        return this.getMobileTerritoryVisitRange(effectorId, effectorYearMonth);
      })
      .then(() => {
        this.pdsaActivityParentFormComponent.activeTab = 2;
        this.mobileTerritoryVisitRange.entry.step =
          this.pdsaActivityParentFormComponent.updateStep(this.step);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getEffectorId(): Promise<number> {
    return new Promise((resolve, reject) => {
      this.route.url.subscribe((segments) => {
        if (segments.length > 0) {
          const lastSegment = segments[segments.length - 1].path;
          const effectorId: number = parseInt(lastSegment, 10);
          resolve(effectorId);
        } else {
          reject("Aucun segment trouvé dans l'URL.");
        }
      });
    });
  }

  getEffectorYearMonth(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.route.url.subscribe((segments) => {
        if (segments.length > 0) {
          const lastSegment = segments[segments.length - 2].path;
          let position = lastSegment.length - 2;
          const effectorYearMonth =
            lastSegment.slice(0, position) + '-' + lastSegment.slice(position);
          resolve(effectorYearMonth);
        } else {
          reject("Aucun segment trouvé dans l'URL.");
        }
      });
    });
  }

  getMobileTerritoryVisitRange(
    id: number = this.effectorId,
    yearMonth: string = this.effectorYearMonth
  ): Promise<MobileTerritoryVisitRange> {
    return new Promise((resolve) =>
      this.mobileTerritoryVisitRangeService
        .getMobileTerritoryVisitRange(id, yearMonth)
        .subscribe((selectedMobileTerritoryVisitRange) => {
          selectedMobileTerritoryVisitRange.elements.map(() => {
            this.sortTimeSlotBySortCode(selectedMobileTerritoryVisitRange);
          })
          this.timeSlotLabels = selectedMobileTerritoryVisitRange.elements.map(
            (element) => element.timeSlot.label
          );
          this.timeSlotDayTypes =
            selectedMobileTerritoryVisitRange.elements.map(
              (element) => element.timeSlot.dayType
            );
          const uniqueDecisionTypeLabelsSet = new Set<string>();

          this.territoryNames =
            selectedMobileTerritoryVisitRange.elements.reduce(
              (acc, element) => {
                element.elements.forEach((innerElement) => {
                  uniqueDecisionTypeLabelsSet.add(
                    innerElement.territory.code +
                      ' - ' +
                      innerElement.territory.name
                  );
                });

                return acc;
              },
              [] as string[]
            );
          this.territoryNames = Array.from(uniqueDecisionTypeLabelsSet);
          this.territoryNames.sort((a, b) => {
            const getOrder = (str: string): number => {
              if (str.includes('EXT')) {
                return 1;
              } else {
                return 0;
              }
            };

            const orderA = getOrder(a);
            const orderB = getOrder(b);

            if (orderA !== orderB) {
              return orderA - orderB;
            } else {
              const codeA = parseInt(a.split(' - ')[0]);
              const codeB = parseInt(b.split(' - ')[0]);
              return codeA - codeB;
            }
          });

          this.mobileTerritoryVisitRange = selectedMobileTerritoryVisitRange;
          this.mobileTerritoryVisitRangeFrom.patchValue(
            selectedMobileTerritoryVisitRange
          );

          const elementsArray = this.mobileTerritoryVisitRangeFrom.get(
            'elements'
          ) as FormArray;

          selectedMobileTerritoryVisitRange.elements.forEach((element) => {
            const innerElementsArray = this.fb.array([]);
            element.elements.forEach((innerElement) => {
              const innerElementGroup = this.fb.group({
                count: [innerElement.count]
              });
              innerElementsArray.push(innerElementGroup);
            });
            const elementGroup = this.fb.group({
              elements: innerElementsArray
            });
            (
              this.mobileTerritoryVisitRangeFrom.get('elements') as FormArray
            ).push(elementGroup);
          });
          this.mobileTerritoryVisitRange = selectedMobileTerritoryVisitRange;
          resolve(selectedMobileTerritoryVisitRange);
        })
    );
  }

  private sortTimeSlotBySortCode(selectedMobileTerritoryVisitRange): MobileTerritoryVisitRange{
    return selectedMobileTerritoryVisitRange.elements.sort((a,b) => a.timeSlot.sortCode - b.timeSlot.sortCode);
  }

  getDataFromTabTwo(mobileTerritoryVisitRange) {
    const elementsArray = this.mobileTerritoryVisitRangeFrom.get(
      'elements'
    ) as FormArray;

    for (let i = 0; i < elementsArray.controls.length; i++) {
      const elementGroup = elementsArray.controls[i] as FormGroup;
      const elementsControl = elementGroup.get('elements');
      if (elementsControl instanceof FormArray && elementsControl.value) {
        for (let j = 0; j < elementsControl.value.length; j++) {
          const countValue = elementGroup.get(`elements.${j}.count`)?.value;

          if (
            mobileTerritoryVisitRange.elements &&
            mobileTerritoryVisitRange.elements[i] &&
            mobileTerritoryVisitRange.elements[i].elements &&
            mobileTerritoryVisitRange.elements[i].elements[j]
          ) {
            mobileTerritoryVisitRange.elements[i].elements[j].count =
              countValue;
          }
        }
      }
    }
  }

  setMobileTerritoryVisitRange(
    effectorId,
    effectorYearMonth,
    mobileTerritoryVisitRange
  ) {
    this.getDataFromTabTwo(mobileTerritoryVisitRange);

    this.mobileTerritoryVisitRangeService
      .setMobileTerritoryVisitRange(
        effectorId,
        effectorYearMonth,
        mobileTerritoryVisitRange
      )
      .subscribe((response) => {});
  }

  handleError(elementsArray: FormArray, attributsName: string[]) {
    let hasInvalidValue = false;
    let hasEmptyValue = false;
    let hasValue = false;

    for (const elements of elementsArray.controls) {
      const innerElementsArray = elements.get('elements') as FormArray;

      for (const innerElement of innerElementsArray.controls) {
        for (const attributName of attributsName) {
          const elementValue = innerElement.get(attributName)?.value;

          if (elementValue !== undefined) {
            const isInvalid = isNaN(elementValue) && elementValue !== 'F';
            const isEmpty = !elementValue || elementValue.trim() === '';

            if (isInvalid) {
              hasInvalidValue = true;
              innerElement.get(attributName)?.setErrors({ invalidValue: true });
            } else if (isEmpty) {
              hasEmptyValue = true;
              innerElement.get(attributName)?.setErrors({ emptyValue: true });
            } else {
              hasValue = true;
              innerElement.get(attributName)?.setErrors(null);
            }
          }
        }
      }
    }

    return { hasInvalidValue, hasEmptyValue, hasValue };
  }

  onInputChange() {
    this.valueHasChanged = true;
  }

  async onSubmit(event: EventType) {
    if (event === EventType.NAVIGATE_TO_TAB && this.valueHasChanged) {
      Swal.fire({
        title: 'Vos dernières modifications ont été enregistrées !',
        icon: 'success',
        confirmButtonColor: '#2B8CA9',
        confirmButtonText: 'OK'
      });
    }

    const elementsArray = this.mobileTerritoryVisitRangeFrom.get(
      'elements'
    ) as FormArray;

    const { hasInvalidValue, hasEmptyValue, hasValue } = this.handleError(
      elementsArray,
      ['count']
    );

    if (hasInvalidValue) {
      this.pdsaActivityParentFormComponent.handleInvalidValueMessage(event);
      return;
    }

    if (hasEmptyValue && event !== EventType.NAVIGATE_TO_TAB) {
      const result = await Swal.fire({
        title:
          "Certaines données n'ont pas été saisies. Etes-vous sûr de vouloir continuer ?",
        icon: 'info',
        showDenyButton: true,
        confirmButtonColor: '#56CC9D',
        confirmButtonText: 'Oui',
        denyButtonText: 'Non',
        denyButtonColor: '#E0796A'
      });
      if (result.isDenied) {
        return;
      }
    }

    this.pdsaActivityParentFormComponent.hasValuesEnteredTwo = hasValue;

    this.pdsaActivityParentFormComponent.updateState(
      event,
      this.mobileTerritoryVisitRange
    );
    if (
      hasValue ||
      this.pdsaActivityParentFormComponent.lastSavedStep >= this.step
    ) {
      await this.getEffectorId()
        .then((effectorId) => {
          return effectorId;
        })
        .then((effectorId) => {
          return this.getEffectorYearMonth().then((effectorYearMonth) => {
            return { effectorId, effectorYearMonth };
          });
        })
        .then(({ effectorId, effectorYearMonth }) => {
          this.setMobileTerritoryVisitRange(
            effectorId,
            effectorYearMonth,
            this.mobileTerritoryVisitRange
          );
          this.pdsaActivityParentFormComponent.lastSavedStep = Math.max(
            this.mobileTerritoryVisitRange.entry.step,
            this.pdsaActivityParentFormComponent.lastSavedStep
          );
        })
        .catch((error) => {
          console.error(error);
        });
    }

    const submitEvent = await this.pdsaActivityParentFormComponent.afterSubmit(
      event
    );

    if (submitEvent) {
      this.onSubmitEvent.emit();
    }
    return;
  }
}
