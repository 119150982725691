import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "departmentName",
})
export class DepartmentName implements PipeTransform {
  transform(departmentNumber: string) {
    switch (departmentNumber) {
       // ARA
      case "01":
        return "Ain";
      case "03":
        return "Allier";
      case "07":
        return "Ardèche";
      case "15":
        return "Cantal";
      case "26":
        return "Drôme";
      case "38":
        return "Isère";
      case "42":
        return "Loire";
      case "43":
        return "Haute-Loire";
      case "63":
        return "Puy-de-dôme";
      case "69":
        return "Rhône";
      case "73":
        return "Savoie";
      case "74":
        return "Haute-savoie";
      case "82":
        return "Tarn-et-Garonne";

      // IDF
      case '75':
        return 'Paris';
      case '77':
        return 'Seine-et-Marne';
      case '78':
        return 'Yvelines';
      case '91':
        return 'Essonne';
      case '92':
        return 'Hauts-de-Seine';
      case '93':
        return 'Seine-Saint-Denis';
      case '94':
        return 'Val-de-Marne';
      case '95':
        return "Val-d'Oise";
    }
  }
}
