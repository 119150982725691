<div class="body">
  <div
    class="changestyle"
    [ngClass]="isAraPage() == true ? 'ara-style' : 'idf-style'"
  >
    <div class="container">
      <div class="row">
        <div class="col-1">
          <div class="error card" style="width: 450px">
            <img
              src="/assets/images/lock-alert.svg"
              alt="error"
              style="width: 83px"
            />
            <h1>
              Vous n'avez pas les droits nécessaires pour accéder à cette page.
            </h1>
            <p *ngIf="isAraPage() == true">
              Vous pouvez en demander l'accès en nous contactant à l'adresse
              suivante :
            </p>
            <p *ngIf="isAraPage() == false">
              Demandez vos droits d'accès à l'équipe eCerveau :
            </p>
            <div class="row button-idf" *ngIf="isAraPage() == false">
              <div class="col-12">
                <button type="button" class="btn btn-lg btn-idf" (click)="openFormContact()">Demande de droits</button>
              </div>
            </div>
            <!-- <div class="lien row" *ngIf="isAraPage() == false">
              <img src="/assets/images/alt.svg" alt="@" />
              <a href="ecerveau.portail@sante-idf.fr"
                >ecerveau.portail@sante-idf.fr</a
              >
            </div> -->
            <div class="lien row" *ngIf="isAraPage() == true">
              <img src="/assets/images/altARA.png" alt="@" />
              <a href="cerveau@sante-ara.fr">cerveau@sante-ara.fr</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
