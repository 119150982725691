import { CommonModule, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { NgxPaginationModule } from 'ngx-pagination';

import { MATOMO_CONFIGURATION } from './analytics/matomo/matomo-configuration';
import { AppComponent } from './app.component';
import { AuthExpiredInterceptor } from './core/interceptor/auth-expired.interceptor';
import { SortDirective } from './directive/sort.directive';
//import for favicons:
import {
  BROWSER_FAVICONS_CONFIG,
  BrowserFavicons,
  Favicons
} from './emergency-depts/services/favicons.service';
import { RapportActiviteService } from './emergency-depts/services/rapport-activite.service';
import { ErrorInexistentComponent } from './error-inexistent/error-inexistent.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { ChargeGraphComponent } from './graph/charge/charge-graph/charge-graph.component';
import { ChargeGraphHomeComponent } from './graph/charge/charge-graph-home/charge-graph-home.component';
import { EmergencyVisitsGraphsComponent } from './graph/emergency-visits-graphs/emergency-visits-graphs.component';
import { HospitalisationsChildGraphComponent } from './graph/emergency-visits-graphs/hospitalisations-child-graph/hospitalisations-child-graph.component';
import { HospitalisationsGraphComponent } from './graph/emergency-visits-graphs/hospitalisations-graph/hospitalisations-graph.component';
import { HospitalisationsRateChildGraphComponent } from './graph/emergency-visits-graphs/hospitalisations-rate-child-graph/hospitalisations-rate-child-graph.component';
import { HospitalisationsRateGraphComponent } from './graph/emergency-visits-graphs/hospitalisations-rate-graph/hospitalisations-rate-graph.component';
import { VisitsAboveSeventyFiveYearsOldGraphComponent } from './graph/emergency-visits-graphs/visits-above-seventy-five-years-old-graph/visits-above-seventy-five-years-old-graph.component';
import { VisitsChildGraphComponent } from './graph/emergency-visits-graphs/visits-child-graph/visits-child-graph.component';
import { VisitsGraphComponent } from './graph/emergency-visits-graphs/visits-graph/visits-graph.component';
import { GraphFilterComponent } from './graph/filter/graph-filter/graph-filter.component';
import { ModalFilterComponent } from './graph/filter/modal-filter/modal-filter.component';
import { NtgGraphComponent } from './graph/ntg/ntg-graph/ntg-graph.component';
import { NtgGraphHomeComponent } from './graph/ntg/ntg-graph-home/ntg-graph-home.component';
import { GraphAccueilComponent } from './graph-accueil/graph-accueil.component';
import { FifthGraphComponent } from './graph-test/fifth-graph/fifth-graph.component';
import { FirstGraphComponent } from './graph-test/first-graph/first-graph.component';
import { FourthGraphComponent } from './graph-test/fourth-graph/fourth-graph.component';
import { GraphTestComponent } from './graph-test/graph-test.component';
import { SecondGraphComponent } from './graph-test/second-graph/second-graph.component';
import { SeventhGraphComponent } from './graph-test/seventh-graph/seventh-graph.component';
import { SixthGraphComponent } from './graph-test/sixth-graph/sixth-graph.component';
import { ThirdGraphComponent } from './graph-test/third-graph/third-graph.component';
import { HetComponent } from './het/het.component';
import { HomeFormContactComponent } from './home-page-idf/home-form-contact/home-form-contact.component';
import {
  FormatNumberPipe,
  HomePageIdfComponent
} from './home-page-idf/home-page-idf.component';
import { IframeEmergencyTraficComponent } from './iframe-emergency-trafic/iframe-emergency-trafic.component';
import { IframeMesUrgencesComponent } from './iframe-mes-urgences/iframe-mes-urgences.component';
import { IframeMesUrgencesProComponent } from './iframe-mes-urgences-pro/iframe-mes-urgences-pro.component';
import { IframeMeteoComponent } from './iframe-meteo/iframe-meteo.component';
import { IframePowerBiComponent } from './iframe-power-bi/iframe-power-bi.component';
import { IframeSamuComponent } from './iframe-samu/iframe-samu.component';
import { IframeSnpQualiteComponent } from './iframe-snp-qualite/iframe-snp-qualite.component';
import { IframeTestComponent } from './iframe-test/iframe-test.component';
import { MentionsLegalesComponent } from './mentions-legales/mentions-legales.component';
import { ModalErrorComponent } from './modal-error/modal-error.component';
import { ModalRegisterComponent } from './modal-register/modal-register.component';
import { EffectorsFormComponent } from './pdsa/components/effectors-form/effectors-form.component';
import { PdsaActivityInputComponent } from './pdsa/components/pdsa-mobile-activity-form/pdsa-activity-input/pdsa-activity-input.component';
import { PdsaActivityParentFormComponent } from './pdsa/components/pdsa-mobile-activity-form/pdsa-activity-parent-form.component';
import { PdsaActivityTabFiveComponent } from './pdsa/components/pdsa-mobile-activity-form/pdsa-activity-tab-five/pdsa-activity-tab-five.component';
import { PdsaActivityTabFourComponent } from './pdsa/components/pdsa-mobile-activity-form/pdsa-activity-tab-four/pdsa-activity-tab-four.component';
import { PdsaActivityTabOneComponent } from './pdsa/components/pdsa-mobile-activity-form/pdsa-activity-tab-one/pdsa-activity-tab-one.component';
import { PdsaActivityTabSixComponent } from './pdsa/components/pdsa-mobile-activity-form/pdsa-activity-tab-six/pdsa-activity-tab-six.component';
import { PdsaActivityTabThreeComponent } from './pdsa/components/pdsa-mobile-activity-form/pdsa-activity-tab-three/pdsa-activity-tab-three.component';
import { PdsaActivityTabTwoComponent } from './pdsa/components/pdsa-mobile-activity-form/pdsa-activity-tab-two/pdsa-activity-tab-two.component';
import { PdsaPosteActivityFormComponent } from './pdsa/components/pdsa-poste-activity-form/pdsa-poste-activity-form.component';
import { PdsaPosteActivityTabOneComponent } from './pdsa/components/pdsa-poste-activity-form/pdsa-poste-activity-tab-one/pdsa-poste-activity-tab-one.component';
import { PdsaPosteActivityTabTwoComponent } from './pdsa/components/pdsa-poste-activity-form/pdsa-poste-activity-tab-two/pdsa-poste-activity-tab-two.component';
import { PdsaSettingsComponent } from './pdsa/components/pdsa-settings/pdsa-settings.component';
import { PdsaStructureActivityComponent } from './pdsa/components/pdsa-structure-activity/pdsa-structure-activity.component';
import { QualiteAraComponent } from './qualite-ara/qualite-ara.component';
import { DepartmentName } from './shared/pipes/DepartmentName';
import { FilterPipe } from './shared/pipes/FilterPipe';
import { SortPipe } from './shared/pipes/SortPipe';
import { SharedModule } from './shared/shared.module';
import { StatusComponent } from './status/status.component';
import { ReportChangeComponent } from './pdsa/components/pdsa-structure-activity/report-change/report-change.component';
import { PdsaPosteActivityTabThreeComponent } from './pdsa/components/pdsa-poste-activity-form/pdsa-poste-activity-tab-three/pdsa-poste-activity-tab-three.component';
import { ButtonClickService } from './shared/service/button-click.service';
import { DisableDoubleClickDirective } from './directive/disable-double-click.directive';
import { EmergencyServiceModule } from './emergency-depts/emergency-dept.module';
import { PdsaActivityTabSevenComponent } from './pdsa/components/pdsa-mobile-activity-form/pdsa-activity-tab-seven/pdsa-activity-tab-seven.component';
import { NgxPrintModule } from 'ngx-print';
import { PdsaPosteActivityPDFComponent } from './pdsa/components/pdsa-poste-activity-form/pdsa-poste-activity-pdf/pdsa-poste-activity-pdf.component';
import { PdsaMobileActivityPdfComponent } from './pdsa/components/pdsa-mobile-activity-form/pdsa-mobile-activity-pdf/pdsa-mobile-activity-pdf.component';
// the second parameter 'fr' is optional
registerLocaleData(localeFr, 'fr');
// import * as Highcharts from 'highcharts';

@NgModule({
  declarations: [
    AppComponent,
    ErrorPageComponent,
    IframeTestComponent,
    StatusComponent,
    NtgGraphHomeComponent,
    NtgGraphComponent,
    GraphAccueilComponent,
    IframeMeteoComponent,
    MentionsLegalesComponent,
    HetComponent,
    IframeSamuComponent,
    ErrorInexistentComponent,
    GraphFilterComponent,
    ModalFilterComponent,
    EmergencyVisitsGraphsComponent,
    VisitsChildGraphComponent,
    VisitsAboveSeventyFiveYearsOldGraphComponent,
    HospitalisationsGraphComponent,
    HospitalisationsRateGraphComponent,
    HospitalisationsRateChildGraphComponent,
    HospitalisationsChildGraphComponent,
    HospitalisationsRateChildGraphComponent,
    ThirdGraphComponent,
    GraphTestComponent,
    FirstGraphComponent,
    VisitsGraphComponent,
    SeventhGraphComponent,
    SixthGraphComponent,
    ThirdGraphComponent,
    FourthGraphComponent,
    FifthGraphComponent,
    SecondGraphComponent,
    ChargeGraphHomeComponent,
    ChargeGraphComponent,
    DepartmentName,
    SortPipe,
    HomePageIdfComponent,
    HomeFormContactComponent,
    FormatNumberPipe,
    IframeSnpQualiteComponent,
    IframeEmergencyTraficComponent,
    IframeMesUrgencesComponent,
    IframePowerBiComponent,
    IframeMesUrgencesProComponent,
    EffectorsFormComponent,
    PdsaSettingsComponent,
    SortDirective,
    FilterPipe,
    ModalErrorComponent,
    ModalRegisterComponent,
    PdsaStructureActivityComponent,
    PdsaActivityParentFormComponent,
    PdsaActivityTabOneComponent,
    PdsaActivityTabTwoComponent,
    PdsaActivityTabThreeComponent,
    PdsaActivityTabFourComponent,
    PdsaActivityTabFiveComponent,
    PdsaActivityTabSixComponent,
    PdsaPosteActivityFormComponent,
    PdsaPosteActivityTabOneComponent,
    PdsaPosteActivityTabTwoComponent,
    PdsaPosteActivityTabThreeComponent,
    PdsaActivityInputComponent,
    PdsaPosteActivityPDFComponent,
    QualiteAraComponent,
    ReportChangeComponent,
    PdsaActivityTabSevenComponent,
    PdsaMobileActivityPdfComponent
  ],
  exports: [DepartmentName, SortPipe, FormatNumberPipe, FilterPipe],
  imports: [
    BrowserModule,
    CommonModule,
    SharedModule,
    EmergencyServiceModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    NgxPaginationModule,
    NgxPrintModule
  ],
  bootstrap: [AppComponent],
  providers: [
    RapportActiviteService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthExpiredInterceptor,
      multi: true
    },
    CookieService,
    { provide: Favicons, useClass: BrowserFavicons },
    {
      provide: BROWSER_FAVICONS_CONFIG,
      useValue: {
        icons: {
          '11': {
            type: 'image/png',
            href: '../assets/images/fav-idf.svg',
            isDefault: true
          },
          '84': {
            type: 'image/png',
            href: '../assets/images/fav-ARA.png',
            isDefault: false
          }
        },
        cacheBusting: true
      }
    },
    {
      provide: MATOMO_CONFIGURATION,
      useValue: {
        scriptUrl: '//analytics.sante-ra.fr/matomo/matomo.js',
        trackers: [
          {
            trackerUrl: '//analytics.sante-ra.fr/matomo/matomo.php'
          }
        ],
        routeTracking: {
          enable: true
        }
      }
    },
    { provide: LOCALE_ID, useValue: 'fr' }
  ]
})
export class AppModule {}
