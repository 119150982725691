<form [formGroup]="postedConsultationRangeForm" (ngSubmit)="onSubmit()">
  <div class="table-responsive" id="tab_one">
    <table class="table table-striped shadow-sm p-3 bg-white">
      <thead>
        <tr class="noselect header-row">
          <th
            style="max-width: 15vw; width: 15vw; padding: 0px; margin: 0px"
          ></th>
          <th
            *ngFor="let timeSlotLabel of timeSlotLabels; let i = index"
            style="max-width: 4vw; margin: 0px"
          >
            <p style="color: #ffffff">{{ timeSlotLabel }}</p>
            <p style="color: #ffffff">{{ timeSlotDayTypes[i] }}</p>
          </th>
        </tr>
      </thead>

      <tbody formArrayName="elements">
        <tr class="noselect hover-effect">
          <td>
            <div class="d-flex align-items-center">
              <div class="line-name fontSize">Nombre de consultations</div>
            </div>
          </td>
          <td
            *ngFor="
              let countControl of postedConsultationRangeForm.get('elements')
                ?.controls;
              let i = index
            "
          >
            <div class="d-flex justify-content-center align-items-center">
              <input
                type="text"
                [formControl]="countControl.get('count')"
                class="rounded border inputSize"
                [ngClass]="{
                  error: countControl.get('count')?.getError('invalidValue'),
                  warning: countControl.get('count')?.getError('emptyValue')
                }"
                (input)="this.onInputChange()"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</form>
