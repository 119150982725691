import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { EffectorsAddress } from '../model/effectorsAddress.model';

@Injectable({
  providedIn: 'root'
})
export class EffectorsAddressService {
  constructor(private httpClient: HttpClient) {}

  public getEffectorsAddress(): Observable<EffectorsAddress[]> {
    return this.httpClient.get<EffectorsAddress[]>(`/api/effectorsAddress`);
  }

  public getEffectorsAddressById(
    idEffecteur: any
  ): Observable<EffectorsAddress> {
    return this.httpClient.get<EffectorsAddress>(
      `/api/effectorsAddress/${idEffecteur}`
    );
  }

  public updateEffectorsAddress(
    id: Number,
    effectorsAddress: EffectorsAddress
  ): Observable<EffectorsAddress> {
    return this.httpClient.put<EffectorsAddress>(
      `api/effectorsAddress/${id}`,
      effectorsAddress
    );
  }

  public saveEffectorsAddress(
    effectorsAddress: EffectorsAddress
  ): Observable<EffectorsAddress> {
    return this.httpClient.post<EffectorsAddress>(
      `api/effectorsAddress`,
      effectorsAddress
    );
  }

  public deleteEffectorsAddress(id: Number): Observable<EffectorsAddress> {
    return this.httpClient.delete<EffectorsAddress>(
      `api/effectorsAddress/${id}`
    );
  }
}
