import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { Alert, AlertType } from "src/app/emergency-depts/model/alert.model";
import { AlertService } from "src/app/emergency-depts/services/alert.service";

@Component({
  selector: "alert",
  templateUrl: "./alert.component.html",
  styleUrls: ["./alert.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AlertComponent implements OnInit {
  @Input() id = "default-alert";
  @Input() fade = true;
  @Output() alertNumber = new EventEmitter<number>();

  alerts: Alert[] = [];
  alertSubscription: Subscription;
  routeSubscription: Subscription;
  lastAlertId: number;

  constructor(private router: Router, private alertService: AlertService) {}

  ngOnInit() {
    this.alertNumber.emit(0);
    console.log("hello1");

    // subscribe to new alert notifications
    this.alertSubscription = this.alertService
      .onAlert(this.id)
      .subscribe((alert) => {
        console.log("hello");
        console.log(alert);
        // clear alerts when an empty alert is received
        this.alerts = this.alerts.filter(
          (alrt) => alrt.pageAlert === alert.pageAlert
        );
        if (!alert.textAlert) {
          return;
        }

        // add alert to array
        this.alerts.push(alert);
        console.log(this.alerts.length);
        // auto close alert if required
        this.alertNumber.emit(this.alerts.length);
      });

    // clear alerts on location change

    this.routeSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.alertService.clear(this.id);
      }
    });
    console.log("nombre d'alerte", this.alerts.length)
  }

  ngOnDestroy() {
    // unsubscribe to avoid memory leaks
    this.alertSubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
  }

  removeAlert(alert: Alert) {
    // check if already removed to prevent error on auto close
    if (!this.alerts.includes(alert)) return;
    this.alerts = this.alerts.filter((x) => x !== alert);
    this.alertNumber.emit(this.alerts.length);
  }

  getAlertNumber() {
    var alertTotal: number = this.alerts.length;
    return alertTotal;
  }


  cssClass(alert: Alert): string | undefined {
    if (!alert) return;

    const classes = ["alert", "alert-dismissable"];

    const alertTypeClass = {
      Primary: "alert alert-primary",
      Secondary: "alert alert-secondary",
      Success: "alert alert-success",
      Danger: "alert alert-danger",
      Info: "alert alert-info",
      Warning: "alert alert-warning",
      Dark: "alert alert-dark",
      Light: "alert alert-light",
    };

    classes.push(alertTypeClass[alert.colorAlert]);

    return classes.join(" ");
  }
}
