import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ChargeIndicateur } from "../../model/ChargeIndicateur.model";

@Injectable({
  providedIn: "root",
})
export class ChargeGraphService {
  constructor(private httpClient: HttpClient) {}

  getIndicateurCharge(
    emergencyDeptId,
    startDate,
    endDate
  ): Observable<ChargeIndicateur[]> {
    const params = {
      startDate: this.dateToString(startDate),
      endDate: this.dateToString(endDate),
      emergencyDeptId: emergencyDeptId,
    };
    return this.httpClient.get<ChargeIndicateur[]>(
      `/api/indicateurs-charge/by_sau`,
      {
        params,
      }
    );
  }

  private dateToString(date: Date): string {
    const month = `${date.getMonth() + 1}`;
    const day = `${date.getDate()}`;
    return (
      date.getFullYear() +
      "-" +
      ("00" + month).substring(month.length) +
      "-" +
      ("00" + day).substring(day.length)
    );
  }
}
