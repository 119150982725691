import { EmergencyDept } from "src/app/emergency-depts/model/emergency-dept.model";
import { SizeGroup } from "./size-group.model";

export type Period = {
  startDate: Date;
  endDate: Date;
};

export enum Period1OptionKind {
  ONE_MONTH = "ONE_MONTH",
  ONE_SEMESTER = "ONE_SEMESTER",
  ONE_YEAR = "ONE_YEAR",
  START_YEAR = "START_YEAR",
  CUSTOM = "CUSTOM",
}

export type Period1Option = {
  optionKind: Period1OptionKind;
  label: string;
};

export type Period1 = Period & {
  optionKind: Period1OptionKind;
};

export enum Period2OptionKind {
  PREVIOUS_PERIOD = "PREVIOUS_PERIOD",
  YEAR_NM1 = "YEAR_NM1",
  YEAR_NM2 = "YEAR_NM2",
  CUSTOM = "CUSTOM",
}

export type Period2Option = {
  optionKind: Period2OptionKind;
  label: string;
};

export type Period2 = Period & {
  optionKind: Period2OptionKind;
};

export type LibSerUrgtoCompare = {
  codRorEg: string;
  codSerUrg: number;
};

export class GraphFilter {
  sizeGroups: SizeGroup[] = [];
  departementCodes: string[] = [];
  emergencyDepts: EmergencyDept[] = [];
  period1: Period1;
  period2: Period2;
  isActivePeriod2 = false;
  compareEmergencyDeptId?: number;
  selectedAbrSerUrgName?: string;
}
